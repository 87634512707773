import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import updateServiceAssignment from './updateServiceAssignment';
import updateServiceAssignmentPowerOfAttorney from './updateServiceAssignmentPowerOfAttorney';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import modalIds from '../../modalIds';

/**
 * Update power of attorney flow
 */
const updatePowerOfAttorneyFlow = function* updatePowerOfAttorneyFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM);
            payloadData = payload;
        }

        if (payloadData) {
            const {
                assignmentType,
                serviceAssignmentLineNo,
                serviceAssignmentData,
                serviceAssignmentPowerOfAttorneyData,
                serviceCaseId,
            } = payloadData;

            const updateServiceAssignmentResponseAction = yield* updateServiceAssignment({
                caller: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM,
                assignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });

            if (!updateServiceAssignmentResponseAction.error) {
                const responsePowerOfAttorneyAction = yield* updateServiceAssignmentPowerOfAttorney({
                    caller: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentPowerOfAttorneyData,
                });

                if (!responsePowerOfAttorneyAction.error) {
                    yield* openModal(modalIds.POWER_OF_ATTORNEY_SUCCESSFUL);
                    shouldWaitForAction = true;
                    payloadData = null;
                }

                if (responsePowerOfAttorneyAction.error) {
                    const status = responsePowerOfAttorneyAction.payload?.response?.status;

                    yield* openModal(
                        modalIds.FAILED_SERVICE_ERROR_MESSAGE,
                        {
                            errorCode: status ? `${status}` : '',
                            requestedAction: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM.toLowerCase(),
                        },
                    );

                    const chosenModalOption = yield take([
                        serviceAssignmentActionTypes.CONFIRM_RESENDING_FAILED_POWER_OF_ATTORNEY,
                        serviceAssignmentActionTypes.DECLINE_RESENDING_FAILED_POWER_OF_ATTORNEY,
                    ]);

                    if (chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_RESENDING_FAILED_POWER_OF_ATTORNEY) { // eslint-disable-line max-len
                        shouldWaitForAction = false;
                        continue;
                    }

                    yield* closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''});
                    shouldWaitForAction = true;
                    payloadData = null;
                }
            }
        }
    }
};

export default updatePowerOfAttorneyFlow;

