import React, {Fragment, useRef, useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efChildrenAgeTypes} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerHomePNG, markerVehiclePNG, markerSearchPNG} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, InputCounter, Option, Divider, Pill} from '@ace-de/ui-components';
import {Form, TextAreaField, AutosuggestField, SelectField, ButtonPrimary, InputField} from '@ace-de/ui-components/form';
import {Icon, locationIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as samcActionTypes from './samcActionTypes';
import config from '../config';

const SAMCRelevantPlacesTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('samc_relevant_places_tab');
    const arcGISMap = useArcGISMap('service-assignment-medical-clearance');
    const {serviceAssignment, serviceCase, submitSAMCDamageLocationForm} = props;
    const {updatePersonCaseDamageLocationInfo, searchSAMCDamageLocationGeolocation} = props;
    const {startSAMCMapDamageLocationWatcher, stopSAMCMapDamageLocationWatcher} = props;
    const isSAMCMapDamageLocationWatcherStarted = useRef(false);
    const currentDamageLocationAddress = useRef('');
    const lastDamageLocationSearchQuery = useRef('');
    const prevReverseGeocodingDamageLocation = useRef(null);
    const [newDamageLocation, setNewDamageLocation] = useState(serviceAssignment?.serviceLocation || null);
    const searchSAMCDamageLocationGeolocationDebounced = useMemo(
        () => debounce(searchSAMCDamageLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSAMCDamageLocationGeolocation],
    );
    const [damageLocationInfoFormData, setNewDamageLocationInfoFormData] = useState(null);
    const [childrenAge, setChildrenAge] = useState(serviceAssignment?.childrenAge?.length
        ? (serviceAssignment.childrenAge.length < serviceAssignment?.childrenAtSite
            ? serviceAssignment.childrenAge
                .concat(new Array(serviceAssignment?.childrenAtSite - serviceAssignment.childrenAge.length)
                    .fill('', 0))
            : serviceAssignment.childrenAge)
        : (serviceAssignment?.childrenAtSite
            ? new Array(serviceAssignment?.childrenAtSite).fill('', 0)
            : []));

    useEffect(() => {
        if (!serviceAssignment || isSAMCMapDamageLocationWatcherStarted.current) return;

        startSAMCMapDamageLocationWatcher({
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        isSAMCMapDamageLocationWatcherStarted.current = true;

        return () => {
            stopSAMCMapDamageLocationWatcher();
            isSAMCMapDamageLocationWatcherStarted.current = false;
        };
    }, [serviceAssignment, startSAMCMapDamageLocationWatcher, stopSAMCMapDamageLocationWatcher]);

    useEffect(() => {
        if (!serviceAssignment || !serviceCase) return;

        const {reverseGeocodingDamageLocation} = serviceAssignment;
        if (reverseGeocodingDamageLocation
            && reverseGeocodingDamageLocation !== prevReverseGeocodingDamageLocation.current) {
            prevReverseGeocodingDamageLocation.current = reverseGeocodingDamageLocation;
            setNewDamageLocation(reverseGeocodingDamageLocation);
        }
    }, [serviceAssignment, serviceCase]);

    useEffect(() => {
        if (!serviceCase || !serviceAssignment || !arcGISMap) return;

        const {member} = serviceCase;
        const {serviceLocation} = serviceAssignment;
        const {residenceLocation} = member;

        const isDamageLocationSaved = !!(newDamageLocation && !serviceLocation
            && !!(serviceLocation && serviceLocation.latitude === newDamageLocation.latitude
                && serviceLocation.longitude === newDamageLocation.longitude));

        arcGISMap.setGraphics({
            graphics: [
                ...(newDamageLocation && !isDamageLocationSaved ? [
                    createMarkerGraphic({
                        id: 'searchLocation',
                        longitude: newDamageLocation.longitude,
                        latitude: newDamageLocation.latitude,
                        icon: markerSearchPNG,
                        isDraggable: true,
                    }),
                ] : []),
                ...(residenceLocation.longitude && residenceLocation.latitude
                    ? [
                        createMarkerGraphic({
                            id: 'residenceLocation',
                            longitude: residenceLocation.longitude,
                            latitude: residenceLocation.latitude,
                            icon: markerHomePNG,
                        }),
                    ] : []),
                ...(serviceLocation
                    ? [
                        createMarkerGraphic({
                            id: 'damageLocation',
                            longitude: serviceLocation.longitude,
                            latitude: serviceLocation.latitude,
                            icon: markerVehiclePNG,
                        }),
                    ] : []),
            ],
        });
    }, [serviceCase, arcGISMap, serviceAssignment, newDamageLocation]);

    const handleDamageLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentDamageLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSAMCDamageLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastDamageLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleDamageLocationCandidateSelect = locationCandidate => {
        currentDamageLocationAddress.current = locationCandidate.formattedAddress;
        setNewDamageLocation(locationCandidate);
    };

    const handleDamageLocationFormSubmit = useCallback(() => {
        if (!newDamageLocation) return;

        // do not overwrite locationNotes
        const {locationNotes, ...restNewDamageLocation} = newDamageLocation;

        submitSAMCDamageLocationForm({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            location: restNewDamageLocation,
            memberResidenceLocation: serviceCase.member.residenceLocation,
        });
    }, [
        submitSAMCDamageLocationForm,
        serviceAssignment.serviceCaseId,
        serviceAssignment.lineNo,
        serviceCase,
        newDamageLocation,
    ]);

    const updateChildrenAgeData = formValues => {
        const {childrenAtSite, childrenAge: childrenAgeValues} = formValues;
        const currentChildrenAgeValues = childrenAtSite
            ? (Object.keys(childrenAgeValues).length
                ? Object.keys(childrenAgeValues).map(key => childrenAgeValues[key])
                    .slice(0, damageLocationInfoFormData?.childrenAtSite || serviceAssignment.childrenAtSite)
                : childrenAge)
            : [];
        if (childrenAtSite > currentChildrenAgeValues.length) {
            setChildrenAge([...currentChildrenAgeValues, '']);
            return;
        }
        if (childrenAtSite < currentChildrenAgeValues.length) {
            const updatedChildrenArray = [...currentChildrenAgeValues];
            updatedChildrenArray.pop();
            setChildrenAge(updatedChildrenArray);
            return;
        }
        setChildrenAge(currentChildrenAgeValues);
    };

    const handleDamageLocationInfoFormChange = formValues => {
        updateChildrenAgeData(formValues);
        setNewDamageLocationInfoFormData(formValues);
    };

    const handleTabChange = useCallback(formValues => {
        const {
            locationNotes,
            clinicNumber,
            grownUpsAtSite,
            childrenAtSite,
            petsAtSite,
        } = formValues;
        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        updatePersonCaseDamageLocationInfo({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            caseType: serviceCase.caseType,
            serviceCaseData: isInitialAssignment ? {
                // if it's an initial assignment, update service case
                damage: {
                    location: {
                        locationNotes,
                    },
                },
                childrenAge: childrenAge.filter(age => age !== ''),
                grownUpsAtSite,
                childrenAtSite,
                petsAtSite,
            } : null,
            serviceAssignmentData: {
                clinicNumber,
                serviceLocation: {
                    locationNotes,
                },
                childrenAge: childrenAge.filter(age => age !== ''),
                grownUpsAtSite,
                childrenAtSite,
                petsAtSite,
            },
        });
    }, [
        updatePersonCaseDamageLocationInfo,
        serviceAssignment.serviceCaseId,
        serviceAssignment.lineNo,
        serviceAssignment.isCloneFromInitial,
        childrenAge,
        serviceCase.caseType,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!damageLocationInfoFormData) {
            completeRouteUnmountSideEffect({
                caller: samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_INFO_FORM,
            });
            return;
        }

        handleTabChange(damageLocationInfoFormData);
    }, [damageLocationInfoFormData, handleTabChange]);

    // if no case or assignment don't render
    if (!serviceAssignment || !serviceCase) return null;

    const {member, damage} = serviceCase;
    const {residenceLocation} = member;
    const {damageLocationCandidates, damageLocationSearchQuery} = serviceAssignment;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.relevant_places')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock
                        label={translateTab('scrollable_block_label.fill_details')}
                    >
                        <Form
                            name="damageLocationForm"
                            onSubmit={handleDamageLocationFormSubmit}
                        >
                            {formValues => {
                                const isDamageLocationSaved = serviceAssignment.serviceLocation
                                    && serviceAssignment.serviceLocation.formattedAddress
                                    && formValues['damageLocationSearchQuery'] === serviceAssignment.serviceLocation.formattedAddress
                                    && (newDamageLocation
                                        ? newDamageLocation.longitude === serviceAssignment.serviceLocation.longitude
                                            && newDamageLocation.latitude === serviceAssignment.serviceLocation.latitude
                                        : true);
                                const isSubmitButtonDisabled = isDamageLocationSaved
                                    || (!formValues['damageLocationSearchQuery'] || !newDamageLocation);
                                return (
                                    <Fragment>
                                        <AutosuggestField
                                            name="damageLocationSearchQuery"
                                            value={newDamageLocation?.formattedAddress || ''}
                                            onChange={handleDamageLocationSearchQueryChange}
                                            onOptionSelect={handleDamageLocationCandidateSelect}
                                            optionValueSelector={locationCandidate => {
                                                return locationCandidate.formattedAddress;
                                            }}
                                            label={translateTab('input_label.address')}
                                            placeholder={translateTab('input_placeholder.please_enter')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-32',
                                            ])}
                                        >
                                            {(formValues['damageLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                && damageLocationSearchQuery === lastDamageLocationSearchQuery.current
                                                ? damageLocationCandidates
                                                    .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                    .map((locationCandidate, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                name={`damage-location-candidate-${index}`}
                                                                value={locationCandidate}
                                                            >
                                                                <Icon
                                                                    icon={locationIcon}
                                                                    className={cx('global!ace-u-margin--right-16')}
                                                                />
                                                                {locationCandidate.formattedAddress}
                                                            </Option>
                                                        );
                                                    }) : null}
                                        </AutosuggestField>
                                        <ButtonPrimary
                                            name="submitDamageLocation"
                                            type="submit"
                                            isDisabled={isSubmitButtonDisabled}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-8',
                                            ], {
                                                'ace-c-button-primary--is-positive': isDamageLocationSaved,
                                            })}
                                        >
                                            {translateTab('button_label.confirm_location')}
                                            {isDamageLocationSaved && (
                                                <Icon
                                                    icon={checkmarkIcon}
                                                    className={cx(
                                                        'ace-c-icon--color-contrast',
                                                        'global!ace-u-margin--left-16',
                                                    )}
                                                />
                                            )}
                                        </ButtonPrimary>
                                        {residenceLocation.formattedAddress && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.members_place_of_residence')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {residenceLocation.formattedAddress}
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )}
                                        {!!serviceAssignment?.serviceLocation && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.vehicle_pickup_location')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {serviceAssignment.serviceLocation.formattedAddress || ''}
                                                </div>
                                                {serviceAssignment.distanceResidenceToServiceLocation !== null && (
                                                    <div className={cx('global!ace-u-margin--bottom-24')}>
                                                        <Pill
                                                            type="information"
                                                            className={cx('global!ace-u-typography--variant-caption')}
                                                        >
                                                            {translateTab('pill_label.distance_to_residence')}&nbsp;
                                                            {/* eslint-disable-next-line max-len */}
                                                            {Math.round(serviceAssignment.distanceResidenceToServiceLocation)}
                                                            {translate('global.unit.km')}
                                                        </Pill>
                                                    </div>
                                                )}
                                                <Divider />
                                            </Fragment>
                                        )}
                                    </Fragment>
                                );
                            }}
                        </Form>
                        <Form
                            name="damageLocationInfoForm"
                            onChange={handleDamageLocationInfoFormChange}
                        >
                            <TextAreaField
                                name="locationNotes"
                                label={translateTab('text_area_label.abode_notes')}
                                placeholder={translateTab('text_area_placeholder.example_hospital_station')}
                                // pre-fill with the damage location notes
                                value={serviceAssignment?.serviceLocation?.locationNotes
                                    ? serviceAssignment.serviceLocation.locationNotes
                                    : damage?.location?.locationNotes || ''}
                                isResizable={false}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--24-0',
                                ])}
                            />
                            <InputField
                                name="clinicNumber"
                                label={translateTab('input_field_label.clinic_phone_number')}
                                placeholder={translateTab('input_field_placeholder.example_clinic_phone_number')}
                                value={serviceAssignment.clinicNumber || ''}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            />
                            <div
                                className={cx([
                                    'global!ace-u-flex--align-self-flex-start',
                                    'global!ace-u-typography--variant-body-bold',
                                ])}
                            >
                                {translateTab('text.escorts')}
                            </div>
                            <InputCounter
                                name="grownUpsAtSite"
                                label={translateTab('input_counter_label.adults')}
                                value={serviceAssignment.grownUpsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider />
                            <InputCounter
                                name="childrenAtSite"
                                label={translateTab('input_counter_label.children')}
                                value={serviceAssignment.childrenAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            {!childrenAge.length && (
                                <Divider />
                            )}
                            <Form name="childrenAge">
                                <div
                                    className={cx('global!ace-u-grid', {
                                        'global!ace-u-padding--16-0': childrenAge.length,
                                    })}
                                >
                                    {childrenAge.map((age, idx) => (
                                        <SelectField
                                            key={idx}
                                            name={`childrenAge${idx}`}
                                            label={translateTab('input_label.children_age')}
                                            value={age}
                                            className={cx('global!ace-u-grid-column--span-6')}
                                        >
                                            {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                <Option
                                                    key={idx}
                                                    name={`children-age-option-${idx}`}
                                                    value={idx}
                                                >
                                                    {efChildrenAgeTypes[ageOption]}
                                                </Option>
                                            ))}
                                        </SelectField>
                                    ))}
                                </div>
                                {!!childrenAge.length && (
                                    <Divider />
                                )}
                            </Form>
                            <InputCounter
                                name="petsAtSite"
                                label={translateTab('input_counter_label.animals')}
                                value={serviceAssignment.petsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider />
                        </Form>
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAMCRelevantPlacesTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    updatePersonCaseDamageLocationInfo: PropTypes.func.isRequired,
    searchSAMCDamageLocationGeolocation: PropTypes.func.isRequired,
    submitSAMCDamageLocationForm: PropTypes.func.isRequired,
    startSAMCMapDamageLocationWatcher: PropTypes.func.isRequired,
    stopSAMCMapDamageLocationWatcher: PropTypes.func.isRequired,
};

SAMCRelevantPlacesTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSAMCDamageLocationGeolocation: payload => dispatch({
        type: samcActionTypes.SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSAMCDamageLocationForm: payload => dispatch({
        type: samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_FORM,
        payload,
    }),
    updatePersonCaseDamageLocationInfo: payload => dispatch({
        type: samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_INFO_FORM,
        payload,
    }),
    startSAMCMapDamageLocationWatcher: payload => dispatch({
        type: samcActionTypes.START_SAMC_MAP_DAMAGE_LOCATION_WATCHER,
        payload,
    }),
    stopSAMCMapDamageLocationWatcher: payload => dispatch({
        type: samcActionTypes.STOP_SAMC_MAP_DAMAGE_LOCATION_WATCHER,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMCRelevantPlacesTab));
