import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, ContentItem, ContentBlock, Divider, Icon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {closeIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmACEPartnerServiceTypes, apmContractPartnerVehicleCompatibilityTypes, apmContractPartnerServiceTonnageTypes, ampVehicleWeightTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import config from '../../config';

const ContractPartnerBasicServicesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel>
            <ContentBlock>
                <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                    <div
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-flex--align-stretch',
                        ])}
                    >
                        <h3 className={cx('global!ace-u-typography--variant-h3')}>
                            {translateTab('content_item_header.service_area')}
                        </h3>
                    </div>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    className={cx('ace-c-table__cell--highlighted')}
                                >
                                    {translateTab('table_header.foreign_services')}
                                </TableCell>
                                <TableCell colSpan={9}>
                                    <div
                                        className={cx(['global!ace-u-inline-flex', 'global!ace-u-full-width'])}
                                    >
                                        <Icon
                                            icon={contractPartner.serviceAreaCountries?.length > 0
                                                ? checkmarkIcon : closeIcon}
                                            className={cx([
                                                'global!ace-u-margin--right-16',
                                                contractPartner.serviceAreaCountries?.length > 0
                                                    ? 'ace-c-icon--color-success'
                                                    : 'ace-c-icon--color-disabled',
                                            ])}
                                        />
                                        {contractPartner.serviceAreaCountries?.length > 0 && contractPartner.serviceAreaCountries // eslint-disable-line max-len
                                            .map(country => translate(`global.country.${country.toLowerCase()}`))
                                            .join(', ')
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Divider />
                </ContentItem>
            </ContentBlock>
            <ContentBlock>
                <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateTab('content_item_header.basic_services')}
                    </h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} />
                                {Object.values(apmContractPartnerServiceTonnageTypes).map(tonnage => (
                                    <TableCell key={tonnage}>
                                        {tonnage !== apmContractPartnerServiceTonnageTypes.NO_SERVICES
                                            ? (tonnage !== apmContractPartnerServiceTonnageTypes.ULTRA_HEAVY_WEIGHT_VEHICLE // eslint-disable-line max-len
                                                ? `${translateTab('table_header.to_vehicle_weight_limit', {
                                                    vehicleWeightLimit: new Intl.NumberFormat('de-DE', {
                                                        style: 'unit',
                                                        unit: 'kilogram',
                                                    }).format(ampVehicleWeightTypes[tonnage]),
                                                })}`
                                                : `${translateTab('table_header.over_vehicle_weight_limit', {
                                                    vehicleWeightLimit: new Intl.NumberFormat('de-DE', {
                                                        style: 'unit',
                                                        unit: 'kilogram',
                                                    }).format(ampVehicleWeightTypes.HEAVY_WEIGHT_VEHICLE),
                                                })}`
                                            ) : translateTab('table_header.no_services')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                apmACEPartnerServiceTypes.RECOVERY,
                                apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                                apmACEPartnerServiceTypes.TOWING,
                            ].map(service => {
                                const cpTThreshold = contractPartner[camelCase(service)]?.tonnageThreshold;
                                return (
                                    <TableRow key={service}>
                                        <TableCell colSpan={3}>
                                            {translateTab(`table_row_label.${service.toLowerCase()}`)}
                                        </TableCell>
                                        {Object.values(apmContractPartnerServiceTonnageTypes).map(tonnage => {
                                            /* eslint-disable max-len */
                                            const cpTonnage = tonnage !== apmContractPartnerServiceTonnageTypes.NO_SERVICES
                                                ? (tonnage !== apmContractPartnerServiceTonnageTypes.ULTRA_HEAVY_WEIGHT_VEHICLE
                                                    ? ampVehicleWeightTypes[tonnage]
                                                    : config.MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE
                                                ) : 0;
                                            /* eslint-enable max-len */
                                            return (
                                                <TableCell key={tonnage}>
                                                    <Icon
                                                        icon={cpTonnage !== 0
                                                            ? cpTThreshold >= cpTonnage
                                                                ? checkmarkIcon : closeIcon
                                                            : (cpTThreshold > 0
                                                                ? closeIcon : checkmarkIcon)}
                                                        className={cx([
                                                            'global!ace-u-margin--right-16',
                                                            cpTonnage !== 0
                                                                ? cpTThreshold >= cpTonnage
                                                                    ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                                : (cpTThreshold > 0
                                                                    ? 'ace-c-icon--color-disabled'
                                                                    : 'ace-c-icon--color-success'),
                                                        ])}
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Divider />
                </ContentItem>
            </ContentBlock>
            <ContentBlock>
                <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateTab('content_item_header.vehicle_compatibility')}
                    </h3>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3} />
                                {Object.values(apmContractPartnerVehicleCompatibilityTypes).map(vehicleType => (
                                    <TableCell colSpan={2} key={vehicleType}>
                                        <div className={cx('global!ace-u-inline-flex')}>
                                            <Icon
                                                icon={contractPartner.vehicleCompatibility?.includes(vehicleType) // eslint-disable-line max-len
                                                    ? checkmarkIcon : closeIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-16',
                                                    contractPartner.vehicleCompatibility.includes(vehicleType) // eslint-disable-line max-len
                                                        ? 'ace-c-icon--color-success'
                                                        : 'ace-c-icon--color-disabled',
                                                ])}
                                            />
                                            {translateTab(`table_row_label.${vehicleType.toLowerCase()}`)}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Divider />
                </ContentItem>
            </ContentBlock>
        </Panel>
    );
};

ContractPartnerBasicServicesPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerBasicServicesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerBasicServicesPanel));
