import {put, select, take, fork} from 'redux-saga/effects';
import {apmACEPartnerServiceTypes, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sarcActionTypes from '../sarcActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';

const loadSARCACEPartnerData = function* loadSARCACEPartnerData() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    yield fork(fetchRequest,
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST,
        partnerManagementService.getACEPartnersV2, {
            partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
            serviceType: apmACEPartnerServiceTypes.RENTAL_CAR,
        });

    const responseAction = yield take([
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST_SUCCEEDED,
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.STORE_RENTAL_CAR_ACE_PARTNERS,
            payload: {acePartnerDTOs},
        });
    }

    yield fork(fetchRequest,
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST,
        partnerManagementService.getACEPartnersV2, {
            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
            serviceType: apmACEPartnerServiceTypes.RENTAL_CAR,
        });

    const responseActionOfACEPartner = yield take([
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST_SUCCEEDED,
        sarcActionTypes.FETCH_ACE_PARTNER_DATA_REQUEST_FAILED,
    ]);

    if (!responseActionOfACEPartner.error) {
        const {response} = responseActionOfACEPartner.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.STORE_RENTAL_CAR_ACE_PARTNERS,
            payload: {acePartnerDTOs},
        });
    }
};

export default loadSARCACEPartnerData;
