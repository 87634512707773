import {efServiceTypes, efDamageCategoryTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {svgIcons} from '@ace-de/ui-components';

export const serviceIcons = {
    [efServiceTypes.VEHICLE_OPENING]: svgIcons.vehicleOpeningIcon,
    [efServiceTypes.RECOVERY]: svgIcons.recoveryIcon,
    [efServiceTypes.ROADSIDE_ASSISTANCE]: svgIcons.breakdownIcon,
    [efServiceTypes.TOWING]: svgIcons.towingIcon,
    [efServiceTypes.NO_SERVICE]: svgIcons.noServiceIcon,
};

export const damageCategoryIcons = {
    [efDamageCategoryTypes.FLAT_TIRE]: svgIcons.tireIcon,
    [efDamageCategoryTypes.ACCIDENT_CRASH]: svgIcons.accidentIcon,
    [efDamageCategoryTypes.ENGINE_STOPPED_WHILE_DRIVING]: svgIcons.vehicleIssuesIcon,
    [efDamageCategoryTypes.PROBLEMS_WHILE_DRIVING]: svgIcons.vehicleIssuesIcon,
    [efDamageCategoryTypes.DOES_NOT_START]: svgIcons.lockIcon,
    [efDamageCategoryTypes.NO_ACCESS_TO_VEHICLE]: svgIcons.vehicleOpeningIcon,
    [efDamageCategoryTypes.THEFT_BURGLARY]: svgIcons.theftIcon,
    [efDamageCategoryTypes.OTHER]: svgIcons.otherIcon,
};

export const serviceAssignmentIcons = {
    [efServiceAssignmentTypes.ACCOMMODATION]: svgIcons.overnightStayIcon,
    [efServiceAssignmentTypes.RENTAL_CAR]: svgIcons.carRentalIcon,
    [efServiceAssignmentTypes.SHORT_TRIP]: svgIcons.taxiIcon,
    [efServiceAssignmentTypes.RETURN_JOURNEY]: svgIcons.flightIcon,
    [efServiceAssignmentTypes.SUBSTITUTE_DRIVER]: svgIcons.personOffIcon,
    [efServiceAssignmentTypes.MEDICAL_CLEARANCE]: svgIcons.hospitalIcon,
    [efServiceAssignmentTypes.SICK_PERSON]: svgIcons.hospitalIcon,
    [efServiceAssignmentTypes.VEHICLE_RETURN]: svgIcons.pickupIcon,
    [efServiceAssignmentTypes.OTHER_SERVICES]: svgIcons.otherIcon,
    [efServiceAssignmentTypes.PICKUP]: svgIcons.shipmentIcon,
    [efServiceAssignmentTypes.TRAILER_RETURN]: svgIcons.trailerIcon,
    [efServiceAssignmentTypes.DEATH]: svgIcons.deathCaseIcon,
    [efServiceAssignmentTypes.MEDICINE_SHIPMENT]: svgIcons.medicationIcon,
    [efServiceAssignmentTypes.MEDICAL_ADVICE]: svgIcons.healingIcon,
    [efServiceAssignmentTypes.LEGAL_ADVICE]: svgIcons.legalIcon,
};
