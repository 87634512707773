/**
 * Calculates rental car price for the given rental car pricing, number of days and isDropOffDateExtended
 *
 * @param {Object} rentalCarPricing
 * @param {number} numberOfDays
 * @param {boolean} isDropOffDateExtended
 */
const calculateRentalCarPrice = (rentalCarPricing, numberOfDays, isDropOffDateExtended = false) => {
    if (!isDropOffDateExtended) {
        const flatRatePricing = rentalCarPricing.pricing?.find(pricing => pricing.isFlatPrice);
        if (flatRatePricing?.price && (numberOfDays === 6 || numberOfDays === 7)) {
            return flatRatePricing.price;
        }
    }
    const sortedPricing = rentalCarPricing.pricing?.filter(pricing => {
        return !pricing.isFlatPrice && pricing.price && pricing.dailyFrom && pricing.dailyFrom <= numberOfDays;
    }).sort((pricingA, pricingB) => {
        return (numberOfDays - pricingA.dailyFrom) >= (numberOfDays - pricingB.dailyFrom) ? 1 : -1;
    });

    if (sortedPricing?.length) {
        return sortedPricing[0].price * numberOfDays;
    }
    return 0;
};

export default calculateRentalCarPrice;
