import {eventChannel} from 'redux-saga';
import {select, take, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const onSAAMapClickSelectAccommodationLocation = function* onSAAMapClickSelectAccommodationLocation(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-accommodation');
    const saaAccommodationLocationsLayer = yield call(arcGISMap.getLayer, 'saa-accommodation-locations');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (saaAccommodationLocationsLayer) {
            const unsubscribeClickListener = saaAccommodationLocationsLayer.on('feature-select', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: accommodationLocationFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (accommodationLocationFeatureDTO && !accommodationLocationFeatureDTO.isSelected) {
            const selectedAccommodationLocationLocationId = accommodationLocationFeatureDTO['locationId'];

            const {serviceAssignments} = yield select(state => state.serviceAssignments);
            const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
            const selectedAccommodationLocation = serviceAssignment
                && serviceAssignment.accommodationLocationRecommendations
                    .find(accommodationLocation => {
                        return accommodationLocation.id === selectedAccommodationLocationLocationId;
                    });

            if (!selectedAccommodationLocation) continue;

            yield* updateServiceAssignment({
                caller: 'ON_SAA_MAP_CLICK_SELECT_ACCOMMODATION_LOCATION',
                assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                        externalId: selectedAccommodationLocation.id,
                        location: {
                            ...selectedAccommodationLocation,
                            locationId: selectedAccommodationLocation.id,
                        },
                        name: selectedAccommodationLocation.locationName || '',
                        businessContactDetails: {
                            phoneNo: selectedAccommodationLocation.phoneNo || '',
                        },
                    },
                },
            });
        }
    }
};

export default onSAAMapClickSelectAccommodationLocation;
