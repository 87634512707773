import {select, take} from 'redux-saga/effects';
import {goBack, openModal, closeModal} from '@computerrock/formation-router/sagas';
import {LOCATION_CHANGE_SIDE_EFFECTS_COMPLETED} from '@computerrock/formation-router';
import {clientTypes, efServiceAssignmentStatusTypes, eupShiftRoleTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import updateServiceAssignmentStatus from './updateServiceAssignmentStatus';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Close/Archive service assignment flow (uses modal)
 */
const closeArchiveServiceAssignmentFlow = function* closeArchiveServiceAssignmentFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW);
        const {assignmentType, serviceAssignmentLineNo, serviceCaseId} = payload;
        const {id: userId, clientRoles} = yield select(state => state.user.user);
        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCaseLockStatus = serviceCases[serviceCaseId]?.lock;
        const hasViewerRole = clientRoles
            .find(role => role.client === clientTypes.ECS_CLIENT).userRoles
            .find(role => role === eupShiftRoleTypes.VIEWER);

        if (hasViewerRole || (
            serviceCaseLockStatus?.lockedBy !== userId && serviceCaseId === serviceCaseLockStatus?.serviceCaseId
        )) {
            yield* goBack(routePaths.DASHBOARD);
            continue;
        }

        yield* openModal(modalIds.CLOSE_ARCHIVE_SERVICE_ASSIGNMENT);

        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
            serviceAssignmentActionTypes.DECLINE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT) {
            yield* updateServiceAssignmentStatus({
                caller: serviceAssignmentActionTypes.CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
                assignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentStatusData: {
                    assignmentStatus: efServiceAssignmentStatusTypes.DONE,
                },
            });
        }

        yield* closeModal(modalIds.CLOSE_ARCHIVE_SERVICE_ASSIGNMENT);

        yield take(LOCATION_CHANGE_SIDE_EFFECTS_COMPLETED);

        yield* goBack(routePaths.DASHBOARD);
    }
};

export default closeArchiveServiceAssignmentFlow;
