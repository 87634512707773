import {take, fork, put, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Calculate the distance between member's location and delivery location
 */
const calculateMemberLocationToDeliveryLocationDistance = function* calculateMemberLocationToDeliveryLocationDistance() { // eslint-disable-line max-len
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(sarcActionTypes.CALCULATE_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE);
        const {serviceAssignmentId, deliveryLocation, memberLocation} = payload;

        // calculate the distance beetwen member's location and delivery location
        if (memberLocation && deliveryLocation) {
            yield fork(
                fetchRequest,
                sarcActionTypes.FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: deliveryLocation.longitude,
                        latitude: deliveryLocation.latitude,
                    },
                    destination: {
                        longitude: memberLocation.longitude,
                        latitude: memberLocation.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                sarcActionTypes.FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                sarcActionTypes.FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                const distanceMemberLocationToDeliveryLocation = arcGISRouteDTO.totalKilometers;

                yield put({
                    type: sarcActionTypes.SET_SARC_DELIVERY_LOCATION_CANDIDATE_DISTANCE,
                    payload: {distanceMemberLocationToDeliveryLocation, serviceAssignmentId},
                });
            }
        }
    }
};

export default calculateMemberLocationToDeliveryLocationDistance;
