import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED, matchPath} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import routePaths from '../../routePaths';

const updateSAVAssignmentChannelsFlow = function* updateSAVAssignmentChannelsFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW);
        const {serviceCaseId, serviceAssignmentLineNo} = payload;
        const {serviceAssignmentData} = payload;
        const {location} = yield select(state => state.router);
        const dashboardScreenMatch = matchPath(location.pathname, {
            path: routePaths.DASHBOARD,
            exact: true,
        });

        // if the dashboardScreenMatch=true
        // service assignment was assigned successfully, no need to execute route unmount effect
        // all the serviceAssignmentData was already stored properly
        if (!dashboardScreenMatch) {
            yield* updateServiceAssignment({
                caller: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW},
        });
    }
};

export default updateSAVAssignmentChannelsFlow;
