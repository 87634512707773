import {take, put, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import updateServiceAssignment from './updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

const createServiceAssignmentPDFFlow = function* createServiceAssignmentPDFFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF);
        const {serviceCaseId, serviceAssignmentLineNo, serviceCaseData, serviceCaseType} = payload;
        const {serviceAssignmentData, serviceAssignmentType, nonAceMember, prefix, serviceType} = payload;
        const {attachmentsToSendWithAssignment} = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
            assignmentType: serviceAssignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        switch (serviceAssignmentType) {
            case (efServiceAssignmentTypes.VEHICLE): {
                if (nonAceMember || serviceCaseData?.damage?.additionalDescription) {
                    yield* updateServiceCase({
                        caller: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
                        caseType: serviceCaseType,
                        serviceCaseId,
                        serviceCaseData,
                    });
                }
                break;
            }
            case (efServiceAssignmentTypes.ACCOMMODATION):
            case (efServiceAssignmentTypes.RENTAL_CAR):
            case (efServiceAssignmentTypes.RETURN_JOURNEY):
            case (efServiceAssignmentTypes.SHORT_TRIP): {
                if (nonAceMember) {
                    yield* updateServiceCase({
                        caller: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
                        caseType: serviceCaseType,
                        serviceCaseId,
                        serviceCaseData,
                    });
                }
                break;
            }
            default: {
                if (serviceCaseData) {
                    yield* updateServiceCase({
                        caller: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
                        caseType: serviceCaseType,
                        serviceCaseId,
                        serviceCaseData,
                    });
                }
            }
        }


        yield put({
            type: serviceAssignmentActionTypes.SUBMIT_CREATE_PDF_FORM,
            payload: {
                serviceCaseId,
                serviceAssignmentLineNo,
                serviceType,
                prefix,
            },
        });
    }
};

export default createServiceAssignmentPDFFlow;
