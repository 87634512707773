import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {resolveRoute, replace} from '@computerrock/formation-router';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import routePaths from '../../routePaths';

/**
 * Create case log flow
 */
const createCaseLogNoteFlow = function* createCaseLogNoteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    while (true) {
        yield take(caseLogActionTypes.INITIATE_CREATE_CASE_LOG_NOTE_FLOW);

        yield* openModal(modalIds.CREATE_CASE_LOG_NOTE);
        const chosenModalOption = yield take([
            caseLogActionTypes.CONFIRM_CREATE_CASE_LOG_NOTE,
            caseLogActionTypes.DECLINE_CREATE_CASE_LOG_NOTE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === caseLogActionTypes.CONFIRM_CREATE_CASE_LOG_NOTE) {
            const {caseLogNoteData, serviceCaseId} = chosenModalOption.payload;

            yield fork(fetchRequest, caseLogActionTypes.CREATE_CASE_LOG_NOTE_REQUEST,
                ecsHistoryManagementService.createCaseLogNote, {
                    ...caseLogNoteData,
                });

            // We need to wait for a response because if more then one attachment is attached to note,
            // GET case logs request will be called too soon and no attachment will be shown (without refreshing).
            yield take([
                caseLogActionTypes.CREATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED,
                caseLogActionTypes.CREATE_CASE_LOG_NOTE_REQUEST_FAILED,
            ]);

            replace(resolveRoute(routePaths.SERVICE_CASE_SECTION, {serviceCaseId}, {search: ''}));
            yield* closeModal(modalIds.CREATE_CASE_LOG_NOTE);
            continue;
        }
        yield* closeModal(modalIds.CREATE_CASE_LOG_NOTE);
    }
};

export default createCaseLogNoteFlow;
