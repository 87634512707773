import {AccessControlPlugin} from '@ace-de/eua-user-access-control';
import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import ecsAuthorizationRoles from './application/ecsAuthorizationRoles';
import routePaths from './routePaths';

/**
 * Instantiate AccessControlPlugin for ECS auth layer
 *
 * @type {AccessControlPlugin}
 */
const ecsAccessControl = new AccessControlPlugin({
    name: 'ecsSystem',
    client: clientTypes.ECS_CLIENT,
    systemRoles: ecsAuthorizationRoles,
    systemAccessDeniedURI: routePaths.SYSTEM_ACCESS_DENIED,
    routeAccessDeniedURI: routePaths.ROUTE_ACCESS_DENIED,
    defaultLayoutRole: eupUserRoleTypes.EMERGENCY_CALL_ADVISOR,
});

export default ecsAccessControl;
