import React, {useState, useCallback, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useStyles, Panel, ToggleSwitch, Form, InputField, Divider} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import createDefaultAlternativeServiceProvider from '../createDefaultAlternativeServiceProvider';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import {validateEmail, validateFax} from '../../utils/validation';

const alternativeServiceProviderInitialState = createDefaultAlternativeServiceProvider();

const AlternativeServiceProviderPanel = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {isActive, serviceAssignment, serviceCase, onToggleSwitch} = props;
    const {isPartnerChangingDisabled, submitAlternativeServiceProviderForm} = props;

    const [alternativeServiceProviderData, setAlternativeServiceProviderData] = useState(
        serviceAssignment?.acePartner?.isManuallyAdded
            ? serviceAssignment.acePartner
            : alternativeServiceProviderInitialState,
    );
    const [emailError, setEmailErrorMessage] = useState([]);
    const [faxNoError, setFaxNoError] = useState([]);
    const disallowedSymbols = ['Tab', ' '];
    const hasErrorsRef = useRef(false);

    useEffect(() => {
        if (serviceAssignment?.acePartner && !serviceAssignment?.acePartner?.id && !hasErrorsRef.current) {
            setAlternativeServiceProviderData(serviceAssignment.acePartner);
        }
    }, [serviceAssignment?.acePartner]);

    const handleOnChange = formValues => {
        if (isActive) {
            if ((!formValues.businessContactDetails.email && emailError)
                || validateEmail(formValues.businessContactDetails.email)) {
                setEmailErrorMessage([]);
            }

            if (formValues.businessContactDetails.email && !validateEmail(formValues.businessContactDetails.email)) {
                setEmailErrorMessage([translate(
                    'alternative_service_provider_panel.error_message.please_enter_valid_email',
                )]);
            }

            if ((!formValues.businessContactDetails.faxNo && faxNoError)
                || validateFax(formValues.businessContactDetails.faxNo)) {
                setFaxNoError([]);
            }

            if (formValues.businessContactDetails.faxNo && !validateFax(formValues.businessContactDetails.faxNo)) {
                setFaxNoError([translate(
                    'alternative_service_provider_panel.error_message.please_enter_valid_fax',
                )]);
            }

            hasErrorsRef.current = faxNoError.length || emailError.length;

            setAlternativeServiceProviderData({
                ...formValues,
                location: {
                    ...alternativeServiceProviderData.location,
                    ...formValues.location,
                },
                businessContactDetails: {
                    ...alternativeServiceProviderData.businessContactDetails,
                    ...formValues.businessContactDetails,
                },
            });
        }
    };

    const switchAlternativeServiceProvider = () => {
        setAlternativeServiceProviderData(alternativeServiceProviderInitialState);
        setEmailErrorMessage([]);
        setFaxNoError([]);
        onToggleSwitch();
    };

    const handleOnTabChange = useCallback(formValues => {
        submitAlternativeServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                acePartner: {
                    ...formValues,
                    id: null, // alternative service provider does not have id
                    isManuallyAdded: isActive,
                },
            },
        });
    }, [
        isActive,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        serviceAssignment.assignmentType,
        submitAlternativeServiceProviderForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!isActive) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SUBMIT_ALTERNATIVE_SERVICE_PROVIDER_FORM,
            });
            return;
        }

        if (faxNoError.length || emailError.length) return;

        handleOnTabChange(alternativeServiceProviderData);
    }, [alternativeServiceProviderData, isActive, handleOnTabChange, emailError.length, faxNoError.length]);

    if (!serviceCase || !serviceAssignment) return null;

    return (
        <Panel
            title={translate('alternative_service_provider_panel.title.enter_alternative_service_provider')}
            actions={(
                <ToggleSwitch
                    name="toggleAlternativeServiceProvider"
                    value={true}
                    isSelected={isActive}
                    isDisabled={isPartnerChangingDisabled}
                    onChange={switchAlternativeServiceProvider}
                />
            )}
        >
            <Form name="alternativeServiceProviderForm" onChange={handleOnChange}>
                <p className={cx('global!ace-u-margin--bottom-24')}>
                    {translate('alternative_service_provider_panel.form_title.service_provider')}
                </p>
                <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-48'])}>
                    <InputField
                        className={cx('global!ace-u-grid-column--span-6')}
                        name="name"
                        value={alternativeServiceProviderData.name || ''}
                        label={translate('alternative_service_provider_panel.input_label.name')}
                        isDisabled={!isActive || isPartnerChangingDisabled}
                    />
                </div>
                <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-48'])}>
                    <Form name="businessContactDetails">
                        <InputField
                            className={cx('global!ace-u-grid-column--span-3')}
                            name="phoneNo"
                            value={alternativeServiceProviderData.businessContactDetails
                                ? alternativeServiceProviderData.businessContactDetails.phoneNo : ''}
                            label={translate('alternative_service_provider_panel.input_label.telephone')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                        />
                        <InputField
                            className={cx('global!ace-u-grid-column--span-3')}
                            name="email"
                            value={alternativeServiceProviderData.businessContactDetails
                                ? alternativeServiceProviderData.businessContactDetails.email : ''}
                            label={translate('alternative_service_provider_panel.input_label.email')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                            errors={emailError}
                            disallowedSymbols={disallowedSymbols}
                        />
                        <InputField
                            className={cx('global!ace-u-grid-column--span-3')}
                            name="faxNo"
                            value={alternativeServiceProviderData.businessContactDetails
                                ? alternativeServiceProviderData.businessContactDetails.faxNo : ''}
                            label={translate('alternative_service_provider_panel.input_label.fax')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                            errors={faxNoError}
                            disallowedSymbols={disallowedSymbols}
                        />
                    </Form>
                </div>
                <Divider />
                <p
                    className={cx('global!ace-u-margin--24-0')}
                >
                    {translate('alternative_service_provider_panel.text.address')}
                </p>
                <Form name="location">
                    <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-48'])}>
                        <InputField
                            className={cx('global!ace-u-grid-column--span-6')}
                            name="street"
                            value={alternativeServiceProviderData.location
                                ? alternativeServiceProviderData.location.street : ''}
                            label={translate('alternative_service_provider_panel.input_label.street')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                        />
                    </div>
                    <div className={cx('global!ace-u-grid')}>
                        <InputField
                            className={cx('global!ace-u-grid-column--span-2')}
                            name="postCode"
                            value={alternativeServiceProviderData.location
                                ? alternativeServiceProviderData.location.postCode : ''}
                            label={translate('alternative_service_provider_panel.input_label.post_code')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                        />
                        <InputField
                            className={cx('global!ace-u-grid-column--span-4')}
                            name="city"
                            value={alternativeServiceProviderData.location
                                ? alternativeServiceProviderData.location.city : ''}
                            label={translate('alternative_service_provider_panel.input_label.city')}
                            isDisabled={!isActive || isPartnerChangingDisabled}
                        />
                    </div>
                </Form>
            </Form>
        </Panel>
    );
};

AlternativeServiceProviderPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    isActive: PropTypes.bool.isRequired,
    isPartnerChangingDisabled: PropTypes.bool,
    onToggleSwitch: PropTypes.func.isRequired,
    submitAlternativeServiceProviderForm: PropTypes.func.isRequired,
};

AlternativeServiceProviderPanel.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
    isPartnerChangingDisabled: false,
};

const mapDispatchToProps = dispatch => ({
    submitAlternativeServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_ALTERNATIVE_SERVICE_PROVIDER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(AlternativeServiceProviderPanel);
