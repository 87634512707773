import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleWeightTypes, efServiceAssignmentTypes, apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import CostCoverageCommissioningPanel from './ui-elements/CostCoverageCommissioningPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageObjectsInVehiclePanel from './ui-elements/CostCoverageObjectsInVehiclePanel';
import CostCoverageVehicleDetailsPanel from './ui-elements/CostCoverageVehicleDetailsPanel';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sapActionTypes from './sapActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import config from '../config';
import VehicleInfoPanel from '../service-cases/ui-elements/VehicleInfoPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';


const getVehicleWeightType = vehicleWeight => {
    let weightType;

    switch (true) {
        case (vehicleWeight <= ampVehicleWeightTypes.LIGHT_WEIGHT_VEHICLE): {
            weightType = ampVehicleWeightTypes.LIGHT_WEIGHT_VEHICLE;
            break;
        }
        case (vehicleWeight <= ampVehicleWeightTypes.MEDIUM_WEIGHT_VEHICLE): {
            weightType = ampVehicleWeightTypes.MEDIUM_WEIGHT_VEHICLE;
            break;
        }
        default:
            weightType = ampVehicleWeightTypes.HEAVY_WEIGHT_VEHICLE;
    }

    return weightType;
};

const calculateCostPerWeightThreshold = weight => {
    let costPerWeightThreshold;

    switch (true) {
        case (weight <= ampVehicleWeightTypes.LIGHT_WEIGHT_VEHICLE): {
            costPerWeightThreshold = 'costPerLightWeightThreshold';
            break;
        }
        case (weight <= ampVehicleWeightTypes.MEDIUM_WEIGHT_VEHICLE): {
            costPerWeightThreshold = 'costPerMediumWeightThreshold';
            break;
        }
        case (weight <= ampVehicleWeightTypes.HEAVY_WEIGHT_VEHICLE): {
            costPerWeightThreshold = 'costPerHeavyWeightThreshold';
            break;
        }
    }

    return costPerWeightThreshold;
};

// TODO: Hidden feature >> uncomment all comments related to additionalNotes when feature is ready
const SAPCostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, users} = props;
    const {submitSAPCommissioningForm, submitSAPCostCoverageServiceProviderForm, submitCreateSAPPDF} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, pickupPrices} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [additionalInformationText, setAdditionalInformationText] = useState(serviceAssignment?.additionalInformationText || '');
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    // const [additionalNotes, setAdditionalNotes] = useState(serviceCase.damage.additionalNotes || '');

    const serviceAssignmentTypeKey = serviceAssignment.assignmentType.toLowerCase();
    const assignmentDefaultText = (serviceCase.prefix === `${config.ACE_COMMISSIONER_ID}`) ? translate(`global.default_assignment_text.${serviceAssignmentTypeKey}`) : '';
    const [assignmentText, setAssignmentText] = useState(
        serviceAssignment?.assignmentText === null
            ? assignmentDefaultText
            : serviceAssignment.assignmentText,
    );
    const hasTariffGroupChangedRef = useRef(false);
    const [pickupCostData, setPickupCostData] = useState({
        additionalCost: serviceAssignment?.additionalCost || '',
        calculatedPickupPrice: serviceAssignment?.pickupPrice || '',
        tariffGroup: serviceAssignment?.tariffGroup || '',
        weight: serviceAssignment?.weight || (serviceCase?.vehicle?.weight ? getVehicleWeightType(serviceCase.vehicle.weight) : ''),
        specialPickupPrice: serviceAssignment?.specialPickupPrice || '',
        toggleSpecialPickupPrice: !!serviceAssignment?.specialPickupPrice,
    });
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [routeDistance, setRouteDistance] = useState(serviceAssignment.routeDistance || '');
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    const roundToAtMost2Decimals = num => {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const calculateTotalCost = useCallback(() => {
        let totalCost = 0;
        const {additionalCost, calculatedPickupPrice, toggleSpecialPickupPrice, specialPickupPrice} = pickupCostData;
        if (additionalCost) totalCost += parseFloat(additionalCost);
        if (toggleSpecialPickupPrice && specialPickupPrice) {
            totalCost += (typeof specialPickupPrice === 'number')
                ? specialPickupPrice : parseFloat(specialPickupPrice);
            return roundToAtMost2Decimals(totalCost);
        }
        if (calculatedPickupPrice) {
            totalCost += (typeof calculatedPickupPrice === 'number')
                ? calculatedPickupPrice : parseFloat(calculatedPickupPrice);
        }
        return roundToAtMost2Decimals(totalCost);
    }, [pickupCostData]);

    useEffect(() => {
        setAdditionalInformationText(serviceAssignment.additionalInformationText);
        setCallbackPhoneNo(serviceAssignment.callbackPhoneNo);
    }, [serviceAssignment.additionalInformationText, serviceAssignment.callbackPhoneNo]);

    useEffect(() => {
        if (hasTariffGroupChangedRef.current && pickupCostData.weight && pickupCostData.tariffGroup) {
            const costPerWeightThreshold = calculateCostPerWeightThreshold(pickupCostData.weight);
            setPickupCostData(prevState => ({
                ...prevState,
                calculatedPickupPrice: pickupPrices?.[pickupCostData.tariffGroup]?.[costPerWeightThreshold].toFixed(2), // eslint-disable-line max-len
            }));
            hasTariffGroupChangedRef.current = false;
        }
    }, [pickupCostData.weight, pickupCostData.tariffGroup, serviceAssignment?.createdAt, pickupPrices]);

    useEffect(() => {
        if (serviceAssignment.pickupRouteDistance >= 0 && !serviceAssignment.tariffGroup) {
            const tariffGroup = Object.entries(pickupPrices).find(([key, value]) => (
                serviceAssignment.pickupRouteDistance >= value.distanceFrom
                && serviceAssignment.pickupRouteDistance <= value.distanceTo
            ))?.[0];
            setPickupCostData(prevState => ({
                ...prevState,
                tariffGroup: tariffGroup,
            }));
            setRouteDistance(Math.round(serviceAssignment.pickupRouteDistance));
            hasTariffGroupChangedRef.current = true;
        }
    }, [serviceAssignment.pickupRouteDistance, serviceAssignment.tariffGroup, pickupPrices]);

    const handlePickupCostDataChange = formValues => {
        const {weight, tariffGroup, calculatedPickupPrice} = formValues;
        const costPerWeightThreshold = calculateCostPerWeightThreshold(weight);

        setPickupCostData({
            ...formValues,
            calculatedPickupPrice: costPerWeightThreshold && tariffGroup
                ? pickupPrices?.[tariffGroup]?.[costPerWeightThreshold].toFixed(2)
                : calculatedPickupPrice,
            toggleSpecialPickupPrice: !!formValues.toggleSpecialPickupPrice,
            specialPickupPrice: formValues.toggleSpecialPickupPrice
                ? formValues.specialPickupPrice : '',
        });
    };

    const onChangeHandler = (key, value) => {
        if (key === 'additionalInformationText') {
            setAdditionalInformationText(value);
            return;
        }

        // if (key === 'additionalNotes') {
        //     setAdditionalNotes(value);
        //     return;
        // }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
                selfPayerNote: '',
            });
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }
    };

    const handleTabChange = useCallback(() => {
        submitSAPCommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalInformationText,
                assignmentText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        submitSAPCommissioningForm,
        additionalInformationText,
        assignmentText,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        attachmentsToSendWithAssignment,
    ]);

    useRouteUnmountEffect(() => {
        handleTabChange();
    }, [handleTabChange]);

    const handleAssignmentSubmit = costCoverageData => {
        const {tariffGroup} = pickupCostData;

        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            additionalInformationText,
            assignmentText,
            callbackPhoneNo,
            routeDistance: serviceAssignment.pickupLocation && serviceAssignment.destination
            && serviceAssignment.pickupRouteDistance
                ? Math.round(serviceAssignment.pickupRouteDistance)
                : routeDistance,
            totalCost: calculateTotalCost(),
            tariffGroup: tariffGroup,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            acePartner: {
                ...costCoverageData.acePartner,
                rank: serviceAssignment?.acePartner?.rank || null,
            },
        };

        submitSAPCostCoverageServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
            qmFeedbackDraft: serviceAssignment.qmFeedbackDraft,
            assignmentType: efServiceAssignmentTypes.PICKUP,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    const submitCreatePDF = serviceProviderData => {
        const {tariffGroup} = pickupCostData;
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            additionalInformationText,
            assignmentText,
            callbackPhoneNo,
            routeDistance: serviceAssignment.pickupLocation && serviceAssignment.destination
            && serviceAssignment.pickupRouteDistance
                ? Math.round(serviceAssignment.pickupRouteDistance)
                : routeDistance,
            totalCost: calculateTotalCost(),
            tariffGroup: tariffGroup,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            ...serviceProviderData,
        };

        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);

        submitCreateSAPPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData,
            qmFeedbackDraft: serviceAssignment.qmFeedbackDraft,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    onChangeHandler={onChangeHandler}
                    selfPayer={selfPayer}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    additionalInformationText={additionalInformationText}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageCommissioningPanel
                    onChangeHandler={onChangeHandler}
                    assignmentText={assignmentText}
                    pickupCostData={pickupCostData}
                    routeDistance={routeDistance}
                    handlePickupCostDataChange={handlePickupCostDataChange}
                    calculateTotalCost={calculateTotalCost}
                    // additionalNotes={additionalNotes}
                />
                <VehicleInfoPanel />
                <CostCoverageVehicleDetailsPanel />
                <CostCoverageObjectsInVehiclePanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    assignmentChannels={[
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                    ]}
                    handleOnAssignmentSubmit={handleAssignmentSubmit}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SAPCostCoverageTab.propTypes = {
    submitSAPCommissioningForm: PropTypes.func.isRequired,
    submitCreateSAPPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSAPCostCoverageServiceProviderForm: PropTypes.func,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
    pickupPrices: PropTypes.object,
};

SAPCostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    submitSAPCostCoverageServiceProviderForm: () => {},
    hasToResetAttachmentSelection: false,
    pickupPrices: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
        pickupPrices: state.serviceAssignments.pickupPrices,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAPCommissioningForm: payload => dispatch({
        type: sapActionTypes.SUBMIT_SAP_COST_COVERAGE_FORM,
        payload,
    }),
    submitSAPCostCoverageServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSAPPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAPCostCoverageTab));
