import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea} from '@ace-de/ui-components';

const CostCoverageCaseDescriptionPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sala_cost_coverage_tab');
    const {description, onChangeHandler, legalClaim} = props;

    return (
        <Panel
            title={(
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <p>{translateTab('panel_title.case_description')}</p>
                </div>
            )}
            qaIdent="sala-cost-coverage-case-description"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-case-description"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={description}
                    onChange={value => onChangeHandler('description', value)}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.is_case_traffic_law')}
                qaIdent="sa-is-case-traffic-law"
            >
                {typeof legalClaim === 'boolean'
                    ? legalClaim
                        ? translateTab('data_row_label.yes')
                        : translateTab('data_row_label.no')
                    : '-'}
            </DataRow>
        </Panel>
    );
};

CostCoverageCaseDescriptionPanel.propTypes = {
    description: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    legalClaim: PropTypes.any,
};

CostCoverageCaseDescriptionPanel.defaultProps = {
    description: '',
    legalClaim: null,
};

export default CostCoverageCaseDescriptionPanel;
