/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_OTHER_SERVICES = '[service-cases] CREATE_SERVICE_ASSIGNMENT_OTHER_SERVICES';
export const SUBMIT_SAOS_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SAOS_REQUIREMENTS_FORM';
export const SUBMIT_SAOS_DAMAGE_LOCATION_FORM = '[service-cases] SUBMIT_SAOS_DAMAGE_LOCATION_FORM';
export const SUBMIT_SAOS_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SAOS_COST_COVERAGE_FORM';
export const SUBMIT_SAOS_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SAOS_SERVICE_PROVIDER_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAOS_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED';

/**
 * Store action types
 */
