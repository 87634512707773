import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sastActionTypes from '../sastActionTypes';

/**
 * Update SAST service provider flow
 */
const updateSASTServiceProviderFlow = function* updateSASTServiceProviderFlow() {
    while (true) {
        const {payload} = yield take(sastActionTypes.SUBMIT_SAST_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: sastActionTypes.SUBMIT_SAST_SERVICE_PROVIDER_FORM,
            assignmentType: efServiceAssignmentTypes.SHORT_TRIP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sastActionTypes.SUBMIT_SAST_SERVICE_PROVIDER_FORM},
        });
    }
};

export default updateSASTServiceProviderFlow;
