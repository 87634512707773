import {all, fork, takeLatest} from 'redux-saga/effects';
import * as savActionTypes from './savActionTypes';
import createServiceAssignmentVehicleFlow from './sagas/createServiceAssignmentVehicleFlow';
import selectDamageDecisionTreeOptionsFlow from './sagas/selectDamageDecisionTreeOptionsFlow';
import savServiceTypeSelectionFlow from './sagas/savServiceTypeSelectionFlow';
import updateSAVDamageSymptomsFlow from './sagas/updateSAVDamageSymptomsFlow';
import updateSAVDamageDescriptionFlow from './sagas/updateSAVDamageDescriptionFlow';
import searchSAVDamageLocationGeolocation from './sagas/searchSAVDamageLocationGeolocation';
import savMapDamageLocationWatcher from './sagas/savMapDamageLocationWatcher';
import updateSAVDamageLocationFlow from './sagas/updateSAVDamageLocationFlow';
import updateSAVDamageLocationInfoFlow from './sagas/updateSAVDamageLocationInfoFlow';
import selectSAVContractPartnerFlow from './sagas/selectSAVContractPartnerFlow';
import updateSAVCommissioningFlow from './sagas/updateSAVCommissioningFlow';
import updateSAVVehicleOwnerAndDriverFlow from './sagas/updateSAVVehicleOwnerAndDriverFlow';
import updateSAVVehicleDamageNotes from './sagas/updateSAVVehicleDamageNotesFlow';
import searchSAVTowingDestinations from './sagas/searchSAVTowingDestinations';
import selectSAVTowingDestinationFlow from './sagas/selectSAVTowingDestinationFlow';
import savMapTowingDestinationWatcher from './sagas/savMapTowingDestinationWatcher';
import savMapContractPartnerWatcher from './sagas/savMapContractPartnerWatcher';
import updateSAVTrailerDataFlow from './sagas/updateSAVTrailerDataFlow';
import createPDFFlow from '../service-assignments/sagas/createPDFFlow';
import searchSAVContractPartners from './sagas/searchSAVContractPartners';
import searchSAVReverseDamageLocationGeolocation from './sagas/searchSAVReverseDamageLocationGeolocation';
import updateSAVAssignmentChannelsFlow from './sagas/updateSAVAssignmentChannelsFlow';
import updateSAVAssignmentStatus from './sagas/updateSAVAssignmentStatus';
import savCreateVBAPartnerQMFeedbackDraft from './sagas/savCreateVBAPartnerQMFeedbackDraft';

/**
 *  SAV watcher saga
 */
const savWatcher = function* savWatcher() {
    yield all([
        fork(createServiceAssignmentVehicleFlow),
        fork(selectDamageDecisionTreeOptionsFlow),
        fork(savServiceTypeSelectionFlow),
        fork(updateSAVDamageSymptomsFlow),
        fork(updateSAVVehicleOwnerAndDriverFlow),
        takeLatest(savActionTypes.SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION, searchSAVDamageLocationGeolocation),
        takeLatest(
            savActionTypes.SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION,
            searchSAVReverseDamageLocationGeolocation,
        ),
        fork(savMapDamageLocationWatcher),
        fork(savMapTowingDestinationWatcher),
        fork(savMapContractPartnerWatcher),
        fork(updateSAVDamageDescriptionFlow),
        fork(updateSAVDamageLocationFlow),
        fork(updateSAVDamageLocationInfoFlow),
        takeLatest(savActionTypes.SEARCH_SAV_TOWING_DESTINATIONS, searchSAVTowingDestinations),
        fork(selectSAVContractPartnerFlow),
        fork(updateSAVCommissioningFlow),
        fork(updateSAVVehicleDamageNotes),
        fork(selectSAVTowingDestinationFlow),
        fork(updateSAVTrailerDataFlow),
        fork(createPDFFlow),
        takeLatest(savActionTypes.SEARCH_SAV_CONTRACT_PARTNERS, searchSAVContractPartners),
        fork(updateSAVAssignmentChannelsFlow),
        fork(updateSAVAssignmentStatus),
        fork(savCreateVBAPartnerQMFeedbackDraft),
    ]);
};

export default savWatcher;
