import {call, fork, put, select, take} from 'redux-saga/effects';
import {apmACEPartnerServiceTypes, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import * as samaActionTypes from '../samaActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';

const searchSAMAServiceProviders = function* searchSAMAServiceProviders() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(samaActionTypes.SEARCH_SAMA_SERVICE_PROVIDERS);
        const {serviceAssignment} = payload;
        const {serviceCaseId} = serviceAssignment;
        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');
        if (!arcGISMap) return;
        const samaServiceProvidersLayer = yield call(arcGISMap.getLayer, 'sama-service-providers-layer');

        if (!samaServiceProvidersLayer) return;

        yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);

        yield put({
            type: samaActionTypes.STORE_SAMA_SERVICE_PROVIDERS,
            payload: {medicalAdviceServiceProviders: []},
        });
        yield call(samaServiceProvidersLayer.setFeatures, {features: []});

        yield fork(
            fetchRequest,
            samaActionTypes.FETCH_SERVICE_PROVIDERS_REQUEST,
            partnerManagementService.getACEPartnersV2,
            {
                partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                serviceType: apmACEPartnerServiceTypes.MEDICAL_ADVICE,
            },
        );

        const responseAction = yield take([
            samaActionTypes.FETCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED,
            samaActionTypes.FETCH_SERVICE_PROVIDERS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTOs} = response;
            const referentialPoint = serviceAssignment.serviceLocation;

            const serviceProviderAddressList = [];
            acePartnerDTOs.forEach((acePartnerDTO, index) => {
                if (acePartnerDTO.address) {
                    serviceProviderAddressList.push({
                        'OBJECTID': index,
                        'SingleLine': [
                            acePartnerDTO.address?.street,
                            acePartnerDTO.address?.postCode,
                            acePartnerDTO.address?.city,
                        ].filter(value => !!value).join(', '),
                    });
                }
            });

            yield fork(
                fetchRequest,
                samaActionTypes.FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST,
                arcGISRESTService.searchBulkAddressLocations,
                {
                    addressList: serviceProviderAddressList,
                    returnRoutes: true,
                    referentialPoint,
                },
            );

            const fetchGeolocationsResponseAction = yield take([
                samaActionTypes.FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_SUCCEEDED,
                samaActionTypes.FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_FAILED,
            ]);

            if (fetchGeolocationsResponseAction.error) {
                yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
                return;
            }

            const {response: fetchGeolocationResponse} = fetchGeolocationsResponseAction.payload;
            const {arcGISGeocodingResults} = fetchGeolocationResponse;
            const medicalAdviceServiceProviders = [];

            acePartnerDTOs.forEach((acePartnerDTO, index) => {
                const arcGISGeocodingCandidateDTO = arcGISGeocodingResults.find(arcGISGeocodingCandidateDTO => {
                    return arcGISGeocodingCandidateDTO.resultId === index;
                });
                if (arcGISGeocodingCandidateDTO) {
                    medicalAdviceServiceProviders.push(
                        {
                            ...acePartnerDTO,
                            arcGISData: arcGISGeocodingCandidateDTO,
                            routeToReferentialPoint: arcGISGeocodingCandidateDTO.routeToReferentialPoint,
                        },
                    );
                }
            });

            yield call(samaServiceProvidersLayer.setFeatures, {
                features: medicalAdviceServiceProviders.map((medicalAdviceServiceProvider, index) => {
                    let geometry = medicalAdviceServiceProvider.arcGISData['arcGISPoint'];
                    if (!geometry && medicalAdviceServiceProvider.arcGISData['longitude'] && medicalAdviceServiceProvider.arcGISData['latitude']) {
                        geometry = {
                            type: 'point',
                            x: medicalAdviceServiceProvider.arcGISData['longitude'],
                            y: medicalAdviceServiceProvider.arcGISData['latitude'],
                        };
                    }
                    if (!geometry) return null;
                    return {
                        attributes: {
                            FID: index,
                            locationId: medicalAdviceServiceProvider.arcGISData['locationId'],
                        },
                        geometry,
                    };
                }).filter(Boolean),
            });

            if (serviceAssignment.acePartner?.location?.id) {
                yield call(samaServiceProvidersLayer.selectFeatureByAttribute, {
                    where: `locationId = '${serviceAssignment.acePartner.location.id}'`,
                });
            }

            yield put({
                type: samaActionTypes.STORE_SAMA_SERVICE_PROVIDERS,
                payload: {medicalAdviceServiceProviders},
            });

            // display layers
            samaServiceProvidersLayer.show();
            yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
        }
    }
};

export default searchSAMAServiceProviders;
