import {createSelector} from 'reselect';

/**
 * Members selectors
 */
export const getMembers = state => state.members.members;
export const getMembersByMembershipNo = state => state.members.membersByMembershipNo;
export const getVehicleSearchResults = state => state.members.vehicleSearchResults;
export const getVehicles = state => state.members.vehicles;
export const getServiceCases = state => state.serviceCases.serviceCases;
export const getMemberSearchResults = state => state.members.memberSearchResults;

export const createMemberByMembershipNoSelector = () => createSelector(
    [
        getMembers,
        getMembersByMembershipNo,
        (state, props) => {
            const {match} = props;
            return match?.params['membershipNo'];
        },
    ],
    (members, membersByMembershipNo, membershipNo) => {
        if (!membershipNo) return null;

        return membersByMembershipNo[membershipNo];
    },
);

export const createMemberSearchResultsByMembershipNoSelector = () => createSelector(
    [
        getMemberSearchResults,
        (state, props) => {
            const {match} = props;
            return match?.params['membershipNo'];
        },
    ],
    (memberSearchResults, membershipNo) => {
        if (!membershipNo) return null;

        return memberSearchResults.find(member => member.membershipNo === membershipNo) || null;
    },
);

export const createMemberVehiclesSelector = () => createSelector(
    [
        createMemberByMembershipNoSelector(),
        getVehicles,
    ],
    (member, vehicles) => {
        const memberVehicles = [];
        if (!member) return memberVehicles;

        member.vehicleIds.forEach(vehicleId => {
            if (vehicles[vehicleId]) memberVehicles.push(vehicles[vehicleId]);
        });

        return memberVehicles;
    },
);

export const createMemberServiceCasesSelector = () => createSelector(
    [
        createMemberByMembershipNoSelector(),
        getServiceCases,
    ],
    (member, serviceCases) => {
        if (!member) return [];

        return member.serviceCaseIds.map(serviceCaseId => serviceCases[serviceCaseId]).filter(Boolean);
    },
);

export const createVehicleByIdSelector = () => createSelector(
    [
        getVehicles,
        (state, props) => {
            const {location} = props;
            const queryParams = new URLSearchParams(location?.search || '');
            return queryParams.get('vehicleId') || null;
        },
    ],
    (vehicles, vehicleId) => {
        if (!vehicles || !vehicleId) return null;

        return vehicles[vehicleId];
    },
);
