import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {ampVehicleCreationStatusTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';

/**
 * Update service case vehicle data flow
 * updates the vehicle snapshot on a service case level
 */
const updateServiceCaseVehicleDataFlow = function* updateServiceCaseVehicleDataFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM);
        const {serviceCaseData, serviceCaseId, caseType} = payload;

        // if no service case data, continue execution of the loop with the next iteration
        if (!serviceCaseData) continue;

        const updateServiceCaseResponseAction = yield* updateServiceCase({
            caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM,
            caseType,
            serviceCaseId,
            serviceCaseData,
        });

        if (updateServiceCaseResponseAction?.error) continue; // skip to the next iteration

        const {serviceCaseDTO} = updateServiceCaseResponseAction?.payload?.response || {};

        // We are registering routeUnmountEffect for the VehicleCreatePanel
        // where the caller is 'SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM'
        // Since that panel exists only for non-ACE member or in the FBS,
        // we have to call ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED conditionally
        if (config.IS_FALLBACK_SYSTEM
            || (serviceCaseDTO?.nonAceMember
                && serviceCaseData?.vehicle?.creationStatus === ampVehicleCreationStatusTypes.DRAFT)
        ) {
            yield put({
                type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
                payload: {caller: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM},
            });
        }
    }
};

export default updateServiceCaseVehicleDataFlow;
