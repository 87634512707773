/**
 * Calculates rental car additional service price for the given rental car pricing, number of days
 * and isDropOffDateExtended
 *
 * @param {Object} rentalCarPricing
 * @param {number} numberOfDays
 * @param {boolean} isDropOffDateExtended
 */
const calculateAdditionalServicePrice = (rentalCarPricing, numberOfDays) => {
    const oneDayPricing = rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 1);
    if (oneDayPricing) {
        return oneDayPricing.isFlatPrice && oneDayPricing.price
            ? oneDayPricing.price
            : numberOfDays * oneDayPricing.price;
    }

    return 0;
};

export default calculateAdditionalServicePrice;
