import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saosActionTypes from '../saosActionTypes';

/**
 * Update SAOS requirements data flow
 */
const updateSAOSRequirementsDataFlow = function* updateSAOSRequirementsDataFlow() {
    while (true) {
        const {payload} = yield take(saosActionTypes.SUBMIT_SAOS_REQUIREMENTS_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: saosActionTypes.SUBMIT_SAOS_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.OTHER_SERVICES,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saosActionTypes.SUBMIT_SAOS_REQUIREMENTS_FORM},
        });
    }
};

export default updateSAOSRequirementsDataFlow;
