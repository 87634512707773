import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';

const loadSAAMapLayers = function* loadSAAMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-accommodation');

    if (!arcGISMap.isLayerSet('saa-accommodation-locations')) {
        arcGISMap.createLayer({
            layerId: 'saa-accommodation-locations',
            featureEntityType: euaFeatureEntityTypes.ACCOMMODATION_LOCATION,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.ACCOMMODATION_LOCATION,
            isVisible: false,
        });
    }
};

export default loadSAAMapLayers;
