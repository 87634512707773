import {take, fork, cancel} from 'redux-saga/effects';
import * as samcActionTypes from '../samcActionTypes';
import onSAMCMapClickFetchDamageLocationAddress from './onSAMCMapClickFetchDamageLocationAddress';

const samcMapDamageLocationWatcher = function* samcMapDamageLocationWatcher() {
    while (true) {
        const {payload} = yield take(samcActionTypes.START_SAMC_MAP_DAMAGE_LOCATION_WATCHER);
        const {serviceAssignmentId} = payload;
        const onSAMCMapClickFetchDamageLocationAddressTask = yield fork(onSAMCMapClickFetchDamageLocationAddress, {
            serviceAssignmentId,
        });

        yield take(samcActionTypes.STOP_SAMC_MAP_DAMAGE_LOCATION_WATCHER);
        yield cancel(onSAMCMapClickFetchDamageLocationAddressTask);
    }
};

export default samcMapDamageLocationWatcher;
