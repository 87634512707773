import {put, select, take, fork} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import savScreenTabs from '../savScreenTabs';
import serviceCaseScreenTabs from '../../service-cases/serviceCaseScreenTabs';

const loadServiceTypeDamageNodes = function* loadServiceTypeDamageNodes() {
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.DAMAGE_AND_SERVICE && activeTab !== savScreenTabs.CONTRACT_PARTNER
        && activeTab !== serviceCaseScreenTabs.ASSIGNMENTS && activeTab !== serviceCaseScreenTabs.BASIC_DATA) return;

    const {serviceTypeDamageNodes} = yield select(state => state.serviceCases);
    if (Object.keys(serviceTypeDamageNodes).length > 0) return;

    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST,
        ecsFlowService.getServiceTypeDamageNodes,
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_FAILED,
        serviceCaseActionTypes.FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {damageNodeDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_TYPE_DAMAGE_NODES,
            payload: {damageNodeDTOs},
        });
    }
};

export default loadServiceTypeDamageNodes;
