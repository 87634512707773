import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import CaseProtocolPanel from '../case-logs/ui-elements/CaseProtocolPanel';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import FilterPanel from '../case-logs/ui-elements/FilterPanel';

const SCProtocolTab = props => {
    const {cx} = useStyles();
    const {serviceCase, serviceAssignments} = props;
    const {caseLogFilterResults, caseLogFilterResultsCount, caseLogFilterResultsPage} = props;
    const {id} = serviceCase;
    const caseLogAssignments = serviceAssignments?.map(assignment => (
        assignment.assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES ? (
            assignment.serviceType ? assignment.serviceType : assignment.assignmentType
        ) : assignment.assignmentType
    )).filter((assignment, id, self) => assignment && self.indexOf(assignment) === id);

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CaseProtocolPanel
                    serviceCase={serviceCase}
                    caseLogFilterResults={caseLogFilterResults}
                    caseLogFilterResultsCount={caseLogFilterResultsCount}
                    caseLogFilterResultsPage={caseLogFilterResultsPage}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <FilterPanel
                    serviceCaseId={id}
                    caseLogAssignments={caseLogAssignments}
                    serviceAssignments={serviceAssignments}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SCProtocolTab.propTypes = {
    serviceCase: PropTypes.object,
    caseLogFilterResults: PropTypes.array,
    serviceAssignments: PropTypes.array,
    caseLogFilterResultsCount: PropTypes.number,
    caseLogFilterResultsPage: PropTypes.number,
};

SCProtocolTab.defaultProps = {
    serviceCase: null,
    serviceAssignments: null,
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        caseLogFilterResults: serviceCaseSelectors.getCaseLogFilterResults(state),
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
        caseLogFilterResultsCount: serviceCaseSelectors.getCaseLogFilterResultsCount(state),
        caseLogFilterResultsPage: serviceCaseSelectors.getCaseLogFilterResultsPage(state),
    };
};

export default withRouter(connect(mapStateToProps, null)(SCProtocolTab));
