import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savActionTypes from '../savActionTypes';
import savScreenTabs from '../savScreenTabs';

const loadSAVMapContractPartnerWatcher = function* loadSAVMapContractPartnerWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.CONTRACT_PARTNER) return;

    yield put({
        type: savActionTypes.START_SAV_MAP_CONTRACT_PARTNER_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAVMapContractPartnerWatcher;
