import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {positions} from '@computerrock/formation-ui/overlays';
import {useTranslate} from '@computerrock/formation-i18n';
import {efRentalCarSubStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonSecondary, DateField, Form} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, calendarIcon} from '@ace-de/ui-components/icons';
import * as sarcActionTypes from '../sarcActionTypes';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const SARCExtendDropOffDateModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('sarc_extend_dropoff_date_modal');
    const {hasBackdrop, serviceAssignment} = props;
    const {declineSARCExtendDropOffDate, confirmSARCExtendDropOffDate} = props;

    const handleOnExtend = formValues => {
        if (!formValues.dropOffDate) return;

        const serviceAssignmentSubStatusData = {
            subStatus: efRentalCarSubStatusTypes.EXTEND,
            extendedDate: moment(formValues.dropOffDate),
        };

        confirmSARCExtendDropOffDate({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentSubStatusData,
        });
    };

    if (!serviceAssignment) return null;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSARCExtendDropOffDate}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={translateModal('title.extend')}
        >
            <div
                className={cx([
                    'global!ace-u-margin--top-32',
                    'global!ace-u-full-width',
                    'global!ace-u-typography--variant-body',
                ])}
            >
                <p className={cx('global!ace-u-margin--bottom-24')}>{translateModal('text.note')}</p>
                <Form name="rentalCarExtensionForm" onSubmit={handleOnExtend}>
                    <DateField
                        name="dropOffDate"
                        label={translateModal('date_label.return')}
                        value={serviceAssignment?.dropOffDate || ''}
                        placeholder={translateModal('date_placeholder.please_enter')}
                        icon={calendarIcon}
                        locale={activeLocale}
                        minDate={serviceAssignment.dropOffDate || undefined}
                        format="DD.MM.YYYY, HH:mm"
                        timeOptionsDirection={positions.DIRECTION_TOP}
                    />
                    <div className={cx(['global!ace-u-margin--top-32', 'global!ace-u-flex', 'global!ace-u-flex--direction-row-reverse'])}>
                        <ButtonSecondary name="extendDropOffDateButton" type="submit">
                            {translateModal('button.confirm')}
                        </ButtonSecondary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

SARCExtendDropOffDateModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineSARCExtendDropOffDate: PropTypes.func.isRequired,
    confirmSARCExtendDropOffDate: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
};

SARCExtendDropOffDateModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentsSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineSARCExtendDropOffDate: payload => dispatch({
        type: sarcActionTypes.DECLINE_SARC_EXTEND_DROPOFF_DATE,
    }),
    confirmSARCExtendDropOffDate: payload => dispatch({
        type: sarcActionTypes.CONFIRM_SARC_EXTEND_DROPOFF_DATE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SARCExtendDropOffDateModal);
