import {produce} from 'immer';

/**
 * Initial application domain state
 *
 * @type {Object}
 */
const initialState = {
    serviceManager: null,
};

/**
 * Application reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const applicationReducer = produce((draft, action) => {
    switch (action.type) {
        // TODO: handle actions

        default:
        // no-op
    }
}, initialState);

export default applicationReducer;
