/**
 * Command action types
 */
export const RETRY_REQUEST = '[application] RETRY_REQUEST';
export const CANCEL_REQUEST = '[application] CANCEL_REQUEST';

/**
 * Store action types
 */
export const SET_REQUEST_STATE = '[application] SET_REQUEST_STATE';
