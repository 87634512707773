import React, {Fragment, useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase, snakeCase} from 'change-case';
import moment from 'moment';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceCaseStatusTypes, ampMembershipTypes, efServiceCaseContactChannelTypes, efServiceTypes, efOtherServiceTypes, efServiceAssignmentTypes, apmContractPartnerContractStatusTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Pill, NoResultsBlock, calendarIcon, AutocompleteField, Paginator, Tooltip, Badge, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import {Form, InputField, DateField, SelectField, Option, ButtonIcon} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, searchIcon, findCaseIcon, InteractiveIcon, resetIcon, deleteIcon, arrowDownIcon, arrowUpIcon} from '@ace-de/ui-components/icons';
import routePaths from '../routePaths';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as contractPartnerSelectors from '../contract-partners/contractPartnerSelectors';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import * as requestStateSelectors from '../application/requestStateSelectors';
import config from '../config';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';
import ecsAccessControl from '../ecsAccessControl';
import FailedRequestBlock from '../application/ui-elements/FailedRequestBlock';

const initialServiceCaseFilteringParams = {
    searchTerm: '',
    createdAfter: '',
    createdBefore: '',
    caseStatus: '',
    damageLocation: '',
    contactChannel: '',
    licensePlateNumber: '',
    memberName: '',
    commissionerId: '',
    serviceType: '',
    agentId: '',
    acePartnerId: '',
    membershipNo: '',
};

const ServiceCaseSearchScreen = props => {
    const {cx} = useStyles();
    const resetRef = useRef(false);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('service_case_search_screen');
    const {history, serviceCaseSearchResults, serviceCaseSearchResultsCount, users, commissioners} = props;
    const {contractPartners, initiateServiceCaseDeletionFlow, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState(initialServiceCaseFilteringParams);
    const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);
    const resetAdvancedFiltersRef = useRef(false);
    const allServiceTypes = [
        ...Object.keys(efServiceTypes),
        efServiceAssignmentTypes.MEDICAL_CLEARANCE,
        efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
        efServiceAssignmentTypes.LEGAL_ADVICE,
        efServiceAssignmentTypes.MEDICAL_ADVICE,
        efServiceAssignmentTypes.MEDICINE_SHIPMENT,
        efServiceAssignmentTypes.DEATH,
        ...Object.keys(efOtherServiceTypes),
    ];
    const paginatorCount = Math.ceil(serviceCaseSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const isCaseDeletionAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.DELETE,
    );
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    // For sorting strings with non-ASCII characters or strings from languages other than English, use localeCompare
    const sortedCommissioners = useMemo(() => (
        Object.values(commissioners).sort((commissionerA, commissionerB) => {
            const commissionerAName = (commissionerA.name || '').toLowerCase();
            const commissionerBName = (commissionerB.name || '').toLowerCase();
            return commissionerAName.localeCompare(commissionerBName);
        })
    ), [commissioners]);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'caseStatus') {
                    apiQueryParams.append(`${formField}`, formData[formField]);
                    return;
                }

                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) {
            return;
        }

        const createdAfter = formValues.createdAfter && moment(formValues.createdAfter).isValid()
            ? moment(formValues.createdAfter).startOf('day').format()
            : formValues.createdAfter;

        const createdBefore = formValues.createdBefore && moment(formValues.createdBefore).isValid()
            ? moment(formValues.createdBefore).endOf('day').format()
            : formValues.createdBefore;

        const apiQueryParams = formatQueryParams({
            ...formValues,
            createdAfter,
            createdBefore,
        });
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_CASE_SEARCH, {}, {search: queryParamsString}));
    };

    const onChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        // form data should contain all fields, even if they are not visible (advanced search dropdown is not expanded)
        // with this approach, we are avoiding having undefined as a value for non-visible fields
        setFilterFormData({
            ...initialServiceCaseFilteringParams,
            ...(!resetAdvancedFiltersRef.current ? formValues : {}),
        });

        if (resetAdvancedFiltersRef.current) {
            resetAdvancedFiltersRef.current = false;
        }
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        resetAdvancedFiltersRef.current = true;
        setFilterFormData(initialServiceCaseFilteringParams);
        history.push(resolveRoute(routePaths.SERVICE_CASE_SEARCH, {}, {search: ''}));
    };

    const openServiceCaseScreen = serviceCase => {
        const previousPath = {previousPath: history.location.pathname};
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceCase.id,
        }, {
            state: previousPath,
        }));
    };

    const formatCreatedBeforeDate = () => {
        if (queryParams.get('createdBefore')) {
            const date = queryParams.get('createdBefore');
            return moment(date).isValid() ? moment(date, 'YYYY-MM-DD').format() : date;
        }
        return filterFormData.createdBefore;
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_CASE_SEARCH, {}, {search: queryParamsString}));
    };

    const expandFilters = () => {
        setAreFiltersExpanded(!areFiltersExpanded);
    };

    const handleCaseDeletion = (event, serviceCaseId) => {
        event.stopPropagation();
        if (!serviceCaseId) return;
        initiateServiceCaseDeletionFlow({serviceCaseId});
    };

    return (
        <Fragment>
            <Panel
                title={translateScreen('panel.title')}
                contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}
            >
                <Form name="serviceCasesSearchForm" onSubmit={handleOnSubmit} onChange={onChange}>
                    {formValues => (
                        <>
                            <div className={cx('global!ace-u-grid')}>
                                <div className={cx('global!ace-u-grid-column--span-5')}>
                                    <InputField
                                        label={translateScreen('input_field_label.free_search')}
                                        name="searchTerm"
                                        value={queryParams.get('searchTerm') || filterFormData.searchTerm}
                                        className={cx('global!ace-u-full-width')}
                                        onKeyDown={() => handleOnSubmit(formValues)}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-2')}>
                                    <AutocompleteField
                                        label={translateScreen('select_field_label.contract_partner')}
                                        name="acePartnerId"
                                        className={cx('global!ace-u-full-width')}
                                        value={queryParams.get('acePartnerId') || filterFormData.acePartnerId}
                                        isArrowIconDisplayed={true}
                                    >
                                        {!!contractPartners && Object.values(contractPartners).map(contractPartner => {
                                            return (
                                                <Option
                                                    key={contractPartner.id}
                                                    name={`contractPartnerOption-${contractPartner.id}`}
                                                    value={contractPartner.id}
                                                >
                                                    {contractPartner.name}
                                                </Option>
                                            );
                                        })}
                                    </AutocompleteField>
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-1')}>
                                    <DateField
                                        label={translateScreen('date_field_label.date_from')}
                                        name="createdAfter"
                                        value={queryParams.get('createdAfter') || filterFormData.createdAfter}
                                        icon={calendarIcon}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-1')}>
                                    <DateField
                                        label={translateScreen('date_field_label.date_to')}
                                        name="createdBefore"
                                        value={formatCreatedBeforeDate()}
                                        icon={calendarIcon}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-2')}>
                                    <SelectField
                                        placeholder={translateScreen('select_field_placeholder.status')}
                                        label={translateScreen('select_field_label.status')}
                                        name="caseStatus"
                                        className={cx('global!ace-u-full-width')}
                                        value={queryParams.get('caseStatus') || filterFormData.caseStatus}
                                    >
                                        {Object.keys(efServiceCaseStatusTypes).filter(statusType => {
                                            return statusType !== efServiceCaseStatusTypes.UNKNOWN;
                                        }).map(serviceCaseStatusType => {
                                            return (
                                                <Option
                                                    key={serviceCaseStatusType}
                                                    name={`serviceCaseStatus${camelCase(serviceCaseStatusType)}`}
                                                    value={serviceCaseStatusType}
                                                >
                                                    {translate(`global.service_case_status_type.${snakeCase(serviceCaseStatusType)}`)}
                                                </Option>
                                            );
                                        })}
                                    </SelectField>
                                </div>
                                <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                                    <ButtonIcon
                                        name="searchButton"
                                        icon={searchIcon}
                                        type="submit"
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                            </div>
                            {areFiltersExpanded && (
                                <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-48')}>
                                    <div className={cx('global!ace-u-grid-column--span-2')}>
                                        <InputField
                                            label={translateScreen('input_field_label.damage_location')}
                                            name="damageLocation"
                                            value={queryParams.get('damageLocation') || filterFormData.damageLocation}
                                            className={cx('global!ace-u-full-width')}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <SelectField
                                            label={translateScreen('select_field_label.channel')}
                                            name="contactChannel"
                                            className={cx('global!ace-u-full-width')}
                                            value={queryParams.get('contactChannel') || filterFormData.contactChannel}
                                        >
                                            {Object.keys(efServiceCaseContactChannelTypes).map(channelType => {
                                                return (
                                                    <Option
                                                        key={channelType}
                                                        name={`serviceCaseStatus${camelCase(channelType)}`}
                                                        value={channelType}
                                                    >
                                                        {translate(`global.service_case_channel_type.${snakeCase(channelType)}`)}
                                                    </Option>
                                                );
                                            })}
                                        </SelectField>
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <InputField
                                            label={translateScreen('input_field_label.license_plate_no')}
                                            name="licensePlateNumber"
                                            value={queryParams.get('licensePlateNumber') || filterFormData.licensePlateNumber}
                                            className={cx('global!ace-u-full-width')}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <InputField
                                            label={translateScreen('input_field_label.member_name')}
                                            name="memberName"
                                            value={queryParams.get('memberName') || filterFormData.memberName}
                                            className={cx('global!ace-u-full-width')}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <InputField
                                            label={translateScreen('input_field_label.membership_no')}
                                            name="membershipNo"
                                            value={queryParams.get('membershipNo') || filterFormData.membershipNo}
                                            className={cx('global!ace-u-full-width')}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-2')}>
                                        <SelectField
                                            label={translateScreen('select_field_label.commissioner')}
                                            name="commissionerId"
                                            className={cx('global!ace-u-full-width')}
                                            value={queryParams.get('commissionerId')?.includes('ne|')
                                                ? serviceCaseSearchResults.length
                                                && serviceCaseSearchResults[0].commissioner.id
                                                : queryParams.get('commissionerId') || filterFormData.commissionerId}
                                        >
                                            <Option
                                                name={config.ACE_COMMISSIONER.name}
                                                value={config.ACE_COMMISSIONER_ID.toString()}
                                            >
                                                {config.ACE_COMMISSIONER.name}
                                            </Option>
                                            {sortedCommissioners.map((commissioner, idx) => (
                                                <Option
                                                    key={`${commissioner.id}-${idx}`}
                                                    name={`${commissioner.id}-${idx}`}
                                                    value={commissioner.id}
                                                >
                                                    {`${commissioner.name}${commissioner.contractStatus === apmContractPartnerContractStatusTypes.TERMINATED || commissioner.contractStatus === apmContractPartnerContractStatusTypes.PERMANENTLY_CLOSED
                                                        ? ` (${translateScreen('option_label.inactive')})` : ''}`}
                                                </Option>
                                            ))}
                                        </SelectField>
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-2')}>
                                        <AutocompleteField
                                            label={translateScreen('select_field_label.services')}
                                            name="serviceType"
                                            className={cx('global!ace-u-full-width')}
                                            value={queryParams.get('serviceType') || filterFormData.serviceType}
                                        >
                                            {allServiceTypes?.map(serviceType => (
                                                <Option
                                                    key={serviceType}
                                                    name={`serviceType${camelCase(serviceType)}`}
                                                    value={serviceType}
                                                >
                                                    {Object.values(efOtherServiceTypes).includes(serviceType)
                                                        ? translate(`global.other_services_service_type.${snakeCase(serviceType)}`)
                                                        : translate(`global.service_type.${snakeCase(serviceType)}`)}
                                                </Option>
                                            ))}
                                        </AutocompleteField>
                                    </div>
                                    {isAgentIdentityAvailable && (
                                        <div className={cx('global!ace-u-grid-column--span-2')}>
                                            <AutocompleteField
                                                name="agentId"
                                                label={translateScreen('select_field_label.nrb')}
                                                value={queryParams.get('agentId') || filterFormData.agentId}
                                                className={cx('global!ace-u-full-width')}
                                                isArrowIconDisplayed={true}
                                            >
                                                {Object.keys(users).map((option, index) => (
                                                    <Option
                                                        key={`${option}-${index}`}
                                                        name={`${option}-${index}`}
                                                        value={option}
                                                    >
                                                        {`${users[option].firstName || ''} ${users[option].lastName || ''}`}
                                                    </Option>
                                                ))}
                                            </AutocompleteField>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-margin--top-32',
                                ])}
                            >
                                <div>
                                    <span className={cx('global!ace-u-typography--variant-body')}>
                                        {translateScreen('label.result_count')}
                                    </span>&nbsp;
                                    <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                        {serviceCaseSearchResultsCount}
                                    </span>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-margin--right-24',
                                    ])}
                                >
                                    <div className={cx('global!ace-u-margin--right-64')}>
                                        <InteractiveIcon
                                            icon={resetIcon}
                                            className={cx([
                                                'ace-c-interactive-icon--reverse',
                                                'ace-c-interactive-icon--highlight',
                                            ])}
                                            onClick={handleResetFilter}
                                        >
                                            {translateScreen('interactive_icon_label.reset_filter')}
                                        </InteractiveIcon>
                                    </div>
                                    <div>
                                        <InteractiveIcon
                                            icon={!areFiltersExpanded ? arrowDownIcon : arrowUpIcon}
                                            className={cx([
                                                'ace-c-interactive-icon--reverse',
                                                'ace-c-interactive-icon--primary',
                                                'ace-c-interactive-icon--highlight',
                                            ])}
                                            onClick={expandFilters}
                                        >
                                            {translateScreen('interactive_icon_label.advanced_search')}
                                        </InteractiveIcon>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Form>
            </Panel>

            <Panel className={cx('ace-c-panel--full-bleed')}>
                <Table qaIdent="service-case-search-results">
                    {serviceCaseSearchResults.length === 0 && !isInProgress && !error && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    {!isInProgress && !!error && (
                        <TableCaption>
                            <FailedRequestBlock translationKey={translationKey} />
                        </TableCaption>
                    )}
                    {isInProgress && (
                        <TableCaption>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary-highlight',
                                    'global!ace-u-margin--top-64',
                                    'global!ace-u-margin--bottom-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="sc-created-at" colSpan={2}>
                                {translateScreen('member_table_cell.fall_system')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-id" colSpan={2}>
                                {translateScreen('member_table_cell.case_number')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-status" colSpan={2}>
                                {translateScreen('member_table_cell.status')}
                            </TableCell>
                            <TableCell qaIdentPart="member-membership-no" colSpan={2}>
                                {translateScreen('member_table_cell.membership_no')}
                            </TableCell>
                            <TableCell qaIdentPart="member-last-name" colSpan={2}>
                                {translateScreen('member_table_cell.last_name')}
                            </TableCell>
                            <TableCell qaIdentPart="member-first-name" colSpan={2}>
                                {translateScreen('member_table_cell.first_name')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-licence-plate-number" colSpan={2}>
                                {translateScreen('member_table_cell.license_plate_no')}
                            </TableCell>
                            <TableCell qaIdentPart="damage-location-address" colSpan={2}>
                                {translateScreen('member_table_cell.damage_location')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-service-type" colSpan={2}>
                                {translateScreen('member_table_cell.service')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-commissioner" colSpan={2}>
                                {translateScreen('member_table_cell.commissioner')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-created-by" colSpan={1}>
                                {translateScreen('member_table_cell.nrb')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-is-legal-case-ongoing" />
                            <TableCell qaIdentPart="sc-deletion" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceCaseSearchResults.length > 0 && !isInProgress
                            && serviceCaseSearchResults.map(serviceCase => {
                                const {member, vehicle, damage, contactChannel, createdBy} = serviceCase;
                                const createdByUser = users[createdBy];
                                const createdByInitials = contactChannel !== efServiceCaseContactChannelTypes.APP
                                    ? (createdByUser ? `${createdByUser?.initials?.toUpperCase()}` : '')
                                    : efServiceCaseContactChannelTypes.APP;
                                const damageLocation = damage && damage.location
                                    ? `${damage.location.postCode} ${damage.location.city}`
                                    : '';

                                return (
                                    <TableRow
                                        key={serviceCase.id}
                                        onClick={() => openServiceCaseScreen(serviceCase)}
                                        qaIdentPart={serviceCase.id}
                                        colSpan={2}
                                    >
                                        <TableCell
                                            qaIdentPart="sc-created-at"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            <div className={cx('global!ace-u-full-width')}>
                                                {serviceCase.createdAt
                                                    ? moment(serviceCase.createdAt).format('DD.MM.YYYY, HH:mm') : ''}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="sc-id"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {serviceCase.id}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="sc-status"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                                            <Pill
                                                type={serviceCase.status === efServiceCaseStatusTypes.NEW
                                                    ? 'positive' : 'pending'}
                                            >
                                                {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                                            </Pill>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-membership-no"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {member ? member.membershipNo : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-last-name"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {/* According to https://computerrock.atlassian.net/browse/ACEECS-4426,
                                          * for the corporate members, display company name in this column
                                          */}
                                            {member
                                                ? member.personalDetails.membershipType === ampMembershipTypes.CORPORATE
                                                    ? member.personalDetails.displayName
                                                    : member.personalDetails.surname
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-first-name"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {member ? member.personalDetails.firstName : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="vehicle-licence-plate-number"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="damage-location-address"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            <div title={damageLocation}>
                                                {damageLocation}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="sc-service-type"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {serviceCase && serviceCase.serviceType
                                                ? (serviceCase?.caseType === efServiceCaseTypes.HOUSEHOLD
                                                    ? translate(`global.other_services_service_type.${serviceCase.serviceType.toLowerCase()}`)
                                                    : translate(`global.service_type.${serviceCase.serviceType.toLowerCase()}`))
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="sc-commissioner"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={2}
                                        >
                                            {serviceCase?.commissioner ? serviceCase?.commissioner?.name : ''}
                                        </TableCell>
                                        <TableCell
                                            className={cx('ace-c-table__cell--overflow-visible')}
                                            qaIdentPart="sc-created-by"
                                            qaIdentPartPostfix={serviceCase.id}
                                            colSpan={1}
                                        >
                                            {isAgentIdentityAvailable && (
                                            <Tooltip message={createdByUser ? createdByUser.displayName : ''}>
                                                {createdByInitials}
                                            </Tooltip>
                                            )}
                                            {!isAgentIdentityAvailable && (
                                            <Tooltip
                                                message={translate('global.user_shift_role.emergency_call_advisor')}
                                            >
                                                {translateScreen('member_table_cell.nrb')}
                                            </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="sc-ongoing-legal-case"
                                            qaIdentPartPostfix={serviceCase.id}
                                            className={cx('ace-c-table__cell--text-align-center')}
                                        >
                                            {serviceCase?.isLegalCaseOngoing && (
                                            <Badge status="negative">
                                                §
                                            </Badge>
                                            )}
                                        </TableCell>
                                        {isCaseDeletionAvailable && (
                                        <TableCell
                                            qaIdentPart="sc-delete"
                                            qaIdentPartPostfix={serviceCase.id}
                                            className={cx('ace-c-table__cell--text-align-center')}
                                        >
                                            {(!config.IS_FALLBACK_SYSTEM
                                                && isCaseDeletionAvailable
                                                && !!serviceCase.isEligibleForDeletion) && (
                                                    <InteractiveIcon
                                                        icon={deleteIcon}
                                                        onClick={e => { handleCaseDeletion(e, serviceCase.id); }}
                                                    />
                                            )}
                                        </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Panel>
            {serviceCaseSearchResults.length > 0 && !isInProgress && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

ServiceCaseSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    serviceCaseSearchResults: PropTypes.array,
    serviceCaseSearchResultsCount: PropTypes.number,
    users: PropTypes.object,
    commissioners: PropTypes.object,
    contractPartners: PropTypes.object,
    initiateServiceCaseDeletionFlow: PropTypes.func,
    requestState: PropTypes.object,
};

ServiceCaseSearchScreen.defaultProps = {
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    users: {},
    commissioners: {},
    contractPartners: {},
    initiateServiceCaseDeletionFlow: () => {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST);
    return {
        serviceCaseSearchResults: serviceCaseSelectors.getServiceCaseSearchResults(state, props),
        serviceCaseSearchResultsCount: state.serviceCases.serviceCaseSearchResultsCount,
        users: userProfileSelectors.getUsers(state),
        commissioners: serviceCaseSelectors.getCommissioners(state),
        contractPartners: contractPartnerSelectors.getContractPartners(state),
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initiateServiceCaseDeletionFlow: payload => dispatch({
            type: serviceCaseActionTypes.INITIATE_DELETE_SERVICE_CASE_FLOW,
            payload,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCaseSearchScreen);
