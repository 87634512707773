import {eventChannel} from 'redux-saga';
import {select, take, fork, put, call} from 'redux-saga/effects';
import {efLocationTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import config from '../../config';

const onSAVMapClickFetchDamageLocationAddress = function* onSAVMapClickFetchDamageLocationAddress(payload) {
    const {serviceCaseId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle');
    const mapClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = arcGISMap.on('map-click', payload => {
            emitter(payload);
        });
        const unsubscribeDragListener = arcGISMap.on('graphic-drag-end', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeClickListener();
            unsubscribeDragListener();
        };
    });

    while (true) {
        const {arcGISPoint} = yield take(mapClickEventChannel);

        yield fork(
            fetchRequest,
            savActionTypes.FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST,
            arcGISRESTService.getLocationAddress,
            {
                longitude: arcGISPoint.longitude,
                latitude: arcGISPoint.latitude,
                langCode: config.ARCGIS_DEFAULT_LANG_CODE,
            },
        );

        const responseAction = yield take([
            savActionTypes.FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_SUCCEEDED,
            savActionTypes.FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {arcGISReverseGeocodingLocationDTO} = response;

            yield put({
                type: serviceCaseActionTypes.SET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION,
                payload: {
                    arcGISReverseGeocodingLocationDTO,
                    serviceCaseId,
                    locationType: efLocationTypes.SELECT_LOCATION,
                },
            });
        }
    }
};

export default onSAVMapClickFetchDamageLocationAddress;
