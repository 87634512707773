import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

const searchMemberAddressGeolocation = function* searchMemberAddressGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const datafactoryService = serviceManager.loadService('datafactoryService');
    const {searchQueryString, memberId} = payload;

    yield put({
        type: memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR,
        payload: {
            memberId,
            hasError: false,
        },
    });

    yield fork(
        fetchRequest,
        memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST,
        datafactoryService.searchAddressSuggestions,
        {combined: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const memberAddressGeolocation = yield take([
        memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED,
        memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (memberAddressGeolocation.type === memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED) {
        yield put({
            type: memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR,
            payload: {
                memberId,
                hasError: true,
            },
        });
        return;
    }

    const {response} = memberAddressGeolocation.payload;
    const {addressCandidateDTOs} = response;

    yield put({
        type: memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES,
        payload: {
            addressCandidateDTOs,
            memberId,
            searchQueryString,
        },
    });
};

export default searchMemberAddressGeolocation;
