import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ContactPerson, efPersonTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ToggleSwitch, Option, calendarIcon} from '@ace-de/ui-components';
import {SelectField, DateField, Form, InputField, InputPhoneNumberField} from '@ace-de/ui-components/form';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import mapMemberDataToContactPersonData from '../../service-cases/mapMemberDataToContactPersonData';

// if user provides member data as a default AND toggle=OFF by default then parse member data
// see SASD/SAMC 'Ansprechpartner vor Ort' panel
const parseDataToContactPersonDTO = data => {
    if (data?.membershipNo) return mapMemberDataToContactPersonData(data);
    return new ContactPerson().fromDTO(data);
};

const ContactPersonDetailsPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('contact_person_details_panel');
    const {title, formName, contactPerson, isIdenticalToMember, hasToggle, hasDate, serviceCase} = props;

    // if no service case, don't render
    if (!serviceCase) return null;

    return (
        <Form name={formName}>
            {formValues => {
                const personData = contactPerson
                    ? parseDataToContactPersonDTO(contactPerson)
                    : (formValues['isIdenticalToMember'] && serviceCase?.member
                        ? mapMemberDataToContactPersonData(serviceCase.member) : null);
                return (
                    <Panel
                        title={title}
                        actions={!!hasToggle && (
                            <ToggleSwitch
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-row-reverse',
                                ])}
                                name="isIdenticalToMember"
                                value={true}
                                isSelected={contactPerson
                                    ? !!contactPerson.isIdenticalToMember : !!isIdenticalToMember}
                            >
                                <span className={cx('global!ace-u-margin--right-16')}>
                                    {translatePanel('toggle_label.is_identical_to_member')}
                                </span>
                            </ToggleSwitch>
                        )}
                    >
                        <div className={cx('global!ace-u-grid')}>
                            <InputField
                                className={cx('global!ace-u-grid-column--span-3')}
                                name="firstName"
                                value={personData?.firstName || ''}
                                label={translatePanel('input_label.first_name')}
                                isDisabled={!!formValues['isIdenticalToMember']}
                            />
                            <InputField
                                className={cx('global!ace-u-grid-column--span-3')}
                                name="lastName"
                                value={personData?.lastName || ''}
                                label={translatePanel('input_label.last_name')}
                                isDisabled={!!formValues['isIdenticalToMember']}
                            />
                            <SelectField
                                className={cx('global!ace-u-grid-column--span-2')}
                                name="relationshipStatus"
                                value={personData?.relationshipStatus || ''}
                                label={translatePanel('input_label.relationship_to_member')}
                                placeholder={translatePanel('input_placeholder.please_select')}
                                isDisabled={!!formValues['isIdenticalToMember']}
                            >
                                {Object.values(efPersonTypes).map(personType => {
                                    return (
                                        <Option
                                            key={personType}
                                            name={personType}
                                            value={personType}
                                        >
                                            {translate(`global.service_assignment_person_type.${personType.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <InputPhoneNumberField
                                className={cx('global!ace-u-grid-column--span-2')}
                                name="phoneNo"
                                value={personData?.phoneNo || ''}
                                label={translatePanel('input_label.telephone')}
                                isDisabled={!!formValues['isIdenticalToMember']}
                            />
                            {!!hasDate && (
                                <DateField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="birthDate"
                                    label={translatePanel('date_field_label.birth_date')}
                                    value={personData?.birthDate || ''}
                                    placeholder={translatePanel('date_field_placeholder.please_enter')}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                    isDisabled={!!formValues['isIdenticalToMember']}
                                />
                            )}
                        </div>
                    </Panel>
                );
            }}
        </Form>
    );
};

ContactPersonDetailsPanel.propTypes = {
    title: PropTypes.string,
    formName: PropTypes.string,
    contactPerson: PropTypes.object,
    isIdenticalToMember: PropTypes.bool,
    hasToggle: PropTypes.bool,
    hasDate: PropTypes.bool,
    serviceCase: PropTypes.object,
};

ContactPersonDetailsPanel.defaultProps = {
    title: '',
    formName: 'contactPerson',
    contactPerson: null,
    isIdenticalToMember: false,
    hasToggle: true,
    hasDate: false,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContactPersonDetailsPanel));
