import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as saaActionTypes from '../saaActionTypes';
import saaScreenTabs from '../saaScreenTabs';

const loadSAAMapAccommodationLocationWatcher = function* loadSAAMapAccommodationLocationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== saaScreenTabs.ACCOMMODATION) return;

    yield put({
        type: saaActionTypes.START_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAAMapAccommodationLocationWatcher;
