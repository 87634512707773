import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {ArcGISMiniMapWidget, createMarkerGraphic, markerVehiclePNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, Icon, checkmarkIcon} from '@ace-de/ui-components';
import config from '../../config';

const DamageLocationInfoPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {damage} = props;
    const arcGISMap = useArcGISMap('sc-basic-data-damage-location');

    const mapCenter = useMemo(() => {
        if (damage && damage.location) {
            return [damage.location.longitude, damage.location.latitude];
        }

        return config.GERMANY_CENTER_COORDINATES;
    }, [damage]);

    useEffect(() => {
        if (!arcGISMap) return;

        if (mapCenter) {
            const [longitude, latitude] = mapCenter;
            arcGISMap.setGraphics({
                graphics: [
                    ...(latitude && longitude
                        ? [createMarkerGraphic({
                            id: 'damageLocation',
                            longitude: longitude,
                            latitude: latitude,
                            icon: markerVehiclePNG,
                        })] : []),
                ],
            });
        }
    }, [damage, arcGISMap, mapCenter]);

    // if no damage object don't render
    if (!damage) return null;
    const {location} = damage;

    return (
        <Panel
            title={translateTab('damage_location_info_panel.title')}
            className={cx([
                'ace-c-panel--full-bleed-content',
            ])}
        >
            <ArcGISMiniMapWidget
                name="sc-basic-data-damage-location"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
            />
            {location ? (
                <div className={cx('global!ace-u-margin--32')}>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {location.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {`${location.street ? `${location.street}, ` : ''}${location.postCode} ${location.city}`}
                        &nbsp;
                        {location.country ? translate(`global.country.${snakeCase(location.country)}`) : ''}
                    </p>
                    <p className={cx('global!ace-u-typography')}>
                        {location.locationNotes}
                    </p>
                    <p className={cx('global!ace-u-typography')}>
                        {location.getDMSCoordinates()}
                    </p>
                </div>
            ) : null}
            {damage.destinationIsReached && (
            <div
                className={cx([
                    'global!ace-u-margin--32',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <Icon icon={checkmarkIcon} className={cx('global!ace-u-margin--right-8')} />
                {translateTab('damage_location_info_panel.vacation_destination_reached')}
            </div>
            )}
        </Panel>
    );
};

DamageLocationInfoPanel.propTypes = {
    damage: PropTypes.object,
};

DamageLocationInfoPanel.defaultProps = {
    damage: null,
};

export default DamageLocationInfoPanel;
