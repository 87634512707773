import {take, fork, cancel} from 'redux-saga/effects';
import * as sasdActionTypes from '../sasdActionTypes';
import onSASDMapClickFetchRelevantLocationAddress from './onSASDMapClickFetchRelevantLocationAddress';

const sasdMapRelevantLocationWatcher = function* sasdMapRelevantLocationWatcher() {
    while (true) {
        const {payload} = yield take(sasdActionTypes.START_SASD_MAP_RELEVANT_LOCATION_WATCHER);
        const {serviceAssignmentId} = payload;
        const onSASDMapClickFetchRelevantLocationAddressTask = yield fork(onSASDMapClickFetchRelevantLocationAddress, {
            serviceAssignmentId,
        });

        yield take(sasdActionTypes.STOP_SASD_MAP_RELEVANT_LOCATION_WATCHER);
        yield cancel(onSASDMapClickFetchRelevantLocationAddressTask);
    }
};

export default sasdMapRelevantLocationWatcher;
