import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPanel, Button, NoResultsBlock, useStyles} from '@ace-de/ui-components';
import {Icon, findCaseIcon} from '@ace-de/ui-components/icons';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

const MemberSearchNoResults = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_search_screen');
    const {initiateCreateForeignAssistanceCaseFlow, initiateCreateEmptyCaseFlow} = props;
    const isCaseCreationFeatureAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.CREATE,
    );

    return (
        <NoResultsBlock
            icon={(
                <Icon
                    icon={findCaseIcon}
                    className={cx('ace-c-icon--xxl')}
                />
            )}
            message={translateScreen('no_search_results.message')}
            description={translateScreen('no_search_results.description')}
        >
            <div className={cx('global!ace-u-margin--top-32')}>
                <ButtonPanel>
                    <Button
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--bottom-24',
                        ])}
                        isDisabled={!isCaseCreationFeatureAvailable}
                        onClick={() => {
                            if (!isCaseCreationFeatureAvailable) return;
                            initiateCreateEmptyCaseFlow();
                        }}
                    >
                        {translateScreen('no_search_results_button.blank_case')}
                    </Button>
                    {/* TODO hiding for now, until button functionalities are included
                        <Button
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateScreen('no_search_results_button.police_case')}
                    </Button> */}
                    <Button
                        className={cx([
                            'global!ace-u-full-width',
                        ])}
                        isDisabled={!isCaseCreationFeatureAvailable}
                        onClick={() => {
                            if (!isCaseCreationFeatureAvailable) return;
                            initiateCreateForeignAssistanceCaseFlow({});
                        }}
                    >
                        {translateScreen('no_search_results_button.foreign_insurance_company')}
                    </Button>
                </ButtonPanel>
            </div>
        </NoResultsBlock>
    );
};

MemberSearchNoResults.propTypes = {
    initiateCreateForeignAssistanceCaseFlow: PropTypes.func.isRequired,
    initiateCreateEmptyCaseFlow: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    initiateCreateForeignAssistanceCaseFlow: () => dispatch({
        type: serviceCaseActionTypes.INITIATE_CREATE_FOREIGN_ASSISTANCE_CASE_FLOW,
    }),
    initiateCreateEmptyCaseFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_EMPTY_CASE_CREATION_FLOW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(MemberSearchNoResults);
