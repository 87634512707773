import {take, call, select, all, put} from 'redux-saga/effects';
import {resolveRoute, replace, prepareSearchQueryParams, matchPath} from '@computerrock/formation-router';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import routePaths from '../../routePaths';
import savScreenTabs from '../../service-assignments-vehicle/savScreenTabs';
import savaScreenTabs from '../../service-assignments-vehicle-additional/savaScreenTabs';
import sapScreenTabs from '../../service-assignments-pickup/sapScreenTabs';

const routePathToServiceLayerMapping = {
    [routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL]: {
        map: 'service-assignment-vehicle-additional',
        locationsLayer: 'sava-contract-partner-locations',
        serviceAreasLayer: 'sava-contract-partner-service-areas',
        routesLayer: 'sava-contract-partner-routes',
    },
    [routePaths.SERVICE_ASSIGNMENT_VEHICLE]: {
        map: 'service-assignment-vehicle',
        locationsLayer: 'sav-contract-partner-locations',
        serviceAreasLayer: 'sav-contract-partner-service-areas',
        routesLayer: 'sav-contract-partner-routes',
    },
    [routePaths.SERVICE_ASSIGNMENT_PICKUP]: {
        map: 'service-assignment-pickup',
        locationsLayer: 'sap-contract-partner-locations',
        serviceAreasLayer: 'sap-contract-partner-service-areas',
        routesLayer: 'sap-contract-partner-routes',
    },
};

const routePathToScreenTabMapping = {
    [routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL]: savaScreenTabs.CONTRACT_PARTNER,
    [routePaths.SERVICE_ASSIGNMENT_VEHICLE]: savScreenTabs.CONTRACT_PARTNER,
    [routePaths.SERVICE_ASSIGNMENT_PICKUP]: sapScreenTabs.SERVICE_PROVIDER,
};

/**
 * Update SA: remove curretly selected ACE partner
 */
const deleteServiceAssignmentACEPartnerFlow = function* deleteServiceAssignmentACEPartnerFlow() {
    while (true) {
        const {payload} = yield take([
            serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_ACE_PARTNER_DELETION_FLOW,
        ]);
        const {serviceAssignmentLineNo, assignmentType, serviceAssignmentData, serviceCaseId} = payload;
        const {isPartnerChangingAllowed} = payload;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_ACE_PARTNER_DELETION_FLOW,
            assignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (isPartnerChangingAllowed) {
            const {serviceManager} = yield select(state => state.application);
            const arcGISMapService = serviceManager.loadService('arcGISMapService');
            const {location} = yield select(state => state.router);

            const vehicleAdditionalScreenMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                exact: true,
            });
            const pickupScreenMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_PICKUP,
                exact: true,
            });
            const routePath = pickupScreenMatch
                ? routePaths.SERVICE_ASSIGNMENT_PICKUP
                : (vehicleAdditionalScreenMatch
                    ? routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL
                    : routePaths.SERVICE_ASSIGNMENT_VEHICLE);

            const arcGISMap = yield call(
                arcGISMapService.getMap,
                routePathToServiceLayerMapping[routePath]?.map,
            );
            if (!arcGISMap) return;
            const contractPartnerLocationsLayer = yield call(
                arcGISMap.getLayer,
                routePathToServiceLayerMapping[routePath]?.locationsLayer,
            );
            if (!contractPartnerLocationsLayer) return;
            contractPartnerLocationsLayer.toggleLayerDisabled(false);

            // if there is a recommended CP, select it
            if (serviceAssignmentData?.acePartner?.id) {
                const contractPartnerServiceAreasLayer = yield call(
                    arcGISMap.getLayer,
                    routePathToServiceLayerMapping[routePath]?.serviceAreasLayer,
                );
                const contractPartnerRoutesLayer = yield call(
                    arcGISMap.getLayer,
                    routePathToServiceLayerMapping[routePath]?.routesLayer,
                );
                yield all([
                    contractPartnerLocationsLayer,
                    contractPartnerServiceAreasLayer,
                    contractPartnerRoutesLayer,
                ].filter(layer => !!layer)
                    .map(layer => call(layer.selectFeatureByAttribute, {
                        where: `contractPa = '${serviceAssignmentData.acePartner.id}'`,
                    })));

                yield put(replace(resolveRoute(
                    routePath,
                    {serviceCaseId, serviceAssignmentLineNo},
                    {
                        search: prepareSearchQueryParams({
                            activeTab: routePathToScreenTabMapping[routePath],
                        }),
                    },
                )));
            }
        }
    }
};

export default deleteServiceAssignmentACEPartnerFlow;
