import {select, take, put, fork} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes, efAdditionalServiceRequestedTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sarcActionTypes from '../sarcActionTypes';
import calculateRentalCarPrice from '../calculateRentalCarPrice';
import calculateAdditionalServicePrice from '../calculateAdditionalServicePrice';
import config from '../../config';

/**
 * Update SARC requirements flow
 */
const updateSARCRequirementsFlow = function* updateSARCRequirementsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, isPriceRecalculationNeeded} = payload;

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[`${serviceCaseId}`];

        if (isPriceRecalculationNeeded && serviceAssignment) {
            let calculatedRentalCarCost = 0;
            const additionalServiceCosts = {
                [efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.WINTER_TIRES]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.AUTOMATIC]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.TRAILER]: {
                    calculatedCost: 0,
                },
            };

            yield fork(
                fetchRequest,
                sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST,
                partnerManagementService.getServiceProviderRentalCarPricing,
                {serviceProviderId: serviceAssignment.acePartner.id},
            );

            const responseAction = yield take([
                sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SUCCEEDED,
                sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_FAILED,
            ]);

            if (responseAction.error) return;

            const {response} = responseAction.payload;
            const {rentalCarPricingDTOs} = response;

            const rentalCarPricing = rentalCarPricingDTOs
                .find(pricingDTO => pricingDTO.sippCode === serviceAssignment?.sippCode);
            if (rentalCarPricing) {
                calculatedRentalCarCost = calculateRentalCarPrice(
                    rentalCarPricing,
                    serviceAssignmentData.numberOfDays,
                );
            }

            if (serviceAssignmentData.additionalServiceRequested) {
                const additionalServicesPricing = rentalCarPricingDTOs.filter(pricingDTO => {
                    return pricingDTO.additionalService;
                });
                Object.values(serviceAssignmentData.additionalServiceRequested).forEach(additionalServiceRequested => {
                    const additionalServicePricing = additionalServicesPricing.find(pricingDTO => {
                        return pricingDTO.additionalService === additionalServiceRequested;
                    });

                    if (additionalServicePricing) {
                        /* eslint-disable-next-line max-len */
                        additionalServiceCosts[additionalServiceRequested].calculatedCost = calculateAdditionalServicePrice(
                            additionalServicePricing,
                            serviceAssignmentData.numberOfDays,
                        );
                    }
                });
            }

            const serviceCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE];
            const winterTiresCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.WINTER_TIRES];
            const otherCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.TRAILER].calculatedCost
                + additionalServiceCosts[efAdditionalServiceRequestedTypes.AUTOMATIC].calculatedCost;

            if (calculatedRentalCarCost) {
                yield* updateServiceAssignment({
                    caller: sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM,
                    assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData: {
                        costs: {
                            rental: calculatedRentalCarCost,
                            service: serviceCost.calculatedCost,
                            tires: winterTiresCost.calculatedCost,
                            other: otherCost,
                        },
                        budget: {
                            rental: serviceAssignment?.budget?.rental,
                            service: serviceCost.calculatedCost,
                            tires: winterTiresCost.calculatedCost,
                            other: serviceAssignment.budget?.other,
                            delivery: serviceAssignment?.deliveryRequested
                                ? (typeof serviceAssignment.budget?.delivery === 'number'
                                    ? serviceAssignment.budget.delivery
                                    : (typeof serviceCase?.remainingBudget === 'number'
                                        ? serviceCase.remainingBudget
                                        : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET))
                                : null,
                        },
                    },
                });
            }
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM},
        });
    }
};

export default updateSARCRequirementsFlow;
