/**
 * Modal ids
 */
export default {
    DELETE_MEMBER_VEHICLE: 'delete-private-member-vehicles',
    DELETE_MEMBER_NOTE: 'delete-member-notes',
    TASK_DATA: 'task-data',
    CREATE_PERSON_SERVICE_CASE: 'create-person-service-case',
    CREATE_FOREIGN_ASSISTANCE_CASE: 'create-foreign-assistance-case',
    ADDITIONAL_SERVICES: 'additional-services',
    SC_DOCUMENT_UPLOAD: 'sc-document-upload',
    SC_DOCUMENT_DELETE: 'sc-document-delete',
    SAV_SERVICE_TYPE_SELECTION: 'sav-service-type-selection',
    SERVICE_ASSIGNMENT_STATUS_UPDATE: 'sav-status-update',
    SAV_ASSIGNMENT_SUCCESSFUL: 'sav-assignment-successful',
    SARC_UPDATE_ACE_PARTNER_STATION: 'sarc-update-ace-partner-station',
    CREATE_CASE_LOG_NOTE: 'create-case-log-note',
    UPDATE_CASE_LOG_NOTE: 'update-case-log-note',
    CREATE_EMPTY_CASE: 'create-empty-case',
    SEND_MESSAGE: 'send-message',
    CREATE_CASE_NO_TARIFF_WARNING: 'create-case-no-tariff-warning',
    SARC_EXTEND_DROPOFF_DATE: 'sarc-extend-dropoff-date',
    POWER_OF_ATTORNEY_SUCCESSFUL: 'power-of-attorney-successful',
    CP_CREATE_TEMPORARY_RESTRICTION: 'cp-create-temporary-restriction',
    CP_EDIT_TEMPORARY_RESTRICTION: 'cp-edit-temporary-restriction',
    CP_TEMPORARY_RESTRICTION_SUCCESSFUL: 'cp-temporary-restriction-successful',
    CP_CREATE_QUALITY_REPORT: 'cp-create-quality-report',
    CP_QUALITY_MANAGEMENT_NOTE_SUCCESSFUL: 'cp-quality-management-note-successful',
    CLOSE_ARCHIVE_SERVICE_ASSIGNMENT: 'close-archive-service-assignment',
    RESEND_SERVICE_ASSIGNMENT: 'resend-service-assignment',
    EDIT_DOCUMENT_DESCRIPTION: 'edit-document-description',
    VEHICLE_DATA: 'vehicle-data',
    CONFIRM_VEHICLE_SELECTION: 'confirm-vehicle-selection',
    EDIT_MEMBER_VEHICLE: 'edit-member-vehicle',
    DELETE_SERVICE_CASE: 'delete-service-case',
    DELETE_SERVICE_ASSIGNMENT: 'delete-service-assignment',
    SERVICE_CASE_DELETION_STATUS: 'service-case-deletion-status',
    SERVICE_ASSIGNMENT_DELETION_STATUS: 'service-assignment-deletion-status',
    ACCOMMODATION_DATA_MODAL: 'accommodation-data-modal',
    SAA_EXTEND_STAY_END_DATE: 'saa-extend-stay-end-date',
    SERVICE_ASSIGNMENT_QM_NOTES: 'service-assignment-qm-notes',
    FAILED_SERVICE_ERROR_MESSAGE: 'failed-service-error-message',
    ACE_PARTNER_DATA: 'ace-partner-data',
    ERROR_MESSAGE_MODAL: 'error-message',
};
