import updateServiceCase from './updateServiceCase';
import * as serviceCaseActions from '../serviceCaseActionTypes';

const toggleOngoingLegalCase = function* toggleOngoingLegalCase({payload}) {
    const {serviceCase, isLegalCaseOngoing} = payload;
    const {isLegalCaseOngoing: oldLegalCaseOngoingValue, id: serviceCaseId, caseType} = serviceCase;

    // Prevent call for setting to the same state
    if (oldLegalCaseOngoingValue === isLegalCaseOngoing) return;

    yield* updateServiceCase({
        caller: serviceCaseActions.TOGGLE_ONGOING_LEGAL_CASE,
        caseType,
        serviceCaseId,
        serviceCaseData: {
            isLegalCaseOngoing,
        },
    });
};

export default toggleOngoingLegalCase;
