import {put} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

export const setPersistencePending = function* setPersistencePending(serviceCaseId) {
    yield put({
        type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
        payload: {serviceCaseId, persistenceState: persistenceStates.PENDING},
    });
};

export const setPersistenceReady = function* setPersistenceReady(serviceCaseId) {
    yield put({
        type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
        payload: {serviceCaseId, persistenceState: persistenceStates.READY},
    });
};
