import {select, fork, take, put} from 'redux-saga/effects';
import {efLocationTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savaActionTypes from '../savaActionTypes';
import config from '../../config';

/**
 * Search reverse service location geolocation
 */
const searchSAVAReverseServiceLocationGeolocation = function* searchSAVAReverseServiceLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const {serviceAssignmentId, latitude, longitude} = payload;

    yield fork(
        fetchRequest,
        savaActionTypes.SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.getLocationAddress,
        {
            longitude,
            latitude,
            langCode: config.ARCGIS_DEFAULT_LANG_CODE,
        },
    );

    const searchReverseServiceLocationGeolocation = yield take([
        savaActionTypes.SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        savaActionTypes.SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchReverseServiceLocationGeolocation.error) {
        const {response} = searchReverseServiceLocationGeolocation.payload;
        const {arcGISReverseGeocodingLocationDTO} = response;

        yield put({
            type: savaActionTypes.SET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION,
            payload: {
                arcGISReverseGeocodingLocationDTO,
                serviceAssignmentId,
                locationType: efLocationTypes.GEO_COORDINATES,
            },
        });
    }
};

export default searchSAVAReverseServiceLocationGeolocation;
