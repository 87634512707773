import {take, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import modalIds from '../../modalIds';
import deletionStatuses from '../deletionStatuses';

const isJSONString = string => {
    try {
        JSON.parse(string);
    } catch (error) {
        return false;
    }
    return true;
};

const deleteServiceCaseFlow = function* deleteServiceCaseFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_DELETE_SERVICE_CASE_FLOW);
        const {serviceCaseId} = payload;
        let requestStatus = deletionStatuses.SUCCEEDED;
        let lockUserId = '';

        yield* openModal(modalIds.DELETE_SERVICE_CASE);

        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_DELETE_SERVICE_CASE_FLOW,
            serviceCaseActionTypes.DECLINE_DELETE_SERVICE_CASE_FLOW,
        ]);

        if (chosenModalOption.type === serviceCaseActionTypes.CONFIRM_DELETE_SERVICE_CASE_FLOW) {
            yield fork(fetchRequest,
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST,
                ecsFlowService.deleteServiceCase,
                {
                    serviceCaseId,
                });

            const serviceCaseDeletionResponseAction = yield take([
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST_FAILED,
                serviceCaseActionTypes.DELETE_SERVICE_CASE_REQUEST_SUCCEEDED,
            ]);

            if (serviceCaseDeletionResponseAction.error) {
                requestStatus = deletionStatuses.FAILED;
                const {payload} = serviceCaseDeletionResponseAction;
                if (isJSONString(payload.message)) {
                    const deleteData = JSON.parse(payload.message);
                    lockUserId = deleteData.lockedBy;
                }
            }

            yield* openModal(modalIds.SERVICE_CASE_DELETION_STATUS, {requestStatus, lockUserId});
        }

        yield* closeModal(modalIds.DELETE_SERVICE_CASE);
    }
};

export default deleteServiceCaseFlow;
