import {all, takeLatest, fork} from 'redux-saga/effects';
import * as taskActionTypes from './taskActionTypes';
import searchTasks from './sagas/searchTasks';
import createTaskFlow from './sagas/createTaskFlow';
import updateTaskFlow from './sagas/updateTaskFlow';

/**
 *  Task watcher saga
 */
const taskWatcher = function* taskWatcher() {
    yield all([
        takeLatest(taskActionTypes.SEARCH_TASKS, searchTasks),
        fork(createTaskFlow),
        fork(updateTaskFlow),
    ]);
};

export default taskWatcher;
