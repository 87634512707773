import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Update Service assignment journey notes
 */
const updateServiceAssignmentJourneyNotesForm = function* updateServiceAssignmentJourneyNotesForm() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, assignmentType, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM,
            assignmentType: assignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM},
        });
    }
};

export default updateServiceAssignmentJourneyNotesForm;
