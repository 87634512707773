import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ToggleSwitch, Divider, Checkbox, Form, NumberInputField, Icon, infoAlertIcon} from '@ace-de/ui-components';
import * as saaActionTypes from '../saaActionTypes';
import getPrice from '../../utils/getPrice';
import config from '../../config';

const SAABudgetPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateTab = createTranslateShorthand('saa_requirements_tab');
    const {serviceAssignment, submitSAABudgetForm, numberOfNights, numberOfPersons} = props;
    const {serviceAssignmentBudgets, isMaxDurationError, serviceAssignmentBudgetMaxDurations} = props;
    const [budgetData, setBudgetData] = useState(serviceAssignment);

    const roundToAtMost2Decimals = num => {
        if (!num) return 0;
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const countTotalCosts = useCallback((budgetPerNight, serviceAssignment) => {
        const {totalCoverage} = serviceAssignment;
        const budget = budgetPerNight || serviceAssignmentBudgets?.[apsServiceTypes.ACCOMMODATION]?.value;
        if (numberOfNights < 1) return totalCoverage;
        if (numberOfNights >= 1
            && numberOfNights <= serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value) {
            const total = parseFloat(budget) * numberOfNights * numberOfPersons;
            return roundToAtMost2Decimals(total);
        }
        const multiplierConstant = serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value < numberOfNights // eslint-disable-line max-len
            ? serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value
            : numberOfNights;
        const total = parseFloat(budget) * multiplierConstant * numberOfPersons;
        return roundToAtMost2Decimals(total);
    }, [
        numberOfNights,
        numberOfPersons,
        serviceAssignmentBudgets,
        serviceAssignmentBudgetMaxDurations,
    ]);

    const handleTabChange = useCallback(formValues => {
        const {coveringBreakfast, overrideDefaultBudget, payLater, budgetPerNight, totalCoverage} = formValues;
        const serviceAssignmentData = {
            coveringBreakfast,
            overrideDefaultBudget,
            payLater,
            budgetPerNight: overrideDefaultBudget
                ? budgetPerNight
                : serviceAssignmentBudgets?.[apsServiceTypes.ACCOMMODATION]?.value,
            totalCoverage,
            // mandatory
            serviceCaseId: serviceAssignment.serviceCaseId,
        };

        submitSAABudgetForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSAABudgetForm,
        serviceAssignment,
        serviceAssignmentBudgets,
    ]);

    const handleFormChange = formValues => {
        setBudgetData({
            ...formValues,
            coveringBreakfast: !!formValues.coveringBreakfast,
            overrideDefaultBudget: !!formValues.overrideDefaultBudget,
            payLater: !!formValues.payLater,
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!budgetData) {
            completeRouteUnmountSideEffect({
                caller: saaActionTypes.SUBMIT_SAA_BUDGET_FORM,
            });
            return;
        }

        handleTabChange({
            ...budgetData,
            totalCoverage: countTotalCosts(budgetData.budgetPerNight, serviceAssignment),
        });
    }, [budgetData, handleTabChange, countTotalCosts, serviceAssignment]);

    return (
        <Panel
            title={translateTab('budget_panel_title.budget', {
                priceType: Number(serviceAssignment.serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID
                    ? translate('global.pricing_type.net')
                    : translate('global.pricing_type.gross'),
            })}
        >
            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-padding--bottom-24'])}>
                {translateTab('budget_panel_text.number_of_nights')}&nbsp;
                {numberOfNights}
            </div>
            <Form name="saaBudgetForm" onChange={handleFormChange}>
                {() => {
                    return (
                        <Fragment>
                            {/* NOTE: Set default value to 90 euros.
                            The default value might differ depending on the member’s tariff and status in the future.
                            The logic for how the play between tariff and status come together,
                            will be implemented at a later point. */}
                            <NumberInputField
                                name="budgetPerNight"
                                label={translateTab('budget_panel_input_label.budget_per_night')}
                                value={budgetData.overrideDefaultBudget
                                    ? budgetData.budgetPerNight
                                    : serviceAssignmentBudgets?.[apsServiceTypes.ACCOMMODATION]?.value || 0}
                                isDisabled={!budgetData.overrideDefaultBudget}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-padding--bottom-32'])}
                                min={0}
                            />
                            <ToggleSwitch
                                name="overrideDefaultBudget"
                                value={true}
                                isSelected={!!budgetData.overrideDefaultBudget}
                                className={cx('global!ace-u-padding--bottom-24')}
                            >
                                {translateTab('budget_panel_toggle_switch_label.adjust_budget')}
                            </ToggleSwitch>
                            <Divider />
                            <ToggleSwitch
                                name="coveringBreakfast"
                                value={true}
                                isSelected={!!budgetData.coveringBreakfast}
                                className={cx(['global!ace-u-padding--top-24', 'global!ace-u-padding--bottom-24'])}
                            >
                                {translateTab('budget_panel_toggle_switch_label.breakfast_included')}
                            </ToggleSwitch>
                            <Divider />
                            <div className={cx(['global!ace-u-padding--top-24', 'global!ace-u-padding--bottom-8'])}>
                                {translateTab('budget_panel_text.cost_coverage_sum')}
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-h2', 'global!ace-u-margin--bottom-24'])}>
                                {getPrice(countTotalCosts(
                                    budgetData.budgetPerNight
                                        ? budgetData.budgetPerNight
                                        : serviceAssignmentBudgets?.[apsServiceTypes.ACCOMMODATION]?.value,
                                    serviceAssignment,
                                ), activeLocale)}
                            </div>
                            <Checkbox
                                name="payLater"
                                value={true}
                                isSelected={budgetData.payLater}
                                className={cx('global!ace-u-margin--bottom-24')}
                            >
                                {translateTab('budget_panel_checkbox_label.member_can_go_in_advance')}
                            </Checkbox>
                        </Fragment>
                    );
                }}
            </Form>
            {isMaxDurationError && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--4-0',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-typography--color-warning',
                    ])}
                >
                    <Icon
                        icon={infoAlertIcon}
                        className={cx([
                            'global!ace-u-margin--right-4',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                            'global!ace-u-flex--shrink-0',
                        ])}
                    />
                    {translateTab('budget_panel_error_message.max_duration')}
                </div>
            )}
        </Panel>
    );
};

SAABudgetPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    submitSAABudgetForm: PropTypes.func.isRequired,
    numberOfNights: PropTypes.number,
    numberOfPersons: PropTypes.number,
    serviceAssignmentBudgets: PropTypes.object,
    serviceAssignmentBudgetMaxDurations: PropTypes.object,
    isMaxDurationError: PropTypes.bool,
};

SAABudgetPanel.defaultProps = {
    serviceAssignment: {},
    numberOfNights: 0,
    numberOfPersons: 0,
    serviceAssignmentBudgets: null,
    serviceAssignmentBudgetMaxDurations: null,
    isMaxDurationError: false,
};

const mapStateToProps = (state, props) => {
    return {
        serviceAssignmentBudgets: state.serviceAssignments.serviceAssignmentBudgets,
        serviceAssignmentBudgetMaxDurations: state.serviceAssignments.serviceAssignmentBudgetMaxDurations,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAABudgetForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_BUDGET_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAABudgetPanel));
