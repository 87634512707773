import moment from 'moment';

const isTemporaryRestrictionActive = temporaryRestriction => {
    const now = moment();
    const restrictionStart = temporaryRestriction.restrictionStartDateTime
        ? moment(temporaryRestriction.restrictionStartDateTime) : null;
    const restrictionEnd = temporaryRestriction.restrictionEndDateTime
        ? moment(temporaryRestriction.restrictionEndDateTime) : null;

    // if both dates exist, check if the current date is in between
    if (restrictionStart && restrictionEnd && restrictionStart.isValid() && restrictionEnd.isValid()) {
        return now.isSameOrAfter(restrictionStart) && now.isBefore(restrictionEnd);
    }

    // if only the start date exists, check if it is in the past
    return restrictionStart && restrictionStart.isValid() ? now.isSameOrAfter(restrictionStart) : false;
};

export default isTemporaryRestrictionActive;
