import {put, select, delay} from 'redux-saga/effects';
import {matchPath, push, resolveRoute} from '@computerrock/formation-router';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';
import routePaths from '../../routePaths';

const serviceCaseLockTimer = function* serviceCaseLockTimer({payload}) {
    const {serviceCaseId} = payload;
    // reset locking time
    yield put({
        type: serviceCaseActionTypes.SET_SERVICE_CASE_REMAINING_LOCK_TIME,
        payload: {remainingLockTime: config.DEFAULT_SERVICE_CASE_LOCK_TIME},
    });

    while (true) {
        yield delay(60000); // in ms (1 minute delay)

        // exit if NOT under service case section
        const {location} = yield select(state => state.router);
        const serviceCaseSectionMatch = matchPath(location.pathname, {
            path: routePaths.SERVICE_CASE_SECTION,
        });

        if (!serviceCaseSectionMatch) return;

        // if agent switches from case1 to case2 and stays under service case section
        const prevServiceCaseId = sessionStorage.getItem('serviceCaseId');
        if (prevServiceCaseId && serviceCaseId !== prevServiceCaseId) {
            return;
        }

        const remainingLockTime = yield select(state => state.serviceCases.remainingLockTime);
        const updatedLockTime = remainingLockTime - 60;

        if (updatedLockTime === 60) {
            // time's up, then redirect to service case search screen
            yield put(push(resolveRoute(routePaths.SERVICE_CASE_SEARCH)));
            return;
        }

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_REMAINING_LOCK_TIME,
            payload: {remainingLockTime: updatedLockTime},
        });
    }
};

export default serviceCaseLockTimer;
