import {ACEPartner} from '@ace-de/eua-entity-types';

const createDefaultAccommodation = () => {
    return new ACEPartner({
        name: '',
        businessContactDetails: {
            phoneNo: '',
            faxNo: '',
            email: '',
        },
        location: {
            street: '',
            postCode: '',
            city: '',
            country: '',
        },
    });
};

export default createDefaultAccommodation;
