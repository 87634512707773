import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {Link, withRouter, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, linkIcon, resubmissionIcon, Icon, Panel, NoResultsBlock, Pill, InteractiveIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as dashboardSelectors from '../dashboardSelectors';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import routePaths from '../../routePaths';
import TableLoaderSpinner from '../../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const pillVariants = {
    [etmTaskStatusTypes.DUE]: 'pending',
    [etmTaskStatusTypes.OVERDUE]: 'negative',
    [etmTaskStatusTypes.DONE]: 'positive',
};

const MyTasksPanel = props => {
    const {cx} = useStyles();
    const {initiateTaskUpdateFlow, currentUsersTasks, currentUser, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('dashboard_screen');

    const openEditTaskModal = task => {
        initiateTaskUpdateFlow({task});
    };

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateTab('tasks_panel.title')}
            actions={(
                <Link
                    to={resolveRoute(
                        routePaths.TASK_SEARCH,
                        {},
                        {search: prepareSearchQueryParams({
                            assignedTo: `${currentUser?.shiftRole},${currentUser?.id}`,
                            status: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
                        })},
                    )}
                    className={cx([
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--color-secondary',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <InteractiveIcon
                            icon={linkIcon}
                            className={cx('ace-c-interactive-icon--reverse')}
                        >
                            {translateTab('tasks_panel_link_label.all')}
                        </InteractiveIcon>
                    </div>
                </Link>
            )}
        >
            <Table qaIdent="current-user-tasks">
                {currentUsersTasks.length === 0 && !isInProgress && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={resubmissionIcon}
                                />
                                )}
                            message={translateTab('tasks_panel_table_no_results.message')}
                            description={translateTab('tasks_panel_table_no_results.description')}
                        />
                    </TableCaption>
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="task-due-date">
                            {translateTab('tasks_panel_table_header.due_date')}
                        </TableCell>
                        <TableCell qaIdentPart="task-status">
                            {translateTab('tasks_panel_table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="task-category">
                            {translateTab('tasks_panel_table_header.category')}
                        </TableCell>
                        <TableCell qaIdentPart="task-description">
                            {translateTab('tasks_panel_table_header.description')}
                        </TableCell>
                        <TableCell qaIdentPart="task-member-name">
                            {translateTab('tasks_panel_table_header.member')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentUsersTasks.length > 0 && !isInProgress && currentUsersTasks.map(task => (
                        <TableRow
                            key={task.id}
                            onClick={() => openEditTaskModal(task)}
                            qaIdentPart={task.id}
                        >
                            <TableCell
                                qaIdentPart="task-due-date"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.dueDate && moment(task.dueDate).isValid()
                                    ? moment(task.dueDate).format('DD.MM.YYYY[,] HH:mm')
                                    : '-'
                                }
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-status"
                                qaIdentPartPostfix={task.id}
                            >
                                <Pill type={pillVariants[task.status]}>
                                    {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                </Pill>
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-category"
                                qaIdentPartPostfix={task.id}
                            >
                                {translate(`global.task_category.${task.category.toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-description"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.description}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-member-name"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.memberName}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

MyTasksPanel.propTypes = {
    initiateTaskUpdateFlow: PropTypes.func.isRequired,
    currentUsersTasks: PropTypes.array,
    currentUser: PropTypes.object,
    requestState: PropTypes.object,
};

MyTasksPanel.defaultProps = {
    currentUsersTasks: [],
    currentUser: {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST);
    return {
        currentUser: userProfileSelectors.getUser(state),
        currentUsersTasks: dashboardSelectors.getCurrentUserTasks(state),
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateTaskUpdateFlow: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_UPDATE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTasksPanel));
