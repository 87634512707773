import React, {Fragment, useRef, useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efChildrenAgeTypes} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerHomePNG, markerVehiclePNG, markerSearchPNG, markerCompanionPNG, markerGoalDestinationPNG} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, InputCounter, Option, Divider, Pill} from '@ace-de/ui-components';
import {Form, TextAreaField, AutosuggestField, SelectField, ButtonPrimary, InputField} from '@ace-de/ui-components/form';
import {Icon, locationIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sasdActionTypes from './sasdActionTypes';
import config from '../config';
import sasdLocationTypes from './sasdLocationTypes';

const SASDRelevantPlacesTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sasd_relevant_places_tab');
    const arcGISMap = useArcGISMap('service-assignment-substitute-driver');
    const {serviceAssignment, serviceCase, submitSASDRelevantLocationForm} = props;
    const {updatePersonCaseDamageLocationInfo, searchSASDRelevantLocationGeolocation} = props;
    const {startSASDMapRelevantLocationWatcher, stopSASDMapRelevantLocationWatcher} = props;
    const isSASDMapRelevantLocationWatcherStarted = useRef(false);
    const currentRelevantLocationAddress = useRef('');
    const currentRelevantLocationType = useRef('');
    const lastRelevantLocationSearchQuery = useRef('');
    const prevReverseGeocodingRelevantLocation = useRef(null);
    const [newRelevantLocation, setNewRelevantLocation] = useState(null);
    const searchSASDRelevantLocationGeolocationDebounced = useMemo(
        () => debounce(searchSASDRelevantLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSASDRelevantLocationGeolocation],
    );
    const [damageLocationInfoFormData, setNewDamageLocationInfoFormData] = useState(null);
    const [childrenAge, setChildrenAge] = useState(serviceAssignment?.childrenAge.length
        ? (serviceAssignment.childrenAge.length < serviceAssignment?.childrenAtSite
            ? serviceAssignment.childrenAge
                .concat(new Array(serviceAssignment.childrenAtSite - serviceAssignment.childrenAge.length)
                    .fill('', 0))
            : serviceAssignment.childrenAge)
        : (serviceAssignment?.childrenAtSite
            ? new Array(serviceAssignment?.childrenAtSite).fill('', 0)
            : []));

    useEffect(() => {
        if (!serviceAssignment || isSASDMapRelevantLocationWatcherStarted.current) return;

        startSASDMapRelevantLocationWatcher({
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        isSASDMapRelevantLocationWatcherStarted.current = true;

        return () => {
            stopSASDMapRelevantLocationWatcher();
            isSASDMapRelevantLocationWatcherStarted.current = false;
        };
    }, [serviceAssignment, startSASDMapRelevantLocationWatcher, stopSASDMapRelevantLocationWatcher]);

    useEffect(() => {
        if (!serviceAssignment || !serviceCase) return;

        const {reverseGeocodingRelevantLocation} = serviceAssignment;
        if (reverseGeocodingRelevantLocation
            && reverseGeocodingRelevantLocation !== prevReverseGeocodingRelevantLocation.current) {
            if (!currentRelevantLocationType?.current) return;
            prevReverseGeocodingRelevantLocation.current = reverseGeocodingRelevantLocation;
            setNewRelevantLocation(reverseGeocodingRelevantLocation);
        }
    }, [serviceAssignment, serviceCase]);

    useEffect(() => {
        if (!serviceCase || !serviceAssignment || !arcGISMap) return;

        const {member} = serviceCase;
        const {destination, serviceLocation, companionPickupLocation} = serviceAssignment;
        const {residenceLocation} = member;

        const isRelevantLocationSaved = !!(newRelevantLocation
            && !(serviceLocation && companionPickupLocation && destination) && (
            !!(serviceLocation && serviceLocation.latitude === newRelevantLocation.latitute
                && serviceLocation.longitude === newRelevantLocation.longitude)
            || !!(companionPickupLocation && companionPickupLocation.latitude === newRelevantLocation.latitude
                && companionPickupLocation.longitude === newRelevantLocation.longitude)
            || !!(destination && destination.latitude === newRelevantLocation.latitude
                && destination.longitude === newRelevantLocation.longitude)
        ));

        arcGISMap.setGraphics({
            graphics: [
                ...(newRelevantLocation && !isRelevantLocationSaved ? [
                    createMarkerGraphic({
                        id: 'searchLocation',
                        longitude: newRelevantLocation.longitude,
                        latitude: newRelevantLocation.latitude,
                        icon: markerSearchPNG,
                        isDraggable: true,
                    }),
                ] : []),
                ...(residenceLocation.longitude && residenceLocation.latitude
                    ? [
                        createMarkerGraphic({
                            id: 'residenceLocation',
                            longitude: residenceLocation.longitude,
                            latitude: residenceLocation.latitude,
                            icon: markerHomePNG,
                        }),
                    ] : []),
                ...(serviceLocation
                    ? [
                        createMarkerGraphic({
                            id: 'damageLocation',
                            longitude: serviceLocation.longitude,
                            latitude: serviceLocation.latitude,
                            icon: markerVehiclePNG,
                        }),
                    ] : []),
                ...(companionPickupLocation
                    ? [
                        createMarkerGraphic({
                            id: 'companionPickupLocation',
                            longitude: companionPickupLocation.longitude,
                            latitude: companionPickupLocation.latitude,
                            icon: markerCompanionPNG,
                        }),
                    ] : []),
                ...(destination
                    ? [
                        createMarkerGraphic({
                            id: 'destinationLocation',
                            longitude: destination.longitude,
                            latitude: destination.latitude,
                            icon: markerGoalDestinationPNG,
                        }),
                    ] : []),
            ],
        });
    }, [serviceCase, arcGISMap, serviceAssignment, newRelevantLocation]);

    const handleRelevantLocationTypeChange = useCallback(newLocationType => {
        const {destination, serviceLocation, companionPickupLocation} = serviceAssignment;

        if (newLocationType === sasdLocationTypes.GOAL_DESTINATION) {
            setNewRelevantLocation(destination);
            currentRelevantLocationType.current = sasdLocationTypes.GOAL_DESTINATION;
            return;
        }

        if (newLocationType === sasdLocationTypes.PICKUP_LOCATION_COMPANION) {
            setNewRelevantLocation(companionPickupLocation);
            currentRelevantLocationType.current = sasdLocationTypes.PICKUP_LOCATION_COMPANION;
            return;
        }

        if (serviceLocation) {
            setNewRelevantLocation(serviceLocation);
            currentRelevantLocationType.current = sasdLocationTypes.PICKUP_LOCATION_VEHICLE;
            return;
        }

        setNewRelevantLocation(null);
        currentRelevantLocationType.current = sasdLocationTypes.PICKUP_LOCATION_VEHICLE;
    }, [serviceAssignment]);

    const handleRelevantLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentRelevantLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSASDRelevantLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastRelevantLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleRelevantLocationCandidateSelect = locationCandidate => {
        currentRelevantLocationAddress.current = locationCandidate.formattedAddress;
        setNewRelevantLocation(locationCandidate);
    };

    const handleDamageLocationFormSubmit = useCallback(formValues => {
        if (!newRelevantLocation) return;

        submitSASDRelevantLocationForm({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            locationType: formValues.relevantLocationType,
            location: newRelevantLocation,
        });
    }, [
        submitSASDRelevantLocationForm,
        serviceAssignment.serviceCaseId,
        serviceAssignment.lineNo,
        newRelevantLocation,
    ]);

    const updateChildrenAgeData = formValues => {
        const {childrenAtSite, childrenAge: childrenAgeValues} = formValues;
        const currentChildrenAgeValues = childrenAtSite
            ? (Object.keys(childrenAgeValues).length
                ? Object.keys(childrenAgeValues).map(key => childrenAgeValues[key])
                    .slice(0, damageLocationInfoFormData?.childrenAtSite || serviceAssignment?.childrenAtSite)
                : childrenAge)
            : [];
        if (childrenAtSite > currentChildrenAgeValues.length) {
            setChildrenAge([...currentChildrenAgeValues, '']);
            return;
        }
        if (childrenAtSite < currentChildrenAgeValues.length) {
            const updatedChildrenArray = [...currentChildrenAgeValues];
            updatedChildrenArray.pop();
            setChildrenAge(updatedChildrenArray);
            return;
        }
        setChildrenAge(currentChildrenAgeValues);
    };

    const handleDamageLocationInfoFormChange = formValues => {
        updateChildrenAgeData(formValues);
        setNewDamageLocationInfoFormData(formValues);
    };

    const handleTabChange = useCallback(formValues => {
        const {
            locationNotes,
            clinicNumber,
            grownUpsAtSite,
            childrenAtSite,
            petsAtSite,
        } = formValues;
        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        updatePersonCaseDamageLocationInfo({
            serviceCaseId: serviceAssignment.serviceCaseId,
            caseType: serviceCase.caseType,
            serviceCaseData: isInitialAssignment ? {
                // if it's an initial assignment, update service case
                damage: {
                    location: {
                        locationNotes,
                    },
                },
                childrenAge: childrenAge.filter(age => age !== ''),
                grownUpsAtSite,
                childrenAtSite,
                petsAtSite,
            } : null,
            serviceAssignmentData: {
                clinicNumber,
                serviceLocation: {
                    locationNotes,
                },
                childrenAge: childrenAge.filter(age => age !== ''),
                grownUpsAtSite,
                childrenAtSite,
                petsAtSite,
            },
            serviceAssignmentLineNo: serviceAssignment.lineNo,
        });
    }, [
        updatePersonCaseDamageLocationInfo,
        serviceAssignment.serviceCaseId,
        serviceAssignment.lineNo,
        serviceAssignment.isCloneFromInitial,
        childrenAge,
        serviceCase.caseType,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!damageLocationInfoFormData) {
            completeRouteUnmountSideEffect({
                caller: sasdActionTypes.SUBMIT_SASD_DAMAGE_LOCATION_INFO_FORM,
            });
            return;
        }

        handleTabChange(damageLocationInfoFormData);
    }, [damageLocationInfoFormData, handleTabChange]);

    // if no case or assignment don't render
    if (!serviceAssignment || !serviceCase) return null;

    const {member, damage} = serviceCase;
    const {residenceLocation} = member;
    const {relevantLocationCandidates, relevantLocationSearchQuery} = serviceAssignment;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.relevant_places')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock
                        label={translateTab('scrollable_block_label.fill_details')}
                    >
                        <Form
                            name="relevantLocationForm"
                            onSubmit={handleDamageLocationFormSubmit}
                        >
                            {formValues => {
                                /* eslint-disable */
                                const isRelevantLocationSaved = !!(formValues.relevantLocationType === sasdLocationTypes.GOAL_DESTINATION && serviceAssignment.destination
                                        && formValues['relevantLocationSearchQuery'] === serviceAssignment.destination.formattedAddress)
                                    || !!(formValues.relevantLocationType === sasdLocationTypes.PICKUP_LOCATION_COMPANION && serviceAssignment.companionPickupLocation
                                        && formValues['relevantLocationSearchQuery'] === serviceAssignment.companionPickupLocation.formattedAddress)
                                    || !!(formValues.relevantLocationType === sasdLocationTypes.PICKUP_LOCATION_VEHICLE && serviceAssignment.serviceLocation?.id
                                        && formValues['relevantLocationSearchQuery'] === serviceAssignment.serviceLocation.formattedAddress);
                                /* eslint-enable */
                                const isSearchInputDisabled = !formValues.relevantLocationType;
                                const isSubmitButtonDisabled = isRelevantLocationSaved || !formValues['relevantLocationType']
                                    || (formValues['relevantLocationType'] && (!formValues['relevantLocationSearchQuery'] || !newRelevantLocation));
                                return (
                                    <Fragment>
                                        <SelectField
                                            name="relevantLocationType"
                                            value="" // by default, no option is selected
                                            onChange={handleRelevantLocationTypeChange}
                                            label={translateTab('select_label.type')}
                                            placeholder={translateTab('select_placeholder.please_select')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-32',
                                            ])}
                                        >
                                            <Option
                                                name="relevant-location-type-goal-destination"
                                                value={sasdLocationTypes.GOAL_DESTINATION}
                                            >
                                                {translateTab('select_option_label.goal_destination')}
                                            </Option>
                                            <Option
                                                name="relevant-location-type-pickup-location-companion"
                                                value={sasdLocationTypes.PICKUP_LOCATION_COMPANION}
                                            >
                                                {translateTab('select_option_label.pickup_location_companion')}
                                            </Option>
                                            <Option
                                                name="relevant-location-type-pickup-location-vehicle"
                                                value={sasdLocationTypes.PICKUP_LOCATION_VEHICLE}
                                            >
                                                {translateTab('select_option_label.pickup_location_vehicle')}
                                            </Option>
                                        </SelectField>
                                        <AutosuggestField
                                            name="relevantLocationSearchQuery"
                                            value={newRelevantLocation?.formattedAddress || ''}
                                            isDisabled={isSearchInputDisabled}
                                            onChange={handleRelevantLocationSearchQueryChange}
                                            onOptionSelect={handleRelevantLocationCandidateSelect}
                                            optionValueSelector={locationCandidate => {
                                                return locationCandidate.formattedAddress;
                                            }}
                                            label={translateTab('input_label.address')}
                                            placeholder={translateTab('input_placeholder.please_enter')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-32',
                                            ])}
                                        >
                                            {(formValues['relevantLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                && relevantLocationSearchQuery === lastRelevantLocationSearchQuery.current // eslint-disable-line max-len
                                                ? relevantLocationCandidates
                                                    .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                    .map((locationCandidate, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                name={`damage-location-candidate-${index}`}
                                                                value={locationCandidate}
                                                            >
                                                                <Icon
                                                                    icon={locationIcon}
                                                                    className={cx('global!ace-u-margin--right-16')}
                                                                />
                                                                {locationCandidate.formattedAddress}
                                                            </Option>
                                                        );
                                                    }) : null}
                                        </AutosuggestField>
                                        <ButtonPrimary
                                            name="submitRelevantLocation"
                                            type="submit"
                                            isDisabled={isSubmitButtonDisabled}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-8',
                                            ], {
                                                'ace-c-button-primary--is-positive': isRelevantLocationSaved,
                                            })}
                                        >
                                            {translateTab('button_label.confirm_location')}
                                            {isRelevantLocationSaved && (
                                                <Icon
                                                    icon={checkmarkIcon}
                                                    className={cx(
                                                        'ace-c-icon--color-contrast',
                                                        'global!ace-u-margin--left-16',
                                                    )}
                                                />
                                            )}
                                        </ButtonPrimary>
                                        {residenceLocation.formattedAddress && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.members_place_of_residence')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {residenceLocation.formattedAddress}
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )}
                                        {!!serviceAssignment?.serviceLocation && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.vehicle_pickup_location')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {serviceAssignment.serviceLocation.formattedAddress || ''}
                                                </div>
                                                {serviceAssignment.distanceResidenceToServiceLocation !== null && (
                                                    <div className={cx('global!ace-u-margin--bottom-24')}>
                                                        <Pill
                                                            type="information"
                                                            className={cx('global!ace-u-typography--variant-caption')}
                                                        >
                                                            {translateTab('pill_label.distance_to_residence')}&nbsp;
                                                            {/* eslint-disable-next-line max-len */}
                                                            {Math.round(serviceAssignment.distanceResidenceToServiceLocation)}
                                                            {translate('global.unit.km')}
                                                        </Pill>
                                                    </div>
                                                )}
                                                <Divider />
                                            </Fragment>
                                        )}
                                        {serviceAssignment.destination && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.goal_destination')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {serviceAssignment.destination.formattedAddress}
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )}
                                        {serviceAssignment.companionPickupLocation && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-margin--top-16',
                                                    ])}
                                                >
                                                    {translateTab('label.companion_pickup_location')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-16',
                                                        'global!ace-u-typography--variant-body',
                                                    ])}
                                                >
                                                    {serviceAssignment.companionPickupLocation.formattedAddress}
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )}
                                    </Fragment>
                                );
                            }}
                        </Form>
                        <Form
                            name="damageLocationInfoForm"
                            onChange={handleDamageLocationInfoFormChange}
                        >
                            <TextAreaField
                                name="locationNotes"
                                label={translateTab('text_area_label.abode_notes')}
                                placeholder={translateTab('text_area_placeholder.example_hospital_station')}
                                // pre-fill with the damage location notes
                                value={serviceAssignment?.serviceLocation?.locationNotes
                                    ? serviceAssignment.serviceLocation.locationNotes
                                    : damage?.location?.locationNotes || ''}
                                isResizable={false}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--24-0',
                                ])}
                            />
                            <InputField
                                name="clinicNumber"
                                label={translateTab('input_field_label.clinic_phone_number')}
                                placeholder={translateTab('input_field_placeholder.example_clinic_phone_number')}
                                value={serviceAssignment.clinicNumber || ''}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            />
                            <div
                                className={cx([
                                    'global!ace-u-flex--align-self-flex-start',
                                    'global!ace-u-typography--variant-body-bold',
                                ])}
                            >
                                {translateTab('text.escorts')}
                            </div>
                            <InputCounter
                                name="grownUpsAtSite"
                                label={translateTab('input_counter_label.adults')}
                                value={serviceAssignment.grownUpsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider />
                            <InputCounter
                                name="childrenAtSite"
                                label={translateTab('input_counter_label.children')}
                                value={serviceAssignment.childrenAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            {!childrenAge.length && (
                                <Divider />
                            )}
                            <Form name="childrenAge">
                                <div
                                    className={cx('global!ace-u-grid', {
                                        'global!ace-u-padding--16-0': childrenAge.length,
                                    })}
                                >
                                    {childrenAge.map((age, idx) => (
                                        <SelectField
                                            key={idx}
                                            name={`childrenAge${idx}`}
                                            label={translateTab('input_label.children_age')}
                                            value={age}
                                            className={cx('global!ace-u-grid-column--span-6')}
                                        >
                                            {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                <Option
                                                    key={idx}
                                                    name={`children-age-option-${idx}`}
                                                    value={idx}
                                                >
                                                    {efChildrenAgeTypes[ageOption]}
                                                </Option>
                                            ))}
                                        </SelectField>
                                    ))}
                                </div>
                                {!!childrenAge.length && (
                                    <Divider />
                                )}
                            </Form>
                            <InputCounter
                                name="petsAtSite"
                                label={translateTab('input_counter_label.animals')}
                                value={serviceAssignment.petsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider />
                        </Form>
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SASDRelevantPlacesTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    updatePersonCaseDamageLocationInfo: PropTypes.func.isRequired,
    searchSASDRelevantLocationGeolocation: PropTypes.func.isRequired,
    submitSASDRelevantLocationForm: PropTypes.func.isRequired,
    startSASDMapRelevantLocationWatcher: PropTypes.func.isRequired,
    stopSASDMapRelevantLocationWatcher: PropTypes.func.isRequired,
};

SASDRelevantPlacesTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSASDRelevantLocationGeolocation: payload => dispatch({
        type: sasdActionTypes.SEARCH_SASD_RELEVANT_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSASDRelevantLocationForm: payload => dispatch({
        type: sasdActionTypes.SUBMIT_SASD_RELEVANT_LOCATION_FORM,
        payload,
    }),
    updatePersonCaseDamageLocationInfo: payload => dispatch({
        type: sasdActionTypes.SUBMIT_SASD_DAMAGE_LOCATION_INFO_FORM,
        payload,
    }),
    startSASDMapRelevantLocationWatcher: payload => dispatch({
        type: sasdActionTypes.START_SASD_MAP_RELEVANT_LOCATION_WATCHER,
        payload,
    }),
    stopSASDMapRelevantLocationWatcher: payload => dispatch({
        type: sasdActionTypes.STOP_SASD_MAP_RELEVANT_LOCATION_WATCHER,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SASDRelevantPlacesTab));
