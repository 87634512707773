import {take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Service case document upload flow (uses modal)
 */
const scDocumentUploadFlow = function* scDocumentUploadFlow() {
    while (true) {
        const {payload} = yield take([serviceCaseActionTypes.INITIATE_SC_DOCUMENT_UPLOAD_FLOW]);

        yield* openModal(modalIds.SC_DOCUMENT_UPLOAD);
        const scDocumentUploadAction = yield take([
            serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_UPLOAD,
            serviceCaseActionTypes.DECLINE_SC_DOCUMENT_UPLOAD,
        ]);
        const {serviceCaseId} = payload;

        if (scDocumentUploadAction.type === serviceCaseActionTypes.DECLINE_SC_DOCUMENT_UPLOAD) {
            yield* closeModal(modalIds.SC_DOCUMENT_UPLOAD);
            continue;
        }

        const {newAttachments} = scDocumentUploadAction.payload;
        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId];
        yield* updateServiceCase({
            caller: serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_UPLOAD,
            caseType: serviceCase.caseType,
            serviceCaseId,
            serviceCaseData: {
                attachments: [
                    ...serviceCase.attachments,
                    ...newAttachments,
                ],
            },
        });

        // NOTE: errors are handled implicitly as part of the fetchRequest (inside batch saga)
        // below you can handle something in addition if needed

        yield* closeModal(modalIds.SC_DOCUMENT_UPLOAD);
    }
};

export default scDocumentUploadFlow;
