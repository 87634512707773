import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import updateServiceCase from './updateServiceCase';

/**
 * Update service case basic data flow
 */
const updateSCBasicDataFlow = function* updateSCBasicDataFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_UPDATE_SC_BASIC_DATA_FLOW);
        const {serviceCaseData, caseType, serviceCaseId} = payload;

        yield* updateServiceCase({
            caller: serviceCaseActionTypes.INITIATE_UPDATE_SC_BASIC_DATA_FLOW,
            caseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceCaseActionTypes.INITIATE_UPDATE_SC_BASIC_DATA_FLOW},
        });
    }
};

export default updateSCBasicDataFlow;
