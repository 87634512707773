import {all, fork, takeLatest} from 'redux-saga/effects';
import * as samcActionTypes from './samcActionTypes';
import createServiceAssignmentMedicalClearanceFlow from './sagas/createServiceAssignmentMedicalClearanceFlow';
import updateSAMCCommissioningFlow from './sagas/updateSAMCCommissioningFlow';
import updateSAMCJourneyDetails from './sagas/updateSAMCJourneyDetails';
import samcMapDamageLocationWatcher from './sagas/samcMapDamageLocationWatcher';
import searchSAMCDamageLocationGeolocation from './sagas/searchSAMCDamageLocationGeolocation';
import updateSAMCDamageLocationInfo from './sagas/updateSAMCDamageLocationInfo';
import updateSAMCDamageLocationFlow from './sagas/updateSAMCDamageLocationFlow';
import updateSAMCServiceProviderFlow from './sagas/updateSAMCServiceProviderFlow';
import updateSAMCProblemAndDamageDetails from './sagas/updateSAMCProblemAndDamageDetails';

/**
 *  SAMC watcher saga
 */
const samcWatcher = function* samcWatcher() {
    yield all([
        fork(createServiceAssignmentMedicalClearanceFlow),
        fork(updateSAMCJourneyDetails),
        fork(updateSAMCCommissioningFlow),
        takeLatest(samcActionTypes.SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION, searchSAMCDamageLocationGeolocation),
        fork(updateSAMCDamageLocationFlow),
        fork(updateSAMCDamageLocationInfo),
        fork(samcMapDamageLocationWatcher),
        fork(updateSAMCServiceProviderFlow),
        fork(updateSAMCProblemAndDamageDetails),
    ]);
};

export default samcWatcher;
