import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sasdActionTypes from '../sasdActionTypes';
import modalIds from '../../modalIds';

/**
 * Update SASD journey details data
 */
const updateSASDJourneyDetails = function* updateSASDJourneyDetails() {
    while (true) {
        const {payload} = yield take(sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM);
        const {location} = yield select(state => state.router);
        // do not PATCH journey data when modal is open,
        // neither confirm vehicle selection modal nor vehicle data modal
        if (location?.state?.isModalOpen
            && [modalIds.CONFIRM_VEHICLE_SELECTION, modalIds.VEHICLE_DATA].includes(location?.state?.modalId)
        ) {
            continue;
        }

        const {serviceCaseData, serviceCaseId, serviceAssignmentData, serviceAssignmentLineNo, caseType} = payload;

        yield* updateServiceCase({
            caller: sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM,
            caseType: caseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield* updateServiceAssignment({
            caller: sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM,
            assignmentType: efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM},
        });
    }
};

export default updateSASDJourneyDetails;
