import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CommissioningRelevantPlacesPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sasp_commissioning_tab');
    const {serviceAssignment, newestMedicalClearanceAssignment} = props;

    const assignment = useMemo(() => {
        // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
        if (![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
            .includes(serviceAssignment.assignmentStatus)
        ) {
            return serviceAssignment;
        }
        return newestMedicalClearanceAssignment || null;
    }, [serviceAssignment, newestMedicalClearanceAssignment]);

    const formatChildrenAtSite = () => {
        if (!serviceAssignment?.childrenAtSite) return '';
        let outputString = `${serviceAssignment.childrenAtSite}`;
        serviceAssignment?.childrenAge.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceAssignment.childrenAge.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')}) ${translateTab('text.children')}`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    // if no assignment, don't render
    if (!assignment) return null;

    return (
        <Panel
            title={translateTab('panel_title.relevant_places')}
            qaIdent="sasp-commissioning-relevant-places"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.vehicle_pickup_location')}
                        qaIdent="sc-vehicle-pickup-up-location-address"
                    >
                        {assignment?.serviceLocation?.formattedAddress || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.pickup_location_notes')}
                        qaIdent="sc-vehicle-pickup-up-location-notes"
                    >
                        {assignment?.serviceLocation?.locationNotes || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.clinic_number')}
                        qaIdent="sa-clinic-number"
                    >
                        {assignment?.clinicNumber || '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.destination')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-destination-address"
                    >
                        {assignment?.destination?.formattedAddress || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.people_at_site')}
                        qaIdent="sc-people-at-site"
                    >
                        {serviceAssignment?.grownUpsAtSite
                            ? `${serviceAssignment.grownUpsAtSite} ${translateTab('text.adults')}${serviceAssignment?.childrenAtSite ? ', ' : ''}`
                            : ''}
                        {formatChildrenAtSite()}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CommissioningRelevantPlacesPanel.propTypes = {
    newestMedicalClearanceAssignment: PropTypes.object,
    serviceAssignment: PropTypes.object,
};

CommissioningRelevantPlacesPanel.defaultProps = {
    newestMedicalClearanceAssignment: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningRelevantPlacesPanel));
