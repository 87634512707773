import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {resolveRoute, replace} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';

const sendMessageFlow = function* sendMessageFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    let shouldWaitForAction = true;

    while (true) {
        if (shouldWaitForAction) {
            yield take(caseLogActionTypes.INITIATE_SENDING_MESSAGES_FLOW);
        }

        yield* openModal(modalIds.SEND_MESSAGE);

        const chosenModalOption = yield take([
            caseLogActionTypes.DECLINE_SEND_MESSAGE,
            caseLogActionTypes.CONFIRM_SEND_MESSAGE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === caseLogActionTypes.CONFIRM_SEND_MESSAGE) {
            const {sendMessageData, serviceCaseId} = chosenModalOption.payload;

            yield fork(fetchRequest, serviceCaseActionTypes.SEND_MESSAGE_REQUEST,
                ecsFlowService.sendMessage, {
                    ...sendMessageData,
                });

            const responseAction = yield take([
                serviceCaseActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.SEND_MESSAGE_REQUEST_FAILED,
            ]);

            if (responseAction.error && responseAction.payload.response) {
                const {status} = responseAction.payload.response;
                yield put({
                    type: caseLogActionTypes.SET_ERROR_CODE,
                    payload: {
                        errorCode: status ? `${status}` : '',
                    },
                });

                shouldWaitForAction = false;
                continue;
            }

            replace(resolveRoute(routePaths.SERVICE_CASE_SECTION, {serviceCaseId}, {search: ''}));
        }
        yield* closeModal(modalIds.SEND_MESSAGE);

        yield put({
            type: caseLogActionTypes.RESET_ERROR_CODE,
        });

        shouldWaitForAction = true;
    }
};

export default sendMessageFlow;
