import {all, fork} from 'redux-saga/effects';
import handleShiftRoleUpdateErrorFlow from './sagas/handleShiftRoleUpdateErrorFlow';
import updateUserShiftRoleFlow from './sagas/updateUserShiftRoleFlow';

/**
 *  User profile watcher saga
 */
const userProfileWatcher = function* userProfileWatcher() {
    yield all([
        fork(updateUserShiftRoleFlow),
        fork(handleShiftRoleUpdateErrorFlow),
    ]);
};

export default userProfileWatcher;
