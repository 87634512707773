import {eventChannel} from 'redux-saga';
import {select, take, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const onSAMAMapClickSelectServiceProvider = function* onSAMAMapClickSelectServiceProvider(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');
    const samaServiceProviderLayer = yield call(arcGISMap.getLayer, 'sama-service-providers-layer');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (samaServiceProviderLayer) {
            const unsubscribeClickListener = samaServiceProviderLayer.on('feature-select', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: serviceProviderFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (serviceProviderFeatureDTO && !serviceProviderFeatureDTO.isSelected) {
            const selectedServiceProviderFID = serviceProviderFeatureDTO['FID'];
            const {medicalAdviceServiceProviders} = yield select(state => state.serviceAssignments);
            const selectedServiceProvider = medicalAdviceServiceProviders.find((serviceProvider, index) => (
                index === selectedServiceProviderFID
            ));

            if (!selectedServiceProvider) continue;

            yield* updateServiceAssignment({
                caller: 'ON_SAMA_MAP_CLICK_SELECT_SERVICE_PROVIDER',
                assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        id: null,
                        externalId: selectedServiceProvider.id,
                        location: {
                            ...selectedServiceProvider.location,
                            ...selectedServiceProvider.address,
                            id: selectedServiceProvider.arcGISData.locationId,
                        },
                        name: selectedServiceProvider.name || '',
                        businessContactDetails: selectedServiceProvider.businessContactDetails,
                    },
                },
            });
        }
    }
};

export default onSAMAMapClickSelectServiceProvider;
