import {put, fork, take, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadContractPartnerTemporaryRestrictions = function* loadContractPartnerTemporaryRestrictions({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const {location} = yield select(state => state.router);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {activeTab} = yield* selectSearchQueryParams();

    // do not load contract partner temporary restrictions if agent is not on that tab, or on basic data tab
    if (![
        contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS,
        contractPartnerScreenTabs.BASIC_DATA,
    ].includes(activeTab)) return;

    // do not reload contract partner restrictions when TR creation/edit modal is open
    if ([
        modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
        modalIds.CP_EDIT_TEMPORARY_RESTRICTION,
        // on basic data tab, we have to load temporary restrictions, but not when qm creation modal is open
        modalIds.CP_CREATE_QUALITY_REPORT,
    ].includes(location.state?.modalId) && location.state?.isModalOpen) return;

    const {match} = payload;
    const {contractPartnerId} = match.params;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST,
        partnerManagementService.getACEPartnerRestrictions,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS,
        payload: {contractPartnerId, contractPartnerRestrictionDTOs: response.acePartnerRestrictionDTOs},
    });
};

export default loadContractPartnerTemporaryRestrictions;
