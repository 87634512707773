import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Modal, TextAreaField, ButtonPrimary, Divider} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

const EditDocumentDescriptionModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_document_description_modal');
    const {confirmDocumentDescriptionChanges, declineDocumentDescriptionChanges, attachmentGroup} = props;
    const [documentDescription, setDocumentDescription] = useState(attachmentGroup[0]?.description || '');

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            title={translateModal('modal_title.edit_description')}
            action={(
                <InteractiveIcon icon={closeIcon} onClick={declineDocumentDescriptionChanges} />
            )}
            contentClassName={cx('global!ace-u-modal-content-size--m')}
        >
            <Divider className={cx('global!ace-u-margin--32-0')} />
            <TextAreaField
                className={cx('global!ace-u-full-width')}
                contentClassName={cx('ace-c-text-area--medium')}
                label={translateModal('text_area_label.description')}
                maxLength={config.DOCUMENT_UPLOAD_DESCRIPTION_MAX_LENGTH}
                isResizable={false}
                value={documentDescription}
                onChange={setDocumentDescription}
            />
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    className={cx('global!ace-u-margin--top-32')}
                    onClick={() => { confirmDocumentDescriptionChanges({newDescription: documentDescription}); }}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

EditDocumentDescriptionModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmDocumentDescriptionChanges: PropTypes.func.isRequired,
    declineDocumentDescriptionChanges: PropTypes.func.isRequired,
    attachmentGroup: PropTypes.array.isRequired,
};

EditDocumentDescriptionModal.defaultProps = {
    hasBackdrop: true,
};

const mapStateToProps = (state, props) => {
    const getServiceCaseAttachmentGroup = serviceCaseSelectors.createServiceCaseAttachmentsGroupSelector();

    return {
        attachmentGroup: getServiceCaseAttachmentGroup(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmDocumentDescriptionChanges: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION,
        payload,
    }),
    declineDocumentDescriptionChanges: payload => dispatch({
        type: serviceCaseActionTypes.DECLINE_UPDATE_DOCUMENT_DESCRIPTION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentDescriptionModal);
