import {put, select, take, fork, call} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes, Location, BusinessContactDetails} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentHelpers from '../../service-assignments/sagas/serviceAssignmentHelpers';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saaActionTypes from '../saaActionTypes';
import saaScreenTabs from '../saaScreenTabs';

const loadSAAAccommodationLocations = function* loadSAAAccommodationLocations({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== saaScreenTabs.ACCOMMODATION) return;

    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const {memberLocation, referencePosition, acePartner} = serviceAssignment;
    const acePartnerLocation = acePartner?.location;
    if (!serviceAssignment || !(memberLocation || referencePosition)) return;

    yield put({
        type: saaActionTypes.SET_SAA_ACCOMMODATION_LOCATION_RECOMMENDATIONS,
        payload: {serviceAssignmentId, accommodationLocationRecommendationDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const i18nService = serviceManager.loadService('i18nService');
    const {translate} = i18nService;
    const googlePlacesService = serviceManager.loadService('googlePlacesService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-accommodation');
    if (!arcGISMap) return;

    const saaAccommodationLocationsLayer = yield call(arcGISMap.getLayer, 'saa-accommodation-locations');
    if (!saaAccommodationLocationsLayer) return;

    yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);

    arcGISMap.setMapViewZoomLevel(11);
    const searchPoint = memberLocation || referencePosition;
    const {longestDistance} = yield call(arcGISMap.getLongestDistanceFromPoint, searchPoint);
    const mapViewport = yield call(arcGISMap.getMapViewport);
    yield fork(
        fetchRequest,
        saaActionTypes.SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST,
        googlePlacesService.searchPlaces,
        {
            searchQuery: `${searchPoint?.formattedAddress} ${translate('saa_accommodation_tab.input.search_query_string')}`,
            longitude: searchPoint.longitude,
            latitude: searchPoint.latitude,
            locationRadius: Math.floor(longestDistance) * 1000 || null,
            viewport: mapViewport
                ? {
                    southwest: mapViewport.southwest,
                    northeast: mapViewport.northeast,
                }
                : null,
        },
    );

    const placesSearchResponseAction = yield take([
        saaActionTypes.SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_FAILED,
        saaActionTypes.SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED,
    ]);

    if (placesSearchResponseAction.error) {
        yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
        return;
    }

    const {response: placesSearchResponse} = placesSearchResponseAction.payload;
    const {googlePlaceDTOs} = placesSearchResponse;

    const accommodationLocationAddressList = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        if (googlePlaceDTO.formattedAddress) {
            accommodationLocationAddressList.push({
                'OBJECTID': index, // should always be OBJECTID (not FID) for searchBulkAddressLocations
                'SingleLine': googlePlaceDTO.formattedAddress,
            });
        }
    });

    yield fork(
        fetchRequest,
        saaActionTypes.FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST,
        arcGISRESTService.searchBulkAddressLocations,
        {
            addressList: accommodationLocationAddressList,
            returnRoutes: true,
            referentialPoint: searchPoint,
        },
    );

    const fetchGeolocationsResponseAction = yield take([
        saaActionTypes.FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED,
        saaActionTypes.FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_FAILED,
    ]);

    if (fetchGeolocationsResponseAction.error) {
        yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
        return;
    }

    const {response: fetchGeolocationsResponse} = fetchGeolocationsResponseAction.payload;
    const {arcGISGeocodingResults} = fetchGeolocationsResponse;

    let accommodationLocationRecommendationDTOs = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        const arcGISGeocodingCandidateDTO = arcGISGeocodingResults.find(arcGISGeocodingCandidateDTO => {
            return arcGISGeocodingCandidateDTO.resultId === index;
        });
        if (arcGISGeocodingCandidateDTO) {
            accommodationLocationRecommendationDTOs.push(
                {
                    ...googlePlaceDTO,
                    ...arcGISGeocodingCandidateDTO,
                    locationId: `${googlePlaceDTO.locationId}_${arcGISGeocodingCandidateDTO.locationId}`,
                    locationName: googlePlaceDTO.locationName,
                    formattedAddress: googlePlaceDTO.formattedAddress,
                },
            );
        }
    });

    accommodationLocationRecommendationDTOs = accommodationLocationRecommendationDTOs
        .sort((locationA, locationB) => {
            if (!locationA['routeToReferentialPoint']
                || !locationB['routeToReferentialPoint']) return 0;

            const {routeToReferentialPoint: locationARoute} = locationA;
            const {routeToReferentialPoint: locationBRoute} = locationB;
            return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
        });
    const recommendedAccommodationLocationDTO = accommodationLocationRecommendationDTOs[0];
    let updatedAccommodationLocationId = null;

    // Determine if selected SP is within suggestion results
    let selectedLocationFromSuggestions = null;
    if (serviceAssignment.acePartner && acePartnerLocation) {
        const {locationName, id: locationId} = acePartnerLocation;

        selectedLocationFromSuggestions = accommodationLocationRecommendationDTOs
            .find(accommodationLocationDTO => {
                return accommodationLocationDTO['locationName'] === locationName
                    && accommodationLocationDTO['locationId'] === locationId;
            });

        if (!selectedLocationFromSuggestions) {
            accommodationLocationRecommendationDTOs.unshift({
                ...acePartnerLocation,
                locationId: acePartnerLocation.id,
                locationName: serviceAssignment?.acePartner?.name || locationName,
                phoneNo: serviceAssignment.acePartner.businessContactDetails?.phoneNo || acePartnerLocation.phoneNo,
            });
            updatedAccommodationLocationId = locationId;
        }

        // patch new version of ace partner's ID
        if (selectedLocationFromSuggestions && selectedLocationFromSuggestions.locationId !== locationId) {
            yield* updateServiceAssignment({
                caller: saaActionTypes.SUBMIT_SAA_ACCOMMODATION_LOCATION_FORM,
                assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        ...serviceAssignment.acePartner,
                        id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                        externalId: selectedLocationFromSuggestions.locationId,
                        location: {
                            ...acePartnerLocation,
                            locationId: selectedLocationFromSuggestions.locationId,
                        },

                    },
                },
            });
        }
    }

    // render markers for accommodation location options
    if (accommodationLocationRecommendationDTOs.length > 0) {
        yield call(saaAccommodationLocationsLayer.setFeatures, {
            features: accommodationLocationRecommendationDTOs.map((accommodationLocationDTO, index) => {
                let geometry = accommodationLocationDTO['arcGISPoint'];
                if (!geometry && accommodationLocationDTO['longitude'] && accommodationLocationDTO['latitude']) {
                    geometry = {
                        type: 'point',
                        x: accommodationLocationDTO['longitude'],
                        y: accommodationLocationDTO['latitude'],
                    };
                }
                if (!geometry) return null;

                return {
                    attributes: {
                        FID: index,
                        locationId: accommodationLocationDTO['locationId'],
                    },
                    geometry,
                };
            }).filter(Boolean),
        });
    }

    // select feature for current accommodation location or the recommended one
    const selectedAccommodationLocationId = selectedLocationFromSuggestions
        ? selectedLocationFromSuggestions.locationId
        : acePartnerLocation
            ? acePartnerLocation.id
            : (recommendedAccommodationLocationDTO ? recommendedAccommodationLocationDTO['locationId'] : null);

    if (selectedAccommodationLocationId) {
        yield call(saaAccommodationLocationsLayer.selectFeatureByAttribute, {
            where: `locationId = '${selectedAccommodationLocationId}'`,
        });
    }

    // save recommended accommodation location as selected if no accommodation location was previously set
    if ((!serviceAssignment.acePartner || !serviceAssignment.acePartner.location)
        && recommendedAccommodationLocationDTO) {
        const recommendedAccommodationLocation = new Location().fromDTO(recommendedAccommodationLocationDTO);
        const businessContactDetails = new BusinessContactDetails({
            phoneNo: recommendedAccommodationLocation.phoneNo || '',
        });

        yield* updateServiceAssignment({
            caller: 'LOAD_SAA_ACCOMMODATION_LOCATIONS',
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                    externalId: recommendedAccommodationLocation.id,
                    location: {
                        ...recommendedAccommodationLocation,
                        locationId: recommendedAccommodationLocation.id,
                    },
                    name: recommendedAccommodationLocation.locationName || '',
                    businessContactDetails,
                    contactDetails: null,
                },
            },
        });

        // set persistence state back to PENDING
        yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);
    }

    yield put({
        type: saaActionTypes.SET_SAA_ACCOMMODATION_LOCATION_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            accommodationLocationRecommendationDTOs,
            recommendedAccommodationLocationId: recommendedAccommodationLocationDTO
                ? recommendedAccommodationLocationDTO['locationId'] : null,
            updatedAccommodationLocationId: updatedAccommodationLocationId,
        },
    });

    // display layers
    saaAccommodationLocationsLayer.show();
    yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
};

export default loadSAAAccommodationLocations;
