import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savActionTypes from '../savActionTypes';
import savScreenTabs from '../savScreenTabs';

const loadSAVMapTowingDestinationWatcher = function* loadSAVMapTowingDestinationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.TOWING_DESTINATION) return;

    yield put({
        type: savActionTypes.START_SAV_MAP_TOWING_DESTINATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAVMapTowingDestinationWatcher;
