import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savaActionTypes from '../savaActionTypes';
import savaScreenTabs from '../savaScreenTabs';

const loadSAVAMapServiceLocationWatcher = function* loadSAVAMapServiceLocationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savaScreenTabs.SERVICE_LOCATION) return;

    yield put({
        type: savaActionTypes.START_SAVA_MAP_SERVICE_LOCATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAVAMapServiceLocationWatcher;
