import {take, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const scDocumentDownloadFlow = function* scDocumentDownloadFlow() {
    const {serviceManager} = yield select(state => state.application);
    const efFileAssetsService = serviceManager.loadService('efFileAssetsService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(fetchRequest, serviceCaseActionTypes.DOWNLOAD_SC_DOCUMENT_REQUEST,
            efFileAssetsService.downloadFile, {
                fileName,
                url,
            });
    }
};

export default scDocumentDownloadFlow;
