import {take, fork, put, select} from 'redux-saga/effects';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Fetch SARC ACE partner stations flow
 */
const fetchSARCACEPartnerStationsFlow = function* fetchSARCACEPartnerStationsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(sarcActionTypes.FETCH_SARC_ACE_PARTNER_STATIONS_FLOW);
        const {acePartnerId, partnerType} = payload;

        yield fork(
            fetchRequest,
            sarcActionTypes.FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST,
            partnerManagementService.getACEPartnerRentalCarStations,
            {acePartnerId},
        );

        const responseAction = yield take([
            sarcActionTypes.FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_SUCCEEDED,
            sarcActionTypes.FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {rentalCarStationDTOs} = response;

            yield put({
                type: serviceAssignmentActionTypes.STORE_SARC_ACE_PARTNER_STATIONS,
                payload: {
                    serviceProviderId: acePartnerId,
                    rentalCarStationDTOs,
                },
            });
        }

        if (partnerType === apmACEPartnerTypes.SERVICE_PROVIDER) {
            yield fork(fetchRequest,
                sarcActionTypes.FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST,
                partnerManagementService.getACEPartnerRentalCarSIPPCodes, {
                    acePartnerId,
                });

            const responseActionOfSippCodes = yield take([
                sarcActionTypes.FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_SUCCEEDED,
                sarcActionTypes.FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_FAILED,
            ]);

            if (!responseActionOfSippCodes.error) {
                const {response} = responseActionOfSippCodes.payload;
                const {acePartnerSippCodeDTOs} = response;

                yield put({
                    type: serviceAssignmentActionTypes.STORE_RENTAL_CAR_ACE_PARTNER_SIPP_CODES,
                    payload: {serviceProviderId: acePartnerId, acePartnerSippCodeDTOs},
                });
            }
        }
    }
};
export default fetchSARCACEPartnerStationsFlow;
