import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import {ecsDashboardLayoutTypes, ecsLayouts, ecsDashboardNavigationLayoutTypes} from './ecsLayoutTypes';
import {ecsFeatures, ecsFeatureActions} from './ecsFeatures';
import routePaths from '../routePaths';
// Note: later on, this could be moved to the DB and used through reducer

/**
* ECS system available roles
*/
const ecsAuthorizationRoles = {
    [eupUserRoleTypes.ADMIN]: {
        name: eupUserRoleTypes.ADMIN,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.ONGOING_LEGAL_CASE,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DEFAULT_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DEFAULT_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.TEAM_LEAD]: {
        name: eupUserRoleTypes.TEAM_LEAD,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.ONGOING_LEGAL_CASE,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_TEAM_LEAD_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_TEAM_LEAD_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.DISPATCHER_1]: {
        name: eupUserRoleTypes.DISPATCHER_1,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DISPATCHER_1_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_1_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.DISPATCHER_2]: {
        name: eupUserRoleTypes.DISPATCHER_2,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DISPATCHER_2_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_2_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.DISPATCHER_3]: {
        name: eupUserRoleTypes.DISPATCHER_3,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DISPATCHER_3_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_3_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.DISPO]: {
        name: eupUserRoleTypes.DISPO,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DISPO_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DISPO_DASHBOARD_NAVIGATION_LAYOUT,
        },
    },
    [eupUserRoleTypes.EMERGENCY_CALL_ADVISOR]: {
        name: eupUserRoleTypes.EMERGENCY_CALL_ADVISOR,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },
    [eupUserRoleTypes.INBOX]: {
        name: eupUserRoleTypes.INBOX,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.BLANK_CASE,
                ecsFeatures.FOREIGN_CASE,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG,
                ecsFeatures.CASE_LOG_MESSAGE,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.CASE_LOG_EXPORT,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.AGENT_IDENTITY,
            ],
            [ecsFeatureActions.UPDATE]: [
                ecsFeatures.SERVICE_CASE,
                ecsFeatures.SERVICE_ASSIGNMENT,
                ecsFeatures.CASE_LOCK,
                ecsFeatures.TASK,
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.MEMBER_NOTE,
                ecsFeatures.MEMBER_DATA,
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CONTRACT_PARTNER,
                ecsFeatures.CASE_LOG_FILES,
                ecsFeatures.SERVICE_CASE_FILES,
                ecsFeatures.SHIFT_ROLE_SELECTION,
            ],
            [ecsFeatureActions.DELETE]: [
                ecsFeatures.MEMBER_VEHICLE,
                ecsFeatures.CASE_LOG_FILES,
            ],
        },
        systems: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_INBOX_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_INBOX_DASHBOARD_NAVIGATION_LAYOUT,
        },
    },

    [eupUserRoleTypes.VIEWER]: {
        name: eupUserRoleTypes.VIEWER,
        features: {
            [ecsFeatureActions.CREATE]: [
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.CASE_LOG_NOTE,
            ],
            [ecsFeatureActions.READ]: [
                ecsFeatures.QUALITY_REPORT,
                ecsFeatures.TEMPORARY_RESTRICTION,
                ecsFeatures.CASE_LOG_NOTE,
                ecsFeatures.CASE_LOG_EXPORT,

            ],
            [ecsFeatureActions.UPDATE]: [],
            [ecsFeatureActions.DELETE]: [],
        },
        system: [clientTypes.ECS_CLIENT],
        forbiddenRoutes: [routePaths.TASK_SEARCH, routePaths.SHIFT_ROLE_SELECTION],
        allowedRoutes: [],
        layouts: {
            [ecsLayouts.DASHBOARD]: ecsDashboardLayoutTypes.ECS_DEFAULT_DASHBOARD_LAYOUT,
            [ecsLayouts.DASHBOARD_NAVIGATION]: ecsDashboardNavigationLayoutTypes.ECS_DEFAULT_DASHBOARD_NAVIGATION_LAYOUT, // eslint-disable-line max-len
        },
    },

};


export default ecsAuthorizationRoles;
