import {select, put, fork, take} from 'redux-saga/effects';
import {etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import config from '../../config';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import serviceCaseOverviewScreenTabs from '../serviceCaseScreenTabs';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';

const loadServiceCaseTaskCount = function* loadServiceCaseTaskCount({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    const isTaskListAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);

    if (!serviceCaseId
        || !Object.values(serviceCaseOverviewScreenTabs).includes(activeTab)
        || config.IS_FALLBACK_SYSTEM
        || !isTaskListAvailable
    ) return;

    const {serviceManager} = yield select(state => state.application);
    const emtFlowService = serviceManager.loadService('ecsTaskManagementService');

    yield fork(
        fetchRequest,
        taskActionTypes.FETCH_SERVICE_CASE_TASK_COUNT_REQUEST,
        emtFlowService.getServiceCaseTaskCount,
        {
            serviceCaseId,
            taskStatuses: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
        },
    );

    const responseAction = yield take([
        taskActionTypes.FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_SUCCEEDED,
        taskActionTypes.FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_FAILED,
    ]);

    if (responseAction.error) {
        // no-op
    }

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskCount} = response;

        yield put({
            type: taskActionTypes.STORE_SERVICE_CASE_TASK_COUNT,
            payload: {serviceCaseId, taskCount},
        });
    }
};

export default loadServiceCaseTaskCount;
