/**
 * @typedef {string} tabId
 */

/**
 * SARC screen tabs
 *
 * @enum {tabId}
 */
export default {
    REQUIREMENTS: 'requirements',
    SELECT_STATION: 'select-station',
    COST_COVERAGE: 'cost-coverage',
};
