/**
 * @typedef {string} tabId
 */

/**
 * SASD screen tabs
 *
 * @enum {tabId}
 */
export default {
    PROBLEM_DAMAGE: 'problem-damage',
    TRIP_DATA: 'trip-data',
    RELEVANT_PLACES: 'relevant-places',
    SERVICE_PROVIDER: 'service-provider',
    COMMISSIONING: 'commissioning',
};
