import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {withRouter, useHistory, Link, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceTypes, efServiceCaseStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, TableBody, Pill, Panel, NoResultsBlock, LabelWithIcon, InteractiveIcon, linkIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, breakdownIcon, historyIcon, vehicleOpeningIcon, recoveryIcon, towingIcon, noServiceIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';
import config from '../../config';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import TableLoaderSpinner from '../../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const serviceIcons = {
    [efServiceTypes.VEHICLE_OPENING]: vehicleOpeningIcon,
    [efServiceTypes.RECOVERY]: recoveryIcon,
    [efServiceTypes.ROADSIDE_ASSISTANCE]: breakdownIcon,
    [efServiceTypes.TOWING]: towingIcon,
    [efServiceTypes.NO_SERVICE]: noServiceIcon,
};

const ForeignAssistanceCasesPanel = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {foreignAssistanceCases, users, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;

    const openServiceCaseScreen = serviceCase => {
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceCase.id,
        }, {
            state: {previousPath: history.location.pathname},
        }));
    };

    return (
        <Panel
            title={translateScreen('foreign_assistance_cases_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={(
                <Link
                    to={resolveRoute(
                        routePaths.SERVICE_CASE_SEARCH,
                        {},
                        {search: prepareSearchQueryParams({
                            caseStatus: [efServiceCaseStatusTypes.OPEN, efServiceCaseStatusTypes.NEW],
                            commissionerId: `ne|${config.ACE_COMMISSIONER_ID}`,
                        })},
                    )}
                    className={cx([
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--color-secondary',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <InteractiveIcon
                            icon={linkIcon}
                            className={cx('ace-c-interactive-icon--reverse')}
                        >
                            {translateScreen('foreign_assistance_cases_panel_link_label.all')}
                        </InteractiveIcon>
                    </div>
                </Link>
            )}
        >
            <Table qaIdent="foreign-assistance-service-cases">
                {foreignAssistanceCases.length === 0 && !isInProgress && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                    )}
                            description={translateScreen('foreign_assistance_cases_panel_no_results.description')}
                            message={translateScreen('foreign_assistance_cases_panel_no_results.message')}
                        />
                    </TableCaption>
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="sc-created-at">
                            {translateScreen('foreign_assistance_cases_panel_data_row.created_at')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-status">
                            {translateScreen('foreign_assistance_cases_panel_data_row.status')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-id">
                            {translateScreen('foreign_assistance_cases_panel_data_row.case_id')}
                        </TableCell>
                        <TableCell qaIdentPart="member-personal-details-last-name">
                            {translateScreen('foreign_assistance_cases_panel_data_row.last_name')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-licence-plate-number">
                            {translateScreen('foreign_assistance_cases_panel_data_row.license_plate')}
                        </TableCell>
                        <TableCell qaIdentPart="damage-location-address">
                            {translateScreen('foreign_assistance_cases_panel_data_row.damage_location')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-service-type">
                            {translateScreen('foreign_assistance_cases_panel_data_row.service')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-commissioner">
                            {translateScreen('foreign_assistance_cases_panel_data_row.commissioner')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-created-by">
                            {translateScreen('foreign_assistance_cases_panel_data_row.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {foreignAssistanceCases.length > 0 && !isInProgress
                        && foreignAssistanceCases.map(serviceCase => {
                            const emergencyCallDate = serviceCase.emergencyCallDate
                                ? moment(serviceCase.emergencyCallDate).format('DD.MM.YYYY[,] HH:mm') : '';
                            const {vehicle, damage} = serviceCase;

                            return (
                                <TableRow
                                    key={serviceCase.id}
                                    onClick={() => openServiceCaseScreen(serviceCase)}
                                    qaIdentPart={serviceCase.id}
                                >
                                    <TableCell
                                        qaIdentPart="sc-created-at"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {emergencyCallDate}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-status"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                                            <Pill
                                                type={serviceCase.status === efServiceCaseStatusTypes.NEW
                                                    ? 'positive' : 'pending'}
                                            >
                                                {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                                            </Pill>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-id"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.id}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-last-name"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.member.personalDetails.surname}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="vehicle-licence-plate-number"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {vehicle?.licensePlateNumber || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="damage-location-address"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {damage && damage.location
                                            ? `${damage.location.city} ${damage.location.postCode}` : '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-service-type"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.serviceType ? (
                                            <LabelWithIcon
                                                className={cx('ace-c-label-with-icon--icon-reverse')}
                                                label={
                                                    serviceCase.serviceType
                                                        ? translate(`global.service_type.${serviceCase.serviceType.toLowerCase()}`)
                                                        : ''
                                                }
                                            >
                                                <Icon icon={serviceIcons[serviceCase.serviceType]} />
                                            </LabelWithIcon>
                                        ) : '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-commissioner"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.commissioner ? serviceCase.commissioner.name : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-created-by"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {users[serviceCase.createdBy]
                                            ? `${users[serviceCase.createdBy].firstName} ${users[serviceCase.createdBy]?.lastName}`
                                            : ''}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ForeignAssistanceCasesPanel.propTypes = {
    foreignAssistanceCases: PropTypes.array,
    users: PropTypes.object,
    requestState: PropTypes.object,
};

ForeignAssistanceCasesPanel.defaultProps = {
    foreignAssistanceCases: [],
    users: {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST);
    return {
        users: userProfileSelectors.getUsers(state),
        foreignAssistanceCases: serviceCaseSelectors.getServiceCaseSearchResults(state, props),
        requestState: createRequestStateSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ForeignAssistanceCasesPanel));
