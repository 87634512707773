import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, checkmarkIcon, svgIcons, Option, HighlightCircle, Icon} from '@ace-de/ui-components';

const serviceIcons = {
    VEHICLE: svgIcons.vehicleIcon,
    VEHICLE_OPENING: svgIcons.vehicleOpeningIcon,
    RECOVERY: svgIcons.recoveryIcon,
    ROADSIDE_ASSISTANCE: svgIcons.breakdownIcon,
    TOWING: svgIcons.towingIcon,
    NO_SERVICE: svgIcons.noServiceIcon,
    PICK_UP: svgIcons.pickupIcon,
    JUMP_START: svgIcons.noServiceIcon,
    RENTAL_CAR: svgIcons.carRentalIcon,
    ACCOMMODATION: svgIcons.overnightStayIcon,
    SHORT_TRIP: svgIcons.taxiIcon,
    RETURN_JOURNEY: svgIcons.flightIcon,
    SUBSTITUTE_DRIVER: svgIcons.personOffIcon,
    MEDICAL_CLEARANCE: svgIcons.hospitalIcon,
    SICK_PERSON: svgIcons.hospitalIcon,
    VEHICLE_RETURN: svgIcons.pickupIcon,
    OTHER_SERVICES: svgIcons.otherIcon,
    PICKUP: svgIcons.shipmentIcon,
    TRAILER_RETURN: svgIcons.trailerIcon,
    SERVICE_CASE: svgIcons.caseIcon,
    DEATH: svgIcons.deathCaseIcon,
    MEDICINE_SHIPMENT: svgIcons.medicationIcon,
    MEDICAL_ADVICE: svgIcons.healingIcon,
    LEGAL_ADVICE: svgIcons.legalIcon,
};

const AssignmentTypeOption = ({value, isSelected, label}) => {
    const {cx} = useStyles();

    return (
        <Option
            className={cx('global!ace-u-padding--left-0')}
            value={value}
            name={`option${value}`}
        >
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--small',
                            'global!ace-u-margin--right-16',
                            'ace-c-highlight-circle--primary',
                        ])}
                    >
                        <Icon
                            icon={serviceIcons[value]}
                            className={cx([
                                'ace-c-icon--m',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </HighlightCircle>
                    <span className={cx('global!ace-u-typography--variant-body-medium')}>
                        {label}
                    </span>
                </div>
                {isSelected && (
                    <Icon
                        icon={checkmarkIcon}
                        className={cx([
                            'ace-c-icon--color-success',
                        ])}
                    />
                )}
            </div>
        </Option>
    );
};

AssignmentTypeOption.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    isSelected: PropTypes.bool,
};

AssignmentTypeOption.defaultProps = {
    label: '',
    isSelected: false,
};

export default AssignmentTypeOption;
