import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sasdActionTypes from '../sasdActionTypes';

/**
 * Update SASD problem/damage details
 */
const updateSASDProblemAndDamageDetails = function* updateSASDProblemAndDamageDetails() {
    while (true) {
        const {payload} = yield take(sasdActionTypes.SUBMIT_SASD_PROBLEM_DAMAGE_FORM);
        const {serviceAssignmentData, serviceCaseId, serviceAssignmentLineNo} = payload;

        yield* updateServiceAssignment({
            caller: sasdActionTypes.SUBMIT_SASD_PROBLEM_DAMAGE_FORM,
            assignmentType: efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sasdActionTypes.SUBMIT_SASD_PROBLEM_DAMAGE_FORM},
        });
    }
};

export default updateSASDProblemAndDamageDetails;
