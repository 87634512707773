import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';

const pillTypes = {
    [efServiceAssignmentStatusTypes.NEW]: 'pending',
    [efServiceAssignmentStatusTypes.REJECTED]: 'negative',
    [efServiceAssignmentStatusTypes.CANCELED]: 'pending',
    [efServiceAssignmentStatusTypes.EUA_CANCELED]: 'negative',
    [efServiceAssignmentStatusTypes.ASSIGNED]: 'positive',
    [efServiceAssignmentStatusTypes.ACCEPTED]: 'positive',
    [efServiceAssignmentStatusTypes.DONE]: 'positive',
    [efServiceAssignmentStatusTypes.APPROACHING]: 'pending',
    [efServiceAssignmentStatusTypes.IN_SERVICE]: 'pending',
    [efServiceAssignmentStatusTypes.SERVICE_FINISHED]: 'positive',
};

export default pillTypes;
