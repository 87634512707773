import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {Panel, Divider, Form, SelectField, Option, InputField, ToggleSwitch, useStyles} from '@ace-de/ui-components';
import {Person, efPersonTypes, Address, ampVehicleCreationStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import * as savActionTypes from '../savActionTypes';
import config from '../../config';

const OwnerAndDriverPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_vehicle_tab');
    const {memberAddress, selectedVehicle, serviceCase, submitSAVVehicleOwnerAndDriver, serviceAssignment} = props;

    const [driverFormData, setDriverFormData] = useState({
        ...(serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase?.vehicle?.vehicleId)
            ? serviceCase?.vehicle?.driver || new Person()
            : (selectedVehicle ? selectedVehicle.driver : serviceCase.vehicle?.driver)),
    });
    const [ownerFormData, setOwnerFormData] = useState({
        ...(serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase?.vehicle?.vehicleId)
            ? serviceCase?.vehicle?.owner || new Person()
            : (selectedVehicle ? selectedVehicle.owner : serviceCase.vehicle?.owner)),
    });
    const [isDriverFormTouched, setIsDriverFormTouched] = useState(false);
    const [isOwnerFormTouched, setIsOwnerFormTouched] = useState(false);

    const driverData = {
        ...(serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase?.vehicle?.vehicleId)
            ? serviceCase?.vehicle?.driver || new Person()
            : (selectedVehicle ? selectedVehicle.driver : serviceCase.vehicle?.driver)),
    };

    const ownerData = {
        ...(serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase?.vehicle?.vehicleId)
            ? serviceCase?.vehicle?.owner || new Person()
            : (selectedVehicle ? selectedVehicle.owner : serviceCase.vehicle?.owner)),
    };

    const handleOnDriverFormChange = formValues => {
        !isDriverFormTouched && setIsDriverFormTouched(true);
        setDriverFormData({
            ...formValues,
            ...(typeof formValues.isIdenticalToMember === 'boolean'
                ? (formValues.isIdenticalToMember
                    ? {type: efPersonTypes.MEMBER}
                    : formValues.type && formValues.type !== efPersonTypes.MEMBER
                        ? {}
                        : {type: efPersonTypes.OTHER})
                : {}),
            // keep address data while address form is hidden (not rendered)
            ...(!formValues.address?.street
                ? {address: driverFormData.address}
                : {}),
        });
    };

    const handleOnOwnerFormChange = formValues => {
        !isOwnerFormTouched && setIsOwnerFormTouched(true);
        setOwnerFormData({
            ...formValues,
            ...(typeof formValues.isIdenticalToMember === 'boolean'
                ? (formValues.isIdenticalToMember
                    ? {type: efPersonTypes.MEMBER}
                    : formValues.type && formValues.type !== efPersonTypes.MEMBER
                        ? {}
                        : {type: efPersonTypes.OTHER})
                : {}),
            // keep address data while address form is hidden (not rendered)
            ...(!formValues.address?.street
                ? {address: ownerFormData.address}
                : {}),
        });
    };

    const handleOnTabChange = useCallback((vehicle, ownerFormData, driverFormData) => {
        const {lineNo, assignmentType} = serviceAssignment;
        const serviceCaseData = {
            vehicle: {
                ...(isOwnerFormTouched
                    ? {
                        owner: {
                            ...vehicle.owner,
                            type: typeof ownerFormData.isIdenticalToMember === 'boolean'
                                ? (ownerFormData.type
                                    ? ownerFormData.type
                                    : (ownerFormData.isIdenticalToMember ? efPersonTypes.MEMBER : efPersonTypes.OTHER)
                                ) : (ownerFormData.isIdenticalToMember === undefined ? null : efPersonTypes.UNKNOWN),
                            addressIdenticalToMember: typeof ownerFormData.isIdenticalToMember === 'boolean'
                                ? ownerFormData.isIdenticalToMember ? true : !!ownerFormData.addressIdenticalToMember
                                : null,
                            name: typeof ownerFormData.isIdenticalToMember === 'boolean'
                                ? (ownerFormData.isIdenticalToMember
                                    ? `${serviceCase.member.personalDetails.firstName} ${serviceCase.member.personalDetails.surname}`
                                    : `${ownerFormData.firstName || ''}${ownerFormData.lastName ? ` ${ownerFormData.lastName}` : ''}`
                                ) : null,
                            address: typeof ownerFormData.isIdenticalToMember === 'boolean'
                                ? (ownerFormData.isIdenticalToMember
                                    ? {...memberAddress}
                                    : (ownerFormData.addressIdenticalToMember
                                        ? {...memberAddress}
                                        : (ownerFormData.address
                                            ? {...ownerFormData.address}
                                            : {...new Address()})
                                    )
                                ) : {...new Address()},
                        },
                    } : (vehicle.vehicleId !== serviceCase.vehicle.vehicleId
                        ? {
                            owner: new Person(),
                        } : {})
                ),
                ...(isDriverFormTouched
                    ? {
                        driver: {
                            ...vehicle.driver,
                            type: typeof driverFormData.isIdenticalToMember === 'boolean'
                                ? (driverFormData.type
                                    ? driverFormData.type
                                    : (driverFormData.isIdenticalToMember ? efPersonTypes.MEMBER : efPersonTypes.OTHER)
                                ) : (driverFormData.isIdenticalToMember === undefined ? null : efPersonTypes.UNKNOWN),
                            addressIdenticalToMember: typeof driverFormData.isIdenticalToMember === 'boolean'
                                ? driverFormData.isIdenticalToMember ? true : !!driverFormData.addressIdenticalToMember
                                : null,
                            name: typeof driverFormData.isIdenticalToMember === 'boolean'
                                ? (driverFormData.isIdenticalToMember
                                    ? `${serviceCase.member.personalDetails.firstName} ${serviceCase.member.personalDetails.surname}`
                                    : `${driverFormData.firstName || ''}${driverFormData.lastName ? ` ${driverFormData.lastName}` : ''}`
                                ) : null,
                            address: typeof driverFormData.isIdenticalToMember === 'boolean'
                                ? (driverFormData.isIdenticalToMember
                                    ? {...memberAddress}
                                    : (driverFormData.addressIdenticalToMember
                                        ? {...memberAddress}
                                        : (driverFormData.address
                                            ? {...driverFormData.address}
                                            : {...new Address()})
                                    )
                                ) : {...new Address()},
                        },
                    } : (vehicle.vehicleId !== serviceCase.vehicle?.vehicleId
                        ? {
                            driver: new Person(),
                        } : {})
                ),
            },
        };
        submitSAVVehicleOwnerAndDriver({
            serviceCaseId: serviceCase.id,
            serviceCaseData,
            serviceAssignmentLineNo: lineNo,
            assignmentType,
        });
    }, [
        memberAddress,
        serviceCase.id,
        serviceCase.vehicle?.vehicleId,
        serviceCase.member.personalDetails.firstName,
        serviceCase.member.personalDetails.surname,
        submitSAVVehicleOwnerAndDriver,
        isDriverFormTouched,
        isOwnerFormTouched,
        serviceAssignment,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        const isVehicleSelected = serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && serviceCase?.vehicle?.creationStatus === ampVehicleCreationStatusTypes.COMPLETED) // eslint-disable-line max-len
            ? !!(serviceCase?.vehicle?.type && serviceCase?.vehicle?.manufacturer
                && serviceCase?.vehicle?.model && serviceCase?.vehicle?.licensePlateNumber)
            : !!selectedVehicle;

        if (!isVehicleSelected) {
            completeRouteUnmountSideEffect({
                caller: savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM,
            });
            return;
        }

        handleOnTabChange(
            serviceCase?.nonAceMember || (config.IS_FALLBACK_SYSTEM && !serviceCase?.vehicle?.vehicleId)
                ? serviceCase?.vehicle
                : selectedVehicle,
            ownerFormData,
            driverFormData,
        );
    }, [
        serviceCase?.nonAceMember,
        serviceCase?.vehicle,
        selectedVehicle,
        ownerFormData,
        driverFormData,
        handleOnTabChange,
    ]);

    const isFormDisabled = config.IS_FALLBACK_SYSTEM
        ? (selectedVehicle
            ? !serviceCase?.vehicle?.vehicleId
            : serviceCase?.vehicle?.creationStatus !== ampVehicleCreationStatusTypes.COMPLETED)
        : (serviceCase?.nonAceMember
            ? serviceCase?.vehicle?.creationStatus !== ampVehicleCreationStatusTypes.COMPLETED
            : !serviceCase?.vehicle?.vehicleId);

    return (
        <Panel title={translateTab('driver_and_owner_panel.title_owner')}>
            <Form name="vehicleOwner" onChange={handleOnOwnerFormChange}>
                {ownerFormValues => {
                    const allowedTypeOptions = ownerFormValues.isIdenticalToMember
                        ? [efPersonTypes.MEMBER]
                        : Object.keys(efPersonTypes)
                            .filter(type => ![efPersonTypes.UNKNOWN, efPersonTypes.MEMBER].includes(type));
                    return (
                        <div className={cx('global!ace-u-grid')}>
                            <SelectField
                                name="isIdenticalToMember"
                                className={cx('global!ace-u-grid-column--span-12')}
                                label={translateTab('select_label.is_owner_member')}
                                placeholder={translateTab('select_placeholder.please_select')}
                                value={ownerData.type
                                    ? (ownerData.type === efPersonTypes.UNKNOWN
                                        ? 'UNKNOWN'
                                        : ownerData.type === efPersonTypes.MEMBER
                                    ) : ''
                                }
                                isDisabled={isFormDisabled}
                            >
                                <Option
                                    name="isIdenticalToMemberYes"
                                    value={true}
                                >
                                    {translateTab('select_option_label.yes')}
                                </Option>
                                <Option
                                    name="isIdenticalToMemberNo"
                                    value={false}
                                >
                                    {translateTab('select_option_label.no')}
                                </Option>
                                <Option
                                    name="isIdenticalToMemberUnknown"
                                    value="UNKNOWN"
                                >
                                    {translateTab('select_option_label.unknown')}
                                </Option>
                            </SelectField>
                            {typeof ownerFormValues.isIdenticalToMember === 'boolean'
                            && !ownerFormValues.isIdenticalToMember
                            && (
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-grid-column--span-12',
                                    ])}
                                >
                                    <SelectField
                                        className={cx('global!ace-u-grid-column--span-12')}
                                        name="type"
                                        value={ownerData.type
                                            ? (ownerData.type === efPersonTypes.UNKNOWN ? '' : ownerData.type)
                                            : ''}
                                        label={translateTab('select_label.relationship_with')}
                                        placeholder={translateTab('select_placeholder.please_select')}
                                    >
                                        {
                                            allowedTypeOptions.map(personType => {
                                                const type = personType.toLowerCase();
                                                return (
                                                    <Option
                                                        name={`relationshipWithMember${type}`}
                                                        value={personType}
                                                        key={`ownersRelationship${type}`}
                                                    >
                                                        {translateTab(`select_option_label.${type}`)}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </SelectField>
                                    <InputField
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        name="firstName"
                                        value={ownerData.name?.split(' ')[0] || ''}
                                        label={translateTab('input_label.first_name')}
                                    />
                                    <InputField
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        name="lastName"
                                        label={translateTab('input_label.last_name')}
                                        value={ownerData.name?.split(' ')[1] || ''}
                                    />
                                    <ToggleSwitch
                                        name="addressIdenticalToMember"
                                        value={true}
                                        isSelected={!!ownerData.addressIdenticalToMember}
                                        className={cx([
                                            'global!ace-u-grid-column--span-12',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-row-reverse',
                                            'global!ace-u-flex--justify-space-between',
                                        ])}
                                    >
                                        {translateTab('toggle_switch_label.is_address_same')}
                                    </ToggleSwitch>
                                    {!ownerFormValues.addressIdenticalToMember && (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-grid-column--span-12',
                                            ])}
                                        >
                                            <Form name="address">
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-12')}
                                                    name="street"
                                                    value={ownerData.address?.street || ''}
                                                    label={translateTab('input_label.street_and_housenumber')}
                                                />
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-4')}
                                                    name="postCode"
                                                    value={ownerData.address?.postCode || ''}
                                                    label={translateTab('input_label.post_code')}
                                                />
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-8')}
                                                    name="city"
                                                    value={ownerData.address?.city || ''}
                                                    label={translateTab('input_label.city')}
                                                />
                                            </Form>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }}
            </Form>
            <Divider className={cx('global!ace-u-margin--32-0')} />
            <p className={cx(['global!ace-u-typography--variant-h3', 'global!ace-u-padding--bottom-32'])}>
                {translateTab('driver_and_owner_panel.title_driver')}
            </p>
            <Form name="vehicleDriver" onChange={handleOnDriverFormChange} onSubmit={() => {}}>
                {driverFormValues => {
                    const allowedTypeOptions = driverFormValues.isIdenticalToMember
                        ? [efPersonTypes.MEMBER]
                        : Object.keys(efPersonTypes)
                            .filter(type => ![efPersonTypes.UNKNOWN, efPersonTypes.MEMBER].includes(type));
                    return (
                        <div className={cx('global!ace-u-grid')}>
                            <SelectField
                                name="isIdenticalToMember"
                                className={cx('global!ace-u-grid-column--span-12')}
                                label={translateTab('select_label.is_driver_member')}
                                placeholder={translateTab('select_placeholder.please_select')}
                                value={driverData.type
                                    ? (driverData.type === efPersonTypes.UNKNOWN
                                        ? 'UNKNOWN'
                                        : driverData.type === efPersonTypes.MEMBER
                                    ) : ''
                                }
                                isDisabled={isFormDisabled}
                            >
                                <Option
                                    name="isIdenticalToMemberYes"
                                    value={true}
                                >
                                    {translateTab('select_option_label.yes')}
                                </Option>
                                <Option
                                    name="isIdenticalToMemberNo"
                                    value={false}
                                >
                                    {translateTab('select_option_label.no')}
                                </Option>
                                <Option
                                    name="isIdenticalToMemberUnknown"
                                    value="UNKNOWN"
                                >
                                    {translateTab('select_option_label.unknown')}
                                </Option>
                            </SelectField>
                            {typeof driverFormValues.isIdenticalToMember === 'boolean'
                            && !driverFormValues.isIdenticalToMember
                            && (
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-grid-column--span-12',
                                    ])}
                                >
                                    <SelectField
                                        className={cx('global!ace-u-grid-column--span-12')}
                                        name="type"
                                        value={driverData.type
                                            ? (driverData.type === efPersonTypes.UNKNOWN ? '' : driverData.type)
                                            : ''}
                                        label={translateTab('select_label.relationship_with')}
                                        placeholder={translateTab('select_placeholder.please_select')}
                                    >
                                        {
                                            allowedTypeOptions.map(personType => {
                                                const type = personType.toLowerCase();
                                                return (
                                                    <Option
                                                        name={`relationshipWithMember${type}`}
                                                        value={personType}
                                                        key={`driversRelationship${type}`}
                                                    >
                                                        {translateTab(`select_option_label.${type}`)}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </SelectField>
                                    <InputField
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        name="firstName"
                                        value={driverFormValues.isIdenticalToMember
                                            ? ''
                                            : driverData.name?.split(' ')[0] || ''}
                                        label={translateTab('input_label.first_name')}
                                    />
                                    <InputField
                                        className={cx('global!ace-u-grid-column--span-6')}
                                        name="lastName"
                                        value={driverFormValues.isIdenticalToMember
                                            ? ''
                                            : driverData.name?.split(' ')[1] || ''}
                                        label={translateTab('input_label.last_name')}
                                    />
                                    <ToggleSwitch
                                        name="addressIdenticalToMember"
                                        value={true}
                                        isSelected={!!driverData.addressIdenticalToMember}
                                        className={cx([
                                            'global!ace-u-grid-column--span-12',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-row-reverse',
                                            'global!ace-u-flex--justify-space-between',
                                        ])}
                                    >
                                        {translateTab('toggle_switch_label.is_address_same')}
                                    </ToggleSwitch>
                                    {!driverFormValues.addressIdenticalToMember && (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-grid-column--span-12',
                                            ])}
                                        >
                                            <Form name="address">
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-12')}
                                                    name="street"
                                                    value={driverData.address?.street || ''}
                                                    label={translateTab('input_label.street_and_housenumber')}
                                                />
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-4')}
                                                    name="postCode"
                                                    value={driverData.address?.postCode || ''}
                                                    label={translateTab('input_label.post_code')}
                                                />
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-8')}
                                                    value={driverData.address?.city || ''}
                                                    name="city"
                                                    label={translateTab('input_label.city')}
                                                />
                                            </Form>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }}
            </Form>
        </Panel>
    );
};

OwnerAndDriverPanel.propTypes = {
    memberAddress: PropTypes.object.isRequired,
    serviceCase: PropTypes.object,
    selectedVehicle: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSAVVehicleOwnerAndDriver: PropTypes.func.isRequired,
};

OwnerAndDriverPanel.defaultProps = {
    serviceCase: null,
    selectedVehicle: null,
    serviceAssignment: null,
};

const mapDispatchToProps = dispatch => ({
    submitSAVVehicleOwnerAndDriver: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(OwnerAndDriverPanel);
