import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sastActionTypes from '../sastActionTypes';

/**
 * Update SAST budget flow
 */
const updateSASTBudgetFlow = function* updateSASTBudgetFlow() {
    while (true) {
        const {payload} = yield take(sastActionTypes.SUBMIT_SAST_BUDGET_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sastActionTypes.SUBMIT_SAST_BUDGET_FORM,
            assignmentType: efServiceAssignmentTypes.SHORT_TRIP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sastActionTypes.SUBMIT_SAST_BUDGET_FORM},
        });
    }
};

export default updateSASTBudgetFlow;
