import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel} from '@ace-de/ui-components/app-layout';
import {useStyles, TextArea} from '@ace-de/ui-components';
import config from '../../config';

const DamageDescriptionPanel = ({damageDescription, setDamageDescription}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_damage_service_tab');

    return (
        <Panel title={translateTab('panel_title.damage_description')}>
            <TextArea
                name="damageDescription"
                value={damageDescription}
                placeholder={translateTab('input_placeholder.enter_damage_description')}
                isResizable={false}
                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                onChange={setDamageDescription}
                className={cx('global!ace-u-full-width')}
            />
            <p
                className={cx([
                    'global!ace-u-margin--top-8',
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                ])}
            >
                {`${damageDescription
                    ? damageDescription.length
                    : 0
                }/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                 ${translate('global.unit.characters')}`
                }
            </p>
        </Panel>
    );
};

DamageDescriptionPanel.propTypes = {
    damageDescription: PropTypes.string,
    setDamageDescription: PropTypes.func.isRequired,
};

DamageDescriptionPanel.defaultProps = {
    damageDescription: '',
};

export default DamageDescriptionPanel;
