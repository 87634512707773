import {tireIcon, lockIcon, vehicleIssuesIcon, accidentIcon, vehicleOpeningIcon, otherIcon, bikeIcon, theftIcon, eVehicleIcon, gasStationIcon} from '@ace-de/ui-components/icons';

/**
 * Hash map for icons in the damage decision flow
 */
export const decisionTreeIcons = {
    FLAT_TIRE: tireIcon,
    ACCIDENT_CRASH: accidentIcon,
    ENGINE_STOPPED_WHILE_DRIVING: vehicleIssuesIcon,
    PROBLEMS_WHILE_DRIVING: vehicleIssuesIcon,
    DOES_NOT_START: lockIcon,
    NO_ACCESS_TO_VEHICLE: vehicleOpeningIcon,
    BIKE_DAMAGE: bikeIcon,
    THEFT_BURGLARY: theftIcon,
    OTHER: otherIcon,
    E_VEHICLE: eVehicleIcon,
    INCORRECT_REFUELING: gasStationIcon,
};
