import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {Button, NoResultsBlock, Paginator, Panel, useStyles} from '@ace-de/ui-components';
import {historyIcon, Icon, plusIcon, InteractiveIcon, downloadIcon, editIcon, deleteIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../application/ecsFeatures';
import config from '../config';
import routePaths from '../routePaths';

const SCDocumentsTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_documents_tab');
    const {serviceCase, initiateDocumentUploadFlow, history} = props;
    const {initiateDocumentEditFlow, initiateDocumentDownloadFlow, initiateDocumentDeleteFlow} = props;
    const isUploadAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.CREATE,
    );
    const isDocumentUpdateAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.UPDATE,
    );
    const isDocumentDeletionAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.DELETE,
    );

    if (!serviceCase || config.IS_FALLBACK_SYSTEM) return null;

    const queryParams = new URLSearchParams(history.location.search);
    const paginatorCount = Math.ceil(serviceCase.attachments.length / config.DEFAULT_DOCUMENTS_TAB_PAGE_SIZE);
    const serviceCaseDocuments = serviceCase.attachments.map(attachment => attachment)
        .sort((attachmentA, attachmentB) => {
            return attachmentA.groupId - attachmentB.groupId;
        }).filter((attachment, index) => (
            (!queryParams.get('page') && index < config.DEFAULT_DOCUMENTS_TAB_PAGE_SIZE)
            || (index >= Number(queryParams.get('page')) * config.DEFAULT_DOCUMENTS_TAB_PAGE_SIZE
                && index < (Number(queryParams.get('page')) + 1) * config.DEFAULT_DOCUMENTS_TAB_PAGE_SIZE)
        ));

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceCase.id,
        }, {search: queryParamsString}));
    };

    return (
        <Panel
            title={translateTab('documents_panel.title')}
            actions={(
                <Button
                    onClick={() => initiateDocumentUploadFlow({serviceCaseId: serviceCase.id})}
                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING || !isUploadAvailable}
                >
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--color-highlight',
                        ])}
                    />
                    {translateTab('button_label.upload')}
                </Button>
            )}
            className={cx('ace-c-panel--full-bleed-content')}
            qaIdent="service-case-documents"
        >
            <Table>
                {serviceCase.attachments.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateTab('no_results.description')}
                            message={translateTab('no_results.message')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="uploaded-at">
                            {translateTab('data_row.date_time')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="attachment-type">
                            {translateTab('data_row.attachment_type')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="group">
                            {translateTab('data_row.group')}
                        </TableCell>
                        <TableCell colSpan={4} qaIdentPart="description">
                            {translateTab('data_row.description')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="file-name">
                            {translateTab('data_row.file_name')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="actions">
                            {translateTab('data_row.actions')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {serviceCaseDocuments.map(attachment => (
                        <TableRow
                            key={attachment.id}
                            qaIdentPart={attachment.id}
                        >
                            <TableCell colSpan={2} qaIdentPartPostfix={attachment.id}>
                                {attachment.createdAt || ''}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={attachment.id}>
                                {attachment.attachmentType
                                    ? translate(`global.service_case_document_type.${attachment.attachmentType.toLowerCase()}`)
                                    : ''}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPartPostfix={attachment.id}>
                                {attachment.groupId || ''}
                            </TableCell>
                            <TableCell colSpan={4} qaIdentPartPostfix={attachment.id}>
                                {attachment.description || ''}
                            </TableCell>
                            <TableCell colSpan={3} qaIdentPartPostfix={attachment.id}>
                                {attachment.fileName || ''}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPartPostfix={attachment.id}>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-space-between',
                                    ])}
                                >
                                    <InteractiveIcon
                                        icon={downloadIcon}
                                        onClick={() => initiateDocumentDownloadFlow({
                                            fileName: attachment.fileName,
                                            url: attachment.url,
                                        })}
                                        className={cx('ace-c-interactive-icon--primary')}
                                    />
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => initiateDocumentEditFlow({
                                            attachmentId: attachment.id,
                                            serviceCaseId: serviceCase.id,
                                            groupId: attachment.groupId,
                                        })}
                                        className={cx('ace-c-interactive-icon--primary')}
                                        isDisabled={!isDocumentUpdateAvailable}
                                    />
                                    <InteractiveIcon
                                        icon={deleteIcon}
                                        onClick={() => initiateDocumentDeleteFlow({
                                            serviceCaseId: serviceCase.id,
                                            attachmentId: attachment.id,
                                        })}
                                        className={cx('ace-c-interactive-icon--primary')}
                                        isDisabled={!isDocumentDeletionAvailable}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {serviceCaseDocuments.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Panel>
    );
};

SCDocumentsTab.propTypes = {
    serviceCase: PropTypes.object,
    history: PropTypes.object.isRequired,
    initiateDocumentUploadFlow: PropTypes.func.isRequired,
    initiateDocumentDownloadFlow: PropTypes.func.isRequired,
    initiateDocumentDeleteFlow: PropTypes.func.isRequired,
    initiateDocumentEditFlow: PropTypes.func.isRequired,
};

SCDocumentsTab.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDocumentUploadFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SC_DOCUMENT_UPLOAD_FLOW,
        payload,
    }),
    initiateDocumentDownloadFlow: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
    initiateDocumentDeleteFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SC_DOCUMENT_DELETE_FLOW,
        payload,
    }),
    initiateDocumentEditFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_UPDATE_DOCUMENT_DESCRIPTION_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCDocumentsTab));
