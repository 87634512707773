import React, {useContext, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Parser} from 'html-to-react';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import {useStyles, SearchBox, Option} from '@ace-de/ui-components';
import {Icon, personIcon, officeIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';
import MemberSearchContext from '../../members/MemberSearchContext';
import config from '../../config';
import * as memberActionTypes from '../../members/memberActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';

const HTMLParser = new Parser();

const MemberSearchBox = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {searchQueryString, setSearchQueryString} = useContext(MemberSearchContext);
    const {
        isSearchRouteActive,
        searchResults,
        openSearchResultsScreen,
        areSearchResultsValid,
    } = useContext(MemberSearchContext);
    const {translate} = useTranslate();
    const {requestState} = props;
    const {translationKey, error} = requestState;

    const redirectToMember = member => {
        if (member) {
            history.push(resolveRoute(routePaths.MEMBER, {
                membershipNo: member.membershipNo,
            }));
        }
    };

    const highlightTerm = data => {
        if (searchQueryString.length < config.MINIMUM_SEARCH_QUERY_LENGTH) return '';
        let searchTermRegex = new RegExp(searchQueryString, 'ig');
        const multiWordSearchQueryString = searchQueryString.replace(/\s+/g, ' ').trim().split(' ');
        if (multiWordSearchQueryString.length > 1) {
            // multi-word string
            let multiWordPattern = '';
            multiWordSearchQueryString.forEach((word, idx) => {
                multiWordPattern = multiWordPattern.concat(word);
                if (idx !== multiWordSearchQueryString.length - 1) {
                    multiWordPattern = multiWordPattern.concat('|');
                }
            });
            searchTermRegex = new RegExp(multiWordPattern, 'ig');
        }
        let newResult = '';
        let highlightedParts;
        let lastIndex = 0;

        while ((highlightedParts = searchTermRegex.exec(data)) !== null) {
            if (highlightedParts[0]) {
                newResult = newResult.concat(data.substring(lastIndex, highlightedParts.index));
                newResult = newResult.concat(
                    '<em class="ace-u-typography--color-highlighted ace-u-typography--variant-body-bold">'
                    + highlightedParts[0]
                    + '</em>',
                );
                lastIndex = searchTermRegex.lastIndex;
            }
        }
        if (lastIndex !== data.length - 1) {
            newResult = newResult.concat(data.substring(lastIndex));
        }

        return HTMLParser.parse(newResult);
    };

    return !isSearchRouteActive ? (
        <SearchBox
            name="memberSearchBox"
            value={searchQueryString}
            onChange={setSearchQueryString}
            onSearchSubmit={openSearchResultsScreen}
            onOptionSelect={redirectToMember}
            isDropDownDisabled={!areSearchResultsValid}
            placeholder={translate('navigation_bar.member_search_box_placeholder.search')}
        >
            {areSearchResultsValid && searchResults.length > 0 && !error ? (
                <Fragment>
                    {searchResults.slice(0, 10).map(member => {
                        const {personalDetails} = member;
                        const {address} = personalDetails;
                        const optionLabel = `${member.membershipNo} ${personalDetails.displayName} ${address.street} ${address.postCode} ${address.city}`;

                        return (
                            <Option
                                key={member.id}
                                name={`member-${member.id}`}
                                value={member}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <Icon
                                        icon={member.membershipType === ampMembershipTypes.PRIVATE
                                            ? personIcon : officeIcon}
                                        className={cx('global!ace-u-margin--right-16')}
                                    />
                                    <span>
                                        {highlightTerm(optionLabel)}
                                    </span>
                                </div>
                            </Option>
                        );
                    })}
                </Fragment>
            ) : (
                <Option
                    name="no-member-found"
                    value={null}
                    isDisabled={true}
                >
                    {error
                        ? (
                            <span className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                                <Icon
                                    icon={infoAlertIcon}
                                    className={cx([
                                        'ace-c-icon--s',
                                        'global!ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-4',
                                    ])}
                                />
                                {translate(`failed_request_block.error_message.${translationKey}`)}
                            </span>
                        ) : translate('navigation_bar.member_search_box_results.no_results_message')}
                </Option>
            )}
        </SearchBox>
    ) : null;
};

MemberSearchBox.propTypes = {
    requestState: PropTypes.object,
};

MemberSearchBox.defaultProps = {
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(memberActionTypes.SEARCH_MEMBERS_REQUEST);
    return {
        requestState: createRequestStateSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(MemberSearchBox);
