import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams, matchPath} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes, efServiceCaseTypes, ehmCaseLogCategoryTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, ButtonPrimary, Badge} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar, NavigationBar, NotificationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import {Icon, plusIcon, closedIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import routePaths from '../routePaths';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import * as caseLogActionTypes from '../case-logs/caseLogActionTypes';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as taskSelectors from '../tasks/taskSelectors';
import FallbackSystemNotificationBar from '../application/ui-elements/FallbackSystemNotificationBar';
import serviceCaseScreenTabs from './serviceCaseScreenTabs';
import SCBasicDataTab from './SCBasicDataTab';
import SCServiceAssignmentsTab from './SCServiceAssignmentsTab';
import SCTasksTab from './SCTasksTab';
import SCProtocolTab from './SCProtocolTab';
import ServiceCaseHeader from './ui-elements/ServiceCaseHeader';
import useBeforeUnloadEffect from './useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import ToDoTasksBanner from './ui-elements/ToDoTasksBanner';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../application/ecsFeatures';
import SCDocumentsTab from './SCDocumentsTab';

const ServiceCaseScreen = props => {
    const {cx} = useStyles();
    const {serviceCase, match, users, user, initiateCreateCaseLogViewedFlow, history} = props;
    const {initiateSelectAdditionalServicesFlow, taskCount, initiateCreateCaseLogLeftFlow} = props;
    const {latestToDoTask, toDoTaskCount, initialServiceAssignment} = props;
    const {params} = match;
    const {serviceCaseId} = params;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('service_case_screen');
    const {activeTab} = useSearchQueryParams();
    const serviceCaseScreenTabsRef = useRef();
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const isAssignmentCreationAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_ASSIGNMENT,
        ecsFeatureActions.CREATE,
    );
    const isTaskListAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    useBeforeUnloadEffect(event => {
        return {
            unlockRequest: ecsFlowService.unlockServiceCase,
            requestParams: {serviceCaseId},
        };
    });

    useEffect(() => {
        // Edge case that needs to be resolved: If agent manually changes serviceCaseId path param from the URL.

        const {state} = history.location;

        const shouldInitiateCaseLogViewed = state && state.previousPath ? !matchPath(state.previousPath, {
            path: routePaths.SERVICE_CASE_SECTION,
        }) : false;

        if (serviceCaseId && shouldInitiateCaseLogViewed) {
            initiateCreateCaseLogViewedFlow({
                serviceCaseId,
            });
        }
    }, [serviceCaseId, initiateCreateCaseLogViewedFlow, history.location]);

    useEffect(() => {
        return () => {
            const shouldInitiateCaseLogLeft = !matchPath(history.location.pathname, {
                path: routePaths.SERVICE_CASE_SECTION,
            });

            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (serviceCaseId && shouldInitiateCaseLogLeft && !serviceCaseScreenTabsRef.current) {
                initiateCreateCaseLogLeftFlow({
                    serviceCaseId,
                });
            }
        };
    }, [serviceCaseId, initiateCreateCaseLogLeftFlow, history.location]);

    const prepareAdditionalSearchQueryParams = useMemo(() => {
        return {
            category: [
                ehmCaseLogCategoryTypes.NOTE,
                ehmCaseLogCategoryTypes.MESSAGE,
                ehmCaseLogCategoryTypes.ASSIGNMENT,
                ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
            ],
            sort: [
                'eventDate,asc',
                'eventTime,asc',
            ],
        };
    }, []);

    // if no case don't render
    if (!serviceCase) return null;

    const isServiceCaseLocked = !!serviceCase.lock && !!user && serviceCase.lock.lockedBy !== user.id;
    const isAdditionalAssignmentCreationAllowed = !isServiceCaseLocked
        && isAssignmentCreationAvailable
        // additional services are not allowed when the initial assignment is OTHER_SERVICES
        && initialServiceAssignment?.assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES
        && (initialServiceAssignment?.assignmentType === efServiceAssignmentTypes.LEGAL_ADVICE
            ? true : !!serviceCase.damage?.location)
        && (serviceCase?.caseType !== efServiceCaseTypes.PERSON ? serviceCase?.isCallbackPhoneValid : true);

    return (
        <Fragment>
            <FallbackSystemNotificationBar />
            <HeaderBar>
                <ServiceCaseHeader
                    serviceCase={serviceCase}
                    serviceAssignment={null}
                    agentUserProfile={users[serviceCase.createdBy]}
                />
            </HeaderBar>
            <TabSet
                name="service-case-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="service-case-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                        ref={serviceCaseScreenTabsRef}
                    >
                        <TabBar
                            name="service-case-details-tabs"
                            tabSet="service-case-screen-tab-set"
                            defaultValue={activeTab || serviceCaseScreenTabs.BASIC_DATA}
                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab name={serviceCaseScreenTabs.BASIC_DATA} value={serviceCaseScreenTabs.BASIC_DATA}>
                                {translateScreen('tab_label.basic_data')}
                            </Tab>
                            <Tab name={serviceCaseScreenTabs.ASSIGNMENTS} value={serviceCaseScreenTabs.ASSIGNMENTS}>
                                {translateScreen('tab_label.assignments')}
                            </Tab>
                            <Tab
                                name={serviceCaseScreenTabs.PROTOCOL}
                                value={serviceCaseScreenTabs.PROTOCOL}
                                additionalSearchQueryParams={prepareAdditionalSearchQueryParams}
                            >
                                {translateScreen('tab_label.protocol')}
                            </Tab>
                            {!config.IS_FALLBACK_SYSTEM && (
                                <Tab
                                    name={serviceCaseScreenTabs.TASK_LIST}
                                    value={serviceCaseScreenTabs.TASK_LIST}
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                    isDisabled={!isTaskListAvailable}
                                >
                                    {translateScreen('tab_label.task_list')}
                                    {taskCount && (taskCount !== 0 || taskCount !== '0') && (
                                        <Badge
                                            className={cx([
                                                'ace-c-badge--status-notification',
                                                'global!ace-u-margin--left-8',
                                            ])}
                                        >
                                            {taskCount}
                                        </Badge>
                                    )}
                                </Tab>
                            )}
                            {!config.IS_FALLBACK_SYSTEM && (
                                <Tab
                                    name={serviceCaseScreenTabs.DOCUMENTS}
                                    value={serviceCaseScreenTabs.DOCUMENTS}
                                    additionalSearchQueryParams={prepareAdditionalSearchQueryParams}
                                >
                                    {translateScreen('tab_label.documents')}
                                </Tab>
                            )}
                        </TabBar>
                        <ButtonPrimary
                            className={cx([
                                'global!ace-u-flex--align-self-stretch',
                                'global!ace-u-margin--right-32',
                            ])}
                            onClick={() => initiateSelectAdditionalServicesFlow()}
                            isDisabled={!isAdditionalAssignmentCreationAllowed}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx([
                                    'ace-c-icon--color-contrast',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateScreen('navigation_button_label.additional_services')}
                        </ButtonPrimary>
                    </div>
                </NavigationBar>
                <div>
                    {isServiceCaseLocked && (
                        <NotificationBar type="information">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                ])}
                            >
                                <Icon
                                    icon={closedIcon}
                                    className={cx('global!ace-u-margin--right-8')}
                                />
                                {translate('global.case_lock_check.case_lock_notification', {
                                    userName: isAgentIdentityAvailable
                                        ? (users[serviceCase.lock?.lockedBy]
                                            ? `${users[serviceCase.lock.lockedBy].firstName} ${users[serviceCase.lock?.lockedBy].lastName}`
                                            : '')
                                        : 'NRB',
                                })}
                            </div>
                        </NotificationBar>
                    )}
                    {!!toDoTaskCount && (
                        <ToDoTasksBanner description={latestToDoTask?.description || ''} />
                    )}
                </div>
                <AppLayoutMain>
                    <TabPanel for={serviceCaseScreenTabs.BASIC_DATA}>
                        <SCBasicDataTab />
                    </TabPanel>
                    <TabPanel for={serviceCaseScreenTabs.ASSIGNMENTS}>
                        <SCServiceAssignmentsTab />
                    </TabPanel>
                    <TabPanel for={serviceCaseScreenTabs.PROTOCOL}>
                        <SCProtocolTab />
                    </TabPanel>
                    <TabPanel for={serviceCaseScreenTabs.TASK_LIST}>
                        {/* todo think about nicer way to resolve subroutes for tabs and get 403 */}
                        {isTaskListAvailable && (
                            <SCTasksTab />
                        )}
                    </TabPanel>
                    <TabPanel for={serviceCaseScreenTabs.DOCUMENTS}>
                        <SCDocumentsTab />
                    </TabPanel>
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ServiceCaseScreen.propTypes = {
    match: PropTypes.object,
    initiateSelectAdditionalServicesFlow: PropTypes.func.isRequired,
    initiateCreateCaseLogViewedFlow: PropTypes.func.isRequired,
    initiateCreateCaseLogLeftFlow: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    users: PropTypes.object,
    user: PropTypes.object,
    taskCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    toDoTaskCount: PropTypes.number,
    latestToDoTask: PropTypes.object,
    history: PropTypes.object,
    initialServiceAssignment: PropTypes.object,
};

ServiceCaseScreen.defaultProps = {
    match: {},
    serviceCase: null,
    users: {},
    user: null,
    taskCount: '',
    toDoTaskCount: 0,
    latestToDoTask: null,
    history: {},
    initialServiceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceCaseTaskCountSelector = taskSelectors.createServiceCaseTaskCountSelector();
    const toDoTaskCountSelector = taskSelectors.createServiceCaseToDoTaskCountSelector();
    const latestToDoTaskSelector = taskSelectors.createLatestToDoTaskSelector();
    const initialServiceAssignmentSelector = serviceCaseSelectors.createInitialServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        user: userProfileSelectors.getUser(state),
        taskCount: serviceCaseTaskCountSelector(state, props),
        toDoTaskCount: toDoTaskCountSelector(state, props),
        latestToDoTask: latestToDoTaskSelector(state, props),
        initialServiceAssignment: initialServiceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateSelectAdditionalServicesFlow: () => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SELECT_ADDITIONAL_SERVICES_FLOW,
    }),
    initiateCreateCaseLogViewedFlow: payload => dispatch({
        type: caseLogActionTypes.INITIATE_CREATE_CASE_LOG_VIEWED_FLOW,
        payload,
    }),
    initiateCreateCaseLogLeftFlow: payload => dispatch({
        type: caseLogActionTypes.INITIATE_CREATE_CASE_LOG_LEFT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCaseScreen);
