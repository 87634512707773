import {eventChannel} from 'redux-saga';
import {select, take, fork, put, call} from 'redux-saga/effects';
import {efLocationTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savaActionTypes from '../savaActionTypes';
import config from '../../config';

const onSAVAMapClickFetchServiceLocationAddress = function* onSAVAMapClickFetchServiceLocationAddress(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');
    const mapClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = arcGISMap.on('map-click', payload => {
            emitter(payload);
        });
        const unsubscribeDragListener = arcGISMap.on('graphic-drag-end', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeClickListener();
            unsubscribeDragListener();
        };
    });

    while (true) {
        const {arcGISPoint} = yield take(mapClickEventChannel);

        yield fork(
            fetchRequest,
            savaActionTypes.FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST,
            arcGISRESTService.getLocationAddress,
            {
                longitude: arcGISPoint.longitude,
                latitude: arcGISPoint.latitude,
                langCode: config.ARCGIS_DEFAULT_LANG_CODE,
            },
        );

        const responseAction = yield take([
            savaActionTypes.FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_SUCCEEDED,
            savaActionTypes.FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {arcGISReverseGeocodingLocationDTO} = response;

            yield put({
                type: savaActionTypes.SET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION,
                payload: {
                    arcGISReverseGeocodingLocationDTO,
                    serviceAssignmentId,
                    locationType: efLocationTypes.SELECT_LOCATION,
                },
            });
        }
    }
};

export default onSAVAMapClickFetchServiceLocationAddress;
