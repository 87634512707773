import {efServiceCaseTypes, ServiceCasePerson, ServiceCaseVehicle, ServiceCase} from '@ace-de/eua-entity-types';

const getServiceCaseClass = serviceType => {
    switch (serviceType) {
        case efServiceCaseTypes.VEHICLE: {
            return ServiceCaseVehicle;
        }

        case efServiceCaseTypes.PERSON: {
            return ServiceCasePerson;
        }

        case efServiceCaseTypes.HOUSEHOLD: {
            return ServiceCase;
        }

        default:
            return {};
    }
};

export default getServiceCaseClass;
