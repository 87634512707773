import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, NoResultsBlock} from '@ace-de/ui-components';
import {Icon, expandIcon, findCaseIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableCell, TableHead, TableRow, TableCaption} from '@ace-de/ui-components/data-elements';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import routePaths from '../../routePaths';

const ContractPartnerRelationsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, history} = props;

    const openContractPartnerScreen = contractPartnerId => {
        if (!contractPartnerId) return null;
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER, {
            contractPartnerId,
        }));
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.branches')}
        >
            <Table>
                {!contractPartner?.affiliatePartners?.length && !contractPartner?.affiliatedPartner && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab('branches_data_no_results.message')}
                            description={translateTab('branches_data_no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>{translateTab('branches_data_table_cell.vendor_number')}</TableCell>
                        <TableCell colSpan={4}>{translateTab('branches_data_table_cell.name')}</TableCell>
                        <TableCell colSpan={4}>{translateTab('branches_data_table_cell.address')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contractPartner?.affiliatedPartner && (
                        <TableRow className={cx('ace-c-table__row--highlighted')}>
                            <TableCell colSpan={3}>
                                {contractPartner.affiliatedPartner.id || ''}
                            </TableCell>
                            <TableCell colSpan={4}>
                                {contractPartner.affiliatedPartner.name || ''}
                            </TableCell>
                            <TableCell colSpan={4} />
                            <TableCell colSpan={1} />
                        </TableRow>
                    )}
                    {!!contractPartner.affiliatePartners?.length
                        && contractPartner.affiliatePartners.map(affiliatePartner => (
                            <TableRow key={affiliatePartner.id}>
                                <TableCell colSpan={3}>
                                    {affiliatePartner.id}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {affiliatePartner.name || ''}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {affiliatePartner.address?.displayAddress || ''}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <Icon
                                        icon={expandIcon}
                                        className={cx([
                                            'ace-c-icon--color-highlight',
                                            'global!ace-u-margin--right-8',
                                        ])}
                                        onClick={() => openContractPartnerScreen(affiliatePartner.id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

ContractPartnerRelationsPanel.propTypes = {
    contractPartner: PropTypes.object,
    history: PropTypes.object.isRequired,
};

ContractPartnerRelationsPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerRelationsPanel));
