import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, HighlightCircle, Icon} from '@ace-de/ui-components';
import {damageCategoryIcons} from '../serviceCaseIcons';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import styles from './DamageDescriptionInfoPanel.module.scss';

const DamageDescriptionInfoPanel = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('damage_description_info_panel');
    const {serviceCase, serviceTypeDamageNodes} = props;

    const damageNodeData = useMemo(() => {
        if (!serviceCase?.damageNodeSnapshots?.length) return null;
        const damageNode = serviceCase.damageNodeSnapshots[0];
        if (!(serviceTypeDamageNodes && serviceTypeDamageNodes[damageNode])) return null;
        const serviceTypeDamageNode = serviceTypeDamageNodes[damageNode];
        if (!serviceTypeDamageNode?.name) return null;
        return {
            typeOfDamage: serviceTypeDamageNode.name.split('.').pop().toUpperCase(),
            translationKey: serviceTypeDamageNode.name,
        };
    }, [serviceCase?.damageNodeSnapshots, serviceTypeDamageNodes]);

    // if no service case don't render
    if (!serviceCase) return null;

    return (
        <Panel title={translatePanel('title.type_of_damage')}>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--small',
                        'global!ace-u-margin--right-16',
                        `ace-c-highlight-circle--${damageCategoryIcons[damageNodeData?.typeOfDamage] ? 'primary' : 'default'}`,
                    ])}
                >
                    {!!damageCategoryIcons[damageNodeData?.typeOfDamage] && (
                        <Icon
                            icon={damageCategoryIcons[damageNodeData.typeOfDamage]}
                            className={cx([
                                'ace-c-icon--m',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    )}
                </HighlightCircle>
                <span className={cx('global!ace-u-typography--variant-body-medium')}>
                    {damageNodeData?.typeOfDamage && damageNodeData?.translationKey
                        ? translate((damageNodeData?.translationKey))
                        : translatePanel('text.no_damage_info')}
                </span>
            </div>
            {!!serviceCase?.damageDescription && (
                <div className={cx(['global!ace-u-margin--top-24', 'ace-c-damage-description-info-panel__damage-description'])}>
                    {serviceCase.damageDescription}
                </div>
            )}
        </Panel>
    );
};

DamageDescriptionInfoPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceTypeDamageNodes: PropTypes.object,
};

DamageDescriptionInfoPanel.defaultProps = {
    serviceCase: null,
    serviceTypeDamageNodes: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceTypeDamageNodes: state.serviceCases?.serviceTypeDamageNodes,
    };
};

export default withRouter(connect(mapStateToProps, null)(DamageDescriptionInfoPanel));
