import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saaActionTypes from '../saaActionTypes';

/**
 * Update SAA people and rooms data flow
 */
const updateSAAPeopleAndRoomsDataFlow = function* updateSAAPeopleAndRoomsDataFlow() {
    while (true) {
        const {payload} = yield take(saaActionTypes.SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: saaActionTypes.SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM,
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saaActionTypes.SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM},
        });
    }
};

export default updateSAAPeopleAndRoomsDataFlow;
