/**
 * General state selectors
 */
export const getLoggedInUsers = state => state.dashboard.loggedInUsers;
export const getReporterCaseAssignmentTaskCount = state => state.dashboard.reporterCaseAssignmentTaskCount;
export const getCurrentUserTasks = state => state.dashboard.currentUsersTasks;
export const getMemberAppServiceCases = state => state.dashboard.memberAppServiceCases;
export const getMemberAppServiceCaseCount = state => Object.values(state.dashboard.memberAppServiceCases || {}).length;
export const getOpenServiceAssignments = state => state.dashboard.openServiceAssignments;
export const getOpenServiceAssignmentsCount = state => {
    // Ensure same count number is used as stored service assignments to avoid strange state issues like in:
    // https://computerrock.atlassian.net/browse/ACEECS-3739
    // return state.dashboard.openServiceAssignmentsCount;
    return Object.values(state.dashboard.openServiceAssignments || {}).length;
};
export const getSickPersonAndVehicleTransportAssignments = state => {
    return state.dashboard.sickPersonAndVehicleTransportAssignments;
};
