import {all, fork, takeLatest} from 'redux-saga/effects';
import * as savrActionTypes from './savrActionTypes';
import createServiceAssignmentVehicleReturnFlow from './sagas/createServiceAssignmentVehicleReturnFlow';
import updateSAVRServiceProviderFlow from './sagas/updateSAVRServiceProviderFlow';
import updateSAVRRequirementsFlow from './sagas/updateSAVRRequirementsFlow';
import searchSAVRPickupLocationGeolocation from './sagas/searchSAVRPickupLocationGeolocation';
import searchSAVRDestinationGeolocation from './sagas/searchSAVRDestinationGeolocation';
import calculatePickupLocationToDestinationDistance from './sagas/calculatePickupLocationToDestinationDistance';
import updateSAVRCostCoverageFlow from './sagas/updateSAVRCostCoverageFlow';

/**
 *  SAVR watcher saga
 */
const savrWatcher = function* savrWatcher() {
    yield all([
        fork(createServiceAssignmentVehicleReturnFlow),
        fork(updateSAVRServiceProviderFlow),
        fork(updateSAVRRequirementsFlow),
        takeLatest(
            savrActionTypes.SEARCH_SAVR_PICKUP_LOCATION_GEOLOCATION,
            searchSAVRPickupLocationGeolocation,
        ),
        takeLatest(savrActionTypes.SEARCH_SAVR_DESTINATION_GEOLOCATION, searchSAVRDestinationGeolocation),
        fork(calculatePickupLocationToDestinationDistance),
        fork(updateSAVRCostCoverageFlow),
    ]);
};

export default savrWatcher;
