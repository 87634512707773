import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import savaScreenTabs from '../savaScreenTabs';
import * as savaActionTypes from '../savaActionTypes';
import onSAVAMapClickFetchServiceLocationAddress from './onSAVAMapClickFetchServiceLocationAddress';

const savaMapServiceLocationWatcher = function* savaMapServiceLocationWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(savaActionTypes.START_SAVA_MAP_SERVICE_LOCATION_WATCHER);
        const onSAVAMapClickFetchServiceLocationAddressTask = yield fork(
            onSAVAMapClickFetchServiceLocationAddress,
            payload,
        );

        while (true) {
            const watcherTerminationAction = yield take([
                savaActionTypes.STOP_SAVA_MAP_SERVICE_LOCATION_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === savaActionTypes.STOP_SAVA_MAP_SERVICE_LOCATION_WATCHER) {
                yield cancel(onSAVAMapClickFetchServiceLocationAddressTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savaRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                exact: true,
            });
            const {activeTab} = savaRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savaRouteMatch || activeTab !== savaScreenTabs.SERVICE_LOCATION) {
                yield cancel(onSAVAMapClickFetchServiceLocationAddressTask);
                continue mainFlow;
            }
        }
    }
};

export default savaMapServiceLocationWatcher;
