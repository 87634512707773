import * as serviceAssignmentActionTypes from './serviceAssignmentActionTypes';
import * as savaActionTypes from '../service-assignments-vehicle-additional/savaActionTypes';
import * as savActionTypes from '../service-assignments-vehicle/savActionTypes';
import * as userProfileActionTypes from '../user-profiles/userProfileActionTypes';

const determineActionTypes = requestedAction => {
    if (!requestedAction) return null;

    switch (requestedAction) {
        case serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM.toLowerCase(): {
            return {
                confirm: serviceAssignmentActionTypes.CONFIRM_RESENDING_FAILED_POWER_OF_ATTORNEY,
                decline: serviceAssignmentActionTypes.DECLINE_RESENDING_FAILED_POWER_OF_ATTORNEY,
                name: 'power_of_attorney_failed',
            };
        }
        case savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE.toLowerCase(): {
            return {
                confirm: savaActionTypes.CONFIRM_RESUBMITTING_STATUS_CHANGE,
                decline: savaActionTypes.DECLINE_RESUBMITTING_STATUS_CHANGE,
                name: 'change_status_failed',
            };
        }
        case serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_STATUS_CHANGE.toLowerCase(): {
            return {
                confirm: serviceAssignmentActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_ACE_PARTNER,
                decline: serviceAssignmentActionTypes.DECLINE_RESUBMITTING_COMMISSIONING_ACE_PARTNER,
                name: 'change_status_failed',
            };
        }
        case savActionTypes.SUBMIT_SAV_STATUS_CHANGE.toLowerCase(): {
            return {
                confirm: savActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_FORM,
                decline: savActionTypes.DECLINE_RESUBMITTING_COMMISSIONING_FORM,
                name: 'change_status_failed',
            };
        }
        case userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST.toLowerCase(): {
            return {
                confirm: null,
                decline: null,
                name: 'fetch_user_shift_role_failed',
            };
        }
        case userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST.toLowerCase(): {
            return {
                confirm: userProfileActionTypes.CONFIRM_REUPDATE_USER_SHIFT_ROLE,
                decline: userProfileActionTypes.DECLINE_REUPDATE_USER_SHIFT_ROLE,
                name: 'update_user_shift_role_failed',
            };
        }
        // todo add other actions
        default: return null;
    }
};

export default determineActionTypes;
