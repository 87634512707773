import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, List, Divider} from '@ace-de/ui-components';
import {apmContractPartnerAdditionalServiceTypes} from '@ace-de/eua-entity-types';
import {Icon, checkmarkIcon, closeIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const ContractPartnerAdditionalServicesPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    const {additionalServices, brands} = contractPartner;

    return (
        <Panel title={translateTab('panel_title.additional_services')}>
            <List>
                {Object.values(apmContractPartnerAdditionalServiceTypes).map(additionalService => {
                    const hasCPAdditionalService = additionalServices.includes(additionalService);
                    return (
                        <Fragment key={additionalService}>
                            <div
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-padding--16',
                                ])}
                            >
                                {additionalService === apmContractPartnerAdditionalServiceTypes.BRANDS ? (
                                    <div className={cx('global!ace-u-typography--variant-body')}>
                                        <span>
                                            {`${translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}: `}
                                        </span>
                                        <span>{brands || ''}</span>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <span className={cx('global!ace-u-typography--variant-body')}>
                                            {translate(`global.contract_partner_additional_services.${(additionalService).toLowerCase()}`)}
                                        </span>
                                        <Icon
                                            icon={hasCPAdditionalService ? checkmarkIcon : closeIcon}
                                            className={cx(hasCPAdditionalService
                                                ? 'ace-c-icon--color-success' : 'ace-c-icon--color-warning')}
                                        />
                                    </Fragment>
                                )}
                            </div>
                            <Divider />
                        </Fragment>
                    );
                })}
            </List>
        </Panel>
    );
};

ContractPartnerAdditionalServicesPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerAdditionalServicesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerAdditionalServicesPanel));
