import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {efServiceAssignmentTypes, efServiceTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, ButtonSecondary, InteractiveIcon, RadioButtonGroup, RadioTile, Tooltip} from '@ace-de/ui-components';
import {closeIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseIcons from '../../service-cases/serviceCaseIcons';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';

const AdditionalServicesModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, declineSelectAdditionalServices, confirmSelectAdditionalServices, match} = props;
    const {serviceCase, serviceAssignments} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('additional_services_modal');
    const {params} = match;
    const [selectedService, setSelectedService] = useState('');

    const hasMedicalClearanceAssignment = useMemo(() => {
        return !!serviceAssignments
            ?.find(assignment => assignment.assignmentType === efServiceAssignmentTypes.MEDICAL_CLEARANCE);
    }, [serviceAssignments]);

    return (
        <Modal
            className={cx('global!ace-u-padding--0-0')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => declineSelectAdditionalServices()}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={translateModal('title.select_additional_service')}
            contentClassName={cx('global!ace-u-margin--top-32')}
        >
            <div>
                <RadioButtonGroup
                    name="servicesSelection"
                    onChange={service => setSelectedService(service)}
                    value={selectedService}
                >
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.RENTAL_CAR]}
                                value={efServiceAssignmentTypes.RENTAL_CAR}
                                name="rentalCarAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.rental_car')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.ACCOMMODATION]}
                                value={efServiceAssignmentTypes.ACCOMMODATION}
                                name="accommodationAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.accommodation')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.SHORT_TRIP]}
                                value={efServiceAssignmentTypes.SHORT_TRIP}
                                name="shortTripAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.short_trip')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.RETURN_JOURNEY]}
                                value={efServiceAssignmentTypes.RETURN_JOURNEY}
                                name="returnJourneyAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.return_journey')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <Tooltip
                                message={!hasMedicalClearanceAssignment
                                    ? translateModal('tooltip_text.no_medical_clearance') : ''}
                            >
                                <RadioTile
                                    icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.SICK_PERSON]}
                                    value={efServiceAssignmentTypes.SICK_PERSON}
                                    name="sickPersonAssignmentOption"
                                    isDisabled={!hasMedicalClearanceAssignment}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-full-height',
                                    ])}
                                >
                                    {translateModal('radio_tile_label.sick_person')}
                                </RadioTile>
                            </Tooltip>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.VEHICLE_RETURN]}
                                value={efServiceAssignmentTypes.VEHICLE_RETURN}
                                name="vehicleReturnAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.vehicle_return')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceIcons[efServiceTypes.TOWING]}
                                value={efServiceTypes.TOWING}
                                name="towingAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.towing')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceIcons[efServiceTypes.ROADSIDE_ASSISTANCE]}
                                value={efServiceTypes.ROADSIDE_ASSISTANCE}
                                name="roadsideAssistanceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.roadside_assistance')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceIcons[efServiceTypes.RECOVERY]}
                                value={efServiceTypes.RECOVERY}
                                name="recoveryAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.recovery')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceIcons[efServiceTypes.VEHICLE_OPENING]}
                                value={efServiceTypes.VEHICLE_OPENING}
                                name="vehicleOpeningAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.vehicle_opening')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.OTHER_SERVICES]}
                                value={efServiceAssignmentTypes.OTHER_SERVICES}
                                name="otherServicesAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.other_services')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.PICKUP]}
                                value={efServiceAssignmentTypes.PICKUP}
                                name="pickupAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.pickup')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.TRAILER_RETURN]}
                                value={efServiceAssignmentTypes.TRAILER_RETURN}
                                name="trailerReturnAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                                isDisabled={!serviceCase?.trailer}
                            >
                                {translateModal('radio_tile_label.trailer_return')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons
                                    .serviceAssignmentIcons[efServiceAssignmentTypes.DEATH]}
                                value={efServiceAssignmentTypes.DEATH}
                                name="deathEventHelpAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.help_in_case_of_death')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[efServiceAssignmentTypes.LEGAL_ADVICE]}
                                value={efServiceAssignmentTypes.LEGAL_ADVICE}
                                name="legalAdviceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.legal_advice')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[
                                    efServiceAssignmentTypes.SUBSTITUTE_DRIVER
                                ]}
                                value={efServiceAssignmentTypes.SUBSTITUTE_DRIVER}
                                name="substituteDriverAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.substitute_driver')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <RadioTile
                                icon={serviceCaseIcons.serviceAssignmentIcons[
                                    efServiceAssignmentTypes.MEDICAL_CLEARANCE
                                ]}
                                value={efServiceAssignmentTypes.MEDICAL_CLEARANCE}
                                name="medicalClearanceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.medical_clearance')}
                            </RadioTile>
                        </div>
                    </div>
                </RadioButtonGroup>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <ButtonSecondary
                        name="closeModalButton"
                        className={cx('global!ace-u-margin--top-32')}
                        isDisabled={!selectedService}
                        onClick={() => confirmSelectAdditionalServices({
                            serviceCaseId: params.serviceCaseId,
                            assignmentType: selectedService,
                        })}
                    >
                        {translateModal('button_label.add_to')}
                    </ButtonSecondary>
                </div>
            </div>
        </Modal>
    );
};

AdditionalServicesModal.propTypes = {
    confirmSelectAdditionalServices: PropTypes.func.isRequired,
    declineSelectAdditionalServices: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.array,
    hasBackdrop: PropTypes.bool,
    match: PropTypes.object,
};

AdditionalServicesModal.defaultProps = {
    hasBackdrop: true,
    serviceCase: null,
    serviceAssignments: [],
    match: {},
};
const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignments: serviceAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmSelectAdditionalServices: payload => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_SELECT_ADDITIONAL_SERVICES,
        payload,
    }),
    declineSelectAdditionalServices: () => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_SELECT_ADDITIONAL_SERVICES,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesModal);
