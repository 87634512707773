import {ampMemberTariffAdditionalPackageTypes, efServiceTypes, ampVehicleTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import config from '../config';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const formatReturnValue = (assignmentDefaultText, assignmentCostCoverageText, assignmentHintText) => {
    return assignmentDefaultText
        + (assignmentCostCoverageText ? '\n\n' + assignmentCostCoverageText : '')
        + (assignmentHintText ? '\n\n' + assignmentHintText : '');
};

export const formatDefaultServiceAssignmentText = params => {
    const {serviceAssignment, serviceCase, translate} = params;

    if (!serviceCase || !serviceAssignment || !translate) return '';

    const isDACH = isACEPartnerFromDACH(serviceAssignment.acePartner);

    const memberTariff = serviceCase.member?.tariffDetails
        && serviceCase.member.tariffDetails.additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)
        && serviceCase.vehicle?.type === ampVehicleTypes.CAMPER
        ? ampMemberTariffAdditionalPackageTypes.CAMPER
        : serviceCase.member.tariffDetails?.tariffGroup || '';

    const hasServiceType = serviceAssignment.serviceType
        && serviceAssignment.serviceType !== efServiceTypes.NO_SERVICE;
    if (!hasServiceType) return '';
    const serviceTypeTranslationKey = serviceAssignment.serviceType.toLowerCase();

    if (serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}`) {
        return isDACH && hasServiceType
            ? translate(`global.assignment_text_non_ace.${serviceTypeTranslationKey}`) : '';
    }

    if (isDACH) {
        const assignmentDefaultText = hasServiceType
            ? translate(`global.default_assignment_text.${serviceTypeTranslationKey}`) : '';
        const assignmentCostCoverageText = hasServiceType
            && memberTariff
            && memberTariff !== ampMemberTariffGroups.NON_MEMBER
            ? (
                serviceAssignment.selfPayerOption
                    ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_self_payer`)
                    : translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`)
            ) : '';
        const assignmentHintText = hasServiceType
            ? (translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`)
                + (serviceAssignment.selfPayerOption
                    ? translate(`global.self_payer_hint_assignment_text.${serviceTypeTranslationKey}`)
                    : translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`)))
            : '';
        return formatReturnValue(assignmentDefaultText, assignmentCostCoverageText, assignmentHintText);
    }

    const assignmentDefaultText = hasServiceType
        ? translate(`global.default_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US') : '';
    const assignmentCostCoverageText = hasServiceType
        && memberTariff
        && memberTariff !== ampMemberTariffGroups.NON_MEMBER
        ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`, {}, 'en-US')
        : '';
    const assignmentHintText = hasServiceType
        ? translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US')
            + translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`, {}, 'en-US')
        : '';
    return formatReturnValue(assignmentDefaultText, assignmentCostCoverageText, assignmentHintText);
};

export const formatServiceAssignmentTextOnChange = params => {
    const {serviceAssignment, serviceCase, text, translate} = params;

    if (!serviceCase || !serviceAssignment || !translate) return '';

    const memberTariff = serviceCase.member?.tariffDetails
        && serviceCase.member.tariffDetails.additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)
        && serviceCase.vehicle?.type === ampVehicleTypes.CAMPER
        ? ampMemberTariffAdditionalPackageTypes.CAMPER
        : serviceCase.member.tariffDetails?.tariffGroup || '';
    const hasServiceType = serviceAssignment.serviceType
        && serviceAssignment.serviceType !== efServiceTypes.NO_SERVICE;
    const serviceTypeTranslationKey = serviceAssignment.serviceType.toLowerCase();

    let assignmentHintText = hasServiceType
        ? translate(`global.general_hint_assignment_text.${serviceTypeTranslationKey}`) : '';

    if (text) {
        assignmentHintText += hasServiceType
            ? translate(`global.self_payer_hint_assignment_text.${serviceTypeTranslationKey}`) : '';
        const assignmentCostCoverageText = hasServiceType
            && memberTariff && memberTariff !== ampMemberTariffGroups.NON_MEMBER
            ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_self_payer`)
            : '';
        return formatReturnValue(
            hasServiceType
                ? translate(`global.default_assignment_text.${serviceTypeTranslationKey}`) : '',
            assignmentCostCoverageText,
            assignmentHintText,
        );
    }

    assignmentHintText += hasServiceType
        ? translate(`global.no_self_payer_hint_assignment_text.${serviceTypeTranslationKey}`) : '';
    const assignmentCostCoverageText = hasServiceType
        && memberTariff && memberTariff !== ampMemberTariffGroups.NON_MEMBER
        ? translate(`global.cost_coverage_assignment_text.${serviceTypeTranslationKey}_${memberTariff.toLowerCase()}`)
        : '';
    return formatReturnValue(
        hasServiceType
            ? translate(`global.default_assignment_text.${serviceTypeTranslationKey}`) : '',
        assignmentCostCoverageText,
        assignmentHintText,
    );
};
