import React, {Fragment, useEffect, useState, useMemo, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel} from '@ace-de/ui-components';
import {persistenceStates, efLocationTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {Form, Autosuggest, SelectField, Option, ButtonPrimary, TextAreaField} from '@ace-de/ui-components/form';
import {Icon, locationIcon, searchIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import {ArcGISMiniMapWidget, createMarkerGraphic, markerPersonPNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import serviceAssignmentLocationKeys from '../serviceAssignmentLocationKeys';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import config from '../../config';


const RelevantLocationPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('relevant_location_panel');

    const {title, mapName, handleLocationSaving, onRelevantLocationSelect} = props;
    const {hasPreviousLocations, hasLocationNotes, hasConfirmation} = props;
    const {searchAssignmentRelevantLocationGeolocation, resetAssignmentRelevantLocationCandidates} = props;
    const {serviceAssignment, serviceAssignments, serviceCase} = props;

    const [newLocation, setNewLocation] = useState(null);
    const [newLocationNotes, setNewLocationNotes] = useState('');

    const arcGISMap = useArcGISMap(mapName);
    const locationKey = serviceAssignment?.assignmentType
        ? serviceAssignmentLocationKeys[serviceAssignment.assignmentType]
        : '';

    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');
    const hasLocationNotesChanged = useRef(false);

    const searchRelevantLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchAssignmentRelevantLocationGeolocation === 'function') {
            return debounce(
                searchAssignmentRelevantLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchAssignmentRelevantLocationGeolocation]);

    // assignment memoization
    const vehicleServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.VEHICLE)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    const rentalCarServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.RENTAL_CAR)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    const accommodationServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.ACCOMMODATION)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    // Setters
    const setNewLocationFromFinalDestination = useCallback(newLocationType => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.finalDestination?.location)[newLocationType.replace(`${efLocationTypes.FINAL_DESTINATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.finalDestination?.location) {
            const location = vehicleServiceAssignment.finalDestination.location
                .setLocationType(efLocationTypes.FINAL_DESTINATION);
            setNewLocation(location);
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [vehicleServiceAssignments, onRelevantLocationSelect]);

    const setNewLocationFromTowingDestination = useCallback(newLocationType => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.towingDestination)[newLocationType.replace(`${efLocationTypes.TOWING_DESTINATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.towingDestination) {
            const location = vehicleServiceAssignment.towingDestination
                .setLocationType(efLocationTypes.TOWING_DESTINATION);
            setNewLocation(location);
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [vehicleServiceAssignments, onRelevantLocationSelect]);

    const setNewLocationFromContractPartnerLocation = useCallback(newLocationType => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)[newLocationType.replace(`${efLocationTypes.CONTRACT_PARTNER_LOCATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.acePartner?.location) {
            const location = vehicleServiceAssignment.acePartner.location
                .setLocationType(efLocationTypes.CONTRACT_PARTNER_LOCATION);
            setNewLocation(location?.formattedAddress
                ? location
                // if there is no formattedAddress, set it manually
                : {
                    ...location,
                    formattedAddress: [
                        location?.street,
                        location?.postCode,
                        location?.city,
                        location?.country,
                    ].filter(value => !!value).join(', '),
                });
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [vehicleServiceAssignments, onRelevantLocationSelect]);

    const setNewLocationFromRentalCarPickupLocation = useCallback(newLocationType => {
        const rentalCarServiceAssignment = rentalCarServiceAssignments.length === 1 ? rentalCarServiceAssignments[0]
            : rentalCarServiceAssignments.filter(assignment => !!assignment.rentalCarStationPickup?.location)[newLocationType.replace(`${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} `, '') - 1];

        if (rentalCarServiceAssignment && rentalCarServiceAssignment.rentalCarStationPickup?.location) {
            const location = rentalCarServiceAssignment.rentalCarStationPickup.location
                .setLocationType(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION);
            setNewLocation(location);
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [rentalCarServiceAssignments, onRelevantLocationSelect]);

    const setNewLocationFromRentalCarDropOffLocation = useCallback(newLocationType => {
        const rentalCarServiceAssignment = rentalCarServiceAssignments.length === 1 ? rentalCarServiceAssignments[0]
            : rentalCarServiceAssignments.filter(assignment => !!assignment.rentalCarStationDropOff?.location)[newLocationType.replace(`${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} `, '') - 1];

        if (rentalCarServiceAssignment && rentalCarServiceAssignment.rentalCarStationDropOff?.location) {
            const location = rentalCarServiceAssignment.rentalCarStationDropOff.location
                .setLocationType(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION);
            setNewLocation(location);
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [rentalCarServiceAssignments, onRelevantLocationSelect]);

    const setNewLocationFromHotelLocation = useCallback(newLocationType => {
        const accommodationServiceAssignment = accommodationServiceAssignments.length === 1
            ? accommodationServiceAssignments[0]
            : accommodationServiceAssignments.filter(assignment => !!assignment.acePartner?.location)[newLocationType.replace(`${efLocationTypes.HOTEL_LOCATION} `, '') - 1];

        if (accommodationServiceAssignment && accommodationServiceAssignment.acePartner?.location) {
            // TODO: fix accommodation selection flow: service provider.location must be an entity!
            const location = {
                ...accommodationServiceAssignment.acePartner.location,
                locationType: efLocationTypes.HOTEL_LOCATION,
            };
            setNewLocation(location);
            if (typeof onRelevantLocationSelect === 'function') {
                onRelevantLocationSelect(location);
            }
        }
    }, [accommodationServiceAssignments, onRelevantLocationSelect]);

    // handlers
    const handleLocationSearchQueryChange = searchQueryString => {
        const currentCenter = arcGISMap.getMapCenter(true);
        if (!searchQueryString
            && (currentCenter[0] !== mapCenter[0] || currentCenter[1] !== mapCenter[1])) {
            arcGISMap.setMapCenter(mapCenter);
        }

        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchRelevantLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                locationKey,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleRelevantLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.formattedAddress;
        setNewLocation(locationCandidate);
        if (typeof onRelevantLocationSelect === 'function') {
            onRelevantLocationSelect(locationCandidate);
        }

        if (!locationCandidate || hasConfirmation) return;

        if (typeof handleLocationSaving === 'function') {
            handleLocationSaving({
                selectedLocation: locationCandidate,
            });
        }
    };

    const handleOnFormSubmit = useCallback(() => {
        if (typeof handleLocationSaving === 'function' && (hasLocationNotes || hasConfirmation)) {
            handleLocationSaving({
                ...(hasConfirmation
                    ? {selectedLocation: newLocation}
                    : {}
                ),
                ...(hasLocationNotes
                    ? {locationNotes: newLocationNotes || ''}
                    : {}
                ),
            });
        }
    }, [hasLocationNotes, newLocationNotes, handleLocationSaving, hasConfirmation, newLocation]);

    const handleRelevantLocationTypeChange = useCallback(newLocationType => {
        if (newLocationType.includes(efLocationTypes.FINAL_DESTINATION)) {
            setNewLocationFromFinalDestination(newLocationType);
            return;
        }

        if (newLocationType.includes(efLocationTypes.TOWING_DESTINATION)) {
            setNewLocationFromTowingDestination(newLocationType);
            return;
        }

        if (newLocationType.includes(efLocationTypes.CONTRACT_PARTNER_LOCATION)) {
            setNewLocationFromContractPartnerLocation(newLocationType);
            return;
        }

        if (newLocationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION)) {
            setNewLocationFromRentalCarPickupLocation(newLocationType);
            return;
        }

        if (newLocationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION)) {
            setNewLocationFromRentalCarDropOffLocation(newLocationType);
            return;
        }

        if (newLocationType.includes(efLocationTypes.HOTEL_LOCATION)) {
            setNewLocationFromHotelLocation(newLocationType);
            return;
        }

        switch (newLocationType) {
            case efLocationTypes.DAMAGE_LOCATION: {
                const {damage} = serviceCase;
                if (damage && damage.location) {
                    const location = damage.location.setLocationType(efLocationTypes.DAMAGE_LOCATION);
                    setNewLocation(location);
                    if (typeof onRelevantLocationSelect === 'function') {
                        onRelevantLocationSelect(location);
                    }
                }
                break;
            }

            case efLocationTypes.RESIDENCE_PLACE: {
                const {member} = serviceCase;
                const {residenceLocation} = member;
                const location = residenceLocation.setLocationType(efLocationTypes.RESIDENCE_PLACE);
                setNewLocation(location);
                if (typeof onRelevantLocationSelect === 'function') {
                    onRelevantLocationSelect(location);
                }
                break;
            }

            case efLocationTypes.SELECT_LOCATION: {
                if (serviceAssignment[locationKey]) {
                    const location = serviceAssignment[locationKey].setLocationType(efLocationTypes.SELECT_LOCATION);
                    setNewLocation(location);
                    if (typeof onRelevantLocationSelect === 'function') {
                        onRelevantLocationSelect(location);
                    }
                }

                if (typeof resetAssignmentRelevantLocationCandidates === 'function') {
                    resetAssignmentRelevantLocationCandidates({
                        serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                        locationKey,
                    });
                }
                break;
            }

            default:
            // no-op
        }
    }, [
        serviceCase,
        serviceAssignment,
        setNewLocationFromFinalDestination,
        setNewLocationFromTowingDestination,
        setNewLocationFromContractPartnerLocation,
        setNewLocationFromRentalCarPickupLocation,
        setNewLocationFromRentalCarDropOffLocation,
        setNewLocationFromHotelLocation,
        onRelevantLocationSelect,
        resetAssignmentRelevantLocationCandidates,
        locationKey,
    ]);

    // hooks
    const mapCenter = useMemo(() => {
        const {damage, member} = serviceCase;
        if (damage && damage.location && damage.location.longitude && damage.location.latitude) {
            return [damage.location.longitude, damage.location.latitude];
        }

        if (member && member.personalDetails) {
            const {coordinates} = member.personalDetails;
            if (coordinates.longitude && coordinates.latitude) {
                return [coordinates.longitude, coordinates.latitude];
            }
        }

        return config.GERMANY_CENTER_COORDINATES;
    }, [serviceCase]);

    useEffect(() => {
        if (!newLocation
            && serviceAssignment
            && locationKey
            && serviceCase
            && serviceAssignment[locationKey]) {
            const {assignmentType} = serviceAssignment;
            if (assignmentType === efServiceAssignmentTypes.OTHER_SERVICES) {
                setNewLocation(serviceAssignment[locationKey] || serviceCase.damage?.location);
                return;
            }
            setNewLocation(serviceAssignment[locationKey]);
        }
    }, [newLocation, serviceCase, serviceAssignment, locationKey]);

    useEffect(() => {
        if (!hasLocationNotesChanged.current
            && !newLocationNotes
            && hasLocationNotes
            && serviceAssignment
            && locationKey
            && serviceAssignment[locationKey]) {
            const {locationNotes} = serviceAssignment[locationKey];
            setNewLocationNotes(locationNotes);
        }
    }, [newLocationNotes, serviceAssignment, hasLocationNotes, locationKey, setNewLocationNotes]);

    useEffect(() => {
        if (!serviceCase || !arcGISMap) return;

        arcGISMap.setGraphics({
            graphics: [
                ...(newLocation
                    ? [createMarkerGraphic({
                        id: 'relevantLocation',
                        longitude: newLocation.longitude,
                        latitude: newLocation.latitude,
                        icon: markerPersonPNG,
                    })] : []),
            ],
        });

        if (!newLocation) {
            arcGISMap.setMapCenter(mapCenter);
        }
    }, [serviceCase, newLocation, arcGISMap, mapCenter]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (hasConfirmation) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SUBMIT_RELEVANT_LOCATION_FORM,
            });
            return;
        }

        handleOnFormSubmit();
    }, [handleOnFormSubmit, hasConfirmation]);

    if (!serviceCase || !serviceAssignment) return null;
    // Search related fields
    const {[locationKey]: savedLocation, [`${locationKey}Candidates`]: locationCandidates, [`${locationKey}SearchQuery`]: locationSearchQuery} = serviceAssignment;

    // Create a list of locations by assignment
    const memberLocationTypeOptions = [
        ...(serviceCase.damage?.location?.formattedAddress
            ? [efLocationTypes.DAMAGE_LOCATION]
            : []),
        ...(serviceCase.member?.residenceLocation?.formattedAddress
            ? [efLocationTypes.RESIDENCE_PLACE]
            : []),
        efLocationTypes.SELECT_LOCATION,
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.towingDestination)
                .map((assignment, idx) => `${efLocationTypes.TOWING_DESTINATION} ${idx + 1}`)
            : (vehicleServiceAssignments[0]?.towingDestination ? [efLocationTypes.TOWING_DESTINATION] : [])),
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.finalDestination?.location)
                .map((assignment, idx) => `${efLocationTypes.FINAL_DESTINATION} ${idx + 1}`)
            : (vehicleServiceAssignments[0]?.finalDestination?.location
                ? [efLocationTypes.FINAL_DESTINATION] : [])),
    ];

    const rentalCarLocationOptions = [
        ...(rentalCarServiceAssignments.length > 1
            ? rentalCarServiceAssignments
                .filter(assignment => !!assignment.rentalCarStationPickup?.location)
                .map((assignment, idx) => `${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} ${idx + 1}`)
            : (rentalCarServiceAssignments[0]?.rentalCarStationPickup?.location
                ? [efLocationTypes.RENTAL_CAR_PICKUP_LOCATION] : [])),
        ...(rentalCarServiceAssignments.length > 1
            ? rentalCarServiceAssignments
                .filter(assignment => !!assignment.rentalCarStationDropOff?.location)
                .map((assignment, idx) => `${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} ${idx + 1}`)
            : (rentalCarServiceAssignments[0]?.rentalCarStationDropOff?.location
                ? [efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION] : [])),
    ];

    const accommodationLocationOptions = [
        ...(accommodationServiceAssignments.length > 1
            ? accommodationServiceAssignments
                .filter(assignment => !!assignment.acePartner?.location)
                .map((assignment, idx) => `${efLocationTypes.HOTEL_LOCATION} ${idx + 1}`)
            : (accommodationServiceAssignments[0]?.acePartner?.location ? [efLocationTypes.HOTEL_LOCATION] : [])),
    ];

    const contractPartnerLocationOptions = [
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)
                .map((assignment, idx) => ({
                    value: `${efLocationTypes.CONTRACT_PARTNER_LOCATION} ${idx + 1}`,
                    label: assignment.acePartner.name,
                }))
            : (vehicleServiceAssignments[0]?.acePartner?.location
                ? [{
                    value: efLocationTypes.CONTRACT_PARTNER_LOCATION,
                    label: vehicleServiceAssignments[0].acePartner.name,
                }] : [])),
    ];

    const handleOnLocationNotesChange = value => {
        hasLocationNotesChanged.current = true;
        setNewLocationNotes(value);
    };

    return (
        <Panel
            title={title || translateTab('title.member_location')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <ArcGISMiniMapWidget
                name={mapName}
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
            />
            <Form
                name="relevantLocationForm"
                onSubmit={handleOnFormSubmit}
                isDisabled={serviceAssignment.persistenceState === persistenceStates.PENDING}
            >
                {formValues => {
                    const getInitialFinalDestinationType = () => {
                        const index = vehicleServiceAssignments
                            .filter(assignment => !!assignment.finalDestination?.location)
                            .findIndex(assignment => {
                                return assignment.finalDestination.location.formattedAddress === newLocation.formattedAddress; // eslint-disable-line max-len
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.FINAL_DESTINATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialTowingDestinationType = () => {
                        const index = vehicleServiceAssignments
                            .filter(assignment => !!assignment.towingDestination).findIndex(assignment => {
                                return assignment.towingDestination.formattedAddress === newLocation.formattedAddress;
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.TOWING_DESTINATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialContractPartnerLocationType = () => {
                        const index = vehicleServiceAssignments
                            .filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)
                            .findIndex(assignment => {
                                return assignment.acePartner.location.formattedAddress === newLocation.formattedAddress;
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.CONTRACT_PARTNER_LOCATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialRentalCarPickupLocationType = () => {
                        const index = rentalCarServiceAssignments
                            .filter(assignment => !!assignment.rentalCarStationPickup?.location)
                            .findIndex(assignment => {
                                // eslint-disable-next-line max-len
                                return assignment.rentalCarStationPickup.location.formattedAddress === newLocation.formattedAddress;
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialRentalCarDropOffLocationType = () => {
                        const index = rentalCarServiceAssignments
                            .filter(assignment => !!assignment.rentalCarStationDropOff?.location)
                            .findIndex(assignment => {
                                // eslint-disable-next-line max-len
                                return assignment.rentalCarStationDropOff.location.formattedAddress === newLocation.formattedAddress;
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialHotelLocationType = () => {
                        const index = accommodationServiceAssignments
                            .filter(assignment => !!assignment.acePartner?.location).findIndex(assignment => {
                                return assignment.acePartner.location.formattedAddress === newLocation.formattedAddress;
                            });
                        if (index >= 0) {
                            return `${efLocationTypes.HOTEL_LOCATION} ${index + 1}`;
                        }
                        return newLocation.locationType; // placeholder will be shown
                    };

                    const getInitialLocationType = () => {
                        if (vehicleServiceAssignments.length > 1) {
                            if (newLocation.locationType.includes(efLocationTypes.FINAL_DESTINATION)) {
                                return getInitialFinalDestinationType();
                            }

                            if (newLocation.locationType.includes(efLocationTypes.TOWING_DESTINATION)) {
                                return getInitialTowingDestinationType();
                            }

                            if (newLocation.locationType.includes(efLocationTypes.CONTRACT_PARTNER_LOCATION)) {
                                return getInitialContractPartnerLocationType();
                            }
                        }

                        if (rentalCarServiceAssignments.length > 1) {
                            if (newLocation.locationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION)) {
                                return getInitialRentalCarPickupLocationType();
                            }

                            if (newLocation.locationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION)) {
                                return getInitialRentalCarDropOffLocationType();
                            }
                        }

                        if (accommodationServiceAssignments.length > 1) {
                            if (newLocation.locationType.includes(efLocationTypes.HOTEL_LOCATION)) {
                                return getInitialHotelLocationType();
                            }
                        }

                        return newLocation.locationType; // placeholder will be shown
                    };

                    const isRelevantLocationSaved = savedLocation
                        && savedLocation.formattedAddress
                        && formValues['relevantLocationSearchQuery'] === savedLocation.formattedAddress
                        && (hasPreviousLocations
                            ? ([
                                efLocationTypes.FINAL_DESTINATION,
                                efLocationTypes.TOWING_DESTINATION,
                                efLocationTypes.CONTRACT_PARTNER_LOCATION,
                                efLocationTypes.RENTAL_CAR_PICKUP_LOCATION,
                                efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION,
                                efLocationTypes.HOTEL_LOCATION,
                            ].includes(savedLocation.locationType)
                                ? (formValues['relevantLocationType'] || '').includes(savedLocation.locationType)
                                : formValues['relevantLocationType'] === savedLocation.locationType)
                            : true)
                        && (newLocation
                            ? (savedLocation.longitude === newLocation.longitude
                                && savedLocation.latitude === newLocation.latitude) : true);

                    const isSubmitButtonDisabled = hasPreviousLocations
                        ? !formValues['relevantLocationSearchQuery'] || !newLocation
                        : !newLocation;

                    return (
                        <div className={cx('global!ace-u-padding--16-32')}>
                            {hasPreviousLocations && (
                                <SelectField
                                    name="relevantLocationType"
                                    value={newLocation
                                        ? getInitialLocationType()
                                        : null}
                                    onChange={handleRelevantLocationTypeChange}
                                    label={translateTab('input_label.location_info')}
                                    placeholder={translate('global.select.placeholder')}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--16-0',
                                    ])}
                                >
                                    {memberLocationTypeOptions.map(locationType => (
                                        <Option
                                            key={locationType}
                                            name={`${locationType}Option`}
                                            value={locationType}
                                        >
                                            {vehicleServiceAssignments.length === 1
                                                ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                : (locationType.includes(efLocationTypes.FINAL_DESTINATION)
                                                    ? locationType.replace(
                                                        efLocationTypes.FINAL_DESTINATION,
                                                        translate(`global.location_type.final_destination`),
                                                    ) : (locationType.includes(efLocationTypes.TOWING_DESTINATION)
                                                        ? locationType.replace(
                                                            efLocationTypes.TOWING_DESTINATION,
                                                            translate(`global.location_type.towing_destination`),
                                                        ) : translate(`global.location_type.${locationType.toLowerCase()}`)
                                                    )
                                                )}
                                        </Option>
                                    ))}
                                    {rentalCarLocationOptions.map(locationType => (
                                        <Option
                                            key={locationType}
                                            name={`${locationType}Option`}
                                            value={locationType}
                                        >
                                            {rentalCarServiceAssignments.length === 1
                                                ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                : (locationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION)
                                                    ? locationType.replace(
                                                        efLocationTypes.RENTAL_CAR_PICKUP_LOCATION,
                                                        translate(`global.location_type.rental_car_pickup_location`),
                                                    )
                                                    // eslint-disable-next-line max-len
                                                    : (locationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION)
                                                        ? locationType.replace(
                                                            efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION,
                                                            translate(`global.location_type.rental_car_dropoff_location`),
                                                        ) : translate(`global.location_type.${locationType.toLowerCase()}`)))}
                                        </Option>
                                    ))}
                                    {accommodationLocationOptions.map(locationType => (
                                        <Option
                                            key={locationType}
                                            name={`${locationType}Option`}
                                            value={locationType}
                                        >
                                            {accommodationServiceAssignments.length === 1
                                                ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                : (locationType.includes(efLocationTypes.HOTEL_LOCATION)
                                                    ? locationType.replace(
                                                        efLocationTypes.HOTEL_LOCATION,
                                                        translate(`global.location_type.hotel_location`),
                                                    )
                                                    : translate(`global.location_type.${locationType.toLowerCase()}`))}
                                        </Option>
                                    ))}
                                    {contractPartnerLocationOptions.map(location => (
                                        <Option
                                            key={location.value}
                                            name={`${location.value}Option`}
                                            value={location.value}
                                        >
                                            {location.label}
                                        </Option>
                                    ))}
                                </SelectField>
                            )}
                            <Autosuggest
                                name="relevantLocationSearchQuery"
                                value={newLocation?.formattedAddress || ''}
                                isDisabled={hasPreviousLocations
                                    ? formValues['relevantLocationType'] !== efLocationTypes.SELECT_LOCATION
                                    : false
                                }
                                onChange={handleLocationSearchQueryChange}
                                onOptionSelect={handleRelevantLocationCandidateSelect}
                                optionValueSelector={locationCandidate => {
                                    return locationCandidate.formattedAddress;
                                }}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--16-0',
                                ])}
                                placeholder={translateTab('input_placeholder.please_fill_in')}
                                icon={searchIcon}
                            >
                                {(formValues['relevantLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                && locationSearchQuery === lastLocationSearchQuery.current
                                    ? locationCandidates
                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                        .map((locationCandidate, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    name={`relevant-location-candidate-${index}`}
                                                    value={locationCandidate}
                                                >
                                                    <Icon
                                                        icon={locationIcon}
                                                        className={cx('global!ace-u-margin--right-16')}
                                                    />
                                                    {locationCandidate.formattedAddress}
                                                </Option>
                                            );
                                        }) : null}
                            </Autosuggest>
                            {hasLocationNotes && (
                                <TextAreaField
                                    name="locationNotes"
                                    className={cx('global!ace-u-full-width', 'global!ace-u-margin--top-24')}
                                    placeholder={translateTab('location_notes_input_placeholder.please_fill_in')}
                                    label={translateTab('location_notes_input_label.location_notes')}
                                    value={newLocationNotes || ''}
                                    onChange={handleOnLocationNotesChange}
                                    isResizable={false}
                                    maxLength={config.MAXIMUM_LOCATION_NOTES_LENGTH}
                                />
                            )}
                            {hasConfirmation && (
                                <ButtonPrimary
                                    name="submitDamageLocation"
                                    type="submit"
                                    isDisabled={isSubmitButtonDisabled}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--16-0',
                                    ], {
                                        'ace-c-button-primary--is-positive': isRelevantLocationSaved,
                                    })}
                                >
                                    {isRelevantLocationSaved
                                        ? (
                                            <Fragment>
                                                {translateTab('button_label.relevant_location_confirmed')}
                                                <Icon
                                                    icon={checkmarkIcon}
                                                    className={cx(
                                                        'ace-c-icon--color-contrast',
                                                        'global!ace-u-margin--left-16',
                                                    )}
                                                />
                                            </Fragment>
                                        ) : translateTab('button_label.confirm_relevant_location')
                                    }
                                </ButtonPrimary>
                            )}
                        </div>
                    );
                }}
            </Form>
        </Panel>
    );
};
RelevantLocationPanel.propTypes = {
    title: PropTypes.string,
    mapName: PropTypes.string.isRequired,
    handleLocationSaving: PropTypes.func.isRequired,
    hasConfirmation: PropTypes.bool,
    hasPreviousLocations: PropTypes.bool,
    hasLocationNotes: PropTypes.bool,
    searchAssignmentRelevantLocationGeolocation: PropTypes.func.isRequired,
    resetAssignmentRelevantLocationCandidates: PropTypes.func.isRequired,
    onRelevantLocationSelect: PropTypes.func,
    serviceAssignment: PropTypes.object,
    serviceAssignments: PropTypes.array,
    serviceCase: PropTypes.object,
};

RelevantLocationPanel.defaultProps = {
    title: '',
    hasConfirmation: true,
    hasPreviousLocations: true,
    hasLocationNotes: false,
    onRelevantLocationSelect: null,
    serviceAssignment: null,
    serviceAssignments: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        searchAssignmentRelevantLocationGeolocation: payload => dispatch({
            type: serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION,
            payload,
        }),
        resetAssignmentRelevantLocationCandidates: payload => dispatch({
            type: serviceAssignmentActionTypes.RESET_RELEVANT_LOCATION_CANDIDATES,
            payload,
        }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RelevantLocationPanel));
