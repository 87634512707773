import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savActionTypes from '../savActionTypes';
import modalIds from '../../modalIds';

/**
 * Update SAV vehicle owner and driver flow
 */
const updateSAVVehicleOwnerAndDriverFlow = function* updateSAVVehicleOwnerAndDriverFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM);
        const {location} = yield select(state => state.router);
        // do not PATCH owner/driver data when modal is open,
        // neither confirm vehicle selection modal nor vehicle data modal
        if (location?.state?.isModalOpen
            && [modalIds.CONFIRM_VEHICLE_SELECTION, modalIds.VEHICLE_DATA].includes(location?.state?.modalId)
        ) {
            continue;
        }

        const {serviceCaseData, serviceCaseId, assignmentType, serviceAssignmentLineNo} = payload;

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData,
        });

        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM,
            assignmentType,
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceAssignmentData: {},
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM},
        });
    }
};

export default updateSAVVehicleOwnerAndDriverFlow;
