import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, TabPrevious, TabNext} from '@ace-de/ui-components';

const ScreenTabControls = ({screenTabs, activeTab}) => {
    const {cx} = useStyles();
    screenTabs = screenTabs || {};

    return (
        <div
            className={cx([
                'global!ace-u-flex--align-self-center',
                'global!ace-u-flex',
                'global!ace-u-flex--justify-center',
                'global!ace-u-flex--align-center',
                'global!ace-u-z-index--navigation',
            ])}
        >
            {Object.values(screenTabs)
                .findIndex(tabId => tabId === activeTab) !== 0 ? (
                    <TabPrevious className={cx('global!ace-u-margin--right-16')} />
                ) : (
                    <div
                        className={cx([
                            'global!ace-u-width--64',
                            'global!ace-u-height--64',
                            'global!ace-u-margin--right-16',
                        ])}
                    />
                )}
            {Object.values(screenTabs)
                .findIndex(tabId => tabId === activeTab) !== Object.values(screenTabs).length - 1 ? (
                    <TabNext className={cx('global!ace-u-margin--left-16')} />
                ) : (
                    <div
                        className={cx([
                            'global!ace-u-width--64',
                            'global!ace-u-height--64',
                            'global!ace-u-margin--left-16',
                        ])}
                    />
                )}
        </div>
    );
};

ScreenTabControls.propTypes = {
    activeTab: PropTypes.string,
    screenTabs: PropTypes.object,
};

ScreenTabControls.defaultProps = {
    activeTab: '',
    screenTabs: {},
};

export default ScreenTabControls;
