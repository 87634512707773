import {take} from 'redux-saga/effects';
import {efServiceAssignmentStatusTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignmentStatus from './updateServiceAssignmentStatus';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Save/Archive service assignment flow
 */
const saveArchiveServiceAssignmentFlow = function* saveArchiveServiceAssignmentFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW);

        const {serviceAssignmentLineNo, serviceCaseId, hasCreditCardPayment = false, serviceAssignmentData} = payload;

        if (hasCreditCardPayment) {
            yield* updateServiceAssignment({
                caller: serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
                // only return journey assignment has isPaidByCreditCard flag
                assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
        }

        yield* updateServiceAssignmentStatus({
            caller: serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: efServiceAssignmentStatusTypes.DONE,
            },
        });
    }
};

export default saveArchiveServiceAssignmentFlow;
