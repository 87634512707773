import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {efPersonTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';

const CommissioningContactPersonPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('commissioning_contact_person_panel');
    const {contactPerson, title, isAffectedPerson} = props;

    return (
        <Panel
            title={title}
            qaIdent="commissioning-contact-person-panel"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translatePanel('data_row_label.name')}
                        qaIdent="sa-contact-person-last-name"
                    >
                        {contactPerson?.lastName || '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.first_name')}
                        qaIdent="sa-contact-person-first-name"
                    >
                        {contactPerson?.firstName || '-'}
                    </DataRow>
                    {isAffectedPerson && (
                        <DataRow
                            label={translatePanel('data_row_label.birthday')}
                            qaIdent="sa-contact-person-birthday"
                        >
                            {contactPerson?.birthDate
                                ? moment(contactPerson.birthDate).format('DD.MM.YYYY')
                                : '-'}
                        </DataRow>
                    )}
                </div>
                <div>
                    {!isAffectedPerson && (
                        <DataRow
                            label={translatePanel('data_row_label.relationship_to_member')}
                            qaIdent="sa-contact-person-relationship-status"
                        >
                            {contactPerson?.relationshipStatus
                                ? translate(`global.service_assignment_person_type.${snakeCase(efPersonTypes[contactPerson.relationshipStatus])}`)
                                : '-'}
                        </DataRow>
                    )}
                    {!!isAffectedPerson && (
                        <DataRow
                            label={translatePanel('data_row_label.relationship_to_member')}
                            qaIdent="sa-contact-person-is-identical-to-member"
                        >
                            {contactPerson?.isIdenticalToMember
                                ? translatePanel('text.is_identical_to_member')
                                : translatePanel('text.is_not_identical_to_member')}
                        </DataRow>
                    )}
                    <DataRow
                        label={translatePanel('data_row_label.callback_no')}
                        qaIdent="sa-contact-person-phone-number"
                    >
                        {contactPerson?.phoneNo || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CommissioningContactPersonPanel.propTypes = {
    contactPerson: PropTypes.object,
    title: PropTypes.string,
    isAffectedPerson: PropTypes.bool,
};

CommissioningContactPersonPanel.defaultProps = {
    contactPerson: null,
    title: '',
    isAffectedPerson: false,
};

export default CommissioningContactPersonPanel;
