import {select, put} from 'redux-saga/effects';
import {apsServiceTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import config from '../../config';

const loadBudgetsByServiceType = function* loadBudgetsByServiceType({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;

    if (!serviceCaseId || !serviceAssignmentLineNo) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const {serviceAssignments} = yield select(state => state.serviceAssignments);

    const serviceCase = serviceCases[serviceCaseId];
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (!serviceCase
        || !serviceAssignment
        || Number(serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID) return;

    const {assignmentType} = serviceAssignment;
    const serviceType = assignmentType !== efServiceAssignmentTypes.RENTAL_CAR
        ? assignmentType
        : serviceCase.damage.location.countryCode !== config.DAMAGE_LOCATION_GERMANY_COUNTRY_CODE
            ? !serviceAssignment.rentalCarAbroadHome
                ? apsServiceTypes.RENTAL_CAR__ABROAD_MAX
                : apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX
            : apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY;

    const {member, createdAt} = serviceCase;
    const {status: memberStatus} = member;
    const tariffGroup = member.tariffDetails.tariffGroup;

    yield put({
        type: serviceAssignmentActionTypes.GET_BUDGETS_BY_SERVICE_TYPE,
        payload: {
            createdAt,
            serviceType,
            memberStatus,
            tariffGroup,
        },
    });
};

export default loadBudgetsByServiceType;
