import React, {useState, Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter, resolveRoute, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentStatusTypes, apmContractPartnerAssignmentChannelTypes, persistenceStates, apmACEPartnerTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, InputField, Checkbox, ButtonPrimary, Button, CheckboxGroup, InteractiveIcon} from '@ace-de/ui-components';
import {Icon, linkIcon, editIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as saaActionTypes from '../../service-assignments-accommodation/saaActionTypes';
import routePaths from '../../routePaths';
import contractPartnerScreenTabs from '../../contract-partners/contractPartnerScreenTabs';
import {validateEmail, validateFax} from '../../utils/validation';

const assignmentChannelSetup = {
    [apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]: {
        fieldName: 'email',
        validationMethod: validateEmail,
    },
    [apmContractPartnerAssignmentChannelTypes.FAX]: {
        fieldName: 'faxNo',
        validationMethod: validateFax,
    },
    [apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]: {
        fieldName: 'phoneNo',
        validationMethod: null,
    },
};

const CommissioningACEPartnerPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('sa_commissioning_panel');

    const {serviceAssignment, assignmentChannels, serviceCase, title, hasCreditCardPayment} = props;
    const {initiateServiceAssignmentStatusUpdateFlow, initiateSaveArchiveServiceAssignmentFlow} = props;
    const {submitCreatePDFForm, initiateResendServiceAssignmentFlow, handleOnAssignmentSubmit} = props;
    const {isConfirmedByMember = false, attachmentsToSendWithAssignment, updateCommissioningACEPartnerDetails} = props;
    const {initiateUpdateAccommodationFlow, initiateExtendSAAStayEndDateFlow, hasCostError = false} = props;
    const {initiateUpdateACEPartnerFlow} = props;
    const {acePartner} = serviceAssignment;

    const downloadPDFButtonRef = useRef(null);
    const persistenceStateRef = useRef(serviceAssignment.persistenceState);
    const [errorMessages, setErrorMessages] = useState({
        [apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]: '',
        [apmContractPartnerAssignmentChannelTypes.FAX]: '',
        [apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE]: '',
    });
    const [isPaidByCreditCard, setIsPaidByCreditCard] = useState(!!serviceAssignment.isPaidByCreditCard);
    const availableAssignmentChannels = (acePartner?.assignmentChannels?.length && acePartner.assignmentChannels
        .filter(assignmentChannel => assignmentChannels.includes(assignmentChannel))) || assignmentChannels;
    // Contract partners should rely on assignmentEmail and assignmentFaxNo fields,
    // while all other partners should rely on business contact details
    const [commissioningFormData, setCommissioningFormData] = useState({
        assignmentChannels: availableAssignmentChannels.filter(assignmentChannel => {
            if ((assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                    && !acePartner?.businessContactDetails?.email && !acePartner?.isManuallyAdded)
                || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX
                    && !acePartner?.businessContactDetails?.faxNo && !acePartner?.isManuallyAdded)) {
                return;
            }
            return assignmentChannel;
        }),
        ...(assignmentChannels.includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML)
            ? {email: acePartner?.businessContactDetails?.email || ''}
            : {}
        ),
        ...(assignmentChannels.includes(apmContractPartnerAssignmentChannelTypes.FAX)
            ? {faxNo: acePartner?.businessContactDetails?.faxNo || ''}
            : {}
        ),
    });

    /*
    * Conditions
    * */
    const doesACEPartnerExist = !!(acePartner && acePartner?.name && acePartner?.location);
    const isInputDisabled = serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED
        || serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE;
    // todo rely on some other logic in order to allow multiple channels without checkbox
    const hasCheckboxes = assignmentChannels?.length > 1;
    const hasLinkout = acePartner?.partnerType === apmACEPartnerTypes.CONTRACT_PARTNER;

    const isSendingDisabled = hasCreditCardPayment
        // SARJ: disabled if acePartner does not exist (hasCreditCardPayment checkbox is visible),
        // or if acePartner exists and transportation type is missing
        ? !doesACEPartnerExist ? true : !serviceAssignment?.transportationTypeReturnJourney
        // disabled if there's no Partner,
        : !doesACEPartnerExist
                // if there's no selected channel
                || (commissioningFormData?.assignmentChannels?.length === 0 && hasCheckboxes
                    ? true
                    : hasCheckboxes ? commissioningFormData.assignmentChannels?.some(channel => {
                        const formField = assignmentChannelSetup[channel].fieldName;
                        return !commissioningFormData[formField]; // if selected channel does not have value
                    })
                        : availableAssignmentChannels?.some(channel => {
                            const formField = assignmentChannelSetup[channel].fieldName;

                            // if there are no checkboxes and some channel has no value
                            return !commissioningFormData[formField];
                        }))
        || persistenceStateRef.current === persistenceStates.PENDING
        || isConfirmedByMember
        || hasCostError;
    const isExtendDisabled = serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE;
    const disallowedSymbols = ['Tab', ' '];

    /*
    * Handlers
    * */
    const handleOnSaveArchive = () => {
        initiateSaveArchiveServiceAssignmentFlow({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            ...(!!hasCreditCardPayment && {
                hasCreditCardPayment,
                serviceAssignmentData: {
                    isPaidByCreditCard,
                },
            }),
        });
    };

    const handleOnAddACEPartnerManually = () => {
        initiateUpdateACEPartnerFlow({
            // TODO: add payload
        });
    };

    const handleDownloadPDF = () => {
        submitCreatePDFForm(commissioningFormData);
        downloadPDFButtonRef.current.blur();
    };

    const handleServiceAssignmentStatusChange = () => {
        initiateServiceAssignmentStatusUpdateFlow();
    };

    const handleRedirectToContractPartnerScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        const {pathname} = resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: acePartner.id},
        );
        window.open(`${pathname}?activeTab=${contractPartnerScreenTabs.SERVICES}`, '_blank');
    };

    const handleOnChange = (key, value) => {
        setCommissioningFormData({
            ...commissioningFormData,
            [key]: value,
        });
    };

    const validateAssignmentChannels = (selectedChannels, channelValues) => {
        if (!selectedChannels.length) return;
        let hasError = false;

        selectedChannels.forEach(selectedChannel => {
            const selectedChannelSetup = assignmentChannelSetup[selectedChannel];
            if (!selectedChannelSetup?.validationMethod) return;

            const channelValue = channelValues[selectedChannelSetup.fieldName];
            if (!selectedChannelSetup.validationMethod(channelValue)) {
                setErrorMessages(prevState => ({
                    ...prevState,
                    [apmContractPartnerAssignmentChannelTypes[selectedChannel]]: translatePanel(`error_message.${selectedChannel.toLowerCase()}`),
                }));

                hasError = true;
            } else if (errorMessages[apmContractPartnerAssignmentChannelTypes[selectedChannel]] !== '') setErrorMessages(
                prevState => ({
                    ...prevState,
                    [apmContractPartnerAssignmentChannelTypes[selectedChannel]]: '',
                }),
            );
        });

        return hasError;
    };

    const handleOnSubmit = () => {
        if (!commissioningFormData) {
            return;
        }
        const {assignmentChannels: selectedChannels, ...channelValues} = commissioningFormData;

        if (validateAssignmentChannels(selectedChannels, channelValues)) return;

        const {businessContactDetails} = acePartner;

        handleOnAssignmentSubmit({
            acePartner: {
                ...acePartner,
                assignmentChannels: selectedChannels,
                businessContactDetails: {
                    ...(businessContactDetails || {}),
                    ...(selectedChannels.includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML)
                        ? {email: channelValues.email}
                        : {}),
                    ...(selectedChannels.includes(apmContractPartnerAssignmentChannelTypes.FAX)
                        ? {faxNo: channelValues.faxNo}
                        : {}),
                },
                contactDetails: null,
            },
        });
    };

    /*
     * Hooks
     * */
    useEffect(() => {
        if (serviceAssignment.persistenceState !== persistenceStateRef.current) {
            persistenceStateRef.current = serviceAssignment.persistenceState;
            if (serviceAssignment.persistenceState === persistenceStates.READY && acePartner) {
                const {businessContactDetails = {}} = acePartner;
                const {email, faxNo} = commissioningFormData;

                if (hasCreditCardPayment && isPaidByCreditCard) {
                    setIsPaidByCreditCard(false);
                }
                setCommissioningFormData({
                    ...commissioningFormData,
                    assignmentChannels: availableAssignmentChannels.filter(assignmentChannel => {
                        if ((assignmentChannel === apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML
                                && !acePartner?.businessContactDetails?.email && !acePartner?.isManuallyAdded)
                            || (assignmentChannel === apmContractPartnerAssignmentChannelTypes.FAX
                                && !acePartner?.businessContactDetails?.faxNo && !acePartner?.isManuallyAdded)) {
                            return;
                        }
                        return assignmentChannel;
                    }),
                    ...(assignmentChannels.includes(apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML)
                        ? {email: email !== businessContactDetails.email ? businessContactDetails.email : email}
                        : {}
                    ),
                    ...(assignmentChannels.includes(apmContractPartnerAssignmentChannelTypes.FAX)
                        ? {faxNo: faxNo !== businessContactDetails.faxNo ? businessContactDetails.faxNo : faxNo}
                        : {}
                    ),
                });
            }
        }
    }, [
        serviceAssignment.persistenceState,
        persistenceStateRef,
        acePartner,
        commissioningFormData,
        setCommissioningFormData,
        hasCreditCardPayment,
        isPaidByCreditCard,
        setIsPaidByCreditCard,
        assignmentChannels,
        availableAssignmentChannels,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        // if inputs are disabled, do not patch on tab change (nothing has been changed)
        if ((!serviceAssignment?.acePartner && !hasCreditCardPayment) || isInputDisabled) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS,
            });
            return;
        }

        const {assignmentChannels, email, faxNo} = commissioningFormData;

        updateCommissioningACEPartnerDetails({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                ...(hasCreditCardPayment && !doesACEPartnerExist
                    ? {isPaidByCreditCard}
                    : {
                        acePartner: {
                            assignmentChannels,
                            businessContactDetails: {
                                ...(acePartner?.businessContactDetails || {}),
                                email,
                                faxNo,
                            },
                        },
                    }),
            },
        });
    }, [
        serviceAssignment.lineNo,
        serviceAssignment.assignmentType,
        serviceAssignment.acePartner,
        serviceCase.id,
        updateCommissioningACEPartnerDetails,
        acePartner,
        commissioningFormData,
        isInputDisabled,
        hasCreditCardPayment,
        isPaidByCreditCard,
        doesACEPartnerExist,
    ]);

    return (
        <Panel
            title={title || translatePanel('panel_title.service_provider')}
            actions={
                serviceAssignment.assignmentType === efServiceAssignmentTypes.ACCOMMODATION ? (
                    <InteractiveIcon
                        icon={editIcon}
                        className={cx('ace-c-interactive-icon--reverse')}
                        onClick={() => initiateUpdateAccommodationFlow()}
                    />
                ) : (serviceAssignment.assignmentType === efServiceAssignmentTypes.PICKUP
                    && serviceAssignment?.acePartner?.isManuallyAdded ? (
                        <InteractiveIcon
                            icon={editIcon}
                            className={cx('ace-c-interactive-icon--reverse')}
                            onClick={() => handleOnAddACEPartnerManually()}
                        />
                    ) : null)
            }
        >
            {!doesACEPartnerExist && (
                <Fragment>
                    <p className={cx('global!ace-u-margin--bottom-24')}>
                        {translatePanel('text.no_service_provider_selected')}
                    </p>
                    {hasCreditCardPayment && (
                        <Checkbox
                            className={cx('global!ace-u-margin--bottom-24')}
                            value={isPaidByCreditCard}
                            isSelected={isPaidByCreditCard}
                            onChange={value => setIsPaidByCreditCard(value)}
                        >
                            {translatePanel('checkbox_label.already_paid_by_credit_card')}
                        </Checkbox>
                    )}
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}>
                        {serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.NEW
                            ? (
                                <Fragment>
                                    <ButtonPrimary
                                        onClick={handleOnSubmit}
                                        isDisabled={isSendingDisabled}
                                    >
                                        {translatePanel('button_label.send')}
                                    </ButtonPrimary>
                                    {serviceAssignment?.assignmentType === efServiceAssignmentTypes.PICKUP
                                    && !serviceAssignment?.acePartner?.isManuallyAdded && (
                                        <Button
                                            className={cx([
                                                'global!ace-u-margin--top-24',
                                                'global!ace-u-full-width',
                                            ])}
                                            onClick={handleOnAddACEPartnerManually}
                                        >
                                            {translatePanel('button_label.enter_another_partner')}
                                        </Button>
                                    )}
                                    {isPaidByCreditCard ? (
                                        <ButtonPrimary
                                            className={cx('global!ace-u-margin--top-24')}
                                            onClick={handleOnSaveArchive}
                                        >
                                            {translatePanel('button_label.save_archive')}
                                        </ButtonPrimary>
                                    ) : (
                                        <Button
                                            className={cx('global!ace-u-margin--top-24')}
                                            onClick={handleOnSaveArchive}
                                        >
                                            {translatePanel('button_label.save_archive')}
                                        </Button>
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <ButtonPrimary
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        onClick={handleServiceAssignmentStatusChange}
                                    >
                                        {translatePanel('button_label.change_status')}
                                    </ButtonPrimary>
                                    <Button
                                        ref={downloadPDFButtonRef}
                                        onClick={handleDownloadPDF}
                                    >
                                        {translatePanel('button_label.download_assignment_pdf')}
                                    </Button>
                                    <Button
                                        className={cx('global!ace-u-margin--top-24')}
                                        onClick={() => initiateResendServiceAssignmentFlow({
                                            attachmentsToSendWithAssignment,
                                        })}
                                        isDisabled={!doesACEPartnerExist || isConfirmedByMember || hasCostError}
                                    >
                                        {translatePanel('button_label.send_again')}
                                    </Button>
                                </Fragment>
                            )
                        }
                    </div>
                </Fragment>
            )}
            {doesACEPartnerExist && (
                <div>
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-12', 'global!ace-u-margin--bottom-24')}>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-flex-start',
                                    'global!ace-u-flex--justify-space-between',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-medium',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                >
                                    {acePartner.name}
                                </p>
                                {hasLinkout && (
                                    <span
                                        className={cx('global!ace-u-cursor--pointer')}
                                        onClick={handleRedirectToContractPartnerScreen}
                                    >
                                        <Icon
                                            icon={linkIcon}
                                        />
                                    </span>
                                )}
                            </div>
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {acePartner?.location
                                    ? acePartner.location.street
                                    : ''}
                            </p>
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {acePartner.location
                                    ? `${acePartner.location.postCode} ${acePartner.location.city}` : ''}
                            </p>
                            <p
                                className={cx('global!ace-u-typography--variant-body')}
                            >
                                {acePartner.businessContactDetails?.phoneNo
                                    ? `${translatePanel('text.tel')} ${acePartner.businessContactDetails.phoneNo}` : ''}
                            </p>
                        </div>
                    </div>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translatePanel('text.assign_assignment')}
                    </p>
                    <div />
                    <div>
                        {hasCheckboxes && (
                            <CheckboxGroup
                                name="assignmentChannels"
                                value={commissioningFormData.assignmentChannels}
                                onChange={value => handleOnChange(`assignmentChannels`, value)}
                            >
                                {assignmentChannels.map((assignmentChannel, idx) => {
                                    const assignmentChannelDetails = assignmentChannelSetup[assignmentChannel];
                                    return (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                {'global!ace-u-margin--48-0': idx !== 0},
                                            ])}
                                            key={assignmentChannel}
                                        >
                                            <Checkbox
                                                className={cx([
                                                    'global!ace-u-grid-column--span-1',
                                                    'global!ace-u-margin--top-24',
                                                ])}
                                                name={`assignmentChannel${assignmentChannelDetails.fieldName}`}
                                                value={assignmentChannel}
                                                isDisabled={isInputDisabled}
                                            />
                                            <InputField
                                                className={cx('global!ace-u-grid-column--span-11')}
                                                label={translatePanel(`input_label.${snakeCase(assignmentChannel)}`)}
                                                name={assignmentChannelDetails.fieldName}
                                                value={commissioningFormData[assignmentChannelDetails.fieldName] || ''}
                                                isDisabled={isInputDisabled}
                                                errors={errorMessages[assignmentChannel]
                                                    ? [errorMessages[assignmentChannel]]
                                                    : []}
                                                onChange={value => {
                                                    handleOnChange(assignmentChannelDetails.fieldName, value);
                                                }}
                                                disallowedSymbols={[
                                                    apmContractPartnerAssignmentChannelTypes.FAX,
                                                    apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                                                ].includes(assignmentChannel) ? disallowedSymbols : []}
                                            />
                                        </div>
                                    );
                                })}
                            </CheckboxGroup>
                        )}
                        {!hasCheckboxes && (
                            <Fragment>
                                {
                                    assignmentChannels.map((assignmentChannel, idx) => {
                                        const assignmentChannelDetails = assignmentChannelSetup[assignmentChannel];
                                        return (
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid',
                                                    {
                                                        'global!ace-u-margin--48-0': (idx !== 0
                                                                && assignmentChannels.length > 1)
                                                            || (idx === 0 && assignmentChannels.length === 1),
                                                    },
                                                ])}
                                                key={assignmentChannel}
                                            >
                                                <InputField
                                                    className={cx('global!ace-u-grid-column--span-11')}
                                                    label={translatePanel(`input_label.${snakeCase(assignmentChannel)}`)}
                                                    name={assignmentChannelDetails.fieldName}
                                                    value={commissioningFormData[assignmentChannelDetails.fieldName] || ''}
                                                    isDisabled={isInputDisabled}
                                                    errors={errorMessages[assignmentChannel]
                                                        ? [errorMessages[assignmentChannel]]
                                                        : []}
                                                    onChange={value => {
                                                        handleOnChange(assignmentChannelDetails.fieldName, value);
                                                    }}
                                                    disallowedSymbols={[
                                                        apmContractPartnerAssignmentChannelTypes.FAX,
                                                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                                                    ].includes(assignmentChannel) ? disallowedSymbols : []}
                                                />
                                            </div>
                                        );
                                    })
                                }
                                <InputField
                                    type="hidden"
                                    value={commissioningFormData.assignmentChannels}
                                    name="assignmentChannels"
                                    onChange={value => handleOnChange(`assignmentChannels`, value)}
                                />
                            </Fragment>
                        )}
                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}>
                            {serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.NEW
                                ? (
                                    <Fragment>
                                        <ButtonPrimary
                                            onClick={handleOnSubmit}
                                            name="submitButton"
                                            isDisabled={isSendingDisabled}
                                        >
                                            {translatePanel('button_label.send')}
                                        </ButtonPrimary>
                                        {serviceAssignment?.assignmentType === efServiceAssignmentTypes.PICKUP
                                        && !serviceAssignment?.acePartner?.isManuallyAdded && (
                                            <Button
                                                className={cx([
                                                    'global!ace-u-margin--top-24',
                                                    'global!ace-u-full-width',
                                                ])}
                                                onClick={handleOnAddACEPartnerManually}
                                            >
                                                {translatePanel('button_label.enter_another_partner')}
                                            </Button>
                                        )}
                                        <Button
                                            className={cx('global!ace-u-margin--top-24')}
                                            onClick={handleOnSaveArchive}
                                        >
                                            {translatePanel('button_label.save_archive')}
                                        </Button>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <ButtonPrimary
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            onClick={handleServiceAssignmentStatusChange}
                                        >
                                            {translatePanel('button_label.change_status')}
                                        </ButtonPrimary>
                                        <Button
                                            ref={downloadPDFButtonRef}
                                            onClick={handleDownloadPDF}
                                        >
                                            {translatePanel('button_label.download_assignment_pdf')}
                                        </Button>
                                        <Button
                                            className={cx('global!ace-u-margin--top-24')}
                                            onClick={() => initiateResendServiceAssignmentFlow({
                                                attachmentsToSendWithAssignment,
                                            })}
                                            isDisabled={isConfirmedByMember || hasCostError}
                                        >
                                            {translatePanel('button_label.send_again')}
                                        </Button>
                                        {
                                            serviceAssignment.assignmentType === efServiceAssignmentTypes.ACCOMMODATION && [ // eslint-disable-line max-len
                                                efServiceAssignmentStatusTypes.ASSIGNED,
                                                efServiceAssignmentStatusTypes.DONE,
                                                efServiceAssignmentStatusTypes.ACCEPTED,
                                            ].includes(serviceAssignment.assignmentStatus) && (
                                                <Button
                                                    className={cx(['global!ace-u-full-width', 'global!ace-u-margin--top-24'])}
                                                    onClick={() => initiateExtendSAAStayEndDateFlow()}
                                                    isDisabled={isExtendDisabled}
                                                >
                                                    {translatePanel('button_label.extend')}
                                                </Button>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            )}
        </Panel>
    );
};

CommissioningACEPartnerPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    title: PropTypes.string,
    initiateServiceAssignmentStatusUpdateFlow: PropTypes.func,
    assignmentChannels: PropTypes.array.isRequired,
    handleOnAssignmentSubmit: PropTypes.func.isRequired,
    submitCreatePDFForm: PropTypes.func.isRequired,
    initiateResendServiceAssignmentFlow: PropTypes.func.isRequired,
    initiateSaveArchiveServiceAssignmentFlow: PropTypes.func.isRequired,
    hasCreditCardPayment: PropTypes.bool,
    isConfirmedByMember: PropTypes.bool,
    hasCostError: PropTypes.bool,
    attachmentsToSendWithAssignment: PropTypes.array,
    initiateUpdateAccommodationFlow: PropTypes.func.isRequired,
    initiateExtendSAAStayEndDateFlow: PropTypes.func.isRequired,
    updateCommissioningACEPartnerDetails: PropTypes.func.isRequired,
    initiateUpdateACEPartnerFlow: PropTypes.func.isRequired,
};

CommissioningACEPartnerPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    title: '',
    initiateServiceAssignmentStatusUpdateFlow: () => {},
    hasCreditCardPayment: false,
    isConfirmedByMember: false,
    hasCostError: false,
    attachmentsToSendWithAssignment: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: getServiceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateServiceAssignmentStatusUpdateFlow: () => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW,
    }),
    initiateSaveArchiveServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateResendServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateUpdateAccommodationFlow: payload => dispatch({
        type: saaActionTypes.INITIATE_UPDATE_ACCOMMODATION_FLOW,
        payload,
    }),
    initiateExtendSAAStayEndDateFlow: payload => dispatch({
        type: saaActionTypes.INITIATE_EXTEND_STAY_END_DATE_FLOW,
        payload,
    }),
    updateCommissioningACEPartnerDetails: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS,
        payload,
    }),
    initiateUpdateACEPartnerFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_UPDATE_ACE_PARTNER,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissioningACEPartnerPanel));
