import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';
import * as samaActionTypes from '../samaActionTypes';

/**
 * Update SAMA commissioning flow
 */
const updateSAMACommissioningFlow = function* updateSAMACommissioningFlow() {
    while (true) {
        const {payload} = yield take(samaActionTypes.SUBMIT_SAMA_COMMISSIONING_FORM);
        const {
            serviceAssignmentLineNo,
            serviceAssignmentData,
            serviceCaseId,
            attachmentsToSendWithAssignment,
        } = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: samaActionTypes.SUBMIT_SAMA_COMMISSIONING_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: samaActionTypes.SUBMIT_SAMA_COMMISSIONING_FORM,
            assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: samaActionTypes.SUBMIT_SAMA_COMMISSIONING_FORM},
        });
    }
};

export default updateSAMACommissioningFlow;
