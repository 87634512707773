import {select, fork, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as memberActionTypes from '../memberActionTypes';

const deleteMemberNoteFlow = function* deleteMemberNoteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take([memberActionTypes.INITIATE_MEMBER_NOTE_DELETE_FLOW]);
        const {memberNoteId, memberId, shouldOpenModal} = payload;
        let chosenModalOption;

        if (shouldOpenModal) {
            yield* openModal(modalIds.DELETE_MEMBER_NOTE);

            chosenModalOption = yield take([
                memberActionTypes.DECLINE_MEMBER_NOTE_DELETE,
                memberActionTypes.CONFIRM_MEMBER_NOTE_DELETE,
            ]);
        }

        if ((chosenModalOption && chosenModalOption.type === memberActionTypes.CONFIRM_MEMBER_NOTE_DELETE)
            || !shouldOpenModal) {
            yield fork(fetchRequest, memberActionTypes.DELETE_MEMBER_NOTE_REQUEST,
                membersService.deleteMemberNote, {
                    memberNoteId,
                });

            const responseAction = yield take([
                memberActionTypes.DELETE_MEMBER_NOTE_REQUEST_SUCCEEDED,
                memberActionTypes.DELETE_MEMBER_NOTE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                yield put({
                    type: memberActionTypes.REMOVE_MEMBER_NOTE,
                    payload: {memberNoteId, memberId},
                });
            }
        }

        if (shouldOpenModal) {
            yield* closeModal(modalIds.DELETE_MEMBER_NOTE);
        }
    }
};

export default deleteMemberNoteFlow;
