import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sadehActionTypes from '../sadehActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SADEH cost coverage flow
 */
const updateSADEHCostCoverageFlow = function* updateSADEHCostCoverageFlow() {
    while (true) {
        const {payload} = yield take(sadehActionTypes.SUBMIT_SADEH_COST_COVERAGE_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData} = payload;
        const {serviceCaseId, attachmentsToSendWithAssignment} = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: sadehActionTypes.SUBMIT_SADEH_COST_COVERAGE_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });


        yield* updateServiceAssignment({
            caller: sadehActionTypes.SUBMIT_SADEH_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.DEATH,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sadehActionTypes.SUBMIT_SADEH_COST_COVERAGE_FORM},
        });
    }
};

export default updateSADEHCostCoverageFlow;
