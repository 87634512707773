/**
 * @typedef {string} tabId
 */

/**
 * SAVA screen tabs
 *
 * @enum {tabId}
 */
export default {
    SERVICE_LOCATION: 'service-location',
    TOWING_DESTINATION: 'towing-destination',
    CONTRACT_PARTNER: 'contract-partner',
    COMMISSIONING: 'commissioning',
};
