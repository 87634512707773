import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as sadehActionTypes from '../sadehActionTypes';

/**
 * Update SADEH journey data
 */
const updateSADEHJourneyData = function* updateSADEHJourneyData() {
    while (true) {
        const {payload} = yield take(sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM);
        const {serviceAssignmentData, serviceCaseData, serviceCaseId, serviceAssignmentLineNo, caseType} = payload;

        yield* updateServiceAssignment({
            caller: sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM,
            assignmentType: efServiceAssignmentTypes.DEATH,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield* updateServiceCase({
            caller: sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM,
            caseType: caseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM},
        });
    }
};

export default updateSADEHJourneyData;
