import React, {Fragment, useRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ButtonSecondary, Icon, InteractiveIcon} from '@ace-de/ui-components';
import {breakdownIcon, recoveryIcon, vehicleOpeningIcon, towingIcon, editIcon, noServiceIcon} from '@ace-de/ui-components/icons';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import styles from './ManualServiceSelectionPanel.module.scss';

const serviceIcons = {
    [efServiceTypes.VEHICLE_OPENING]: vehicleOpeningIcon,
    [efServiceTypes.RECOVERY]: recoveryIcon,
    [efServiceTypes.ROADSIDE_ASSISTANCE]: breakdownIcon,
    [efServiceTypes.TOWING]: towingIcon,
    [efServiceTypes.NO_SERVICE]: noServiceIcon,
};

const ManualServiceSelectionPanel = props => {
    const {cx} = useStyles(props, styles);
    const {initiateSAVServiceTypeSelectionFlow, serviceCase, match} = props;
    const {serviceType, id: serviceCaseId} = serviceCase;
    const {params} = match;
    const {serviceAssignmentLineNo} = params;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_damage_service_tab');
    const selectServiceIcon = type => serviceIcons[type] || null;
    const manualSelectionButtonRef = useRef(null);

    return (
        <Panel
            title={translateTab('manual_service_selection_panel.title')}
            className={cx('ace-c-service-description-panel', {
                'ace-c-service-description-panel--has-service': !!serviceType,
            })}
        >
            <div
                className={cx([
                    'global!ace-u-margin--bottom-32',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                {serviceType && (
                    <Fragment>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-typography--color-contrast',
                            ])}
                        >
                            {translate(`global.service_type.${snakeCase(serviceType)}`)}
                        </p>
                        <Icon
                            icon={selectServiceIcon(serviceType)}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-contrast',
                            ])}
                        />
                    </Fragment>
                )}
                {!serviceType && (
                    <p
                        className={cx('global!ace-u-typography--color-medium-emphasis')}
                    >
                        {translateTab('manual_service_selection_panel_text.message')}
                    </p>
                )}
            </div>
            {!serviceType && (
                <ButtonSecondary
                    ref={manualSelectionButtonRef}
                    className={cx('global!ace-u-full-width')}
                    onClick={() => {
                        initiateSAVServiceTypeSelectionFlow({serviceCaseId, serviceAssignmentLineNo});
                        manualSelectionButtonRef.current.blur();
                    }}
                >
                    {translateTab('manual_service_selection_button_label.set_manually')}
                </ButtonSecondary>
            )}
            {serviceType && (
                <InteractiveIcon
                    className={cx('ace-c-interactive-icon--contrast', 'global!ace-u-margin--top-8')}
                    icon={editIcon}
                    onClick={() => initiateSAVServiceTypeSelectionFlow({serviceCaseId, serviceAssignmentLineNo})}
                >
                    <p className={cx('global!ace-u-typography--variant-highlight')}>
                        {translateTab('manual_service_selection_button_label.change_service')}
                    </p>
                </InteractiveIcon>
            )}
        </Panel>
    );
};

ManualServiceSelectionPanel.propTypes = {
    serviceCase: PropTypes.object.isRequired,
    serviceType: PropTypes.string,
    initiateSAVServiceTypeSelectionFlow: PropTypes.func,
    match: PropTypes.object,
};

ManualServiceSelectionPanel.defaultProps = {
    serviceType: '',
    initiateSAVServiceTypeSelectionFlow: () => null,
    match: {},
};

const mapStateToProps = (state, props) => {
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateSAVServiceTypeSelectionFlow: payload => {
        dispatch({type: savActionTypes.INITIATE_SAV_SERVICE_TYPE_SELECTION_FLOW, payload});
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManualServiceSelectionPanel));
