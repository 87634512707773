import {take} from 'redux-saga/effects';
import updateServiceAssignmentSubStatus from '../../service-assignments/sagas/updateServiceAssignmentSubStatus';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Send SARC ACE partner enquiry flow
 */
const sendSARCACEPartnerEnquiryFlow = function* sendSARCACEPartnerEnquiryFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_ACE_PARTNER_ENQUIRY_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentSubStatusData} = payload;

        yield* updateServiceAssignmentSubStatus({
            caller: sarcActionTypes.SUBMIT_SARC_ACE_PARTNER_ENQUIRY_FORM,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentSubStatusData,
        });
    }
};

export default sendSARCACEPartnerEnquiryFlow;
