import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, HighlightCircle, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {ButtonPrimary, ButtonSecondary} from '@ace-de/ui-components/buttons';
import {Icon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const ConfirmServiceCaseDeletionModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('confirm_service_case_deletion_modal');
    const {declineServiceCaseDeletion, confirmServiceCaseDeletion, hasBackdrop} = props;

    return (
        <Modal
            action={(
                <Icon icon={closeIcon} onClick={declineServiceCaseDeletion} />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName="global!ace-u-flex--justify-center"
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-11',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--primary',
                        ])}
                    >
                        <Icon
                            icon={warningOutlinedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-highlight-pressed',
                            ])}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateModal('title.confirm_deletion')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('heading.confirm_deletion')}
                    </p>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    <ButtonPrimary
                        className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                        onClick={confirmServiceCaseDeletion}
                    >
                        {translateModal('button_label.confirm')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        className={cx('global!ace-u-full-width')}
                        onClick={declineServiceCaseDeletion}
                    >
                        {translateModal('button_label.decline')}
                    </ButtonSecondary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

ConfirmServiceCaseDeletionModal.propTypes = {
    declineServiceCaseDeletion: PropTypes.func.isRequired,
    confirmServiceCaseDeletion: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
};

ConfirmServiceCaseDeletionModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineServiceCaseDeletion: () => dispatch({type: serviceCaseActionTypes.DECLINE_DELETE_SERVICE_CASE_FLOW}),
    confirmServiceCaseDeletion: () => dispatch({type: serviceCaseActionTypes.CONFIRM_DELETE_SERVICE_CASE_FLOW}),
});

export default connect(null, mapDispatchToProps)(ConfirmServiceCaseDeletionModal);
