import React, {useState, Fragment, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, Link} from '@computerrock/formation-router';
import {ampVehicleFuelTypes, ampVehicleSpecialFeatureTypes, ampVehicleTypes, ampMemberTariffAdditionalPackageTypes} from '@ace-de/eua-entity-types';
import {Panel, SearchBox, useStyles, RadioButton, Button, Divider, Badge} from '@ace-de/ui-components';
import {Icon, plusIcon, warningAlertIcon, editIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableHead, TableRow, DataRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as memberSelectors from '../../members/memberSelectors';
import * as memberActionTypes from '../../members/memberActionTypes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import CreateTrailerPanel from '../../service-assignments-vehicle/ui-elements/CreateTrailerPanel';
import createDefaultVehicle from '../createDefaultVehicle';
import VehicleCreatePanel from './VehicleCreatePanel';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';
import routePaths from '../../routePaths';

const VehicleSelectPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sav_vehicle_tab');
    const {memberVehicles, serviceCase, serviceAssignments} = props;
    const {searchVehicles, vehicleSearchResults, submitServiceCaseVehicleDataForm} = props;
    const {selectedVehicle, setSelectedVehicle, initiateCreateServiceCaseVehicle} = props;
    const {initiateVehicleSelectionFlow, updateServiceAssignmentText, initiateEditServiceCaseVehicleFlow} = props;
    const [searchQueryString, setSearchQueryString] = useState('');
    const searchVehiclesDebounced = useMemo(() => debounce(searchVehicles, 250), [searchVehicles]);
    const {additionalPackages} = serviceCase.member?.tariffDetails;
    const [fallbackVehicleCreation, setFallbackVehicleCreation] = useState(
        config.IS_FALLBACK_SYSTEM
        && serviceCase?.vehicle
        && !serviceCase.vehicle?.id,
    );
    const isVehicleEditingFeatureAvailable = !config.IS_FALLBACK_SYSTEM && ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_VEHICLE,
        ecsFeatureActions.UPDATE,
    );

    const vehicleDefault = createDefaultVehicle();

    const handleOnChange = searchValue => {
        setSearchQueryString(searchValue);
        if (searchValue) {
            searchVehiclesDebounced({
                searchQueryString: searchValue,
                membershipNo: serviceCase.member.membershipNo,
            });
        }
    };

    // Note: trigger to select the last created vehicle, if agent was created newly vehicle
    useEffect(() => {
        if (serviceCase?.vehicle?.vehicleId) {
            setSelectedVehicle(serviceCase.vehicle);
            if (config.IS_FALLBACK_SYSTEM && fallbackVehicleCreation) setFallbackVehicleCreation(false);
        }
        if (config.IS_FALLBACK_SYSTEM && serviceCase?.vehicle && !serviceCase?.vehicle?.vehicleId && !fallbackVehicleCreation) { // eslint-disable-line max-len
            setFallbackVehicleCreation(true);
        }
    }, [serviceCase?.vehicle, setSelectedVehicle, memberVehicles, setFallbackVehicleCreation, fallbackVehicleCreation]);

    const handleVehicleSelected = vehicleId => {
        if (vehicleId) {
            const [vehicle] = memberVehicles.filter(vehicle => vehicle.id === vehicleId);
            if (vehicle) {
                // format vehicle data
                const {
                    id,
                    vehicleId,
                    specialFeatures,
                    licensePlateCountryFlag,
                    owner,
                    driver,
                    ...restVehicle
                } = vehicle;
                const serviceCaseData = {
                    vehicle: {
                        ...restVehicle,
                        vehicleId: id || vehicleId,
                        specialFeatures: specialFeatures === ampVehicleSpecialFeatureTypes.UNKNOWN || !specialFeatures.length // eslint-disable-line max-len
                            ? [ampVehicleSpecialFeatureTypes.UNKNOWN]
                            : specialFeatures,
                    },
                };

                // check if we have to reset assignment text
                let typeHasChanged = false;
                if (additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)) {
                    typeHasChanged = (serviceCase.vehicle?.type !== vehicle.type)
                    && (serviceCase.vehicle?.type === ampVehicleTypes.CAMPER || vehicle.type === ampVehicleTypes.CAMPER); // eslint-disable-line max-len
                }

                // if no selected vehicle & no draft, do not show the confirmation modal
                if (!selectedVehicle && !(serviceCase.vehicle && !serviceCase.vehicle?.vehicleId)) {
                    submitServiceCaseVehicleDataForm({
                        serviceCaseId: serviceCase.id,
                        serviceCaseData,
                        caseType: serviceCase.caseType,
                    });
                    if (typeHasChanged) {
                        updateServiceAssignmentText({
                            serviceCaseId: serviceCase.id,
                            serviceAssignments,
                            serviceAssignmentData: {
                                assignmentText: null,
                            },
                        });
                    }
                    return;
                }

                // open confirmation modal
                initiateVehicleSelectionFlow({
                    serviceCaseData,
                    resetAssignmentText: typeHasChanged,
                    serviceCaseId: serviceCase.id,
                    serviceAssignments,
                    caseType: serviceCase.caseType,
                });
            }
        }
    };

    const handleFBSVehicleCreation = () => {
        if (selectedVehicle) {
            initiateVehicleSelectionFlow({
                serviceCaseData: {
                    vehicle: {
                        vehicleId: null,
                        ...vehicleDefault,
                    },
                },
                resetAssignmentText: true,
                serviceCaseId: serviceCase.id,
                serviceAssignments,
                caseType: serviceCase.caseType,
            });
            return;
        }
        setFallbackVehicleCreation(true);
    };

    // if no case don't render
    if (!serviceCase) return null;

    const connectedMemberVehicles = searchQueryString
        ? vehicleSearchResults
        : memberVehicles.filter(vehicle => {
            // always include already selected vehicle
            if (selectedVehicle && vehicle.id === selectedVehicle.vehicleId) return true;
            // include only active vehicles
            return vehicle.isActive;
        });
    const sortedVehicles = connectedMemberVehicles.length > 1
        ? connectedMemberVehicles.slice().sort((memberVehicleA, memberVehicleB) => {
            return memberVehicleA.licensePlateNumber > memberVehicleB.licensePlateNumber ? 1 : -1;
        })
        : connectedMemberVehicles;
    const hasDraftVehicle = !config.IS_FALLBACK_SYSTEM && !!serviceCase.vehicle && !(serviceCase.vehicle?.vehicleId);

    return (
        <Fragment>
            <Panel
                className={cx('ace-c-panel--full-bleed-content')}
                title={translateTab('connected_member_vehicles_panel.title')}
                actions={(
                    <SearchBox
                        value={searchQueryString}
                        onChange={handleOnChange}
                        isDropDownDisabled={true}
                        placeholder={translateTab('connected_member_vehicles_panel_search_input_placeholder.search_by_license_plate')}
                    />
                )}
            >
                <Table qaIdent="vehicle-select">
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="vehicle-option" colSpan={1} />
                            <TableCell qaIdentPart="vehicle-manufacturer" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.manufacturer')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-model" colSpan={3}>
                                {translateTab('connected_member_vehicles_panel_table_header.model')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-licence-plate-number" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.license_plate_no')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-build-year" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.build_year')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-color" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.color')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-fuel-type" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.fuel_type')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-weight" colSpan={2}>
                                {translateTab('connected_member_vehicles_panel_table_header.weight')}
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={1} />
                        </TableRow>
                    </TableHead>
                    {hasDraftVehicle && (
                        <TableBody>
                            <TableRow
                                key={`draft-${serviceCase.vehicle.manufacturer}`}
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-typography--color-warning',
                                ])}
                                qaIdentPart={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                <TableCell
                                    qaIdentPart="vehicle-option"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={1}
                                >
                                    <Icon
                                        icon={warningAlertIcon}
                                        className={cx('ace-c-icon--color-warning')}
                                    />
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-manufacturer"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.manufacturer}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-model"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={3}
                                >
                                    {`${serviceCase.vehicle.model} ${serviceCase.vehicle.variant}`}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-licence-plate-number"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.licensePlateNumber}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-build-year"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.buildYear}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-color"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.color
                                        ? translate(`global.vehicle_color.${serviceCase.vehicle.color.toLowerCase()}`)
                                        : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-fuel-type"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                        ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[serviceCase.vehicle.fuelType])}`)
                                        : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-weight"
                                    qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                                    colSpan={2}
                                >
                                    {serviceCase.vehicle.weight?.toLocaleString(activeLocale) || ''}
                                </TableCell>
                                <TableCell colSpan={2} />
                                <TableCell colSpan={1} />
                            </TableRow>
                        </TableBody>
                    )}
                    {sortedVehicles.length > 0 && (
                    <TableBody>
                        {sortedVehicles.map(vehicle => (
                            <TableRow
                                key={vehicle.id}
                                qaIdentPart={vehicle.id}
                                className={cx('', {
                                    'global!ace-u-typography--color-disabled-main': selectedVehicle?.vehicleId === vehicle.id && !vehicle.isActive,
                                    'global!ace-u-typography--text-decoration-line-through': selectedVehicle?.vehicleId === vehicle.id && !vehicle.isActive,
                                })}
                            >
                                <TableCell
                                    qaIdentPart="vehicle-option"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={1}
                                >
                                    <RadioButton
                                        name={`vehicle-${vehicle.id}`}
                                        value={vehicle.id}
                                        isSelected={selectedVehicle?.vehicleId === vehicle.id}
                                        onChange={() => handleVehicleSelected(vehicle.id)}
                                    />
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-manufacturer"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.manufacturer}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-model"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={3}
                                >
                                    {`${vehicle.model} ${vehicle.variant}`}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-licence-plate-number"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.licensePlateNumber}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-build-year"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.buildYear}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-color"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.color
                                        ? translate(`global.vehicle_color.${vehicle.color.toLowerCase()}`)
                                        : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-fuel-type"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                        ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[vehicle.fuelType])}`)
                                        : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-weight"
                                    qaIdentPartPostfix={vehicle.id}
                                    colSpan={2}
                                >
                                    {vehicle.weight?.toLocaleString(activeLocale) || ''}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Link
                                        to={resolveRoute(routePaths.SERVICE_CASE_SEARCH, {vehicleId: vehicle.id})}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                        ])}
                                    >
                                        <Badge status="gold">
                                            {vehicle.caseIds?.length || 0}
                                        </Badge>
                                    </Link>
                                    {vehicle.isVehicleBCNav && (
                                        <Badge
                                            className={cx('global!ace-u-margin--left-4')}
                                        >
                                            {translate('global.vehicle_source.bc_nav')}
                                        </Badge>
                                    )}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => initiateEditServiceCaseVehicleFlow({vehicleId: vehicle.id})}
                                        isDisabled={!isVehicleEditingFeatureAvailable}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    )}
                </Table>
                <div className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-row-reverse',
                ])}
                >
                    <Button
                        onClick={config.IS_FALLBACK_SYSTEM
                            ? handleFBSVehicleCreation
                            : initiateCreateServiceCaseVehicle
                        }
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-margin--top-32',
                            'global!ace-u-margin--right-32',
                        ])}
                    >
                        <Icon
                            icon={hasDraftVehicle ? warningAlertIcon : plusIcon}
                            className={cx([
                                hasDraftVehicle ? 'ace-c-icon--color-warning' : 'ace-c-icon--color-highlight',
                                'global!ace-u-margin--right-8',
                            ])}
                        />
                        {hasDraftVehicle
                            ? translateTab('connected_member_vehicles_panel_button.finish_draft_creation')
                            : translateTab('connected_member_vehicles_panel_button.create_new_vehicle')}
                    </Button>
                </div>
            </Panel>
            {config.IS_FALLBACK_SYSTEM && (
                <VehicleCreatePanel serviceCase={serviceCase} isPanelVisibleInFBS={fallbackVehicleCreation} />
            )}
            {selectedVehicle && (
                <Panel
                    title={translateTab('selected_vehicle_details_panel.title')}
                >
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.type')}
                                qaIdent="vehicle-type"
                            >
                                {translate(`global.service_assignment_vehicle_type.${
                                    selectedVehicle.type.toLowerCase()
                                }`) || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.manufacturer')}
                                qaIdent="vehicle-manufacturer"
                            >
                                {selectedVehicle.manufacturer || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.model')}
                                qaIdent="vehicle-model"
                            >
                                {`${selectedVehicle.model} ${selectedVehicle.variant}` || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.license_plate_no')}
                                qaIdent="vehicle-licence-plate-number"
                            >
                                {selectedVehicle.licensePlateNumber || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.build_year')}
                                qaIdent="vehicle-build-year"
                            >
                                {selectedVehicle.buildYear || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.fuel_type')}
                                qaIdent="vehicle-fuel-type"
                            >
                                {selectedVehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                    ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[selectedVehicle.fuelType])}`)
                                    : '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.number_of_seats')}
                                qaIdent="vehicle-number-of-seats"
                            >
                                {selectedVehicle.numberOfSeats || '-'}
                            </DataRow>
                        </div>
                        <div>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.weight')}
                                qaIdent="vehicle-weight"
                            >
                                {selectedVehicle.weight?.toLocaleString(activeLocale) || '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.color')}
                                qaIdent="vehicle-color"
                            >
                                {selectedVehicle.color
                                    ? translate(`global.vehicle_color.${selectedVehicle.color.toLowerCase()}`)
                                    : '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('selected_vehicle_details_panel_input_label.vehicle_dimensions')}
                                className={cx('ace-c-data-row--full-bleed-content')}
                                contentClassName={cx([
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-items-stretch',
                                ])}
                                qaIdent="vehicle-dimensions"
                            >
                                <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                                    <p>{translateTab('selected_vehicle_details_panel_input_label.length')}</p>
                                    <p
                                        className={cx([
                                            'global!ace-u-flex--grow-1',
                                            'global!ace-u-typography--align-center',
                                        ])}
                                    >
                                        {selectedVehicle.length?.toLocaleString(activeLocale) || '-'}
                                    </p>
                                </div>
                                <Divider />
                                <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                                    <p>{translateTab('selected_vehicle_details_panel_input_label.width')}</p>
                                    <p
                                        className={cx([
                                            'global!ace-u-flex--grow-1',
                                            'global!ace-u-typography--align-center',
                                        ])}
                                    >
                                        {selectedVehicle.width?.toLocaleString(activeLocale) || '-'}
                                    </p>
                                </div>
                                <Divider />
                                <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                                    <p>{translateTab('selected_vehicle_details_panel_input_label.height')}</p>
                                    <p
                                        className={cx([
                                            'global!ace-u-flex--grow-1',
                                            'global!ace-u-typography--align-center',
                                        ])}
                                    >
                                        {selectedVehicle.height?.toLocaleString(activeLocale) || '-'}
                                    </p>
                                </div>
                            </DataRow>
                        </div>
                    </div>
                </Panel>
            )}
            <CreateTrailerPanel serviceCase={serviceCase} />
        </Fragment>
    );
};

VehicleSelectPanel.propTypes = {
    vehicleSearchResults: PropTypes.array,
    memberVehicles: PropTypes.array,
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.object,
    selectedVehicle: PropTypes.object,
    setSelectedVehicle: PropTypes.func.isRequired,
    submitServiceCaseVehicleDataForm: PropTypes.func.isRequired,
    updateServiceAssignmentText: PropTypes.func.isRequired,
    searchVehicles: PropTypes.func.isRequired,
    initiateCreateServiceCaseVehicle: PropTypes.func.isRequired,
    initiateVehicleSelectionFlow: PropTypes.func.isRequired,
    initiateEditServiceCaseVehicleFlow: PropTypes.func.isRequired,
};

VehicleSelectPanel.defaultProps = {
    vehicleSearchResults: [],
    memberVehicles: [],
    serviceCase: {},
    serviceAssignments: null,
    selectedVehicle: null,
};

const mapStateToProps = (state, props) => {
    return {
        vehicleSearchResults: memberSelectors.getVehicleSearchResults(state, props),
        serviceAssignments: serviceCaseSelectors.getServiceAssignments(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitServiceCaseVehicleDataForm: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM,
        payload,
    }),
    updateServiceAssignmentText: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW,
        payload,
    }),
    searchVehicles: payload => dispatch({type: memberActionTypes.SEARCH_VEHICLES, payload}),
    initiateCreateServiceCaseVehicle: () => dispatch({
        type: serviceCaseActionTypes.INITIATE_CREATE_SERVICE_CASE_VEHICLE_FLOW,
    }),
    initiateEditServiceCaseVehicleFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW,
        payload,
    }),
    initiateVehicleSelectionFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SELECT_SERVICE_CASE_VEHICLE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelectPanel);
