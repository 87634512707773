import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageObjectsInTrailerPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('satr_cost_coverage_tab');
    const {serviceAssignment} = props;

    return (
        <Panel
            title={translateTab('panel_title.objects_in_trailer')}
            qaIdent="satr-cost-coverage-object-in-trailer"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.luggage')}
                        qaIdent="sa-luggage"
                    >
                        {serviceAssignment.luggage
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.valuable_things')}
                        qaIdent="sa-valuable-things"
                    >
                        {serviceAssignment.valuableThings
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.bicycle_carrier')}
                        qaIdent="sa-bicycle-carrier"
                    >
                        {serviceAssignment.bicycleCarrier
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageObjectsInTrailerPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageObjectsInTrailerPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageObjectsInTrailerPanel));
