import {eventChannel} from 'redux-saga';
import {select, take, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const onSAMAMapClickSelectDoctorLocation = function* onSAMAMapClickSelectDoctorLocation(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');
    const samaDoctorsLayer = yield call(arcGISMap.getLayer, 'sama-doctors-layer');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (samaDoctorsLayer) {
            const unsubscribeClickListener = samaDoctorsLayer.on('feature-select', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: doctorLocationFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (doctorLocationFeatureDTO && !doctorLocationFeatureDTO.isSelected) {
            const selectedDoctorLocationLocationId = doctorLocationFeatureDTO['locationId'];

            const {serviceAssignments} = yield select(state => state.serviceAssignments);
            const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
            const selectedDoctorLocation = serviceAssignment
                && serviceAssignment.doctorLocationRecommendations
                    .find(doctorLocation => {
                        return doctorLocation.id === selectedDoctorLocationLocationId;
                    });

            if (!selectedDoctorLocation) continue;

            yield* updateServiceAssignment({
                caller: 'ON_SAMA_MAP_CLICK_SELECT_DOCTOR_LOCATION',
                assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                        externalId: selectedDoctorLocation.id,
                        location: {
                            ...selectedDoctorLocation,
                            locationId: selectedDoctorLocation.id,
                        },
                        name: selectedDoctorLocation.locationName || '',
                        businessContactDetails: {
                            phoneNo: selectedDoctorLocation.phoneNo || '',
                        },
                    },
                },
            });
        }
    }
};

export default onSAMAMapClickSelectDoctorLocation;
