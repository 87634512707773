import {put, take, fork, select} from 'redux-saga/effects';
import {resolveRoute, prepareSearchQueryParams, replace} from '@computerrock/formation-router';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {closeModal} from '@computerrock/formation-router/sagas';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import serviceCaseScreenTabs from '../../service-cases/serviceCaseScreenTabs';
import serviceAssignmentTypeToScreenTabMapping from '../serviceAssignmentTypeToScreenTabMapping';
import fetchRequest from '../../application/sagas/fetchRequest';

const cloneServiceAssignment = function* cloneServiceAssignment() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take([serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_CLONE_FORM]);
        const {serviceCaseId, serviceAssignmentLineNo, modal, redirectionPath} = payload;

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {
                serviceCaseId,
                persistenceState: persistenceStates.PENDING,
            },
        });

        yield put(replace(resolveRoute(
            routePaths.SERVICE_CASE_SECTION,
            {serviceCaseId},
            {
                search: prepareSearchQueryParams({
                    activeTab: serviceCaseScreenTabs.ASSIGNMENTS,
                    serviceAssignmentLineNo: `${serviceAssignmentLineNo}`,
                    modal: modal,
                }),
            },
        )));

        yield* closeModal(modalIds.SERVICE_ASSIGNMENT_STATUS_UPDATE);

        yield fork(
            fetchRequest,
            serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST,
            ecsFlowService.cloneServiceAssignment,
            {
                serviceCaseId,
                lineNo: serviceAssignmentLineNo,
            },
        );

        const serviceAssignmentCloneResponse = yield take([
            serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_FAILED,
        ]);

        if (!serviceAssignmentCloneResponse.error) {
            const {response} = serviceAssignmentCloneResponse.payload;
            const {serviceAssignmentDTO} = response;

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs: [serviceAssignmentDTO]},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
                payload: {
                    serviceCaseId,
                    persistenceState: persistenceStates.SUCCEEDED,
                },
            });

            yield put(replace(resolveRoute(
                redirectionPath,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo: serviceAssignmentDTO.lineNo,
                },
                {
                    search: prepareSearchQueryParams({
                        activeTab: serviceAssignmentTypeToScreenTabMapping[serviceAssignmentDTO.assignmentType],
                    }),
                },
            )));
        }

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {
                serviceCaseId,
                persistenceState: persistenceStates.READY,
            },
        });
    }
};

export default cloneServiceAssignment;
