import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import serviceCaseScreenTabs from './serviceCaseScreenTabs';
import savrScreenTabs from '../service-assignments-vehicle-return/savrScreenTabs';
import saaScreenTabs from '../service-assignments-accommodation/saaScreenTabs';
import samcScreenTabs from '../service-assignments-medical-clearance/samcScreenTabs';
import saosScreenTabs from '../service-assignments-other-services/saosScreenTabs';
import sarcScreenTabs from '../service-assignments-rental-car/sarcScreenTabs';
import sarjScreenTabs from '../service-assignments-return-journey/sarjScreenTabs';
import sastScreenTabs from '../service-assignments-short-trip/sastScreenTabs';
import saspScreenTabs from '../service-assignments-sick-person/saspScreenTabs';
import sasdScreenTabs from '../service-assignments-substitute-driver/sasdScreenTabs';
import savScreenTabs from '../service-assignments-vehicle/savScreenTabs';
import savaScreenTabs from '../service-assignments-vehicle-additional/savaScreenTabs';
import sapScreenTabs from '../service-assignments-pickup/sapScreenTabs';
import satrScreenTabs from '../service-assignments-trailer-return/satrScreenTabs';
import sadehScreenTabs from '../service-assignments-death-event-help/sadehScreenTabs';
import samsScreenTabs from '../service-assignments-medicine-shipment/samsScreenTabs';
import samaScreenTabs from '../service-assignments-medical-advice/samaScreenTabs';
import salaScreenTabs from '../service-assignments-legal-advice/salaScreenTabs';
import routePaths from '../routePaths';

const getServiceAssignmentRedirectParams = serviceAssignment => {
    const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
        || parseInt(serviceAssignment.lineNo, 10) === 1
        || serviceAssignment.isCloneFromInitial;

    switch (serviceAssignment.assignmentType) {
        case efServiceAssignmentTypes.VEHICLE: {
            return isInitialAssignment
                ? {
                    activeTab: serviceAssignment.serviceLocation
                        ? savScreenTabs.COMMISSIONING
                        : savScreenTabs.DAMAGE_LOCATION,
                    route: routePaths.SERVICE_ASSIGNMENT_VEHICLE,
                }
                : {
                    activeTab: serviceAssignment.serviceLocation
                        ? savaScreenTabs.COMMISSIONING
                        : savaScreenTabs.SERVICE_LOCATION,
                    route: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                };
        }

        case efServiceAssignmentTypes.VEHICLE_RETURN: {
            return {
                activeTab: savrScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_VEHICLE_RETURN,
            };
        }

        case efServiceAssignmentTypes.ACCOMMODATION: {
            return {
                activeTab: saaScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_ACCOMMODATION,
            };
        }

        case efServiceAssignmentTypes.MEDICAL_CLEARANCE: {
            return {
                activeTab: samcScreenTabs.COMMISSIONING,
                route: routePaths.SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE,
            };
        }

        case efServiceAssignmentTypes.OTHER_SERVICES: {
            return {
                activeTab: serviceAssignment.serviceType
                    ? saosScreenTabs.COST_COVERAGE
                    : saosScreenTabs.REQUIREMENTS,
                route: routePaths.SERVICE_ASSIGNMENT_OTHER_SERVICES,
            };
        }

        case efServiceAssignmentTypes.RENTAL_CAR: {
            return {
                activeTab: sarcScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_RENTAL_CAR,
            };
        }

        case efServiceAssignmentTypes.RETURN_JOURNEY: {
            return {
                activeTab: sarjScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_RETURN_JOURNEY,
            };
        }

        case efServiceAssignmentTypes.SHORT_TRIP: {
            return {
                activeTab: sastScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_SHORT_TRIP,
            };
        }

        case efServiceAssignmentTypes.SICK_PERSON: {
            return {
                activeTab: saspScreenTabs.COMMISSIONING,
                route: routePaths.SERVICE_ASSIGNMENT_SICK_PERSON,
            };
        }

        case efServiceAssignmentTypes.SUBSTITUTE_DRIVER: {
            return {
                activeTab: sasdScreenTabs.COMMISSIONING,
                route: routePaths.SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER,
            };
        }

        case efServiceAssignmentTypes.PICKUP: {
            return {
                activeTab: sapScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_PICKUP,
            };
        }

        case efServiceAssignmentTypes.TRAILER_RETURN: {
            return {
                activeTab: satrScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_TRAILER_RETURN,
            };
        }

        case efServiceAssignmentTypes.DEATH: {
            return {
                activeTab: sadehScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_DEATH_EVENT_HELP,
            };
        }

        case efServiceAssignmentTypes.MEDICINE_SHIPMENT: {
            return {
                activeTab: samsScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT,
            };
        }

        case efServiceAssignmentTypes.MEDICAL_ADVICE: {
            return {
                activeTab: samaScreenTabs.COMMISSIONING,
                route: routePaths.SERVICE_ASSIGNMENT_MEDICAL_ADVICE,
            };
        }

        case efServiceAssignmentTypes.LEGAL_ADVICE: {
            return {
                activeTab: salaScreenTabs.COST_COVERAGE,
                route: routePaths.SERVICE_ASSIGNMENT_LEGAL_ADVICE,
            };
        }

        default: {
            return {
                activeTab: serviceCaseScreenTabs.ASSIGNMENTS,
                route: routePaths.SERVICE_CASE_SECTION,
            };
        }
    }
};

export default getServiceAssignmentRedirectParams;
