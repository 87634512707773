import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Update service assignment final destination flow
 */
const updateServiceAssignmentFinalDestination = function* updateServiceAssignmentFinalDestination() {
    while (true) {
        const {payload} = yield take([
            serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW,
        ]);
        const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceAssignmentData,
        });
    }
};

export default updateServiceAssignmentFinalDestination;
