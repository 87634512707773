import {take, put} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as sasdActionTypes from '../../service-assignments-substitute-driver/sasdActionTypes';
import * as samcActionTypes from '../../service-assignments-medical-clearance/samcActionTypes';
import * as sadehActionTypes from '../../service-assignments-death-event-help/sadehActionTypes';
import * as samsActionTypes from '../../service-assignments-medicine-shipment/samsActionTypes';
import * as samaActionTypes from '../../service-assignments-medical-advice/samaActionTypes';
import * as salaActionTypes from '../../service-assignments-legal-advice/salaActionTypes';

/**
 * Create person service assignment flow
 */
const createPersonServiceAssignmentFlow = function* createPersonServiceAssignmentFlow() {
    while (true) {
        const {payload} = yield take([serviceAssignmentActionTypes.CREATE_PERSON_SERVICE_ASSIGNMENT]);

        const {serviceCaseId, assignmentType} = payload;

        switch (assignmentType) {
            case efServiceAssignmentTypes.SUBSTITUTE_DRIVER: {
                yield put({
                    type: sasdActionTypes.CREATE_SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER,
                    payload: {serviceCaseId},
                });
                break;
            }

            case efServiceAssignmentTypes.MEDICAL_CLEARANCE: {
                yield put({
                    type: samcActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE,
                    payload: {serviceCaseId},
                });
                break;
            }

            case efServiceAssignmentTypes.DEATH: {
                yield put({
                    type: sadehActionTypes.CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP,
                    payload: {serviceCaseId},
                });
                break;
            }

            case efServiceAssignmentTypes.MEDICINE_SHIPMENT: {
                yield put({
                    type: samsActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT,
                    payload: {serviceCaseId},
                });
                break;
            }

            // TODO: check assignment types for newly added person assignments
            case efServiceAssignmentTypes.MEDICAL_ADVICE: {
                yield put({
                    type: samaActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE,
                    payload: {serviceCaseId},
                });
                break;
            }

            case efServiceAssignmentTypes.LEGAL_ADVICE: {
                yield put({
                    type: salaActionTypes.CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE,
                    payload: {serviceCaseId},
                });
                break;
            }

            default: {
                // no-op
            }
        }
    }
};

export default createPersonServiceAssignmentFlow;
