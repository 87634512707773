import {put, select, take, fork} from 'redux-saga/effects';
import {etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import serviceCaseScreenTabs from '../serviceCaseScreenTabs';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import modalIds from '../../modalIds';

const loadServiceCaseTasks = function* loadServiceCaseTasks({payload}) {
    const {match, location} = payload;
    const {serviceCaseId} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    const isTaskListAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);

    if (!serviceCaseId || activeTab !== serviceCaseScreenTabs.TASK_LIST || !isTaskListAvailable) return;

    // do not reload tasks when agent opens task data modal
    if (location && location.state?.isModalOpen
        && location.state?.modalId === modalIds.TASK_DATA) return;

    // reset search results
    yield put({
        type: taskActionTypes.STORE_TASKS,
        payload: {taskDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('assignedCaseId', serviceCaseId);
    searchQueryParams.append('status', etmTaskStatusTypes.DUE);
    searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);
    searchQueryParams.append('sort', 'dueDate,asc');

    yield fork(
        fetchRequest,
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST,
        ecsTaskManagementService.getTasks,
        {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED,
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskDTOs} = response;

        yield put({
            type: taskActionTypes.STORE_TASKS,
            payload: {taskDTOs},
        });
    }
};

export default loadServiceCaseTasks;
