import {take, all, call, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savActionTypes from '../savActionTypes';

const selectSAVTowingDestinationFlow = function* selectSAVTowingDestinationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_TOWING_DESTINATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, isUpdateNeeded = true} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle');
        const savTowingDestinationsLayer = yield call(arcGISMap.getLayer, 'sav-towing-destinations');
        const savTowingDestinationRoutesLayer = yield call(arcGISMap.getLayer, 'sav-towing-destination-routes');

        if (savTowingDestinationsLayer && savTowingDestinationRoutesLayer) {
            const {towingDestination} = serviceAssignmentData;

            yield all([
                call(savTowingDestinationsLayer.selectFeatureByAttribute, {
                    where: `locationId = '${towingDestination?.id}'`,
                }),
                call(savTowingDestinationRoutesLayer.selectFeatureByAttribute, {
                    where: `locationId = '${towingDestination?.id}'`,
                }),
            ]);
        }

        if (isUpdateNeeded) {
            yield* updateServiceAssignment({
                caller: savActionTypes.SUBMIT_SAV_TOWING_DESTINATION_FORM,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
        }
    }
};

export default selectSAVTowingDestinationFlow;
