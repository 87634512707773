import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {efServiceAssignmentStatusTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Input, checkmarkIcon, Icon, Tooltip} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

const ServiceCaseInfoPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('service_case_info_panel');
    const {serviceCase, serviceAssignments, handleOnChange, externalCaseId, fallbackSystemId} = props;

    const [connectedServiceAssignmentsData, connectedServiceAssignmentsText] = useMemo(() => {
        if (!serviceAssignments?.length) return '-';
        const connectedServiceAssignments = serviceAssignments.filter(serviceAssignment => {
            return serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED
                && [
                    efServiceAssignmentTypes.ACCOMMODATION,
                    efServiceAssignmentTypes.RENTAL_CAR,
                    efServiceAssignmentTypes.RETURN_JOURNEY,
                    efServiceAssignmentTypes.SHORT_TRIP,
                ].includes(serviceAssignment.assignmentType);
        }).map(serviceAssignment => serviceAssignment.assignmentType);
        return [
            connectedServiceAssignments.map((assignmentType, idx) => (
                <span
                    key={`${assignmentType}-${idx}`}
                    className={cx([
                        'global!ace-u-inline-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <Icon
                        icon={checkmarkIcon}
                        className={cx(['ace-c-icon--s', 'global!ace-u-margin--right-4'])}
                    />
                    {translate(`global.service_type.${snakeCase(assignmentType)}`)}
                    {idx < connectedServiceAssignments.length - 1 ? ', ' : ''}
                </span>
            )),
            connectedServiceAssignments
                .map(assignmentType => translate(`global.service_type.${snakeCase(assignmentType)}`))
                .join(', '),
        ];
    }, [serviceAssignments, translate, cx]);

    const formatPersonsAtSite = () => {
        return (
            <span>
                {serviceCase.grownUpsAtSite
                    ? `${serviceCase.grownUpsAtSite} ${translatePanel('text.adults')}${serviceCase?.childrenAtSite ? ', ' : ''}`
                    : ''}
                {formatChildrenAtSite()}
            </span>
        );
    };

    const formatChildrenAtSite = () => {
        if (!serviceCase.childrenAtSite) return '';
        let outputString = `${serviceCase.childrenAtSite}`;
        serviceCase.childrenAge.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceCase.childrenAge.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')}) ${translatePanel('text.children')}`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    // if no service case, don't render
    if (!serviceCase) return null;

    return (
        <Panel
            title={translatePanel('title.service_case_data')}
            qaIdent="service-case-info"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translatePanel('data_row_label.external_case_id')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="external-case-id"
                    >
                        <Input
                            className={cx(['ace-c-input--small', 'global!ace-u-flex--grow-1'])}
                            value={externalCaseId}
                            onChange={value => handleOnChange('externalCaseId', value)}
                        />
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.fallback_system_id')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="fallback-system-id"
                    >
                        <Input
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            value={fallbackSystemId}
                            onChange={value => handleOnChange('fallbackSystemId', value)}
                            isDisabled={config.IS_FALLBACK_SYSTEM}
                        />
                    </DataRow>
                    <Tooltip message={connectedServiceAssignmentsText}>
                        <DataRow
                            label={translatePanel('data_row_label.connected_assignments')}
                            qaIdent="connected-assignments"
                            contentClassName={cx('ace-c-data-row__content--no-wrap')}
                        >
                            {connectedServiceAssignmentsData?.length ? connectedServiceAssignmentsData : '-'}
                        </DataRow>
                    </Tooltip>
                    <DataRow
                        label={translatePanel('data_row_label.credit_card_availability')}
                        qaIdent="credit-card-availability"
                    >
                        {serviceCase.isCreditCardAvailable
                            ? translatePanel('text.yes')
                            : translatePanel('text.no')}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translatePanel('data_row_label.departure_date')}
                        qaIdent="departure-date"
                    >
                        {serviceCase.departureDate ? moment(serviceCase.departureDate).format('DD.MM.YYYY') : '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.persons_at_site')}
                        qaIdent="persons-at-site"
                    >
                        {!serviceCase.grownUpsAtSite && !serviceCase.childrenAtSite
                            ? '-'
                            : formatPersonsAtSite()}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.pets_at_site')}
                        qaIdent="pets-at-site"
                    >
                        {serviceCase.petsAtSite || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

ServiceCaseInfoPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.array,
    handleOnChange: PropTypes.func.isRequired,
    externalCaseId: PropTypes.string.isRequired,
    fallbackSystemId: PropTypes.string.isRequired,
};

ServiceCaseInfoPanel.defaultProps = {
    serviceCase: null,
    serviceAssignments: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();
    return {
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ServiceCaseInfoPanel));
