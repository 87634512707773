import {take, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';
import modalIds from '../../modalIds';

const handleShiftRoleUpdateErrorFlow = function* handleShiftRoleUpdateErrorFlow() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');
    let shouldWaitForAction = true;
    let payloadData = null;
    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(userProfileActionTypes.INITIATE_SHIFT_ROLE_UPDATE_ERROR_FLOW);
            payloadData = payload;

            yield* openModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {
                errorCode: payloadData?.status || '',
                requestedAction: userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST.toLowerCase(),
            });
        }

        const chosenModalOption = yield take([
            userProfileActionTypes.CONFIRM_REUPDATE_USER_SHIFT_ROLE,
            userProfileActionTypes.DECLINE_REUPDATE_USER_SHIFT_ROLE,
        ]);

        // re-update user shift role (try again)
        if (chosenModalOption.type === userProfileActionTypes.CONFIRM_REUPDATE_USER_SHIFT_ROLE) {
            yield fork(
                fetchRequest,
                userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST,
                userProfileService.setUserShiftRole,
                {shiftRole: payloadData?.shiftRole || ''},
            );
            const setUserShiftRoleAction = yield take([
                userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_SUCCEEDED,
                userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED,
            ]);

            if (setUserShiftRoleAction.error) {
                shouldWaitForAction = false;
                continue;
            }
        }

        shouldWaitForAction = true;
        // close the modal
        const {location} = yield select(state => state.router);
        if (location?.query?.modal && location.query.modal === modalIds.FAILED_SERVICE_ERROR_MESSAGE) {
            yield* closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''});
        }
    }
};

export default handleShiftRoleUpdateErrorFlow;
