import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, Pill} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const ContractPartnerEmergencyCallDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner} = props;

    // pre-sort emergency contacts by id and put 24/7 contact first
    const sortedEmergencyContacts = useMemo(() => {
        if (!contractPartner?.emergencyContacts?.length) return [];
        const sortedContacts = contractPartner.emergencyContacts
            .filter(contact => !contact.is24h7Emergency && !contact.isAdditional)
            .sort((contactA, contactB) => contactA.id - contactB.id);
        const emergency24h7Contact = contractPartner.emergencyContacts.find(contact => !!contact.is24h7Emergency);
        if (emergency24h7Contact) {
            sortedContacts.splice(0, 0, emergency24h7Contact);
        }
        return sortedContacts;
    }, [contractPartner.emergencyContacts]);

    // if no contract partner, do not render
    if (!contractPartner) return null;

    const displayWorkingHours = workingHoursDTO => {
        const fromString = workingHoursDTO?.from?.slice(0, -3) || '';
        const toString = workingHoursDTO?.to?.slice(0, -3) || '';
        return fromString && toString
            ? `${fromString}-${toString} ${translate('global.time_unit.time')}`
            : translate('global.placeholder.unknown');
    };

    return (
        <Panel
            title={translateTab('panel_title.emergency_call_data')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="contract-partner-contact-details">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="cp-contact-details-index">
                            {translateTab('emergency_call_data_table_cell.contact')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="cp-contact-details-phone-number">
                            {translateTab('emergency_call_data_table_cell.telephone_number')}
                        </TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.name')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.function')}</TableCell>
                        <TableCell colSpan={1} />
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.reachability:')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.monday_friday')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.saturday')}</TableCell>
                        <TableCell colSpan={2}>{translateTab('emergency_call_data_table_cell.sunday_holiday')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedEmergencyContacts
                        .filter(contact => !contact.isDeleted)
                        .map((contact, idx) => {
                            const mondayToFridayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
                            });
                            const saturdayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
                            });
                            const sundayAndHolidayAvailability = contact.availability?.find(data => {
                                return data.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
                            });
                            return (
                                <TableRow
                                    key={`${contact.phoneNo}-${idx}`}
                                    qaIdentPart={`${contact.phoneNo}-${idx}`}
                                >
                                    <TableCell
                                        colSpan={2}
                                        qaIdentPart="cp-contact-details-index"
                                        qaIdentPartPostfix={`${contact.phoneNo}-${idx}`}
                                    >
                                        {contact.is24h7Emergency && (
                                            <Pill
                                                type="information"
                                                className={cx('global!ace-u-margin--right-8')}
                                            >
                                                {translateTab('pill_label.24_7')}
                                            </Pill>
                                        )}
                                        {contact.type || ''}
                                    </TableCell>
                                    <TableCell
                                        colSpan={3}
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--color-highlighted',
                                        ])}
                                        qaIdentPart="cp-contact-details-phone-number"
                                        qaIdentPartPostfix={`${contact.phoneNo}-${idx}`}
                                    >
                                        {contact.phoneNo || ''}
                                    </TableCell>
                                    <TableCell colSpan={2}>{contact.name || ''}</TableCell>
                                    <TableCell colSpan={2}>{contact.function || ''}</TableCell>
                                    <TableCell colSpan={1} />
                                    <TableCell colSpan={2} />
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(mondayToFridayAvailability)}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(saturdayAvailability)}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {displayWorkingHours(sundayAndHolidayAvailability)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ContractPartnerEmergencyCallDataPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerEmergencyCallDataPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerEmergencyCallDataPanel));
