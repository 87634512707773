import React from 'react';
import {useStyles} from '@ace-de/ui-components';
import styles from './EUALogo.module.scss';

const EUALogo = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);

    return (
        <div ref={ref} className={cx('ace-c-eua-logo')} />
    );
});

EUALogo.displayName = 'EUALogo';

export default EUALogo;
