import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, HighlightCircle, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Icon, closeIcon, warningOutlinedIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';
import deletionStatuses from '../deletionStatuses';
import * as userSelectors from '../../user-profiles/userProfileSelectors';

const ServiceCaseDeletionStatusModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('service_case_deletion_status_modal');
    const {hasBackdrop, history, users} = props;
    const {closeModal} = useRouter();
    const queryParams = new URLSearchParams(history?.location?.search);
    const requestStatus = queryParams.get('requestStatus');
    const lockUserId = queryParams.get('lockUserId');
    const lockedBy = `${users[lockUserId]?.firstName} ${users[lockUserId]?.lastName}` || '';

    return (
        <Modal
            action={(
                <Icon
                    icon={closeIcon}
                    onClick={() => closeModal(modalIds.SERVICE_CASE_DELETION_STATUS, {requestStatus, lockUserId})}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName="global!ace-u-flex--justify-center"
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-11',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            {
                                'ace-c-highlight-circle--success': requestStatus === deletionStatuses.SUCCEEDED,
                                'ace-c-highlight-circle--warning': requestStatus === deletionStatuses.FAILED,
                            },
                        ])}
                    >
                        <Icon
                            icon={requestStatus === deletionStatuses.SUCCEEDED ? checkmarkIcon : warningOutlinedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                {
                                    'ace-c-icon--color-success': requestStatus === deletionStatuses.SUCCEEDED,
                                    'ace-c-icon--color-warning': requestStatus === deletionStatuses.FAILED,
                                },
                            ])}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateModal(`title.deletion_${requestStatus.toLowerCase()}`)}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal(`message.deletion_${requestStatus.toLowerCase()}`, {lockedBy})}
                    </p>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    <ButtonPrimary
                        className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                        onClick={() => closeModal(modalIds.SERVICE_CASE_DELETION_STATUS, {requestStatus, lockUserId})}
                    >
                        {translateModal('button_label.close')}
                    </ButtonPrimary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

ServiceCaseDeletionStatusModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    history: PropTypes.object,
    users: PropTypes.object,
};

ServiceCaseDeletionStatusModal.defaultProps = {
    hasBackdrop: true,
    history: {},
    users: null,
};

const mapStateToProps = state => {
    return {
        users: userSelectors.getUsers(state),
    };
};

export default connect(mapStateToProps, null)(ServiceCaseDeletionStatusModal);
