/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_PICKUP = '[service-cases] CREATE_SERVICE_ASSIGNMENT_PICKUP';
export const SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION';
export const SEARCH_SAP_DESTINATION_GEOLOCATION = '[service-cases] SEARCH_SAP_DESTINATION_GEOLOCATION';
export const SUBMIT_SAP_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SAP_REQUIREMENTS_FORM';
export const START_SAP_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] START_SAP_MAP_CONTRACT_PARTNER_WATCHER';
export const STOP_SAP_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] STOP_SAP_MAP_CONTRACT_PARTNER_WATCHER';
export const SUBMIT_SAP_CONTRACT_PARTNER_FORM = '[service-cases] SUBMIT_SAP_CONTRACT_PARTNER_FORM';
export const SEARCH_SAP_CONTRACT_PARTNERS = '[service-cases] SEARCH_SAP_CONTRACT_PARTNERS';
export const CALCULATE_PICKUP_ROUTE_DISTANCE = '[service-cases] CALCULATE_PICKUP_ROUTE_DISTANCE';
export const SUBMIT_SAP_COST_COVERAGE_COMMISSIONING_FORM = '[service-cases] SUBMIT_SAP_COST_COVERAGE_COMMISSIONING_FORM';
export const SUBMIT_SAP_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SAP_COST_COVERAGE_FORM';
export const CONFIRM_SAP_QM_NOTES = '[service-cases] CONFIRM_SAP_QM_NOTES';
export const DECLINE_SAP_QM_NOTES = '[service-cases] DECLINE_SAP_QM_NOTES';
export const SUBMIT_SAP_REQUIRED_LOCATION_FORM = '[service-cases] SUBMIT_SAP_REQUIRED_LOCATION_FORM';
export const INITIATE_SAP_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION = '[service-cases] INITIATE_SAP_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_PICKUP_REQUEST = '[service-cases] CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST';
export const CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_SENT';
export const CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_VEHICLE_PICKUP_REQUEST_FAILED';

export const SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST';
export const SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAP_DESTINATION_GEOLOCATION_REQUEST_FAILED';

export const FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST = '[service-cases] FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST';
export const FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SENT = '[service-cases] FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST';
export const FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED';
export const FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED = '[service-cases] FILTER_SAP_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED';

export const FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST';
export const FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST';
export const FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAP_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST = '[service-cases] FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST';
export const FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT = '[service-cases] FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT';
export const FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED';
export const FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED = '[service-cases] FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED';

export const FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST = '[service-cases] FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST';
export const FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT = '[service-cases] FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT';
export const FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED';

export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED';

export const FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST = '[service-cases] FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST';
export const FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_SENT';
export const FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_DESTINATION_TO_CONTRACT_PARTNER_DISTANCE_REQUEST_FAILED';

export const FETCH_PICKUP_PRICES_REQUEST = '[service-cases] FETCH_PICKUP_PRICES_REQUEST';
export const FETCH_PICKUP_PRICES_REQUEST_SENT = '[service-cases] FETCH_PICKUP_PRICES_REQUEST_SENT';
export const FETCH_PICKUP_PRICES_REQUEST_SUCCEEDED = '[service-cases] FETCH_PICKUP_PRICES_REQUEST_SUCCEEDED';
export const FETCH_PICKUP_PRICES_REQUEST_FAILED = '[service-cases] FETCH_PICKUP_PRICES_REQUEST_FAILED';

export const FETCH_SURCHARGE_RATES_REQUEST = '[service-cases] FETCH_SURCHARGE_RATES_REQUEST';
export const FETCH_SURCHARGE_RATES_REQUEST_SENT = '[service-cases] FETCH_SURCHARGE_RATES_REQUEST_SENT';
export const FETCH_SURCHARGE_RATES_REQUEST_SUCCEEDED = '[service-cases] FETCH_SURCHARGE_RATES_REQUEST_SUCCEEDED';
export const FETCH_SURCHARGE_RATES_REQUEST_FAILED = '[service-cases] FETCH_SURCHARGE_RATES_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS = '[service-cases] SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS';
export const SET_SAP_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT = '[service-cases] SET_SAP_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT';
export const STORE_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE = '[service-cases] STORE_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE';
export const RESET_SAP_PICKUP_LOCATION_CANDIDATES = '[service-cases] RESET_SAP_PICKUP_LOCATION_CANDIDATES';
export const RESET_SAP_DESTINATION_CANDIDATES = '[service-cases] RESET_SAP_DESTINATION_CANDIDATES';
export const SET_SAP_PICKUP_LOCATION_CANDIDATES = '[service-cases] SET_SAP_PICKUP_LOCATION_CANDIDATES';
export const SET_SAP_DESTINATION_CANDIDATES = '[service-cases] SET_SAP_DESTINATION_CANDIDATES';
export const SET_SAP_PICKUP_ROUTE_DISTANCE = '[service-cases] SET_SAP_PICKUP_ROUTE_DISTANCE';
export const RESET_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE = '[service-cases] RESET_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE';
export const SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE = '[service-cases] SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE';
export const STORE_PICKUP_PRICES = '[service-cases] STORE_PICKUP_PRICES';
