import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as savActionTypes from '../savActionTypes';
import savScreenTabs from '../savScreenTabs';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const createServiceAssignmentVehicleFlow = function* createServiceAssignmentVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE);
        const {serviceCaseId, isNonACEMemberEmptyCase = false} = payload;

        yield fork(
            fetchRequest,
            savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST,
            ecsFlowService.createServiceAssignment,
            {
                serviceCaseId,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
            },
        );

        const responseAction = yield take([
            savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_SUCCEEDED,
            savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_FAILED,
        ]);

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE,
            payload: {isServiceCaseCreationInProgress: false},
        });

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceAssignmentDTO} = response;
            const serviceAssignmentLineNo = serviceAssignmentDTO.lineNo;
            const serviceAssignmentDTOs = [serviceAssignmentDTO];

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs, serviceCaseId},
            });

            if (isNonACEMemberEmptyCase) {
                // set selfPayerOption to true
                yield* updateServiceAssignment({
                    caller: savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE,
                    assignmentType: efServiceAssignmentTypes.VEHICLE,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData: {
                        selfPayerOption: true,
                    },
                });
            }

            localStorage.setItem('isInitialAssignmentCreationFlow', 'true');

            yield put(push(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_VEHICLE,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo,
                },
                {search: prepareSearchQueryParams({activeTab: savScreenTabs.DAMAGE_AND_SERVICE})},
            )));
        }
    }
};

export default createServiceAssignmentVehicleFlow;
