import {select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import savScreenTabs from '../savScreenTabs';
import modalIds from '../../modalIds';
import filterSAVMapContractPartnersByLocation from './filterSAVMapContractPartnersByLocation';

const loadSAVContractPartnerRecommendations = function* loadSAVContractPartnerRecommendations({payload}) {
    const {match, location} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.CONTRACT_PARTNER || location.query.modal || (location.state?.isModalClosed
        && location.state?.modalId === modalIds.SERVICE_ASSIGNMENT_QM_NOTES)) return;
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignment = (serviceAssignments || {})[`${serviceCaseId}-${serviceAssignmentLineNo}`] || null;
    const {contractPartners} = yield select(state => state.contractPartners);
    const contractPartner = (contractPartners || {})[serviceAssignment?.acePartner?.id] || null;
    const currentQueryParams = new URLSearchParams(location.search);
    const isVBASearch = currentQueryParams.get('isVBASearch') === 'false'
        ? false
        : (currentQueryParams.get('isVBASearch') === 'true'
            || contractPartner?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
        );

    yield* filterSAVMapContractPartnersByLocation(serviceCaseId, serviceAssignmentLineNo, isVBASearch);
};

export default loadSAVContractPartnerRecommendations;
