import {take, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

const createPDFFlow = function* createSAVPDFFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.SUBMIT_CREATE_PDF_FORM);
        const {serviceCaseId, serviceAssignmentLineNo, serviceType, prefix} = payload;

        yield fork(fetchRequest, serviceAssignmentActionTypes.CREATE_PDF_REQUEST,
            ecsFlowService.createPDF, {
                serviceCaseId,
                serviceAssignmentLineNo,
                type: 'DEFAULT',
                serviceType,
                prefix,
            });

        yield take([
            serviceAssignmentActionTypes.CREATE_PDF_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.CREATE_PDF_REQUEST_FAILED,
        ]);
    }
};

export default createPDFFlow;
