import React, {useState, useRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {EditorState, ContentState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {useTranslate} from '@computerrock/formation-i18n';
import {languages, ehmCaseLogMessageTopics, ehmCaseLogMessageChannels, efServiceAssignmentTypes, ehmCaseLogMessageTextTemplates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Divider, Option, ButtonPrimary, MultiSelectOption, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {Form, SelectField, InputField, MultiSelectField, RichTextAreaField} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, closeIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as caseLogSelectors from '../caseLogSelectors';
import textTemplatesMatrix from '../textTemplatesMatrix';
import {validateEmail} from '../../utils/validation';

const UNDERSCORES_STRING = '___________';

const SendMessagesModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, serviceAssignments, serviceCase, declineSendMessage, confirmSendMessage, errorCode} = props;
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('send_messages_data_modal');
    const [messageData, setMessageData] = useState(null);
    const [receiverEndpointValue, setReceiverEndpointValue] = useState('');
    const [emailError, setEmailErrorMessage] = useState([]);
    const [richTextAreaContent, setRichTextAreaContent] = useState(EditorState.createEmpty());
    const prevTopic = useRef(null);
    const prevTextTemplates = useRef(null);
    const isButtonDisabled = !messageData?.channel
        || !messageData?.receiver
        || !richTextAreaContent.getCurrentContent().hasText()
        || !messageData?.topic
        || !messageData?.receiverEndpoint
        || !messageData?.assignments
        || (messageData?.receiver === 'OTHER' && !messageData?.name);

    const createTextTemplateArguments = (templates, topic, serviceAssignmentId) => {
        const serviceAssignment = serviceAssignments && serviceAssignments[serviceAssignmentId];

        const formattedFinalDestinationText = [
            serviceAssignment?.finalDestination?.location?.locationName,
            serviceAssignment?.finalDestination?.location?.formattedAddress,
        ].filter(value => value).join(', ');

        const formattedTowingDestinationText = [
            serviceAssignment?.towingDestination?.locationName,
            serviceAssignment?.towingDestination?.formattedAddress,
        ].filter(value => value).join(', ');

        const formattedWorkingHours = serviceAssignment?.finalDestination?.workingHoursFrom
            && serviceAssignment?.finalDestination?.workingHoursTo
            ? `${serviceAssignment?.finalDestination?.workingHoursFrom}-${serviceAssignment?.finalDestination?.workingHoursTo}`
            : UNDERSCORES_STRING;

        const formattedAlternativeWorkingHours = serviceAssignment?.finalDestination?.alternativeWorkingHoursFrom
        && serviceAssignment?.finalDestination?.alternativeWorkingHoursTo
            ? `${serviceAssignment?.finalDestination?.alternativeWorkingHoursFrom}-${serviceAssignment?.finalDestination?.alternativeWorkingHoursTo}`
            : UNDERSCORES_STRING;

        switch (topic) {
            case ehmCaseLogMessageTopics.FEEDBACK_ROADSIDE_ASSISTANCE_NOT_SUCCESSFUL: {
                switch (templates) {
                    case ehmCaseLogMessageTextTemplates.TOWING_WORKSHOP: {
                        return {
                            finalDestination: formattedFinalDestinationText
                            || formattedTowingDestinationText
                            || UNDERSCORES_STRING,
                        };
                    }

                    case ehmCaseLogMessageTextTemplates.TOWING_WORKSHOP_CLOSED:
                    case ehmCaseLogMessageTextTemplates.TOWING_DEPOT: {
                        return {
                            finalDestination: formattedFinalDestinationText || UNDERSCORES_STRING,
                            finalDestinationWorkingHours: formattedWorkingHours,
                            finalDestinationDailyFee: serviceAssignment?.finalDestination?.dailyFee
                                || UNDERSCORES_STRING,
                        };
                    }

                    default: {
                        return {};
                    }
                }
            }

            case ehmCaseLogMessageTopics.FEEDBACK_WORKSHOP: {
                switch (templates) {
                    case ehmCaseLogMessageTextTemplates.SCHEDULE_DIAGNOSIS:
                    case ehmCaseLogMessageTextTemplates.MISSING_DIAGNOSTIC_JOB: {
                        return {
                            finalDestinationWorkingHours: formattedWorkingHours,
                            finalDestinationDailyFee: serviceAssignment?.finalDestination?.dailyFee
                                || UNDERSCORES_STRING,
                        };
                    }

                    default: {
                        return {};
                    }
                }
            }

            case ehmCaseLogMessageTopics.FEEDBACK_TOWING: {
                switch (templates) {
                    case ehmCaseLogMessageTextTemplates.TOWING_DESTINATION: {
                        return {
                            finalDestination: formattedFinalDestinationText
                                || formattedTowingDestinationText
                                || UNDERSCORES_STRING,
                            finalDestinationWorkingHours: formattedWorkingHours,
                            finalDestinationAlternativeWorkingHours: formattedAlternativeWorkingHours,
                            finalDestinationDailyFee: serviceAssignment?.finalDestination?.dailyFee
                                || UNDERSCORES_STRING,
                        };
                    }

                    default: {
                        return {};
                    }
                }
            }

            default: {
                return {};
            }
        }
    };

    const handleOnChange = formValues => {
        if (formValues.receiver && formValues.channel) {
            const selectedChannel = formValues.channel;
            const receiverEndpointContactInfo = formValues.receiver.businessContactDetails
                ? formValues.receiver.businessContactDetails
                : formValues.receiver.contactDetails;

            const newReceiverEndpointValue = !receiverEndpointContactInfo || !selectedChannel
                ? ''
                : (selectedChannel === ehmCaseLogMessageChannels.FAX
                    ? receiverEndpointContactInfo['faxNo'] || ''
                    : (selectedChannel === ehmCaseLogMessageChannels.SMS
                        ? receiverEndpointContactInfo['phoneNo'] || ''
                        : receiverEndpointContactInfo['email'] || ''));

            setReceiverEndpointValue(newReceiverEndpointValue);
        }

        setMessageData(formValues);

        if (formValues.topic
            && formValues.textTemplates
            && (prevTopic.current !== formValues.topic || prevTextTemplates.current !== formValues.textTemplates)) {
            prevTopic.current = formValues.topic;
            prevTextTemplates.current = formValues.textTemplates;

            if (!textTemplatesMatrix[formValues.topic].includes(formValues.textTemplates)) {
                setRichTextAreaContent(EditorState.createEmpty());
                return;
            }

            const {topic, textTemplates} = formValues;
            const assignmentId = `${serviceCase.id}-${formValues?.assignments}`;

            const argsArray = [createTextTemplateArguments(textTemplates, topic, assignmentId)];

            const generatedText = translateModal.apply(this, [
                `message.${topic.toLowerCase()}_${textTemplates.toLowerCase()}`,
                ...formValues?.language === languages.ENGLISH ? [...argsArray, 'en-US'] : [...argsArray],
            ]);
            const newEditorState = EditorState.createWithContent(ContentState.createFromText(generatedText));
            setRichTextAreaContent(newEditorState);
        }
    };

    const handleOnSubmit = () => {
        if (messageData.channel === ehmCaseLogMessageChannels.EMAIL && !validateEmail(messageData.receiverEndpoint)) {
            setEmailErrorMessage([translateModal('text.please_enter_valid_email')]);
            return;
        }

        // NOTE: if email doesn't fall into if block, I need to set '' as message,
        // because it will keep prev state, which contains text
        setEmailErrorMessage([]);

        const translatedTopic = translateModal.apply(this, [
            `topic_option.${messageData?.topic.toLowerCase()}`,
            ...messageData?.language === languages.ENGLISH ? [{}, 'en-US'] : [],
        ]);
        const subject = `${translatedTopic} (${serviceCase.prefix}-${serviceCase?.id}-${messageData?.assignments}) / ${serviceCase?.externalCaseId}`;
        const channel = Object.keys(ehmCaseLogMessageChannels).find(key => {
            return ehmCaseLogMessageChannels[key] === messageData.channel;
        });
        const html = stateToHTML(richTextAreaContent.getCurrentContent());

        const sendMessageData = {
            serviceCaseId: serviceCase?.id,
            lineNo: messageData?.assignments,
            subject: subject,
            receiverName: messageData?.receiver.personalDetails
                ? `${messageData?.receiver.personalDetails.firstName} ${messageData?.receiver.personalDetails.surname}`
                : (messageData.receiver === 'OTHER'
                    ? messageData.name
                    : messageData.receiver.name || messageData.receiver.contractPartnerName
                ),
            channel: channel,
            receiverEndpoint: messageData?.receiverEndpoint,
            text: html,
            attachmentIds: messageData?.attachments === '' ? [] : messageData?.attachments,
            ...(!!channel && channel !== ehmCaseLogMessageChannels.SMS && {
                // base data
                memberName: [
                    serviceCase.member?.personalDetails?.firstName,
                    serviceCase.member?.personalDetails?.surname,
                ].filter(value => !!value).join(' '),
                licencePlate: serviceCase.vehicle?.licensePlateNumber || '',
                externalId: serviceCase.externalCaseId || '',
            }),
        };

        const serviceCaseId = serviceCase?.id;
        confirmSendMessage({sendMessageData, serviceCaseId});
    };

    return (
        <Modal
            contentClassName={cx('global!ace-u-modal-content-size--m')}
            title={translateModal('title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSendMessage}
                />
            )}
            className={cx('ace-c-modal--full-scrollable')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <Divider className={cx('global!ace-u-margin--bottom-32')} />
                <Form name="messageData" onChange={handleOnChange}>
                    {formValues => {
                        return (
                            <ContentBlock className={cx('global!ace-u-grid')}>
                                <ContentItem
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-grid-column--span-6',
                                        'global!ace-u-margin--right-64',
                                    ])}
                                >
                                    <SelectField
                                        name="language"
                                        label={translateModal('language_label')}
                                        className={cx('global!ace-u-margin--bottom-32')}
                                        value={messageData?.language || languages.GERMAN}
                                    >
                                        {Object.values(languages).map((language, id) => (
                                            <Option
                                                key={`${language}-${id}`}
                                                name={language}
                                                value={language}
                                                className={cx('ace-c-option--small')}
                                            >
                                                {translateModal(`language_option.${language.toLowerCase()}`)}
                                            </Option>
                                        ))}
                                    </SelectField>
                                    <SelectField
                                        name="channel"
                                        label={translateModal('channel_label')}
                                        placeholder={translate('global.select.placeholder')}
                                        className={cx('global!ace-u-margin--bottom-32')}
                                        value={messageData?.channel || ''}
                                        isFieldRequired={true}
                                    >
                                        {Object.keys(ehmCaseLogMessageChannels).map((channel, id) => (
                                            <Option
                                                key={`${channel}-${id}`}
                                                name={channel}
                                                value={ehmCaseLogMessageChannels[channel]}
                                                className={cx('ace-c-option--small')}
                                            >
                                                {translateModal(`channel_option.${channel.toLowerCase()}`)}
                                            </Option>
                                        ))}
                                    </SelectField>
                                    {serviceCase?.involvedParties ? (
                                        <SelectField
                                            name="receiver"
                                            label={translateModal('receiver_label')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-margin--bottom-32')}
                                            value={messageData?.receiver || ''}
                                            isFieldRequired={true}
                                        >
                                            {[...serviceCase?.involvedParties.entries()].map(entry => {
                                                const key = entry[0];
                                                const value = entry[1];
                                                return (
                                                    <Option
                                                        key={key}
                                                        name={`receiver-${key}`}
                                                        value={value}
                                                    >
                                                        {value.personalDetails ? `${value.personalDetails.firstName} ${value.personalDetails.surname}` : (value.name || value.contractPartnerName)}
                                                    </Option>
                                                );
                                            })}
                                            <Option
                                                key="other"
                                                name="receiver-other"
                                                value="OTHER"
                                            >
                                                {translateModal('receiver_option_label.other')}
                                            </Option>
                                        </SelectField>
                                    ) : null}
                                    <SelectField
                                        name="topic"
                                        label={translateModal('topic_label')}
                                        placeholder={translate('global.select.placeholder')}
                                        className={cx('global!ace-u-margin--bottom-32')}
                                        value={messageData?.topic || ''}
                                        isFieldRequired={true}
                                    >
                                        {Object.values(ehmCaseLogMessageTopics).map((topic, id) => (
                                            <Option
                                                key={`${topic}-${id}`}
                                                name={`${topic}-${id}`}
                                                value={topic}
                                            >
                                                {translateModal(`topic_option.${topic.toLowerCase()}`)}
                                            </Option>
                                        ))}
                                    </SelectField>
                                    <InputField
                                        name="receiverEndpoint"
                                        className={cx('global!ace-u-margin--bottom-32')}
                                        label={translateModal('email/fax/mobile_number_label')}
                                        value={receiverEndpointValue}
                                        errors={emailError}
                                        isFieldRequired={true}
                                    />
                                    {formValues.receiver === 'OTHER' && (
                                        <InputField
                                            name="name"
                                            label={translateModal('name_label')}
                                            value={messageData?.name || ''}
                                            isFieldRequired={true}
                                        />
                                    )}
                                </ContentItem>
                                <ContentItem className={cx('global!ace-u-grid-column--span-6')}>
                                    <SelectField
                                        name="assignments"
                                        label={translateModal('assignment_label')}
                                        placeholder={translate('global.select.placeholder')}
                                        className={cx([
                                            'global!ace-u-margin--bottom-32',
                                            'global!ace-u-full-width',
                                        ])}
                                        value={messageData?.assignments || ''}
                                        isFieldRequired={true}
                                    >
                                        {(serviceCase?.serviceAssignmentIds || []).map(serviceAssignmentId => {
                                            const serviceAssignment = serviceAssignments[serviceAssignmentId];
                                            const caseNumber = `${serviceCase?.prefix}${serviceCase.id}${serviceAssignment.lineNo}`;
                                            const {serviceType, assignmentType} = serviceAssignment;
                                            const serviceAssignmentType = serviceType
                                            && assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES
                                                ? translate(`global.service_type.${serviceType.toLowerCase()}`)
                                                : translate(`global.service_type.${assignmentType.toLowerCase()}`);
                                            const serviceAssignmentCreationDate = `${moment(serviceAssignment?.createdAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`;
                                            return (
                                                <Option
                                                    name={`${serviceCase.id}-${serviceAssignmentId}`}
                                                    key={`${serviceType}-${serviceAssignmentId}`}
                                                    value={serviceAssignment.lineNo}
                                                >
                                                    {`${caseNumber} ${serviceAssignmentType} (${serviceAssignmentCreationDate})`}
                                                </Option>
                                            );
                                        })}

                                    </SelectField>
                                    <SelectField
                                        name="textTemplates"
                                        label={translateModal('text_templates_label')}
                                        placeholder={translate('global.select.placeholder')}
                                        className={cx([
                                            'global!ace-u-margin--bottom-32',
                                            'global!ace-u-full-width',
                                        ])}
                                        value={messageData?.textTemplates || ''}
                                    >
                                        {
                                            formValues?.topic ? textTemplatesMatrix[`${formValues.topic}`].map((textTemplate, id) => (
                                                <Option
                                                    name={`${textTemplate}`}
                                                    key={`${textTemplate}-${id}`}
                                                    value={textTemplate}
                                                >
                                                    {translateModal(`text_template_option.${textTemplate.toLowerCase()}`)}
                                                </Option>
                                            )) : null
                                        }
                                    </SelectField>
                                    <RichTextAreaField
                                        label={translateModal('message_label')}
                                        maxLength={formValues.channel !== ehmCaseLogMessageChannels.EMAIL
                                            ? config.MAXIMUM_SMS_TEXT_AREA_CONTENT_LENGTH
                                            : null}
                                        isResizable={false}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--bottom-32',
                                        ])}
                                        setEditorState={value => setRichTextAreaContent(value)}
                                        editorState={richTextAreaContent}
                                        contentClassName={cx('ace-c-rich-text-area--small')}
                                        locale={activeLocale}
                                        isFieldRequired={true}
                                    />
                                    <MultiSelectField
                                        name="attachments"
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--bottom-32',
                                        ])}
                                        placeholder={translate('global.select.placeholder')}
                                        value={messageData?.attachments || ''}
                                        label={translateModal('select_attachment_label')}
                                        isDisabled={formValues.channel === ehmCaseLogMessageChannels.SMS}
                                    >
                                        {(serviceCase?.attachments || []).map(attachment => (
                                            <MultiSelectOption
                                                key={`${attachment.id}-${attachment.fileName}`}
                                                name={`option-${attachment.id}`}
                                                value={attachment.id}
                                                isSelected={true}
                                            >
                                                {attachment.fileName}
                                            </MultiSelectOption>
                                        ))}
                                    </MultiSelectField>
                                </ContentItem>
                            </ContentBlock>
                        );
                    }}
                </Form>
                <Divider className={cx('global!ace-u-margin--top-32')} />
                <div
                    className={cx([
                        'global!ace-u-margin--top-32',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    {errorCode && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--0-128',
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-typography--color-warning',
                            ])}
                        >
                            <Icon
                                icon={infoAlertIcon}
                                className={cx([
                                    'global!ace-u-margin--right-4',
                                    'ace-c-icon--s',
                                    'ace-c-icon--color-warning',
                                ])}
                            />
                            {translateModal(`error_message.send_message_failed`, {errorCode: errorCode || ''})}
                        </div>
                    )}
                    <ButtonPrimary
                        name="sendMessage"
                        onClick={handleOnSubmit}
                        isDisabled={isButtonDisabled}
                    >
                        {errorCode ? translateModal('button_label.try_again') : translateModal('button_label.send_message')}
                    </ButtonPrimary>
                </div>
            </div>
        </Modal>
    );
};

SendMessagesModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    serviceAssignments: PropTypes.object,
    serviceCase: PropTypes.object,
    declineSendMessage: PropTypes.func.isRequired,
    confirmSendMessage: PropTypes.func.isRequired,
    errorCode: PropTypes.string,
};

SendMessagesModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignments: null,
    serviceCase: null,
    errorCode: '',
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignments: serviceCaseSelectors.getServiceAssignments(state, props),
        errorCode: caseLogSelectors.getErrorCode(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineSendMessage: () => dispatch({type: caseLogActionTypes.DECLINE_SEND_MESSAGE}),
    confirmSendMessage: payload => dispatch({
        type: caseLogActionTypes.CONFIRM_SEND_MESSAGE,
        payload,
    }),
});


export default connect(mapStateToProps, mapDispatchToProps)(SendMessagesModal);
