import {take, put, fork} from 'redux-saga/effects';
import {push, resolveRoute} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import serviceManager from '../../serviceManager';
import * as userProfileActionTypes from '../userProfileActionTypes';
import ecsAccessControl from '../../ecsAccessControl';

const updateUserShiftRoleFlow = function* updateUserShiftRoleFlow() {
    while (true) {
        const {payload} = yield take(userProfileActionTypes.SUBMIT_CHANGE_USER_SHIFT_ROLE_FORM); // TODO SUBMIT_ _FORM
        const userService = serviceManager.loadService('userProfileService');
        const {shiftRole} = payload;

        yield put({
            type: userProfileActionTypes.STORE_SHIFT_ROLE_UPDATE_ERROR_CODE,
            payload: {shiftRoleUpdateErrorCode: ''},
        });

        yield fork(
            fetchRequest,
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST,
            userService.setUserShiftRole,
            {shiftRole},
        );
        const setUserShiftRoleAction = yield take([
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_SUCCEEDED,
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED,
        ]);

        if (setUserShiftRoleAction.type === userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED) {
            const {response} = setUserShiftRoleAction.payload;
            yield put({
                type: userProfileActionTypes.STORE_SHIFT_ROLE_UPDATE_ERROR_CODE,
                payload: {shiftRoleUpdateErrorCode: response?.status ? `${response.status}` : ''},
            });
            continue;
        }
        // pass selected shift role to plugin
        ecsAccessControl.setActiveShiftRole(shiftRole);
        yield put({
            type: userProfileActionTypes.SET_USER_SHIFT_ROLE,
            payload: {shiftRole},
        });
        yield put(push(resolveRoute(routePaths.DASHBOARD)));
    }
};

export default updateUserShiftRoleFlow;
