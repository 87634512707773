import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarjActionTypes from '../sarjActionTypes';

/**
 * Update SARJ service provider flow
 */
const updateSARJServiceProviderFlow = function* updateSARJServiceProviderFlow() {
    while (true) {
        const {payload} = yield take(sarjActionTypes.SUBMIT_SARJ_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: sarjActionTypes.SUBMIT_SARJ_SERVICE_PROVIDER_FORM,
            assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sarjActionTypes.SUBMIT_SARJ_SERVICE_PROVIDER_FORM},
        });
    }
};

export default updateSARJServiceProviderFlow;
