import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useRouter, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, Icon, checkmarkIcon} from '@ace-de/ui-components/icons';
import getPrice from '../../utils/getPrice';

const ContractPartnerHeader = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const router = useRouter();
    const translateHeader = createTranslateShorthand('contract_partner_header');
    const {contractPartner} = props;

    return (
        <Fragment>
            <ScreenTitle>
                <div
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-contrast',
                    ])}
                >
                    {contractPartner.name}
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span>{translateHeader('label.creditor_number')}</span>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--left-8',
                            ])}
                        >
                            {contractPartner.id || ''}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx('global!ace-u-margin--left-48')}>
                            {translateHeader('label.status')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--left-8',
                            ])}
                        >
                            {contractPartner.contractStatus
                                ? translate(`global.contract_status.${snakeCase(contractPartner.contractStatus)}`) : ''}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx(['global!ace-u-margin--left-48', 'global!ace-u-margin--right-8'])}>
                            {translateHeader('label.fixed_area_of_application')}
                        </span>
                        {contractPartner.fixedAreaOfApplication ? (
                            <Icon
                                icon={checkmarkIcon}
                                className={cx('ace-c-icon--color-success')}
                            />
                        ) : (
                            <Icon
                                icon={closeIcon}
                                className={cx('ace-c-icon--color-warning')}
                            />
                        )}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx('global!ace-u-margin--left-48')}>
                            {translateHeader('label.fixed_price')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--left-8',
                            ])}
                        >
                            {contractPartner.fixedPrice
                                ? getPrice(contractPartner.fixedPrice, activeLocale)
                                : '-'}
                        </span>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={router.goBack}
                        isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
                    />
                </div>
            </div>
        </Fragment>
    );
};

ContractPartnerHeader.propTypes = {
    contractPartner: PropTypes.object.isRequired,
};

export default withRouter(ContractPartnerHeader);
