import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savaActionTypes from '../savaActionTypes';

/**
 * Update SAVA service location info flow
 */
const updateSAVAServiceLocationInfoFlow = function* updateSAVAServiceLocationInfoFlow() {
    while (true) {
        const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM);
        const {serviceCase, serviceAssignmentLineNo, serviceCaseData, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo,
            serviceAssignmentData,
        });

        yield* updateServiceCase({
            caller: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_CASE_INFORMATION,
            serviceCaseId: serviceCase.id,
            caseType: serviceCase.caseType,
            serviceCaseData: serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM},
        });
    }
};

export default updateSAVAServiceLocationInfoFlow;
