import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saaActionTypes from '../saaActionTypes';

const updateSAAReferencePositionFlow = function* updateSAAReferencePositionFlow() {
    while (true) {
        const {payload} = yield take(saaActionTypes.SUBMIT_SAA_REFERENCE_POSITION_FORM);
        const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: saaActionTypes.SUBMIT_SAA_REFERENCE_POSITION_FORM,
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });
    }
};

export default updateSAAReferencePositionFlow;
