import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import SARJDetailsPanel from './ui-elements/SARJDetailsPanel';
import * as sarjActionTypes from './sarjActionTypes';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';

const SARJRequirementsTab = props => {
    const {cx} = useStyles();
    const {serviceAssignment, submitSARJMemberLocationForm} = props;

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        submitSARJMemberLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                memberLocation: locationInfo.selectedLocation,
                pickupLocation: locationInfo.selectedLocation,
            },
        });
    };

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <SARJDetailsPanel serviceAssignment={serviceAssignment} />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    mapName="sarj-member-location"
                    handleLocationSaving={handleLocationSaving}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SARJRequirementsTab.propTypes = {
    serviceAssignment: PropTypes.object,
    submitSARJMemberLocationForm: PropTypes.func.isRequired,
};

SARJRequirementsTab.defaultProps = {
    serviceAssignment: null,
};

const mapDispatchToProps = dispatch => ({
    submitSARJMemberLocationForm: payload => dispatch({
        type: sarjActionTypes.SUBMIT_SARJ_MEMBER_LOCATION_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(SARJRequirementsTab);
