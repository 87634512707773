import React, {Fragment, useState, useRef, useMemo, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efLocationTypes, efChildrenAgeTypes, Location} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerHomePNG, markerVehiclePNG, mapArcGISPOITypeToMarkerIcon} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, InputCounter, Option, Pill, Divider} from '@ace-de/ui-components';
import {Form, TextAreaField, AutosuggestField, InputField, InputPhoneNumberField, SelectField, ButtonPrimary, DateField, SearchBox} from '@ace-de/ui-components/form';
import {Icon, locationIcon, checkmarkIcon, calendarIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as savaActionTypes from './savaActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';

const SAVAServiceLocationTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sava_damage_location_tab');
    const arcGISMap = useArcGISMap('service-assignment-vehicle-additional');
    const [serviceLocationInfoFormData, setNewServiceLocationInfoFormData] = useState(null);
    const currentServiceLocationAddress = useRef('');
    const lastServiceLocationSearchQuery = useRef('');
    const prevReverseGeocodingServiceLocation = useRef(null);
    const {serviceCase, serviceAssignment, setArcGISNearbyPOIs} = props;
    const {arcGISNearbyPOIs, selectedPOIsCategory, searchArcGISNearbyPOIs} = props;
    const {searchSAVAServiceLocationGeolocation, searchSAVAReverseServiceLocationGeolocation} = props;
    const {submitSAVAServiceLocationForm, submitSAVAServiceLocationInfoForm} = props;
    const {resetSAVAReverseGeocodingServiceLocation} = props;
    const initialNewServiceLocationType = serviceAssignment && serviceAssignment.serviceLocation
        ? serviceAssignment.serviceLocation.locationType : efLocationTypes.SELECT_LOCATION;
    const [newServiceLocationType, setNewServiceLocationType] = useState(initialNewServiceLocationType);
    const [newServiceLocation, setNewServiceLocation] = useState(null);
    const [isNewServiceLocationSelected, setIsNewServiceLocationSelected] = useState(true);
    const newServiceLocationRef = useRef(newServiceLocation?.formattedAddress || '');
    const searchSAVAServiceLocationGeolocationDebounced = useMemo(
        () => debounce(searchSAVAServiceLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSAVAServiceLocationGeolocation],
    );
    const [childrenAge, setChildrenAge] = useState(serviceAssignment.childrenAge.length
        ? (serviceAssignment.childrenAge.length < serviceAssignment.childrenAtSite
            ? serviceAssignment.childrenAge
                .concat(new Array(serviceAssignment.childrenAtSite - serviceAssignment.childrenAge.length)
                    .fill('', 0))
            : serviceAssignment.childrenAge)
        : (serviceAssignment.childrenAtSite
            ? new Array(serviceAssignment.childrenAtSite).fill('', 0)
            : []));

    useEffect(() => {
        if (!serviceAssignment || !arcGISMap || !!newServiceLocation) return;

        const {serviceLocation} = serviceAssignment;
        if (serviceLocation) {
            setNewServiceLocation(serviceLocation);

            if (serviceLocation.locationType === efLocationTypes.RESIDENCE_PLACE) {
                arcGISMap.toggleMapClickEvent(false);
                return; // early exit to prevent enabling map click event
            }
        }

        arcGISMap.toggleMapClickEvent(true);
    }, [serviceAssignment, newServiceLocation, arcGISMap]);

    useEffect(() => {
        if (!serviceAssignment) return;

        const {reverseGeocodingServiceLocation} = serviceAssignment;
        if (reverseGeocodingServiceLocation
            && reverseGeocodingServiceLocation !== prevReverseGeocodingServiceLocation.current) {
            prevReverseGeocodingServiceLocation.current = reverseGeocodingServiceLocation;
            setNewServiceLocationType(reverseGeocodingServiceLocation.locationType);
            setNewServiceLocation(reverseGeocodingServiceLocation);
        }
    }, [serviceAssignment]);

    useEffect(() => {
        if (!arcGISMap) return;

        arcGISMap.hideLayer('sava-contract-partner-service-areas');
        arcGISMap.hideLayer('sava-contract-partner-routes');
        arcGISMap.hideLayer('sava-contract-partner-locations');
        arcGISMap.hideLayer('sava-towing-destinations');
        arcGISMap.hideLayer('sava-towing-destination-routes');
    }, [arcGISMap]);

    useEffect(() => {
        if (newServiceLocation && (newServiceLocation.formattedAddress !== newServiceLocationRef.current)) {
            newServiceLocationRef.current = newServiceLocation.formattedAddress || '';
            if (arcGISNearbyPOIs?.length > 0 && selectedPOIsCategory) {
                searchArcGISNearbyPOIs({
                    categoriesString: selectedPOIsCategory,
                });
            }
        }
    }, [newServiceLocation, searchArcGISNearbyPOIs, arcGISNearbyPOIs, selectedPOIsCategory]);

    useEffect(() => {
        if (!serviceCase || !arcGISMap) return;

        const {member} = serviceCase;
        const {residenceLocation} = member;

        arcGISMap.setGraphics({
            graphics: [
                ...(newServiceLocation ? [
                    createMarkerGraphic({
                        id: 'serviceLocation',
                        longitude: newServiceLocation.longitude,
                        latitude: newServiceLocation.latitude,
                        icon: markerVehiclePNG,
                        isDraggable: newServiceLocation.locationType !== efLocationTypes.RESIDENCE_PLACE,
                    }),
                ] : []),
                ...((residenceLocation && residenceLocation.longitude && residenceLocation.latitude)
                && (!newServiceLocation
                    || (newServiceLocation
                        && newServiceLocation.locationType !== efLocationTypes.RESIDENCE_PLACE))
                    ? [
                        createMarkerGraphic({
                            id: 'residenceLocation',
                            longitude: residenceLocation.longitude,
                            latitude: residenceLocation.latitude,
                            icon: markerHomePNG,
                        }),
                    ] : []),
                ...(arcGISNearbyPOIs?.length
                    ? arcGISNearbyPOIs.map(poi => createMarkerGraphic({
                        id: poi.id,
                        longitude: poi.longitude,
                        latitude: poi.latitude,
                        icon: mapArcGISPOITypeToMarkerIcon(Array.isArray(poi.establishmentType)
                            ? poi.establishmentType[0] : ''),
                        isSmall: true,
                        popupFields: {
                            formattedAddress: poi.formattedAddress,
                        },
                        popupTemplate: {
                            title: poi.locationName,
                            content: `<p>{formattedAddress}</p>`,
                        },
                    })) : []),
            ],
        });
    }, [serviceCase, newServiceLocation, arcGISMap, arcGISNearbyPOIs]);

    const handleServiceLocationTypeChange = useCallback(newServiceLocationType => {
        const {member} = serviceCase;
        const {residenceLocation} = member;
        const {serviceLocation} = serviceAssignment;
        setNewServiceLocationType(newServiceLocationType);

        if (newServiceLocationType === efLocationTypes.RESIDENCE_PLACE) {
            setNewServiceLocation(residenceLocation);
            if (arcGISMap) arcGISMap.toggleMapClickEvent(false);
            return;
        }

        // reset to efLocationTypes.SELECT_LOCATION
        if (newServiceLocationType === efLocationTypes.SELECT_LOCATION
            && serviceLocation
            && serviceLocation.locationType === efLocationTypes.SELECT_LOCATION) {
            setNewServiceLocation(serviceLocation);
            if (arcGISMap) arcGISMap.toggleMapClickEvent(true);
            return;
        }

        setNewServiceLocation(new Location({
            locationType: newServiceLocationType,
        }));
        if (arcGISMap) arcGISMap.toggleMapClickEvent(true);
    }, [serviceCase, serviceAssignment, arcGISMap]);

    const handleServiceLocationSearchQueryChange = searchQueryString => {
        if (!searchQueryString) return;

        if (searchQueryString.toLowerCase() !== currentServiceLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            setIsNewServiceLocationSelected(false);
            searchSAVAServiceLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceCase.id}-${serviceAssignment.lineNo}`,
                ...(!!(residenceLocation && residenceLocation.longitude && residenceLocation.latitude) && {
                    location: [residenceLocation.longitude, residenceLocation.latitude].toString(),
                }),
            });
            lastServiceLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleServiceLocationCoordinatesSelect = serviceLocationCoordinatesString => {
        if (!serviceLocationCoordinatesString) return;

        const coordinatesString = serviceLocationCoordinatesString.trim();
        const latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        if (coordinatesString && latLngRegex.test(coordinatesString)) {
            const [latitude, longitude] = coordinatesString.split(',');
            if (latitude && longitude) {
                searchSAVAReverseServiceLocationGeolocation({
                    serviceAssignmentId: `${serviceCase.id}-${serviceAssignment.lineNo}`,
                    latitude: latitude.trim(),
                    longitude: longitude.trim(),
                });
            }
        }
    };

    const handleServiceLocationCandidateSelect = locationCandidate => {
        currentServiceLocationAddress.current = locationCandidate.formattedAddress;
        setNewServiceLocation(locationCandidate);
        setIsNewServiceLocationSelected(true);
    };

    const handleServiceLocationFormSubmit = useCallback(() => {
        if (!newServiceLocation) return;

        submitSAVAServiceLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                serviceLocation: newServiceLocation,
            },
        });
    }, [
        submitSAVAServiceLocationForm,
        serviceCase.id,
        serviceAssignment.lineNo,
        newServiceLocation,
    ]);

    const handleTabChange = useCallback(formValues => {
        const {locationNotes, ...restFormValues} = formValues;
        const {lineNo} = serviceAssignment;
        submitSAVAServiceLocationInfoForm({
            serviceCase: serviceCase,
            serviceAssignmentLineNo: lineNo,
            serviceAssignmentData: {
                ...restFormValues,
                childrenAge: childrenAge.filter(age => age !== ''),
                ...(serviceAssignment.serviceLocation && {
                    serviceLocation: {
                        ...serviceAssignment.serviceLocation,
                        locationNotes,
                    },
                }),
                // TODO: Hidden feature >> uncomment this when feature is ready
                // vehicleNotAccessible: !!formValues.vehicleNotAccessible,
            },
            serviceCaseData: {
                departureDate: formValues.departureDate,
            },
        });
    }, [submitSAVAServiceLocationInfoForm, serviceCase, serviceAssignment, childrenAge]);

    const updateChildrenAgeData = formValues => {
        const {childrenAtSite, childrenAge: childrenAgeValues} = formValues;
        const currentChildrenAgeValues = childrenAtSite
            ? (Object.keys(childrenAgeValues).length
                ? Object.keys(childrenAgeValues).map(key => childrenAgeValues[key])
                    .slice(0, serviceLocationInfoFormData?.childrenAtSite || serviceCase.childrenAtSite)
                : childrenAge)
            : [];
        if (childrenAtSite > currentChildrenAgeValues.length) {
            setChildrenAge([...currentChildrenAgeValues, '']);
            return;
        }
        if (childrenAtSite < currentChildrenAgeValues.length) {
            const updatedChildrenArray = [...currentChildrenAgeValues];
            updatedChildrenArray.pop();
            setChildrenAge(updatedChildrenArray);
            return;
        }
        setChildrenAge(currentChildrenAgeValues);
    };

    const handleServiceLocationInfoFormChange = formValues => {
        updateChildrenAgeData(formValues);
        setNewServiceLocationInfoFormData(formValues);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        // on tab change, always reset serviceAssignment.reverseGeocodingServiceLocation
        resetSAVAReverseGeocodingServiceLocation({
            serviceAssignmentId: `${serviceCase.id}-${serviceAssignment.lineNo}`,
        });
        // on tab change, always reset serviceAssignment.arcGISNearbyPOIs
        setArcGISNearbyPOIs({
            arcGISLocationCandidateDTOs: [],
            selectedPOIsCategory: '',
        });

        if (!serviceLocationInfoFormData) {
            completeRouteUnmountSideEffect({
                caller: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM,
            });
            return;
        }

        handleTabChange(serviceLocationInfoFormData);
    }, [
        serviceLocationInfoFormData,
        handleTabChange,
        resetSAVAReverseGeocodingServiceLocation,
        serviceCase.id,
        serviceAssignment.lineNo,
        setArcGISNearbyPOIs,
    ]);

    // if no case or an assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const {member} = serviceCase;
    const {residenceLocation} = member;
    const {serviceLocation, serviceLocationCandidates, serviceLocationSearchQuery} = serviceAssignment;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
            isLeftSidebar={true}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.damage_location')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock
                        label={translateTab('scrollable_block_label.fill_details')}
                    >
                        <Form
                            name="serviceLocationForm"
                            onSubmit={handleServiceLocationFormSubmit}
                            isDisabled={serviceAssignment.persistenceState === persistenceStates.PENDING}
                        >
                            {formValues => {
                                const isSubmitButtonDisabled = !(formValues['serviceLocationType'] === efLocationTypes.GEO_COORDINATES
                                    ? formValues['serviceLocationGeoCoordinates'] : formValues['serviceLocationSearchQuery'])
                                    || !isNewServiceLocationSelected
                                    || (newServiceLocation
                                        ? (!newServiceLocation.longitude || !newServiceLocation.latitude) : false);
                                const isServiceLocationSaved = serviceLocation
                                    && serviceLocation.formattedAddress
                                    && (formValues['serviceLocationType'] !== efLocationTypes.GEO_COORDINATES
                                        ? formValues['serviceLocationSearchQuery'] === serviceLocation.formattedAddress
                                        : true)
                                    && formValues['serviceLocationType'] === serviceLocation.locationType
                                    && (newServiceLocation
                                        ? newServiceLocation.longitude === serviceLocation.longitude
                                        && newServiceLocation.latitude === serviceLocation.latitude
                                        : true);

                                return (
                                    <Fragment>
                                        <SelectField
                                            name="serviceLocationType"
                                            value={newServiceLocationType}
                                            onChange={handleServiceLocationTypeChange}
                                            label={translateTab('select_label.damage_location_information')}
                                            placeholder={translateTab('select_placeholder.please_select')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-48',
                                            ])}
                                        >
                                            <Option
                                                name="service-location-type-select-location"
                                                value={efLocationTypes.SELECT_LOCATION}
                                            >
                                                {translateTab('select_option_label.damage_location_known')}
                                            </Option>
                                            <Option
                                                name="service-location-type-residence-place"
                                                value={efLocationTypes.RESIDENCE_PLACE}
                                            >
                                                {translateTab('select_option_label.residence_place')}
                                            </Option>
                                            <Option
                                                name="service-location-type-geo-coordinates"
                                                value={efLocationTypes.GEO_COORDINATES}
                                            >
                                                {translateTab('select_option_label.geo_coordinates')}
                                            </Option>
                                        </SelectField>
                                        {formValues['serviceLocationType'] === efLocationTypes.TRACK_ROUTE && (
                                            <Fragment>
                                                <InputField
                                                    name="routeStart"
                                                    label={translateTab('input_label.start_point')}
                                                    value=""
                                                    placeholder={translateTab('input_placeholder.please_fill_in')}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-48',
                                                    ])}
                                                    isAutoCompleteOff={true}
                                                />
                                                <InputField
                                                    name="routeEnd"
                                                    label={translateTab('input_label.end_point')}
                                                    value=""
                                                    placeholder={translateTab('input_placeholder.please_fill_in')}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-48',
                                                    ])}
                                                    isAutoCompleteOff={true}
                                                />
                                            </Fragment>
                                        )}
                                        {formValues['serviceLocationType'] === efLocationTypes.GEO_COORDINATES && (
                                            <Fragment>
                                                <p
                                                    className={cx([
                                                        'global!ace-u-typography--variant-caption',
                                                        'global!ace-u-margin--bottom-8',
                                                    ])}
                                                >
                                                    {translateTab('input_label.geo_coordinates')}
                                                </p>
                                                <SearchBox
                                                    name="serviceLocationGeoCoordinates"
                                                    placeholder={translateTab('input_placeholder.latitude_longitude')}
                                                    value={newServiceLocation
                                                    && newServiceLocation.latitude
                                                    && newServiceLocation.longitude
                                                        ? `${newServiceLocation.latitude},${newServiceLocation.longitude}` : ''}
                                                    onSearchSubmit={handleServiceLocationCoordinatesSelect}
                                                    isDropDownDisabled={true}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-16',
                                                    ])}
                                                />
                                                {newServiceLocation && newServiceLocation.formattedAddress && (
                                                    <Fragment>
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-flex--align-self-flex-start',
                                                                'global!ace-u-typography--variant-body-bold',
                                                            ])}
                                                        >
                                                            {translateTab('input_label.damage_location')}
                                                        </div>
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-flex--align-self-flex-start',
                                                                'global!ace-u-margin--bottom-24',
                                                            ])}
                                                        >
                                                            {newServiceLocation.formattedAddress}
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        {(formValues['serviceLocationType'] === efLocationTypes.RESIDENCE_PLACE
                                            || formValues['serviceLocationType'] === efLocationTypes.SELECT_LOCATION) && (
                                            <AutosuggestField
                                                name="serviceLocationSearchQuery"
                                                value={newServiceLocation?.formattedAddress || ''}
                                                isDisabled={formValues['serviceLocationType'] !== efLocationTypes.SELECT_LOCATION}
                                                onChange={handleServiceLocationSearchQueryChange}
                                                onOptionSelect={handleServiceLocationCandidateSelect}
                                                optionValueSelector={locationCandidate => {
                                                    return locationCandidate.formattedAddress;
                                                }}
                                                label={translateTab('input_label.damage_location')}
                                                placeholder={translateTab('input_placeholder.please_fill_in')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--bottom-48',
                                                ])}
                                            >
                                                {(formValues['serviceLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                // eslint-disable-next-line max-len
                                                && serviceLocationSearchQuery === lastServiceLocationSearchQuery.current
                                                    ? serviceLocationCandidates
                                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                        .map((locationCandidate, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    name={`service-location-candidate-${index}`}
                                                                    value={locationCandidate}
                                                                >
                                                                    <Icon
                                                                        icon={locationIcon}
                                                                        className={cx('global!ace-u-margin--right-16')}
                                                                    />
                                                                    {locationCandidate.formattedAddress}
                                                                </Option>
                                                            );
                                                        }) : null}
                                            </AutosuggestField>
                                        )}
                                        <ButtonPrimary
                                            name="submitServiceLocation"
                                            type="submit"
                                            isDisabled={isSubmitButtonDisabled}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-24',
                                            ], {
                                                'ace-c-button-primary--is-positive': isServiceLocationSaved,
                                            })}
                                        >
                                            {isServiceLocationSaved
                                                ? (
                                                    <Fragment>
                                                        {translateTab('button_label.service_location_confirmed')}
                                                        <Icon
                                                            icon={checkmarkIcon}
                                                            className={cx(
                                                                'ace-c-icon--color-contrast',
                                                                'global!ace-u-margin--left-16',
                                                            )}
                                                        />
                                                    </Fragment>
                                                ) : translateTab('button_label.confirm_service_location')
                                            }
                                        </ButtonPrimary>
                                        {residenceLocation.formattedAddress && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                    ])}
                                                >
                                                    {translateTab('label.members_place_of_residence')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-24',
                                                    ])}
                                                >
                                                    {residenceLocation.formattedAddress}
                                                </div>
                                                <div className={cx('global!ace-u-margin--bottom-24')}>
                                                    <Pill
                                                        type="information"
                                                        className={cx('global!ace-u-typography--variant-caption')}
                                                    >
                                                        {translateTab('pill_label.residence_to_damage_location_distance')}&nbsp;
                                                        {Math.round(
                                                            serviceAssignment.distanceResidenceToServiceLocation,
                                                        )}
                                                        {translate('global.unit.km')}
                                                    </Pill>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                );
                            }}
                        </Form>
                        <Form
                            name="damageLocationInfoForm"
                            onChange={handleServiceLocationInfoFormChange}
                        >
                            {/* TODO: Hidden feature >> uncomment this when feature is ready */}
                            {/* <Checkbox */}
                            {/*    name="vehicleNotAccessible" */}
                            {/*    value={true} */}
                            {/*    isSelected={serviceAssignment.vehicleNotAccessible} */}
                            {/*    className={cx([ */}
                            {/*        'global!ace-u-flex--align-self-flex-start', */}
                            {/*        'global!ace-u-margin--bottom-24', */}
                            {/*    ])} */}
                            {/* > */}
                            {/*    {translateTab('checkbox_label.vehicle_not_accessible')} */}
                            {/* </Checkbox> */}
                            <InputPhoneNumberField
                                name="callbackPhoneNo"
                                label={translateTab('input_label.callback_phone_no')}
                                value={serviceAssignment.callbackPhoneNo || ''}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            />
                            <TextAreaField
                                name="locationNotes"
                                label={translateTab('text_area_label.location_notes')}
                                placeholder={translateTab('text_area_placeholder.location_notes')}
                                value={serviceLocation ? serviceLocation?.locationNotes : ''}
                                isResizable={false}
                                isDisabled={!serviceAssignment.serviceLocation}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            />
                            <div
                                className={cx('global!ace-u-flex--align-self-flex-start')}
                            >
                                {translateTab('text.present_place')}
                            </div>
                            <InputCounter
                                name="grownUpsAtSite"
                                label={translateTab('input_counter_label.adults')}
                                value={serviceAssignment.grownUpsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider />
                            <InputCounter
                                name="childrenAtSite"
                                label={translateTab('input_counter_label.children')}
                                value={serviceAssignment.childrenAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            {!childrenAge.length && (
                                <Divider />
                            )}
                            <Form name="childrenAge">
                                <div
                                    className={cx('global!ace-u-grid', {
                                        'global!ace-u-padding--16-0': childrenAge.length,
                                    })}
                                >
                                    {childrenAge.map((age, idx) => (
                                        <SelectField
                                            key={idx}
                                            name={`childrenAge${idx}`}
                                            label={translateTab('input_label.children_age')}
                                            value={age}
                                            className={cx('global!ace-u-grid-column--span-6')}
                                        >
                                            {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                <Option
                                                    key={idx}
                                                    name={`children-age-option-${idx}`}
                                                    value={idx}
                                                >
                                                    {efChildrenAgeTypes[ageOption]}
                                                </Option>
                                            ))}
                                        </SelectField>
                                    ))}
                                </div>
                                {!!childrenAge.length && (
                                    <Divider />
                                )}
                            </Form>
                            <InputCounter
                                name="petsAtSite"
                                label={translateTab('input_counter_label.animals')}
                                value={serviceAssignment.petsAtSite}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'ace-c-input-counter--list-element',
                                ])}
                            />
                            <Divider className={cx('global!ace-u-margin--bottom-24')} />
                            <DateField
                                name="departureDate"
                                label={translateTab('date_field_label.planned_departure')}
                                value={serviceCase.departureDate}
                                placeholder={translateTab('date_field_placeholder.please_enter')}
                                icon={calendarIcon}
                                locale={activeLocale}
                                format="DD.MM.YYYY"
                                className={cx('global!ace-u-full-width')}
                            />
                        </Form>
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAVAServiceLocationTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchSAVAServiceLocationGeolocation: PropTypes.func.isRequired,
    submitSAVAServiceLocationForm: PropTypes.func.isRequired,
    submitSAVAServiceLocationInfoForm: PropTypes.func.isRequired,
    searchSAVAReverseServiceLocationGeolocation: PropTypes.func.isRequired,
    resetSAVAReverseGeocodingServiceLocation: PropTypes.func.isRequired,
    arcGISNearbyPOIs: PropTypes.array,
    setArcGISNearbyPOIs: PropTypes.func.isRequired,
    searchArcGISNearbyPOIs: PropTypes.func.isRequired,
    selectedPOIsCategory: PropTypes.string,
};

SAVAServiceLocationTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    arcGISNearbyPOIs: [],
    selectedPOIsCategory: '',
};

const mapStateToProps = state => {
    return {
        arcGISNearbyPOIs: state.serviceAssignments.arcGISNearbyPOIs,
        selectedPOIsCategory: state.serviceAssignments.selectedPOIsCategory,
    };
};

const mapDispatchToProps = dispatch => ({
    searchSAVAServiceLocationGeolocation: payload => dispatch({
        type: savaActionTypes.SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION,
        payload,
    }),
    searchSAVAReverseServiceLocationGeolocation: payload => dispatch({
        type: savaActionTypes.SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSAVAServiceLocationForm: payload => dispatch({
        type: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_FORM,
        payload,
    }),
    submitSAVAServiceLocationInfoForm: payload => dispatch({
        type: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM,
        payload,
    }),
    resetSAVAReverseGeocodingServiceLocation: payload => dispatch({
        type: savaActionTypes.RESET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION,
        payload,
    }),
    setArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
        payload,
    }),
    searchArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVAServiceLocationTab));
