import {fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

const selectMemberAddressGeolocation = function* selectMemberAddressGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const datafactoryService = serviceManager.loadService('datafactoryService');

    const {uuid} = payload;
    yield fork(
        fetchRequest,
        memberActionTypes.SELECT_MEMBER_ADDRESS_GEOLOCATION,
        datafactoryService.selectAddressSuggestion,
        {uuid},
    );
};

export default selectMemberAddressGeolocation;
