import {take, all, call, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savaActionTypes from '../savaActionTypes';

const selectSAVATowingDestinationFlow = function* selectSAVATowingDestinationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_TOWING_DESTINATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, isUpdateNeeded = true} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');
        const savaTowingDestinationsLayer = yield call(arcGISMap.getLayer, 'sava-towing-destinations');
        const savaTowingDestinationRoutesLayer = yield call(arcGISMap.getLayer, 'sava-towing-destination-routes');

        if (savaTowingDestinationsLayer && savaTowingDestinationRoutesLayer) {
            const {towingDestination} = serviceAssignmentData;

            yield all([
                call(savaTowingDestinationsLayer.selectFeatureByAttribute, {
                    where: `locationId = '${towingDestination?.id}'`,
                }),
                call(savaTowingDestinationRoutesLayer.selectFeatureByAttribute, {
                    where: `locationId = '${towingDestination?.id}'`,
                }),
            ]);
        }

        if (isUpdateNeeded) {
            yield* updateServiceAssignment({
                caller: savaActionTypes.SUBMIT_SAVA_TOWING_DESTINATION_FORM,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
        }
    }
};

export default selectSAVATowingDestinationFlow;
