import {take, fork, put, select} from 'redux-saga/effects';
import {Member, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Update member flow
 */
const updateMemberFlow = function* updateMemberFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take(memberActionTypes.SUBMIT_UPDATE_MEMBER_FORM);
        const {memberId, memberData} = payload;

        yield fork(
            fetchRequest,
            memberActionTypes.UPDATE_MEMBER_REQUEST,
            membersService.updateMember,
            {
                memberId,
                memberDTO: Member.objectToPatchDTO(memberData),
            },
        );
        const responseAction = yield take([
            memberActionTypes.UPDATE_MEMBER_REQUEST_SUCCEEDED,
            memberActionTypes.UPDATE_MEMBER_REQUEST_FAILED,
        ]);

        // NOTE: errors are handled implicitly as part of the fetchRequest
        // below you can handle something in addition if needed

        yield put({
            type: memberActionTypes.SET_MEMBER_PERSISTENCE_STATE,
            payload: {
                memberId,
                persistenceState: responseAction.error
                    ? persistenceStates.FAILED : persistenceStates.SUCCEEDED,
            },
        });

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {memberDTO} = response;

            yield put({
                type: memberActionTypes.STORE_MEMBER,
                payload: {memberDTO},
            });

            yield put({
                type: memberActionTypes.SET_MEMBER_PERSISTENCE_STATE,
                payload: {memberId, persistenceState: persistenceStates.READY},
            });
        }
    }
};

export default updateMemberFlow;
