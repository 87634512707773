import {useSelector} from 'react-redux';

const useServiceCaseLockedStatus = serviceCase => {
    const currentUser = useSelector(state => state.user.user);
    const allUsers = useSelector(state => state.user.users);
    // if no service case or current user, return true (locked state)
    if (!serviceCase || !currentUser) return true;

    const isServiceCaseLocked = !!serviceCase.lock && serviceCase.lock.lockedBy !== currentUser.id;
    if (!isServiceCaseLocked) {
        return {
            isServiceCaseLocked,
            lockedBy: null,
        };
    }

    const lockedBy = allUsers[serviceCase.lock.lockedBy]
        ? `${allUsers[serviceCase.lock.lockedBy].firstName} ${allUsers[serviceCase.lock.lockedBy].lastName}`
        : '';

    return {
        isServiceCaseLocked,
        lockedBy,
    };
};

export default useServiceCaseLockedStatus;
