import {put, take, fork, select} from 'redux-saga/effects';
import {replace, resolveRoute, END_SIDE_EFFECTS_RUNNING} from '@computerrock/formation-router';
import {openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as selectors from '../userProfileSelectors';
import ecsAccessControl from '../../ecsAccessControl';
import modalIds from '../../modalIds';

const loadUserProfile = function* loadUserProfile() {
    const userProfile = yield select(selectors.getUser);
    if (userProfile) return;

    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    yield fork(
        fetchRequest,
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST,
        userProfileService.getUserProfile,
    );
    const fetchUserResultAction = yield take([
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED,
    ]);

    if (fetchUserResultAction.type === userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED) {
        yield put(replace(routePaths.SYSTEM_ACCESS_DENIED));
        return END_SIDE_EFFECTS_RUNNING;
    }

    const {response: fetchUserResponse} = fetchUserResultAction.payload;
    const {userDTO} = fetchUserResponse;
    yield put({
        type: userProfileActionTypes.STORE_USER_PROFILE,
        payload: {userDTO},
    });

    // Pass user information to plugin
    ecsAccessControl.setActiveUser(userDTO);

    if (userDTO?.shiftRoleInfo?.currentShiftRole) {
        ecsAccessControl.setActiveShiftRole(userDTO.shiftRoleInfo.currentShiftRole);
        // set role in the store as well
        yield put({
            type: userProfileActionTypes.SET_USER_SHIFT_ROLE,
            payload: {shiftRole: userDTO.shiftRoleInfo.currentShiftRole},
        });
    }

    yield fork(
        fetchRequest,
        userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST,
        userProfileService.getUserShiftRoles,
        {
            userId: userDTO.id,
        },
    );
    const fetchUserShiftRolesAction = yield take([
        userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST_FAILED,
    ]);

    if (fetchUserShiftRolesAction.type === userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST_FAILED) {
        // if fetching of shift roles was not successful, notify the user and continue
        // since an agent is allowed to use the app without the shift role
        const {response} = fetchUserShiftRolesAction.payload;
        yield* openModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {
            errorCode: response?.status ? `${response.status}` : '',
            requestedAction: userProfileActionTypes.FETCH_USER_SHIFT_ROLES_REQUEST.toLowerCase(),
        });
        return;
    }

    const {response: fetchUserShiftRolesResponse} = fetchUserShiftRolesAction.payload;
    const {allowedShiftRoles} = fetchUserShiftRolesResponse;
    yield put({
        type: userProfileActionTypes.STORE_USER_SHIFT_ROLES,
        payload: {allowedShiftRoles},
    });

    // Note: Handle account with one role and if,
    // its value of fetched user profile returns property of userDTO object with --> shiftRole: null
    if (!userDTO?.shiftRoleInfo?.shiftRole && allowedShiftRoles && allowedShiftRoles.length === 1) {
        yield fork(
            fetchRequest,
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST,
            userProfileService.setUserShiftRole,
            {shiftRole: allowedShiftRoles[0]},
        );
        const setUserShiftRoleAction = yield take([
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_SUCCEEDED,
            userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED,
        ]);

        if (setUserShiftRoleAction.type === userProfileActionTypes.UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED) {
            const {response} = setUserShiftRoleAction.payload;
            yield put({
                type: userProfileActionTypes.INITIATE_SHIFT_ROLE_UPDATE_ERROR_FLOW,
                payload: {shiftRole: allowedShiftRoles[0], status: response?.status || ''},
            });
            return;
        }

        if (!setUserShiftRoleAction.error) {
            const {response} = setUserShiftRoleAction.payload;
            const {shiftRole} = response;
            // pass shift role information to plugin
            ecsAccessControl.setActiveShiftRole(shiftRole);

            yield put({
                type: userProfileActionTypes.SET_USER_SHIFT_ROLE,
                payload: {shiftRole},
            });
        }
    }

    // if user doesn't have shiftRole set, redirect to shift role
    // selection screen and prevent other loaders from executing
    // prevent redirection user with one allowedShiftRole
    if (!userDTO?.shiftRoleInfo?.currentShiftRole && allowedShiftRoles?.length > 1) {
        yield put(replace(resolveRoute(routePaths.SHIFT_ROLE_SELECTION)));
        return END_SIDE_EFFECTS_RUNNING;
    }
};

export default loadUserProfile;
