import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageCostCoveragePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_cost_coverage_tab');
    const {serviceAssignment, assignmentText, onChange} = props;

    return (
        <Panel
            title={translateTab('panel_title.cost_coverage')}
            qaIdent="sarc-cost-coverage-cost-coverage"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-cost-coverage-description"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={assignmentText}
                    onChange={value => onChange('assignmentText', value)}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.pick_up_station')}
                qaIdent="sa-rental-car-station-pickup"
            >
                {[
                    serviceAssignment.rentalCarStationPickup?.name,
                    serviceAssignment.rentalCarStationPickup?.location?.city,
                ].filter(value => !!value).join(' ') || '-'}
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.drop_off_station')}
                qaIdent="sa-rental-car-station-drop-off"
            >
                {[
                    serviceAssignment.rentalCarStationDropOff?.name,
                    serviceAssignment.rentalCarStationDropOff?.location?.city,
                ].filter(value => !!value).join(' ') || '-'}
            </DataRow>
        </Panel>
    );
};

CostCoverageCostCoveragePanel.propTypes = {
    serviceAssignment: PropTypes.object,
    assignmentText: PropTypes.string,
    onChange: PropTypes.func,
};

CostCoverageCostCoveragePanel.defaultProps = {
    serviceAssignment: null,
    assignmentText: '',
    onChange: () => {},
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageCostCoveragePanel));
