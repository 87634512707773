import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';

/**
 * Update SAV vehicle damage notes flow
 */
const updateSAVVehicleDamageNotes = function* updateSAVVehicleDamageNotes() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_VEHICLE_DAMAGE_NOTES);
        const {serviceCaseData, serviceCaseId} = payload;

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_VEHICLE_DAMAGE_NOTES,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_VEHICLE_DAMAGE_NOTES},
        });
    }
};

export default updateSAVVehicleDamageNotes;
