import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const searchServiceCasesByVehicleId = function* searchServiceCasesByVehicleId({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const efService = serviceManager.loadService('ecsFlowService');
    const {vehicleId} = payload;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST,
        efService.getServiceCaseIdsForVehicles,
        {vehicleIds: [vehicleId]},
    );

    const caseIdsResponseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED,
    ]);

    if (caseIdsResponseAction.error) return;

    const caseIdsResponse = caseIdsResponseAction.payload.response;
    const serviceCaseCountForVehicles = caseIdsResponse?.serviceCaseCountForVehicles || [];

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST,
        efService.searchServiceCasesByCaseIds,
        {caseIds: serviceCaseCountForVehicles[0]?.caseIds || []},
    );

    const serviceCasesResponseAction = yield take([
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_FAILED,
    ]);

    if (serviceCasesResponseAction.error) return;

    const {response} = serviceCasesResponseAction.payload;
    const {results: serviceCaseSearchResults, totalCount: serviceCaseSearchResultsCount} = response;

    yield put({
        type: serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
        payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
    });
};

export default searchServiceCasesByVehicleId;
