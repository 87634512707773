import {select, fork, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

const deleteMemberVehicleFlow = function* deleteMemberVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take([memberActionTypes.INITIATE_VEHICLE_DELETE_FLOW]);
        const {vehicleId} = payload;

        yield* openModal(modalIds.DELETE_MEMBER_VEHICLE);
        const chosenModalOption = yield take([
            memberActionTypes.CONFIRM_DELETE_MEMBER_VEHICLE,
            memberActionTypes.DECLINE_DELETE_MEMBER_VEHICLE,
        ]);

        if (chosenModalOption && chosenModalOption.type === memberActionTypes.CONFIRM_DELETE_MEMBER_VEHICLE) {
            yield fork(
                fetchRequest,
                memberActionTypes.DELETE_MEMBER_VEHICLE_REQUEST,
                membersService.deleteMemberVehicle,
                {vehicleId},
            );

            const responseAction = yield take([
                memberActionTypes.DELETE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
                memberActionTypes.DELETE_MEMBER_VEHICLE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                yield put({
                    type: memberActionTypes.REMOVE_MEMBER_VEHICLE,
                    payload: {vehicleId},
                });
            }
        }

        yield* closeModal(modalIds.DELETE_MEMBER_VEHICLE);
    }
};

export default deleteMemberVehicleFlow;
