import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Modal, ButtonSecondary, ButtonPrimary} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

const CloseArchiveServiceAssignmentModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, declineCloseArchiveServiceAssignment, confirmCloseArchiveServiceAssignment} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('close_archive_service_assignment_modal');

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            title={translateModal('title.danger')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <p className={cx('global!ace-u-margin--top-48')}>{translateModal('body_text.archive_delete_assignment')}</p>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-margin--top-32',
                    ])}
                >
                    <ButtonPrimary
                        name="closeArchiveAssignmentButton"
                        onClick={confirmCloseArchiveServiceAssignment}
                        className={cx('global!ace-u-margin--right-32')}
                    >
                        {translateModal('button_label.yes')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        name="closeModalButton"
                        onClick={declineCloseArchiveServiceAssignment}
                    >
                        {translateModal('button_label.no')}
                    </ButtonSecondary>
                </div>
            </div>
        </Modal>
    );
};

CloseArchiveServiceAssignmentModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineCloseArchiveServiceAssignment: PropTypes.func.isRequired,
    confirmCloseArchiveServiceAssignment: PropTypes.func.isRequired,
};

CloseArchiveServiceAssignmentModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineCloseArchiveServiceAssignment: () => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
    }),
    confirmCloseArchiveServiceAssignment: () => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
    }),
});

export default connect(null, mapDispatchToProps)(CloseArchiveServiceAssignmentModal);
