import {select, fork, take, put} from 'redux-saga/effects';
import {efLocationTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import config from '../../config';

/**
 * Search reverse damage location geolocation
 */
const searchSAVReverseDamageLocationGeolocation = function* searchSAVReverseDamageLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const {serviceCaseId, latitude, longitude} = payload;

    yield fork(
        fetchRequest,
        savActionTypes.SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.getLocationAddress,
        {
            longitude,
            latitude,
            langCode: config.ARCGIS_DEFAULT_LANG_CODE,
        },
    );

    const searchReverseDamageLocationGeolocation = yield take([
        savActionTypes.SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        savActionTypes.SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchReverseDamageLocationGeolocation.error) {
        const {response} = searchReverseDamageLocationGeolocation.payload;
        const {arcGISReverseGeocodingLocationDTO} = response;

        yield put({
            type: serviceCaseActionTypes.SET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION,
            payload: {
                arcGISReverseGeocodingLocationDTO,
                serviceCaseId,
                locationType: efLocationTypes.GEO_COORDINATES,
            },
        });
    }
};

export default searchSAVReverseDamageLocationGeolocation;
