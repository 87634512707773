import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useRouter} from '@computerrock/formation-router';
import {Modal, HighlightCircle, useStyles, ButtonSecondary, ButtonPrimary, ContentItem} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, warningAlertOutlinedIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';
import determineActionTypes from '../determineActionTypes';

const FailedServiceErrorMessageModal = ({location, hasBackdrop, confirmSendingAgain, declineSendingAgain}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const {closeModal} = useRouter();
    const translateModal = createTranslateShorthand('failed_service_error_message_modal');
    const errorCode = location?.query?.errorCode;
    const requestedAction = location?.query?.requestedAction;
    const actionTypes = determineActionTypes(requestedAction);

    return (
        <Modal
            contentClassName={cx('global!ace-u-modal-content-size--xs')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => (actionTypes?.decline
                        ? declineSendingAgain(actionTypes.decline)
                        : closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''}))
                    }
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--negative',
                    ])}
                >
                    <Icon
                        icon={warningAlertOutlinedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                </HighlightCircle>
                <h3 className={cx('global!ace-u-margin--bottom-16')}>
                    {translateModal(`headline_text.${actionTypes.name}`)}
                </h3>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translateModal(`body_text.${actionTypes.name}`, {errorCode: errorCode || ''})}
                </p>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    {!!actionTypes?.confirm && (
                        <ButtonPrimary
                            className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                            onClick={() => confirmSendingAgain(actionTypes.confirm)}
                        >
                            {translateModal('button.try_again')}
                        </ButtonPrimary>
                    )}
                    <ButtonSecondary
                        className={cx('global!ace-u-full-width')}
                        onClick={() => (actionTypes?.decline
                            ? declineSendingAgain(actionTypes.decline)
                            : closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''}))
                        }
                    >
                        {translateModal('button.cancel')}
                    </ButtonSecondary>
                </ContentItem>
            </div>
        </Modal>
    );
};

FailedServiceErrorMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmSendingAgain: PropTypes.func.isRequired,
    declineSendingAgain: PropTypes.func.isRequired,
    location: PropTypes.object,
};

FailedServiceErrorMessageModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapDispatchToProps = dispatch => ({
    confirmSendingAgain: type => dispatch({type, payload: null}),
    declineSendingAgain: type => dispatch({type, payload: null}),
});

export default connect(null, mapDispatchToProps)(FailedServiceErrorMessageModal);
