import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {apmVehicleStorageServiceTypes, apmContractPartnerTransportOptions} from '@ace-de/eua-entity-types';
import {Icon, Panel, useStyles} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {closeIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import getPrice from '../../utils/getPrice';

const vehicleTransportsDistances = [0, 50, 100, 200];

const ContractPartnerTransportVehicleStorage = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_transport_vehicle_storage_panel');
    const {contractPartner} = props;
    const data = {
        distanceThresholdWithPassengers: contractPartner.pickup?.distanceThresholdWithPassengers || 0,
        distanceThresholdWithoutPassengers: contractPartner.pickup?.distanceThresholdWithoutPassengers || 0,
        vehicleStorage: contractPartner.vehicleStorage?.vehicleStorage.map(obj => obj.type) || [],
        openAirSecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.OPEN_AIR_SECURED)?.price || '',
        openAirUnsecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.OPEN_AIR_UNSECURED)?.price || '',
        carPortSecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.CAR_PORT_SECURED)?.price || '',
        carPortUnsecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.CAR_PORT_UNSECURED)?.price || '',
        hallPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.HALL)?.price || '',
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel>
            <div className={cx('global!ace-u-two-column-layout')}>
                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                    <div className={cx('global!ace-u-margin--bottom-32')}>
                        <h2>{translateTab('panel_title.address_data')}</h2>
                    </div>
                    <Table className={cx('global!ace-u-margin--bottom-48')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translateTab('transport_table_cell.vehicle_transports')}</TableCell>
                                <TableCell>{translateTab('transport_table_cell.no_service')}</TableCell>
                                <TableCell>{translateTab('transport_table_cell.50km')}</TableCell>
                                <TableCell>{translateTab('transport_table_cell.about_100km')}</TableCell>
                                <TableCell>{translateTab('transport_table_cell.about_200km')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{translateTab('transport_table_cell.with_passengers')}</TableCell>
                                {vehicleTransportsDistances
                                    .map((vehicleTransportsDistance, id) => {
                                        const distance = data?.distanceThresholdWithPassengers;
                                        return (
                                            <TableCell key={vehicleTransportsDistance}>
                                                <Icon
                                                    icon={vehicleTransportsDistance !== 0
                                                        ? distance >= vehicleTransportsDistance
                                                            ? checkmarkIcon : closeIcon
                                                        : distance === 0
                                                            ? checkmarkIcon
                                                            : closeIcon}
                                                    className={cx(
                                                        vehicleTransportsDistance !== 0
                                                            ? distance >= vehicleTransportsDistance
                                                                ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                            : distance === 0
                                                                ? 'ace-c-icon--color-success'
                                                                : 'ace-c-icon--color-disabled',
                                                    )}
                                                />
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                            <TableRow>
                                <TableCell>{translateTab('transport_table_cell.without_passengers')}</TableCell>
                                {vehicleTransportsDistances
                                    .map((vehicleTransportsDistance, id) => {
                                        const distance = data?.distanceThresholdWithoutPassengers;
                                        return (
                                            <TableCell key={vehicleTransportsDistance}>
                                                <Icon
                                                    icon={vehicleTransportsDistance !== 0
                                                        ? distance >= vehicleTransportsDistance
                                                            ? checkmarkIcon : closeIcon
                                                        : distance === 0
                                                            ? checkmarkIcon
                                                            : closeIcon}
                                                    className={cx(
                                                        vehicleTransportsDistance !== 0
                                                            ? distance >= vehicleTransportsDistance
                                                                ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                            : distance === 0
                                                                ? 'ace-c-icon--color-success'
                                                                : 'ace-c-icon--color-disabled',
                                                    )}
                                                />
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    {translateTab('transport_table_cell.general')}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(apmContractPartnerTransportOptions)
                                .map(transportOption => {
                                    const hasCPTransportOption = contractPartner.transportOptions
                                        && contractPartner.transportOptions.includes(transportOption);
                                    return (
                                        <TableRow key={transportOption}>
                                            <TableCell colSpan={4}>
                                                {translateTab(`transport_table_cell.${transportOption.toLowerCase()}`)}
                                            </TableCell>
                                            <TableCell>
                                                <Icon
                                                    icon={hasCPTransportOption
                                                        ? checkmarkIcon : closeIcon}
                                                    className={cx(hasCPTransportOption
                                                        ? 'ace-c-icon--color-success'
                                                        : 'ace-c-icon--color-disabled')}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <div
                    className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}
                >
                    <div
                        className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-space-between', 'global!ace-u-margin--bottom-32'])}
                    >
                        <h2>{translateTab('panel_title.vehicle_storage')}</h2>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    {translateTab('transport_table_cell.kind')}
                                </TableCell>
                                <TableCell colSpan={2} />
                                <TableCell colSpan={4}>
                                    {translateTab('transport_table_cell.price')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(apmVehicleStorageServiceTypes).map(vehicleStorageType => {
                                const hasCPServicesVehicleStorageType = data.vehicleStorage
                                    && data.vehicleStorage.some(obj => obj === vehicleStorageType);
                                return (
                                    <TableRow key={vehicleStorageType}>
                                        <TableCell colSpan={6}>
                                            {translateTab(`transport_table_cell.${(vehicleStorageType).toLowerCase()}`)}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Icon
                                                icon={hasCPServicesVehicleStorageType
                                                    ? checkmarkIcon
                                                    : closeIcon}
                                                className={cx(hasCPServicesVehicleStorageType
                                                    ? 'ace-c-icon--color-success'
                                                    : 'ace-c-icon--color-disabled')}
                                            />
                                        </TableCell>
                                        <TableCell colSpan={4}>
                                            {getPrice(parseFloat(data[`${camelCase(vehicleStorageType)}Price`]), activeLocale)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Panel>
    );
};

ContractPartnerTransportVehicleStorage.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerTransportVehicleStorage.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerTransportVehicleStorage));
