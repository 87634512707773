import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, ContentBlock, ContentItem, Option, DateField} from '@ace-de/ui-components';
import {Form, InputField, SelectField} from '@ace-de/ui-components/form';
import {calendarIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as samcActionTypes from './samcActionTypes';
import ContactPersonDetailsPanel from '../service-assignments/ui-elements/ContactPersonDetailsPanel';
import mapMemberDataToContactPersonData from '../service-cases/mapMemberDataToContactPersonData';
import config from '../config';

const isObjectWithKeys = obj => (typeof obj === 'object' && !!Object.keys(obj).length);

const SAMCProblemDamageTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('samc_problem_damage_tab');
    const {serviceCase, serviceAssignment, submitSAMCProblemDamageForm} = props;
    const [formData, setFormData] = useState({
        // set initial form state in order to patch default values if an agent does not touch the form
        contactPersonOnSite: serviceAssignment?.contactPersonOnSite || {
            ...mapMemberDataToContactPersonData(serviceCase.member),
            isIdenticalToMember: false,
        },
        affectedPerson: serviceAssignment?.affectedPerson || mapMemberDataToContactPersonData(serviceCase.member),
        contactPersonAtHome: serviceAssignment?.contactPersonAtHome
            || mapMemberDataToContactPersonData(serviceCase.member),
    });

    const handleOnChange = formValues => {
        setFormData({
            ...formValues,
            ...(isObjectWithKeys(formValues['contactPersonOnSite']) && {
                contactPersonOnSite: {
                    ...(formValues['contactPersonOnSite']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['contactPersonOnSite']),
                    isIdenticalToMember: !!formValues['contactPersonOnSite']['isIdenticalToMember'],
                },
            }),
            ...(isObjectWithKeys(formValues['affectedPerson']) && {
                affectedPerson: {
                    ...(formValues['affectedPerson']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['affectedPerson']),
                    isIdenticalToMember: !!formValues['affectedPerson']['isIdenticalToMember'],
                },
            }),
            ...(isObjectWithKeys(formValues['contactPersonAtHome']) && {
                contactPersonAtHome: {
                    ...(formValues['contactPersonAtHome']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['contactPersonAtHome']),
                    isIdenticalToMember: !!formValues['contactPersonAtHome']['isIdenticalToMember'],
                },
            }),
        });
    };

    const handleOnTabChange = useCallback(formValues => {
        const {contactPersonOnSite, affectedPerson, contactPersonAtHome, diagnoseAndInsuranceData} = formValues;
        submitSAMCProblemDamageForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                ...(isObjectWithKeys(contactPersonOnSite)
                    ? {contactPersonOnSite}
                    : (!serviceAssignment.contactPersonOnSite
                        ? {contactPersonOnSite: {
                            ...mapMemberDataToContactPersonData(serviceCase.member),
                            isIdenticalToMember: false,
                        }} : {})
                ),
                ...(isObjectWithKeys(affectedPerson)
                    ? {affectedPerson}
                    : (!serviceAssignment.affectedPerson
                        ? {affectedPerson: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(contactPersonAtHome)
                    ? {contactPersonAtHome}
                    : (!serviceAssignment.contactPersonAtHome
                        ? {contactPersonAtHome: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(diagnoseAndInsuranceData) && diagnoseAndInsuranceData),
            },
        });
    }, [
        serviceCase.id,
        serviceCase.member,
        serviceAssignment.lineNo,
        serviceAssignment.contactPersonOnSite,
        serviceAssignment.affectedPerson,
        serviceAssignment.contactPersonAtHome,
        submitSAMCProblemDamageForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: samcActionTypes.SUBMIT_SAMC_PROBLEM_DAMAGE_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="contactPersonsForm" onChange={handleOnChange}>
                    <ContactPersonDetailsPanel
                        title={translateTab('panel_title.local_contact')}
                        formName="contactPersonOnSite"
                        contactPerson={serviceAssignment.contactPersonOnSite || serviceCase.member}
                    />
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.affected')}
                            formName="affectedPerson"
                            isIdenticalToMember={true}
                            hasDate={true}
                            contactPerson={serviceAssignment.affectedPerson}
                        />
                    </div>
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.contact_person_at_home')}
                            formName="contactPersonAtHome"
                            isIdenticalToMember={true}
                            contactPerson={serviceAssignment.contactPersonAtHome}
                        />
                    </div>
                    <Panel title={translateTab('panel_title.diagnose_insurance_and_data')}>
                        <Form name="diagnoseAndInsuranceData">
                            <div className={cx('global!ace-u-grid')}>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-6')}
                                    name="diagnoseDescription"
                                    value={serviceAssignment.diagnoseDescription}
                                    label={translateTab('input_label.diagnose_description')}
                                    maxLength={config.MAXIMUM_DIAGNOSE_DESCRIPTION_LENGTH}
                                />
                                <SelectField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="isForeignInsuranceGiven"
                                    value={typeof serviceAssignment.isForeignInsuranceGiven === 'boolean'
                                        ? serviceAssignment.isForeignInsuranceGiven : ''}
                                    label={translateTab('input_label.foreign_health_insurance_available')}
                                    placeholder={translateTab('input_placeholder.please_select')}
                                >
                                    <Option
                                        name="foreignInsuranceIsGivenOption"
                                        value={true}
                                    >
                                        {translateTab('option_label.yes')}
                                    </Option>
                                    <Option
                                        name="foreignInsuranceIsNotGivenOption"
                                        value={false}
                                    >
                                        {translateTab('option_label.no')}
                                    </Option>
                                </SelectField>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="insurance"
                                    value={serviceAssignment.insurance}
                                    label={translateTab('input_label.insurance')}
                                />
                                <DateField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="hospitalEntryDate"
                                    label={translateTab('date_field_label.delivery_to_clinic_date')}
                                    value={serviceAssignment.hospitalEntryDate}
                                    placeholder={translateTab('date_field_placeholder.please_enter')}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                />
                            </div>
                        </Form>
                    </Panel>
                </Form>
            </ContentItem>
        </ContentBlock>
    );
};

SAMCProblemDamageTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSAMCProblemDamageForm: PropTypes.func.isRequired,
};

SAMCProblemDamageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAMCProblemDamageForm: payload => dispatch({
        type: samcActionTypes.SUBMIT_SAMC_PROBLEM_DAMAGE_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMCProblemDamageTab));
