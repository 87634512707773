import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar} from '@ace-de/ui-components/app-layout';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import * as memberSelectors from './memberSelectors';
import FallbackSystemNotificationBar from '../application/ui-elements/FallbackSystemNotificationBar';
import MemberHeader from './ui-elements/MemberHeader';
import MemberServiceCasesPanel from './ui-elements/MemberServiceCasesPanel';
import MemberDataCorporatePanel from './ui-elements/MemberDataCorporatePanel';
import MemberDataPrivatePanel from './ui-elements/MemberDataPrivatePanel';
import MemberVehiclesCorporatePanel from './ui-elements/MemberVehiclesCorporatePanel';
import MemberVehiclesPrivatePanel from './ui-elements/MemberVehiclesPrivatePanel';
import CreateServiceCasePanel from './ui-elements/CreateServiceCasePanel';
import MemberNotesPanel from './ui-elements/MemberNotesPanel';

const MemberScreen = ({member}) => {
    const {cx} = useStyles();

    // if no member don't render
    if (!member) return null;

    return (
        <Fragment>
            <FallbackSystemNotificationBar />
            <HeaderBar className={cx('ace-c-header-bar--primary-navigation')}>
                <MemberHeader member={member} />
            </HeaderBar>
            <AppLayoutMain>
                <ContentBlock>
                    <ContentItem className={cx('ace-c-content-item--span-9')}>
                        {member.membershipType === ampMembershipTypes.CORPORATE && (
                            <Fragment>
                                <MemberDataCorporatePanel />
                                <MemberServiceCasesPanel />
                                <MemberVehiclesCorporatePanel />
                            </Fragment>
                        )}

                        {member.membershipType === ampMembershipTypes.PRIVATE && (
                            <Fragment>
                                <MemberDataPrivatePanel />
                                <MemberServiceCasesPanel />
                                <MemberVehiclesPrivatePanel />
                            </Fragment>
                        )}
                    </ContentItem>

                    <ContentItem className={cx('ace-c-content-item--span-3')}>
                        <CreateServiceCasePanel />
                        <MemberNotesPanel />
                    </ContentItem>
                </ContentBlock>
            </AppLayoutMain>
        </Fragment>
    );
};

MemberScreen.propTypes = {
    member: PropTypes.object,
};

MemberScreen.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(MemberScreen);
