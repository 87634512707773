import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as samsActionTypes from '../samsActionTypes';

/**
 * Update SAMS service provider flow
 */
const updateSAMSServiceProviderFlow = function* updateSAMSServiceProviderFlow() {
    while (true) {
        const {payload} = yield take(samsActionTypes.SUBMIT_SAMS_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: samsActionTypes.SUBMIT_SAMS_SERVICE_PROVIDER_FORM,
            assignmentType: efServiceAssignmentTypes.MEDICINE_SHIPMENT,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: samsActionTypes.SUBMIT_SAMS_SERVICE_PROVIDER_FORM},
        });
    }
};

export default updateSAMSServiceProviderFlow;
