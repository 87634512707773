import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Badge, TextAreaField, Button} from '@ace-de/ui-components';
import {DropDownTrigger, PopOver, withDropDownProvider} from '@ace-de/ui-components/overlays';
import MemberNotePopupItem from './MemberNotePopupItem';
import * as memberSelectors from '../../members/memberSelectors';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as memberActionTypes from '../../members/memberActionTypes';
import config from '../../config';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const MemberNotePopup = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('service_case_header');
    const {members, users, member, submitCreateMemberNoteForm} = props;
    const memberNotes = members[member?.membershipNo]?.memberNotes;
    const filteredMemberNotes = memberNotes ? memberNotes.filter(note => !note.deleted) : [];
    const [memberNoteContent, setMemberNoteContent] = useState('');
    const isUserAllowedToCreateNotes = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_NOTE,
        ecsFeatureActions.CREATE,
    );

    if (!memberNotes) return null;

    const handleOnSubmit = () => {
        if (memberNoteContent) {
            submitCreateMemberNoteForm({
                memberId: member.id,
                content: memberNoteContent,
            });
            setMemberNoteContent('');
        }
    };

    return (
        <DropDownTrigger>
            <div className={cx('global!ace-u-cursor--pointer')}>
                <Badge
                    status={memberNotes.length > 0 ? 'notification' : 'default'}
                    className={cx('global!ace-u-margin--left-8')}
                >
                    {memberNotes.length ? filteredMemberNotes.length : '0'}
                </Badge>
            </div>
            <PopOver alignment="end">
                <div className={cx('global!ace-u-padding--32')}>
                    {!config.IS_FALLBACK_SYSTEM && isUserAllowedToCreateNotes && (
                        <div className={cx('global!ace-u-width--full')}>
                            <TextAreaField
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-16'])}
                                placeholder={translateHeader('member_note_popup_textarea_placeholder.new_entry')}
                                value={memberNoteContent || ''}
                                onChange={value => setMemberNoteContent(value)}
                                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                isResizable={false}
                            />
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                                <Button onClick={handleOnSubmit} isDisabled={!memberNoteContent}>
                                    {translateHeader('member_note_popup_button.add_note')}
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-full-width',
                        {'global!ace-u-margin--top-16': !config.IS_FALLBACK_SYSTEM && isUserAllowedToCreateNotes},
                        'global!ace-u-flex--direction-column',
                    ])}
                    >
                        {filteredMemberNotes
                            .sort((memberNoteA, memberNoteB) => (memberNoteA.createdAt < memberNoteB.createdAt
                                ? 1 : memberNoteA.createdAt > memberNoteB.createdAt ? -1 : 0))
                            .map((memberNote, id) => {
                                const user = users[memberNote.createdBy];
                                const userName = user ? `${user.firstName} ${user.lastName}` : '';
                                return (
                                    <MemberNotePopupItem
                                        key={memberNote.id}
                                        userName={userName}
                                        memberNote={memberNote}
                                        member={member}
                                        hasDivider={id < filteredMemberNotes.length - 1}
                                    />
                                );
                            })}
                    </div>
                </div>
            </PopOver>
        </DropDownTrigger>
    );
};

MemberNotePopup.propTypes = {
    members: PropTypes.object,
    users: PropTypes.object,
    member: PropTypes.object,
    submitCreateMemberNoteForm: PropTypes.func.isRequired,
};

MemberNotePopup.defaultProps = {
    members: {},
    users: null,
    member: null,
};

const mapStateToProps = state => ({
    members: memberSelectors.getMembersByMembershipNo(state),
    users: userProfileSelectors.getUsers(state),
});

const mapDispatchToProps = dispatch => ({
    submitCreateMemberNoteForm: payload => dispatch({
        type: memberActionTypes.SUBMIT_CREATE_MEMBER_NOTE_FORM,
        payload,
    }),
});

export default withDropDownProvider(connect(mapStateToProps, mapDispatchToProps)(MemberNotePopup));
