import {take, put, call, select, delay} from 'redux-saga/effects';
import {push, resolveRoute, ALL_ROUTE_UNMOUNT_SIDE_EFFECTS_COMPLETED} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceAssignmentStatus from '../../service-assignments/sagas/updateServiceAssignmentStatus';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';
import * as ehmCaseLogActionTypes from '../../case-logs/caseLogActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

const updateSAVAssignmentStatus = function* updateSAVAssignmentStatus() {
    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(savActionTypes.SUBMIT_SAV_STATUS_CHANGE);
            payloadData = payload;
        }

        if (!payloadData) continue;

        const {serviceCaseId, serviceAssignmentLineNo, serviceCaseData, serviceCaseType} = payloadData;
        const {serviceAssignmentData, serviceAssignmentType} = payloadData;
        const {attachmentsToSendWithAssignment, nonAceMember} = payloadData;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: savActionTypes.SUBMIT_SAV_STATUS_CHANGE,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_STATUS_CHANGE,
            assignmentType: serviceAssignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (nonAceMember || serviceCaseData?.damage?.additionalDescription) {
            yield* updateServiceCase({
                caller: savActionTypes.SUBMIT_SAV_STATUS_CHANGE,
                caseType: serviceCaseType,
                serviceCaseId,
                serviceCaseData,
            });
        }

        const updateServiceAssignmentStatusResponseAction = yield* updateServiceAssignmentStatus({
            caller: savActionTypes.SUBMIT_SAV_STATUS_CHANGE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            },
        });

        if (!updateServiceAssignmentStatusResponseAction.error) {
            yield put(push(resolveRoute(routePaths.DASHBOARD), {
                // location state
                shouldSkipTabSelection: true,
            }));

            const isInitialAssignmentCreationFlow = localStorage.getItem('isInitialAssignmentCreationFlow');

            if (!isInitialAssignmentCreationFlow) {
                yield put({
                    type: ehmCaseLogActionTypes.INITIATE_CREATE_CASE_LOG_LEFT_FLOW,
                    payload: {serviceCaseId},
                });
            }

            if (isInitialAssignmentCreationFlow) {
                localStorage.removeItem('isInitialAssignmentCreationFlow');
            }

            // ensure that all route unmount effect were executed before the modal changes a location key
            yield take(ALL_ROUTE_UNMOUNT_SIDE_EFFECTS_COMPLETED);
            // delay is needed because the router is faster than the UI update,
            // so fields connected to the router may behave inconsistently
            yield delay(1000);

            yield* openModal(modalIds.SAV_ASSIGNMENT_SUCCESSFUL);
        }

        if (updateServiceAssignmentStatusResponseAction.error) {
            const {status} = updateServiceAssignmentStatusResponseAction.payload.response;

            yield* openModal(
                modalIds.FAILED_SERVICE_ERROR_MESSAGE,
                {
                    errorCode: status ? `${status}` : '',
                    requestedAction: savActionTypes.SUBMIT_SAV_STATUS_CHANGE.toLowerCase(),
                },
            );

            const selectedErrorModalOption = yield take([
                savActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_FORM,
                savActionTypes.DECLINE_RESUBMITTING_COMMISSIONING_FORM,
            ]);

            if (selectedErrorModalOption.type === savActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_FORM) {
                shouldWaitForAction = false;
                continue;
            }
        }

        const {location} = yield select(state => state.router);
        if (location?.query?.modal && location.query.modal === modalIds.FAILED_SERVICE_ERROR_MESSAGE) {
            yield* closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''});
        }

        shouldWaitForAction = true;
    }
};

export default updateSAVAssignmentStatus;
