import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, NumberInputField, ToggleSwitch, Form, Icon, infoAlertIcon} from '@ace-de/ui-components';
import config from '../../config';
import * as sarcActionTypes from '../sarcActionTypes';
import getPrice from '../../utils/getPrice';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';

const SARCBudgetPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_requirements_tab');
    const {serviceAssignment, numberOfDays, submitSARCBudgetForm, serviceCase} = props;
    const {isDamageLocationInGermany, isDeliveryRequested} = props;
    const {serviceAssignmentBudgets, isMaxDurationError, getBudgetsByServiceType} = props;
    const [budgetData, setBudgetData] = useState({
        ...serviceAssignment,
        rentalBudget: serviceAssignment.budget.rental,
    });

    const roundToAtMost2Decimals = num => {
        if (!num) return 0;
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const countTotalCosts = useCallback((budgetValue, isRentalCarAbroadHome) => {
        if (numberOfDays === 0) return 0;
        if (isDamageLocationInGermany) {
            const budget = budgetValue
                || serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY]?.value;
            const total = parseFloat(budget) * numberOfDays;
            return roundToAtMost2Decimals(total);
        }
        return budgetValue || (!isRentalCarAbroadHome
            ? serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_MAX]?.value
            : serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX]?.value);
    }, [
        numberOfDays,
        isDamageLocationInGermany,
        serviceAssignmentBudgets,
    ]);

    const handleTabChange = useCallback(formValues => {
        const {overrideDefaultBudget, budgetPerDay, rentalBudget, rentalCarAbroadHome} = formValues;
        const serviceAssignmentData = {
            overrideDefaultBudget,
            rentalCarAbroadHome,
            ...(isDamageLocationInGermany && {
                budgetPerDay: overrideDefaultBudget
                    ? budgetPerDay
                    : serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY]?.value,
            }),
            serviceCaseId: serviceAssignment.serviceCaseId,
            budget: {
                rental: rentalBudget,
                delivery: isDeliveryRequested
                    ? (typeof serviceAssignment.budget?.delivery === 'number'
                        ? serviceAssignment.budget.delivery
                        : (typeof serviceCase?.remainingBudget === 'number'
                            ? serviceCase.remainingBudget
                            : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET))
                    : null,
            },
        };

        submitSARCBudgetForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSARCBudgetForm,
        serviceAssignment,
        isDeliveryRequested,
        serviceCase?.remainingBudget,
        serviceAssignmentBudgets,
        isDamageLocationInGermany,
    ]);

    const handleFormChange = formValues => {
        if (formValues.rentalCarAbroadHome !== budgetData.rentalCarAbroadHome) {
            getBudgetsByServiceType({
                serviceType: formValues.rentalCarAbroadHome
                    ? apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX
                    : apsServiceTypes.RENTAL_CAR__ABROAD_MAX,
                createdAt: serviceCase.createdAt,
                memberStatus: serviceCase.member.status,
                tariffGroup: serviceCase.member.tariffDetails.tariffGroup,
            });
        }
        setBudgetData({
            ...formValues,
            overrideDefaultBudget: !!formValues.overrideDefaultBudget,
            rentalCarAbroadHome: !!formValues.rentalCarAbroadHome,
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!budgetData) {
            completeRouteUnmountSideEffect({
                caller: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM,
            });
            return;
        }

        handleTabChange({
            ...budgetData,
            rentalBudget: countTotalCosts(
                isDamageLocationInGermany
                    ? budgetData.budgetPerDay
                    : budgetData.rentalBudget,
                budgetData.rentalCarAbroadHome,
            ),
        });
    }, [budgetData, handleTabChange, countTotalCosts, serviceAssignment, isDamageLocationInGermany]);

    return (
        <Panel
            title={translateTab('budget_panel_title.budget', {
                priceType: Number(serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID
                    ? translate('global.pricing_type.net')
                    : translate('global.pricing_type.gross'),
            })}
        >
            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-padding--bottom-24'])}>
                {translateTab('budget_panel_text.number_of_days')}&nbsp;
                {numberOfDays}
            </div>
            <Form name="sarcBudgetForm" onChange={handleFormChange}>
                {() => {
                    return (
                        <Fragment>
                            <NumberInputField
                                name={isDamageLocationInGermany ? 'budgetPerDay' : 'rentalBudget'}
                                label={isDamageLocationInGermany
                                    ? translateTab('budget_panel_input_label.budget_per_day')
                                    : translateTab('budget_panel_input_label.max_budget')}
                                value={budgetData.overrideDefaultBudget
                                    ? isDamageLocationInGermany
                                        ? budgetData.budgetPerDay
                                        : budgetData.rentalBudget
                                    : Number(serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID
                                        ? 0
                                        : isDamageLocationInGermany
                                            ? serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY]?.value // eslint-disable-line max-len
                                            : !budgetData.rentalCarAbroadHome
                                                ? serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_MAX]?.value // eslint-disable-line max-len
                                                : serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_HOME_MAX]?.value // eslint-disable-line max-len
                                }
                                isDisabled={!budgetData.overrideDefaultBudget}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-padding--bottom-32'])}
                                min={0}
                            />
                            {!isDamageLocationInGermany && (
                                <ToggleSwitch
                                    name="rentalCarAbroadHome"
                                    value={true}
                                    isSelected={!!budgetData.rentalCarAbroadHome}
                                >
                                    {translateTab('budget_panel_toggle_switch_label.rental_car_abroad_home')}
                                </ToggleSwitch>
                            )}
                            <ToggleSwitch
                                name="overrideDefaultBudget"
                                value={true}
                                isSelected={!!budgetData.overrideDefaultBudget}
                            >
                                {translateTab('budget_panel_toggle_switch_label.adjust_budget')}
                            </ToggleSwitch>
                            <div className={cx(['global!ace-u-padding--top-24', 'global!ace-u-padding--bottom-8'])}>
                                {translateTab('budget_panel_text.cost_coverage_sum')}
                            </div>
                            <div className={cx('global!ace-u-typography--variant-h2')}>
                                {getPrice(countTotalCosts(
                                    isDamageLocationInGermany
                                        ? budgetData.budgetPerDay
                                        : budgetData.rentalBudget,
                                    budgetData.rentalCarAbroadHome,
                                ), activeLocale)}
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
            {(isMaxDurationError
                || (!isDamageLocationInGermany && !budgetData.rentalCarAbroadHome
                    && budgetData.rentalBudget
                    > serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_MAX]?.value))
                && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--4-0',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-typography--color-warning',
                    ])}
                >
                    <Icon
                        icon={infoAlertIcon}
                        className={cx([
                            'global!ace-u-margin--right-4',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                            'global!ace-u-flex--shrink-0',
                        ])}
                    />
                    {isDamageLocationInGermany
                        ? translateTab('budget_panel_error_message.max_duration')
                        : translateTab('budget_panel_error_message.max_duration_abroad')
                    }
                </div>
                )}
        </Panel>
    );
};

SARCBudgetPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    submitSARCBudgetForm: PropTypes.func.isRequired,
    numberOfDays: PropTypes.number,
    isDamageLocationInGermany: PropTypes.bool,
    isDeliveryRequested: PropTypes.bool,
    serviceAssignmentBudgets: PropTypes.object,
    isMaxDurationError: PropTypes.bool,
    getBudgetsByServiceType: PropTypes.func.isRequired,
};

SARCBudgetPanel.defaultProps = {
    serviceAssignment: {},
    serviceCase: {},
    numberOfDays: 0,
    isDamageLocationInGermany: false,
    isDeliveryRequested: false,
    serviceAssignmentBudgets: null,
    isMaxDurationError: false,
};

const mapStateToProps = (state, props) => {
    return {
        serviceAssignmentBudgets: state.serviceAssignments.serviceAssignmentBudgets,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARCBudgetForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM,
        payload,
    }),
    getBudgetsByServiceType: payload => dispatch({
        type: serviceAssignmentActionTypes.GET_BUDGETS_BY_SERVICE_TYPE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SARCBudgetPanel);
