import {eventChannel} from 'redux-saga';
import {efServiceAssignmentTypes, Location} from '@ace-de/eua-entity-types';
import {select, take, fork, put, call} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saaActionTypes from '../saaActionTypes';
import config from '../../config';

const onSAAMapClickFetchReferencePositionAddress = function* onSAAMapClickFetchReferencePositionAddress(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-accommodation');
    const mapClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = arcGISMap.on('map-click', payload => {
            emitter(payload);
        });
        const unsubscribeDragListener = arcGISMap.on('graphic-drag-end', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeClickListener();
            unsubscribeDragListener();
        };
    });

    while (true) {
        const {arcGISPoint} = yield take(mapClickEventChannel);

        yield fork(
            fetchRequest,
            saaActionTypes.FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST,
            arcGISRESTService.getLocationAddress,
            {
                longitude: arcGISPoint.longitude,
                latitude: arcGISPoint.latitude,
                forStorage: true,
                langCode: config.ARCGIS_DEFAULT_LANG_CODE,
            },
        );

        const responseAction = yield take([
            saaActionTypes.FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_SUCCEEDED,
            saaActionTypes.FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_FAILED,
        ]);

        if (responseAction.error) return;

        const {response} = responseAction.payload;
        const {arcGISReverseGeocodingLocationDTO} = response;

        yield put({
            type: saaActionTypes.SET_SAA_REVERSE_GEOCODING_REFERENCE_POSITION,
            payload: {arcGISReverseGeocodingLocationDTO, serviceAssignmentId},
        });

        yield* updateServiceAssignment({
            caller: 'ON_SAA_MAP_CLICK_FETCH_REFERENCE_POSITION_ADDRESS',
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                referencePosition: new Location().fromDTO(arcGISReverseGeocodingLocationDTO),
            },
        });
    }
};

export default onSAAMapClickFetchReferencePositionAddress;
