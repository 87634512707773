import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Divider, ToggleSwitch} from '@ace-de/ui-components';
import {InteractiveIcon, deleteIcon} from '@ace-de/ui-components/icons';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import * as memberActionTypes from '../../members/memberActionTypes';
import config from '../../config';

const MemberNotePopupItem = props => {
    const {cx} = useStyles();
    const {translate, activeLocale, createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('service_case_header');
    const {member, initiateMemberNoteDeleteFlow, memberNote, userName, hasDivider} = props;
    const [showToggle, setShowToggle] = useState(false);
    const [deleteMemberNote, setDeleteMemberNote] = useState(false);
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    const handleShowToggle = () => {
        setShowToggle(!showToggle);
    };

    const toggleDeleteMemberNote = () => {
        setDeleteMemberNote(!deleteMemberNote);
    };

    useEffect(() => {
        if (deleteMemberNote) {
            initiateMemberNoteDeleteFlow({
                memberNoteId: memberNote.id,
                memberId: member.id,
                shouldOpenModal: false,
            });
        }
    }, [deleteMemberNote, initiateMemberNoteDeleteFlow, memberNote.id, member.id]);

    return (
        <Fragment>
            <div className={cx('global!ace-u-margin--16-0')}>
                <p className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-margin--bottom-8',
                ])}
                >
                    {`${isAgentIdentityAvailable
                        ? userName || ''
                        : translate('global.user_shift_role.emergency_call_advisor')
                    } ${moment(memberNote.createdAt).locale(activeLocale).format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`}
                </p>
                <div className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-space-between',
                ])}
                >
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {memberNote.content}
                    </p>
                    {!config.IS_FALLBACK_SYSTEM
                    && ecsAccessControl.grantFeatureAccess(ecsFeatures.MEMBER_NOTE, ecsFeatureActions.DELETE) && (
                        <InteractiveIcon
                            icon={deleteIcon}
                            className={cx([
                                'ace-c-interactive-icon--primary',
                                'global!ace-u-flex--align-self-flex-end',
                                'global!ace-u-padding--left-8',
                            ])}
                            onClick={handleShowToggle}
                        />
                    )}
                </div>
                {showToggle && (
                    <ToggleSwitch
                        className={cx('global!ace-u-padding--24-0')}
                        onChange={toggleDeleteMemberNote}
                    >
                        {translateHeader('member_note_popup_item.delete_confirmation')}
                    </ToggleSwitch>
                )}
            </div>
            {hasDivider ? <Divider /> : null}
        </Fragment>
    );
};

MemberNotePopupItem.propTypes = {
    memberNote: PropTypes.object,
    userName: PropTypes.string,
    member: PropTypes.object,
    hasDivider: PropTypes.bool.isRequired,
    initiateMemberNoteDeleteFlow: PropTypes.func.isRequired,
};

MemberNotePopupItem.defaultProps = {
    userName: '',
    memberNote: null,
    member: null,
};

const mapDispatchToProps = dispatch => ({
    initiateMemberNoteDeleteFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_MEMBER_NOTE_DELETE_FLOW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(MemberNotePopupItem);
