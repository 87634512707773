import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase, snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmQualityManagementPositiveFeedbackCategoryTypes, apmQualityManagementNegativeFeedbackCategoryTypes, apmQualityManagementFeedbackClassificationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Option, InputField, MultiSelectField, MultiSelectOption, ButtonPrimary, HighlightCircle} from '@ace-de/ui-components';
import {Form, TextAreaField, AutocompleteField, RadioButtonGroup, RadioTile} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, closeIcon, searchIcon, ratingPositiveIcon, ratingNegativeIcon, uploadIcon, timeIcon, attachmentIcon, deleteIcon, infoAlertIcon, plusIcon} from '@ace-de/ui-components/icons';
import {FileAssetsDropZone, FileAssetsInput, useFileAssets} from '@ace-de/eua-file-assets';
import * as contractPartnersSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';
import allowedAttachmentMIMETypes from '../../service-cases/allowedAttachmentMIMETypes';
import styles from './CreateQualityReportModal.module.scss';

const nonMandatoryQualityReportFields = [
    'caseId',
    'reportedBy',
    'submitQualityReportFormData',
    '',
];

const CreateQualityReportModal = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('create_quality_report_modal');
    const {hasBackdrop, contractPartner, serviceCaseSearchResults} = props;
    const {declineCreateCPQualityReport, confirmCreateCPQualityReport, location} = props;
    const [qualityReportFormData, setQualityReportFormData] = useState({
        ...(!!location?.query?.serviceCaseId && {caseId: location.query.serviceCaseId}),
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'apmFileAssetsService',
        fileList: 'feedback-documents',
    });

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const sortedServiceCases = useMemo(() => {
        return [...serviceCaseSearchResults].sort((serviceCaseA, serviceCaseB) => {
            return serviceCaseA.id < serviceCaseB.id ? -1 : 1;
        });
    }, [serviceCaseSearchResults]);

    const handleOnSubmit = formValues => {
        const qualityReportData = {
            ...formValues,
            contractPartnerId: contractPartner?.id || location?.query?.contractPartnerId,
            prefix: config.ACE_COMMISSIONER_ID,
            classification: formValues.positiveFeedback
                ? apmQualityManagementFeedbackClassificationTypes.POSITIVE
                : apmQualityManagementFeedbackClassificationTypes.NEGATIVE,
        };

        if (fileList.files.length) {
            qualityReportData.attachments = fileList.files.map(file => ({
                fileName: file.fileName,
                id: file.id,
            }));
        }
        confirmCreateCPQualityReport({
            qualityReportData,
        });
    };

    const isButtonDisabledCheck = formValues => {
        const mandatoryFields = Object.keys(formValues)
            .filter(fieldName => !nonMandatoryQualityReportFields.includes(fieldName));
        const validationOutput = mandatoryFields.every(mandatoryFieldName => {
            if (typeof formValues[mandatoryFieldName] === 'object') {
                return formValues[mandatoryFieldName]?.length > 0;
            }
            if (typeof formValues[mandatoryFieldName] === 'boolean') {
                return formValues[mandatoryFieldName] || !formValues[mandatoryFieldName];
            }
            return formValues[mandatoryFieldName] && formValues[mandatoryFieldName] !== '';
        });

        return !validationOutput;
    };

    const handleOnChange = formValues => {
        if (!formValues) return;
        const isDisabled = isButtonDisabledCheck(formValues);
        if (isDisabled !== isButtonDisabled) {
            setIsButtonDisabled(isDisabled);
        }

        if (Object.keys(qualityReportFormData).length === 0) {
            setQualityReportFormData({...formValues});
            return;
        }

        setQualityReportFormData(prevState => {
            Object.keys(prevState).forEach(key => {
                if (key !== 'categories' && key !== 'positiveFeedback') prevState[key] = formValues[key];
            });

            return {
                ...prevState,
                categories: prevState.positiveFeedback !== formValues.positiveFeedback ? [] : formValues.categories,
                positiveFeedback: formValues.positiveFeedback,
            };
        });
    };

    return (
        <Modal
            title={translateModal('header.title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateCPQualityReport}
                />
            )}
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--m'])}
        >
            <p
                className={cx([
                    'global!ace-u-margin--top-24',
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-typography--variant-body-bold',
                    'global!ace-u-full-width',
                ])}
            >
                {translateModal('text.contract_partner')}
            </p>
            {!!(contractPartner || location?.query?.contractPartnerName) && (
                <p
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-full-width',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    {contractPartner?.name || location?.query?.contractPartnerName || ''}
                </p>
            )}
            <div className={cx('global!ace-u-full-width')}>
                <Form name="createQualityReportForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-margin--bottom-16',
                        ])}
                    >
                        <AutocompleteField
                            name="caseId"
                            label={translateModal('input_label.case_number')}
                            className={cx([
                                'ace-c-input--small',
                                'global!ace-u-full-width',
                                'global!ace-u-flex--basis-30',
                            ])}
                            icon={searchIcon}
                            value={qualityReportFormData?.caseId || ''}
                            isDisabled={!!location?.query?.serviceCaseId}
                        >
                            {sortedServiceCases.map(serviceCase => (
                                <Option
                                    key={serviceCase.id}
                                    name={`caseId-${serviceCase.id}`}
                                    value={serviceCase.id}
                                >
                                    {serviceCase.id}
                                </Option>
                            ))}
                        </AutocompleteField>
                        <InputField
                            name="reportedBy"
                            label={translateModal('input_label.reported_by')}
                            className={cx([
                                'ace-c-input--small',
                                'global!ace-u-full-width',
                                'global!ace-u-flex--basis-30',
                            ])}
                            value={qualityReportFormData?.reportedBy || ''}
                        />
                    </div>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-16',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-full-width',
                        ])}
                    >
                        {translateModal('text.note')}
                    </p>
                    <RadioButtonGroup
                        name="positiveFeedback"
                        value={qualityReportFormData.hasOwnProperty('positiveFeedback')
                            ? qualityReportFormData.positiveFeedback : ''}
                    >
                        <div
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-start',
                            ])}
                        >
                            <RadioTile
                                icon={ratingPositiveIcon}
                                value={true}
                                name="qualityReportTypePositive"
                            >
                                {translateModal('input_label.positive')}
                            </RadioTile>
                            <RadioTile
                                icon={ratingNegativeIcon}
                                value={false}
                                name="qualityReportTypeNegative"
                                className={cx('global!ace-u-margin--left-24')}
                            >
                                {translateModal('input_label.negative')}
                            </RadioTile>
                        </div>
                    </RadioButtonGroup>
                    <MultiSelectField
                        name="categories"
                        label={translateModal('input_label.category')}
                        value={qualityReportFormData?.categories || []}
                        placeholder={translateModal('input_placeholder.select')}
                        className={cx(['global!ace-u-margin--24-0', 'global!ace-u-full-width'])}
                        isFieldRequired={true}
                    >
                        {qualityReportFormData.hasOwnProperty('positiveFeedback')
                        && typeof qualityReportFormData.positiveFeedback === 'boolean'
                            ? qualityReportFormData?.positiveFeedback
                                ? Object.keys(apmQualityManagementPositiveFeedbackCategoryTypes).map(feedback => (
                                    <MultiSelectOption
                                        key={feedback}
                                        name={`qualityReportCategoryOption${camelCase(apmQualityManagementPositiveFeedbackCategoryTypes[feedback])}`}
                                        value={feedback}
                                    >
                                        {translate(`global.quality_management_feedback_category.${snakeCase(apmQualityManagementPositiveFeedbackCategoryTypes[feedback])}`)}
                                    </MultiSelectOption>
                                )) : Object.keys(apmQualityManagementNegativeFeedbackCategoryTypes).map(feedback => (
                                    <MultiSelectOption
                                        key={feedback}
                                        name={`qualityReportCategoryOption${camelCase(apmQualityManagementNegativeFeedbackCategoryTypes[feedback])}`}
                                        value={feedback}
                                    >
                                        {translate(`global.quality_management_feedback_category.${snakeCase(apmQualityManagementNegativeFeedbackCategoryTypes[feedback])}`)}
                                    </MultiSelectOption>
                                )) : null}
                    </MultiSelectField>
                    <TextAreaField
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-24')}
                        name="description"
                        label={translateModal('input_label.description')}
                        placeholder={translateModal('input_placeholder.description')}
                        value={qualityReportFormData?.description || ''}
                        maxLength={config.MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH}
                        isResizable={false}
                        isFieldRequired={true}
                    />
                    <FileAssetsDropZone
                        service="apmFileAssetsService"
                        fileList="feedback-documents"
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--justify-center',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-position--relative',
                        ])}
                    >
                        <HighlightCircle
                            className={cx([
                                'global!ace-u-margin--32-192',
                                'ace-c-highlight-circle--medium',
                                'ace-c-highlight-circle--primary',
                            ])}
                        >
                            <Icon
                                icon={uploadIcon}
                                className={cx([
                                    'ace-c-icon--xxl',
                                    'ace-c-icon--color-highlight',
                                ])}
                            />
                        </HighlightCircle>
                        <h3 className={cx('global!ace-u-typography--variant-h3')}>
                            {translateModal('drop_zone_title.document_upload')}
                        </h3>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography--color-medium-emphasis',
                            ])}
                        >
                            {translateModal('text.valid_formats')}
                        </p>
                        <FileAssetsInput
                            service="apmFileAssetsService"
                            fileList="feedback-documents"
                            className={cx([
                                'global!ace-u-margin--24-0',
                                'ace-c-file-input--no-chrome',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-margin--24-0',
                                    'global!ace-u-typography--variant-highlight',
                                    'global!ace-u-typography--color-highlighted',
                                    'global!ace-u-cursor--pointer',
                                ])}
                            >
                                <Icon
                                    icon={plusIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--color-highlight',
                                    ])}
                                />
                                {translateModal('label.search_documents')}
                            </div>
                        </FileAssetsInput>
                    </FileAssetsDropZone>
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-space-between')}>
                        <div>
                            <p
                                className={cx([
                                    'global!ace-u-margin--bottom-16',
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-full-width',
                                ])}
                            >
                                {translateModal('list_documents.title')}
                            </p>
                            {fileList.errors.length > 0 && (
                                <div className={cx('global!ace-u-margin--top-8')}>
                                    {fileList.errors.map((errorInfo, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-margin--4-0',
                                                    'global!ace-u-typography--variant-caption',
                                                    'global!ace-u-typography--color-warning',
                                                ])}
                                            >
                                                <Icon
                                                    icon={infoAlertIcon}
                                                    className={cx([
                                                        'global!ace-u-margin--right-4',
                                                        'ace-c-icon--s',
                                                        'ace-c-icon--color-warning',
                                                        'global!ace-u-flex--shrink-0',
                                                    ])}
                                                />
                                                {translateModal(`eua_file_assets_error_type.${snakeCase(errorInfo.errorType)}`)}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {fileList.files.length > 0 && (
                                <div className={cx(['global!ace-u-margin--16-0', {
                                    'ace-c-quality-report-modal__attachments': fileList.files.length > config.MAXIMUM_FILE_ASSETS_IN_VIEW,
                                }])}
                                >
                                    {fileList.files.map(fileAsset => {
                                        return (
                                            <div
                                                key={fileAsset.id}
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-space-between',
                                                    'global!ace-u-margin--8-0',
                                                ])}
                                            >
                                                <span
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-typography--variant-body',
                                                        'global!ace-u-typography--color-highlighted',
                                                    ])}
                                                >
                                                    <Icon
                                                        icon={fileAsset.isUploadPending
                                                            ? timeIcon
                                                            : attachmentIcon
                                                        }
                                                        className={cx([
                                                            'global!ace-u-margin--right-8',
                                                            'ace-c-icon--xs',
                                                            'ace-c-icon--color-highlight',
                                                        ])}
                                                    />
                                                    {fileAsset.fileName}
                                                </span>
                                                <Icon
                                                    onClick={() => deleteFile(fileAsset)}
                                                    icon={deleteIcon}
                                                    className={cx([
                                                        'ace-c-icon--xs',
                                                        'ace-c-icon--color-medium-emphasis',
                                                    ])}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <ButtonPrimary
                            type="submit"
                            name="submitQualityReportFormData"
                            isDisabled={isButtonDisabled}
                        >
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

CreateQualityReportModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    serviceCaseSearchResults: PropTypes.array,
    location: PropTypes.object,
    declineCreateCPQualityReport: PropTypes.func.isRequired,
    confirmCreateCPQualityReport: PropTypes.func.isRequired,
};

CreateQualityReportModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: {},
    serviceCaseSearchResults: [],
    location: {},
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnersSelectors.createContractPartnerSelector();
    return {
        contractPartner: getContractPartner(state, props),
        serviceCaseSearchResults: contractPartnersSelectors.getServiceCaseSearchResults(state),
    };
};

const mapDispatchToProps = dispatch => ({
    declineCreateCPQualityReport: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT,
    }),
    confirmCreateCPQualityReport: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateQualityReportModal);
