import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as salaActionTypes from '../salaActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SALA cost coverage flow
 */
const updateSALACostCoverageFlow = function* updateSALACostCoverageFlow() {
    while (true) {
        const {payload} = yield take(salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData} = payload;
        const {serviceCaseId, attachmentsToSendWithAssignment} = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.LEGAL_ADVICE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM},
        });
    }
};

export default updateSALACostCoverageFlow;
