import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import ecsAccessControl from './ecsAccessControl';
import applicationReducer from './application/applicationReducer';
import userReducer from './user-profiles/userProfileReducer';
import memberReducer from './members/memberReducer';
import serviceCaseReducer from './service-cases/serviceCaseReducer';
import serviceAssignmentReducer from './service-assignments/serviceAssignmentReducer';
import taskReducer from './tasks/taskReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import caseLogReducer from './case-logs/caseLogReducer';
import contractPartnerReducer from './contract-partners/contractPartnerReducer';
import requestStateReducer from './application/requestStateReducer';

// application reducers
const reducers = {
    application: applicationReducer,
    user: userReducer,
    members: memberReducer,
    serviceCases: serviceCaseReducer,
    serviceAssignments: serviceAssignmentReducer,
    tasks: taskReducer,
    contractPartners: contractPartnerReducer,
    dashboard: dashboardReducer,
    caseLog: caseLogReducer,
    requestStates: requestStateReducer,
    ...keycloakAuth.reducer,
    ...arcGISAuth.reducer,
    ...ecsAccessControl.reducer,
};

export default reducers;
