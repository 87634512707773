import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, HighlightCircle} from '@ace-de/ui-components';
import {infoAlertIcon, Icon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';

const FailedRequestBlock = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {translationKey, children} = props;

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-full-width',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-content-center',
                'global!ace-u-margin--64-0',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                ])}
            >
                <Icon
                    icon={infoAlertIcon}
                    className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                />
            </HighlightCircle>
            {!!translationKey && (
                <p
                    className={cx([
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-medium-emphasis',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translate(`failed_request_block.error_message.${translationKey.toLowerCase()}`)}
                </p>
            )}
            {children}
        </div>
    );
};

FailedRequestBlock.propTypes = {
    translationKey: PropTypes.string,
};

FailedRequestBlock.defaultProps = {
    translationKey: '',
};

export default FailedRequestBlock;
