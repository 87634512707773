import React, {useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {useTranslate} from '@computerrock/formation-i18n';
import {ACEPartner, BusinessContactDetails, efServiceAssignmentTypes, apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary, DataRow, Divider} from '@ace-de/ui-components';
import {InputField, Form, NumberInput, AutosuggestField, Option} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, closeIcon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import config from '../../config';
import {validateEmail, validateFax} from '../../utils/validation';

const nonMandatoryFields = ['phoneNo', 'faxNo', 'email', 'totalCost'];
const initialErrorFields = {
    email: [],
    address: [],
    name: [],
    faxNo: [],
};

const ManualACEPartnerDataModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('manual_ace_partner_data_modal');
    const {hasBackdrop, serviceAssignment, searchACEPartnerLocationGeolocation} = props;
    const {confirmUpdateACEPartner, declineUpdateACEPartner} = props;
    const [acePartnerData, setACEPartnerData] = useState(serviceAssignment?.acePartner?.isManuallyAdded ? {
        ...serviceAssignment.acePartner,
        ...(serviceAssignment.assignmentType !== efServiceAssignmentTypes.PICKUP && {
            totalCost: serviceAssignment?.totalCost || '',
        }),
    } : null);
    const [errorFields, setErrorFields] = useState({...initialErrorFields});
    const [newACEPartnerLocation, setNewACEPartnerLocation] = useState(serviceAssignment?.acePartner?.isManuallyAdded
        ? serviceAssignment.acePartner.location
        : null);
    const currentACEPartnerLocationAddress = useRef('');
    const lastACEPartnerLocationSearchQuery = useRef('');
    const searchACEPartnerLocationGeolocationDebounced = useMemo(
        () => debounce(
            searchACEPartnerLocationGeolocation,
            config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
        ),
        [searchACEPartnerLocationGeolocation],
    );
    const disallowedSymbols = ['Tab', ' '];

    const handleACEPartnerLocationSearchQueryChange = searchQueryString => {
        if (!searchQueryString && searchQueryString.length <= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            setNewACEPartnerLocation(null);
        }

        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentACEPartnerLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH
        ) {
            searchACEPartnerLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastACEPartnerLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleACEPartnerLocationCandidateSelect = acePartnerLocationCandidate => {
        currentACEPartnerLocationAddress.current = acePartnerLocationCandidate.formattedAddress;
        setNewACEPartnerLocation(acePartnerLocationCandidate);
    };

    const handleFormFieldsValidation = formValues => {
        let hasError = false;
        const mandatoryErrorMessage = translateModal('error_message.mandatory_field');
        const emailErrorMessage = translateModal('error_message.please_enter_valid_email');
        const faxErrorMessage = translateModal('error_message.please_enter_valid_fax');

        Object.entries(formValues).forEach(formValueEntry => {
            const key = formValueEntry[0];
            const value = formValueEntry[1];

            if (value === '' && !nonMandatoryFields.includes(key)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [mandatoryErrorMessage],
                }));
                hasError = true;
            } else if (key === 'email' && value !== '' && !validateEmail(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [emailErrorMessage],
                }));
                hasError = true;
            } else if (key === 'faxNo' && value !== '' && !validateFax(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [faxErrorMessage],
                }));
                hasError = true;
            } else if (errorFields[key]) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [],
                }));
            }
        });

        return hasError;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) return;
        const hasError = handleFormFieldsValidation(formValues);
        if (hasError || !newACEPartnerLocation) return;

        const businessContactDetails = {
            phoneNo: formValues.phoneNo,
            faxNo: formValues.faxNo || null,
            email: formValues.email,
        };

        confirmUpdateACEPartner({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                ...(serviceAssignment.assignmentType !== efServiceAssignmentTypes.PICKUP && {
                    totalCost: formValues.totalCost,
                }),
                ...(serviceAssignment.assignmentType === efServiceAssignmentTypes.PICKUP && {
                    // reset route distance, in order to force recalculation on the last tab
                    routeDistance: null,
                }),
                acePartner: {
                    ...(new ACEPartner()),
                    id: null, // manually added acePartner does not have an ID
                    isManuallyAdded: true,
                    name: formValues.name,
                    businessContactDetails: {
                        ...(new BusinessContactDetails()),
                        ...businessContactDetails,
                    },
                    location: newACEPartnerLocation,
                    contactDetails: null,
                    assignmentChannels: [
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                        apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE,
                    ],
                },
            },
            // if there is a draft, delete it
            qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft?.id || null,
            // if there is a draft placeholder, delete it
            qmFeedbackDraftPlaceholderId: serviceAssignment.qmFeedbackDraftPlaceholder?.id || null,
        });
    };

    const handleOnChange = formValues => {
        if (!formValues) return;

        setACEPartnerData({
            ...formValues,
            // TODO: check this in EDIT mode
            location: {
                ...(acePartnerData?.location || {}),
                ...(formValues.location || {}),
            },
            businessContactDetails: {
                ...(acePartnerData?.businessContactDetails || {}),
                ...(formValues.businessContactDetails || {}),
            },
        });
    };

    if (!serviceAssignment) return null;

    const {acePartnerLocationCandidates, acePartnerLocationSearchQuery} = serviceAssignment;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => declineUpdateACEPartner()}
                />
            )}
            title={translateModal('title.add_ace_partner_manually')}
            contentClassName={cx(['global!ace-u-margin--top-32', 'global!ace-u-modal-content-size--m'])}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form name="addACEPartnerDataForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-6')}>
                            <DataRow
                                label={translateModal('data_row_label.name')}
                                qaIdent="ace-partner-name"
                                isFieldRequired={true}
                            >
                                <InputField
                                    name="name"
                                    value={acePartnerData?.name || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.name}
                                />
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.address')}
                                qaIdent="ace-partner-address"
                                isFieldRequired={true}
                            >
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <AutosuggestField
                                        name="address"
                                        value={newACEPartnerLocation?.formattedAddress || ''}
                                        onChange={handleACEPartnerLocationSearchQueryChange}
                                        onOptionSelect={handleACEPartnerLocationCandidateSelect}
                                        optionValueSelector={acePartnerLocationCandidate => {
                                            return acePartnerLocationCandidate.formattedAddress;
                                        }}
                                        placeholder={translateModal('input_placeholder.please_fill_in')}
                                        icon={searchIcon}
                                        className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                        errors={errorFields.address}
                                    >
                                        {(acePartnerData?.address || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                        && acePartnerLocationSearchQuery === lastACEPartnerLocationSearchQuery.current
                                            ? acePartnerLocationCandidates
                                                .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                .map((acePartnerLocationCandidate, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            name={`ace-partner-location-candidate-${index}`}
                                                            value={acePartnerLocationCandidate}
                                                        >
                                                            <Icon
                                                                icon={locationIcon}
                                                                className={cx('global!ace-u-margin--right-16')}
                                                            />
                                                            {acePartnerLocationCandidate.formattedAddress}
                                                        </Option>
                                                    );
                                                }) : null}
                                    </AutosuggestField>
                                </div>
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.phone_number')}
                                qaIdent="ace-partner-phone-no"
                            >
                                <InputField
                                    name="phoneNo"
                                    value={acePartnerData?.businessContactDetails?.phoneNo
                                        ? acePartnerData?.businessContactDetails.phoneNo
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                />
                            </DataRow>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-6')}>
                            <DataRow
                                label={translateModal('data_row_label.fax_number')}
                                qaIdent="ace-partner-fax-no"
                            >
                                <InputField
                                    name="faxNo"
                                    value={acePartnerData?.businessContactDetails?.faxNo
                                        ? acePartnerData?.businessContactDetails.faxNo
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.faxNo}
                                    disallowedSymbols={disallowedSymbols}
                                />
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.email')}
                                qaIdent="ace-partner-email"
                            >
                                <InputField
                                    name="email"
                                    value={acePartnerData?.businessContactDetails?.email
                                        ? acePartnerData?.businessContactDetails.email
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.email}
                                    disallowedSymbols={disallowedSymbols}
                                />
                            </DataRow>
                            {serviceAssignment?.assignmentType !== efServiceAssignmentTypes.PICKUP && (
                                <DataRow
                                    label={translateModal('data_row_label.costs')}
                                    qaIdent="ace-partner-costs"
                                >
                                    <NumberInput
                                        name="totalCost"
                                        value={acePartnerData?.totalCost || ''}
                                        className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                        min={0}
                                    />
                                </DataRow>
                            )}
                        </div>
                    </div>
                    <Divider className={cx('global!ace-u-margin--48-0-32')} />
                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                        <ButtonPrimary
                            name="submitACEPartnerData"
                            type="submit"
                        >
                            {translateModal('button_label.accept')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

ManualACEPartnerDataModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    confirmUpdateACEPartner: PropTypes.func.isRequired,
    declineUpdateACEPartner: PropTypes.func.isRequired,
    searchACEPartnerLocationGeolocation: PropTypes.func.isRequired,
};

ManualACEPartnerDataModal.defaultProps = {
    hasBackdrop: false,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateACEPartner: payload => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_UPDATE_ACE_PARTNER,
        payload,
    }),
    declineUpdateACEPartner: payload => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_UPDATE_ACE_PARTNER,
        payload,
    }),
    searchACEPartnerLocationGeolocation: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualACEPartnerDataModal);
