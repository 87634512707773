import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, ButtonPrimary, ButtonSecondary, HighlightCircle, SelectField, Option, Form, TextAreaField} from '@ace-de/ui-components';
import {Icon, infoIcon} from '@ace-de/ui-components/icons';
import {apmGenericQMFeedbackCategoryTypes, efServiceAssignmentTypes, apmQualityManagementFeedbackDecisionByTypes} from '@ace-de/eua-entity-types';
import * as sapActionTypes from '../../service-assignments-pickup/sapActionTypes';
import * as savActionTypes from '../../service-assignments-vehicle/savActionTypes';
import * as savaActionTypes from '../../service-assignments-vehicle-additional/savaActionTypes';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import config from '../../config';

const determineActionTypes = serviceAssignment => {
    if (!serviceAssignment) return null;

    switch (serviceAssignment.assignmentType) {
        case efServiceAssignmentTypes.PICKUP: {
            return {
                confirm: sapActionTypes.CONFIRM_SAP_QM_NOTES,
                decline: sapActionTypes.DECLINE_SAP_QM_NOTES,
            };
        }
        case efServiceAssignmentTypes.VEHICLE: {
            const isInitial = parseInt(serviceAssignment.lineNo, 10) === 1
                || serviceAssignment.lineNo === 'FBS1'
                || serviceAssignment.isCloneFromInitial;
            return {
                confirm: isInitial
                    ? savActionTypes.CONFIRM_SAV_QM_NOTES
                    : savaActionTypes.CONFIRM_SAVA_QM_NOTES,
                decline: isInitial
                    ? savActionTypes.DECLINE_SAV_QM_NOTES
                    : savaActionTypes.DECLINE_SAVA_QM_NOTES,
            };
        }
        default: return null;
    }
};

const ServiceAssignmentQMNotesModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('service_assignment_qm_notes_modal');
    const {hasBackdrop, serviceAssignment, location, contractPartners} = props;
    const {confirmMultipleQMNotes, declineMultipleQMNotes} = props;
    const {contractPartnerRecommendations, recommendedContractPartnerId} = serviceAssignment;
    const actionTypes = determineActionTypes(serviceAssignment);

    const recommendedContractPartner = useMemo(() => {
        const currentQueryParams = new URLSearchParams(location?.search || '');
        const isVBASearch = currentQueryParams.get('isVBASearch') === 'true';
        const recommendedCPId = currentQueryParams.get('recommendedCPId');
        if (isVBASearch && recommendedCPId) return contractPartners
            ? contractPartners[recommendedCPId] : null;
        return contractPartnerRecommendations
            .find(contractPartner => contractPartner.id === recommendedContractPartnerId);
    }, [contractPartnerRecommendations, recommendedContractPartnerId, contractPartners, location?.search]);

    const handleOnSubmit = formValues => {
        // check if we are creating VBA QM draft
        // if so, contractPartnerId !== serviceAssignment.recommendedContractPartnerId
        // since we have to create a QM note for previously selected ACTIVE recommended CP
        const currentQueryParams = new URLSearchParams(location?.search || '');
        const isVBASearch = currentQueryParams.get('isVBASearch') === 'true';
        const recommendedCPId = currentQueryParams.get('recommendedCPId');

        const qmDraftData = {
            contractPartnerId: isVBASearch && recommendedCPId
                ? recommendedCPId : recommendedContractPartnerId,
            caseId: serviceAssignment.serviceCaseId,
            lineNo: serviceAssignment.lineNo,
            // form data
            description: formValues.description,
            categories: [formValues.category],
            decisionBy: formValues.decisionBy,
            isContractPartnerConsulted: formValues.isContractPartnerConsulted,
            // cp list snapshot data
            contractPartnerRecommendations: contractPartnerRecommendations.map(contractPartner => ({
                id: contractPartner.id,
                rank: contractPartner.rank,
                routeToDamageLocation: {
                    totalKilometers: contractPartner.routeToDamageLocation.totalKilometers,
                    totalTravelTime: contractPartner.routeToDamageLocation.totalTravelTime,
                },
                formattedAddress: contractPartner.location?.formattedAddress,
            })),
        };
        confirmMultipleQMNotes(actionTypes.confirm, {qmDraftData});
    };

    if (!recommendedContractPartner || !actionTypes) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-modal-content-size--xs')}
            className={cx('ace-c-modal__content--scrollable')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--negative',
                    ])}
                >
                    <Icon
                        icon={infoIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                </HighlightCircle>
                <h3>{translateModal('title.please_note')}</h3>
                <p className={cx('global!ace-u-typography--align-center')}>
                    {translateModal('text.description')}
                </p>
                <Form name="multipleQMNoteForm" onSubmit={handleOnSubmit}>
                    {formValues => {
                        return (
                            <div className={cx('global!ace-u-modal-content-size--xs')}>
                                <div className={cx('global!ace-u-margin--24-0', 'global!ace-u-full-width')}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--column-gap-24',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                    >
                                        <SelectField
                                            className={cx('global!ace-u-flex--basis-50')}
                                            name="decisionBy"
                                            label={translateModal('input_label.decision_by')}
                                            value={apmQualityManagementFeedbackDecisionByTypes.EUA}
                                            isFieldRequired={true}
                                        >
                                            <Option
                                                value={apmQualityManagementFeedbackDecisionByTypes.EUA}
                                                name="decisionByOptionEUA"
                                            >
                                                {translate('global.quality_management_feedback_decision_by.eua')}
                                            </Option>
                                            <Option
                                                value={apmQualityManagementFeedbackDecisionByTypes.CONTRACT_PARTNER}
                                                name="decisionByOptionCP"
                                            >
                                                {translate('global.quality_management_feedback_decision_by.contract_partner')}
                                            </Option>
                                        </SelectField>
                                        <SelectField
                                            className={cx('global!ace-u-flex--basis-50')}
                                            name="isContractPartnerConsulted"
                                            label={translateModal('input_label.cp_consultation')}
                                            value={false}
                                            isFieldRequired={true}
                                        >
                                            <Option
                                                value={true}
                                                name="isContractPartnerConsultedOptionYes"
                                            >
                                                {translateModal('is_cp_consulted_option.yes')}
                                            </Option>
                                            <Option
                                                value={false}
                                                name="isContractPartnerConsultedOptionNo"
                                            >
                                                {translateModal('is_cp_consulted_option.no')}
                                            </Option>
                                        </SelectField>
                                    </div>
                                    <SelectField
                                        className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-full-width')}
                                        name="category"
                                        label={`#1 ${recommendedContractPartner.name}`}
                                        value=""
                                        isFieldRequired={true}
                                    >
                                        {Object.keys(apmGenericQMFeedbackCategoryTypes)
                                            .filter(category => {
                                                return ![
                                                    apmGenericQMFeedbackCategoryTypes.TOO_MUCH_TRAFFIC,
                                                    apmGenericQMFeedbackCategoryTypes.RESTRICTED_SERVICES,
                                                ].includes(category);
                                            }).map(apmGenericQMFeedbackCategoryType => {
                                                return (
                                                    <Option
                                                        key={apmGenericQMFeedbackCategoryType}
                                                        value={apmGenericQMFeedbackCategoryType}
                                                        name={`feedbackCategory${camelCase(apmGenericQMFeedbackCategoryType)}`}
                                                    >
                                                        {translate(
                                                            `global.quality_management_feedback_category.${apmGenericQMFeedbackCategoryType.toLowerCase()}`,
                                                        )}
                                                    </Option>
                                                );
                                            })}
                                    </SelectField>
                                    <TextAreaField
                                        name="description"
                                        label={translateModal('input_label.description')}
                                        maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                        isResizable={false}
                                        className={cx('global!ace-u-full-width')}
                                        value=""
                                    />
                                    <p
                                        className={cx([
                                            'global!ace-u-margin--top-8',
                                            'global!ace-u-typography--variant-caption',
                                            'global!ace-u-typography--color-medium-emphasis',
                                        ])}
                                    >
                                        {`${formValues?.description?.length}/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH} ${translate('global.unit.characters')}`}
                                    </p>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-row',
                                        'global!ace-u-flex--justify-center',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-full-width',
                                    ])}
                                >
                                    <ButtonSecondary
                                        type="button"
                                        name="declineQMNotes"
                                        className={cx('global!ace-u-margin--right-32')}
                                        onClick={() => declineMultipleQMNotes(actionTypes.decline, {})}
                                    >
                                        {translateModal('button_label.decline_qm_notes')}
                                    </ButtonSecondary>
                                    <ButtonPrimary
                                        name="submitMultipleQMNoteFormButton"
                                        type="submit"
                                        isDisabled={!formValues.category
                                            || !formValues.decisionBy
                                            || typeof formValues.isContractPartnerConsulted !== 'boolean'}
                                    >
                                        {translateModal('button_label.confirm_qm_notes')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

ServiceAssignmentQMNotesModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    location: PropTypes.object,
    contractPartners: PropTypes.object,
    confirmMultipleQMNotes: PropTypes.func.isRequired,
    declineMultipleQMNotes: PropTypes.func.isRequired,
};

ServiceAssignmentQMNotesModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
    location: null,
    contractPartners: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        contractPartners: state.contractPartners.contractPartners,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmMultipleQMNotes: (type, payload) => dispatch({
        type,
        payload,
    }),
    declineMultipleQMNotes: (type, payload) => dispatch({
        type,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAssignmentQMNotesModal);
