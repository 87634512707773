/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_SICK_PERSON = '[service-cases] CREATE_SERVICE_ASSIGNMENT_SICK_PERSON';
export const SUBMIT_SASP_COMMISSIONING_FORM = '[service-cases] SUBMIT_SASP_COMMISSIONING_FORM';
export const SUBMIT_SASP_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SASP_SERVICE_PROVIDER_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST_FAILED';

/**
 * Store action types
 */
