import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';
import modalIds from '../../modalIds';

/**
 * Update SAV trailer data flow
 */
const updateSAVTrailerDataFlow = function* updateSAVTrailerDataFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_TRAILER_FORM);
        const {location} = yield select(state => state.router);
        // do not PATCH trailer data when modal is open,
        // neither confirm vehicle selection modal nor vehicle data modal
        if (location?.state?.isModalOpen
            && [modalIds.CONFIRM_VEHICLE_SELECTION, modalIds.VEHICLE_DATA].includes(location?.state?.modalId)
        ) {
            continue;
        }

        const {serviceCaseData, serviceCaseId, caseType} = payload;

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_TRAILER_FORM,
            caseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_TRAILER_FORM},
        });
    }
};

export default updateSAVTrailerDataFlow;
