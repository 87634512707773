/**
 * @typedef {string} deletionStatuses
 */

/**
 * Deletion status
 *
 * @enum {deletionStatuses}
 */
export default {
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};
