import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';

export const serviceAssignmentCloningStatusCandidates = [
    efServiceAssignmentStatusTypes.CANCELED,
    efServiceAssignmentStatusTypes.EUA_CANCELED,
    efServiceAssignmentStatusTypes.REJECTED,
];

export const serviceAssignmentAcceptedStatusCandidates = [
    efServiceAssignmentStatusTypes.ACCEPTED,
    efServiceAssignmentStatusTypes.ASSIGNED,
];

export const serviceAssignmentRejectedCandidates = [
    efServiceAssignmentStatusTypes.REJECTED,
    efServiceAssignmentStatusTypes.ASSIGNED,
];

export const serviceAssignmentEUACancelledCandidates = [
    efServiceAssignmentStatusTypes.EUA_CANCELED,
    efServiceAssignmentStatusTypes.ASSIGNED,
];

export const serviceAssignmentDoneCandidates = [
    efServiceAssignmentStatusTypes.DONE,
];

export const serviceAssignmentCancelledCandidates = [
    efServiceAssignmentStatusTypes.CANCELED,
    efServiceAssignmentStatusTypes.ACCEPTED,
];

export const serviceAssignmentNewCandidates = [
    efServiceAssignmentStatusTypes.DONE,
];
