/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_ACCOMMODATION = '[service-cases] CREATE_SERVICE_ASSIGNMENT_ACCOMMODATION';
export const SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM = '[service-cases] SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM';
export const SUBMIT_SAA_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SAA_COST_COVERAGE_FORM';
export const SUBMIT_SAA_MEMBER_LOCATION_FORM = '[service-cases] SUBMIT_SAA_MEMBER_LOCATION_FORM';
export const SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION = '[service-cases] SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION';
export const SUBMIT_SAA_REFERENCE_POSITION_FORM = '[service-cases] SUBMIT_SAA_REFERENCE_POSITION_FORM';
export const SEARCH_SAA_ACCOMMODATION_LOCATIONS = '[service-cases] SEARCH_SAA_ACCOMMODATION_LOCATIONS';
export const SUBMIT_SAA_ACCOMMODATION_LOCATION_FORM = '[service-cases] SUBMIT_SAA_ACCOMMODATION_LOCATION_FORM';
export const START_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER = '[service-cases] START_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER';
export const STOP_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER = '[service-cases] STOP_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER';
export const SUBMIT_SAA_BUDGET_FORM = '[service-cases] SUBMIT_SAA_BUDGET_FORM';
export const INITIATE_UPDATE_ACCOMMODATION_FLOW = '[service-cases] INITIATE_UPDATE_ACCOMMODATION_FLOW';
export const CONFIRM_UPDATE_ACCOMMODATION = '[service-cases] CONFIRM_UPDATE_ACCOMMODATION';
export const DECLINE_UPDATE_ACCOMMODATION = '[service-cases] DECLINE_UPDATE_ACCOMMODATION';
export const SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION';
export const INITIATE_EXTEND_STAY_END_DATE_FLOW = '[service-cases] INITIATE_EXTEND_STAY_END_DATE_FLOW';
export const CONFIRM_SAA_EXTEND_STAY_END_DATE = '[service-cases] CONFIRM_SAA_EXTEND_STAY_END_DATE';
export const DECLINE_SAA_EXTEND_STAY_END_DATE = '[service-cases] DECLINE_SAA_EXTEND_STAY_END_DATE';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_FAILED';

export const FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST = '[service-cases] FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST';
export const FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_SENT = '[service-cases] FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_SENT';
export const FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_SUCCEEDED';
export const FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_FAILED = '[service-cases] FETCH_SAA_REFERENCE_POSITION_ADDRESS_REQUEST_FAILED';

export const SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST';
export const SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST = '[service-cases] SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST';
export const SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_SENT = '[service-cases] SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_SENT';
export const SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED';
export const SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_FAILED = '[service-cases] SEARCH_ACCOMMODATION_LOCATION_GOOGLE_PLACES_REQUEST_FAILED';

export const FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST = '[service-cases] FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST';
export const FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_SENT = '[service-cases] FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_SENT';
export const FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED';
export const FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_FAILED = '[service-cases] FETCH_ACCOMMODATION_LOCATION_GEOLOCATIONS_REQUEST_FAILED';

export const SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAA_REFERENCE_POSITION_CANDIDATES = '[service-cases] SET_SAA_REFERENCE_POSITION_CANDIDATES';
export const SET_SAA_REVERSE_GEOCODING_REFERENCE_POSITION = '[service-cases] SET_SAA_REVERSE_GEOCODING_REFERENCE_POSITION';
export const SET_SAA_ACCOMMODATION_LOCATION_RECOMMENDATIONS = '[service-cases] SET_SAA_ACCOMMODATION_LOCATION_RECOMMENDATIONS';
export const SET_SAA_HOTEL_LOCATION_CANDIDATES = '[service-cases] SET_SAA_HOTEL_LOCATION_CANDIDATES';
