import {createSelector} from 'reselect';
import defaultRequestState from './defaultRequestState';

/**
 * Request state selectors
 */

export const getRequestStates = state => state.requestStates.requestStates;

export const createRequestStateSelector = actionType => createSelector(
    [
        getRequestStates,
    ], requestStates => {
        if (!actionType || !requestStates.hasOwnProperty(actionType)) return defaultRequestState;

        return requestStates[actionType];
    },
);
