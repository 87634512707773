import {all, fork} from 'redux-saga/effects';
import createCaseLogLeftFlow from './sagas/createCaseLogLeftFlow';
import createCaseLogViewedFlow from './sagas/createCaseLogViewedFlow';
import caseLogCreateNoteFlow from './sagas/createCaseLogNoteFlow';
import sendMessageFlow from './sagas/sendMessageFlow';
import updateCaseLogNoteFlow from './sagas/updateCaseLogNoteFlow';
import noteDocumentDownloadFlow from './sagas/noteDocumentDownloadFlow';
import createCaseLogPDFFlow from './sagas/createCaseLogPDFFlow';

/**
 *  Case log watcher saga
 */
const caseLogWatcher = function* caseLogWatcher() {
    yield all([
        fork(createCaseLogViewedFlow),
        fork(createCaseLogLeftFlow),
        fork(caseLogCreateNoteFlow),
        fork(sendMessageFlow),
        fork(updateCaseLogNoteFlow),
        fork(noteDocumentDownloadFlow),
        fork(createCaseLogPDFFlow),
    ]);
};

export default caseLogWatcher;
