import React, {useState, useCallback, useEffect, useRef, useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efChildrenAgeTypes, efReturnJourneyTransportationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Form, Divider, DateField, calendarIcon, InputCounter, AutosuggestField, searchIcon, Icon, locationIcon} from '@ace-de/ui-components';
import {InputField, InputPhoneNumberField, NumberInputField} from '@ace-de/ui-components/text-inputs';
import {Checkbox, SelectField, Option} from '@ace-de/ui-components/selector-inputs';
import * as sarjActionTypes from '../sarjActionTypes';
import config from '../../config';

const SARJDetailsPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sarj_details_panel');
    const {serviceAssignment, submitSARJDetailsForm, searchSARJDestinationGeolocation} = props;
    const [returnJourneyData, setReturnJourneyData] = useState(serviceAssignment);
    const [newReturnJourneyDestination, setNewReturnJourneyDestination] = useState(null);
    const currentReturnJourneyAddress = useRef('');
    const lasttReturnJourneyDestinationSearchQuery = useRef('');
    const isSARJDetailsFormTouched = useRef(false);
    const [ageOfKids, setAgeOfKids] = useState(serviceAssignment.ageOfKids.length
        ? (serviceAssignment.ageOfKids.length < serviceAssignment.numberOfKids
            ? serviceAssignment.ageOfKids
                .concat(new Array(serviceAssignment.numberOfKids - serviceAssignment.ageOfKids.length)
                    .fill('', 0))
            : serviceAssignment.ageOfKids)
        : (serviceAssignment.numberOfKids
            ? new Array(serviceAssignment.numberOfKids).fill('', 0)
            : []));

    const searchSARJDestionationGeolocationDebounced = useMemo(
        () => debounce(searchSARJDestinationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSARJDestinationGeolocation],
    );

    useEffect(() => {
        setReturnJourneyData(prevState => ({
            ...prevState,
            callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
        }));
    }, [serviceAssignment.callbackPhoneNo]);

    useEffect(() => {
        if (!serviceAssignment) return;

        const {destination} = serviceAssignment;
        if (destination) {
            setNewReturnJourneyDestination(destination);
        }
    }, [serviceAssignment]);

    // TODO temporary solution
    // NOTE: triggering update of text user entered
    useEffect(() => {
        setReturnJourneyData(prevData => ({
            ...prevData,
            additionalInformationText: serviceAssignment.additionalInformationText,
        }));
    }, [serviceAssignment.additionalInformationText]);

    const handleOnSubmit = useCallback(formValues => {
        const serviceAssignmentData = {
            ...formValues,
            ageOfKids: ageOfKids.filter(age => age !== ''),
            destination: newReturnJourneyDestination,
        };

        submitSARJDetailsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        newReturnJourneyDestination,
        submitSARJDetailsForm,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        ageOfKids,
    ]);

    const updateAgeOfKidsData = (numberOfKids, ageOfKidsValues) => {
        const currentAgeOfKidsValues = numberOfKids
            ? (Object.keys(ageOfKidsValues).length
                ? Object.keys(ageOfKidsValues).map(key => ageOfKidsValues[key])
                    .slice(0, returnJourneyData?.numberOfKids || serviceAssignment.numberOfKids)
                : ageOfKids)
            : [];

        if (numberOfKids > currentAgeOfKidsValues.length) {
            setAgeOfKids([...currentAgeOfKidsValues, '']);
            return;
        }
        if (numberOfKids < currentAgeOfKidsValues.length) {
            const updatedKidsArray = [...currentAgeOfKidsValues];
            updatedKidsArray.pop();
            setAgeOfKids(updatedKidsArray);
            return;
        }
        setAgeOfKids(currentAgeOfKidsValues);
    };

    const handleOnChange = formValues => {
        if (!isSARJDetailsFormTouched.current) isSARJDetailsFormTouched.current = true;
        updateAgeOfKidsData(formValues.numberOfKids, formValues.ageOfKids);
        setReturnJourneyData({
            ...formValues,
            idIsAvailable: !!formValues['idIsAvailable'],
            passportIsAvailable: !!formValues['passportIsAvailable'],
        });
    };

    const handleReturnJourneyDestinationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentReturnJourneyAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSARJDestionationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lasttReturnJourneyDestinationSearchQuery.current = searchQueryString;
        }
    };

    const handleReturnJourneyDestinationCandidateSelect = destinationCandidate => {
        currentReturnJourneyAddress.current = destinationCandidate.formattedAddress;
        setNewReturnJourneyDestination(destinationCandidate);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!returnJourneyData || !isSARJDetailsFormTouched.current) {
            completeRouteUnmountSideEffect({
                caller: sarjActionTypes.SUBMIT_SARJ_DETAILS_FORM,
            });
            return;
        }

        handleOnSubmit(returnJourneyData);
    }, [returnJourneyData, handleOnSubmit]);

    const {destinationCandidates, destinationSearchQuery} = serviceAssignment;

    return (
        <Panel
            title={translateTab('panel_title.details')}
        >
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-bold',
                    'global!ace-u-margin--bottom-24',
                ])}
            >
                {translateTab('text.travel_data')}
            </p>
            <Form name="sarjDetailsForm" onChange={handleOnChange}>
                {formValues => {
                    return (
                        <Fragment>
                            <div className={cx([
                                'global!ace-u-grid',
                                'global!ace-u-margin--bottom-48',
                            ])}
                            >
                                <SelectField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="transportationTypeReturnJourney"
                                    value={efReturnJourneyTransportationTypes[
                                        returnJourneyData.transportationTypeReturnJourney
                                    ]}
                                    label={translateTab('select_field_label.transportation_type')}
                                    placeholder={translateTab('select_field_placeholder.please_select')}
                                >
                                    {Object.values(efReturnJourneyTransportationTypes)
                                        .map((transportationType, idx) => {
                                            return (
                                                <Option
                                                    key={transportationType}
                                                    name={`option${idx}`}
                                                    value={transportationType}
                                                >
                                                    {translate(`global.sarj_transportation_type.${transportationType.toLowerCase()}`)}
                                                </Option>
                                            );
                                        })
                                    }
                                </SelectField>
                                <DateField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="departureDate"
                                    label={translateTab('date_field_label.departure_date')}
                                    value={returnJourneyData.departureDate}
                                    placeholder={translateTab('date_field_placeholder.please_enter')}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                    format="DD.MM.YYYY"
                                />
                                <AutosuggestField
                                    label={translateTab('input_label.destination')}
                                    name="destinationSearchQuery"
                                    value={newReturnJourneyDestination?.formattedAddress || ''}
                                    onChange={handleReturnJourneyDestinationSearchQueryChange}
                                    onOptionSelect={handleReturnJourneyDestinationCandidateSelect}
                                    optionValueSelector={destinationCandidate => {
                                        return destinationCandidate.formattedAddress;
                                    }}
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    placeholder={translateTab('input_placeholder.please_enter')}
                                    icon={searchIcon}
                                >
                                    {(formValues['destinationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                      && destinationSearchQuery === lasttReturnJourneyDestinationSearchQuery.current
                                        ? destinationCandidates
                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((destinationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`return-journey-destination-candidate-${index}`}
                                                        value={destinationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {destinationCandidate.formattedAddress}
                                                    </Option>
                                                );
                                            }) : null}
                                </AutosuggestField>
                                <NumberInputField
                                    className={cx('global!ace-u-grid-column--span-2')}
                                    name="amountOfLuggage"
                                    value={returnJourneyData.amountOfLuggage}
                                    label={translateTab('input_label.amount_of_luggage')}
                                    // allow only integers
                                    min={0}
                                    areOnlyIntegersAllowed={true}
                                />
                            </div>
                            <Divider />
                            <div>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--32-0-24',
                                    ])}
                                >
                                    {translateTab('text.personal_data')}
                                </p>
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    <div className={cx('global!ace-u-grid-column--span-4')}>
                                        <InputCounter
                                            name="numberOfAdults"
                                            label={translateTab('input_counter_label.adults')}
                                            value={returnJourneyData.numberOfAdults}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid-column--span-4',
                                            'global!ace-u-margin--left-24',
                                        ])}
                                    >
                                        <InputCounter
                                            name="numberOfKids"
                                            label={translateTab('input_counter_label.children')}
                                            value={returnJourneyData.numberOfKids}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--wrap',
                                                'global!ace-u-flex--justify-space-between',
                                            ], {
                                                'global!ace-u-margin--bottom-48': ageOfKids.length,
                                            })}
                                        >
                                            <Form name="ageOfKids">
                                                {ageOfKids.map((age, idx) => (
                                                    <SelectField
                                                        key={idx}
                                                        name={`ageOfKids${idx}`}
                                                        label={`${translateTab('select_field_label.age_of_kid')} ${idx + 1}`}
                                                        value={age}
                                                        className={cx([
                                                            'global!ace-u-margin--top-8',
                                                            'global!ace-u-flex--basis-45',
                                                        ])}
                                                    >
                                                        {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                            <Option
                                                                key={idx}
                                                                name={`age-of-kid-option-${idx}`}
                                                                value={idx}
                                                            >
                                                                {efChildrenAgeTypes[ageOption]}
                                                            </Option>
                                                        ))}
                                                    </SelectField>
                                                ))}
                                            </Form>
                                        </div>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid-column--span-4',
                                            'global!ace-u-margin--left-24',
                                        ])}
                                    >
                                        <InputCounter
                                            name="numberOfPets"
                                            label={translateTab('input_counter_label.animals')}
                                            value={returnJourneyData.numberOfPets}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                >
                                    {translateTab('text.additional_information')}
                                </p>
                                <div className={cx('global!ace-u-grid')}>
                                    <InputPhoneNumberField
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="callbackPhoneNo"
                                        value={returnJourneyData.callbackPhoneNo || ''}
                                        label={translateTab('input_label.callback_phone_no')}
                                    />
                                    <InputField
                                        className={cx('global!ace-u-grid-column--span-9')}
                                        name="additionalInformationText"
                                        value={returnJourneyData.additionalInformationText}
                                        label={translateTab('input_label.other_comments')}
                                        placeholder={translateTab('input_placeholder.additional_request_text')}
                                    />
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--align-flex-start',
                                        'global!ace-u-margin--top-48',
                                    ])}
                                >
                                    <Checkbox
                                        name="idIsAvailable"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        isSelected={returnJourneyData.idIsAvailable}
                                    >
                                        {translateTab('checkbox_label.identity_card')}
                                    </Checkbox>
                                    <Checkbox
                                        name="passportIsAvailable"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        isSelected={returnJourneyData.passportIsAvailable}
                                    >
                                        {translateTab('checkbox_label.passport')}
                                    </Checkbox>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Panel>
    );
};

SARJDetailsPanel.propTypes = {
    searchSARJDestinationGeolocation: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    submitSARJDetailsForm: PropTypes.func.isRequired,
};

SARJDetailsPanel.defaultProps = {
    serviceAssignment: {},
};

const mapDispatchToProps = dispatch => ({
    searchSARJDestinationGeolocation: payload => dispatch({
        type: sarjActionTypes.SEARCH_SARJ_DESTINATION_GEOLOCATION,
        payload,
    }),

    submitSARJDetailsForm: payload => dispatch({
        type: sarjActionTypes.SUBMIT_SARJ_DETAILS_FORM,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(SARJDetailsPanel));
