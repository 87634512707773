import {select, fork, take, put} from 'redux-saga/effects';
import {pascalCase} from 'change-case';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';
import getMemberServiceCaseCounts from './getMemberServiceCaseCounts';

/**
 * Search members
 */
const searchMembers = function* searchMembers({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    yield put({
        type: memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID,
        payload: {areSearchResultsValid: false},
    });

    const {searchQueryParams} = payload;
    yield fork(
        fetchRequest,
        memberActionTypes.SEARCH_MEMBERS_REQUEST,
        membersService.searchMembers,
        {
            searchQueryParams,
            searchByMembershipTypes: Object.keys(ampMembershipTypes).map(ampMembershipType => {
                return {
                    key: pascalCase(ampMembershipType),
                    value: ampMembershipTypes[ampMembershipType],
                };
            }),
        },
    );

    const searchMembersResponseAction = yield take([
        memberActionTypes.SEARCH_MEMBERS_REQUEST_SUCCEEDED,
        memberActionTypes.SEARCH_MEMBERS_REQUEST_FAILED,
    ]);

    // in case of an error: clear search results, set an error flag and mark it as ready
    if (searchMembersResponseAction.error) {
        yield put({
            type: memberActionTypes.STORE_MEMBER_SEARCH_RESULTS,
            payload: {
                memberSearchResults: [],
                memberSearchResultsCount: 0,
                memberSearchResultsPrivate: [],
                memberSearchResultsCountPrivate: 0,
                memberSearchResultsCorporate: [],
                memberSearchResultsCountCorporate: 0,
            },
        });

        yield put({
            type: memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID,
            payload: {areSearchResultsValid: true},
        });

        return;
    }

    const {response} = searchMembersResponseAction.payload;
    let {
        results: memberSearchResults,
        resultsPrivate: memberSearchResultsPrivate,
        resultsCorporate: memberSearchResultsCorporate,
    } = response;
    const {
        totalCount: memberSearchResultsCount,
        totalCountPrivate: memberSearchResultsCountPrivate,
        totalCountCorporate: memberSearchResultsCountCorporate,
    } = response;

    const memberIds = [...new Set([
        ...memberSearchResults.map(memberSearchResult => memberSearchResult.id),
        ...memberSearchResultsPrivate.map(memberSearchResult => memberSearchResult.id),
        ...memberSearchResultsCorporate.map(memberSearchResult => memberSearchResult.id),
    ])];

    const serviceCaseCountForMembers = yield* getMemberServiceCaseCounts(memberIds);
    memberSearchResults = memberSearchResults.map(memberSearchResult => {
        const memberCaseCount = serviceCaseCountForMembers.find(serviceCaseCountForMember => {
            return memberSearchResult.id === serviceCaseCountForMember.memberId;
        });

        return {
            ...memberSearchResult,
            serviceCaseCount: memberCaseCount ? memberCaseCount.caseCount : 0,
        };
    });
    memberSearchResultsPrivate = memberSearchResultsPrivate.map(memberSearchResult => {
        const memberCaseCount = serviceCaseCountForMembers.find(serviceCaseCountForMember => {
            return memberSearchResult.id === serviceCaseCountForMember.memberId;
        });

        return {
            ...memberSearchResult,
            serviceCaseCount: memberCaseCount ? memberCaseCount.caseCount : 0,
        };
    });
    memberSearchResultsCorporate = memberSearchResultsCorporate.map(memberSearchResult => {
        const memberCaseCount = serviceCaseCountForMembers.find(serviceCaseCountForMember => {
            return memberSearchResult.id === serviceCaseCountForMember.memberId;
        });

        return {
            ...memberSearchResult,
            serviceCaseCount: memberCaseCount ? memberCaseCount.caseCount : 0,
        };
    });

    yield put({
        type: memberActionTypes.STORE_MEMBER_SEARCH_RESULTS,
        payload: {
            memberSearchResults,
            memberSearchResultsCount,
            memberSearchResultsPrivate,
            memberSearchResultsCountPrivate,
            memberSearchResultsCorporate,
            memberSearchResultsCountCorporate,
        },
    });

    yield put({
        type: memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID,
        payload: {areSearchResultsValid: true},
    });
};

export default searchMembers;
