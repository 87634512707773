/**
 * ECS layouts
 */
export const ecsLayouts = {
    DASHBOARD: 'DASHBOARD',
    DASHBOARD_NAVIGATION: 'DASHBOARD_NAVIGATION',
};

/**
 * ECS dashboard layouts
 */
export const ecsDashboardLayoutTypes = {
    ECS_TEAM_LEAD_DASHBOARD_LAYOUT: 'ECS_TEAM_LEAD_DASHBOARD_LAYOUT',
    ECS_DISPATCHER_1_DASHBOARD_LAYOUT: 'ECS_DISPATCHER_1_DASHBOARD_LAYOUT',
    ECS_DISPATCHER_2_DASHBOARD_LAYOUT: 'ECS_DISPATCHER_2_DASHBOARD_LAYOUT',
    ECS_DISPATCHER_3_DASHBOARD_LAYOUT: 'ECS_DISPATCHER_3_DASHBOARD_LAYOUT',
    ECS_DISPO_DASHBOARD_LAYOUT: 'ECS_DISPO_DASHBOARD_LAYOUT',
    ECS_INBOX_DASHBOARD_LAYOUT: 'ECS_INBOX_DASHBOARD_LAYOUT',
    ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_LAYOUT: 'ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_LAYOUT',
    ECS_DEFAULT_DASHBOARD_LAYOUT: 'ECS_DEFAULT_DASHBOARD_LAYOUT',
};

/**
 * ECS dashboard navigation layouts
 */
export const ecsDashboardNavigationLayoutTypes = {
    ECS_TEAM_LEAD_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_TEAM_LEAD_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_DISPATCHER_1_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_DISPATCHER_1_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_DISPATCHER_2_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_DISPATCHER_2_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_DISPATCHER_3_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_DISPATCHER_3_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_DISPO_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_DISPO_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_INBOX_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_INBOX_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_NAVIGATION_LAYOUT',
    ECS_DEFAULT_DASHBOARD_NAVIGATION_LAYOUT: 'ECS_VIEWER_DASHBOARD_NAVIGATION_LAYOUT',
};
