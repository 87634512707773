import {take, fork, select, call, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Resend service assignment flow (uses modal)
 */
const resendServiceAssignmentFlow = function* resendServiceAssignmentFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW);
            payloadData = payload;
        }

        if (payloadData) {
            const {attachmentsToSendWithAssignment} = payloadData;

            yield* openModal(modalIds.RESEND_SERVICE_ASSIGNMENT);

            const chosenModalOption = yield take([
                serviceAssignmentActionTypes.CONFIRM_RESEND_SERVICE_ASSIGNMENT,
                serviceAssignmentActionTypes.DECLINE_RESEND_SERVICE_ASSIGNMENT,
            ]);

            if (chosenModalOption
                && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_RESEND_SERVICE_ASSIGNMENT) {
                const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentData} = chosenModalOption.payload;

                yield call(sendAttachmentsWithAssignmentFlow, {
                    caller: serviceAssignmentActionTypes.INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW,
                    attachmentsToSendWithAssignment,
                    serviceCaseId,
                });

                yield fork(
                    fetchRequest,
                    serviceAssignmentActionTypes.RESEND_SERVICE_ASSIGNMENT_REQUEST,
                    ecsFlowService.resendServiceAssignment,
                    {
                        serviceCaseId,
                        serviceAssignmentLineNo,
                        serviceAssignmentData,
                    },
                );

                const responseAction = yield take([
                    serviceAssignmentActionTypes.RESEND_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED,
                    serviceAssignmentActionTypes.RESEND_SERVICE_ASSIGNMENT_REQUEST_FAILED,
                ]);

                if (!responseAction.error) {
                    yield put({
                        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
                        payload: {
                            hasToResetAttachmentSelection: true,
                        },
                    });
                }

                if (responseAction.error && responseAction.payload.response) {
                    const {status} = responseAction.payload.response;

                    yield put({
                        type: serviceAssignmentActionTypes.SET_ERROR_CODE,
                        payload: {
                            errorCode: status ? `${status}` : '',
                        },
                    });

                    shouldWaitForAction = false;
                    continue;
                }
            }

            yield* closeModal(modalIds.RESEND_SERVICE_ASSIGNMENT);

            yield put({
                type: serviceAssignmentActionTypes.RESET_ERROR_CODE,
            });

            shouldWaitForAction = true;
        }
    }
};

export default resendServiceAssignmentFlow;
