import {take, fork, put, select, actionChannel, all} from 'redux-saga/effects';
import {buffers} from 'redux-saga';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import getServiceAssignmentClass from '../getServiceAssignmentClass';

/**
 * Batch update ServiceAssignment flow
 */
const batchUpdateServiceAssignmentFlow = function* batchUpdateServiceAssignmentFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        let nextBatchUpdateServiceAssignmentAction = yield take(
            serviceAssignmentActionTypes.BATCH_UPDATE_SERVICE_ASSIGNMENT,
        );

        const {payload} = nextBatchUpdateServiceAssignmentAction;
        const {serviceAssignmentLineNo, serviceCaseId} = payload;
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE,
            payload: {serviceAssignmentId, persistenceState: persistenceStates.PENDING},
        });
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.PENDING},
        });

        const expandingBuffer = buffers.expanding(10);
        const batchUpdateChannel = yield actionChannel(
            serviceAssignmentActionTypes.BATCH_UPDATE_SERVICE_ASSIGNMENT,
            expandingBuffer,
        );
        const completedBatchUpdateActions = [];
        while (true) {
            const {payload, meta} = nextBatchUpdateServiceAssignmentAction;
            const {serviceAssignmentLineNo, serviceCaseId, isStatusUpdate,
                isSubStatusUpdate, isPowerOfAttorneyUpdate} = payload;
            let batchUpdateResponseAction = {};

            if (!isStatusUpdate && !isSubStatusUpdate && !isPowerOfAttorneyUpdate) {
                const {assignmentType, serviceAssignmentData} = payload;

                // https://computerrock.atlassian.net/browse/ACEECS-6149
                // In order to fix this issue, we have to send 'serviceSelectionType' as part of the
                // ServiceAssignmentVehiclePatchDTO, even if the 'serviceSelectionType' field is not part
                // of the ServiceAssignmentVehicle entity -> BE will consume this and update it
                // on the ServiceCaseVehicle entity
                const serviceAssignmentPatchDTO = getServiceAssignmentClass(assignmentType)
                    .objectToPatchDTO(serviceAssignmentData);
                if (serviceAssignmentData['serviceSelectionType']) {
                    serviceAssignmentPatchDTO['serviceSelectionType'] = {
                        value: serviceAssignmentData['serviceSelectionType'],
                    };
                }

                yield fork(
                    fetchRequest,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_REQUEST,
                    ecsFlowService.updateServiceAssignment,
                    {
                        assignmentType,
                        serviceAssignmentLineNo,
                        serviceCaseId,
                        serviceAssignmentPatchDTO,
                    },
                );

                batchUpdateResponseAction = yield take([
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_REQUEST_FAILED,
                ]);
            }

            if (isStatusUpdate) {
                const {serviceAssignmentStatusData} = payload;

                yield fork(
                    fetchRequest,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST,
                    ecsFlowService.updateServiceAssignmentStatus,
                    {
                        serviceAssignmentLineNo,
                        serviceCaseId,
                        serviceAssignmentStatusData,
                    },
                    // metadata: caller
                    {caller: meta?.caller, isBatch: true},
                );

                batchUpdateResponseAction = yield take([
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_FAILED,
                ]);
            }

            if (isSubStatusUpdate) {
                const {serviceAssignmentSubStatusData} = payload;

                yield fork(
                    fetchRequest,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST,
                    ecsFlowService.updateServiceAssignmentSubStatus,
                    {
                        serviceAssignmentLineNo,
                        serviceCaseId,
                        serviceAssignmentSubStatusData,
                    },
                );

                batchUpdateResponseAction = yield take([
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_SUCCEEDED,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_FAILED,
                ]);
            }

            if (isPowerOfAttorneyUpdate) {
                const {serviceAssignmentPowerOfAttorneyData} = payload;

                yield fork(
                    fetchRequest,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST,
                    ecsFlowService.updateServiceAssignmentPowerOfAttorney,
                    {
                        serviceAssignmentLineNo,
                        serviceCaseId,
                        serviceAssignmentPowerOfAttorneyData,
                    },
                );

                batchUpdateResponseAction = yield take([
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_SUCCEEDED,
                    serviceAssignmentActionTypes.UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_FAILED,
                ]);
            }

            const {payload: responsePayload, error: responseError} = batchUpdateResponseAction;
            completedBatchUpdateActions.push({
                type: serviceAssignmentActionTypes.BATCH_UPDATE_SERVICE_ASSIGNMENT_COMPLETED,
                payload: responsePayload,
                error: responseError,
                meta,
            });

            // exit if no more update requests, or continue processing
            if (expandingBuffer.isEmpty()) break;
            nextBatchUpdateServiceAssignmentAction = yield take(batchUpdateChannel);
        }

        yield fork(
            fetchRequest,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_REQUEST,
            ecsFlowService.getServiceCaseAssignment,
            {serviceAssignmentLineNo, serviceCaseId},
        );

        const fetchServiceAssignmentResponseAction = yield take([
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_REQUEST_FAILED,
        ]);

        if (!fetchServiceAssignmentResponseAction.error) {
            const {response} = fetchServiceAssignmentResponseAction.payload;
            const {serviceAssignmentDTO} = response;

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs: [serviceAssignmentDTO]},
            });
        }

        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE,
            payload: {serviceAssignmentId, persistenceState: persistenceStates.READY},
        });
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.READY},
        });

        // signal completed batch updates
        yield all(completedBatchUpdateActions
            .map(batchUpdateAction => put(batchUpdateAction)));
    }
};

export default batchUpdateServiceAssignmentFlow;
