import {take, fork, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';
import * as serviceAssignmentHelpers from '../../service-assignments/sagas/serviceAssignmentHelpers';
import * as savaActionTypes from '../savaActionTypes';

/**
 * Update SAVA service location flow
 */
const updateSAVAServiceLocationFlow = function* updateSAVAServiceLocationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_FORM);
        const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentData} = payload;

        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const serviceAssignment = yield select(state => {
            return state.serviceAssignments.serviceAssignments[serviceAssignmentId];
        });
        yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);
        yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);

        const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);
        if (serviceCase.member?.personalDetails?.coordinates) {
            const memberResidenceLocation = serviceCase.member.personalDetails.coordinates;
            const {serviceLocation} = serviceAssignmentData;

            yield fork(
                fetchRequest,
                savaActionTypes.FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: serviceLocation.longitude,
                        latitude: serviceLocation.latitude,
                    },
                    destination: {
                        longitude: memberResidenceLocation.longitude,
                        latitude: memberResidenceLocation.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                },
            );

            const routeResponseAction = yield take([
                savaActionTypes.FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                savaActionTypes.FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                serviceAssignmentData['distanceResidenceToServiceLocation'] = arcGISRouteDTO.totalKilometers;
            }
        }

        yield* updateServiceAssignment({
            caller: savaActionTypes.SUBMIT_SAVA_SERVICE_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceAssignmentData: {
                ...serviceAssignmentData,
                ...(serviceAssignment?.assignmentStatus === efServiceAssignmentStatusTypes.NEW && {
                    towingDestination: null,
                    acePartner: null,
                    assignmentText: null,
                }),
            },
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceAssignmentData.serviceLocation});
    }
};

export default updateSAVAServiceLocationFlow;
