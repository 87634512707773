import {all, fork} from 'redux-saga/effects';
import provideKeycloakTokenToServicesFlow from './sagas/provideKeycloakTokenToServicesFlow';
import provideArcGISTokenToServicesFlow from './sagas/provideArcGISTokenToServicesFlow';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideKeycloakTokenToServicesFlow),
        fork(provideArcGISTokenToServicesFlow),
    ]);
};

export default applicationWatcher;
