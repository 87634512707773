import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {Link, useHistory, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceCaseStatusTypes, efServiceCaseContactChannelTypes} from '@ace-de/eua-entity-types';
import {useStyles, linkIcon, InteractiveIcon, Panel, NoResultsBlock, Icon, findCaseIcon, Pill, warningAlertIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as dashboardSelectors from '../dashboardSelectors';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import routePaths from '../../routePaths';
import TableLoaderSpinner from '../../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const MemberAppPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('dashboard_screen');
    const {memberAppServiceCases, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const history = useHistory();

    const openServiceCaseScreen = serviceCase => {
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceCase.id,
        }, {
            state: {previousPath: history.location.pathname},
        }));
    };

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateTab('member_app_panel.title')}
            actions={(
                <Link
                    to={resolveRoute(
                        routePaths.SERVICE_CASE_SEARCH,
                        {},
                        {search: prepareSearchQueryParams({
                            contactChannel: efServiceCaseContactChannelTypes.APP,
                            caseStatus: efServiceCaseStatusTypes.NEW,
                            sort: 'createdAt,desc',
                        })},
                    )}
                    className={cx([
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--variant-highlight',
                        'global!ace-u-typography--color-secondary',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <InteractiveIcon
                            icon={linkIcon}
                            className={cx('ace-c-interactive-icon--reverse')}
                        >
                            {translateTab('member_app_panel_link_label.all')}
                        </InteractiveIcon>
                    </div>
                </Link>
            )}
        >
            <Table qaIdent="member-app-service-cases">
                {memberAppServiceCases.length === 0 && !isInProgress && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab('member_app_panel_table_no_results.message')}
                            description={translateTab('member_app_panel_table_no_results.description')}
                        />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="sc-created-at">
                            {translateTab('member_app_panel_table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-status">
                            {translateTab('member_app_panel_table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-id">
                            {translateTab('member_app_panel_table_header.case_id')}
                        </TableCell>
                        <TableCell qaIdentPart="member-personal-details-last-name">
                            {translateTab('member_app_panel_table_header.member_last_name')}
                        </TableCell>
                        <TableCell qaIdentPart="damage-location-address">
                            {translateTab('member_app_panel_table_header.damage_location')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-licence-plate-number">
                            {translateTab('member_app_panel_table_header.license_plate')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberAppServiceCases.length > 0 && !isInProgress
                    && memberAppServiceCases.map(serviceCase => {
                        const {member, vehicle, damage} = serviceCase;
                        return (
                            <TableRow
                                key={serviceCase.id}
                                onClick={() => openServiceCaseScreen(serviceCase)}
                                qaIdentPart={serviceCase.id}
                            >
                                <TableCell
                                    qaIdentPart="sc-created-at"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    <div className={cx([
                                        'global!ace-u-inline-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-flex-start',
                                    ])}
                                    >
                                        {serviceCase.status === efServiceCaseStatusTypes.NEW
                                            ? (
                                                <div>
                                                    <Icon
                                                        icon={warningAlertIcon}
                                                        className={cx([
                                                            'ace-c-icon--m',
                                                            'ace-c-icon--color-warning',
                                                        ])}
                                                    />
                                                </div>
                                            ) : ''}
                                        {serviceCase.createdAt
                                            ? moment(serviceCase.createdAt).format('DD.MM.YYYY HH:mm') : ''}
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-status"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                                        <Pill
                                            type={serviceCase.status === efServiceCaseStatusTypes.NEW
                                                ? 'positive' : 'pending'}
                                        >
                                            {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                                        </Pill>
                                    )}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-id"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.id}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="member-personal-details-last-name"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {member ? member.personalDetails.surname : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="damage-location-address"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {damage && damage.location
                                        ? `${damage.location.postCode} ${damage.location.city}`
                                        : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="vehicle-licence-plate-number"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

MemberAppPanel.propTypes = {
    memberAppServiceCases: PropTypes.array,
    requestState: PropTypes.object,
};

MemberAppPanel.defaultProps = {
    memberAppServiceCases: [],
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST);
    return {
        memberAppServiceCases: dashboardSelectors.getMemberAppServiceCases(state),
        requestState: createRequestStateSelector(state, props),
    };
};

export default connect(mapStateToProps)(MemberAppPanel);
