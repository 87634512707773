import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import {Panel, useStyles, ButtonPrimary, NoResultsBlock, LabelWithIcon, otherIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, plusIcon, findCaseIcon, InteractiveIcon, editIcon, noServiceIcon, waitingIcon, questionmarkIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import * as contractPartnersSelectors from './contractPartnerSelectors';
import config from '../config';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';
import ecsAccessControl from '../ecsAccessControl';

const categoryIconMap = {
    [apmTemporaryRestrictionCategoryTypes.RESTRICTED_SERVICES]: questionmarkIcon,
    [apmTemporaryRestrictionCategoryTypes.TEMPORARILY_CLOSED]: noServiceIcon,
    [apmTemporaryRestrictionCategoryTypes.WAITING_TIME]: waitingIcon,
    [apmTemporaryRestrictionCategoryTypes.OTHER]: otherIcon,
};

const ContractPartnerTemporaryRestrictionsTab = props => {
    const {cx} = useStyles();
    const {contractPartner, activeTemporaryRestrictions} = props;
    const {initiateCreateTemporaryRestrictionFlow, initiateEditTemporaryRestrictionFlow} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_temporary_restrictions_tab');

    const isTemporaryRestrictionCreationDisabled = !ecsAccessControl.grantFeatureAccess(
        ecsFeatures.TEMPORARY_RESTRICTION,
        ecsFeatureActions.CREATE,
    );
    const isTemporaryRestrictionEditDisabled = !ecsAccessControl.grantFeatureAccess(
        ecsFeatures.TEMPORARY_RESTRICTION,
        ecsFeatureActions.UPDATE,
    );

    const createTemporaryRestriction = () => {
        if (isTemporaryRestrictionCreationDisabled) return;
        initiateCreateTemporaryRestrictionFlow({contractPartner});
    };

    const handleEditTemporaryRestriction = temporaryRestrictionId => {
        if (isTemporaryRestrictionEditDisabled) return;
        initiateEditTemporaryRestrictionFlow({
            contractPartner,
            temporaryRestrictionId,
        });
    };

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateTab('panel_header.active_and_upcoming_restrictions')}
            actions={!config.IS_FALLBACK_SYSTEM && (
                <ButtonPrimary
                    onClick={createTemporaryRestriction}
                    isDisabled={isTemporaryRestrictionCreationDisabled}
                >
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    {translateTab('button_label.create_temporary_restriction')}
                </ButtonPrimary>
            )}
        >
            <Table qaIdent="cp-active-temporary-restrictions">
                {activeTemporaryRestrictions.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab(`no_results.message`)}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="tr-start-date-time">
                            {translateTab('table_header.restriction_start_datetime')}
                        </TableCell>
                        <TableCell qaIdentPart="tr-end-date-time">
                            {translateTab('table_header.restriction_end_datetime')}
                        </TableCell>
                        <TableCell qaIdentPart="tr-category">
                            {translateTab('table_header.restriction_category')}
                        </TableCell>
                        <TableCell colSpan={3} qaIdentPart="tr-description">
                            {translateTab('table_header.description')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="tr-creator-name">
                            {translateTab('table_header.creator_name')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeTemporaryRestrictions.length > 0
                    && activeTemporaryRestrictions.map(temporaryRestriction => {
                        return (
                            <TableRow
                                key={temporaryRestriction.id}
                                qaIdentPart={temporaryRestriction.id}
                            >
                                <TableCell
                                    qaIdentPart="tr-start-date-time"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    {temporaryRestriction.restrictionStartDateTime
                                        ? moment(temporaryRestriction.restrictionStartDateTime).format('DD.MM.YYYY HH:mm')
                                        : ''
                                     }
                                </TableCell>
                                <TableCell
                                    qaIdentPart="tr-end-date-time"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    {temporaryRestriction.restrictionEndDateTime
                                        ? moment(temporaryRestriction.restrictionEndDateTime).format('DD.MM.YYYY HH:mm')
                                        : ''
                                     }
                                </TableCell>
                                <TableCell
                                    qaIdentPart="tr-category"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    <LabelWithIcon
                                        className={cx('ace-c-label-with-icon--icon-reverse')}
                                        label={translate(
                                            `global.temporary_restriction_category.${snakeCase(
                                                temporaryRestriction.restrictionCategory,
                                            )}`,
                                        )}
                                    >
                                        <Icon
                                            icon={categoryIconMap[temporaryRestriction.restrictionCategory]}
                                        />
                                    </LabelWithIcon>
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    qaIdentPart="tr-description"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    {temporaryRestriction.description}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="tr-creator-name"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    {temporaryRestriction.creatorName}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="tr-edit"
                                    qaIdentPartPostfix={temporaryRestriction.id}
                                >
                                    {!config.IS_FALLBACK_SYSTEM && (
                                        <InteractiveIcon
                                            icon={editIcon}
                                            onClick={() => handleEditTemporaryRestriction(temporaryRestriction.id)}
                                            isDisabled={isTemporaryRestrictionEditDisabled}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ContractPartnerTemporaryRestrictionsTab.propTypes = {
    initiateCreateTemporaryRestrictionFlow: PropTypes.func.isRequired,
    initiateEditTemporaryRestrictionFlow: PropTypes.func.isRequired,
    contractPartner: PropTypes.object,
    activeTemporaryRestrictions: PropTypes.array,
};

ContractPartnerTemporaryRestrictionsTab.defaultProps = {
    contractPartner: null,
    activeTemporaryRestrictions: [],
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnersSelectors.createContractPartnerSelector();
    const activeTemporaryRestrictionsSelector = contractPartnersSelectors
        .createActiveAndUpcomingTemporaryRestrictionsSelector();

    return {
        contractPartner: getContractPartner(state, props),
        activeTemporaryRestrictions: activeTemporaryRestrictionsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateTemporaryRestrictionFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW,
        payload,
    }),
    initiateEditTemporaryRestrictionFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerTemporaryRestrictionsTab));
