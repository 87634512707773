import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as samcActionTypes from '../samcActionTypes';

/**
 * Search damage location geolocation
 */
const searchSAMCDamageLocationGeolocation = function* searchSAMCDamageLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId} = payload;
    yield fork(
        fetchRequest,
        samcActionTypes.SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const searchDamageLocationGeolocation = yield take([
        samcActionTypes.SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        samcActionTypes.SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchDamageLocationGeolocation.error) {
        const {response} = searchDamageLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: samcActionTypes.SET_SAMC_DAMAGE_LOCATION_CANDIDATES,
            payload: {
                locationKey: 'damageLocation',
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchSAMCDamageLocationGeolocation;
