import {eventChannel} from 'redux-saga';
import {select, take, call, put} from 'redux-saga/effects';
import * as savaActionTypes from '../savaActionTypes';

const onSAVAMapClickSelectTowingDestination = function* onSAVAMapClickSelectTowingDestination(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');

    const savaTowingDestinationsLayer = yield call(arcGISMap.getLayer, 'sava-towing-destinations');
    const savaTowingDestinationRoutesLayer = yield call(arcGISMap.getLayer, 'sava-towing-destination-routes');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (savaTowingDestinationsLayer) {
            const unsubscribeClickListener = savaTowingDestinationsLayer.on('feature-select', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: towingDestinationFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (towingDestinationFeatureDTO && !towingDestinationFeatureDTO.isSelected) {
            const selectedTowingDestinationLocationId = towingDestinationFeatureDTO['locationId'];

            yield call(savaTowingDestinationRoutesLayer.selectFeatureByAttribute, {
                where: `locationId = '${selectedTowingDestinationLocationId}'`,
            });

            yield put({
                type: savaActionTypes.SET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID,
                payload: {
                    serviceAssignmentId: `${serviceCaseId}-${serviceAssignmentLineNo}`,
                    selectedTowingDestinationLocationId: selectedTowingDestinationLocationId,
                },
            });
        }
    }
};

export default onSAVAMapClickSelectTowingDestination;
