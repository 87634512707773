import {all, fork, takeLatest} from 'redux-saga/effects';
import createServiceAssignmentVehicleAdditionalFlow from './sagas/createServiceAssignmentVehicleAdditionalFlow';
import searchSAVAServiceLocationGeolocation from './sagas/searchSAVAServiceLocationGeolocation';
import savaMapServiceLocationWatcher from './sagas/savaMapServiceLocationWatcher';
import updateSAVAServiceLocationFlow from './sagas/updateSAVAServiceLocationFlow';
import updateSAVAServiceLocationInfoFlow from './sagas/updateSAVAServiceLocationInfoFlow';
import searchSAVATowingDestinations from './sagas/searchSAVATowingDestinations';
import savaMapTowingDestinationWatcher from './sagas/savaMapTowingDestinationWatcher';
import selectSAVATowingDestinationFlow from './sagas/selectSAVATowingDestinationFlow';
import selectSAVAContractPartnerFlow from './sagas/selectSAVAContractPartnerFlow';
import savaMapContractPartnerWatcher from './sagas/savaMapContractPartnerWatcher';
import updateSAVACommissioningFlow from './sagas/updateSAVACommissioningFlow';
import updateSAVAAssignmentStatus from './sagas/updateSAVAAssignmentStatus';
import * as savaActionTypes from './savaActionTypes';
import searchSAVAContractPartners from './sagas/searchSAVAContractPartners';
import searchSAVAReverseServiceLocationGeolocation from './sagas/searchSAVAReverseDamageLocationGeolocation';
import savaCreateVBAPartnerQMFeedbackDraft from './sagas/savaCreateVBAPartnerQMFeedbackDraft';

/**
 *  SAVA watcher saga
 */
const savaWatcher = function* savaWatcher() {
    yield all([
        fork(createServiceAssignmentVehicleAdditionalFlow),
        takeLatest(savaActionTypes.SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION, searchSAVAServiceLocationGeolocation),
        takeLatest(
            savaActionTypes.SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION,
            searchSAVAReverseServiceLocationGeolocation,
        ),
        fork(savaMapServiceLocationWatcher),
        fork(updateSAVAServiceLocationFlow),
        fork(updateSAVAServiceLocationInfoFlow),
        takeLatest(savaActionTypes.SEARCH_SAVA_TOWING_DESTINATIONS, searchSAVATowingDestinations),
        fork(savaMapTowingDestinationWatcher),
        fork(selectSAVATowingDestinationFlow),
        fork(selectSAVAContractPartnerFlow),
        fork(savaMapContractPartnerWatcher),
        fork(updateSAVACommissioningFlow),
        fork(updateSAVAAssignmentStatus),
        takeLatest(savaActionTypes.SEARCH_SAVA_CONTRACT_PARTNERS, searchSAVAContractPartners),
        fork(savaCreateVBAPartnerQMFeedbackDraft),
    ]);
};

export default savaWatcher;
