import {select, take, fork, put} from 'redux-saga/effects';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sapActionTypes from '../sapActionTypes';

const loadSAPMapPickupToTowingStaticRoute = function* loadSAPMapPickupToTowingStaticRoute({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const {pickupLocation, destination} = serviceAssignment;

    if (!serviceAssignment || !pickupLocation || !destination) return;

    yield fork(
        fetchRequest,
        sapActionTypes.FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST,
        arcGISRESTService.getRoute,
        {
            startingPoint: {
                longitude: pickupLocation.longitude,
                latitude: pickupLocation.latitude,
            },
            destination: {
                longitude: destination.longitude,
                latitude: destination.latitude,
            },
            travelModeType: arcGISTravelModeTypes.TRUCK_TRAVEL_TIME,
            startTime: 'now',
        },
    );

    const routeResponseAction = yield take([
        sapActionTypes.FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED,
        sapActionTypes.FETCH_PICKUP_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED,
    ]);

    let routeToReferentialPoint;

    if (!routeResponseAction.error) {
        const {response} = routeResponseAction.payload;
        const {arcGISRouteDTO} = response;
        routeToReferentialPoint = arcGISRouteDTO;
        const {geometry} = routeToReferentialPoint;

        yield put({
            type: sapActionTypes.STORE_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE,
            payload: {geometry},
        });
    }
};

export default loadSAPMapPickupToTowingStaticRoute;
