import {put, select, take, fork, call} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentHelpers from '../../service-assignments/sagas/serviceAssignmentHelpers';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Search SARC rental car stations
 */
const searchSARCRentalCarStations = function* searchSARCRentalCarStations({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, pickupLocation} = payload;
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    if (!serviceAssignment || !pickupLocation) return;

    yield put({
        type: sarcActionTypes.SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS,
        payload: {serviceAssignmentId, rentalCarStationRecommendationDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-rental-car');
    if (!arcGISMap) return;

    const sarcRentalCarStationsLayer = yield call(arcGISMap.getLayer, 'sarc-rental-car-stations');
    if (!sarcRentalCarStationsLayer) return;

    yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);

    yield fork(
        fetchRequest,
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST,
        sarcRentalCarStationsLayer.filterPointFeaturesByDistance,
        {
            distance: 100,
            referentialPoint: pickupLocation,
            returnRoutes: true,
            featureCount: 10,
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_FAILED,
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);

    if (filterPointFeaturesResponseAction.error) {
        yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
        return;
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    const {featureDTOs: rentalCarStationRecommendationDTOs = []} = response;

    // find candidate rental car station based on distance from damage location
    let nearestRoute;
    let recommendedRentalCarStationDTO;
    rentalCarStationRecommendationDTOs.forEach(rentalCarStationRecommendationDTO => {
        const routeToReferentialPointDTO = rentalCarStationRecommendationDTO['routeToReferentialPoint'];
        if (!routeToReferentialPointDTO) return;

        if (routeToReferentialPointDTO['totalKilometers'] < nearestRoute || !recommendedRentalCarStationDTO) {
            recommendedRentalCarStationDTO = rentalCarStationRecommendationDTO;
            nearestRoute = routeToReferentialPointDTO['totalKilometers'];
        }
    });

    // select features for recommended rental car station
    if (recommendedRentalCarStationDTO) {
        yield call(sarcRentalCarStationsLayer.selectFeatureByAttribute, {
            where: `stationID = '${recommendedRentalCarStationDTO['stationId']}'`,
        });
    }

    yield put({
        type: sarcActionTypes.SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            rentalCarStationRecommendationDTOs,
            recommendedRentalCarStationId: recommendedRentalCarStationDTO
                ? recommendedRentalCarStationDTO['stationId'] : null,
        },
    });
    yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
};

export default searchSARCRentalCarStations;
