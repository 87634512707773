import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

/**
 * Search damage location geolocation
 */
const searchSAVDamageLocationGeolocation = function* searchSAVDamageLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceCaseId, location} = payload;
    yield fork(
        fetchRequest,
        savActionTypes.SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {
            singleLine: searchQueryString,
            ...(!!location && {location}),
            outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region'],
        },
    );

    const searchDamageLocationGeolocation = yield take([
        savActionTypes.SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        savActionTypes.SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchDamageLocationGeolocation.error) {
        const {response} = searchDamageLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.SET_SAV_DAMAGE_LOCATION_CANDIDATES,
            payload: {
                locationKey: 'damageLocation',
                arcGISLocationCandidateDTOs,
                serviceCaseId,
                searchQueryString,
            },
        });
    }
};

export default searchSAVDamageLocationGeolocation;
