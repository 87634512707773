import avis from '../application/assets/images/avis.png';
import enterprise from '../application/assets/images/enterprise.png';
import sixt from '../application/assets/images/sixt.png';
import hertz from '../application/assets/images/hertz.png';
import europcar from '../application/assets/images/europcar.png';
import starcar from '../application/assets/images/starcar.png';

/**
 * List of main rental car service provider's IDs, acePartner.isSpecial=true
 */
const specialACEPartnerIds = {
    AVIS: '1011004608',
    ENTERPRISE: '1013209037',
    SIXT: '1011002036',
    HERTZ: '1011004357',
    EUROPCAR: '1011004128',
    STARCAR: '1016228008',
};

const acePartnerIdToLogoMapping = {
    [specialACEPartnerIds.AVIS]: avis,
    [specialACEPartnerIds.ENTERPRISE]: enterprise,
    [specialACEPartnerIds.SIXT]: sixt,
    [specialACEPartnerIds.HERTZ]: hertz,
    [specialACEPartnerIds.EUROPCAR]: europcar,
    [specialACEPartnerIds.STARCAR]: starcar,
};

export default acePartnerIdToLogoMapping;
