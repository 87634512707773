import {call, select, take, put} from 'redux-saga/effects';
import {resolveRoute, replace, prepareSearchQueryParams} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as sapActionTypes from '../sapActionTypes';
import createServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/createServiceAssignmentQMFeedbackDraft';
import deleteServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/deleteServiceAssignmentQMFeedbackDraft';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import sapScreenTabs from '../sapScreenTabs';

const sapCreateVBAPartnerQMFeedbackDraft = function* sapCreateVBAPartnerQMFeedbackDraft() {
    while (true) {
        const {payload} = yield take(sapActionTypes.INITIATE_SAP_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION);
        const {serviceCaseId, serviceAssignmentLineNo} = payload;
        const {recommendedCPId, contractPartnerRecommendations} = payload;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];

        // if no service assignment, continue
        if (!serviceAssignment) continue;

        yield* openModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId});
        const createQMFeedbackDraftActionType = yield take([
            sapActionTypes.CONFIRM_SAP_QM_NOTES,
            sapActionTypes.DECLINE_SAP_QM_NOTES,
        ]);

        if (createQMFeedbackDraftActionType.type === sapActionTypes.DECLINE_SAP_QM_NOTES) {
            yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
            yield put(replace(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_PICKUP,
                {serviceCaseId, serviceAssignmentLineNo},
                {
                    search: prepareSearchQueryParams({
                        activeTab: sapScreenTabs.SERVICE_PROVIDER,
                        isVBASearch: 'false',
                    }),
                },
            )));
            continue;
        }

        const {qmDraftData} = createQMFeedbackDraftActionType.payload;
        yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});

        // delete previously created QM draft if exists
        if (serviceAssignment?.qmFeedbackDraft) {
            yield call(deleteServiceAssignmentQMFeedbackDraft, {
                serviceAssignmentLineNo,
                serviceCaseId,
                qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft?.id || null,
            });
        }
        if (qmDraftData) {
            yield call(createServiceAssignmentQMFeedbackDraft, {
                qmFeedbackDraftData: {
                    ...qmDraftData,
                    // override contract partner recommendations: use the list of ACTIVE contract partners
                    contractPartnerRecommendations: contractPartnerRecommendations.map(contractPartner => ({
                        id: contractPartner.id,
                        rank: contractPartner.rank,
                        routeToDamageLocation: {
                            totalKilometers: contractPartner.routeToDamageLocation?.totalKilometers,
                            totalTravelTime: contractPartner.routeToDamageLocation?.totalTravelTime,
                        },
                        formattedAddress: contractPartner.location?.formattedAddress,
                    })),
                },
            });
        }
    }
};

export default sapCreateVBAPartnerQMFeedbackDraft;
