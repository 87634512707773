import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter, useRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle} from '@ace-de/ui-components';
import {Icon, memberIcon, linkIcon, closeIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';

const MemberHeader = ({member}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_header');
    const router = useRouter();
    const {personalDetails} = member;

    return (
        <Fragment>
            <ScreenTitle>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={memberIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <div
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {personalDetails.displayName}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {member.membershipType === ampMembershipTypes.PRIVATE
                            ? translateScreen('screen_title.member_private')
                            : translateScreen('screen_title.member_corporate')}
                    </div>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    {member.corporateMembershipNo && (
                        <Link
                            to={resolveRoute(routePaths.MEMBER, {
                                membershipNo: member.corporateMembershipNo,
                            })}
                            className={cx([
                                'global!ace-u-typography--variant-highlight',
                                'global!ace-u-typography--variant-highlight',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            {translateScreen('link.switch_to_corporate_member')}
                            <Icon
                                icon={linkIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </Link>
                    )}
                    {member.privateMembershipNo && (
                        <Link
                            to={resolveRoute(routePaths.MEMBER, {
                                membershipNo: member.privateMembershipNo,
                            })}
                            className={cx([
                                'global!ace-u-typography--variant-highlight',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            {translateScreen('link.switch_to_private_member')}
                            <Icon
                                icon={linkIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </Link>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <Icon
                        icon={closeIcon}
                        onClick={router.goBack}
                    />
                </div>
            </div>
        </Fragment>
    );
};

MemberHeader.propTypes = {
    member: PropTypes.object,
};

MemberHeader.defaultProps = {
    member: null,
};

export default withRouter(MemberHeader);
