import {put, select, take, fork} from 'redux-saga/effects';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as selectors from '../serviceCaseSelectors';

const loadServiceCaseCommissioners = function* loadServiceCaseCommissioners() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const commissioners = yield select(selectors.getCommissioners);

    if (Object.keys(commissioners).length !== 0) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST,
        partnerManagementService.getACEPartnersV2, {
            partnerType: apmACEPartnerTypes.COMMISSIONER,
        },
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_COMMISSIONERS,
            payload: {commissionerDTOs: acePartnerDTOs},
        });
    }
};

export default loadServiceCaseCommissioners;
