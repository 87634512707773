import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {apmContractPartnerContractStatusTypes, apmACEPartnerServiceTypes, efServiceAssignmentTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Input, ScrollableBlock, LoaderSpinner, NoResultsBlock, Icon, findCaseIcon} from '@ace-de/ui-components';
import ServiceProviderItem from './ServiceProviderItem';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import config from '../../config';

const ServiceProviderPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('service_provider_panel');
    const {serviceProviders, serviceAssignment, isPartnerChangingDisabled, spRecommendationsPersistenceState} = props;
    const {isEditModeActive, selectedServiceProviderId, handleOnSelectServiceProvider} = props;
    const [searchTerm, setSearchTerm] = useState('');
    const isSearchInProgress = spRecommendationsPersistenceState === persistenceStates.PENDING;

    if (!serviceAssignment) return null;

    return (
        <Panel
            title={serviceAssignment.assignmentType === efServiceAssignmentTypes.SUBSTITUTE_DRIVER
                ? translatePanel('title.return_driver')
                : translatePanel('title.service_provider')}
            className={cx([
                'ace-c-panel--full-bleed-content',
                'global!ace-u-height--full',
            ])}
        >
            <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                <div className={cx('global!ace-u-padding--0-24')}>
                    <Input
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-full-width',
                        ])}
                        name="serviceProviderSearch"
                        value={searchTerm}
                        onChange={value => setSearchTerm(value)}
                        placeholder={translatePanel('input_placeholder.search_for_service_providers')}
                    />
                    {!isSearchInProgress && !Object.values(serviceProviders).length && (
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            variant="medium"
                            message={translatePanel('no_results_message.message')}
                            className={cx('global!ace-u-typography--align-center')}
                        />
                    )}
                </div>
                <div>
                    {!isSearchInProgress && Object.values(serviceProviders).filter(serviceProvider => {
                        return serviceProvider.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE
                            && (searchTerm.length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                ? serviceProvider.name.toLowerCase().includes(searchTerm.toLowerCase())
                                : true);
                    })
                        .map(serviceProvider => {
                            let isEmergencyLawyerCurrentlyActive = false;
                            let shouldShowServiceProvider = true;
                            if (serviceAssignment.assignmentType === efServiceAssignmentTypes.LEGAL_ADVICE) {
                                const legalAdviceService = serviceProvider.services?.find(service => {
                                    return service.serviceType === apmACEPartnerServiceTypes.LEGAL_ADVICE;
                                });

                                if (legalAdviceService?.standByTimes) {
                                    isEmergencyLawyerCurrentlyActive = legalAdviceService.standByTimes
                                        .find(standbyTime => standbyTime.isActive);
                                    shouldShowServiceProvider = isEmergencyLawyerCurrentlyActive;
                                }
                            }

                            return (
                                shouldShowServiceProvider && (
                                    <ServiceProviderItem
                                        key={serviceProvider.id}
                                        serviceProvider={serviceProvider}
                                        serviceAssignment={serviceAssignment}
                                        isSelected={selectedServiceProviderId === serviceProvider.id}
                                        onChange={handleOnSelectServiceProvider}
                                        isDisabled={!!isEditModeActive || isPartnerChangingDisabled
                                        || serviceAssignment.listOfPreviousPartners?.includes(serviceProvider.id)}
                                    />
                                )
                            );
                        })
                    }
                    {isSearchInProgress && (
                        <div
                            className={cx([
                                'global!ace-u-padding--top-32',
                                'global!ace-u-width--full',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-center',
                            ])}
                        >
                            <LoaderSpinner />
                        </div>
                    )}
                </div>
            </ScrollableBlock>
        </Panel>
    );
};

ServiceProviderPanel.propTypes = {
    handleOnSelectServiceProvider: PropTypes.func.isRequired,
    selectedServiceProviderId: PropTypes.string,
    isEditModeActive: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    serviceProviders: PropTypes.object,
    isPartnerChangingDisabled: PropTypes.bool,
    spRecommendationsPersistenceState: PropTypes.string.isRequired,
};

ServiceProviderPanel.defaultProps = {
    selectedServiceProviderId: '',
    isEditModeActive: false,
    serviceAssignment: null,
    serviceProviders: null,
    isPartnerChangingDisabled: false,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceProviders: state.serviceAssignments.serviceProviders,
        serviceAssignment: serviceAssignmentSelector(state, props),
        spRecommendationsPersistenceState: state.serviceAssignments.serviceProviderRecommendationsPersistenceState,
    };
};

export default withRouter(connect(mapStateToProps, null)(ServiceProviderPanel));
