import {take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';

const updateDocumentDescriptionFlow = function* updateDocumentDescriptionFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_UPDATE_DOCUMENT_DESCRIPTION_FLOW);

        yield* openModal(modalIds.EDIT_DOCUMENT_DESCRIPTION, {groupId: payload?.groupId});

        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION,
            serviceCaseActionTypes.DECLINE_UPDATE_DOCUMENT_DESCRIPTION,
        ]);

        const {groupId, serviceCaseId} = payload;
        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId];

        if (chosenModalOption.type === serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION) {
            const {newDescription} = chosenModalOption.payload;

            yield* updateServiceCase({
                caller: serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION,
                caseType: serviceCase.caseType,
                serviceCaseId,
                serviceCaseData: {
                    attachments: serviceCase.attachments.map(attachment => {
                        return attachment.groupId !== groupId
                            ? attachment
                            : {
                                ...attachment,
                                description: newDescription,
                            };
                    }),
                },
            });

            // NOTE: errors are handled implicitly as part of the fetchRequest (inside batch saga)
            // below you can handle something in addition if needed
        }

        yield* closeModal(modalIds.EDIT_DOCUMENT_DESCRIPTION, {groupId});
    }
};

export default updateDocumentDescriptionFlow;
