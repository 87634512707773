import {take, call, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes, BusinessContactDetails} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saaActionTypes from '../saaActionTypes';

const selectSAAAccommodationLocationFlow = function* selectSAAAccommodationLocationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(saaActionTypes.SUBMIT_SAA_ACCOMMODATION_LOCATION_FORM);
        const {serviceAssignmentLineNo, accommodationLocation, serviceCaseId} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-accommodation');
        const saaAccommodationLocationsLayer = yield call(arcGISMap.getLayer, 'saa-accommodation-locations');

        if (!saaAccommodationLocationsLayer || !accommodationLocation) continue;

        yield call(saaAccommodationLocationsLayer.selectFeatureByAttribute, {
            where: `locationId = '${accommodationLocation.id}'`,
        });

        const businessContactDetails = new BusinessContactDetails({
            phoneNo: accommodationLocation.phoneNo || '',
        });

        yield* updateServiceAssignment({
            caller: 'SELECT_SAA_ACCOMMODATION_LOCATION',
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                    externalId: accommodationLocation.id,
                    location: {
                        ...accommodationLocation,
                        locationId: accommodationLocation.id,
                    },
                    name: accommodationLocation.locationName || '',
                    businessContactDetails,
                },
            },
        });
    }
};

export default selectSAAAccommodationLocationFlow;
