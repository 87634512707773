import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import URLParse from 'url-parse';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Divider} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, closedIcon} from '@ace-de/ui-components/icons';
import styles from './TowingDestinationItem.module.scss';

const TowingDestinationItem = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_towing_destination_tab');
    const {towingDestination, recommendedTowingDestination, isSelected, onSelect} = props;

    const handleOnClick = () => {
        if (typeof onSelect === 'function') {
            onSelect(towingDestination.id);
        }
    };

    const displayWorkingHours = workingHours => {
        const {today, isAlwaysOpen, isOpen} = workingHours;
        let formattedWorkingHours = translateTab(`radio_button_label.${isOpen ? 'open' : 'closed'}`);

        if (isAlwaysOpen) {
            formattedWorkingHours += ` 24 ${translate('global.time_unit.time')}`;
        }

        if (!isAlwaysOpen) {
            formattedWorkingHours += (today !== ''
                ? `, ${translate('global.time_unit.today')} ${today}`
                : '');
        }

        return formattedWorkingHours;
    };

    let websiteURL;
    if (typeof towingDestination.website === 'string') {
        const parsedURL = URLParse(towingDestination.website);
        websiteURL = `${parsedURL.hostname}${parsedURL.pathname}`;
    }

    const recommendedTowingDestinationDiff = towingDestination.routeToReferentialPoint
        && recommendedTowingDestination.routeToReferentialPoint
        ? (Math.round(towingDestination.routeToReferentialPoint.totalKilometers * 10) / 10
            - Math.round(recommendedTowingDestination.routeToReferentialPoint.totalKilometers * 10) / 10)
        : null;

    return (
        <Fragment>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-padding--16-32',
                ], {
                    'ace-c-towing-destination-item--is-selected': isSelected,
                })}
                onClick={handleOnClick}
            >
                <label
                    htmlFor={`radio-button-${towingDestination.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-cursor--pointer',
                    ])}
                >
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-margin--bottom-4',
                        ])}
                    >
                        {towingDestination.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {towingDestination.formattedAddress || ''}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                        {translateTab('radio_button_label.phone_no')}
                        &nbsp;
                        {towingDestination.phoneNo}
                    </p>
                    {websiteURL && towingDestination.website && (
                        <a
                            className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-typography--color-website-link')}
                            href={towingDestination.website}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {websiteURL}
                        </a>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-caption',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-margin--4-0-8',
                        ])}
                    >
                        {towingDestination.routeToReferentialPoint && (
                            <Fragment>
                                <span>
                                    {translateTab('radio_button_label.distance_to_damage_location')}
                                    &nbsp;
                                    {Math.round(towingDestination.routeToReferentialPoint.totalKilometers * 10) / 10}
                                    &nbsp;
                                    {translate('global.unit.km')}
                                </span>
                                &nbsp;
                                <span className={cx('global!ace-u-typography--color-success')}>
                                    ({Math.round(towingDestination.routeToReferentialPoint.totalTravelTime)}{translate('global.time_unit.minute')})
                                </span>
                            </Fragment>
                        )}
                        {recommendedTowingDestinationDiff !== null && (
                            <Fragment>
                                <br />
                                <span>
                                    {translateTab('radio_button_label.diff_distance_to_damage_location')}
                                </span>
                                &nbsp;
                                <span
                                    className={cx({
                                        'global!ace-u-typography--color-success': recommendedTowingDestinationDiff <= 0,
                                        'global!ace-u-typography--color-warning': recommendedTowingDestinationDiff > 0,
                                    })}
                                >
                                    {new Intl.NumberFormat('en-US', {
                                        signDisplay: 'exceptZero',
                                    }).format(recommendedTowingDestinationDiff)}
                                    &nbsp;
                                    {translate('global.unit.km')}
                                </span>
                            </Fragment>
                        )}
                        {towingDestination.workingHours && (
                            <p className={cx('global!ace-u-flex', 'global!ace-u-flex--align-flex-end')}>
                                <Icon
                                    icon={
                                        towingDestination.workingHours.isOpen
                                            ? checkmarkIcon
                                            : closedIcon
                                    }
                                    className={cx([
                                        'ace-c-icon--s',
                                        towingDestination.workingHours.isOpen
                                            ? 'ace-c-icon--color-success'
                                            : 'ace-c-icon--color-warning',
                                        'global!ace-u-margin--top-4',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {displayWorkingHours(towingDestination.workingHours)}
                            </p>
                        )}
                    </div>
                </label>
            </div>
            <Divider />
        </Fragment>
    );
};

TowingDestinationItem.propTypes = {
    towingDestination: PropTypes.object,
    recommendedTowingDestination: PropTypes.object,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
};

TowingDestinationItem.defaultProps = {
    towingDestination: {},
    recommendedTowingDestination: {},
    isSelected: false,
    onSelect: null,
};

export default TowingDestinationItem;
