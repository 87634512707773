import {put} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

export const setPersistencePending = function* setPersistencePending(serviceAssignmentId) {
    yield put({
        type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE,
        payload: {serviceAssignmentId, persistenceState: persistenceStates.PENDING},
    });
};

export const setPersistenceReady = function* setPersistenceReady(serviceAssignmentId) {
    yield put({
        type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE,
        payload: {serviceAssignmentId, persistenceState: persistenceStates.READY},
    });
};
