import React, {useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, ContentBlock, ContentItem, ScrollableBlock, SearchBox} from '@ace-de/ui-components';
import {RadioButtonGroup, ToggleSwitch} from '@ace-de/ui-components/form';
import {useArcGISMap, createMarkerGraphic, markerPersonPNG} from '@ace-de/eua-arcgis-map';
import GooglePlacesLogo from '../application/ui-elements/GooglePlacesLogo';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as samaActionTypes from './samaActionTypes';
import DoctorLocationItem from './ui-elements/DoctorLocationItem';
import ServiceProviderItem from './ui-elements/ServiceProviderItem';

const SAMAServiceProviderTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sama_service_provider_tab');
    const arcGISMap = useArcGISMap('service-assignment-medical-advice');
    const {serviceAssignment, serviceCase, submitSAMAServiceProviderForm, searchSAMADoctorLocations} = props;
    const {searchACEPartners, medicalAdviceServiceProviders} = props;
    const {serviceLocation, doctorLocationRecommendations} = serviceAssignment;
    const [doctorLocationSearchQuery, setDoctorLocationSearchQuery] = useState(translateTab('input_search_param.doctor'));
    const [selectedDoctorLocationId, setSelectedDoctorLocationId] = useState('');
    const [acePartnersToggle, setACEPartnersToggle] = useState(false);
    const [selectedServiceProviderId, setSelectedServiceProviderId] = useState(null);


    useEffect(() => {
        if (!arcGISMap) return;

        arcGISMap.setGraphics({
            graphics: [
                ...(serviceLocation
                    ? [createMarkerGraphic({
                        id: 'referencePosition',
                        longitude: serviceLocation.longitude,
                        latitude: serviceLocation.latitude,
                        icon: markerPersonPNG,
                        isDraggable: false,
                    })] : []),
            ],
        });
    }, [arcGISMap, serviceLocation]);

    // on serviceAssignment change, set selected doctor location
    useEffect(() => {
        const {acePartner} = serviceAssignment;

        if (!acePartner || !arcGISMap || medicalAdviceServiceProviders.length === 0) return;

        if (!!medicalAdviceServiceProviders.find(medicalAdviceServiceProvider => (
            medicalAdviceServiceProvider.id === acePartner.externalId
        )) && selectedServiceProviderId !== acePartner?.externalId) {
            setSelectedServiceProviderId(acePartner.externalId);
            setSelectedDoctorLocationId('');
            arcGISMap.hideLayer('sama-doctors-layer');
            arcGISMap.showLayer('sama-service-providers-layer');
        }

        if (!medicalAdviceServiceProviders.find(medicalAdviceServiceProvider => (
            medicalAdviceServiceProvider.id === acePartner.externalId
        )) && selectedDoctorLocationId !== acePartner?.externalId) {
            setSelectedDoctorLocationId(acePartner.externalId);
            setSelectedServiceProviderId('');
            arcGISMap.hideLayer('sama-service-providers-layer');
            arcGISMap.showLayer('sama-doctors-layer');
        }

        setACEPartnersToggle(!!medicalAdviceServiceProviders.find(medicalAdviceServiceProvider => (
            medicalAdviceServiceProvider.id === acePartner.externalId
        )));
    }, [
        serviceAssignment,
        selectedDoctorLocationId,
        selectedServiceProviderId,
        medicalAdviceServiceProviders,
        arcGISMap,
    ]);

    const handleDoctorLocationSearchSearch = () => {
        if (doctorLocationSearchQuery) {
            searchSAMADoctorLocations({
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                searchQuery: doctorLocationSearchQuery,
            });
        }
    };

    const handleDoctorLocationSelect = newDoctorLocationId => {
        if (!newDoctorLocationId || selectedDoctorLocationId === newDoctorLocationId) return;
        const {doctorLocationRecommendations} = serviceAssignment;
        submitSAMAServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            doctorLocation: doctorLocationRecommendations
                .find(doctorLocationRecommendation => doctorLocationRecommendation.id === newDoctorLocationId),
        });
    };

    const handleServiceProviderSelect = serviceProviderId => {
        if (!serviceProviderId || selectedServiceProviderId === serviceProviderId) return;
        const chosenServiceProvider = medicalAdviceServiceProviders.find(medicalAdviceServiceProvider => (
            medicalAdviceServiceProvider.id === serviceProviderId
        ));
        submitSAMAServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceProvider: chosenServiceProvider,
        });
    };

    const handleACEPartnersToggle = value => {
        if (value && medicalAdviceServiceProviders.length === 0) {
            searchACEPartners({serviceAssignment});
        }
        value ? arcGISMap.hideLayer('sama-doctors-layer') : arcGISMap.showLayer('sama-doctors-layer');
        !value ? arcGISMap.hideLayer('sama-service-providers-layer') : arcGISMap.showLayer('sama-service-providers-layer');
        setACEPartnersToggle(value);
    };

    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                    hasHeaderFullWidth={true}
                    header={(
                        <div className={cx('global!ace-u-width--full')}>
                            <div className={cx([
                                'global!ace-u-margin--top-32',
                                'global!ace-u-width--full',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                            >
                                <ToggleSwitch
                                    name="acePartnersToggle"
                                    value={true}
                                    isSelected={acePartnersToggle}
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-row-reverse',
                                        'global!ace-u-flex--justify-space-between',
                                    ])}
                                    onChange={value => handleACEPartnersToggle(value)}
                                >
                                    <span className={cx('global!ace-u-margin--right-8')}>
                                        {translateTab('toggle_label.service_providers')}
                                    </span>
                                </ToggleSwitch>
                            </div>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--16-0-32',
                                ])}
                            >
                                {translateTab('panel_title.medical_care')}
                            </p>
                        </div>
                    )}
                >
                    <ScrollableBlock isLabelDisabled={true}>
                        {acePartnersToggle && medicalAdviceServiceProviders.length > 0 && (
                            <Fragment>
                                <RadioButtonGroup
                                    name="selectedServiceProvider"
                                    value={selectedServiceProviderId}
                                    onChange={handleServiceProviderSelect}
                                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                        || serviceAssignment.persistenceState === persistenceStates.PENDING}
                                >
                                    {medicalAdviceServiceProviders.map(medicalAdviceServiceProvider => {
                                        return (
                                            <ServiceProviderItem
                                                key={medicalAdviceServiceProvider.id}
                                                isSelected={false}
                                                serviceProvider={medicalAdviceServiceProvider}
                                            />
                                        );
                                    })}
                                </RadioButtonGroup>
                            </Fragment>
                        )}
                        {!acePartnersToggle && (
                            <Fragment>
                                <SearchBox
                                    isComposedIn={true}
                                    name="searchServiceProvider"
                                    placeholder={translateTab('input_placeholder.find_doctor')}
                                    className={cx([
                                        'global!ace-u-flex--grow-1',
                                        'global!ace-u-margin--16-0',
                                        'global!ace-u-width--full',
                                    ])}
                                    value={doctorLocationSearchQuery}
                                    onChange={setDoctorLocationSearchQuery}
                                    onSearchSubmit={handleDoctorLocationSearchSearch}
                                    isDropDownDisabled={true}
                                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                        || serviceAssignment.persistenceState === persistenceStates.PENDING}
                                />
                                <GooglePlacesLogo />
                                {doctorLocationRecommendations?.length > 0 && (
                                    <Fragment>
                                        <RadioButtonGroup
                                            name="selectedDoctorLocationId"
                                            value={selectedDoctorLocationId}
                                            onChange={handleDoctorLocationSelect}
                                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                                || serviceAssignment.persistenceState === persistenceStates.PENDING}
                                        >
                                            {doctorLocationRecommendations.map(doctorLocationRecommendation => {
                                                return (
                                                    <DoctorLocationItem
                                                        key={doctorLocationRecommendation.id}
                                                        isSelected={false}
                                                        doctorLocation={doctorLocationRecommendation}
                                                    />
                                                );
                                            })}
                                        </RadioButtonGroup>
                                    </Fragment>
                                )}
                                {(!doctorLocationRecommendations || doctorLocationRecommendations.length === 0) && (
                                    <Fragment>
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                        >
                                            {translateTab('text.no_results')}
                                        </p>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAMAServiceProviderTab.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    medicalAdviceServiceProviders: PropTypes.array,
    submitSAMAServiceProviderForm: PropTypes.func.isRequired,
    searchSAMADoctorLocations: PropTypes.func.isRequired,
    searchACEPartners: PropTypes.func.isRequired,
};

SAMAServiceProviderTab.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
    medicalAdviceServiceProviders: [],
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
        medicalAdviceServiceProviders: state.serviceAssignments.medicalAdviceServiceProviders,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitSAMAServiceProviderForm: payload => dispatch({
            type: samaActionTypes.SUBMIT_SAMA_SERVICE_PROVIDER_FORM,
            payload,
        }),
        searchSAMADoctorLocations: payload => dispatch({
            type: samaActionTypes.SEARCH_SAMA_DOCTOR_LOCATIONS,
            payload,
        }),
        searchACEPartners: payload => dispatch({
            type: samaActionTypes.SEARCH_SAMA_SERVICE_PROVIDERS,
            payload,
        }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMAServiceProviderTab));
