import React, {Fragment, useCallback, useEffect, useRef, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efChildrenAgeTypes, efShortTripTransportationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Form, InputPhoneNumberField, Divider, Icon, AutosuggestField, searchIcon, locationIcon, Pill, InputCounter} from '@ace-de/ui-components';
import {SelectField, Option} from '@ace-de/ui-components/selector-inputs';
import * as sastActionTypes from '../sastActionTypes';
import config from '../../config';

const SASTTripDetailsPanel = props => {
    const {cx} = useStyles();
    const {serviceAssignment, submitSASTTripDetailsForm, searchSASTDestinationGeolocation, serviceCase} = props;
    const {calculateMemberLocationToDestinationDistance, memberLocationCandidate} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sast_requirements_tab');
    const [newShortTripDestination, setNewShortTripDestination] = useState(null);
    const currentShortTripAddress = useRef('');
    const lastShortTripDestinationSearchQuery = useRef('');

    const searchSASTDestionationGeolocationDebounced = useMemo(
        () => debounce(searchSASTDestinationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSASTDestinationGeolocation],
    );

    const [sastOverviewData, setSastOverviewData] = useState({
        transportationType: efShortTripTransportationTypes[serviceAssignment.transportationType],
        destination: serviceAssignment.destination,
        callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
        grownUpsAtSite: serviceAssignment.grownUpsAtSite,
        childrenAtSite: serviceAssignment.childrenAtSite,
        petsAtSite: serviceAssignment.petsAtSite,
    });

    const [childrenAge, setChildrenAge] = useState(serviceAssignment.childrenAge.length
        ? (serviceAssignment.childrenAge.length < serviceAssignment.childrenAtSite
            ? serviceAssignment.childrenAge
                .concat(new Array(serviceAssignment.childrenAtSite - serviceAssignment.childrenAge.length)
                    .fill('', 0))
            : serviceAssignment.childrenAge)
        : (serviceAssignment.childrenAtSite
            ? new Array(serviceAssignment.childrenAtSite).fill('', 0)
            : []));

    useEffect(() => {
        setSastOverviewData({
            transportationType: efShortTripTransportationTypes[serviceAssignment.transportationType],
            destination: serviceAssignment.destination,
            callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
            grownUpsAtSite: serviceAssignment.grownUpsAtSite,
            childrenAtSite: serviceAssignment.childrenAtSite,
            petsAtSite: serviceAssignment.petsAtSite,
        });
    }, [
        serviceAssignment.transportationType,
        serviceAssignment.destination,
        serviceAssignment.callbackPhoneNo,
        serviceAssignment.grownUpsAtSite,
        serviceAssignment.childrenAtSite,
        serviceAssignment.petsAtSite,
    ]);

    useEffect(() => {
        if (newShortTripDestination && memberLocationCandidate) {
            // calculate the distance between member's location and selected destination candidate
            calculateMemberLocationToDestinationDistance({
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                destination: newShortTripDestination,
                memberLocation: memberLocationCandidate,
            });
        }
    }, [
        newShortTripDestination,
        memberLocationCandidate,
        calculateMemberLocationToDestinationDistance,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
    ]);

    useEffect(() => {
        if (!serviceAssignment?.destination) return;

        if (serviceAssignment.destination) {
            setNewShortTripDestination(serviceAssignment.destination);
        }
    }, [serviceAssignment.destination]);

    const updateChildrenAgeData = formValues => {
        const {childrenAtSite, childrenAge: childrenAgeValues} = formValues;
        const currentChildrenAgeValues = childrenAtSite
            ? (Object.keys(childrenAgeValues).length
                ? Object.keys(childrenAgeValues).map(key => childrenAgeValues[key])
                    .slice(0, sastOverviewData?.childrenAtSite || serviceCase.childrenAtSite)
                : childrenAge)
            : [];
        if (childrenAtSite > currentChildrenAgeValues.length) {
            setChildrenAge([...currentChildrenAgeValues, '']);
            return;
        }
        if (childrenAtSite < currentChildrenAgeValues.length) {
            const updatedChildrenArray = [...currentChildrenAgeValues];
            updatedChildrenArray.pop();
            setChildrenAge(updatedChildrenArray);
            return;
        }
        setChildrenAge(currentChildrenAgeValues);
    };

    const handleOnChange = formValues => {
        updateChildrenAgeData(formValues);
        setSastOverviewData(formValues);
    };

    const handleOnSubmit = useCallback(formValues => {
        const serviceAssignmentData = {
            ...formValues,
            childrenAge: childrenAge.filter(age => age !== ''),
            destination: newShortTripDestination,
        };

        submitSASTTripDetailsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        newShortTripDestination,
        submitSASTTripDetailsForm,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        childrenAge,
    ]);

    const handleShortTripDestinationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentShortTripAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSASTDestionationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastShortTripDestinationSearchQuery.current = searchQueryString;
        }
    };

    const handleShortTripDestinationCandidateSelect = destinationCandidate => {
        currentShortTripAddress.current = destinationCandidate.formattedAddress;
        setNewShortTripDestination(destinationCandidate);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!sastOverviewData) {
            completeRouteUnmountSideEffect({
                caller: sastActionTypes.SUBMIT_SAST_TRIP_DETAILS_FORM,
            });
            return;
        }

        handleOnSubmit(sastOverviewData);
    }, [sastOverviewData, handleOnSubmit]);


    const {destinationCandidates, destinationSearchQuery} = serviceAssignment;

    return (
        <Panel title={translateTab('panel_title.overview')}>
            <Form name="sastOverviewForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                {formValues => {
                    return (
                        <Fragment>
                            <p className={cx('global!ace-u-margin--bottom-24')}>
                                {translateTab('text.travel_data')}
                            </p>
                            <div className={cx('global!ace-u-grid')}>
                                <SelectField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="transportationType"
                                    value={efShortTripTransportationTypes[sastOverviewData.transportationType]}
                                    label={translateTab('select_field_label.transportation_type')}
                                    placeholder={translateTab('select_field_placeholder.please_select')}
                                >
                                    {Object.values(efShortTripTransportationTypes).map((transportationType, idx) => {
                                        return (
                                            <Option
                                                key={transportationType}
                                                name={`option${idx}`}
                                                value={transportationType}
                                            >
                                                {translate(`global.service_assignment_transportation_type.${transportationType.toLowerCase()}`)}
                                            </Option>
                                        );
                                    })}
                                </SelectField>
                                <div
                                    className={cx([
                                        'global!ace-u-grid-column--span-3',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--justify-flex-start',
                                    ])}
                                >
                                    <AutosuggestField
                                        label={translateTab('input_label.destination')}
                                        name="destinationSearchQuery"
                                        value={newShortTripDestination?.formattedAddress || ''}
                                        onChange={handleShortTripDestinationSearchQueryChange}
                                        onOptionSelect={handleShortTripDestinationCandidateSelect}
                                        optionValueSelector={destinationCandidate => {
                                            return destinationCandidate.formattedAddress;
                                        }}
                                        placeholder={translateTab('input_placeholder.please_enter')}
                                        icon={searchIcon}
                                        className={cx('global!ace-u-margin--bottom-8')}
                                    >
                                        {(formValues['destinationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                        && destinationSearchQuery === lastShortTripDestinationSearchQuery.current
                                            ? destinationCandidates
                                                .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                .map((destinationCandidate, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            name={`short-trip-destination-candidate-${index}`}
                                                            value={destinationCandidate}
                                                        >
                                                            <Icon
                                                                icon={locationIcon}
                                                                className={cx('global!ace-u-margin--right-16')}
                                                            />
                                                            {destinationCandidate.formattedAddress}
                                                        </Option>
                                                    );
                                                }) : null}
                                    </AutosuggestField>
                                    {serviceAssignment.destinationCandidateDistance && newShortTripDestination
                                        && memberLocationCandidate && (
                                        <div
                                            className={cx([
                                                'global!ace-u-margin--bottom-8',
                                                'global!ace-u-flex--align-self-center',
                                            ])}
                                        >
                                            <Pill
                                                type="information"
                                                className={cx('global!ace-u-typography--variant-caption')}
                                            >
                                                {translateTab('pill_label.distance_from_damage_location_to_destination')}&nbsp;
                                                {Math
                                                    .round(serviceAssignment.destinationCandidateDistance)}
                                                {translate('global.unit.km')}
                                            </Pill>
                                        </div>
                                    )}
                                </div>
                                <InputPhoneNumberField
                                    className={cx('global!ace-u-grid-column--span-3', 'global!ace-u-margin--bottom-32')}
                                    name="callbackPhoneNo"
                                    value={sastOverviewData.callbackPhoneNo}
                                    label={translateTab('input_label.callback_phone_no')}
                                />
                            </div>
                            <Divider />
                            <p
                                className={cx('global!ace-u-margin--24-0')}
                            >
                                {translateTab('text.persons_at_site')}
                            </p>
                            <div className={cx(['global!ace-u-margin--bottom-32', 'global!ace-u-grid'])}>
                                <div className={cx('global!ace-u-grid-column--span-4')}>
                                    <InputCounter
                                        name="grownUpsAtSite"
                                        label={translateTab('input_counter_label.adults')}
                                        value={sastOverviewData.grownUpsAtSite}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'ace-c-input-counter--list-element',
                                        ])}
                                    />
                                    <Divider />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-4')}>
                                    <InputCounter
                                        name="childrenAtSite"
                                        label={translateTab('input_counter_label.children')}
                                        value={sastOverviewData.childrenAtSite}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'ace-c-input-counter--list-element',
                                            'global!ace-u-grid-column--span-3',
                                        ])}
                                    />
                                    <Divider />
                                    <Form name="childrenAge">
                                        <div
                                            className={cx('global!ace-u-grid', {
                                                'global!ace-u-padding--16-0': childrenAge.length,
                                            })}
                                        >
                                            {childrenAge.map((age, idx) => (
                                                <SelectField
                                                    key={idx}
                                                    name={`childrenAge${idx}`}
                                                    label={translateTab('input_label.children_age')}
                                                    value={age}
                                                    className={cx('global!ace-u-grid-column--span-6')}
                                                >
                                                    {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                        <Option
                                                            key={idx}
                                                            name={`children-age-option-${idx}`}
                                                            value={idx}
                                                        >
                                                            {efChildrenAgeTypes[ageOption]}
                                                        </Option>
                                                    ))}
                                                </SelectField>
                                            ))}
                                        </div>
                                    </Form>
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-4')}>
                                    <InputCounter
                                        name="petsAtSite"
                                        label={translateTab('input_counter_label.animals')}
                                        value={sastOverviewData.petsAtSite}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'ace-c-input-counter--list-element',
                                            'global!ace-u-grid-column--span-3',
                                        ])}
                                    />
                                    <Divider />
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Panel>
    );
};

SASTTripDetailsPanel.propTypes = {
    searchSASTDestinationGeolocation: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    submitSASTTripDetailsForm: PropTypes.func.isRequired,
    calculateMemberLocationToDestinationDistance: PropTypes.func.isRequired,
    memberLocationCandidate: PropTypes.object,
    serviceCase: PropTypes.object,
};

SASTTripDetailsPanel.defaultProps = {
    serviceAssignment: null,
    memberLocationCandidate: null,
    serviceCase: {},
};

const mapDispatchToProps = dispatch => ({
    searchSASTDestinationGeolocation: payload => dispatch({
        type: sastActionTypes.SEARCH_SAST_DESTINATION_GEOLOCATION,
        payload,
    }),

    submitSASTTripDetailsForm: payload => dispatch({
        type: sastActionTypes.SUBMIT_SAST_TRIP_DETAILS_FORM,
        payload,
    }),
    calculateMemberLocationToDestinationDistance: payload => dispatch({
        type: sastActionTypes.CALCULATE_MEMBER_LOCATION_TO_DESTINATION_DISTANCE,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(SASTTripDetailsPanel);
