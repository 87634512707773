import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Divider, RadioButton, warningAlertIcon} from '@ace-de/ui-components';
import {Icon, areaIcon, arrowDownIcon, arrowUpIcon, infoIcon, linkIcon, deleteIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';
import contractPartnerScreenTabs from '../../contract-partners/contractPartnerScreenTabs';
import styles from './ContractPartnerItem.module.scss';

const ContractPartnerItem = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sap_service_provider_tab');
    const [isToggleOn, setIsToggleOn] = useState(false);
    const {contractPartner, isDisabled, isManuallyAdded, assignmentStatus, handleOnDelete} = props;

    const handleDeleteManuallyAddedContractPartner = () => {
        if (handleOnDelete && typeof handleOnDelete === 'function') {
            handleOnDelete();
        }
    };

    const togglePhoneList = event => {
        event.preventDefault();
        event.stopPropagation();
        setIsToggleOn(!isToggleOn);
    };

    const handleRedirectToContractPartnerScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        const {pathname} = resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: contractPartner.id},
        );
        window.open(`${pathname}?activeTab=${contractPartnerScreenTabs.SERVICES}`, '_blank');
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    // pre-sort emergency contacts by id and put 24/7 contact first
    const emergencyContacts = contractPartner.emergencyContacts
        ? contractPartner.emergencyContacts.slice().sort((emergencyContactA, emergencyContactB) => {
            if (emergencyContactA.is24h7Emergency) return -1;
            return emergencyContactA.id - emergencyContactB.id;
        }) : [];

    const hasServicesWarning = !!contractPartner.isOpeningSoon || !!contractPartner.isPickupServiceSupported;

    return (
        <div
            className={cx('global!ace-u-margin--bottom-16', {
                'ace-c-contract-partner-item--highlighted': !!isManuallyAdded,
            })}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-margin--bottom-16',
                ])}
            >
                {!isManuallyAdded && (
                    <RadioButton
                        id={`radio-button-${contractPartner.id}`}
                        name={`radio-button-${contractPartner.id}`}
                        value={contractPartner.id}
                        isDisabled={isDisabled}
                    />
                )}
                <label
                    htmlFor={`radio-button-${contractPartner.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-margin--left-16',
                    ], {
                        'global!ace-u-cursor--pointer': !isManuallyAdded,
                    })}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-flex-start',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-margin--bottom-4',
                        ], {
                            'global!ace-u-margin--top-8': !!isManuallyAdded,
                        })}
                    >
                        <p
                            className={cx(['global!ace-u-typography--variant-body', 'global!ace-u-margin--right-8'], {
                                'global!ace-u-typography--variant-body-bold': !!isManuallyAdded,
                            })}
                        >
                            {contractPartner.name}
                        </p>
                        {!!isManuallyAdded && assignmentStatus !== efServiceAssignmentStatusTypes.ASSIGNED && (
                            <span
                                className={cx('global!ace-u-cursor--pointer')}
                                onClick={handleDeleteManuallyAddedContractPartner}
                            >
                                <Icon
                                    icon={deleteIcon}
                                />
                            </span>
                        )}
                        {!isManuallyAdded && (
                            <span onClick={handleRedirectToContractPartnerScreen}>
                                <Icon
                                    icon={linkIcon}
                                />
                            </span>
                        )}
                    </div>
                    {!isManuallyAdded && hasServicesWarning && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-items-center',
                                'global!ace-u-margin--16-0',
                                'global!ace-u-padding--8',
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-box-skin--negative-dark',
                            ])}
                        >
                            <div>
                                <Icon
                                    icon={warningAlertIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--color-warning-light',
                                        'ace-c-icon--s',
                                    ])}
                                />
                            </div>
                            <div
                                className={cx('global!ace-u-flex--grow-1')}
                            >
                                {!!contractPartner.isOpeningSoon && (
                                    <p key="isOpeningSoonWarning">
                                        {translateTab('services_warning_text.opening_soon')}
                                    </p>
                                )}
                                {!!contractPartner.isPickupServiceSupported && (
                                    <p key="isPickupServiceSupportedWarning">
                                        {translateTab('services_warning_text.does_not_offer_pickup')}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {!isManuallyAdded && [...contractPartner.temporaryRestrictions.values()]
                        .filter(restriction => !!restriction.description).length > 0 && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-items-center',
                                'global!ace-u-margin--16-0',
                                'global!ace-u-padding--8',
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-box-skin--negative',
                            ])}
                        >
                            <div>
                                <Icon
                                    icon={infoIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--color-warning',
                                        'ace-c-icon--s',
                                    ])}
                                />
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                ])}
                            >
                                {[...contractPartner.temporaryRestrictions.values()]
                                    .filter(restriction => !!restriction.description)
                                    .map((restriction, index) => (
                                        <p key={index}>
                                            {restriction.description}
                                        </p>
                                    ))}
                            </div>
                        </div>
                    )}
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {contractPartner.location?.formattedAddress || ''}
                    </p>
                    {!isManuallyAdded && emergencyContacts.length > 0 ? (
                        <Fragment>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-margin--4-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                ])}
                                title={translateTab(emergencyContacts[0].is24h7Emergency
                                    ? 'radio_button_label.emergency_contact_24_7'
                                    : 'radio_button_label.emergency_contact', {
                                    type: emergencyContacts[0].type || '-',
                                    name: emergencyContacts[0].name || '-',
                                })}
                            >
                                <span>
                                    {translateTab(emergencyContacts[0].is24h7Emergency
                                        ? 'radio_button_label.emergency_contact_24_7'
                                        : 'radio_button_label.emergency_contact', {
                                        type: emergencyContacts[0].type || '-',
                                        name: emergencyContacts[0].name || '-',
                                    })}&nbsp;
                                </span>
                                <a
                                    href={`tel:${emergencyContacts[0].phoneNo}`}
                                    className={cx([
                                        'global!ace-u-typography--variant-caption',
                                        'global!ace-u-typography--color-highlighted',
                                    ])}
                                >
                                    {emergencyContacts[0].phoneNo}
                                </a>
                                {emergencyContacts.length > 1 && (
                                    <Icon
                                        className={cx([
                                            'ace-c-icon--color-highlight',
                                            'global!ace-u-margin--left-8',
                                        ])}
                                        icon={!isToggleOn ? arrowDownIcon : arrowUpIcon}
                                        onClick={togglePhoneList}
                                    />
                                )}
                            </div>
                            {isToggleOn
                                && emergencyContacts.length > 1
                                && emergencyContacts.map((emergencyContact, index) => {
                                    if (index === 0) return null;

                                    return (
                                        <p
                                            key={`${emergencyContact.phoneNo}-${index}`}
                                            className={cx([
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-margin--top-4',
                                                'global!ace-u-margin--bottom-4',
                                            ])}
                                            title={translateTab('radio_button_label.emergency_contact', {
                                                type: emergencyContact.type || '-',
                                                name: emergencyContact.name || '-',
                                            })}
                                        >
                                            {translateTab('radio_button_label.emergency_contact', {
                                                type: emergencyContact.type || '-',
                                                name: emergencyContact.name || '-',
                                            })}
                                            &nbsp;
                                            <a
                                                href={`tel:${emergencyContact.phoneNo}`}
                                                className={cx([
                                                    'global!ace-u-typography--variant-caption',
                                                    'global!ace-u-typography--color-highlighted',
                                                ])}
                                            >
                                                {emergencyContact.phoneNo}
                                            </a>
                                        </p>
                                    );
                                })}
                        </Fragment>
                    ) : (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-margin--4-0',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            {isManuallyAdded
                                ? translateTab('radio_button_label.telephone', {
                                    phoneNo: contractPartner?.businessContactDetails?.phoneNo || '-',
                                }) : translateTab('radio_button_label.no_emergency_contacts')}
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-caption',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-margin--4-0-8',
                        ])}
                    >
                        {contractPartner.routeToDamageLocation && (
                            <Fragment>
                                <span>
                                    {translateTab('radio_button_label.distance_to_damage_location')}
                                    &nbsp;
                                    {Math.round(contractPartner.routeToDamageLocation.totalKilometers)}
                                    {translate('global.unit.km')}
                                </span>
                                &nbsp;
                                <span className={cx('global!ace-u-typography--color-success')}>
                                    ({Math.round(contractPartner.routeToDamageLocation.totalTravelTime)}{translate('global.time_unit.minute')})
                                </span>
                            </Fragment>
                        )}
                    </div>
                    {!isManuallyAdded && contractPartner?.hasServiceArea && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-flex-end',
                                'global!ace-u-margin--bottom-15',
                            ])}
                        >
                            <Icon
                                icon={areaIcon}
                                className={cx('global!ace-u-margin--right-8')}
                            />
                            <p
                                className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-flex--grow-1')}
                            >
                                {translateTab('radio_button_label.fixed_price_area')}
                            </p>
                        </div>
                    )}
                </label>
            </div>
            <Divider />
        </div>
    );
};


ContractPartnerItem.propTypes = {
    contractPartner: PropTypes.object,
    isDisabled: PropTypes.bool,
    isManuallyAdded: PropTypes.bool,
    assignmentStatus: PropTypes.string,
    handleOnDelete: PropTypes.func,
};

ContractPartnerItem.defaultProps = {
    contractPartner: {},
    isDisabled: false,
    isManuallyAdded: false,
    assignmentStatus: '',
    handleOnDelete: null,
};

export default withRouter(ContractPartnerItem);
