import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarjActionTypes from '../sarjActionTypes';

/**
 * Update SARJ details data flow
 */
const updateSARJDetailsDataFlow = function* updateSARJDetailsDataFlow() {
    while (true) {
        const {payload} = yield take(sarjActionTypes.SUBMIT_SARJ_DETAILS_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: sarjActionTypes.SUBMIT_SARJ_DETAILS_FORM,
            assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sarjActionTypes.SUBMIT_SARJ_DETAILS_FORM},
        });
    }
};

export default updateSARJDetailsDataFlow;
