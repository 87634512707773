import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {positions} from '@computerrock/formation-ui/overlays';
import {useTranslate} from '@computerrock/formation-i18n';
import {efAccommodationSubStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonSecondary, DateField, Form} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, calendarIcon} from '@ace-de/ui-components/icons';
import * as saaActionTypes from '../saaActionTypes';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const SAAExtendStayEndDateModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('saa_extend_stay_end_date_modal');
    const {hasBackdrop, serviceAssignment} = props;
    const {confirmSAAExtendStayEndDate, declineSAAExtendStayEndDate} = props;

    const handleOnExtend = formValues => {
        if (!formValues.stayEndDate) return;

        const serviceAssignmentSubStatusData = {
            subStatus: efAccommodationSubStatusTypes.EXTEND,
            extendedDate: moment(formValues.stayEndDate),
        };

        confirmSAAExtendStayEndDate({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentSubStatusData,
        });
    };

    if (!serviceAssignment) return null;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSAAExtendStayEndDate}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={translateModal('title.extend')}
        >
            <div
                className={cx([
                    'global!ace-u-margin--top-32',
                    'global!ace-u-full-width',
                    'global!ace-u-typography--variant-body',
                ])}
            >
                <p className={cx('global!ace-u-margin--bottom-24')}>{translateModal('text.note')}</p>
                <Form name="accommodationExtensionForm" onSubmit={handleOnExtend}>
                    <DateField
                        name="stayEndDate"
                        label={translateModal('date_label.check_out')}
                        value={serviceAssignment?.stayEndDate || ''}
                        placeholder={translateModal('date_placeholder.please_enter')}
                        icon={calendarIcon}
                        locale={activeLocale}
                        minDate={serviceAssignment.stayEndDate || undefined}
                        format="DD.MM.YYYY, HH:mm"
                        timeOptionsDirection={positions.DIRECTION_TOP}
                    />
                    <div className={cx(['global!ace-u-margin--top-32', 'global!ace-u-flex', 'global!ace-u-flex--direction-row-reverse'])}>
                        <ButtonSecondary name="extendStayEndDateButton" type="submit">
                            {translateModal('button.confirm')}
                        </ButtonSecondary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

SAAExtendStayEndDateModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineSAAExtendStayEndDate: PropTypes.func.isRequired,
    confirmSAAExtendStayEndDate: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
};

SAAExtendStayEndDateModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentsSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineSAAExtendStayEndDate: payload => dispatch({
        type: saaActionTypes.DECLINE_SAA_EXTEND_STAY_END_DATE,
    }),
    confirmSAAExtendStayEndDate: payload => dispatch({
        type: saaActionTypes.CONFIRM_SAA_EXTEND_STAY_END_DATE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SAAExtendStayEndDateModal);
