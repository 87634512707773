import {select, fork, take, put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import savScreenTabs from '../../service-assignments-vehicle/savScreenTabs';
import savaScreenTabs from '../../service-assignments-vehicle-additional/savaScreenTabs';
import routePaths from '../../routePaths';
import sapScreenTabs from '../../service-assignments-pickup/sapScreenTabs';

/**
 * Calculate route from manually selected contract partner to damage location
 */
const loadContractPartnerToDamageLocationRoute = function* loadContractPartnerToDamageLocationRoute({payload}) {
    const {match, location} = payload;
    const {activeTab} = yield* selectSearchQueryParams();
    if (![
        savScreenTabs.CONTRACT_PARTNER,
        savaScreenTabs.CONTRACT_PARTNER,
        sapScreenTabs.SERVICE_PROVIDER,
    ].includes(activeTab)) return;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {serviceCases} = yield select(state => state.serviceCases);
    const serviceCase = serviceCases[serviceCaseId];
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    // if no case or assignment, return
    if (!serviceCase || !serviceAssignment) return;

    const vehicleAdditionalScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
        exact: true,
    });

    const pickupScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT_PICKUP,
        exact: true,
    });

    const damageLocation = vehicleAdditionalScreenMatch
        ? serviceAssignment?.serviceLocation
        : pickupScreenMatch
            ? serviceAssignment?.destination // pickup
            : serviceCase?.damage?.location; // vehicle initial

    // if no damage location or if ace partner is not manually selected, return
    if (!damageLocation
        || !serviceAssignment.acePartner?.isManuallyAdded
        || !serviceAssignment.acePartner?.location
    ) return;

    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST,
        arcGISRESTService.getRoute,
        {
            startingPoint: {
                longitude: damageLocation.longitude,
                latitude: damageLocation.latitude,
            },
            destination: {
                longitude: serviceAssignment.acePartner.location.longitude,
                latitude: serviceAssignment.acePartner.location.latitude,
            },
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
        },
    );

    const routeResponseAction = yield take([
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_FAILED,
    ]);

    if (!routeResponseAction.error) {
        const {response} = routeResponseAction.payload;
        const {arcGISRouteDTO} = response;
        const routeToReferentialPoint = arcGISRouteDTO;

        yield put({
            type: serviceAssignmentActionTypes.SET_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE,
            payload: {
                routeToReferentialPoint,
                serviceAssignmentId,
            },
        });
    }
};

export default loadContractPartnerToDamageLocationRoute;
