import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import saaScreenTabs from '../saaScreenTabs';
import * as saaActionTypes from '../saaActionTypes';
import onSAAMapClickFetchReferencePositionAddress from './onSAAMapClickFetchReferencePositionAddress';
import onSAAMapClickSelectAccommodationLocation from './onSAAMapClickSelectAccommodationLocation';

const savMapAccommodationLocationWatcher = function* savMapAccommodationLocationWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(saaActionTypes.START_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER);

        const onSAVMapClickFetchReferencePositionAddressTask = yield fork(
            onSAAMapClickFetchReferencePositionAddress,
            payload,
        );
        const onSAAMapClickSelectAccommodationLocationTask = yield fork(
            onSAAMapClickSelectAccommodationLocation,
            payload,
        );

        while (true) {
            const watcherTerminationAction = yield take([
                saaActionTypes.STOP_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === saaActionTypes.STOP_SAA_MAP_ACCOMMODATION_LOCATION_WATCHER) {
                yield cancel(onSAVMapClickFetchReferencePositionAddressTask);
                yield cancel(onSAAMapClickSelectAccommodationLocationTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_RENTAL_CAR,
                exact: true,
            });
            const {activeTab} = savRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savRouteMatch || activeTab !== saaScreenTabs.ACCOMMODATION) {
                yield cancel(onSAVMapClickFetchReferencePositionAddressTask);
                yield cancel(onSAAMapClickSelectAccommodationLocationTask);
                continue mainFlow;
            }
        }
    }
};

export default savMapAccommodationLocationWatcher;
