import {take, fork, put, select, actionChannel, all} from 'redux-saga/effects';
import {buffers} from 'redux-saga';
import {v4 as uuidv4} from 'uuid';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import getServiceCaseClass from '../getServiceCaseClass';
import * as memberActionTypes from '../../members/memberActionTypes';

/**
 * Batch update ServiceCase flow
 */
const batchUpdateServiceCaseFlow = function* batchUpdateServiceCaseFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        let nextBatchUpdateServiceCaseAction = yield take(
            serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
        );

        const {payload} = nextBatchUpdateServiceCaseAction;
        const {serviceCaseId} = payload;
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.PENDING},
        });

        const expandingBuffer = buffers.expanding(10);
        const batchUpdateChannel = yield actionChannel(
            serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
            expandingBuffer,
        );
        const completedBatchUpdateActions = [];
        while (true) {
            const {payload, meta} = nextBatchUpdateServiceCaseAction;
            const {caseType, serviceCaseData, serviceCaseId} = payload;

            yield fork(
                fetchRequest,
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST,
                ecsFlowService.updateServiceCase,
                {
                    caseType,
                    serviceCaseId,
                    serviceCasePatchDTO: getServiceCaseClass(caseType)
                        .objectToPatchDTO(serviceCaseData),
                },
                // metadata: caller
                {caller: meta?.caller, isBatch: true},
            );

            const batchUpdateResponseAction = yield take([
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.UPDATE_SERVICE_CASE_REQUEST_FAILED,
            ]);

            const {payload: responsePayload, error: responseError} = batchUpdateResponseAction;
            completedBatchUpdateActions.push({
                type: serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE_COMPLETED,
                payload: responsePayload,
                error: responseError,
                meta,
            });

            // exit if no more update requests, or continue processing
            if (expandingBuffer.isEmpty()) break;
            nextBatchUpdateServiceCaseAction = yield take(batchUpdateChannel);
        }

        const caller = nextBatchUpdateServiceCaseAction.meta?.caller || uuidv4();
        yield fork(
            fetchRequest,
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST,
            ecsFlowService.getServiceCase,
            {serviceCaseId},
            {caller},
        );

        let fetchServiceCaseResponseAction = {};
        while (true) {
            fetchServiceCaseResponseAction = yield take([
                serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_FAILED,
            ]);

            if (fetchServiceCaseResponseAction.meta?.caller && fetchServiceCaseResponseAction.meta.caller === caller) {
                break;
            }
        }

        if (!fetchServiceCaseResponseAction.error) {
            const {response} = fetchServiceCaseResponseAction.payload;
            const {serviceCaseDTO} = response;

            yield put({
                type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                payload: {serviceCaseDTOs: [serviceCaseDTO]},
            });

            // update caseIDs indicator for member vehicles
            const membershipNo = serviceCaseDTO?.member?.membershipNo;
            const {membersByMembershipNo} = yield select(state => state.members);
            const member = membersByMembershipNo && membershipNo ? membersByMembershipNo[membershipNo] : null;

            if (serviceCaseDTO?.vehicle && member?.vehicleIds) {
                const efService = serviceManager.loadService('ecsFlowService');

                yield fork(
                    fetchRequest,
                    serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST,
                    efService.getServiceCaseIdsForVehicles,
                    {vehicleIds: [...member.vehicleIds]},
                );

                const caseIdsResponseAction = yield take([
                    serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED,
                    serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED,
                ]);

                if (!caseIdsResponseAction.error) {
                    const caseIdsResponse = caseIdsResponseAction.payload.response;
                    const serviceCaseCountForVehicles = caseIdsResponse?.serviceCaseCountForVehicles || [];
                    const {vehicles = {}} = yield select(state => state.members);
                    const memberVehicles = Object.values(vehicles)
                        .filter(vehicle => member.vehicleIds.includes(vehicle.id))
                        .map(vehicle => ({
                            ...vehicle,
                            colour: vehicle.color,
                            caseIds: serviceCaseCountForVehicles.find(serviceCaseCountForVehicle => (
                                serviceCaseCountForVehicle.vehicleId === vehicle.id
                            ))?.caseIds,
                        }));

                    yield put({
                        type: memberActionTypes.STORE_MEMBER_VEHICLES,
                        payload: {
                            membershipNo,
                            memberVehicleDTOs: memberVehicles,
                        },
                    });
                }
            }
        }

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE,
            payload: {serviceCaseId, persistenceState: persistenceStates.READY},
        });

        // signal completed batch updates
        yield all(completedBatchUpdateActions
            .map(batchUpdateAction => put(batchUpdateAction)));
    }
};

export default batchUpdateServiceCaseFlow;
