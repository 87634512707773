import {select, take, fork, put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import savScreenTabs from '../../service-assignments-vehicle/savScreenTabs';
import sapScreenTabs from '../../service-assignments-pickup/sapScreenTabs';
import modalIds from '../../modalIds';

/*
* Service assignment: get QM feedback draft flow
*/
const loadServiceAssignmentQMFeedbackDraft = function* loadServiceAssignmentQMFeedbackDraft({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {match, location} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (!serviceAssignment) return;

    if (serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
        && ![savScreenTabs.CONTRACT_PARTNER, savScreenTabs.COMMISSIONING].includes(activeTab)
    ) return;

    if (serviceAssignment.assignmentType === efServiceAssignmentTypes.PICKUP
        && ![sapScreenTabs.SERVICE_PROVIDER, sapScreenTabs.COST_COVERAGE].includes(activeTab)
    ) return;

    if (location.query.modal
        || (location.state?.isModalClosed
            && [
                modalIds.SERVICE_ASSIGNMENT_QM_NOTES,
            ].includes(location.state?.modalId)
        )
    ) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('isDraft', 'true');
    searchQueryParams.append('caseId', serviceCaseId);
    searchQueryParams.append('lineNo', serviceAssignmentLineNo);

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST,
        partnerManagementService.getQualityManagementFeedbacks, {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerQualityManagementFeedbackDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS,
            payload: {
                qmFeedbackDraftDTOs: acePartnerQualityManagementFeedbackDTOs,
                serviceAssignmentId,
            },
        });
    }
};

export default loadServiceAssignmentQMFeedbackDraft;
