import React, {useState, useRef, useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, AutosuggestField, Option} from '@ace-de/ui-components';
import {ButtonPrimary, InputField, Checkbox, Form, TimeField, NumberInputField} from '@ace-de/ui-components/form';
import {Icon, InteractiveIcon, locationIcon, checkmarkIcon, resetIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import config from '../../config';

const TowingDestinationInfoPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_commissioning_tab');
    const {serviceAssignment, initiateUpdateServiceAssignmentFinalDestination} = props;
    const mainAssignment = serviceAssignment;
    const {searchAssignmentFinalDestinationLocationGeolocation} = props;
    const [finalDestinationData, setFinalDestinationData] = useState(mainAssignment?.finalDestination ? {
        ...mainAssignment.finalDestination,
        locationName: mainAssignment.finalDestination?.location?.locationName || '',
    } : null);
    const [isIdenticalToTowingDestination, setIsIdenticalToTowingDestination] = useState(
        !!mainAssignment?.finalDestination?.location?.id
        && !!mainAssignment?.towingDestination?.id
        && mainAssignment.finalDestination.location.id === mainAssignment.towingDestination.id,
    );
    const [newLocation, setNewLocation] = useState(mainAssignment?.finalDestination?.location || null);
    const [errors, setErrors] = useState({
        location: '',
        workingHoursFrom: '',
        workingHoursTo: '',
        alternativeWorkingHoursFrom: '',
        alternativeWorkingHoursTo: '',
    });
    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');
    const resetToDefaultStateRef = useRef(false);
    const searchFinalDestinationLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchAssignmentFinalDestinationLocationGeolocation === 'function') {
            return debounce(
                searchAssignmentFinalDestinationLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchAssignmentFinalDestinationLocationGeolocation]);

    const handleLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchFinalDestinationLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${mainAssignment.serviceCaseId}-${mainAssignment.lineNo}`,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.formattedAddress;
        setNewLocation(locationCandidate);
    };

    const handleResetFinalDestination = () => {
        resetToDefaultStateRef.current = true;
        setNewLocation(null);
        setFinalDestinationData(null);
        setIsIdenticalToTowingDestination(false);
        currentLocationAddress.current = '';
        lastLocationSearchQuery.current = '';
        initiateUpdateServiceAssignmentFinalDestination({
            serviceAssignmentLineNo: mainAssignment.lineNo,
            serviceCaseId: mainAssignment.serviceCaseId,
            serviceAssignmentData: {
                finalDestination: null,
            },
        });
    };

    const handleOnCheckboxChange = value => {
        resetToDefaultStateRef.current = true;
        setFinalDestinationData(value ? mainAssignment.towingDestination : null);
        setNewLocation(mainAssignment.towingDestination);
        setIsIdenticalToTowingDestination(value);
        if (!value) {
            setNewLocation(null);
            currentLocationAddress.current = '';
            lastLocationSearchQuery.current = '';
        }
        return;
    };

    const handleOnChange = formValues => {
        if (resetToDefaultStateRef.current) {
            resetToDefaultStateRef.current = false;
            return;
        }
        setFinalDestinationData(formValues);
    };

    const handleValidation = formValues => {
        let hasError = false;
        const updatedErrors = {
            location: '',
            workingHoursFrom: '',
            workingHoursTo: '',
            alternativeWorkingHoursFrom: '',
            alternativeWorkingHoursTo: '',
        };
        if (!newLocation?.id) {
            updatedErrors.location = translateTab('error_message.empty_address');
            hasError = true;
        }

        const timeRegex = /^(([0-1][0-9])|([2][0-3])):[0-5][0-9]$/;
        if (formValues.workingHoursFrom && !timeRegex.test(formValues.workingHoursFrom)) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if (formValues.workingHoursTo && !timeRegex.test(formValues.workingHoursTo)) {
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if ((!formValues.workingHoursFrom && formValues.workingHoursTo)
            || (!formValues.workingHoursTo && formValues.workingHoursFrom)
        ) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        // check if times are valid ('to' needs to be greater than 'from')
        if (formValues.workingHoursFrom && formValues.workingHoursTo
            && formValues.workingHoursFrom >= formValues.workingHoursTo
        ) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        if (formValues.alternativeWorkingHoursFrom && !timeRegex.test(formValues.alternativeWorkingHoursFrom)) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if (formValues.alternativeWorkingHoursTo && !timeRegex.test(formValues.alternativeWorkingHoursTo)) {
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if ((!formValues.alternativeWorkingHoursFrom && formValues.alternativeWorkingHoursTo)
            || (!formValues.alternativeWorkingHoursTo && formValues.alternativeWorkingHoursFrom)
        ) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        // check if times are valid ('to' needs to be greater than 'from')
        if (formValues.alternativeWorkingHoursFrom && formValues.alternativeWorkingHoursTo
            && formValues.alternativeWorkingHoursFrom >= formValues.alternativeWorkingHoursTo
        ) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        return {hasError, updatedErrors};
    };

    const handleOnSubmit = formValues => {
        // validate and update errors state
        const {hasError, updatedErrors} = handleValidation(formValues);
        setErrors(updatedErrors);
        if (hasError) return;

        initiateUpdateServiceAssignmentFinalDestination({
            serviceCaseId: mainAssignment.serviceCaseId,
            serviceAssignmentLineNo: mainAssignment.lineNo,
            serviceAssignmentData: {
                finalDestination: {
                    location: {
                        ...newLocation,
                        locationName: formValues.locationName,
                    },
                    phoneNo: formValues.phoneNo,
                    workingHoursFrom: formValues.workingHoursFrom,
                    workingHoursTo: formValues.workingHoursTo,
                    alternativeWorkingHoursFrom: formValues.alternativeWorkingHoursFrom,
                    alternativeWorkingHoursTo: formValues.alternativeWorkingHoursTo,
                    dailyFee: formValues.dailyFee,
                },
            },
        });
    };

    // if no towing destination don't render
    if (!mainAssignment?.towingDestination) return null;
    const {towingDestination} = mainAssignment;
    const {finalDestinationLocationSearchQuery, finalDestinationLocationCandidates} = mainAssignment;

    return (
        <Panel title={translateTab('panel_title.towing_destination')}>
            <div className={cx('global!ace-u-grid')}>
                <div className={cx('global!ace-u-grid-column--span-9', 'global!ace-u-margin--bottom-24')}>
                    <p className={cx('global!ace-u-typography--variant-body-medium')}>
                        {towingDestination.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {towingDestination.street}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {`${towingDestination.postCode} ${towingDestination.city}`}
                    </p>
                </div>
            </div>
            <Checkbox
                value={true}
                isSelected={isIdenticalToTowingDestination}
                onChange={handleOnCheckboxChange}
            >
                {translateTab('checkbox_label.apply_as_a_final_destination')}
            </Checkbox>
            <h3
                className={cx([
                    'global!ace-u-margin--top-16',
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-typography--color-default',
                    'global!ace-u-typography--variant-h3',
                ])}
            >
                {translateTab('headline_text.final_towing_destination')}
            </h3>
            <Form name="finalDestinationForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                {formValues => {
                    const isFinalDestinationSaved = !!mainAssignment.finalDestination?.location?.id
                        && !!newLocation?.id
                        && mainAssignment.finalDestination.location?.id === newLocation.id;
                    const isFormTouched = mainAssignment.finalDestination?.location?.locationName !== formValues.locationName // eslint-disable-line max-len
                        || mainAssignment.finalDestination?.phoneNo !== formValues.phoneNo
                        || mainAssignment.finalDestination?.workingHoursFrom !== formValues.workingHoursFrom
                        || mainAssignment.finalDestination?.workingHoursTo !== formValues.workingHoursTo
                        || mainAssignment.finalDestination?.alternativeWorkingHoursFrom !== formValues.alternativeWorkingHoursFrom // eslint-disable-line max-len
                        || mainAssignment.finalDestination?.alternativeWorkingHoursTo !== formValues.alternativeWorkingHoursTo // eslint-disable-line max-len
                        || `${mainAssignment.finalDestination?.dailyFee}` !== `${formValues.dailyFee}`;
                    return (
                        <Fragment>
                            <InputField
                                name="locationName"
                                label={translateTab('input_label.name')}
                                value={finalDestinationData?.locationName || ''}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-16'])}
                            />
                            <AutosuggestField
                                name="locationSearchQuery"
                                label={translateTab('input_label.address')}
                                value={newLocation?.formattedAddress || ''}
                                onChange={handleLocationSearchQueryChange}
                                onOptionSelect={handleLocationCandidateSelect}
                                optionValueSelector={locationCandidate => {
                                    return locationCandidate.formattedAddress;
                                }}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--16-0',
                                ])}
                                placeholder={translateTab('input_placeholder.please_fill_in')}
                                errors={errors?.location ? [errors.location] : []}
                            >
                                {(formValues['locationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                && finalDestinationLocationSearchQuery === lastLocationSearchQuery.current
                                    ? finalDestinationLocationCandidates
                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                        .map((locationCandidate, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    name={`relevant-location-candidate-${index}`}
                                                    value={locationCandidate}
                                                >
                                                    <Icon
                                                        icon={locationIcon}
                                                        className={cx('global!ace-u-margin--right-16')}
                                                    />
                                                    {locationCandidate.formattedAddress}
                                                </Option>
                                            );
                                        }) : null}
                            </AutosuggestField>
                            <InputField
                                name="phoneNo"
                                label={translateTab('input_label.phone_number')}
                                value={finalDestinationData?.phoneNo || ''}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-16'])}
                            />
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--column-gap-24', 'global!ace-u-margin--bottom-16'])}>
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <TimeField
                                        name="workingHoursFrom"
                                        label={translateTab('input_label.opening_hours_from')}
                                        value={finalDestinationData?.workingHoursFrom || ''}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        errors={errors?.workingHoursFrom ? [errors.workingHoursFrom] : []}
                                    />
                                </div>
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <TimeField
                                        name="workingHoursTo"
                                        label={translateTab('input_label.opening_hours_to')}
                                        value={finalDestinationData?.workingHoursTo || ''}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        errors={errors?.workingHoursTo ? [errors.workingHoursTo] : []}
                                    />
                                </div>
                            </div>
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--column-gap-24', 'global!ace-u-margin--bottom-16'])}>
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <TimeField
                                        name="alternativeWorkingHoursFrom"
                                        label={translateTab('input_label.opening_hours_from')}
                                        value={finalDestinationData?.alternativeWorkingHoursFrom || ''}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        errors={errors?.alternativeWorkingHoursFrom
                                            ? [errors.alternativeWorkingHoursFrom]
                                            : []}
                                    />
                                </div>
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <TimeField
                                        name="alternativeWorkingHoursTo"
                                        label={translateTab('input_label.opening_hours_to')}
                                        value={finalDestinationData?.alternativeWorkingHoursTo || ''}
                                        inputClassName={cx('global!ace-u-width--full')}
                                        errors={errors?.alternativeWorkingHoursTo
                                            ? [errors.alternativeWorkingHoursTo]
                                            : []}
                                    />
                                </div>
                            </div>
                            <NumberInputField
                                name="dailyFee"
                                label={translateTab('input_label.daily_stand_fee')}
                                value={finalDestinationData?.dailyFee || ''}
                                min={0}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-32'])}
                            />
                            <ButtonPrimary
                                name="submitFinalDestinationButton"
                                type="submit"
                                className={cx('global!ace-u-full-width', {
                                    'ace-c-button-primary--is-dark-positive': !!isFinalDestinationSaved && !isFormTouched,
                                })}
                            >
                                {isFinalDestinationSaved && !isFormTouched
                                    ? (
                                        <Fragment>
                                            {translateTab('button_label.accepted')}
                                            <Icon
                                                icon={checkmarkIcon}
                                                className={cx(
                                                    'ace-c-icon--color-contrast',
                                                    'global!ace-u-margin--left-16',
                                                )}
                                            />
                                        </Fragment>
                                    ) : translateTab('button_label.accept')
                                }
                            </ButtonPrimary>
                        </Fragment>
                    );
                }}
            </Form>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-margin--top-16',
                ])}
            >
                <InteractiveIcon
                    icon={resetIcon}
                    className={cx([
                        'ace-c-interactive-icon--reverse',
                        'ace-c-interactive-icon--highlight',
                    ])}
                    onClick={handleResetFinalDestination}
                >
                    {translateTab('button_label.reset')}
                </InteractiveIcon>
            </div>
        </Panel>
    );
};

TowingDestinationInfoPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    initiateUpdateServiceAssignmentFinalDestination: PropTypes.func.isRequired,
    searchAssignmentFinalDestinationLocationGeolocation: PropTypes.func.isRequired,
};

TowingDestinationInfoPanel.defaultProps = {
    serviceAssignment: {},
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateServiceAssignmentFinalDestination: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW,
        payload,
    }),
    searchAssignmentFinalDestinationLocationGeolocation: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TowingDestinationInfoPanel));
