import {produce} from 'immer';
import {Member, Vehicle, Address} from '@ace-de/eua-entity-types';
import * as memberActionTypes from './memberActionTypes';

const initialState = {
    areSearchResultsValid: false,
    memberSearchResults: [],
    memberSearchResultsCount: 0,
    memberSearchResultsPrivate: [],
    memberSearchResultsCountPrivate: 0,
    memberSearchResultsCorporate: [],
    memberSearchResultsCountCorporate: 0,
    members: {},
    membersByMembershipNo: {},
    vehicles: {},
    vehicleSearchResults: [],
};

/**
 * Members reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const memberReducer = produce((draft, action) => {
    switch (action.type) {
        case memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID: {
            const {areSearchResultsValid} = action.payload;
            draft.areSearchResultsValid = areSearchResultsValid;
            break;
        }

        case memberActionTypes.STORE_MEMBER_SEARCH_RESULTS: {
            const {memberSearchResults, memberSearchResultsCount} = action.payload;
            const {memberSearchResultsPrivate, memberSearchResultsCountPrivate} = action.payload;
            const {memberSearchResultsCorporate, memberSearchResultsCountCorporate} = action.payload;
            // all
            draft.memberSearchResults = memberSearchResults.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCount = memberSearchResultsCount;
            // private
            draft.memberSearchResultsPrivate = memberSearchResultsPrivate.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCountPrivate = memberSearchResultsCountPrivate;
            // corporate
            draft.memberSearchResultsCorporate = memberSearchResultsCorporate.map(memberDTO => {
                return new Member().fromDTO(memberDTO);
            });
            draft.memberSearchResultsCountCorporate = memberSearchResultsCountCorporate;
            break;
        }

        case memberActionTypes.SET_MEMBER_PERSISTENCE_STATE: {
            const {memberId, persistenceState} = action.payload;
            const draftMember = draft.members[memberId];
            if (draftMember) {
                draftMember.persistenceState = persistenceState;
                draft.membersByMembershipNo[draftMember.membershipNo].persistenceState = persistenceState;
            }
            break;
        }

        case memberActionTypes.STORE_MEMBER: {
            const {memberDTO} = action.payload;
            let member = draft.members[memberDTO.id];
            if (!member) {
                const newMember = new Member().fromDTO(memberDTO);
                draft.members[newMember.id] = newMember;
                draft.membersByMembershipNo[newMember.membershipNo] = newMember;
                break;
            }
            member = member.fromDTO(memberDTO);
            draft.members[member.id] = member;
            draft.membersByMembershipNo[member.membershipNo] = member;
            break;
        }

        case memberActionTypes.STORE_MEMBER_VEHICLES: {
            const {membershipNo, memberVehicleDTOs} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            memberVehicleDTOs.forEach(vehicleDTO => {
                const vehicle = new Vehicle().fromDTO(vehicleDTO);

                // Add vehicleIds array into `members` and `membersByMembershipNo`
                draft.vehicles[vehicle.id] = vehicle;
                // If vehicle.id does not exist in vehicleIds, add
                if (!draft.members[member.id].vehicleIds.includes(vehicle.id)) {
                    draft.members[member.id].vehicleIds.push(vehicle.id);
                }
                // If vehicle.id does not exist in vehicleIds, add
                if (!draft.membersByMembershipNo[member.membershipNo].vehicleIds.includes(vehicle.id)) {
                    draft.membersByMembershipNo[member.membershipNo].vehicleIds.push(vehicle.id);
                }
            });
            break;
        }

        case memberActionTypes.STORE_MEMBER_VEHICLE: {
            const {membershipNo, memberVehicleDTO} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            const vehicle = new Vehicle().fromDTO(memberVehicleDTO);

            // Add vehicleIds array into `members` and `membersByMembershipNo`
            draft.vehicles[vehicle.id] = vehicle;

            // If vehicle.id does not exist in vehicleIds, add
            if (!draft.members[member.id].vehicleIds.includes(vehicle.id)) {
                draft.members[member.id].vehicleIds.push(vehicle.id);
            }
            // If vehicle.id does not exist in vehicleIds, add
            if (!draft.membersByMembershipNo[member.membershipNo].vehicleIds.includes(vehicle.id)) {
                draft.membersByMembershipNo[member.membershipNo].vehicleIds.push(vehicle.id);
            }
            break;
        }

        case memberActionTypes.REMOVE_MEMBER_VEHICLE: {
            const {vehicleId} = action.payload;
            const memberId = draft.vehicles[vehicleId].memberId;
            delete draft.vehicles[vehicleId];
            // Remove vehicleId from members[memberId].vehicleIds
            const index = draft.members[memberId].vehicleIds.findIndex(id => id === vehicleId);
            if (index >= 0) {
                draft.members[memberId].vehicleIds.splice(index, 1);
                // Remove vehicleId from membersByMembershipNo[memberId].vehicleIds
                const membershipNo = draft.members[memberId].membershipNo;
                draft.membersByMembershipNo[membershipNo].vehicleIds.splice(index, 1);
            }
            break;
        }

        case memberActionTypes.STORE_VEHICLE_SEARCH_RESULTS: {
            const {vehicleSearchResults} = action.payload;
            draft.vehicleSearchResults = vehicleSearchResults.map(vehicleDTO => {
                return new Vehicle().fromDTO(vehicleDTO);
            });
            break;
        }

        case memberActionTypes.STORE_MEMBER_SERVICE_CASES: {
            const {membershipNo, serviceCaseIds} = action.payload;
            const member = draft.membersByMembershipNo[membershipNo];
            if (member) {
                draft.members[member.id].serviceCaseIds = serviceCaseIds;
                draft.membersByMembershipNo[membershipNo].serviceCaseIds = serviceCaseIds;
            }
            break;
        }

        case memberActionTypes.STORE_MEMBER_NOTE: {
            const {memberNoteDTO, memberId} = action.payload;
            const member = draft.members[memberId];

            draft.membersByMembershipNo[member.membershipNo].memberNotes.push(memberNoteDTO);
            draft.members[member.id].memberNotes.push(memberNoteDTO);
            break;
        }

        case memberActionTypes.REMOVE_MEMBER_NOTE: {
            const {memberId, memberNoteId} = action.payload;
            const member = draft.members[memberId];
            const memberByMembershipNumber = draft.membersByMembershipNo[member.membershipNo];

            if (member) {
                member.memberNotes = member.memberNotes
                    .filter(memberNote => memberNote.id !== memberNoteId);
            }

            if (memberByMembershipNumber) {
                memberByMembershipNumber.memberNotes = memberByMembershipNumber.memberNotes
                    .filter(memberNote => memberNote.id !== memberNoteId);
            }

            break;
        }

        case memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES: {
            const {addressCandidateDTOs, memberId, searchQueryString} = action.payload;
            const member = draft.members[memberId];
            member['memberAddressSearchQuery'] = searchQueryString;
            member['memberAddressCandidates'] = addressCandidateDTOs.map(addressCandidateDTO => (
                new Address().fromDTO(addressCandidateDTO)
            ));
            draft.membersByMembershipNo[member.membershipNo]['memberAddressSearchQuery'] = searchQueryString;
            draft.membersByMembershipNo[member.membershipNo]['memberAddressCandidates'] = member['memberAddressCandidates'];
            break;
        }

        case memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR: {
            const {hasError, memberId} = action.payload;
            const member = draft.members[memberId];
            member['memberAddressSearchError'] = hasError;
            draft.membersByMembershipNo[member.membershipNo]['memberAddressSearchError'] = hasError;
            break;
        }

        default:
        //     no-op
    }
    return draft;
}, initialState);

export default memberReducer;
