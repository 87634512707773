import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';

const serviceAssignmentLocationKeys = {
    [efServiceAssignmentTypes.RETURN_JOURNEY]: 'memberLocation',
    [efServiceAssignmentTypes.RENTAL_CAR]: 'memberLocation',
    [efServiceAssignmentTypes.ACCOMMODATION]: 'memberLocation',
    [efServiceAssignmentTypes.SHORT_TRIP]: 'memberLocation',
    [efServiceAssignmentTypes.OTHER_SERVICES]: 'damageLocation',
    [efServiceAssignmentTypes.DEATH]: 'serviceLocation',
    [efServiceAssignmentTypes.MEDICINE_SHIPMENT]: 'serviceLocation',
    [efServiceAssignmentTypes.MEDICAL_ADVICE]: 'serviceLocation',
    [efServiceAssignmentTypes.LEGAL_ADVICE]: 'serviceLocation',
};

export default serviceAssignmentLocationKeys;
