import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saspActionTypes from '../saspActionTypes';

/**
 * Update SASP service provider flow
 */
const updateSASPServiceProviderFlow = function* updateSASPServiceProviderFlow() {
    while (true) {
        const {payload} = yield take(saspActionTypes.SUBMIT_SASP_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;

        yield* updateServiceAssignment({
            caller: saspActionTypes.SUBMIT_SASP_SERVICE_PROVIDER_FORM,
            assignmentType: efServiceAssignmentTypes.SICK_PERSON,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saspActionTypes.SUBMIT_SASP_SERVICE_PROVIDER_FORM},
        });
    }
};

export default updateSASPServiceProviderFlow;
