import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as sapActionTypes from '../sapActionTypes';
import sapScreenTabs from '../sapScreenTabs';

const loadSAPMapContractPartnerWatcher = function* loadSAPMapContractPartnerWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== sapScreenTabs.SERVICE_PROVIDER) return;

    yield put({
        type: sapActionTypes.START_SAP_MAP_CONTRACT_PARTNER_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAPMapContractPartnerWatcher;
