import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Update SARC costs and budget flow
 */
const updateSARCCostsAndBudgetFlow = function* updateSARCCostsAndBudgetFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_COSTS_AND_BUDGET_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_COSTS_AND_BUDGET_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });
    }
};

export default updateSARCCostsAndBudgetFlow;
