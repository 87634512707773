export const ecsFeatureActions = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const ecsFeatures = {
    SERVICE_CASE: 'SERVICE_CASE',
    SERVICE_CASE_FILES: 'SERVICE_CASE_FILES',
    CASE_LOCK: 'CASE_LOCK',
    BLANK_CASE: 'BLANK_CASE',
    FOREIGN_CASE: 'FOREIGN_CASE',
    SERVICE_ASSIGNMENT: 'SERVICE_ASSIGNMENT',
    TASK: 'TASK',
    CONTRACT_PARTNER: 'CONTRACT_PARTNER',
    QUALITY_REPORT: 'QUALITY_REPORT',
    TEMPORARY_RESTRICTION: 'TEMPORARY_RESTRICTION',
    MEMBER_NOTE: 'MEMBER_NOTE',
    MEMBER_VEHICLE: 'MEMBER_VEHICLE',
    MEMBER_DATA: 'MEMBER_DATA',
    CASE_LOG: 'CASE_LOG',
    CASE_LOG_NOTE: 'CASE_LOG_NOTE',
    CASE_LOG_MESSAGE: 'CASE_LOG_MESSAGE',
    CASE_LOG_FILES: 'CASE_LOG_FILES',
    CASE_LOG_EXPORT: 'CASE_LOG_EXPORT',
    ONGOING_LEGAL_CASE: 'ONGOING_LEGAL_CASE',
    AGENT_IDENTITY: 'AGENT_IDENTITY',
    SHIFT_ROLE_SELECTION: 'SHIFT_ROLE_SELECTION',
};
