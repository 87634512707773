import moment from 'moment';
import {take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes, apsServiceTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignmentSubStatus from '../../service-assignments/sagas/updateServiceAssignmentSubStatus';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import modalIds from '../../modalIds';
import * as saaActionTypes from '../saaActionTypes';

const calculateNumberOfNights = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const areDatesValid = moment(startDate).isValid() && moment(endDate).isValid()
        && moment(endDate).isAfter(moment(startDate));
    return areDatesValid
        ? moment(endDate).diff(moment(startDate), 'days')
        : 0;
};

const calculateTotalCost = (
    serviceAssignment,
    numberOfNights,
    serviceAssignmentBudget,
    serviceAssignmentBudgetMaxDuration,
) => {
    const roundToAtMost2Decimals = num => {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const {totalCoverage, numberOfAdults, numberOfKids, budgetPerNight} = serviceAssignment;
    const numberOfPersons = numberOfAdults + numberOfKids;
    const budget = budgetPerNight || serviceAssignmentBudget;

    if (numberOfNights < 1) return totalCoverage;

    if (numberOfNights >= 1 && numberOfNights <= serviceAssignmentBudgetMaxDuration) {
        const total = parseFloat(budget) * numberOfNights * numberOfPersons;
        return roundToAtMost2Decimals(total);
    }

    const multiplierConstant = numberOfNights > serviceAssignmentBudgetMaxDuration
        ? serviceAssignmentBudgetMaxDuration : numberOfNights;

    const total = parseFloat(budget) * multiplierConstant * numberOfPersons;
    return roundToAtMost2Decimals(total);
};

/**
 * SAA extend stay end date flow (uses modal)
 */
const extendSAAStayEndDateFlow = function* extendSAAStayEndDateFlow() {
    while (true) {
        yield take(saaActionTypes.INITIATE_EXTEND_STAY_END_DATE_FLOW);

        yield* openModal(modalIds.SAA_EXTEND_STAY_END_DATE);

        const chosenModalOption = yield take([
            saaActionTypes.CONFIRM_SAA_EXTEND_STAY_END_DATE,
            saaActionTypes.DECLINE_SAA_EXTEND_STAY_END_DATE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === saaActionTypes.CONFIRM_SAA_EXTEND_STAY_END_DATE) {
            const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentSubStatusData} = chosenModalOption.payload;

            const updateServiceAssignmentStatusResponseAction = yield* updateServiceAssignmentSubStatus({
                caller: saaActionTypes.CONFIRM_SAA_EXTEND_STAY_END_DATE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentSubStatusData,
            });

            if (!updateServiceAssignmentStatusResponseAction.error) {
                const {response} = updateServiceAssignmentStatusResponseAction.payload;
                const {serviceAssignmentDTO} = response;
                const {serviceAssignmentBudgets, serviceAssignmentBudgetMaxDurations} = yield select(state => state.serviceAssignments); // eslint-disable-line max-len

                const numberOfNights = calculateNumberOfNights(
                    serviceAssignmentDTO.stayStartDate,
                    serviceAssignmentSubStatusData.extendedDate,
                );

                const totalCoverage = calculateTotalCost(
                    serviceAssignmentDTO,
                    numberOfNights,
                    serviceAssignmentBudgets?.[apsServiceTypes.ACCOMMODATION]?.value,
                    serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value,
                );

                const serviceAssignmentData = {
                    totalCoverage,
                    numberOfNights,
                    hotelCost: '',
                };

                yield* updateServiceAssignment({
                    caller: saaActionTypes.CONFIRM_SAA_EXTEND_STAY_END_DATE,
                    assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData,
                });
            }
        }

        yield* closeModal(modalIds.SAA_EXTEND_STAY_END_DATE);
    }
};

export default extendSAAStayEndDateFlow;
