/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT';
export const SUBMIT_SAMS_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SAMS_REQUIREMENTS_FORM';
export const SUBMIT_SAMS_SERVICE_LOCATION_FORM = '[service-cases] SUBMIT_SAMS_SERVICE_LOCATION_FORM';
export const SUBMIT_SAMS_JOURNEY_DATA_FORM = '[service-cases] SUBMIT_SAMS_JOURNEY_DATA_FORM';
export const SUBMIT_SAMS_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SAMS_COST_COVERAGE_FORM';
export const SUBMIT_SAMS_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SAMS_SERVICE_PROVIDER_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_FAILED';

/**
 * Store action types
 */
