import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Pill, Input, InputPhoneNumber, NumberInput, ToggleSwitch} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import config from '../../config';
import pillTypes from '../../service-cases/pillStateTypes';

const CommissioningBasicDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('commissioning_basic_data_panel');
    const {serviceCase, serviceAssignment, onChangeHandler} = props;
    const {selfPayer, agentUserProfile, maximumBudget, callbackPhoneNo} = props;
    const {additionalInformationText, isFallbackSystemIdEnabled, fallbackSystemId} = props;

    const isInitialVehicleAssignment = serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE && (
        serviceAssignment.lineNo === 'FBS1'
        || parseInt(serviceAssignment.lineNo, 10) === 1
        || serviceAssignment.isCloneFromInitial
    );

    return (
        <Panel
            title={translateTab('title.basic_data')}
            qaIdent="commissioning-basic-data-panel"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.created_at')}
                        qaIdent="sa-created-at"
                    >
                        {serviceAssignment?.createdAt
                            ? `${moment(serviceAssignment.createdAt).format('DD.MM.YYYY[,] HH:mm')}
                                ${translate('global.time_unit.time')}` : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.created_by')}
                        qaIdent="sa-created-by"
                    >
                        {agentUserProfile?.displayName || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.service')}
                        qaIdent="sa-service-type"
                    >
                        {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                            ? serviceAssignment?.serviceType
                                ? translate(`global.service_type.${serviceAssignment.serviceType.toLowerCase()}`) : '-'
                            : serviceAssignment?.assignmentType
                                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`) : '-'}
                    </DataRow>
                    {[
                        efServiceAssignmentTypes.MEDICINE_SHIPMENT,
                        efServiceAssignmentTypes.DEATH,
                        efServiceAssignmentTypes.MEDICAL_ADVICE,
                        efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
                        efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                        efServiceAssignmentTypes.LEGAL_ADVICE,
                    ].includes(serviceAssignment.assignmentType) && (
                        <Fragment>
                            {![
                                efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
                                efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                                efServiceAssignmentTypes.LEGAL_ADVICE,
                            ].includes(serviceAssignment.assignmentType) && (
                                <DataRow
                                    label={translateTab('data_row_label.service_location')}
                                    qaIdent="sa-service-location"
                                >
                                    {serviceAssignment?.serviceLocation?.formattedAddress || '-'}
                                </DataRow>
                            )}
                            <DataRow
                                label={translateTab('data_row_label.service_location_notes')}
                                qaIdent="sa-service-location-notes"
                            >
                                {serviceAssignment?.serviceLocation?.locationNotes || '-'}
                            </DataRow>
                        </Fragment>
                    )}
                    {[
                        efServiceAssignmentTypes.PICKUP,
                        efServiceAssignmentTypes.VEHICLE_RETURN,
                        efServiceAssignmentTypes.TRAILER_RETURN,
                    ].includes(serviceAssignment.assignmentType) && (
                        <DataRow
                            label={translateTab('data_row_label.additional_information')}
                            className={cx('ace-c-data-row--condensed')}
                            qaIdent="sa-additional-information"
                        >
                            <Input
                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                value={additionalInformationText}
                                onChange={value => onChangeHandler('additionalInformationText', value)}
                            />
                        </DataRow>
                    )}
                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.RENTAL_CAR && (
                        <Fragment>
                            <DataRow
                                label={translateTab('data_row_label.driver_licence')}
                                qaIdent="sa-driver-license-presented"
                            >
                                {translate(`global.label.${serviceAssignment?.driversLicensePresented
                                    ? 'available' : 'unavailable'}`)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.identity_card')}
                                qaIdent="sa-identity-card-presented"
                            >
                                {translate(`global.label.${serviceAssignment?.idCardPresented
                                    ? 'available' : 'unavailable'}`)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.credit_card')}
                                qaIdent="sa-credit-card-presented"
                            >
                                {translate(`global.label.${serviceAssignment?.creditCardPresented
                                    ? 'available' : 'unavailable'}`)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.ec_card')}
                                qaIdent="sa-ec-card-presented"
                            >
                                {translate(`global.label.${serviceAssignment?.ecCardPresented
                                    ? 'available' : 'unavailable'}`)}
                            </DataRow>
                        </Fragment>
                    )}
                    {![
                        efServiceAssignmentTypes.PICKUP,
                        efServiceAssignmentTypes.SICK_PERSON,
                        efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                        efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
                        efServiceAssignmentTypes.MEDICINE_SHIPMENT,
                        efServiceAssignmentTypes.DEATH,
                        efServiceAssignmentTypes.LEGAL_ADVICE,
                    ].includes(serviceAssignment.assignmentType)
                    && serviceCase?.nonAceMember
                    && serviceCase?.prefix !== `${config.ACE_COMMISSIONER_ID}` ? (
                        <DataRow
                            label={translateTab('data_row_label.external_assignment_number')}
                            qaIdent="sc-external-case-id"
                        >
                            {serviceCase?.externalCaseId || '-'}
                        </DataRow>
                        ) : null}
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.status')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-assignment-status"
                    >
                        {serviceAssignment?.assignmentStatus ? (
                            <Pill type={pillTypes[serviceAssignment.assignmentStatus]}>
                                {translate(`global.service_assignment_status_type.${
                                    serviceAssignment.assignmentStatus.toLowerCase()
                                }`)}
                            </Pill>
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.entitlement_check')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-entitlement-check"
                    >
                        <div>
                            <Pill
                                type={serviceAssignment?.entitlementCheck ? 'positive' : 'negative'}
                                className={cx('global!ace-u-typography--variant-body-medium')}
                                title={serviceAssignment?.entitlementCheck
                                    ? translate('global.entitlement_check.full_service_coverage')
                                    : translate('global.entitlement_check.no_service_coverage')}
                            >
                                {serviceAssignment?.entitlementCheck
                                    ? translate('global.entitlement_check.full_service_coverage')
                                    : translate('global.entitlement_check.no_service_coverage')}
                            </Pill>
                            {serviceAssignment.entitlementCheck && serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}` && (
                                <p className={cx('global!ace-u-padding--left-8')}>
                                    {translate('global.entitlement_check.entitlement_available')}
                                </p>
                            )}
                        </div>
                    </DataRow>
                    {serviceAssignment.assignmentType !== efServiceAssignmentTypes.RENTAL_CAR && (
                        <DataRow
                            label={(
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--grow-1',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <p className={cx('global!ace-u-margin--right-16')}>
                                        {translateTab('data_row_label.self_payers')}
                                    </p>
                                    <ToggleSwitch
                                        defaultIsSelected={!!selfPayer?.hasSelfPayerOption}
                                        onChange={value => onChangeHandler('hasSelfPayerOption', value)}
                                    />
                                </div>
                            )}
                            className={cx('ace-c-data-row--condensed')}
                            qaIdent="sa-has-self-payer-option"
                        >
                            <Input
                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                placeholder={translateTab('input_placeholder.reason')}
                                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                value={selfPayer?.selfPayerNote || ''}
                                onChange={value => onChangeHandler('selfPayerNote', value)}
                                isDisabled={!selfPayer?.hasSelfPayerOption}
                            />
                        </DataRow>
                    )}
                    <DataRow
                        label={(
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <p className={cx('global!ace-u-margin--right-16')}>
                                    {translateTab('data_row_label.fallback_system_id')}
                                </p>
                                <ToggleSwitch
                                    defaultIsSelected={!!isFallbackSystemIdEnabled}
                                    onChange={value => onChangeHandler('isFallbackSystemIdEnabled', !!value)}
                                />
                            </div>
                        )}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-fallback-system-id"
                    >
                        <Input
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            value={fallbackSystemId}
                            onChange={value => onChangeHandler('fallbackSystemId', value)}
                            isDisabled={config.IS_FALLBACK_SYSTEM || !isFallbackSystemIdEnabled}
                        />
                    </DataRow>
                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.RENTAL_CAR && (
                        <DataRow
                            label={translateTab('data_row_label.comment')}
                            qaIdent="sa-additional-request-text"
                        >
                            {serviceAssignment?.additionalInformationText || '-'}
                        </DataRow>
                    )}
                    {!isInitialVehicleAssignment && (
                        <DataRow
                            label={translateTab('data_row_label.callback_phone_no')}
                            className={cx('ace-c-data-row--condensed')}
                            qaIdent="sa-callback-phone-number"
                        >
                            <InputPhoneNumber
                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                value={callbackPhoneNo}
                                onChange={value => onChangeHandler('callbackPhoneNo', value)}
                            />
                        </DataRow>
                    )}
                    {![
                        efServiceAssignmentTypes.PICKUP,
                        efServiceAssignmentTypes.SICK_PERSON,
                        efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                        efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
                        efServiceAssignmentTypes.MEDICINE_SHIPMENT,
                        efServiceAssignmentTypes.DEATH,
                        efServiceAssignmentTypes.LEGAL_ADVICE,
                    ].includes(serviceAssignment.assignmentType)
                    && serviceCase?.nonAceMember
                    && serviceCase?.prefix !== `${config.ACE_COMMISSIONER_ID}` ? (
                        <DataRow
                            label={translateTab('data_row_label.maximum_budget', {
                                priceType: translate('global.pricing_type.net'),
                            })}
                            className={cx('ace-c-data-row--condensed')}
                            qaIdent="sa-maximum-budget"
                        >
                            <NumberInput
                                className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                placeholder={translateTab('input_placeholder.maximum_budget')}
                                min={0}
                                value={maximumBudget}
                                onChange={value => onChangeHandler('maximumBudget', value)}
                            />
                        </DataRow>
                        ) : null}
                </div>
            </div>
        </Panel>
    );
};

CommissioningBasicDataPanel.propTypes = {
    onChangeHandler: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    agentUserProfile: PropTypes.object,
    additionalInformationText: PropTypes.string,
    selfPayer: PropTypes.object,
    fallbackSystemId: PropTypes.string,
    isFallbackSystemIdEnabled: PropTypes.bool,
    callbackPhoneNo: PropTypes.string,
    maximumBudget: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

CommissioningBasicDataPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    agentUserProfile: null,
    additionalInformationText: '',
    selfPayer: null,
    fallbackSystemId: '',
    isFallbackSystemIdEnabled: false,
    callbackPhoneNo: '',
    maximumBudget: 0,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningBasicDataPanel));
