import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import contractPartnerScreenTabs from '../../contract-partners/contractPartnerScreenTabs';

/**
 * Search service cases
 */
const searchServiceCases = function* searchServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {searchQueryParams, membershipNo} = payload;

    // reset search results
    yield put({
        type: serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
        payload: {serviceCaseSearchResults: [], serviceCaseSearchResultsCount: 0},
    });

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST,
        ecsFlowService.searchServiceCases,
        {
            searchQueryParams,
            membershipNo,
        },
    );

    const searchServiceCasesResponseAction = yield take([
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!searchServiceCasesResponseAction.error) {
        const {response} = searchServiceCasesResponseAction.payload;
        const {results: serviceCaseSearchResults, totalCount: serviceCaseSearchResultsCount} = response;
        const activeTab = searchQueryParams.get('activeTab');

        if (activeTab === contractPartnerScreenTabs.CASE_HISTORY
            || activeTab === contractPartnerScreenTabs.BASIC_DATA) {
            yield put({
                type: contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
                payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
            });
            return;
        }

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
            payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
        });
    }
};

export default searchServiceCases;
