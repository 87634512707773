import {eventChannel} from 'redux-saga';
import {select, take, call, put} from 'redux-saga/effects';
import * as savActionTypes from '../savActionTypes';

const onSAVMapClickSelectTowingDestination = function* onSAVMapClickSelectTowingDestination(payload) {
    const {serviceManager} = yield select(state => state.application);
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle');
    const savTowingDestinationsLayer = yield call(arcGISMap.getLayer, 'sav-towing-destinations');
    const savTowingDestinationRoutesLayer = yield call(arcGISMap.getLayer, 'sav-towing-destination-routes');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (savTowingDestinationsLayer) {
            const unsubscribeClickListener = savTowingDestinationsLayer.on('feature-select', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: towingDestinationFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (towingDestinationFeatureDTO && !towingDestinationFeatureDTO.isSelected) {
            const selectedTowingDestinationLocationId = towingDestinationFeatureDTO['locationId'];

            yield call(savTowingDestinationRoutesLayer.selectFeatureByAttribute, {
                where: `locationId = '${selectedTowingDestinationLocationId}'`,
            });

            yield put({
                type: savActionTypes.SET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID,
                payload: {
                    serviceAssignmentId: `${serviceCaseId}-${serviceAssignmentLineNo}`,
                    selectedTowingDestinationLocationId: selectedTowingDestinationLocationId,
                },
            });
        }
    }
};

export default onSAVMapClickSelectTowingDestination;
