import {createSelector} from 'reselect';
import moment from 'moment';
import {etmTaskCategoryTypes} from '@ace-de/eua-entity-types';

/**
 * Tasks selectors
 */

export const getTasks = state => state.tasks.tasks;

export const getTaskSearchResults = state => state.tasks.taskSearchResults;

export const getAreSearchResultsValid = state => state.tasks.areSearchResultsValid;

export const getServiceCasesTasksCount = state => state.tasks.serviceCasesTasksCount;

export const getServiceCasesToDoTasksCount = state => state.tasks.serviceCasesToDoTasksCount;

export const createTaskSelector = () => createSelector(
    [
        getTasks,
        (state, props) => {
            return props.location.query.taskId;
        },
    ], (tasks, taskId) => {
        if (Object.keys(tasks).length === 0 || !taskId) return null;

        return tasks[taskId];
    },
);

export const createTasksSelector = () => createSelector(
    [
        getTasks,
        (state, props) => {
            return props.match.params.serviceCaseId;
        },
    ], (tasks, serviceCaseId) => {
        const taskIds = Object.keys(tasks);
        const taskList = [];

        if (taskIds.length === 0 || !serviceCaseId) return [];

        taskIds.forEach(taskId => {
            if (parseInt(tasks[taskId].assignedCaseId, 10) === parseInt(serviceCaseId, 10)) {
                taskList.push(tasks[taskId]);
            }
        });

        return taskList;
    },
);

export const createLatestToDoTaskSelector = () => createSelector(
    [
        getTasks,
        (state, props) => {
            return props.match.params.serviceCaseId;
        },
    ], (tasks, serviceCaseId) => {
        const taskIds = Object.keys(tasks);
        const taskList = [];

        if (taskIds.length === 0 || !serviceCaseId) return null;

        taskIds.forEach(taskId => {
            if (tasks[taskId].category === etmTaskCategoryTypes.TODO
                && parseInt(tasks[taskId].assignedCaseId, 10) === parseInt(serviceCaseId, 10)) {
                taskList.push(tasks[taskId]);
            }
        });

        const sortedToDoTasks = taskList
            .filter(task => !!(task.createdAt && moment(task.createdAt).isValid()))
            .sort((taskA, taskB) => {
                const taskADate = moment(taskA.createdAt);
                const taskBDate = moment(taskB.createdAt);
                return taskADate.isBefore(taskBDate) ? 1 : -1;
            });

        return sortedToDoTasks?.length ? sortedToDoTasks[0] : null;
    },
);

export const createServiceCaseToDoTaskCountSelector = () => createSelector(
    [
        getServiceCasesToDoTasksCount,
        (state, props) => {
            return props.match.params.serviceCaseId;
        },
    ], (serviceCasesToDoTasksCount, serviceCaseId) => {
        if (!serviceCaseId || !serviceCasesToDoTasksCount) return 0;

        return serviceCasesToDoTasksCount[serviceCaseId] || 0;
    },
);

export const createServiceCaseTaskCountSelector = () => createSelector(
    [
        getServiceCasesTasksCount,
        (state, props) => {
            return props.match.params.serviceCaseId;
        },
    ], (serviceCasesTasksCount, serviceCaseId) => {
        if (!serviceCaseId || !serviceCasesTasksCount) return '';

        return serviceCasesTasksCount[serviceCaseId] || '';
    },
);
