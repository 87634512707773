import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, RadioButton, Divider, Badge} from '@ace-de/ui-components';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';

const ServiceProviderItem = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('service_provider_item');
    const {serviceProvider, isSelected, onChange, isDisabled, serviceAssignment} = props;

    const handleOnChange = () => {
        if (typeof onChange === 'function') {
            onChange(serviceProvider.id);
        }
    };

    return (
        <div className={cx('global!ace-u-margin--0-24')}>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-margin--16-0',
                ])}
            >
                <RadioButton
                    id={`radio-button-${serviceProvider.id}`}
                    name={`radio-button-${serviceProvider.id}`}
                    value={serviceProvider.id}
                    isSelected={isSelected}
                    onChange={handleOnChange}
                    isDisabled={isDisabled}
                />
                <label
                    htmlFor={`radio-button-${serviceProvider.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-cursor--pointer',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--bottom-4',
                        ])}
                    >
                        {serviceProvider.name}
                        {typeof (serviceProvider.numOfAssignedAssignments) === 'number' && (
                            <Badge status="default" className={cx('global!ace-u-margin--left-8')}>
                                {serviceProvider.numOfAssignedAssignments}
                            </Badge>
                        )}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {`${serviceProvider.address?.street
                            ? `${serviceProvider.address.street}, `
                            : ''}${serviceProvider.address?.postCode || ''} ${serviceProvider.address?.city || ''}`}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                        {translateTab('radio_button_label_details.tel')}
                        &nbsp;
                        {serviceProvider.businessContactDetails?.phoneNo || ''}
                    </p>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-caption',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-margin--4-0-8',
                        ])}
                    >
                        {![
                            efServiceAssignmentTypes.OTHER_SERVICES,
                            efServiceAssignmentTypes.RETURN_JOURNEY,
                        ].includes(serviceAssignment?.assignmentType)
                            && serviceProvider.distance && (
                            <span>
                                {translateTab('radio_button_label_details.distance')}
                                &nbsp;
                                {Math.round(serviceProvider.distance)}
                                {translate('global.unit.km')}
                            </span>
                        )}
                        {![
                            efServiceAssignmentTypes.OTHER_SERVICES,
                            efServiceAssignmentTypes.RETURN_JOURNEY,
                            efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                        ].includes(serviceAssignment?.assignmentType)
                        && serviceProvider?.location?.routeToReferentialPoint && (
                            <span>
                                {translateTab('radio_button_label_details.total_kilometers')}
                                &nbsp;
                                {Math.round(serviceProvider.location.routeToReferentialPoint.totalKilometers)}
                                {translate('global.unit.km')}
                            </span>
                        )}
                    </div>
                </label>
            </div>
            <Divider />
        </div>
    );
};

ServiceProviderItem.propTypes = {
    serviceProvider: PropTypes.object,
    serviceAssignment: PropTypes.object,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
};

ServiceProviderItem.defaultProps = {
    serviceProvider: {},
    serviceAssignment: {},
    isSelected: false,
    isDisabled: false,
    onChange: null,
};

export default ServiceProviderItem;
