import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Update service assignment text flow
 * updates the text field on a service assignment
 */
const updateServiceAssignmentTextFlow = function* updateServiceAssignmentTextFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW);
        const {serviceAssignmentData, serviceAssignments, serviceCaseId} = payload;

        if (!serviceAssignmentData || !serviceAssignments || !serviceCaseId) continue;

        let index = 0;
        const assignments = Object.values(serviceAssignments);
        while (index < assignments.length) {
            const serviceAssignment = assignments[index];
            yield call(updateServiceAssignment, {
                caller: `${serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW}-${serviceAssignment.lineNo}`,
                assignmentType: serviceAssignment.assignmentType,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
            index += 1;
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW},
        });
    }
};

export default updateServiceAssignmentTextFlow;
