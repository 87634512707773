import {take, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';

const createCaseLogPDFFlow = function* createCaseLogPDFFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    while (true) {
        const {payload} = yield take(caseLogActionTypes.SUBMIT_CREATE_CASE_LOG_PDF);
        const {serviceCaseId, searchQueryParams} = payload;

        yield fork(
            fetchRequest,
            caseLogActionTypes.CREATE_CASE_LOG_PDF_REQUEST,
            ecsHistoryManagementService.createCaseLogPDF,
            {
                searchQueryParams,
                serviceCaseId,
            },
        );

        yield take([
            caseLogActionTypes.CREATE_CASE_LOG_PDF_REQUEST_SUCCEEDED,
            caseLogActionTypes.CREATE_CASE_LOG_PDF_REQUEST_FAILED,
        ]);

        // NOTE: errors are handled implicitly as part of the fetchRequest
        // below you can handle something in addition if needed
    }
};

export default createCaseLogPDFFlow;
