/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_SHORT_TRIP = '[service-cases] CREATE_SERVICE_ASSIGNMENT_SHORT_TRIP';
export const SUBMIT_SAST_TRIP_DETAILS_FORM = '[service-cases] SUBMIT_SAST_TRIP_DETAILS_FORM';
export const SEARCH_SAST_DESTINATION_GEOLOCATION = '[service-cases] SEARCH_SAST_DESTINATION_GEOLOCATION';
export const SUBMIT_SAST_MEMBER_LOCATION_FORM = '[service-cases] SUBMIT_SAST_MEMBER_LOCATION_FORM';
export const SUBMIT_SAST_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SAST_COST_COVERAGE_FORM';
export const SUBMIT_SAST_BUDGET_FORM = '[service-cases] SUBMIT_SAST_BUDGET_FORM';
export const SUBMIT_SAST_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SAST_SERVICE_PROVIDER_FORM';
export const CALCULATE_MEMBER_LOCATION_TO_DESTINATION_DISTANCE = '[service-cases] CALCULATE_MEMBER_LOCATION_TO_DESTINATION_DISTANCE';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST';
export const SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAST_DESTINATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST = '[service-cases] FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST';
export const FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT';
export const FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAST_DESTINATION_CANDIDATES = '[service-cases] SET_SAST_DESTINATION_CANDIDATES';
export const SET_SAST_DESTINATION_CANDIDATE_DISTANCE = '[service-cases] SET_SAST_DESTINATION_CANDIDATE_DISTANCE';
