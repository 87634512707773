export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateFax = fax => {
    const re = /^([+])[0-9]{8,15}$/;
    return re.test(fax);
};

export const validatePhoneNo = phoneNo => {
    const regex = /^([+])[0-9]{8,15}$/;
    return regex.test(phoneNo);
};
