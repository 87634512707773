import React from 'react';
import PropTypes from 'prop-types';
import {useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, ButtonSecondary, Icon, InteractiveIcon, HighlightCircle} from '@ace-de/ui-components';
import {closeIcon, acceptedIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';

const SAVAssignmentSuccessfulModal = ({hasBackdrop}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const {closeModal} = useRouter();
    const translateModal = createTranslateShorthand('sav_assignment_successful_modal');

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => closeModal(modalIds.SAV_ASSIGNMENT_SUCCESSFUL)}
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--success',
                    ])}
                >
                    <Icon
                        icon={acceptedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-success',
                        ])}
                    />
                </HighlightCircle>
                <h3>{translateModal('headline_text.request_sent_successfully')}</h3>
                <ButtonSecondary
                    name="closeModalButton"
                    className={cx('global!ace-u-margin--top-32')}
                    onClick={() => closeModal(modalIds.SAV_ASSIGNMENT_SUCCESSFUL)}
                >
                    {translateModal('button.close')}
                </ButtonSecondary>
            </div>
        </Modal>
    );
};

SAVAssignmentSuccessfulModal.propTypes = {
    hasBackdrop: PropTypes.bool,
};

SAVAssignmentSuccessfulModal.defaultProps = {
    hasBackdrop: true,
};

export default SAVAssignmentSuccessfulModal;
