import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageShortTripDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sast_cost_coverage_tab');
    const {serviceAssignment} = props;
    const personsAtSite = {
        adults: serviceAssignment.grownUpsAtSite,
        children: serviceAssignment.childrenAtSite,
        animals: serviceAssignment.petsAtSite,
    };

    return (
        <Panel
            title={translateTab('panel_title.short_trip_data')}
            qaIdent="sast-short-trip-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.transportation_type')}
                        qaIdent="sa-transportation-type"
                    >
                        {serviceAssignment.transportationType
                            ? translate(`global.service_assignment_transportation_type.${serviceAssignment.transportationType.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    {serviceAssignment.memberLocation && (
                        <DataRow
                            label={translateTab('data_row_label.pickup_location')}
                        >
                            {serviceAssignment.memberLocation.formattedAddress || '-'}
                        </DataRow>
                    )}
                    {(
                        personsAtSite.adults > 0
                        || personsAtSite.children > 0
                        || personsAtSite.animals > 0
                    ) && (
                    <DataRow label={translateTab('data_row_label.persons')}>
                        {Object.entries(personsAtSite).map(([key, value]) => {
                            if (key === 'children' && serviceAssignment.childrenAge?.length) {
                                return `${value} ${translateTab(`data_row_text.${key}`)}
                                (${serviceAssignment.childrenAge.map(age => (
                                    `${age} ${translateTab('data_row_text.year')}`
                                ))})`;
                            }
                            if (value && value > 0) return `${value} ${translateTab(`data_row_text.${key}`)}`;
                            return null;
                        }).filter(value => !!value).join(', ')}
                    </DataRow>
                    )}
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.destination')}
                        qaIdent="sa-destination-address"
                    >
                        {serviceAssignment.destination?.formattedAddress || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageShortTripDataPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageShortTripDataPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageShortTripDataPanel));
