/*
* SASD location types
*/
const sasdLocationTypes = {
    GOAL_DESTINATION: 'GOAL_DESTINATION',
    PICKUP_LOCATION_VEHICLE: 'PICKUP_LOCATION_VEHICLE',
    PICKUP_LOCATION_COMPANION: 'PICKUP_LOCATION_COMPANION',
};

export default sasdLocationTypes;
