import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase, snakeCase} from 'change-case';
import moment from 'moment';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceCaseStatusTypes, efServiceCaseContactChannelTypes, efServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Pill, NoResultsBlock, calendarIcon, AutocompleteField, Paginator} from '@ace-de/ui-components';
import {Form, InputField, DateField, SelectField, Option, ButtonIcon} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, searchIcon, findCaseIcon, InteractiveIcon, resetIcon, arrowDownIcon, arrowUpIcon} from '@ace-de/ui-components/icons';
import routePaths from '../routePaths';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import config from '../config';
import contractPartnerScreenTabs from './contractPartnerScreenTabs';

const initialServiceCaseFilteringParams = {
    searchTerm: '',
    createdAfter: '',
    createdBefore: '',
    caseStatus: '',
    damageLocation: '',
    contactChannel: '',
    licensePlateNumber: '',
    memberName: '',
    commissionerId: '',
    serviceType: '',
    agentId: '',
};

const ContractPartnerCaseHistoryTab = props => {
    const {cx} = useStyles();
    const resetRef = useRef(false);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_case_history_tab');
    const {history, match, serviceCaseSearchResults, serviceCaseSearchResultsCount, users, commissioners} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState(initialServiceCaseFilteringParams);
    const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);
    const resetAdvancedFiltersRef = useRef(false);
    const paginatorCount = Math.ceil(serviceCaseSearchResultsCount / config.DEFAULT_PAGE_SIZE);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'caseStatus') {
                    apiQueryParams.append(`${formField}`, formData[formField]);
                    return;
                }

                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) {
            return;
        }

        const createdBefore = formValues.createdBefore && moment(formValues.createdBefore).isValid()
            ? moment(formValues.createdBefore).endOf('day').format()
            : formValues.createdBefore;

        const apiQueryParams = formatQueryParams({
            ...formValues,
            createdBefore,
            activeTab: contractPartnerScreenTabs.CASE_HISTORY,
        });
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        const {contractPartnerId} = match?.params;
        history.replace(resolveRoute(routePaths.CONTRACT_PARTNER, {contractPartnerId}, {search: queryParamsString}));
    };

    const onChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        // form data should contain all fields, even if they are not visible (advanced search dropdown is not expanded)
        // with this approach, we are avoiding having undefined as a value for non-visible fields
        setFilterFormData({
            ...initialServiceCaseFilteringParams,
            ...(!resetAdvancedFiltersRef.current ? formValues : {}),
        });

        if (resetAdvancedFiltersRef.current) {
            resetAdvancedFiltersRef.current = false;
        }
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        resetAdvancedFiltersRef.current = true;
        setFilterFormData(initialServiceCaseFilteringParams);
        const {contractPartnerId} = match?.params;
        history.replace(resolveRoute(routePaths.CONTRACT_PARTNER, {contractPartnerId}, {search: '', activeTab: contractPartnerScreenTabs.CASE_HISTORY}));
    };

    const formatCreatedBeforeDate = () => {
        if (queryParams.get('createdBefore')) {
            const date = queryParams.get('createdBefore');
            return moment(date).isValid() ? moment(date, 'YYYY-MM-DD').format() : date;
        }
        return filterFormData.createdBefore;
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);
        apiQueryParams.set('activeTab', contractPartnerScreenTabs.CASE_HISTORY);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        const {contractPartnerId} = match?.params;
        history.replace(resolveRoute(routePaths.CONTRACT_PARTNER, {contractPartnerId}, {search: queryParamsString}));
    };

    const expandFilters = () => {
        setAreFiltersExpanded(!areFiltersExpanded);
    };

    return (
        <Fragment>
            <Panel
                title={translateTab('panel.title')}
                contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}
            >
                <Form name="serviceCasesSearchForm" onSubmit={handleOnSubmit} onChange={onChange}>
                    {formValues => {
                        return (
                            <Fragment>
                                <div className={cx('global!ace-u-grid')}>
                                    <div className={cx('global!ace-u-grid-column--span-7')}>
                                        <InputField
                                            label={translateTab('input_field_label.free_search')}
                                            name="searchTerm"
                                            value={queryParams.get('searchTerm') || filterFormData.searchTerm}
                                            className={cx('global!ace-u-full-width')}
                                            onKeyDown={() => handleOnSubmit(formValues)}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <DateField
                                            label={translateTab('date_field_label.date_from')}
                                            name="createdAfter"
                                            value={queryParams.get('createdAfter') || filterFormData.createdAfter}
                                            icon={calendarIcon}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-1')}>
                                        <DateField
                                            label={translateTab('date_field_label.date_to')}
                                            name="createdBefore"
                                            value={formatCreatedBeforeDate()}
                                            icon={calendarIcon}
                                        />
                                    </div>
                                    <div className={cx('global!ace-u-grid-column--span-2')}>
                                        <SelectField
                                            placeholder={translateTab('select_field_placeholder.status')}
                                            label={translateTab('select_field_label.status')}
                                            name="caseStatus"
                                            className={cx('global!ace-u-full-width')}
                                            value={queryParams.get('caseStatus') || filterFormData.caseStatus}
                                        >
                                            {Object.keys(efServiceCaseStatusTypes).filter(statusType => {
                                                return statusType !== efServiceCaseStatusTypes.UNKNOWN;
                                            }).map(serviceCaseStatusType => {
                                                return (
                                                    <Option
                                                        key={serviceCaseStatusType}
                                                        name={`serviceCaseStatus${camelCase(serviceCaseStatusType)}`}
                                                        value={serviceCaseStatusType}
                                                    >
                                                        {translate(`global.service_case_status_type.${snakeCase(serviceCaseStatusType)}`)}
                                                    </Option>
                                                );
                                            })}
                                        </SelectField>
                                    </div>
                                    <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                                        <ButtonIcon
                                            name="searchButton"
                                            icon={searchIcon}
                                            onClick={() => handleOnSubmit(formValues)}
                                            className={cx('global!ace-u-margin--top-4')}
                                        />
                                    </div>
                                </div>
                                {areFiltersExpanded && (
                                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-48')}>
                                        <div className={cx('global!ace-u-grid-column--span-2')}>
                                            <InputField
                                                label={translateTab('input_field_label.damage_location')}
                                                name="damageLocation"
                                                value={queryParams.get('damageLocation') || filterFormData.damageLocation}
                                                className={cx('global!ace-u-full-width')}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-1')}>
                                            <SelectField
                                                label={translateTab('select_field_label.channel')}
                                                name="contactChannel"
                                                className={cx('global!ace-u-full-width')}
                                                value={queryParams.get('contactChannel') || filterFormData.contactChannel}
                                            >
                                                {Object.keys(efServiceCaseContactChannelTypes).map(channelType => {
                                                    return (
                                                        <Option
                                                            key={channelType}
                                                            name={`serviceCaseStatus${camelCase(channelType)}`}
                                                            value={channelType}
                                                        >
                                                            {translate(`global.service_case_channel_type.${snakeCase(channelType)}`)}
                                                        </Option>
                                                    );
                                                })}
                                            </SelectField>
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-1')}>
                                            <InputField
                                                label={translateTab('input_field_label.license_plate_no')}
                                                name="licensePlateNumber"
                                                value={queryParams.get('licensePlateNumber') || filterFormData.licensePlateNumber}
                                                className={cx('global!ace-u-full-width')}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-1')}>
                                            <InputField
                                                label={translateTab('input_field_label.member_name')}
                                                name="memberName"
                                                value={queryParams.get('memberName') || filterFormData.memberName}
                                                className={cx('global!ace-u-full-width')}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-2')}>
                                            <SelectField
                                                label={translateTab('select_field_label.commissioner')}
                                                name="commissionerId"
                                                className={cx('global!ace-u-full-width')}
                                                value={queryParams.get('commissionerId')?.includes('ne|')
                                                    ? serviceCaseSearchResults.length
                                                    && serviceCaseSearchResults[0].commissioner.id
                                                    : queryParams.get('commissionerId') || filterFormData.commissionerId}
                                            >
                                                {Object.keys(commissioners).map((commissionerId, idx) => (
                                                    <Option
                                                        key={`${commissionerId}-${idx}`}
                                                        name={`${commissionerId}-${idx}`}
                                                        value={commissionerId}
                                                    >
                                                        {commissioners[commissionerId].name}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-2')}>
                                            <SelectField
                                                label={translateTab('select_field_label.services')}
                                                name="serviceType"
                                                className={cx('global!ace-u-full-width')}
                                                value={queryParams.get('serviceType') || filterFormData.serviceType}
                                            >
                                                {Object.keys(efServiceTypes).map(serviceType => (
                                                    <Option
                                                        key={serviceType}
                                                        name={`serviceType${camelCase(serviceType)}`}
                                                        value={serviceType}
                                                    >
                                                        {translate(`global.service_type.${snakeCase(serviceType)}`)}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                        </div>
                                        <div className={cx('global!ace-u-grid-column--span-2')}>
                                            <AutocompleteField
                                                name="agentId"
                                                label={translateTab('select_field_label.nrb')}
                                                value={queryParams.get('agentId') || filterFormData.agentId}
                                                className={cx('global!ace-u-full-width')}
                                                isArrowIconDisplayed={true}
                                            >
                                                {Object.keys(users).map((option, index) => (
                                                    <Option
                                                        key={`${option}-${index}`}
                                                        name={`${option}-${index}`}
                                                        value={option}
                                                    >
                                                        {`${users[option].firstName || ''} ${users[option].lastName || ''}`}
                                                    </Option>
                                                ))}
                                            </AutocompleteField>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-space-between',
                                        'global!ace-u-margin--top-32',
                                    ])}
                                >
                                    <div>
                                        <span className={cx('global!ace-u-typography--variant-body')}>
                                            {translateTab('label.result_count')}
                                        </span>&nbsp;
                                        <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                            {serviceCaseSearchResultsCount}
                                        </span>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-margin--right-24',
                                        ])}
                                    >
                                        <div className={cx('global!ace-u-margin--right-64')}>
                                            <InteractiveIcon
                                                icon={resetIcon}
                                                className={cx([
                                                    'ace-c-interactive-icon--reverse',
                                                    'ace-c-interactive-icon--highlight',
                                                ])}
                                                onClick={handleResetFilter}
                                            >
                                                {translateTab('interactive_icon_label.reset_filter')}
                                            </InteractiveIcon>
                                        </div>
                                        <div>
                                            <InteractiveIcon
                                                icon={!areFiltersExpanded ? arrowDownIcon : arrowUpIcon}
                                                className={cx([
                                                    'ace-c-interactive-icon--reverse',
                                                    'ace-c-interactive-icon--primary',
                                                    'ace-c-interactive-icon--highlight',
                                                ])}
                                                onClick={expandFilters}
                                            >
                                                {translateTab('interactive_icon_label.advanced_search')}
                                            </InteractiveIcon>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            </Panel>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <Table qaIdent="service-case-search-results">
                    {serviceCaseSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateTab('no_results.message')}
                                description={translateTab('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="sc-created-at">
                                {translateTab('case_history_table_cell.fall_system')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-id">
                                {translateTab('case_history_table_cell.case_number')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-status">
                                {translateTab('case_history_table_cell.status')}
                            </TableCell>
                            <TableCell qaIdentPart="member-membership-no">
                                {translateTab('case_history_table_cell.membership_no')}
                            </TableCell>
                            <TableCell qaIdentPart="member-last-name">
                                {translateTab('case_history_table_cell.last_name')}
                            </TableCell>
                            <TableCell qaIdentPart="member-first-name">
                                {translateTab('case_history_table_cell.first_name')}
                            </TableCell>
                            <TableCell qaIdentPart="vehicle-licence-plate-number">
                                {translateTab('case_history_table_cell.license_plate_no')}
                            </TableCell>
                            <TableCell qaIdentPart="damage-location-address">
                                {translateTab('case_history_table_cell.damage_location')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-service-type">
                                {translateTab('case_history_table_cell.service')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-commissioner">
                                {translateTab('case_history_table_cell.commissioner')}
                            </TableCell>
                            <TableCell qaIdentPart="sc-created-by">
                                {translateTab('case_history_table_cell.nrb')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceCaseSearchResults.length > 0 && serviceCaseSearchResults.map(serviceCase => {
                            const {member, vehicle, damage, contactChannel} = serviceCase;
                            const initials = users[serviceCase.createdBy]
                                ? users[serviceCase.createdBy]?.initials?.toUpperCase()
                                : '';
                            const createdByInitials = contactChannel !== efServiceCaseContactChannelTypes.APP ? `${initials}` : efServiceCaseContactChannelTypes.APP;
                            const damageLocation = damage && damage.location
                                ? `${damage.location.postCode} ${damage.location.city}`
                                : '';

                            return (
                                <TableRow
                                    key={serviceCase.id}
                                    qaIdentPart={serviceCase.id}
                                >
                                    <TableCell
                                        qaIdentPart="sc-created-at"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        <div className={cx('global!ace-u-full-width')}>
                                            {serviceCase.createdAt
                                                ? moment(serviceCase.createdAt).format('DD.MM.YYYY, HH:mm') : ''}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-id"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.id}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-status"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                                            <Pill
                                                type={serviceCase.status === efServiceCaseStatusTypes.NEW
                                                    ? 'positive' : 'pending'}
                                            >
                                                {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                                            </Pill>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-membership-no"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {member ? member.membershipNo : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-last-name"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {member ? member.personalDetails.surname : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="member-first-name"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {member ? member.personalDetails.firstName : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="vehicle-licence-plate-number"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="damage-location-address"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        <div title={damageLocation}>
                                            {damageLocation}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-service-type"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase && serviceCase.serviceType
                                            ? translate(`global.service_type.${serviceCase.serviceType.toLowerCase()}`)
                                            : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-commissioner"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {serviceCase?.commissioner ? serviceCase?.commissioner?.name : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-created-by"
                                        qaIdentPartPostfix={serviceCase.id}
                                    >
                                        {createdByInitials}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Panel>
            {serviceCaseSearchResults.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

ContractPartnerCaseHistoryTab.propTypes = {
    history: PropTypes.object.isRequired,
    serviceCaseSearchResults: PropTypes.array,
    serviceCaseSearchResultsCount: PropTypes.number,
    users: PropTypes.object,
    commissioners: PropTypes.object,
    match: PropTypes.object,
};

ContractPartnerCaseHistoryTab.defaultProps = {
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    users: {},
    commissioners: {},
    match: {},
};

const mapStateToProps = (state, props) => {
    return {
        serviceCaseSearchResults: contractPartnerSelectors.getServiceCaseSearchResults(state),
        serviceCaseSearchResultsCount: contractPartnerSelectors.getServiceCaseSearchResultsCount(state),
        users: userProfileSelectors.getUsers(state),
        commissioners: contractPartnerSelectors.getCommissioners(state),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerCaseHistoryTab));
