import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as savActionTypes from './savActionTypes';
import DamageDescriptionPanel from './ui-elements/DamageDescriptionPanel';
import DamageCategoryPanel from './ui-elements/DamageCategoryPanel';
import DamageDetailsPanel from './ui-elements/DamageDetailsPanel';
import ManualServiceSelectionPanel from './ui-elements/ManualServiceSelectionPanel';

const SAVDamageServiceTab = props => {
    const {cx} = useStyles();
    const {serviceCase, submitSAVDamageSymptomsForm, damageNodes, match} = props;
    const {damageNodeSnapshots, serviceType, id: serviceCaseId, damage, serviceSelectionType, damageType} = serviceCase;
    const {params} = match;
    const {serviceAssignmentLineNo} = params;
    const [damageNodeSnapshotState, setDamageNodeSnapshotState] = useState(damageNodeSnapshots);
    const [serviceCaseTypeState, setServiceCaseTypeState] = useState(serviceType);
    const [damageState, setDamageState] = useState(damage);
    const [damageDescription, setDamageDescription] = useState(serviceCase?.damageDescription || '');

    const handleTabChange = useCallback((
        damageNodeSnapshots,
        serviceType,
        damage,
        damageDescription,
        serviceSelectionType,
    ) => {
        submitSAVDamageSymptomsForm({
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceCaseData: {
                damageNodeSnapshots,
                damageDescription,
                serviceType,
                serviceSelectionType,
                damage: {
                    description: damage?.description || '',
                    damageType,
                },
            },
        });
    }, [submitSAVDamageSymptomsForm, serviceCaseId, serviceAssignmentLineNo, damageType]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!damageNodeSnapshotState) {
            completeRouteUnmountSideEffect({
                caller: savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM,
            });
            return;
        }

        handleTabChange(
            damageNodeSnapshotState,
            serviceCaseTypeState,
            damageState,
            damageDescription,
            serviceSelectionType,
        );
    }, [
        damageNodeSnapshotState,
        serviceCaseTypeState,
        damageState,
        damageDescription,
        handleTabChange,
        serviceSelectionType,
    ]);

    useEffect(() => {
        if (damageNodeSnapshots) {
            setDamageNodeSnapshotState(damageNodeSnapshots);
        }
        if (typeof serviceType === 'string') {
            setServiceCaseTypeState(serviceType);
        }
        if (damage) {
            setDamageState(damage);
        }
    }, [damageNodeSnapshots, serviceType, damage]);

    // if no case or damage nodes
    if (!serviceCase || Object.keys(damageNodes).length === 0) return null;

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <DamageCategoryPanel />
                {damageNodeSnapshots.length > 0 && (
                    damageNodeSnapshots.map(decisionNode => {
                        return (
                            <DamageDetailsPanel
                                key={decisionNode}
                                decision={decisionNode}
                            />
                        );
                    })
                )}
                <DamageDescriptionPanel
                    damageDescription={damageDescription}
                    setDamageDescription={setDamageDescription}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <ManualServiceSelectionPanel />
            </ContentItem>
        </ContentBlock>
    );
};

SAVDamageServiceTab.propTypes = {
    damageNodes: PropTypes.object,
    serviceCase: PropTypes.object,
    submitSAVDamageSymptomsForm: PropTypes.func,
    match: PropTypes.object,
};

SAVDamageServiceTab.defaultProps = {
    serviceCase: null,
    damageNodes: null,
    submitSAVDamageSymptomsForm: () => null,
    match: {},
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        damageNodes: serviceCaseSelectors.getServiceTypeDamageNodes(state),
    };
};
const mapDispatchToProps = dispatch => ({
    submitSAVDamageSymptomsForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVDamageServiceTab));
