/**
 * Route paths
 */
export default {
    ROOT: '/',
    KEYCLOAK_AUTHORIZE: '/oidc/authorize',
    KEYCLOAK_AUTHENTICATE: '/oidc/authenticate',
    ARCGIS_AUTHORIZE: '/oauth2/authorize',
    ARCGIS_AUTHENTICATE: '/oauth2/authenticate',
    SHIFT_ROLE_SELECTION: '/shift-role-selection',
    SYSTEM_ACCESS_DENIED: '/access/403',
    ROUTE_ACCESS_DENIED: '/403',
    DASHBOARD_SECTION: '/dashboard',
    DASHBOARD: '/dashboard',
    SERVICE_CASE_SEARCH: '/dashboard/service-case-search/:vehicleId?',
    MEMBER_SEARCH: '/dashboard/member-search',
    TASK_SEARCH: '/dashboard/task-search/tasks',
    CONTRACT_PARTNER_SEARCH: '/dashboard/contract-partner-search',
    MEMBER: '/members/:membershipNo',
    CONTRACT_PARTNER: '/contract-partners/:contractPartnerId',
    SERVICE_CASE_SECTION: '/service-cases/:serviceCaseId',
    SERVICE_CASE: '/service-cases/:serviceCaseId',
    SERVICE_ASSIGNMENT: '/service-cases/:serviceCaseId/:serviceAssignmentType/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_VEHICLE: '/service-cases/:serviceCaseId/service-assignments-vehicle/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_RENTAL_CAR: '/service-cases/:serviceCaseId/service-assignments-rental-car/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_ACCOMMODATION: '/service-cases/:serviceCaseId/service-assignments-accommodation/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_SHORT_TRIP: '/service-cases/:serviceCaseId/service-assignments-short-trip/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_RETURN_JOURNEY: '/service-cases/:serviceCaseId/service-assignments-return-journey/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER: '/service-cases/:serviceCaseId/service-assignments-substitute-driver/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE: '/service-cases/:serviceCaseId/service-assignments-medical-clearance/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_SICK_PERSON: '/service-cases/:serviceCaseId/service-assignments-sick-person/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_VEHICLE_RETURN: '/service-cases/:serviceCaseId/service-assignments-vehicle-return/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL: '/service-cases/:serviceCaseId/service-assignments-vehicle-additional/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_OTHER_SERVICES: '/service-cases/:serviceCaseId/service-assignments-other-services/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_PICKUP: '/service-cases/:serviceCaseId/service-assignments-pickup/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_TRAILER_RETURN: '/service-cases/:serviceCaseId/service-assignments-trailer-return/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_DEATH_EVENT_HELP: '/service-cases/:serviceCaseId/service-assignments-death-event-help/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT: '/service-cases/:serviceCaseId/service-assignments-medicine-shipment/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_MEDICAL_ADVICE: '/service-cases/:serviceCaseId/service-assignments-medical-advice/:serviceAssignmentLineNo',
    SERVICE_ASSIGNMENT_LEGAL_ADVICE: '/service-cases/:serviceCaseId/service-assignments-legal-advice/:serviceAssignmentLineNo',
};
