/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_TRAILER_RETURN = '[service-cases] CREATE_SERVICE_ASSIGNMENT_TRAILER_RETURN';
export const SUBMIT_SATR_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SATR_SERVICE_PROVIDER_FORM';
export const SUBMIT_SATR_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SATR_REQUIREMENTS_FORM';
export const SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION';
export const SEARCH_SATR_DESTINATION_GEOLOCATION = '[service-cases] SEARCH_SATR_DESTINATION_GEOLOCATION';
export const CALCULATE_PICKUP_LOCATION_TO_DESTINATION_DISTANCE = '[service-cases] CALCULATE_PICKUP_LOCATION_TO_DESTINATION_DISTANCE';
export const SUBMIT_SATR_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SATR_COST_COVERAGE_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST';
export const SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SATR_DESTINATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SENT';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SATR_PICKUP_LOCATION_CANDIDATES = '[service-cases] SET_SATR_PICKUP_LOCATION_CANDIDATES';
export const SET_SATR_DESTINATION_CANDIDATES = '[service-cases] SET_SATR_DESTINATION_CANDIDATES';
export const SET_SATR_PICKUP_LOCATION_TO_DESTINATION_DISTANCE = '[service-cases] SET_SATR_PICKUP_LOCATION_TO_DESTINATION_DISTANCE';
export const RESET_SATR_PICKUP_LOCATION_CANDIDATES = '[service-cases] RESET_SATR_PICKUP_LOCATION_CANDIDATES';
export const RESET_SATR_DESTINATION_CANDIDATES = '[service-cases] RESET_SATR_DESTINATION_CANDIDATES';
