import {take, select, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as ehmCaseLogActionTypes from '../caseLogActionTypes';

const noteDocumentDownloadFlow = function* noteDocumentDownloadFlow() {
    const {serviceManager} = yield select(state => state.application);
    const efFileAssetsService = serviceManager.loadService('efFileAssetsService');

    while (true) {
        const {payload} = yield take(ehmCaseLogActionTypes.SUBMIT_NOTE_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(fetchRequest, ehmCaseLogActionTypes.DOWNLOAD_NOTE_DOCUMENT_REQUEST,
            efFileAssetsService.downloadFile, {
                fileName,
                url,
            });
    }
};

export default noteDocumentDownloadFlow;
