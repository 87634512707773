import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../../members/memberActionTypes';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import getMemberServiceCaseCounts from '../../members/sagas/getMemberServiceCaseCounts';

const loadSCMember = function* loadSCMember({snapshotMemberDTO, nonAceMember}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');
    const efService = serviceManager.loadService('ecsFlowService');
    const {membershipNo} = snapshotMemberDTO || {};

    if (!membershipNo) return;

    if (nonAceMember) {
        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {memberDTO: snapshotMemberDTO},
        });
    }

    if (!nonAceMember) {
        yield fork(
            fetchRequest,
            memberActionTypes.FETCH_MEMBER_REQUEST,
            membersService.getMember,
            {membershipNo},
        );

        const responseFetchMemberDetailsAction = yield take([
            memberActionTypes.FETCH_MEMBER_REQUEST_SUCCEEDED,
            memberActionTypes.FETCH_MEMBER_REQUEST_FAILED,
        ]);

        if (responseFetchMemberDetailsAction.error) return;

        const {response: fetchMemberDetailsResponse} = responseFetchMemberDetailsAction.payload;
        const {memberDTO} = fetchMemberDetailsResponse;

        const serviceCaseCountForMember = yield* getMemberServiceCaseCounts([memberDTO.id]);

        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {
                memberDTO: {
                    ...memberDTO,
                    serviceCaseCount: serviceCaseCountForMember[0]?.caseCount,
                },
            },
        });
    }

    yield fork(
        fetchRequest,
        memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST,
        membersService.getMemberVehicles,
        {membershipNo},
    );

    const responseFetchMemberVehiclesAction = yield take([
        memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_FAILED,
    ]);

    if (responseFetchMemberVehiclesAction.error) return;

    const {response: fetchMemberVehiclesResponse} = responseFetchMemberVehiclesAction.payload;
    const {memberVehicleDTOs} = fetchMemberVehiclesResponse;

    if (memberVehicleDTOs.length === 0) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST,
        efService.getServiceCaseIdsForVehicles,
        {vehicleIds: memberVehicleDTOs.map(vehicle => (vehicle.id))},
    );

    const caseIdsResponseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED,
    ]);

    if (caseIdsResponseAction.error) return;
    const caseIdsResponse = caseIdsResponseAction.payload.response;
    const serviceCaseCountForVehicles = caseIdsResponse?.serviceCaseCountForVehicles || [];
    const memberVehicles = memberVehicleDTOs.map(vehicle => ({
        ...vehicle,
        caseIds: serviceCaseCountForVehicles.find(serviceCaseCountForVehicle => (
            serviceCaseCountForVehicle.vehicleId === vehicle.id
        ))?.caseIds || [],
    }));

    yield put({
        type: memberActionTypes.STORE_MEMBER_VEHICLES,
        payload: {
            membershipNo,
            memberVehicleDTOs: memberVehicles,
        },
    });
};

export default loadSCMember;
