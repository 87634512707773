import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savaActionTypes from '../savaActionTypes';

/**
 * Search service location geolocation
 */
const searchSAVAServiceLocationGeolocation = function* searchSAVAServiceLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId, location} = payload;

    yield fork(
        fetchRequest,
        savaActionTypes.SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {
            singleLine: searchQueryString,
            ...(!!location && {location}),
            outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region'],
        },
    );

    const searchServiceLocationGeolocation = yield take([
        savaActionTypes.SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        savaActionTypes.SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchServiceLocationGeolocation.error) {
        const {response} = searchServiceLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: savaActionTypes.SET_SAVA_SERVICE_LOCATION_CANDIDATES,
            payload: {
                locationKey: 'serviceLocation',
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchSAVAServiceLocationGeolocation;
