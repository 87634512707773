import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {efTransportationTypes, efServiceAssignmentTypes, apmContractPartnerAssignmentChannelTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as saspActionTypes from './saspActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import SASPCommissioningAgreedPricePanel from './ui-elements/SASPCommissioningAgreedPricePanel';
import CommissioningDiagnoseAndInsurancePanel from '../service-assignments/ui-elements/CommissioningDiagnoseAndInsurancePanel';
import CommissioningContactPersonPanel from '../service-assignments/ui-elements/CommissioningContactPersonPanel';
import CommissioningJourneyDataPanel from '../service-assignments/ui-elements/CommissioningJourneyDataPanel';
import CommissioningRelevantPlacesPanel from './ui-elements/CommissioningRelevantPlacesPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import config from '../config';
import VehicleInfoPanel from '../service-cases/ui-elements/VehicleInfoPanel';
import TrailerInfoPanel from '../service-assignments/ui-elements/TrailerInfoPanel';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SASPCommissioningTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, submitSASPCommissioningForm, users, submitCreateSASPPDF} = props;
    const {submitSASPCommissioningServiceProviderForm, serviceAssignments} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const newestMedicalClearanceAssignment = useMemo(() => {
        return serviceAssignments
            .sort((assignmentA, assignmentB) => {
                const createdAtA = assignmentA.createdAt && moment(assignmentA.createdAt).isValid()
                    ? moment(assignmentA.createdAt) : null;
                const createdAtB = assignmentB.createdAt && moment(assignmentB.createdAt).isValid()
                    ? moment(assignmentB.createdAt) : null;
                if (createdAtA && createdAtB) {
                    return createdAtA.isAfter(createdAtB) ? -1 : 1;
                }
                return 0;
            })
            .find(assignment => assignment.assignmentType === efServiceAssignmentTypes.MEDICAL_CLEARANCE);
    }, [serviceAssignments]);
    const [assignmentText, setAssignmentText] = useState(serviceAssignment?.assignmentText || '');
    const [totalCoverage, setTotalCoverage] = useState(serviceAssignment.totalCoverage);
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    const onChangeHandler = (key, value) => {
        switch (key) {
            case 'assignmentText': {
                setAssignmentText(value);
                break;
            }

            case 'callbackPhoneNo': {
                setCallbackPhoneNo(value);
                break;
            }

            case 'fallbackSystemId': {
                setFallbackSystemId(value);
                break;
            }

            case 'isFallbackSystemIdEnabled': {
                setIsFallbackSystemIdEnabled(value);
                setFallbackSystemId('');
                break;
            }

            case 'hasSelfPayerOption': {
                setSelfPayer({
                    ...selfPayer,
                    [key]: value,
                    selfPayerNote: '',
                });
                break;
            }

            case 'selfPayerNote': {
                if (value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
                    setSelfPayer({
                        ...selfPayer,
                        [key]: value,
                    });
                }
                break;
            }

            case 'totalCoverage': {
                if (value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
                    setTotalCoverage(value);
                }
                break;
            }

            default:
                break;
        }
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM,
            });
            return;
        }

        submitSASPCommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                totalCoverage,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        serviceCase.caseType,
        assignmentText,
        totalCoverage,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        submitSASPCommissioningForm,
        attachmentsToSendWithAssignment,
    ]);

    const handleOnSend = serviceProviderData => {
        const assignment = (() => {
            if (![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                .includes(serviceAssignment.assignmentStatus)
            ) {
                return serviceAssignment;
            }
            return newestMedicalClearanceAssignment || null;
        })();
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            assignmentText,
            totalCoverage,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            clinicNumber: assignment.clinicNumber,
            ...serviceProviderData,
        };

        submitSASPCommissioningServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.SICK_PERSON,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    const submitCreatePDF = serviceProviderData => {
        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            assignmentText,
            totalCoverage,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            ...serviceProviderData,
        };

        submitCreateSASPPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <SASPCommissioningAgreedPricePanel
                    assignmentText={assignmentText}
                    totalCoverage={totalCoverage}
                    onChangeHandler={onChangeHandler}
                />
                <CommissioningContactPersonPanel
                    title={translate('sasp_commissioning_tab.panel_title.contact_person_at_location')}
                    // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
                    contactPerson={![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                        .includes(serviceAssignment.assignmentStatus)
                        ? serviceAssignment?.contactPersonOnSite
                        : newestMedicalClearanceAssignment?.contactPersonOnSite}
                />
                <CommissioningContactPersonPanel
                    title={translate('sasp_commissioning_tab.panel_title.affected')}
                    // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
                    contactPerson={![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                        .includes(serviceAssignment.assignmentStatus)
                        ? serviceAssignment?.affectedPerson
                        : newestMedicalClearanceAssignment?.affectedPerson}
                    isAffectedPerson={true}
                />
                <CommissioningContactPersonPanel
                    title={translate('sasp_commissioning_tab.panel_title.contact_person_at_home')}
                    // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
                    contactPerson={![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                        .includes(serviceAssignment.assignmentStatus)
                        ? serviceAssignment?.contactPersonAtHome
                        : newestMedicalClearanceAssignment?.contactPersonAtHome}
                />
                <CommissioningDiagnoseAndInsurancePanel
                    newestMedicalClearanceAssignment={newestMedicalClearanceAssignment}
                />
                <CommissioningJourneyDataPanel
                    newestMedicalClearanceAssignment={newestMedicalClearanceAssignment}
                />
                {serviceCase.transportationType === efTransportationTypes.PERSONAL_VEHICLE ? (
                    <VehicleInfoPanel />
                ) : null}
                {serviceCase.trailer ? (
                    <TrailerInfoPanel />
                ) : null}
                <CommissioningRelevantPlacesPanel
                    newestMedicalClearanceAssignment={newestMedicalClearanceAssignment}
                />
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <CommissioningACEPartnerPanel
                        assignmentChannels={[apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]}
                        handleOnAssignmentSubmit={handleOnSend}
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        submitCreatePDFForm={submitCreatePDF}
                    />
                    {!config.IS_FALLBACK_SYSTEM && (
                        <DocumentUploadPanel
                            attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                            setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                        />
                    )}
                </div>
            </ContentItem>
        </ContentBlock>
    );
};

SASPCommissioningTab.propTypes = {
    submitSASPCommissioningForm: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    serviceAssignments: PropTypes.array,
    users: PropTypes.object,
    submitSASPCommissioningServiceProviderForm: PropTypes.func.isRequired,
    submitCreateSASPPDF: PropTypes.func.isRequired,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SASPCommissioningTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    serviceAssignments: [],
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        serviceAssignments: serviceAssignmentsSelector(state, props),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSASPCommissioningForm: payload => dispatch({
        type: saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM,
        payload,
    }),
    submitSASPCommissioningServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSASPPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SASPCommissioningTab));
