import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarjActionTypes from '../sarjActionTypes';

/**
 * Update SARJ member location flow
 */
const updateSARJMemberLocationFlow = function* updateSARJMemberLocationFlow() {
    while (true) {
        const {payload} = yield take(sarjActionTypes.SUBMIT_SARJ_MEMBER_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sarjActionTypes.SUBMIT_SARJ_MEMBER_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceAssignmentData.memberLocation});
    }
};

export default updateSARJMemberLocationFlow;
