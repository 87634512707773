import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as savActionTypes from './savActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CommissioningServiceAndDamageDescription from './ui-elements/CommissioningServiceAndDamageDescription';
import CommissioningMemberDetailsPanel from './ui-elements/CommissioningMemberDetailsPanel';
import CommissioningPeopleOnSitePanel from './ui-elements/CommissioningPeopleOnSitePanel';
import CommissioningContractPartnerPanel from './ui-elements/CommissioningContractPartnerPanel';
import TowingDestinationInfoPanel from './ui-elements/TowingDestinationInfoPanel';
import DamageLocationInfoPanel from '../service-cases/ui-elements/DamageLocationInfoPanel';
import DamageNotePanel from './ui-elements/DamageNotePanel';
import config from '../config';
import VehicleInfoPanel from '../service-cases/ui-elements/VehicleInfoPanel';
import TrailerInfoPanel from '../service-assignments/ui-elements/TrailerInfoPanel';
import {formatDefaultServiceAssignmentText, formatServiceAssignmentTextOnChange} from './formatServiceAssignmentText';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SAVCommissioningTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, submitSAVCommissioningForm, users, submitCreateSAVPDF} = props;
    const {updateSAVAssignmentStatus, hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [damageNote, setDamageNote] = useState(serviceCase?.damage?.additionalDescription || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);
    const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);

    const formattedAssignmentText = formatDefaultServiceAssignmentText({serviceAssignment, serviceCase, translate});

    const [assignmentText, setAssignmentText] = useState(formattedAssignmentText || serviceAssignment.assignmentText);
    const [totalCost, setTotalCost] = useState(serviceAssignment?.totalCost || '');

    useEffect(() => {
        if (!serviceAssignment?.totalCost) return;
        setTotalCost(serviceAssignment.totalCost);
    }, [serviceAssignment.totalCost]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    useEffect(() => {
        serviceAssignment.assignmentText
            ? setAssignmentText(serviceAssignment.assignmentText)
            : setAssignmentText(formattedAssignmentText);
    }, [serviceAssignment.assignmentText, setAssignmentText, formattedAssignmentText]);

    const onChangeHandler = (key, value) => {
        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: !!value,
                ...(!value && {selfPayerNote: ''}),
            });
            if (serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}` || !isDACH) return;
            setAssignmentText(formatServiceAssignmentTextOnChange({
                serviceAssignment,
                serviceCase,
                text: value,
                translate,
            }));
            return;
        }

        if (key === 'selfPayerNote' && value.length > config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) return;

        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'totalCost') {
            setTotalCost(value);
            return;
        }

        setSelfPayer({
            ...selfPayer,
            [key]: value,
        });
    };

    const handleTabChange = useCallback(() => {
        submitSAVCommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        assignmentText,
        serviceAssignment.lineNo,
        serviceCase.id,
        serviceCase.caseType,
        submitSAVCommissioningForm,
        selfPayer.hasSelfPayerOption,
        selfPayer.selfPayerNote,
        maximumBudget,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        serviceCase.nonAceMember,
        serviceAssignment.assignmentType,
        attachmentsToSendWithAssignment,
        totalCost,
    ]);

    useRouteUnmountEffect(() => {
        handleTabChange();
    }, [handleTabChange]);

    const submitContractPartnerAssignmentChannels = formValues => {
        updateSAVAssignmentStatus({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
                acePartner: {
                    assignmentChannels: formValues['assignmentChannelsRequested'] || [],
                    businessContactDetails: {
                        email: formValues['email'],
                        faxNo: formValues['faxNo'],
                    },
                    contactDetails: null,
                    rank: serviceAssignment?.acePartner?.rank || null,
                    assignment2ndEmail: formValues['assignment2ndEmail'],
                },
            },
            serviceCaseData: {
                maximumBudget,
                damage: {
                    additionalDescription: damageNote,
                },
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    };

    const submitCreatePDF = formValues => {
        submitCreateSAVPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                totalCost,
                acePartner: {
                    assignmentChannels: formValues['assignmentChannelsRequested'] || [],
                    businessContactDetails: {
                        email: formValues['email'],
                        faxNo: formValues['faxNo'],
                    },
                    contactDetails: null,
                },
            },
            newServiceAssignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            serviceCaseData: {
                maximumBudget,
                damage: {
                    additionalDescription: damageNote,
                },
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.serviceType.toLowerCase()}`)
                : serviceAssignment.serviceType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                />
                <CommissioningServiceAndDamageDescription
                    assignmentText={assignmentText}
                    totalCost={totalCost}
                    onChangeHandler={onChangeHandler}
                />
                <VehicleInfoPanel />
                {serviceCase.trailer ? (
                    <TrailerInfoPanel />
                ) : null}
                <CommissioningMemberDetailsPanel serviceCase={serviceCase} />
                <CommissioningPeopleOnSitePanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningContractPartnerPanel
                    submitContractPartnerAssignmentChannels={submitContractPartnerAssignmentChannels}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                <DamageLocationInfoPanel damage={serviceCase.damage} />
                <TowingDestinationInfoPanel />
                <DamageNotePanel
                    serviceCase={serviceCase}
                    damageNote={damageNote}
                    setDamageNote={setDamageNote}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SAVCommissioningTab.propTypes = {
    updateSAVAssignmentStatus: PropTypes.func.isRequired,
    submitSAVCommissioningForm: PropTypes.func.isRequired,
    submitCreateSAVPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SAVCommissioningTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    updateSAVAssignmentStatus: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_STATUS_CHANGE,
        payload,
    }),
    submitSAVCommissioningForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM,
        payload,
    }),
    submitCreateSAVPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVCommissioningTab));
