import {fork, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';

const createPowerOfAttorneyTaskFlow = function* createPowerOfAttorneyTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    while (true) {
        yield take(serviceAssignmentActionTypes.INITIATE_POWER_OF_ATTORNEY_TASK_CREATION_FLOW);

        yield* closeModal(modalIds.POWER_OF_ATTORNEY_SUCCESSFUL);
        yield* openModal(modalIds.TASK_DATA, {type: 'POWER_OF_ATTORNEY'});
        const chosenModalOption = yield take([
            taskActionTypes.CONFIRM_TASK_CREATION,
            taskActionTypes.DECLINE_TASK_CREATION,
        ]);

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.CONFIRM_TASK_CREATION) {
            const {taskData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                taskActionTypes.CREATE_TASK_REQUEST,
                ecsTaskManagementService.createTask,
                {
                    taskDTO: Task.objectToDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.CREATE_TASK_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {taskDTO} = response;
                const taskDTOs = [taskDTO];

                yield put({
                    type: taskActionTypes.STORE_TASKS,
                    payload: {taskDTOs},
                });
            }
        }

        yield* closeModal(modalIds.TASK_DATA, {type: 'POWER_OF_ATTORNEY'});
    }
};

export default createPowerOfAttorneyTaskFlow;
