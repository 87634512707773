import {take, fork, put, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sastActionTypes from '../sastActionTypes';

/**
 * Calculate the distance between member's location and destination
 */
const calculateMemberLocationToDestinationDistance = function* calculateMemberLocationToDestinationDistance() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(sastActionTypes.CALCULATE_MEMBER_LOCATION_TO_DESTINATION_DISTANCE);
        const {serviceAssignmentId, destination, memberLocation} = payload;

        // calculate the distance beetwen member's location and destination
        if (memberLocation) {
            yield fork(
                fetchRequest,
                sastActionTypes.FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: memberLocation.longitude,
                        latitude: memberLocation.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                sastActionTypes.FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED,
                sastActionTypes.FETCH_MEMBER_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                const distanceResidenceToDestination = arcGISRouteDTO.totalKilometers;

                yield put({
                    type: sastActionTypes.SET_SAST_DESTINATION_CANDIDATE_DISTANCE,
                    payload: {distanceResidenceToDestination, serviceAssignmentId},
                });
            }
        }
    }
};

export default calculateMemberLocationToDestinationDistance;
