import {all, fork, takeLatest} from 'redux-saga/effects';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import searchServiceCases from './sagas/searchServiceCases';
import createServiceCaseFlow from './sagas/createServiceCaseFlow';
import batchUpdateServiceCaseFlow from './sagas/batchUpdateServiceCaseFlow';
import scDocumentUploadFlow from './sagas/scDocumentUploadFlow';
import scDocumentDeleteFlow from './sagas/scDocumentDeleteFlow';
import serviceCaseLockTimer from './sagas/serviceCaseLockTimer';
import scDocumentDownloadFlow from './sagas/scDocumentDownloadFlow';
import updateSCBasicDataFlow from './sagas/updateSCBasicDataFlow';
import updateDocumentDescriptionFlow from './sagas/updateDocumentDescriptionFlow';
import createServiceCaseVehicleFlow from './sagas/createServiceCaseVehicleFlow';
import getVehicleManufacturersFlow from './sagas/getVehicleManufacturersFlow';
import getVehicleModelsFlow from './sagas/getVehicleModelsFlow';
import getVehicleVariantsFlow from './sagas/getVehicleVariantsFlow';
import updateServiceCaseVehicleDataFlow from './sagas/updateServiceCaseVehicleDataFlow';
import selectServiceCaseVehicleFlow from './sagas/selectServiceCaseVehicleFlow';
import editServiceCaseVehicleFlow from './sagas/editServiceCaseVehicleFlow';
import deleteServiceCaseFlow from './sagas/deleteServiceCaseFlow';
import toggleOngoingLegalCase from './sagas/toggleOngoingLegalCase';
import searchServiceCasesByVehicleId from './sagas/searchServiceCasesByVehicleId';

/**
 *  Service case watcher saga
 */
const serviceCaseWatcher = function* serviceCaseWatcher() {
    yield all([
        takeLatest(serviceCaseActionTypes.SEARCH_SERVICE_CASES, searchServiceCases),
        takeLatest(serviceCaseActionTypes.SEARCH_SERVICE_CASES_BY_VEHICLE_ID, searchServiceCasesByVehicleId),
        fork(createServiceCaseFlow),
        fork(batchUpdateServiceCaseFlow),
        fork(scDocumentUploadFlow),
        fork(scDocumentDeleteFlow),
        fork(scDocumentDownloadFlow),
        takeLatest(serviceCaseActionTypes.START_SERVICE_CASE_LOCK_TIMER, serviceCaseLockTimer),
        fork(updateDocumentDescriptionFlow),
        fork(updateSCBasicDataFlow),
        fork(createServiceCaseVehicleFlow),
        fork(getVehicleManufacturersFlow),
        fork(getVehicleModelsFlow),
        fork(getVehicleVariantsFlow),
        fork(updateServiceCaseVehicleDataFlow),
        fork(selectServiceCaseVehicleFlow),
        fork(editServiceCaseVehicleFlow),
        fork(deleteServiceCaseFlow),
        takeLatest(serviceCaseActionTypes.TOGGLE_ONGOING_LEGAL_CASE, toggleOngoingLegalCase),
    ]);
};

export default serviceCaseWatcher;
