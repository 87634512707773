import {createSelector} from 'reselect';
import moment from 'moment';

/**
 * General state selectors
 */
export const getContractPartners = state => state.contractPartners.contractPartners;
export const getContractPartnerSearchResults = state => state.contractPartners.contractPartnerSearchResults;
export const getContractPartnerSearchResultsCount = state => state.contractPartners.contractPartnerSearchResultsCount;
export const getServiceCaseSearchResults = state => state.contractPartners.serviceCaseSearchResults;
export const getServiceCaseSearchResultsCount = state => state.contractPartners.serviceCaseSearchResultsCount;
export const getCommissioners = state => state.contractPartners.commissioners;

/**
 * Returns selector for contractPartner matched from route
 */
export const createContractPartnerSelector = () => createSelector(
    [
        getContractPartners,
        (state, props) => {
            const {match, location} = props;
            return match?.params?.contractPartnerId || location?.query?.contractPartnerId || null;
        },
    ],
    (contractPartners, contractPartnerId) => {
        if (!contractPartnerId) return null;

        return contractPartners[contractPartnerId];
    },
);

/**
 * Returns selector for contract partner's temporary restriction
 */
export const createTemporaryRestrictionSelector = () => createSelector(
    [
        (state, props) => {
            const {location} = props;
            return location?.query?.temporaryRestrictionId || null;
        },
        createContractPartnerSelector(),
    ],
    (temporaryRestrictionId, contractPartner) => {
        if (!contractPartner || !temporaryRestrictionId) return null;
        return contractPartner.temporaryRestrictions.get(temporaryRestrictionId);
    },
);

/**
 * Returns selector for all active and upcoming temporary restrictions of contractPartner matched from route
 */
export const createActiveAndUpcomingTemporaryRestrictionsSelector = () => createSelector(
    [
        createContractPartnerSelector(),
    ],
    contractPartner => {
        if (!contractPartner) return null;
        return [...contractPartner.temporaryRestrictions.values()]
            .filter(temporaryRestriction => {
                if (!temporaryRestriction.restrictionEndDateTime) {
                    return temporaryRestriction;
                }
                const now = moment();
                const restrictionEnd = moment(temporaryRestriction.restrictionEndDateTime);
                return now.isBefore(restrictionEnd);
            });
    },
);

/**
 * Returns selector for all active restrictions of contractPartner matched from route
 */
export const createActiveTemporaryRestrictionsSelector = () => createSelector(
    [
        createContractPartnerSelector(),
    ],
    contractPartner => {
        if (!contractPartner) return null;
        return [...contractPartner.temporaryRestrictions.values()]
            .filter(temporaryRestriction => {
                const now = moment();
                const restrictionStart = temporaryRestriction.restrictionStartDateTime
                    ? moment(temporaryRestriction.restrictionStartDateTime) : null;
                const restrictionEnd = temporaryRestriction.restrictionEndDateTime
                    ? moment(temporaryRestriction.restrictionEndDateTime) : null;

                // if both dates exist, check if the current date is in between
                if (restrictionStart && restrictionEnd && restrictionStart.isValid() && restrictionEnd.isValid()) {
                    return now.isSameOrAfter(restrictionStart) && now.isBefore(restrictionEnd);
                }

                // if only the start date exists, check if it is in the past
                return restrictionStart && restrictionStart.isValid() ? now.isSameOrAfter(restrictionStart) : false;
            });
    },
);
