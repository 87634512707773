import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Pill, Panel, Button, NoResultsBlock} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, plusIcon, resubmissionIcon} from '@ace-de/ui-components/icons';
import * as taskActionTypes from '../tasks/taskActionTypes';
import * as taskSelectors from '../tasks/taskSelectors';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as requestStateSelectors from '../application/requestStateSelectors';
import TableLoaderSpinner from '../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../application/ui-elements/FailedRequestBlock';

const pillVariants = {
    [etmTaskStatusTypes.DUE]: 'pending',
    [etmTaskStatusTypes.OVERDUE]: 'negative',
    [etmTaskStatusTypes.DONE]: 'positive',
};

const SCTasksTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_tasks_tab');
    const {serviceCase, initiateTaskCreationFlow, tasks, users, initiateTaskUpdateFlow, requestState} = props;

    const {isInProgress, translationKey, error} = requestState;

    const openEditTaskModal = task => {
        initiateTaskUpdateFlow({task});
    };

    // if no case don't render
    if (!serviceCase) return null;

    const sortedTasks = tasks.slice()
        .filter(task => task.status !== etmTaskStatusTypes.DONE)
        .sort((taskA, taskB) => {
            return moment(taskA.dueDate).isSameOrAfter(moment(taskB.dueDate)) ? 1 : -1;
        });

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateTab('tasks_panel.title')}
            actions={(
                <Button
                    onClick={() => initiateTaskCreationFlow()}
                >
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    {translateTab('tasks_panel_button_label.new_task')}
                </Button>
            )}
        >
            <Table qaIdent="service-case-tasks">
                {sortedTasks.length === 0 && !isInProgress && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={resubmissionIcon}
                                />
                            )}
                            message={translateTab('no_results.message')}
                            description={translateTab('no_results.description')}
                        />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                {!isInProgress && !!error && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="task-due-date">
                            {translateTab('table_header.due_date')}
                        </TableCell>
                        <TableCell qaIdentPart="task-status">
                            {translateTab('table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="task-category">
                            {translateTab('table_header.category')}
                        </TableCell>
                        <TableCell qaIdentPart="task-description">
                            {translateTab('table_header.description')}
                        </TableCell>
                        <TableCell qaIdentPart="task-member-name">
                            {translateTab('table_header.member')}
                        </TableCell>
                        <TableCell qaIdentPart="task-assigned-to-role">
                            {translateTab('table_header.assigned_to')}
                        </TableCell>
                        <TableCell qaIdentPart="task-assigned-service-case-id">
                            {translateTab('table_header.case_id')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedTasks.length > 0
                    && !isInProgress
                    && !error
                    && sortedTasks.map(task => (
                        <TableRow
                            key={task.id}
                            onClick={() => openEditTaskModal(task)}
                            qaIdentPart={task.id}
                        >
                            <TableCell
                                qaIdentPart="task-due-date"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.dueDate && moment(task.dueDate).isValid()
                                    ? moment(task.dueDate).format('DD.MM.YYYY[,] HH:mm') : '-'}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-status"
                                qaIdentPartPostfix={task.id}
                            >
                                <Pill type={pillVariants[task.status]}>
                                    {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                </Pill>
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-category"
                                qaIdentPartPostfix={task.id}
                            >
                                {translate(`global.task_category.${task.category.toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-description"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.description}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-member-name"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.memberName}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-assigned-to-role"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.assignedToRole
                                    ? translate(`global.user_shift_role.${task.assignedTo.toLowerCase()}`)
                                    : `${users[task.assignedTo]?.firstName || ''} ${users[task.assignedTo]?.lastName || ''}`}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-assigned-service-case-id"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.assignedCaseId}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

SCTasksTab.propTypes = {
    serviceCase: PropTypes.object,
    initiateTaskCreationFlow: PropTypes.func.isRequired,
    initiateTaskUpdateFlow: PropTypes.func.isRequired,
    tasks: PropTypes.array,
    users: PropTypes.object,
    requestState: PropTypes.object,
};

SCTasksTab.defaultProps = {
    serviceCase: null,
    tasks: [],
    users: {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const tasksSelector = taskSelectors.createTasksSelector();
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST);

    return {
        serviceCase: serviceCaseSelector(state, props),
        tasks: tasksSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        persistenceState: state.tasks.searchPersistenceState,
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateTaskCreationFlow: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_CREATION_FLOW,
        payload,
    }),
    initiateTaskUpdateFlow: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_UPDATE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCTasksTab));
