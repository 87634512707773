import {take, put, call, select, delay} from 'redux-saga/effects';
import {push, resolveRoute, ALL_ROUTE_UNMOUNT_SIDE_EFFECTS_COMPLETED} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceAssignmentStatus from '../../service-assignments/sagas/updateServiceAssignmentStatus';
import * as savaActionTypes from '../savaActionTypes';
import * as ehmCaseLogActionTypes from '../../case-logs/caseLogActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

const updateSAVAAssignmentStatus = function* updateSAVAAssignmentStatus() {
    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE);
            payloadData = payload;
        }

        if (!payloadData) continue;

        const {serviceAssignmentLineNo, serviceAssignmentData, newServiceAssignmentStatus} = payloadData;
        const {serviceCaseId, attachmentsToSendWithAssignment} = payloadData;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        const updateServiceAssignmentStatusResponseAction = yield* updateServiceAssignmentStatus({
            caller: savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: newServiceAssignmentStatus,
            },
        });

        if (!updateServiceAssignmentStatusResponseAction.error) {
            yield put(push(resolveRoute(routePaths.DASHBOARD), {
                // location state
                shouldSkipTabSelection: true,
            }));

            yield put({
                type: ehmCaseLogActionTypes.INITIATE_CREATE_CASE_LOG_LEFT_FLOW,
                payload: {serviceCaseId},
            });

            // ensure that all route unmount effect were executed before the modal changes a location key
            yield take(ALL_ROUTE_UNMOUNT_SIDE_EFFECTS_COMPLETED);
            // delay is needed because the router is faster than the UI update,
            // so fields connected to the router may behave inconsistently
            yield delay(1000);

            yield* openModal(modalIds.SAV_ASSIGNMENT_SUCCESSFUL);
        }

        if (updateServiceAssignmentStatusResponseAction.error) {
            const {status} = updateServiceAssignmentStatusResponseAction.payload.response;
            yield* openModal(
                modalIds.FAILED_SERVICE_ERROR_MESSAGE,
                {
                    errorCode: status,
                    requestedAction: savaActionTypes.SUBMIT_SAVA_STATUS_CHANGE.toLowerCase(),
                },
            );

            const selectedErrorModalOption = yield take([
                savaActionTypes.CONFIRM_RESUBMITTING_STATUS_CHANGE,
                savaActionTypes.DECLINE_RESUBMITTING_STATUS_CHANGE,
            ]);

            if (selectedErrorModalOption.type === savaActionTypes.CONFIRM_RESUBMITTING_STATUS_CHANGE) {
                shouldWaitForAction = false;
                continue;
            }
        }

        const {location} = yield select(state => state.router);
        if (location?.query?.modal && location.query.modal === modalIds.FAILED_SERVICE_ERROR_MESSAGE) {
            yield* closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''});
        }

        shouldWaitForAction = true;
    }
};

export default updateSAVAAssignmentStatus;
