import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';

const CostCoverageTrailerStateDetailsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('satr_cost_coverage_tab');
    const {serviceAssignment} = props;

    return (
        <Panel
            title={translateTab('panel_title.trailer_state_details')}
            qaIdent="satr-cost-coverage-trailer-details"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.key-at-trailer')}
                        qaIdent="sa-key-at-trailer"
                    >
                        {serviceAssignment?.keyAtTrailer
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.trailer_registration_at_trailer')}
                        qaIdent="sa-trailer-registration-at-trailer"
                    >
                        {serviceAssignment?.trailerRegistrationAtTrailer
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.reason')}
                        qaIdent="sa-reason"
                    >
                        {serviceAssignment?.reason || ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.rollable')}
                        qaIdent="sa-rollable"
                    >
                        {serviceAssignment?.rollable
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageTrailerStateDetailsPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageTrailerStateDetailsPanel.defaultProps = {
    serviceAssignment: null,
};

export default CostCoverageTrailerStateDetailsPanel;
