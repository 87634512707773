import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageRentalCarDataPanel from './ui-elements/CostCoverageRentalCarDataPanel';
import CostCoverageCostCoveragePanel from './ui-elements/CostCoverageCostCoveragePanel';
import CostCoverageRentalCarPanel from './ui-elements/CostCoverageRentalCarPanel';
import CostCoverageBudgetCalculationPanel from './ui-elements/CostCoverageBudgetCalculationPanel';
import * as sarcActionTypes from './sarcActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import config from '../config';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SARCCostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, users, submitSARCCostCoverageForm, submitCreateSARCPDF} = props;
    const {submitSARCCostCoverageRentalCarForm} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [assignmentText, setAssignmentText] = useState(serviceAssignment?.assignmentText || '');
    const [reservationNumber, setReservationNumber] = useState(serviceAssignment?.reservationNumber || '');
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    const onChangeHandler = (key, value) => {
        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'reservationNumber') {
            setReservationNumber(value);
            return;
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
        }
    };

    const handleSendCostCoverage = sendCostCoverageData => {
        const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentData} = sendCostCoverageData;
        submitSARCCostCoverageRentalCarForm({
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceAssignmentData: {
                ...serviceAssignmentData,
                assignmentText,
                reservationNumber,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            caseType: serviceCase.caseType,
            serviceCaseData: {
                // patch remaining budget on the case level only when assigning the assignment
                remainingBudget: typeof serviceAssignment.costs?.delivery === 'number'
                    ? typeof serviceCase.remainingBudget === 'number'
                        ? (serviceAssignment.costs.delivery < serviceCase.remainingBudget
                            ? serviceCase.remainingBudget - serviceAssignment.costs.delivery
                            : 0)
                        : (serviceAssignment.costs.delivery < config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET
                            ? config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET - serviceAssignment.costs.delivery
                            : 0)
                    : serviceCase.remainingBudget,
            },
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    const handleOnTabChange = useCallback(() => {
        submitSARCCostCoverageForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                assignmentText,
                reservationNumber,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        submitSARCCostCoverageForm,
        serviceAssignment.lineNo,
        serviceCase.id,
        assignmentText,
        reservationNumber,
        maximumBudget,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        serviceCase.nonAceMember,
        attachmentsToSendWithAssignment,
    ]);

    useEffect(() => {
        setCallbackPhoneNo(serviceAssignment?.callbackPhoneNo);
    }, [serviceAssignment?.callbackPhoneNo]);

    useRouteUnmountEffect(() => {
        handleOnTabChange();
    }, [handleOnTabChange]);

    const submitCreatePDF = () => {
        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);
        submitCreateSARCPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentData: {
                assignmentText,
                reservationNumber,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const {acePartner, rentalCarStationPickup} = serviceAssignment;
    const showBudgetCalculationPanel = !!(acePartner && rentalCarStationPickup);

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    maximumBudget={maximumBudget}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageRentalCarDataPanel
                    reservationNumber={reservationNumber}
                    onChange={onChangeHandler}
                />
                <CostCoverageCostCoveragePanel
                    assignmentText={assignmentText}
                    onChange={onChangeHandler}
                />
                {showBudgetCalculationPanel && (
                    <CostCoverageBudgetCalculationPanel
                        setIsSendButtonDisabled={setIsSendButtonDisabled}
                    />
                )}
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CostCoverageRentalCarPanel
                    onSendCostCoverage={handleSendCostCoverage}
                    isSendButtonDisabled={isSendButtonDisabled}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SARCCostCoverageTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSARCCostCoverageForm: PropTypes.func.isRequired,
    submitSARCCostCoverageRentalCarForm: PropTypes.func.isRequired,
    submitCreateSARCPDF: PropTypes.func.isRequired,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SARCCostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: {},
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARCCostCoverageForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_FORM,
        payload,
    }),
    submitSARCCostCoverageRentalCarForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM,
        payload,
    }),
    submitCreateSARCPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SARCCostCoverageTab));
