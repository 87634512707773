import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, DataRow, Pill, Icon, noTowingIcon} from '@ace-de/ui-components';
import pillTypes from '../pillStateTypes';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';

const VehicleAdditionalAssignmentDetailsTable = props => {
    const {cx} = useStyles();
    const {serviceCase, serviceAssignment, agentUserProfile} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_service_assignments_tab');
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    return (
        <div className={cx('global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-24')}>
            <div>
                <DataRow
                    label={translateTab('data_row_label.assigned_at')}
                    qaIdent="sa-assigned-at"
                >
                    {serviceAssignment.assignedAt
                        ? `${moment(serviceAssignment.assignedAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`
                        : '-'}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.created_by')}
                    qaIdent="sa-created-by"
                >
                    {isAgentIdentityAvailable
                        ? agentUserProfile?.displayName || '-'
                        : translate('global.user_shift_role.emergency_call_advisor')
                    }
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.location')}
                    qaIdent="sa-service-location-address"
                >
                    {serviceAssignment.serviceLocation?.formattedAddress || '-'}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.service_provider')}
                    qaIdent="sa-contract-partner"
                >
                    {serviceAssignment.acePartner?.name ? (
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                            <p>{serviceAssignment.acePartner.name}</p>
                            {!!serviceAssignment.acePartner?.isManuallyAdded && (
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-typography--color-warning',
                                    ])}
                                >
                                    <Icon
                                        icon={noTowingIcon}
                                        className={cx([
                                            'ace-c-icon--color-warning',
                                            'global!ace-u-margin--left-8',
                                            'ace-c-icon--l',
                                        ])}
                                    />
                                </div>
                            )}
                        </div>
                    ) : '-'}
                </DataRow>
            </div>
            <div>
                <DataRow
                    label={translateTab('data_row_label.updated_at')}
                    qaIdent="sa-updated-at"
                >
                    {serviceAssignment.updatedAt
                        ? `${moment(serviceAssignment.updatedAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`
                        : '-'}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.status')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="sa-assignment-status"
                >
                    <Pill
                        type={pillTypes[serviceAssignment.assignmentStatus]}
                        className={cx('global!ace-u-typography--variant-body-medium')}
                    >
                        {translate(`global.service_assignment_status_type.${
                            serviceAssignment.assignmentStatus.toLowerCase()
                        }`)}
                    </Pill>
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.entitlement_check')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="sa-entitlement-check"
                >
                    <div>
                        <Pill
                            type={serviceAssignment.entitlementCheck ? 'positive' : 'negative'}
                            className={cx('global!ace-u-typography--variant-body-medium')}
                        >
                            {serviceAssignment.entitlementCheck
                                ? translate('global.entitlement_check.entitlement_available')
                                : translate('global.entitlement_check.no_entitlement_available')}
                        </Pill>
                        {serviceAssignment.entitlementCheck && serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}` && (
                            <p className={cx('global!ace-u-padding--left-8')}>
                                {translate('global.entitlement_check.entitlement_available')}
                            </p>
                        )}
                    </div>
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.planned_departure')}
                    qaIdent="sc-planned-departure"
                >
                    {serviceCase.departureDate ? moment(serviceCase.departureDate).format('DD.MM.YYYY') : '-'}
                </DataRow>
            </div>
        </div>
    );
};

VehicleAdditionalAssignmentDetailsTable.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    agentUserProfile: PropTypes.object,
};

VehicleAdditionalAssignmentDetailsTable.defaultProps = {
    serviceCase: {},
    serviceAssignment: {},
    agentUserProfile: null,
};

export default VehicleAdditionalAssignmentDetailsTable;
