import {take, select, fork, put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';
import savScreenTabs from '../../service-assignments-vehicle/savScreenTabs';
import savaScreenTabs from '../../service-assignments-vehicle-additional/savaScreenTabs';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Load service assignment final destination route flow
 */
const loadServiceAssignmentFinalDestinationRoute = function* loadServiceAssignmentFinalDestinationRoute({payload}) { // eslint-disable-line max-len
    const {match, location} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (![savScreenTabs.TOWING_DESTINATION, savaScreenTabs.TOWING_DESTINATION].includes(activeTab)) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceCase = serviceCases[serviceCaseId];
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (serviceAssignmentId) {
        // reset previously stored final destination route
        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_ROUTE,
            payload: {
                serviceAssignmentId,
                routeToReferentialPoint: null,
            },
        });
    }

    const vehicleAdditionalScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
        exact: true,
    });
    const damageLocation = vehicleAdditionalScreenMatch
        ? serviceAssignment?.serviceLocation
        : serviceCase?.damage?.location;
    if (!serviceAssignment?.finalDestination?.location || !damageLocation) return;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST,
        arcGISRESTService.getRoute,
        {
            startingPoint: {
                longitude: damageLocation.longitude,
                latitude: damageLocation.latitude,
            },
            destination: {
                longitude: serviceAssignment.finalDestination.location.longitude,
                latitude: serviceAssignment.finalDestination.location.latitude,
            },
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
        },
    );

    const routeResponseAction = yield take([
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_FAILED,
        serviceAssignmentActionTypes.FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_SUCCEEDED,
    ]);

    if (!routeResponseAction.error) {
        const {response} = routeResponseAction.payload;
        const {arcGISRouteDTO} = response;
        const routeToReferentialPoint = arcGISRouteDTO;

        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_ROUTE,
            payload: {
                serviceAssignmentId,
                routeToReferentialPoint,
            },
        });
    }
};

export default loadServiceAssignmentFinalDestinationRoute;
