import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as samcActionTypes from '../samcActionTypes';

/**
 * Update SAMC problem/damage details
 */
const updateSAMCProblemAndDamageDetails = function* updateSAMCProblemAndDamageDetails() {
    while (true) {
        const {payload} = yield take(samcActionTypes.SUBMIT_SAMC_PROBLEM_DAMAGE_FORM);
        const {serviceAssignmentData, serviceCaseId, serviceAssignmentLineNo} = payload;

        yield* updateServiceAssignment({
            caller: samcActionTypes.SUBMIT_SAMC_PROBLEM_DAMAGE_FORM,
            assignmentType: efServiceAssignmentTypes.MEDICAL_CLEARANCE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: samcActionTypes.SUBMIT_SAMC_PROBLEM_DAMAGE_FORM},
        });
    }
};

export default updateSAMCProblemAndDamageDetails;
