import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, Panel, calendarIcon} from '@ace-de/ui-components';
import {DateField} from '@ace-de/ui-components/form';
import {persistenceStates} from '@ace-de/eua-entity-types';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as samcActionTypes from './samcActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import ServiceProviderPanel from '../service-assignments/ui-elements/ServiceProviderPanel';
import AlternativeServiceProviderPanel from '../service-assignments/ui-elements/AlternativeServiceProviderPanel';

const SAMCServiceProviderTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('samc_service_provider_tab');
    const {serviceAssignment, serviceCase, serviceProviders} = props;
    const {resetServiceProviderList, submitSAMCServiceProviderForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(!!serviceAssignment?.acePartner?.isManuallyAdded);
    const [pickupDate, setPickupDate] = useState(serviceAssignment.pickupDate);
    const [selectedServiceProviderId, setSelectedServiceProviderId] = useState(
        serviceAssignment?.acePartner && !serviceAssignment?.acePartner?.isManuallyAdded
            ? serviceAssignment.acePartner?.id : null,
    );

    const handleOnToggleSwitch = () => {
        if (!isEditModeActive && selectedServiceProviderId !== null) {
            // false to true: remove selected service provider since agent wants to use the alternative one
            setSelectedServiceProviderId(null);
        }
        setIsEditModeActive(!isEditModeActive);
    };

    const handleOnSelectServiceProvider = id => {
        if (id && typeof id === 'string') {
            setSelectedServiceProviderId(id);
        }
    };

    const handleOnTabChange = useCallback(() => {
        submitSAMCServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                pickupDate,
                ...(!isEditModeActive
                    ? {
                        acePartner: selectedServiceProviderId
                            ? {
                                ...serviceProviders[selectedServiceProviderId],
                                location: {
                                    ...(!!serviceProviders[selectedServiceProviderId]?.location
                                            && serviceProviders[selectedServiceProviderId].location),
                                    ...(!!serviceProviders[selectedServiceProviderId]?.address
                                            && serviceProviders[selectedServiceProviderId].address),
                                },
                                contactDetails: null,
                            }
                            : null,
                    }
                    : {}
                ),
            },
        });
    }, [
        isEditModeActive,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        selectedServiceProviderId,
        serviceProviders,
        pickupDate,
        submitSAMCServiceProviderForm,
    ]);

    useRouteUnmountEffect(() => {
        if (serviceAssignment.persistenceState === persistenceStates.READY) {
            resetServiceProviderList();
        }
        handleOnTabChange();
    }, [handleOnTabChange, resetServiceProviderList, serviceAssignment.persistenceState]);

    // if no service assignment, don't render
    if (!serviceAssignment || !serviceCase) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <Panel title={translateTab('pickup_date_panel.title')} className={cx('global!ace-u-margin--bottom-0')}>
                        <DateField
                            name="pickupDate"
                            label={translateTab('pickup_date_panel_input_label.pickup_date')}
                            value={pickupDate}
                            placeholder={translateTab('date_field_placeholder.please_enter')}
                            icon={calendarIcon}
                            locale={activeLocale}
                            onChange={value => setPickupDate(value)}
                        />
                    </Panel>
                    <ServiceProviderPanel
                        isEditModeActive={isEditModeActive}
                        selectedServiceProviderId={selectedServiceProviderId}
                        handleOnSelectServiceProvider={handleOnSelectServiceProvider}
                        isPartnerChangingDisabled={false}
                    />
                </div>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <AlternativeServiceProviderPanel
                    serviceAssignment={serviceAssignment}
                    serviceCase={serviceCase}
                    isActive={isEditModeActive}
                    onToggleSwitch={handleOnToggleSwitch}
                    isPartnerChangingDisabled={false}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SAMCServiceProviderTab.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceProviders: PropTypes.object,
    submitSAMCServiceProviderForm: PropTypes.func.isRequired,
    resetServiceProviderList: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
};

SAMCServiceProviderTab.defaultProps = {
    serviceAssignment: null,
    serviceProviders: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
        serviceProviders: state.serviceAssignments.serviceProviders,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAMCServiceProviderForm: payload => dispatch({
        type: samcActionTypes.SUBMIT_SAMC_SERVICE_PROVIDER_FORM,
        payload,
    }),
    resetServiceProviderList: () => dispatch({
        type: serviceAssignmentActionTypes.RESET_SERVICE_PROVIDERS,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMCServiceProviderTab));
