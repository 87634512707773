import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';

const serviceAssignmentPartnerDisabledStateStatuses = [
    efServiceAssignmentStatusTypes.ASSIGNED,
    efServiceAssignmentStatusTypes.DONE,
    efServiceAssignmentStatusTypes.CANCELED,
    efServiceAssignmentStatusTypes.EUA_CANCELED,
    efServiceAssignmentStatusTypes.ACCEPTED,
    efServiceAssignmentStatusTypes.REJECTED,
];

export default serviceAssignmentPartnerDisabledStateStatuses;
