import {take, put, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceCaseTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

/**
 * Create person service case flow
 */
const createPersonServiceCaseFlow = function* createPersonServiceCaseFlow() {
    while (true) {
        const {payload} = yield take([serviceAssignmentActionTypes.INITIATE_CREATE_PERSON_SERVICE_CASE_FLOW]);
        const {member} = payload;

        if (!member) continue;

        if (!member.tariffDetails
            || !member.tariffDetails.currentTariff
            || member.tariffDetails.tariffGroup === ampMemberTariffGroups.NON_MEMBER
        ) {
            yield* openModal(modalIds.CREATE_CASE_NO_TARIFF_WARNING);
            continue;
        }

        yield* openModal(modalIds.CREATE_PERSON_SERVICE_CASE);
        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_CREATE_PERSON_SERVICE_CASE,
            serviceAssignmentActionTypes.DECLINE_CREATE_PERSON_SERVICE_CASE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_CREATE_PERSON_SERVICE_CASE) {
            const {assignmentType, pathname} = chosenModalOption.payload;

            let memberData = member;
            if (!memberData && pathname) {
                const membershipNo = pathname.split('/').pop();
                if (membershipNo) {
                    const memberSearchResults = yield select(state => state.members.memberSearchResults);
                    if (memberSearchResults) {
                        const result = memberSearchResults.find(result => result.membershipNo === membershipNo);
                        if (result) {
                            memberData = result;
                        }
                    }
                }
            }

            yield put({
                type: serviceCaseActionTypes.CREATE_SERVICE_CASE,
                payload: {member: memberData, caseType: efServiceCaseTypes.PERSON, assignmentType},
            });

            yield* closeModal(modalIds.CREATE_SERVICE_CASE);
        }

        yield* closeModal(modalIds.CREATE_PERSON_SERVICE_CASE);
    }
};

export default createPersonServiceCaseFlow;
