/**
 * @typedef {string} tabId
 */

/**
 * SAA screen tabs
 *
 * @enum {tabId}
 */
export default {
    REQUIREMENTS: 'requirements',
    ACCOMMODATION: 'accommodation',
    COST_COVERAGE: 'cost-coverage',
};
