import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import DocumentUploadPanel from './ui-elements/DocumentUploadPanel';
import MemberInfoPanel from './ui-elements/MemberInfoPanel';
import VehicleInfoPanel from './ui-elements/VehicleInfoPanel';
import DamageLocationInfoPanel from './ui-elements/DamageLocationInfoPanel';
import SCBasicDataTowingDestinationInfoPanel from '../service-assignments-vehicle/ui-elements/SCBasicDataTowingDestinationInfoPanel';
import ContactDetailsPanel from './ui-elements/ContactDetailsPanel';
import ServiceCaseInfoPanel from './ui-elements/ServiceCaseInfoPanel';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import config from '../config';
import DamageDescriptionInfoPanel from './ui-elements/DamageDescriptionInfoPanel';
import TrailerInfoPanel from '../service-assignments/ui-elements/TrailerInfoPanel';

const SCBasicDataTab = props => {
    const {serviceCase, updateSCBasicData, initiateEditServiceCaseVehicle} = props;
    const {serviceAssignmentWithTowingDestination} = props;
    const {cx} = useStyles();
    const [externalCaseId, setExternalCaseId] = useState(serviceCase.externalCaseId || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceCase?.fallbackSystemId || '');
    const [email, setEmail] = useState(serviceCase?.member?.contactDetails?.email || '');

    const handleOnChange = (key, value) => {
        switch (key) {
            case 'externalCaseId': {
                setExternalCaseId(value);
                break;
            }

            case 'fallbackSystemId': {
                setFallbackSystemId(value);
                break;
            }

            case 'email': {
                setEmail(value);
                break;
            }

            default: {
                // no-op
            }
        }
    };

    const handleTabChange = useCallback(serviceCaseData => {
        updateSCBasicData({
            caseType: serviceCase.caseType,
            serviceCaseId: serviceCase.id,
            serviceCaseData: {
                externalCaseId: serviceCaseData.externalCaseId,
                fallbackSystemId: serviceCaseData.fallbackSystemId,
                member: {
                    contactDetails: {
                        email: serviceCaseData.email,
                    },
                },
            },
        });
    }, [
        serviceCase.id,
        serviceCase.caseType,
        updateSCBasicData,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (externalCaseId === serviceCase.externalCaseId
            && fallbackSystemId === serviceCase.fallbackSystemId
            && email === serviceCase.member?.contactDetails?.email
        ) {
            completeRouteUnmountSideEffect({
                caller: serviceCaseActionTypes.INITIATE_UPDATE_SC_BASIC_DATA_FLOW,
            });
            return;
        }

        handleTabChange({externalCaseId, fallbackSystemId, email});
    }, [
        externalCaseId,
        serviceCase.externalCaseId,
        fallbackSystemId,
        serviceCase.fallbackSystemId,
        email,
        serviceCase.member?.contactDetails?.email,
        handleTabChange,
    ]);

    // if no case don't render
    if (!serviceCase) return null;

    const {member, vehicle, damage} = serviceCase;

    const hideVehicleInfo = (serviceCase.caseType === efServiceCaseTypes.PERSON && !vehicle)
        || serviceCase.caseType === efServiceCaseTypes.HOUSEHOLD;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                {member && (
                    <MemberInfoPanel
                        member={member}
                        serviceCase={serviceCase}
                        email={email}
                        handleOnChange={handleOnChange}
                    />
                )}
                <ServiceCaseInfoPanel
                    serviceCase={serviceCase}
                    externalCaseId={externalCaseId}
                    fallbackSystemId={fallbackSystemId}
                    handleOnChange={handleOnChange}
                />
                {hideVehicleInfo ? null : (
                    <VehicleInfoPanel
                        handleOnEdit={() => initiateEditServiceCaseVehicle({vehicleId: vehicle.vehicleId})}
                    />
                )}
                {!!serviceCase?.trailer && (
                    <TrailerInfoPanel />
                )}
                {serviceCase.caseType === efServiceCaseTypes.VEHICLE && (
                    <SCBasicDataTowingDestinationInfoPanel
                        key={serviceAssignmentWithTowingDestination}
                        serviceAssignmentWithTowingDestination={serviceAssignmentWithTowingDestination}
                    />
                )}
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                {damage && (
                    <DamageLocationInfoPanel damage={damage} />
                )}
                <DamageDescriptionInfoPanel />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel />
                )}
                <ContactDetailsPanel />
            </ContentItem>
        </ContentBlock>
    );
};

SCBasicDataTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignmentWithTowingDestination: PropTypes.object,
    updateSCBasicData: PropTypes.func.isRequired,
    initiateEditServiceCaseVehicle: PropTypes.func.isRequired,
};

SCBasicDataTab.defaultProps = {
    serviceCase: null,
    serviceAssignmentWithTowingDestination: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentWithTowingDestinationSelector = serviceCaseSelectors.createServiceAssignmentWithTowingDestinationSelector(); // eslint-disable-line max-len
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignmentWithTowingDestination: serviceAssignmentWithTowingDestinationSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    updateSCBasicData: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_UPDATE_SC_BASIC_DATA_FLOW,
        payload,
    }),
    initiateEditServiceCaseVehicle: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCBasicDataTab));
