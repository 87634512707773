import {call, select, take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import createServiceAssignmentQMFeedbackDraft from './createServiceAssignmentQMFeedbackDraft';
import deleteServiceAssignmentQMFeedbackDraft from './deleteServiceAssignmentQMFeedbackDraft';

const initiateServiceAssignmentQMFeedbackDraftCreationFlow = function* initiateServiceAssignmentQMFeedbackDraftCreationFlow() { // eslint-disable-line max-len
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW);
        const {qmFeedbackDraftData, isDraftDeletion = false, isDraftPlaceholderDeletion = false} = payload;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignment = serviceAssignments[`${qmFeedbackDraftData.caseId}-${qmFeedbackDraftData.lineNo}`];

        if (!serviceAssignment) continue;

        // if isDraftDeletion=true and there is a draft placeholder
        // then we have to delete it, because we are not creating REJECT note for the VBA partner
        if (isDraftDeletion || isDraftPlaceholderDeletion) {
            if (isDraftPlaceholderDeletion && serviceAssignment.qmFeedbackDraftPlaceholder?.id) {
                yield call(deleteServiceAssignmentQMFeedbackDraft, {
                    serviceAssignmentLineNo: serviceAssignment.lineNo,
                    serviceCaseId: serviceAssignment.serviceCaseId,
                    qmFeedbackDraftId: serviceAssignment.qmFeedbackDraftPlaceholder.id,
                });
            }
            if (isDraftDeletion && serviceAssignment.qmFeedbackDraft?.id) {
                yield call(deleteServiceAssignmentQMFeedbackDraft, {
                    serviceAssignmentLineNo: serviceAssignment.lineNo,
                    serviceCaseId: serviceAssignment.serviceCaseId,
                    qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft.id,
                });
            }
            yield put({
                type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
                payload: {caller: serviceAssignmentActionTypes.INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW},
            });
            continue;
        }

        if (serviceAssignment?.qmFeedbackDraftPlaceholder
            && serviceAssignment?.qmFeedbackDraftPlaceholder?.contractPartnerId === qmFeedbackDraftData.contractPartnerId) { // eslint-disable-line max-len
            yield put({
                type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
                payload: {caller: serviceAssignmentActionTypes.INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW},
            });
            continue;
        }

        if (serviceAssignment?.qmFeedbackDraftPlaceholder
            && serviceAssignment?.qmFeedbackDraftPlaceholder?.contractPartnerId !== qmFeedbackDraftData.contractPartnerId) { // eslint-disable-line max-len
            yield call(deleteServiceAssignmentQMFeedbackDraft, {
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                serviceCaseId: serviceAssignment.serviceCaseId,
                qmFeedbackDraftId: serviceAssignment.qmFeedbackDraftPlaceholder.id,
            });
        }

        yield call(createServiceAssignmentQMFeedbackDraft, {
            qmFeedbackDraftData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW},
        });
    }
};

export default initiateServiceAssignmentQMFeedbackDraftCreationFlow;
