import {select, fork, take, call, put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import routePaths from '../../routePaths';

/**
 * ArcGIS: search for nearby POIs
 */
const searchArcGISNearbyPOIs = function* searchArcGISNearbyPOIs({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const {location} = yield select(state => state.router);
    const serviceAssignmentVehicleScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT_VEHICLE,
        exact: true,
    });
    const serviceAssignmentVehicleAdditionalScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
        exact: true,
    });
    // currently, we have to display POIs only for maps on the SAV and SAVA
    if (!serviceAssignmentVehicleScreenMatch && !serviceAssignmentVehicleAdditionalScreenMatch) return;

    const arcGISMap = yield call(
        arcGISMapService.getMap,
        serviceAssignmentVehicleScreenMatch
            ? 'service-assignment-vehicle'
            : 'service-assignment-vehicle-additional',
    );
    const {categoriesString} = payload;
    const mapCenter = arcGISMap.getMapCenter(true);

    // if no categoriesString or mapCenter, do not render
    if (!categoriesString || !mapCenter) return;

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS_REQUEST,
        arcGISRESTService.searchNearbyPOIs,
        {
            location: mapCenter,
            maxLocations: 20,
            // TBD: check if we can use change-case to replace '_' with ' '
            category: categoriesString?.replaceAll('_', ' '),
            distance: 100,
            forStorage: true,
        },
    );

    const responseAction = yield take([
        serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
            payload: {
                arcGISLocationCandidateDTOs,
                selectedCategory: categoriesString,
            },
        });
    }
};

export default searchArcGISNearbyPOIs;
