import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter, useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Checkbox} from '@ace-de/ui-components';
import {Icon, attachmentIcon, deleteIcon, editIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import styles from './DocumentUploadPanelItem.module.scss';
import serviceCaseScreenTabs from '../serviceCaseScreenTabs';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const DocumentUploadPanelItem = props => {
    const {cx} = useStyles(props, styles);
    const {activeTab} = useSearchQueryParams();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {attachmentGroup, groupedAttachments, serviceCaseId} = props;
    const {submitSCDocumentDownloadFlow, initiateSCDocumentDeleteFlow, initiateUpdateDocumentDescription} = props;
    const isDocumentUpdateAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.UPDATE,
    );
    const isDocumentDeletionAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.DELETE,
    );

    const handleFileDownload = attachment => {
        const payloadData = {
            fileName: attachment.fileName,
            url: attachment.url,
        };
        submitSCDocumentDownloadFlow(payloadData);
    };

    const formatText = (descriptionText, attachmentGroupId) => {
        if (descriptionText) return descriptionText;
        return groupedAttachments[attachmentGroupId].map(attachment => attachment.fileName).join(' ') || '';
    };

    const handleFileEdit = attachment => {
        initiateUpdateDocumentDescription({
            attachmentId: attachment.id,
            serviceCaseId: serviceCaseId,
            groupId: attachment.groupId,
        });
    };

    return (
        <Fragment>
            {attachmentGroup.map((attachment, id) => {
                return (
                    <div key={attachment.id} className={cx('global!ace-u-padding--8-0')}>
                        {id === 0 ? (
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-margin--16-0',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-medium',
                                        'ace-c-document-upload-panel-item__description',
                                    ])}
                                >
                                    {formatText(attachment.description, attachment.groupId)}
                                </p>
                                <InteractiveIcon
                                    onClick={() => { handleFileEdit(attachment); }}
                                    icon={editIcon}
                                    className={cx([
                                        'ace-c-icon--xs',
                                        'ace-c-icon--color-medium-emphasis',
                                    ])}
                                    isDisabled={!isDocumentUpdateAvailable}
                                />
                            </div>
                        ) : null}
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-cursor--pointer',
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-flex--justify-space-between',
                            ])}
                        >
                            <span
                                className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}
                                onClick={() => handleFileDownload(attachment)}
                            >
                                <Icon
                                    icon={attachmentIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'ace-c-icon--color-highlight',
                                    ])}
                                />
                                <p>
                                    {attachment.fileName}
                                    <span className={cx('global!ace-u-typography--color-default')}>
                                        {attachment.attachmentType
                                            ? ` (${translate(`global.service_case_document_type.${snakeCase(attachment.attachmentType)}`)})`
                                            : null
                                        }
                                    </span>
                                </p>
                            </span>
                            {isDocumentDeletionAvailable && (
                                <Icon
                                    onClick={() => initiateSCDocumentDeleteFlow({
                                        serviceCaseId: serviceCaseId,
                                        attachmentId: attachment.id,
                                    })}
                                    icon={deleteIcon}
                                    className={cx([
                                        'ace-c-icon--xs',
                                        'ace-c-icon--color-medium-emphasis',
                                        'global!ace-u-flex--align-self-flex-end',
                                    ])}
                                />
                            )}
                        </div>
                        {activeTab !== serviceCaseScreenTabs.BASIC_DATA && (
                            <Checkbox
                                className={cx('global!ace-u-margin--bottom-8')}
                                key={`${attachment}-${id}`}
                                name={attachment.id}
                                value={attachment.id}
                            >
                                {translateTab('text.send_with_assignment')}
                            </Checkbox>
                        )}
                        {id === attachmentGroup.length - 1
                            ? (
                                <div className={cx('global!ace-u-typography--variant-caption')}>
                                    {attachment.createdAt || ''}
                                </div>
                            ) : null
                        }
                    </div>
                );
            })}
        </Fragment>
    );
};


DocumentUploadPanelItem.propTypes = {
    initiateSCDocumentDeleteFlow: PropTypes.func.isRequired,
    submitSCDocumentDownloadFlow: PropTypes.func.isRequired,
    initiateUpdateDocumentDescription: PropTypes.func.isRequired,
    serviceCaseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    groupedAttachments: PropTypes.object,
    attachmentGroup: PropTypes.array,
};

DocumentUploadPanelItem.defaultProps = {
    groupedAttachments: null,
    attachmentGroup: null,
};

const mapDispatchToProps = dispatch => ({
    initiateSCDocumentDeleteFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SC_DOCUMENT_DELETE_FLOW,
        payload,
    }),
    submitSCDocumentDownloadFlow: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
    initiateUpdateDocumentDescription: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_UPDATE_DOCUMENT_DESCRIPTION_FLOW,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(DocumentUploadPanelItem));
