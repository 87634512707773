import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import SASTTripDetailsPanel from './ui-elements/SASTTripDetailsPanel';
import SASTBudgetPanel from './ui-elements/SASTBudgetPanel';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import * as sastActionTypes from './sastActionTypes';

const SASTRequirementsTab = props => {
    const {cx} = useStyles();
    const {serviceAssignment, serviceCase, submitSASTMemberLocationForm} = props;
    const [memberLocationCandidate, setMemberLocationCandidate] = useState(serviceAssignment.memberLocation
        && serviceAssignment.memberLocation.latitude && serviceAssignment.memberLocation.longitude
        ? serviceAssignment.memberLocation : null);

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        submitSASTMemberLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                memberLocation: locationInfo.selectedLocation,
                pickupLocation: locationInfo.selectedLocation,
            },
        });
    };

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <SASTTripDetailsPanel
                    serviceAssignment={serviceAssignment}
                    memberLocationCandidate={memberLocationCandidate}
                    serviceCase={serviceCase}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    mapName="sast-member-location"
                    onRelevantLocationSelect={setMemberLocationCandidate}
                    handleLocationSaving={handleLocationSaving}
                />
                <SASTBudgetPanel serviceAssignment={serviceAssignment} serviceCase={serviceCase} />
            </ContentItem>
        </ContentBlock>
    );
};

SASTRequirementsTab.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    submitSASTMemberLocationForm: PropTypes.func.isRequired,
};

SASTRequirementsTab.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
};

const mapDispatchToProps = dispatch => ({
    submitSASTMemberLocationForm: payload => dispatch({
        type: sastActionTypes.SUBMIT_SAST_MEMBER_LOCATION_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(SASTRequirementsTab);
