import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Modal, HighlightCircle, ButtonSecondary} from '@ace-de/ui-components';
import {useRouter} from '@computerrock/formation-router';
import {closeIcon, InteractiveIcon, Icon, acceptedIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import modalIds from '../../modalIds';

const CreateQualityManagementSuccessModal = props => {
    const {cx} = useStyles(props, {});
    const {hasBackdrop} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('create_quality_management_success_modal');
    const {closeModal} = useRouter();

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => closeModal(modalIds.CP_QUALITY_MANAGEMENT_NOTE_SUCCESSFUL)}
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--success',
                    ])}
                >
                    <Icon
                        icon={acceptedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-success',
                        ])}
                    />
                </HighlightCircle>
                <h3>{translateModal('header.title')}</h3>
                <p
                    className={cx([
                        'global!ace-u-margin--top-32',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translateModal('headline_text.quality_note_created_successfully')}
                </p>
                <ButtonSecondary
                    name="closeModalButton"
                    className={cx('global!ace-u-margin--top-32')}
                    onClick={() => closeModal(modalIds.CP_QUALITY_MANAGEMENT_NOTE_SUCCESSFUL)}
                >
                    {translateModal('button.close')}
                </ButtonSecondary>
            </div>
        </Modal>
    );
};

CreateQualityManagementSuccessModal.propTypes = {
    hasBackdrop: PropTypes.bool,
};

CreateQualityManagementSuccessModal.defaultProps = {
    hasBackdrop: true,
};

export default CreateQualityManagementSuccessModal;
