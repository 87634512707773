import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea, NumberInput} from '@ace-de/ui-components';

const CostCoverageCostCoveragePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('saos_cost_coverage_tab');
    const {onChangeHandler, description, totalCost} = props;

    return (
        <Panel
            title={translateTab('panel_title.cost_coverage')}
            qaIdent="saos-cost-coverage-cost-coverage"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-cost-coverage-description"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={description}
                    onChange={value => onChangeHandler('description', value)}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.amount')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-cost-coverage-total-cost"
            >
                <NumberInput
                    className={cx('global!ace-u-flex--grow-1')}
                    value={totalCost}
                    onChange={value => onChangeHandler('totalCost', value)}
                    placeholder={translateTab('input_placeholder.please_enter_here')}
                    min={0}
                />
            </DataRow>
        </Panel>
    );
};

CostCoverageCostCoveragePanel.propTypes = {
    onChangeHandler: PropTypes.func.isRequired,
    description: PropTypes.string,
    totalCost: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

CostCoverageCostCoveragePanel.defaultProps = {
    description: '',
    totalCost: 0,
};

export default CostCoverageCostCoveragePanel;
