import {take, fork, select, put} from 'redux-saga/effects';
import moment from 'moment';
import {mapMemberTariffGroupsFromAMP, mapMemberStatusesFromAMP, apsServiceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';

const loadShortTripBudget = function* loadShortTripBudget({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;

    if (!serviceCaseId) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const serviceCase = serviceCases[serviceCaseId];

    if (!serviceCase || Number(serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID) return;

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    const {member, createdAt} = serviceCase;
    const {status: memberStatus} = member;
    const tariffGroup = member.tariffDetails.tariffGroup;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('validOn', moment(createdAt).format('YYYY-MM-DD'));
    searchQueryParams.append('serviceType', apsServiceTypes.SHORT_TRIP);
    searchQueryParams.append('memberStatus', mapMemberStatusesFromAMP(memberStatus));
    searchQueryParams.append('tariffGroup', mapMemberTariffGroupsFromAMP(tariffGroup));

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SHORT_TRIP_BUDGET_REQUEST,
        pricingManagementService.getBudgets, {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SHORT_TRIP_BUDGET_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SHORT_TRIP_BUDGET_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {budgetDTOs} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SHORT_TRIP_BUDGET,
            payload: {budgetDTOs},
        });
    }
};

export default loadShortTripBudget;
