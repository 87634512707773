/**
 * Command action types
 */
export const INITIATE_VEHICLE_DELETE_FLOW = '[members] INITIATE_VEHICLE_DELETE_FLOW';
export const DECLINE_DELETE_MEMBER_VEHICLE = '[members] DECLINE_DELETE_MEMBER_VEHICLE';
export const CONFIRM_DELETE_MEMBER_VEHICLE = '[members] CONFIRM_DELETE_MEMBER_VEHICLE';
export const SEARCH_MEMBERS = '[members] SEARCH_MEMBERS';
export const SUBMIT_UPDATE_MEMBER_FORM = '[members] SUBMIT_UPDATE_MEMBER_FORM';
export const SEARCH_VEHICLES = '[members] SEARCH_VEHICLES';
export const MARK_SEARCH_RESULTS_AS_VALID = '[members] MARK_SEARCH_RESULTS_AS_VALID';
export const SUBMIT_CREATE_MEMBER_NOTE_FORM = '[members] SUBMIT_CREATE_MEMBER_NOTE_FORM';
export const INITIATE_MEMBER_NOTE_DELETE_FLOW = '[members] INITIATE_MEMBER_NOTE_DELETE_FLOW';
export const DECLINE_MEMBER_NOTE_DELETE = '[members] DECLINE_MEMBER_NOTE_DELETE';
export const CONFIRM_MEMBER_NOTE_DELETE = '[members] CONFIRM_MEMBER_NOTE_DELETE';
export const INITIATE_CREATE_MEMBER_VEHICLE_FLOW = '[members] INITIATE_CREATE_MEMBER_VEHICLE_FLOW';
export const INITIATE_EDIT_MEMBER_VEHICLE_FLOW = '[members] INITIATE_EDIT_MEMBER_VEHICLE_FLOW';
export const CONFIRM_EDIT_MEMBER_VEHICLE = '[members] CONFIRM_EDIT_MEMBER_VEHICLE';
export const DECLINE_EDIT_MEMBER_VEHICLE = '[members] DECLINE_EDIT_MEMBER_VEHICLE';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION = '[members] SEARCH_MEMBER_ADDRESS_GEOLOCATION';
export const INITIATE_UPDATE_MEMBER_VEHICLE_ACTIVITY_FLOW = '[members] INITIATE_UPDATE_MEMBER_VEHICLE_ACTIVITY_FLOW';

/**
 * Event action types
 */
export const SEARCH_MEMBERS_REQUEST = '[members] SEARCH_MEMBERS_REQUEST';
export const SEARCH_MEMBERS_REQUEST_SENT = '[members] SEARCH_MEMBERS_REQUEST_SENT';
export const SEARCH_MEMBERS_REQUEST_SUCCEEDED = '[members] SEARCH_MEMBERS_REQUEST_SUCCEEDED';
export const SEARCH_MEMBERS_REQUEST_FAILED = '[members] SEARCH_MEMBERS_REQUEST_FAILED';

export const SEARCH_VEHICLES_REQUEST = '[members] SEARCH_VEHICLES_REQUEST';
export const SEARCH_VEHICLES_REQUEST_SENT = '[members] SEARCH_VEHICLES_REQUEST_SENT';
export const SEARCH_VEHICLES_REQUEST_SUCCEEDED = '[members] SEARCH_VEHICLES_REQUEST_SUCCEEDED';
export const SEARCH_VEHICLES_REQUEST_FAILED = '[members] SEARCH_VEHICLES_REQUEST_FAILED';

export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST = '[members] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT = '[members] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED = '[members] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED = '[members] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED';

export const SELECT_MEMBER_ADDRESS_GEOLOCATION = '[members] SELECT_MEMBER_ADDRESS_GEOLOCATION';
export const SELECT_MEMBER_ADDRESS_GEOLOCATION_SENT = '[members] SELECT_MEMBER_ADDRESS_GEOLOCATION_SENT';
export const SELECT_MEMBER_ADDRESS_GEOLOCATION_SUCCEEDED = '[members] SELECT_MEMBER_ADDRESS_GEOLOCATION_SUCCEEDED';
export const SELECT_MEMBER_ADDRESS_GEOLOCATION_FAILED = '[members] SELECT_MEMBER_ADDRESS_GEOLOCATION_FAILED';

export const FETCH_MEMBER_REQUEST = '[members] FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_REQUEST_SENT = '[members] FETCH_MEMBER_REQUEST_SENT';
export const FETCH_MEMBER_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_REQUEST_FAILED = '[members] FETCH_MEMBER_REQUEST_FAILED';

export const UPDATE_MEMBER_REQUEST = '[members] UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_REQUEST_SENT = '[members] UPDATE_MEMBER_REQUEST_SENT';
export const UPDATE_MEMBER_REQUEST_SUCCEEDED = '[members] UPDATE_MEMBER_REQUEST_SUCCEEDED';
export const UPDATE_MEMBER_REQUEST_FAILED = '[members] UPDATE_MEMBER_REQUEST_FAILED';

export const FETCH_MEMBER_SERVICE_CASES_REQUEST = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_SENT = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_SENT';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED = '[members] FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED';

export const FETCH_MEMBER_VEHICLES_REQUEST = '[members] FETCH_MEMBER_VEHICLES_REQUEST';
export const FETCH_MEMBER_VEHICLES_REQUEST_SENT = '[members] FETCH_MEMBER_VEHICLES_REQUEST_SENT';
export const FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED = '[members] FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_VEHICLES_REQUEST_FAILED = '[members] FETCH_MEMBER_VEHICLES_REQUEST_FAILED';

export const DELETE_MEMBER_VEHICLE_REQUEST = '[members] DELETE_MEMBER_VEHICLE_REQUEST';
export const DELETE_MEMBER_VEHICLE_REQUEST_SENT = '[members] DELETE_MEMBER_VEHICLE_REQUEST_SENT';
export const DELETE_MEMBER_VEHICLE_REQUEST_SUCCEEDED = '[members] DELETE_MEMBER_VEHICLE_REQUEST_SUCCEEDED';
export const DELETE_MEMBER_VEHICLE_REQUEST_FAILED = '[members] DELETE_MEMBER_VEHICLE_REQUEST_FAILED';

export const CREATE_MEMBER_NOTE_REQUEST = '[members] CREATE_MEMBER_NOTE_REQUEST';
export const CREATE_MEMBER_NOTE_REQUEST_SENT = '[members] CREATE_MEMBER_NOTE_REQUEST_SENT';
export const CREATE_MEMBER_NOTE_REQUEST_SUCCEEDED = '[members] CREATE_MEMBER_NOTE_REQUEST_SUCCEEDED';
export const CREATE_MEMBER_NOTE_REQUEST_FAILED = '[members] CREATE_MEMBER_NOTE_REQUEST_FAILED';

export const DELETE_MEMBER_NOTE_REQUEST = '[members] DELETE_MEMBER_NOTE_REQUEST';
export const DELETE_MEMBER_NOTE_REQUEST_SENT = '[members] DELETE_MEMBER_NOTE_REQUEST_SENT';
export const DELETE_MEMBER_NOTE_REQUEST_SUCCEEDED = '[members] DELETE_MEMBER_NOTE_REQUEST_SUCCEEDED';
export const DELETE_MEMBER_NOTE_REQUEST_FAILED = '[members] DELETE_MEMBER_NOTE_REQUEST_FAILED';

export const CREATE_MEMBER_VEHICLE_REQUEST = '[members] CREATE_MEMBER_VEHICLE_REQUEST';
export const CREATE_MEMBER_VEHICLE_REQUEST_SENT = '[members] CREATE_MEMBER_VEHICLE_REQUEST_SENT';
export const CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED = '[members] CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED';
export const CREATE_MEMBER_VEHICLE_REQUEST_FAILED = '[members] CREATE_MEMBER_VEHICLE_REQUEST_FAILED';

export const UPDATE_MEMBER_VEHICLE_REQUEST = '[members] UPDATE_MEMBER_VEHICLE_REQUEST';
export const UPDATE_MEMBER_VEHICLE_REQUEST_SENT = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_SENT';
export const UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED';
export const UPDATE_MEMBER_VEHICLE_REQUEST_FAILED = '[members] UPDATE_MEMBER_VEHICLE_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_ARE_SEARCH_RESULTS_VALID = '[members] SET_ARE_SEARCH_RESULTS_VALID';
export const STORE_MEMBER_SEARCH_RESULTS = '[members] STORE_MEMBER_SEARCH_RESULTS';
export const SET_MEMBER_PERSISTENCE_STATE = '[members] SET_MEMBER_PERSISTENCE_STATE';
export const STORE_MEMBER = '[members] STORE_MEMBER';
export const STORE_MEMBER_VEHICLE = '[members] STORE_MEMBER_VEHICLE';
export const STORE_MEMBER_SERVICE_CASES = '[members] STORE_MEMBER_SERVICE_CASES';
export const STORE_MEMBER_VEHICLES = '[members] STORE_MEMBER_VEHICLES';
export const REMOVE_MEMBER_VEHICLE = '[members] REMOVE_MEMBER_VEHICLE';
export const STORE_VEHICLE_SEARCH_RESULTS = '[members] STORE_VEHICLE_SEARCH_RESULTS';
export const STORE_MEMBER_NOTE = '[members] STORE_MEMBER_NOTE';
export const REMOVE_MEMBER_NOTE = '[members] REMOVE_MEMBER_NOTE';
export const SET_MEMBER_ADDRESS_CANDIDATES = '[members] SET_MEMBER_ADDRESS_CANDIDATES';
export const SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR = '[members] SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR';
