import {take, call, put, select} from 'redux-saga/effects';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Select SARC rental car flow
 */
const selectSARCRentalCarStationFlow = function* selectSARCRentalCarStationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_RENTAL_CAR_STATION_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, provisionalRentalCarStation} = payload;
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-rental-car');
        const sarcRentalCarStationsLayer = yield call(arcGISMap.getLayer, 'sarc-rental-car-stations');

        if (sarcRentalCarStationsLayer) {
            yield call(sarcRentalCarStationsLayer.selectFeatureByAttribute, {
                where: `stationID = '${provisionalRentalCarStation.id}'`,
            });
        }

        yield put({
            type: sarcActionTypes.SET_SARC_PROVISIONAL_RENTAL_CAR_STATION,
            payload: {serviceAssignmentId, provisionalRentalCarStation},
        });
    }
};

export default selectSARCRentalCarStationFlow;
