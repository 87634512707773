import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {efServiceCaseTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as sarjActionTypes from './sarjActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import AlternativeServiceProviderPanel from '../service-assignments/ui-elements/AlternativeServiceProviderPanel';
import ServiceProviderPanel from '../service-assignments/ui-elements/ServiceProviderPanel';
import serviceAssignmentPartnerDisabledStateStatuses from '../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';

const SARJServiceProviderTab = props => {
    const {cx} = useStyles();
    const {serviceAssignment, serviceProviders, serviceCase} = props;
    const {resetServiceProviderList, submitSARJServiceProviderForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(!!serviceAssignment?.acePartner?.isManuallyAdded);
    const [selectedServiceProviderId, setSelectedServiceProviderId] = useState(
        serviceAssignment?.acePartner && !serviceAssignment?.acePartner?.isManuallyAdded
            ? serviceAssignment.acePartner?.id : null,
    );

    const handleOnToggleSwitch = () => {
        if (!isEditModeActive && selectedServiceProviderId !== null) {
            // false to true: remove selected service provider since agent wants to use the alternative one
            setSelectedServiceProviderId(null);
        }
        setIsEditModeActive(!isEditModeActive);
    };

    const handleOnTabChange = useCallback(() => {
        submitSARJServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                ...(!isEditModeActive
                    ? {
                        acePartner: selectedServiceProviderId
                            ? {
                                ...serviceProviders[selectedServiceProviderId],
                                location: {
                                    ...(!!serviceProviders[selectedServiceProviderId]?.location
                                            && serviceProviders[selectedServiceProviderId].location),
                                    ...(!!serviceProviders[selectedServiceProviderId]?.address
                                            && serviceProviders[selectedServiceProviderId].address),
                                },
                                contactDetails: null,
                            }
                            : null,
                    }
                    : {}
                ),
            },
        });
    }, [
        isEditModeActive,
        selectedServiceProviderId,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        submitSARJServiceProviderForm,
        serviceProviders,
    ]);

    useRouteUnmountEffect(() => {
        if (serviceAssignment.persistenceState === persistenceStates.READY) {
            resetServiceProviderList();
        }
        handleOnTabChange();
    }, [handleOnTabChange, resetServiceProviderList, serviceAssignment.persistenceState]);

    const handleOnSelectServiceProvider = id => {
        if (id && typeof id === 'string') {
            setSelectedServiceProviderId(id);
        }
    };

    // if no service assignment or service case, don't render
    if (!serviceAssignment || !serviceCase) return null;

    const {assignmentStatus} = serviceAssignment;
    const isPartnerChangingDisabled = serviceAssignmentPartnerDisabledStateStatuses.includes(assignmentStatus)
        && serviceCase.caseType === efServiceCaseTypes.VEHICLE;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ])}
            >
                <ServiceProviderPanel
                    isEditModeActive={isEditModeActive}
                    selectedServiceProviderId={selectedServiceProviderId}
                    handleOnSelectServiceProvider={handleOnSelectServiceProvider}
                    isPartnerChangingDisabled={isPartnerChangingDisabled}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <AlternativeServiceProviderPanel
                    serviceCase={serviceCase}
                    serviceAssignment={serviceAssignment}
                    isActive={isEditModeActive}
                    onToggleSwitch={handleOnToggleSwitch}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SARJServiceProviderTab.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceProviders: PropTypes.object,
    submitSARJServiceProviderForm: PropTypes.func.isRequired,
    resetServiceProviderList: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
};

SARJServiceProviderTab.defaultProps = {
    serviceAssignment: null,
    serviceProviders: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceProviders: state.serviceAssignments.serviceProviders,
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARJServiceProviderForm: payload => dispatch({
        type: sarjActionTypes.SUBMIT_SARJ_SERVICE_PROVIDER_FORM,
        payload,
    }),
    resetServiceProviderList: () => dispatch({
        type: serviceAssignmentActionTypes.RESET_SERVICE_PROVIDERS,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SARJServiceProviderTab));
