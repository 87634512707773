import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efDamageUITypes, efDamageNodeTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel} from '@ace-de/ui-components';
import {RadioTile, RadioButton, RadioButtonGroup, CheckboxTile, CheckboxGroup, Checkbox} from '@ace-de/ui-components/selector-inputs';
import {infoIcon} from '@ace-de/ui-components/icons';
import {decisionTreeIcons} from './decisionTreeIcons';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as savActionTypes from '../savActionTypes';

const DamageDetailsPanel = ({decision, damageNodes, selectDecisionTreeOptions, serviceCase, serviceAssignment}) => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const initialSelectedOption = typeof damageNodes[decision] !== 'undefined'
        ? damageNodes[decision].childNodeIds.filter(node => {
            return serviceCase.damageNodeSnapshots.includes(node);
        }) : [];
    const [selectedOption] = useState(initialSelectedOption);

    if (Object.keys(damageNodes).length === 0) return null;

    const handleSymptomsChange = symptoms => {
        const selectedOptions = Array.isArray(symptoms) ? [...symptoms] : [symptoms];

        selectDecisionTreeOptions({
            serviceCase,
            serviceAssignment,
            parentId: damageDetailsDecision.id,
            selectedOptions,
        });
    };

    const selectOptionIcon = name => {
        const iconId = name.split('.').pop().toUpperCase();
        return decisionTreeIcons[iconId] || infoIcon;
    };

    const damageDetailsDecision = damageNodes[decision];
    const damageDetailsAnswers = damageNodes[decision].childNodeIds;

    if (damageDetailsDecision.nodeType === efDamageNodeTypes.ANSWER
        || damageDetailsDecision.nodeType === efDamageNodeTypes.SERVICE
        || damageDetailsAnswers.length === 0
        || (damageDetailsAnswers.length === 1 && damageDetailsAnswers[0] === null)) {
        return null;
    }

    const isStringLonger = str => str.length > 18;

    return (
        <Panel title={translate(damageDetailsDecision.tierTitle)}>
            <div>
                <p
                    className={cx('global!ace-u-margin--bottom-24', {
                        'global!ace-u-typography--variant-body-bold': damageDetailsDecision.nodeType !== efDamageNodeTypes.DECISION,
                    })}
                >
                    {translate(damageDetailsDecision.name)}
                </p>
                {damageDetailsDecision.typeUI?.includes('RADIO') && (
                    <RadioButtonGroup
                        name={`damageSymptomsRadioGroup${decision}`}
                        onChange={handleSymptomsChange}
                        value={selectedOption[0]}
                    >
                        <div className={cx('global!ace-u-grid')}>
                            {damageDetailsAnswers.map((answerId, idx) => {
                                const answer = damageNodes[answerId];

                                return (
                                    <div
                                        key={`radioOptionWrap${decision}${idx}`}
                                        className={cx(isStringLonger(translate(answer.name))
                                            ? 'global!ace-u-grid-column--span-8'
                                            : 'global!ace-u-grid-column--span-2')
                                        }
                                    >
                                        {damageDetailsDecision.typeUI === efDamageUITypes.RADIOBUTTON && (
                                            <RadioButton
                                                value={answerId}
                                                name={`damageSymptomAnswer${answer.id}`}
                                            >
                                                {translate(answer.name)}
                                            </RadioButton>
                                        )}
                                        {damageDetailsDecision.typeUI === efDamageUITypes.RADIOTILE && (
                                            <RadioTile
                                                icon={selectOptionIcon(answer.name)}
                                                value={answerId}
                                                name={`damageSymptomAnswer${answer.id}`}
                                                className={cx('global!ace-u-full-width')}
                                            >
                                                {translate(answer.name)}
                                            </RadioTile>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </RadioButtonGroup>
                )}

                {damageDetailsDecision.typeUI?.includes('CHECKBOX') && (
                    <CheckboxGroup
                        name={`damageSymptomsCheckboxGroup${decision}`}
                        onChange={handleSymptomsChange}
                        value={selectedOption}
                    >
                        <div className={cx('global!ace-u-grid')}>
                            {damageDetailsAnswers.map((answerId, idx) => {
                                const answer = damageNodes[answerId];
                                return (
                                    <div
                                        key={`checkboxOptionWrap${decision}${idx}`}
                                        className={cx('global!ace-u-grid-column--span-2')}
                                    >
                                        {damageDetailsDecision.typeUI === efDamageUITypes.CHECKBOX && (
                                            <Checkbox
                                                value={answerId}
                                                name={`damageSymptomAnswer${answer.id}`}
                                            >
                                                {translate(answer.name)}
                                            </Checkbox>
                                        )}
                                        {damageDetailsDecision.typeUI === efDamageUITypes.CHECKBOXTILE && (
                                            <CheckboxTile
                                                icon={selectOptionIcon(answer.name)}
                                                value={answerId}
                                                name={`damageSymptomAnswer${answer.id}`}
                                                className={cx('global!ace-u-full-width')}
                                            >
                                                {translate(answer.name)}
                                            </CheckboxTile>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </CheckboxGroup>
                )}
            </div>
        </Panel>
    );
};

DamageDetailsPanel.propTypes = {
    decision: PropTypes.number.isRequired,
    damageNodes: PropTypes.object,
    serviceCase: PropTypes.object.isRequired,
    serviceAssignment: PropTypes.object.isRequired,
    selectDecisionTreeOptions: PropTypes.func,
};

DamageDetailsPanel.defaultProps = {
    damageNodes: {},
    selectDecisionTreeOptions: () => null,
};

const mapStateToProps = (state, props) => {
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        damageNodes: serviceCaseSelectors.getServiceTypeDamageNodes(state),
        serviceCase: getServiceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    selectDecisionTreeOptions: payload => dispatch({
        type: savActionTypes.SELECT_DECISION_TREE_OPTIONS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DamageDetailsPanel));
