import {take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceTypeSelectionTypes, efServiceCaseTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as savActionTypes from '../savActionTypes';

/**
 * SAV service type selection flow (uses modal)
 */
const savServiceTypeSelectionFlow = function* savServiceTypeSelectionFlow() {
    while (true) {
        const servicePickRequest = yield take([savActionTypes.INITIATE_SAV_SERVICE_TYPE_SELECTION_FLOW]);
        const {serviceCaseId, serviceAssignmentLineNo} = servicePickRequest.payload;

        yield* openModal(modalIds.SAV_SERVICE_TYPE_SELECTION);
        const chosenModalOption = yield take([
            savActionTypes.CONFIRM_SAV_SERVICE_TYPE_SELECTION,
            savActionTypes.DECLINE_SAV_SERVICE_TYPE_SELECTION,
        ]);

        if (chosenModalOption && chosenModalOption.type === savActionTypes.CONFIRM_SAV_SERVICE_TYPE_SELECTION) {
            const {serviceType, damageNodeSnapshots} = chosenModalOption.payload;
            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_TYPE,
                payload: {
                    serviceCaseId: serviceCaseId,
                    serviceType,
                },
            });

            // ACEECS-6070 fix: BE removed serviceType from SC patch object. Service type on SC will be patched
            // automatically as soon as serviceType on assignment is patched.
            // This is the reason why we need to update assignment first and then service case.
            yield* updateServiceAssignment({
                caller: savActionTypes.CONFIRM_SAV_SERVICE_TYPE_SELECTION,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    serviceType,
                    assignmentText: null,
                    // https://computerrock.atlassian.net/browse/ACEECS-6149
                    // In order to fix this issue, we have to send 'serviceSelectionType' as part of the
                    // ServiceAssignmentVehiclePatchDTO, even if the 'serviceSelectionType' field is not part
                    // of the ServiceAssignmentVehicle entity -> BE will consume this and update it
                    // on the ServiceCaseVehicle entity
                    serviceSelectionType: efServiceTypeSelectionTypes.MANUAL,
                },
            });

            yield* updateServiceCase({
                caller: savActionTypes.CONFIRM_SAV_SERVICE_TYPE_SELECTION,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId,
                serviceCaseData: {
                    damageNodeSnapshots,
                    serviceType,
                    // as mentioned above, this field is already sent to the BE
                    // (as part of the service assignment patch request),
                    // and their responsibility is to update it on the case level
                    // serviceSelectionType: efServiceTypeSelectionTypes.MANUAL,
                },
            });
        }

        yield* closeModal(modalIds.SAV_SERVICE_TYPE_SELECTION);
    }
};

export default savServiceTypeSelectionFlow;
