import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonSecondary, InteractiveIcon, RadioButtonGroup, RadioTile, legalIcon, healingIcon, medicationIcon} from '@ace-de/ui-components';
import {closeIcon, personOffIcon, hospitalIcon, deathCaseIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';

const CreatePersonServiceCaseModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, declineCreatePersonServiceCase, confirmCreatePersonServiceCase, location} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('create_person_service_case_modal');
    const [selectedPersonDamageType, setSelectedPersonDamageType] = useState('');

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => declineCreatePersonServiceCase()}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={translateModal('title.personal_damage')}
            contentClassName={cx('global!ace-u-margin--top-32')}
        >
            <div>
                <RadioButtonGroup
                    name="personDamageTypeSelection"
                    onChange={service => setSelectedPersonDamageType(service)}
                    value={selectedPersonDamageType}
                >
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={hospitalIcon}
                                value={efServiceAssignmentTypes.MEDICAL_CLEARANCE}
                                name="medicalClearanceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.medical_clearance')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={personOffIcon}
                                value={efServiceAssignmentTypes.SUBSTITUTE_DRIVER}
                                name="substituteDriverAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.substitute_driver')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={deathCaseIcon}
                                value={efServiceAssignmentTypes.DEATH}
                                name="deathEventHelpAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.help_in_case_of_death')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={medicationIcon}
                                value={efServiceAssignmentTypes.MEDICINE_SHIPMENT}
                                name="medicineShipmentAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.medicine_shipment')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={healingIcon}
                                value={efServiceAssignmentTypes.MEDICAL_ADVICE}
                                name="medicalAdviceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.medical_advice')}
                            </RadioTile>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-4')}>
                            <RadioTile
                                icon={legalIcon}
                                value={efServiceAssignmentTypes.LEGAL_ADVICE}
                                name="legalAdviceAssignmentOption"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-full-height',
                                ])}
                            >
                                {translateModal('radio_tile_label.legal_advice')}
                            </RadioTile>
                        </div>
                    </div>
                </RadioButtonGroup>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <ButtonSecondary
                        name="createPersonServiceCaseButton"
                        className={cx('global!ace-u-margin--top-32')}
                        isDisabled={!selectedPersonDamageType}
                        onClick={() => confirmCreatePersonServiceCase({
                            assignmentType: selectedPersonDamageType,
                            pathname: location.pathname,
                        })}
                    >
                        {translateModal('button_label.select')}
                    </ButtonSecondary>
                </div>
            </div>
        </Modal>
    );
};

CreatePersonServiceCaseModal.propTypes = {
    confirmCreatePersonServiceCase: PropTypes.func.isRequired,
    declineCreatePersonServiceCase: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object,
};

CreatePersonServiceCaseModal.defaultProps = {
    hasBackdrop: true,
    location: {},
};

const mapDispatchToProps = dispatch => ({
    confirmCreatePersonServiceCase: payload => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_CREATE_PERSON_SERVICE_CASE,
        payload,
    }),
    declineCreatePersonServiceCase: () => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_CREATE_PERSON_SERVICE_CASE,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(CreatePersonServiceCaseModal));
