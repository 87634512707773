/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE';
export const SUBMIT_SAMA_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SAMA_REQUIREMENTS_FORM';
export const SUBMIT_SAMA_SERVICE_LOCATION_FORM = '[service-cases] SUBMIT_SAMA_SERVICE_LOCATION_FORM';
export const SUBMIT_SAMA_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SAMA_SERVICE_PROVIDER_FORM';
export const SEARCH_SAMA_DOCTOR_LOCATIONS = '[service-cases] SEARCH_SAMA_DOCTOR_LOCATIONS';
export const START_SAMA_MAP_DOCTOR_LOCATION_WATCHER = '[service-cases] START_SAMA_MAP_DOCTOR_LOCATION_WATCHER';
export const STOP_SAMA_MAP_DOCTOR_LOCATION_WATCHER = '[service-cases] STOP_SAMA_MAP_DOCTOR_LOCATION_WATCHER';
export const SUBMIT_SAMA_COMMISSIONING_FORM = '[service-cases] SUBMIT_SAMA_COMMISSIONING_FORM';
export const SEARCH_SAMA_SERVICE_PROVIDERS = '[service-cases] SEARCH_SAMA_SERVICE_PROVIDERS';
export const START_SAMA_MAP_SERVICE_PROVIDER_WATCHER = '[service-cases] START_SAMA_MAP_SERVICE_PROVIDER_WATCHER';
export const STOP_SAMA_MAP_SERVICE_PROVIDER_WATCHER = '[service-cases] STOP_SAMA_MAP_SERVICE_PROVIDER_WATCHER';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_ADVICE_REQUEST_FAILED';

export const SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST = '[service-cases] SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST';
export const SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_SENT = '[service-cases] SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_SENT';
export const SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED';
export const SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_FAILED = '[service-cases] SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_FAILED';

export const FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST = '[service-cases] FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST';
export const FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_SENT = '[service-cases] FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_SENT';
export const FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED';
export const FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_FAILED = '[service-cases] FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_FAILED';

export const FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST = '[service-cases] FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST';
export const FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_SENT = '[service-cases] FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_SENT';
export const FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_FAILED';

export const FETCH_SERVICE_PROVIDERS_REQUEST = '[service-cases] FETCH_SERVICE_PROVIDERS_REQUEST';
export const FETCH_SERVICE_PROVIDERS_REQUEST_SENT = '[service-cases] FETCH_SERVICE_PROVIDERS_REQUEST_SENT';
export const FETCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_PROVIDERS_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_PROVIDERS_REQUEST_FAILED';

export const FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST = '[service-cases] FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST';
export const FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_SENT = '[service-cases] FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_SENT';
export const FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_PROVIDER_GEOLOCATIONS_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAMA_DOCTOR_LOCATION_RECOMMENDATIONS = '[service-cases] SET_SAMA_DOCTOR_LOCATION_RECOMMENDATIONS';
export const STORE_SAMA_SERVICE_PROVIDERS = '[service-cases] STORE_SAMA_SERVICE_PROVIDERS';
