import {take, fork, put, select} from 'redux-saga/effects';
import {Vehicle} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Update members vehicle activity flow
 */
const updateMemberVehicleActivityFlow = function* updateMemberVehicleActivityFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take(memberActionTypes.INITIATE_UPDATE_MEMBER_VEHICLE_ACTIVITY_FLOW);
        const {vehicleId, vehicleData, membershipNo} = payload;

        yield fork(
            fetchRequest,
            memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST,
            membersService.updateMemberVehicle,
            {
                memberVehiclePatchDTO: Vehicle.objectToPatchDTO(vehicleData),
                vehicleId,
            },
        );
        const responseAction = yield take([
            memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
            memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {memberVehicleDTO} = response;

            yield put({
                type: memberActionTypes.STORE_MEMBER_VEHICLE,
                payload: {membershipNo, memberVehicleDTO},
            });
        }
    }
};

export default updateMemberVehicleActivityFlow;
