import {select, take, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes, BusinessContactDetails} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as samaActionTypes from '../samaActionTypes';

const selectSAMAServiceProviderFlow = function* selectSAMAServiceProviderFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(samaActionTypes.SUBMIT_SAMA_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, doctorLocation, serviceProvider} = payload;
        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');
        const samaDoctorsLayer = yield call(arcGISMap.getLayer, 'sama-doctors-layer');
        const samaServiceProvidersLayer = yield call(arcGISMap.getLayer, 'sama-service-providers-layer');

        if (!samaDoctorsLayer || (!doctorLocation && !serviceProvider) || !arcGISMap) continue;

        let businessContactDetails;
        if (doctorLocation) {
            yield call(samaDoctorsLayer.selectFeatureByAttribute, {
                where: `locationId = '${doctorLocation.id}'`,
            });

            businessContactDetails = new BusinessContactDetails({
                phoneNo: doctorLocation.phoneNo || '',
            });
        }

        if (serviceProvider) {
            yield call(samaServiceProvidersLayer.selectFeatureByAttribute, {
                where: `locationId = '${serviceProvider.arcGISData.locationId}'`,
            });
        }

        yield* updateServiceAssignment({
            caller: 'SELECT_SAMA_SERVICE_PROVIDER_LOCATION',
            assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...(serviceProvider
                        ? {
                            id: null,
                            externalId: serviceProvider.id,
                            location: {
                                ...serviceProvider.location,
                                ...serviceProvider.address,
                                id: serviceProvider.arcGISData.locationId,
                            },
                            name: serviceProvider.name || '',
                            businessContactDetails: serviceProvider.businessContactDetails,
                        }
                        : {
                            id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                            externalId: doctorLocation.id,
                            location: {
                                ...doctorLocation,
                                locationId: doctorLocation.id,
                            },
                            name: doctorLocation.locationName || '',
                            businessContactDetails,
                        }),
                },
            },
        });
    }
};

export default selectSAMAServiceProviderFlow;
