import {select, fork, take, put} from 'redux-saga/effects';
import moment from 'moment/moment';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sapActionTypes from '../sapActionTypes';

const loadPickupPrices = function* loadPickupPrices({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;

    if (!serviceCaseId) return;

    const {serviceCases} = yield select(state => state.serviceCases);
    const serviceCase = serviceCases[serviceCaseId];

    if (!serviceCase) return;

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {createdAt} = serviceCase;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('validOn', moment(createdAt).format('YYYY-MM-DD'));
    searchQueryParams.append('type', apsPriceTypes.PICKUP_PRICE);

    yield fork(
        fetchRequest,
        sapActionTypes.FETCH_PICKUP_PRICES_REQUEST,
        pricingManagementService.getValidPrice,
        {
            searchQueryParams,
        },
    );

    const pickupPricesResponseAction = yield take([
        sapActionTypes.FETCH_PICKUP_PRICES_REQUEST_SUCCEEDED,
        sapActionTypes.FETCH_PICKUP_PRICES_REQUEST_FAILED,
    ]);

    searchQueryParams.set('type', apsPriceTypes.SURCHARGE_RATE);

    yield fork(
        fetchRequest,
        sapActionTypes.FETCH_SURCHARGE_RATES_REQUEST,
        pricingManagementService.getValidPrice,
        {
            searchQueryParams,
        },
    );

    const surchargeRatesResponseAction = yield take([
        sapActionTypes.FETCH_SURCHARGE_RATES_REQUEST_SUCCEEDED,
        sapActionTypes.FETCH_SURCHARGE_RATES_REQUEST_FAILED,
    ]);

    if (!pickupPricesResponseAction.error && !surchargeRatesResponseAction.error) {
        const {response: pickupPricesResponse} = pickupPricesResponseAction.payload;
        const {response: surchargeRatesResponse} = surchargeRatesResponseAction.payload;
        const {pricePeriodDTOs: pickupPriceDTOs} = pickupPricesResponse;
        const {pricePeriodDTOs: surchargeRateDTOs} = surchargeRatesResponse;

        yield put({
            type: sapActionTypes.STORE_PICKUP_PRICES,
            payload: {
                pickupPriceDTOs,
                surchargeRateDTOs,
            },
        });
    }
};

export default loadPickupPrices;
