import {take, put, call, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED, matchPath} from '@computerrock/formation-router';
import {efServiceAssignmentTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savaActionTypes from '../savaActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';
import routePaths from '../../routePaths';

const updateSAVACommissioningFlow = function* updateSAVACommissioningFlow() {
    while (true) {
        const {payload} = yield take(savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM);
        const {
            serviceAssignmentLineNo,
            serviceAssignmentData,
            serviceCaseId,
            serviceCaseData,
            nonAceMember,
            attachmentsToSendWithAssignment,
        } = payload;
        const {location} = yield select(state => state.router);
        const dashboardScreenMatch = matchPath(location.pathname, {
            path: routePaths.DASHBOARD,
            exact: true,
        });

        // if the dashboardScreenMatch=true
        // service assignment was assigned successfully, no need to execute route unmount effect
        // all the serviceAssignmentData and serviceCaseData were already stored properly
        if (!dashboardScreenMatch) {
            yield call(sendAttachmentsWithAssignmentFlow, {
                caller: savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM,
                attachmentsToSendWithAssignment,
                serviceCaseId,
            });

            yield* updateServiceAssignment({
                caller: savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });

            if (nonAceMember) {
                yield* updateServiceCase({
                    caller: savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM,
                    caseType: efServiceCaseTypes.VEHICLE,
                    serviceCaseId,
                    serviceCaseData,
                });
            }
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savaActionTypes.SUBMIT_SAVA_COMMISSIONING_FORM},
        });
    }
};

export default updateSAVACommissioningFlow;
