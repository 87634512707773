import {put} from 'redux-saga/effects';
import config from '../../config';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const loadContractPartnerSearchResults = function* loadContractPartnerSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('sort', 'name,asc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);
    // include restrictions: get activeRestrictions and lastRestrictionType
    searchQueryParams.append('includeRestrictions', true);

    // do not reload contract partner search results when TR creation modal is open
    // TBD: improve performances on close: if the modal is submitted, then reload, otherwise no
    if (location.state?.modalId === modalIds.CP_CREATE_TEMPORARY_RESTRICTION
        && location.state?.isModalOpen) return;

    yield put({
        type: contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS,
        payload: {searchQueryParams},
    });
};

export default loadContractPartnerSearchResults;
