import {take, fork, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as samcActionTypes from '../samcActionTypes';

/**
 * Update SAMC damage location flow
 */
const updateSAMCDamageLocationFlow = function* updateSAMCDamageLocationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_FORM);
        const {serviceCaseId, serviceAssignmentLineNo, location, memberResidenceLocation} = payload;

        let distanceResidenceToRelevantLocation = null;

        // calculate the distance beetwen member's residence and damage location
        const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignment = serviceAssignments[serviceAssignmentId];
        if (serviceCase.member?.personalDetails?.coordinates) {
            const memberResidenceLocation = serviceCase.member.personalDetails.coordinates;

            yield fork(
                fetchRequest,
                samcActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: location.longitude,
                        latitude: location.latitude,
                    },
                    destination: {
                        longitude: memberResidenceLocation.longitude,
                        latitude: memberResidenceLocation.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                samcActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                samcActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                distanceResidenceToRelevantLocation = arcGISRouteDTO.totalKilometers;
            }
        }

        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        if (isInitialAssignment) {
            // update damage location on person case
            yield* updateServiceCase({
                caller: samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_FORM,
                caseType: serviceCase.caseType,
                serviceCaseId,
                serviceCaseData: {
                    damage: {
                        location,
                    },
                    ...(!!distanceResidenceToRelevantLocation && {
                        distanceResidenceToDamageLocation: distanceResidenceToRelevantLocation,
                    }),
                },
            });
        }

        // update service location on sasd service assignment
        yield* updateServiceAssignment({
            caller: samcActionTypes.SUBMIT_SAMC_DAMAGE_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.MEDICAL_CLEARANCE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                serviceLocation: location,
                destination: memberResidenceLocation,
                ...(!!distanceResidenceToRelevantLocation && {
                    distanceResidenceToServiceLocation: distanceResidenceToRelevantLocation,
                }),
            },
        });

        // credit stored location
        yield* creditGeocodedLocation({location});
    }
};

export default updateSAMCDamageLocationFlow;
