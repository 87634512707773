/**
 * @typedef {string} tabId
 */

/**
 * SADEH screen tabs
 *
 * @enum {tabId}
 */
export default {
    REQUIREMENTS: 'requirements',
    TRIP_DATA: 'trip-data',
    SERVICE_PROVIDER: 'service-provider',
    COST_COVERAGE: 'cost-coverage',
};
