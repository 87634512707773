import {put, select, take, fork, call} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {RentalCarStation} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentHelpers from '../../service-assignments/sagas/serviceAssignmentHelpers';
import * as sarcActionTypes from '../sarcActionTypes';
import sarcScreenTabs from '../sarcScreenTabs';

const loadSARCNearbyRentalCarStations = function* loadSARCNearbyRentalCarStations({payload}) {
    const {match, location} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== sarcScreenTabs.SELECT_STATION || location.query?.modal || location.state?.isModalClosed) return;

    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const {memberLocation, pickupLocation} = serviceAssignment;
    if (!serviceAssignment || !(memberLocation || pickupLocation)) return;

    yield put({
        type: sarcActionTypes.SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS,
        payload: {serviceAssignmentId, rentalCarStationRecommendationDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-rental-car');
    if (!arcGISMap) return;

    const sarcRentalCarStationsLayer = yield call(arcGISMap.getLayer, 'sarc-rental-car-stations');
    if (!sarcRentalCarStationsLayer) return;

    yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);

    yield fork(
        fetchRequest,
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST,
        sarcRentalCarStationsLayer.filterPointFeaturesByDistance,
        {
            distance: 100,
            referentialPoint: pickupLocation || memberLocation,
            returnRoutes: true,
            featureCount: 10,
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_FAILED,
        sarcActionTypes.FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);

    if (filterPointFeaturesResponseAction.error) {
        yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
        return;
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    const {featureDTOs: rentalCarStationRecommendationDTOs = []} = response;

    // find candidate rental car station based on distance from damage location
    let nearestRoute;
    let recommendedRentalCarStationDTO;
    rentalCarStationRecommendationDTOs.forEach(rentalCarStationRecommendationDTO => {
        const routeToReferentialPointDTO = rentalCarStationRecommendationDTO['routeToReferentialPoint'];
        if (!routeToReferentialPointDTO) return;

        if (routeToReferentialPointDTO['totalKilometers'] < nearestRoute || !recommendedRentalCarStationDTO) {
            recommendedRentalCarStationDTO = rentalCarStationRecommendationDTO;
            nearestRoute = routeToReferentialPointDTO['totalKilometers'];
        }
    });

    // select features for recommended rental car station
    if (recommendedRentalCarStationDTO) {
        yield call(sarcRentalCarStationsLayer.selectFeatureByAttribute, {
            where: `stationID = '${recommendedRentalCarStationDTO['stationId']}'`,
        });
    }

    // save recommended rental car station as provisional if no rental car station was previously set
    if (!serviceAssignment.provisionalRentalCarStation) {
        const provisionalRentalCarStation = new RentalCarStation().fromDTO(recommendedRentalCarStationDTO);

        yield put({
            type: sarcActionTypes.SET_SARC_PROVISIONAL_RENTAL_CAR_STATION,
            payload: {serviceAssignmentId, provisionalRentalCarStation},
        });
    }

    yield put({
        type: sarcActionTypes.SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            rentalCarStationRecommendationDTOs,
            recommendedRentalCarStationId: recommendedRentalCarStationDTO
                ? recommendedRentalCarStationDTO['stationId'] : null,
        },
    });

    // display layers & unlock tabs
    sarcRentalCarStationsLayer.show();
    yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);
};

export default loadSARCNearbyRentalCarStations;
