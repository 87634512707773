import {all, fork} from 'redux-saga/effects';
import createServiceAssignmentLegalAdviceFlow from './sagas/createServiceAssignmentLegalAdviceFlow';
import updateSALACostCoverageFlow from './sagas/updateSALACostCoverageFlow';
import updateSALARequirements from './sagas/updateSALARequirements';
import updateSALAServiceProviderFlow from './sagas/updateSALAServiceProviderFlow';
import updateSALAServiceLocationFlow from './sagas/updateSALAServiceLocationFlow';

/**
 *  SALA watcher saga
 */
const salaWatcher = function* salaWatcher() {
    yield all([
        fork(createServiceAssignmentLegalAdviceFlow),
        fork(updateSALARequirements),
        fork(updateSALAServiceProviderFlow),
        fork(updateSALACostCoverageFlow),
        fork(updateSALAServiceLocationFlow),
    ]);
};

export default salaWatcher;
