import React, {useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {HighlightCircle, Icon, closeIcon, InteractiveIcon, infoAlertIcon, Modal, useStyles, ButtonPrimary, ButtonSecondary, LoaderSpinner} from '@ace-de/ui-components';
import * as applicationActionTypes from '../applicationActionTypes';
import * as requestStateSelectors from '../requestStateSelectors';
import defaultRequestState from '../defaultRequestState';

/**
 * ErrorMessageModal - displays the error message and two CTAs: retry and cancel
 * Reads location's query parameter 'actionType' to determine which request failed
 */
const ErrorMessageModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, location, confirmRetry, declineRetry, requestStates} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('error_message_modal');
    const requestState = useMemo(() => {
        if (!location?.query?.actionType) return defaultRequestState;
        return requestStates?.[location.query.actionType] || defaultRequestState;
    }, [location?.query?.actionType, requestStates]);
    const {isInProgress, translationKey} = requestState;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape' && !isInProgress) {
            declineRetry();
        }
    }, [declineRetry, isInProgress]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineRetry}
                    isDisabled={!!isInProgress}
                />
            )}
        >
            {!!isInProgress && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-full-width',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-content-center',
                        'global!ace-u-margin--24-0',
                    ])}
                >
                    <LoaderSpinner />
                </div>
            )}
            {!isInProgress && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-full-width',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-content-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <Icon
                            icon={infoAlertIcon}
                            className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                        />
                    </HighlightCircle>
                    <h1 className={cx('global!ace-u-margin--24-0')}>
                        {translateModal('heading.error')}
                    </h1>
                    {translationKey && typeof translationKey === 'string' && (
                        <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                            {translateModal(`error_message.${snakeCase(translationKey)}`)}
                        </p>
                    )}
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-center')}>
                        <ButtonPrimary
                            className={cx('global!ace-u-margin--right-16')}
                            onClick={confirmRetry}
                        >
                            {translateModal('button.try_again')}
                        </ButtonPrimary>
                        <ButtonSecondary
                            onClick={declineRetry}
                        >
                            {translateModal('button.cancel')}
                        </ButtonSecondary>
                    </div>
                </div>
            )}
        </Modal>
    );
};

ErrorMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmRetry: PropTypes.func.isRequired,
    declineRetry: PropTypes.func.isRequired,
    location: PropTypes.object,
    requestStates: PropTypes.object,
};

ErrorMessageModal.defaultProps = {
    hasBackdrop: true,
    location: null,
    requestStates: {},
};

const mapStateToProps = (state, props) => {
    return {
        requestStates: requestStateSelectors.getRequestStates(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmRetry: () => dispatch({
        type: applicationActionTypes.RETRY_REQUEST,
        payload: null,
    }),
    declineRetry: () => dispatch({
        type: applicationActionTypes.CANCEL_REQUEST,
        payload: null,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageModal);
