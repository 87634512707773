import {produce} from 'immer';
import {User} from '@ace-de/eua-entity-types';
import * as userProfileActionTypes from './userProfileActionTypes';

const initialState = {
    user: null,
    allowedShiftRoles: null,
    users: {},
    loggedInUsers: {},
    shiftRoleUpdateErrorCode: '',
};

const userReducer = produce((draft, action) => {
    const {payload, type} = action;
    switch (type) {
        case userProfileActionTypes.STORE_USER_PROFILE: {
            const {userDTO} = payload;
            draft.user = new User().fromDTO(userDTO);
            break;
        }

        case userProfileActionTypes.SET_USER_SHIFT_ROLE: {
            const {shiftRole} = payload;
            draft.user.shiftRole = shiftRole;
            break;
        }

        case userProfileActionTypes.STORE_USER_SHIFT_ROLES: {
            const {allowedShiftRoles} = payload;
            draft.allowedShiftRoles = allowedShiftRoles;
            break;
        }

        case userProfileActionTypes.STORE_USER_PROFILES: {
            const {userDTOs} = payload;
            userDTOs.forEach(userDTO => {
                const userId = userDTO.id;
                const user = draft.users[userId];
                if (!user) {
                    draft.users[userId] = new User()
                        .fromDTO(userDTO);
                    return;
                }
                draft.users[userId] = user.fromDTO(userDTO);
            });
            break;
        }

        case userProfileActionTypes.STORE_SHIFT_ROLE_UPDATE_ERROR_CODE: {
            const {shiftRoleUpdateErrorCode} = payload;
            draft.shiftRoleUpdateErrorCode = shiftRoleUpdateErrorCode;
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default userReducer;
