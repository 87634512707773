import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import sapScreenTabs from '../sapScreenTabs';
import * as sapActionTypes from '../sapActionTypes';
import onSAPMapClickSelectContractPartner from './onSAPMapClickSelectContractPartner';

const sapMapContractPartnerWatcher = function* sapMapContractPartnerWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(sapActionTypes.START_SAP_MAP_CONTRACT_PARTNER_WATCHER);

        const onSAPMapClickSelectContractPartnerTask = yield fork(onSAPMapClickSelectContractPartner, payload);

        while (true) {
            const watcherTerminationAction = yield take([
                sapActionTypes.STOP_SAP_MAP_CONTRACT_PARTNER_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === sapActionTypes.STOP_SAP_MAP_CONTRACT_PARTNER_WATCHER) {
                yield cancel(onSAPMapClickSelectContractPartner);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const sapRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_PICKUP,
                exact: true,
            });
            const {activeTab} = sapRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!sapRouteMatch || activeTab !== sapScreenTabs.SERVICE_PROVIDER) {
                yield cancel(onSAPMapClickSelectContractPartnerTask);
                continue mainFlow;
            }
        }
    }
};

export default sapMapContractPartnerWatcher;
