import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savActionTypes from '../savActionTypes';
import savScreenTabs from '../savScreenTabs';

const loadSAVMapDamageLocationWatcher = function* loadSAVMapDamageLocationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.DAMAGE_LOCATION) return;

    yield put({
        type: savActionTypes.START_SAV_MAP_DAMAGE_LOCATION_WATCHER,
        payload: {serviceCaseId},
    });
};

export default loadSAVMapDamageLocationWatcher;
