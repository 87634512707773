import {take, put, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED, matchPath} from '@computerrock/formation-router';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';
import routePaths from '../../routePaths';

/**
 * Update SAV damage description flow
 */
const updateSAVDamageDescriptionFlow = function* updateSAVDamageDescriptionFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM);
        const {serviceCaseData, serviceCaseId} = payload;
        const {location} = yield select(state => state.router);
        const dashboardScreenMatch = matchPath(location.pathname, {
            path: routePaths.DASHBOARD,
            exact: true,
        });

        // if the dashboardScreenMatch=true
        // service assignment was assigned successfully, no need to execute route unmount effect
        // all the serviceCaseData was already stored properly
        if (!dashboardScreenMatch) {
            yield* updateServiceCase({
                caller: savActionTypes.SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM},
        });
    }
};

export default updateSAVDamageDescriptionFlow;
