import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as samaActionTypes from '../samaActionTypes';

/**
 * Update SAMA requirements details
 */
const updateSAMARequirements = function* updateSAMARequirements() {
    while (true) {
        const {payload} = yield take(samaActionTypes.SUBMIT_SAMA_REQUIREMENTS_FORM);
        const {serviceCaseId, serviceAssignmentData, serviceAssignmentLineNo} = payload;

        yield* updateServiceAssignment({
            caller: samaActionTypes.SUBMIT_SAMA_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: samaActionTypes.SUBMIT_SAMA_REQUIREMENTS_FORM},
        });
    }
};

export default updateSAMARequirements;
