import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberStatusTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Badge} from '@ace-de/ui-components';
import {Icon, warningAlertIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as memberSelectors from '../memberSelectors';
import getPrice from '../../utils/getPrice';

const MemberDataCorporatePanel = props => {
    const {cx} = useStyles();
    const {member} = props;
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');

    // if no member don't render
    if (!member) return null;

    const membershipBeginDate = member.beginMembership ? moment(member.beginMembership).format('DD.MM.YYYY') : '';
    const membershipEndDate = member.endMembership ? moment(member.endMembership).format('DD.MM.YYYY') : '-';
    const isMembershipEnded = member.endMembership ? moment(member.endMembership).isSameOrBefore(moment()) : false;
    const {personalDetails, contactDetails, tariffDetails} = member;
    const {address} = personalDetails;

    return (
        <Panel
            title={translateScreen('membership_data_panel_corporate.title')}
            qaIdent="member-data-corporate"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.company')}
                        qaIdent="member-personal-details-name"
                    >
                        {personalDetails.name || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.address')}
                        qaIdent="member-personal-details-address"
                    >
                        {[
                            address.street,
                            [address.postCode, address.city].filter(value => !!value).join(' '),
                        ].filter(value => !!value).join(', ') || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.phone_number')}
                        qaIdent="member-contact-details-phone"
                    >
                        {contactDetails.phoneNo || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.email')}
                        qaIdent="member-contact-details-email"
                    >
                        {contactDetails.email || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.membership_begin_date')}
                        qaIdent="member-membership-begin-date"
                    >
                        {membershipBeginDate || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.membership_end_date')}
                        qaIdent="member-membership-end-date"
                    >
                        <span className={cx({
                            'global!ace-u-typography--color-warning': isMembershipEnded,
                            'global!ace-u-typography--variant-body-bold': isMembershipEnded,
                        })}
                        >
                            {membershipEndDate || '-'}
                        </span>
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.membership_no')}
                        qaIdent="member-membership-number"
                    >
                        {member.membershipNo || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.current_tariff')}
                        qaIdent="member-membership-tariff"
                    >
                        {tariffDetails.tariffGroup
                            ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[tariffDetails.tariffGroup])}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.tariff_description')}
                        qaIdent="member-membership-tariff-additional-package"
                    >
                        {tariffDetails.additionalPackage || '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.status')}
                        qaIdent="member-status"
                    >
                        {member.status !== ampMemberStatusTypes.EMPTY ? (
                            <Badge status={member.status.toLowerCase()}>
                                {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                            </Badge>
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.dunning_level')}
                        qaIdent="member-dunning-level"
                    >
                        <p
                            className={cx({
                                'global!ace-u-typography--color-warning': member.dunningLevel
                                    >= config.WARNING_DUNNING_LEVEL,
                            })}
                        >
                            {member.dunningLevel}
                        </p>
                        {member.dunningLevel >= config.WARNING_DUNNING_LEVEL && (
                            <Icon
                                icon={warningAlertIcon}
                                className={cx('ace-c-icon--color-warning', 'global!ace-u-margin--left-16')}
                            />
                        )}
                    </DataRow>
                    <DataRow
                        label={translateScreen('membership_data_panel_input_label_corporate.balance_member_fee')}
                        qaIdent="member-membership-tariff-balance"
                    >
                        {getPrice(tariffDetails.balanceMemberFee, activeLocale)}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

MemberDataCorporatePanel.propTypes = {
    member: PropTypes.object,
};

MemberDataCorporatePanel.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(MemberDataCorporatePanel));
