/**
 * @typedef {string} tabId
 */

/**
 * SASP screen tabs
 *
 * @enum {tabId}
 */
export default {
    SERVICE_PROVIDER: 'service-provider',
    COMMISSIONING: 'commissioning',
};
