import {select, fork} from 'redux-saga/effects';
import {efLocationTypes} from '@ace-de/eua-entity-types';

/**
 * Credit geocoded location
 *
 * Used for applying credit expending according to ESRI terms & services. When geocoded information is saved
 * in our database, we need to apply forStorage=true parameter to geocoding call. Since this is not always possible
 * within original call, this utility method should be used to credit geocoded location after it was selected by
 * application user for storing and save method is called.
 * ticket: https://computerrock.atlassian.net/browse/ACEECS-4294
 */
const creditGeocodedLocation = function* creditGeocodedLocation(params) {
    const {location} = params;

    // skip if no location, or location was not acquired by using suggest+bulk geocoding method
    if (!location || (location.locationType !== efLocationTypes.SELECT_LOCATION)) return;

    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield fork(
        arcGISRESTService.searchAddressLocation,
        {
            singleLine: location.formattedAddress,
            forStorage: true,
        },
    );
};

export default creditGeocodedLocation;
