import {take, fork, select, put} from 'redux-saga/effects';
import {efServiceCaseTypes, efServiceAssignmentTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';
import * as savActionTypes from '../savActionTypes';

/**
 * Update SAV damage location flow
 */
const updateSAVDamageLocationFlow = function* updateSAVDamageLocationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_FORM);
        const {serviceCaseId, serviceAssignmentLineNo, serviceCaseData, serviceAssignmentData} = payload;

        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const serviceAssignment = yield select(state => {
            return state.serviceAssignments.serviceAssignments[serviceAssignmentId];
        });
        yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);

        const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);
        if (serviceCase.member?.personalDetails?.coordinates) {
            const memberResidenceLocation = serviceCase.member.personalDetails.coordinates;
            const damageLocation = serviceCaseData.damage.location;

            yield fork(
                fetchRequest,
                savActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: damageLocation.longitude,
                        latitude: damageLocation.latitude,
                    },
                    destination: {
                        longitude: memberResidenceLocation.longitude,
                        latitude: memberResidenceLocation.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                },
            );

            const routeResponseAction = yield take([
                savActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                savActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                serviceCaseData['distanceResidenceToDamageLocation'] = arcGISRouteDTO.totalKilometers;
            }
        }

        // We have to patch serviceAssignment.serviceLocation whenever agent set serviceCase.damage.location
        // From the ECS web app perspective, we will never read this field, but it is mandatory for the driver app,
        // and they need to read it from the assignment
        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                ...serviceAssignmentData,
                ...(serviceAssignment?.assignmentStatus === efServiceAssignmentStatusTypes.NEW
                    ? {towingDestination: null, acePartner: null, assignmentText: null} : {}),
            },
        });

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_FORM,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData,
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceCaseData.damage.location});

        // reset towing destination recommendations and recommended towing destination on damage location change
        // reset contract partner recommendations and recommended contract partner on damage location change
        if (serviceAssignment?.assignmentStatus === efServiceAssignmentStatusTypes.NEW) {
            yield put({
                type: savActionTypes.SET_SAV_TOWING_DESTINATION_RECOMMENDATIONS,
                payload: {
                    serviceAssignmentId,
                    towingDestinationRecommendationDTOs: [],
                    recommendedTowingDestinationId: null,
                },
            });

            yield put({
                type: savActionTypes.SET_SAV_CONTRACT_PARTNER_RECOMMENDATIONS,
                payload: {
                    serviceAssignmentId,
                    contractPartnerRecommendationDTOs: [],
                    recommendedContractPartnerId: null,
                },
            });
        }
    }
};

export default updateSAVDamageLocationFlow;
