/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL';
export const START_SAVA_MAP_TOWING_DESTINATION_WATCHER = '[service-cases] START_SAVA_MAP_TOWING_DESTINATION_WATCHER';
export const STOP_SAVA_MAP_TOWING_DESTINATION_WATCHER = '[service-cases] STOP_SAVA_MAP_TOWING_DESTINATION_WATCHER';
export const START_SAVA_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] START_SAVA_MAP_CONTRACT_PARTNER_WATCHER';
export const STOP_SAVA_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] STOP_SAVA_MAP_CONTRACT_PARTNER_WATCHER';
export const SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION';
export const SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION';
export const START_SAVA_MAP_SERVICE_LOCATION_WATCHER = '[service-cases] START_SAVA_MAP_SERVICE_LOCATION_WATCHER';
export const STOP_SAVA_MAP_SERVICE_LOCATION_WATCHER = '[service-cases] STOP_SAVA_MAP_SERVICE_LOCATION_WATCHER';
export const SUBMIT_SAVA_SERVICE_LOCATION_FORM = '[service-cases] SUBMIT_SAVA_SERVICE_LOCATION_FORM';
export const SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM = '[service-cases] SUBMIT_SAVA_SERVICE_LOCATION_INFO_FORM';
export const SUBMIT_SAVA_CONTRACT_PARTNER_FORM = '[service-cases] SUBMIT_SAVA_CONTRACT_PARTNER_FORM';
export const SEARCH_SAVA_TOWING_DESTINATIONS = '[service-cases] SEARCH_SAVA_TOWING_DESTINATIONS';
export const SUBMIT_SAVA_TOWING_DESTINATION_FORM = '[service-cases] SUBMIT_SAVA_TOWING_DESTINATION_FORM';
export const SUBMIT_SAVA_COMMISSIONING_FORM = '[service-cases] SUBMIT_SAVA_COMMISSIONING_FORM';
export const SUBMIT_SAVA_STATUS_CHANGE = '[service-cases] SUBMIT_SAVA_STATUS_CHANGE';
export const SEARCH_SAVA_CONTRACT_PARTNERS = '[service-cases] SEARCH_SAVA_CONTRACT_PARTNERS';
export const SUBMIT_SAVA_SERVICE_LOCATION_CASE_INFORMATION = '[service-case] SUBMIT_SAVA_SERVICE_LOCATION_CASE_INFORMATION';
export const CONFIRM_SAVA_QM_NOTES = '[service-cases] CONFIRM_SAVA_QM_NOTES';
export const DECLINE_SAVA_QM_NOTES = '[service-cases] DECLINE_SAVA_QM_NOTES';
export const CONFIRM_RESUBMITTING_STATUS_CHANGE = '[service-cases] CONFIRM_RESUBMITTING_STATUS_CHANGE';
export const DECLINE_RESUBMITTING_STATUS_CHANGE = '[service-cases] DECLINE_RESUBMITTING_STATUS_CHANGE';
export const INITIATE_SAVA_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION = '[service-cases] INITIATE_SAVA_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST_FAILED';

export const SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAVA_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAVA_REVERSE_SERVICE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST = '[service-cases] FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST';
export const FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_SENT = '[service-cases] FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_SENT';
export const FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_SUCCEEDED';
export const FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_FAILED = '[service-cases] FETCH_SAVA_SERVICE_LOCATION_ADDRESS_REQUEST_FAILED';

export const FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST';
export const FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_RESIDENCE_TO_SERVICE_LOCATION_DISTANCE_REQUEST_FAILED';

export const SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST = '[service-cases] SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST';
export const SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SENT = '[service-cases] SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST';
export const SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SUCCEEDED';
export const SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_FAILED = '[service-cases] SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_FAILED';

export const FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST = '[service-cases] FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST';
export const FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SENT = '[service-cases] FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SENT';
export const FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SUCCEEDED';
export const FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_FAILED = '[service-cases] FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_FAILED';

export const FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST = '[service-cases] FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST';
export const FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT = '[service-cases] FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT';
export const FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED';

export const FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST = '[service-cases] FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST';
export const FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SENT = '[service-cases] FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SENT';
export const FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED';
export const FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED = '[service-cases] FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED';

export const FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST';
export const FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST';
export const FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST = '[service-cases] FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST';
export const FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT = '[service-cases] FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT';
export const FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED';
export const FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED = '[service-cases] FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED';

export const FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST = '[service-cases] FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST';
export const FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_SENT = '[service-cases] FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_SENT';
export const FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED';
export const FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_FAILED = '[service-cases] FETCH_SAVA_CP_TEMPORARY_RESTRICTIONS_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAVA_SERVICE_LOCATION_CANDIDATES = '[service-cases] SET_SAVA_SERVICE_LOCATION_CANDIDATES';
export const SET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION = '[service-cases] SET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION';
export const RESET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION = '[service-cases] RESET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION';
export const SET_SAVA_TOWING_DESTINATION_RECOMMENDATIONS = '[service-cases] SET_SAVA_TOWING_DESTINATION_RECOMMENDATIONS';
export const SET_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS = '[service-cases] SET_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS';
export const SET_SAVA_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT = '[service-cases] SET_SAVA_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT';
export const SET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID = '[service-cases] SET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID';
export const RESET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID = '[service-cases] RESET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID';
export const SET_SAVA_CP_RECOMMENDATIONS_PERSISTENCE_STATE = '[service-cases] SET_SAVA_CP_RECOMMENDATIONS_PERSISTENCE_STATE';
