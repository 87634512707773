import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as samaActionTypes from '../samaActionTypes';
import samaScreenTabs from '../samaScreenTabs';

const loadSAMAMapDoctorLocationWatcher = function* loadSAMAMapDoctorLocationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== samaScreenTabs.SERVICE_PROVIDER) return;

    yield put({
        type: samaActionTypes.START_SAMA_MAP_DOCTOR_LOCATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAMAMapDoctorLocationWatcher;
