import React, {useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Checkbox, InputField, Button} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import {validateEmail, validateFax} from '../../utils/validation';

const PowerOfAttorneyPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('power_of_attorney_panel');
    const {serviceCase, serviceAssignment, onSubmit, pickupLocation, submitPowerOfAttorneyChannels} = props;
    const powerOfAttorneyButtonRef = useRef(null);
    const [powerOfAttorneyData, setPowerOfAttorneyData] = useState({
        sendPowerAttorneyViaEmail: serviceAssignment?.sendPowerAttorneyViaEmail,
        email: serviceAssignment?.powerOfAttorneyEmail !== null ? serviceAssignment.powerOfAttorneyEmail
            : serviceCase.member.contactDetails.email,
        sendPowerAttorneyViaFax: serviceAssignment?.sendPowerAttorneyViaFax,
        faxNo: serviceAssignment?.powerOfAttorneyFax !== null ? serviceAssignment.powerOfAttorneyFax
            : (serviceCase.member.contactDetails.faxNo || ''),
        sendPowerAttorneyViaPost: serviceAssignment?.sendPowerAttorneyViaPost,
        address: serviceAssignment?.powerOfAttorneyPost !== null ? serviceAssignment.powerOfAttorneyPost
            : serviceCase.member.personalDetails.address.displayAddress,
    });
    const [validationFields, setValidationFields] = useState({
        email: {
            fieldName: 'email',
            validationMethod: validateEmail,
            errorMessage: [],
        },
        faxNo: {
            fieldName: 'faxNo',
            validationMethod: validateFax,
            errorMessage: [],
        },
    });
    const disallowedSymbols = ['Tab', ' '];

    const handleValidation = (selectedChannel, channelValue) => {
        let hasError = false;
        const {fieldName} = validationFields[selectedChannel];

        if (!validationFields[selectedChannel].validationMethod(channelValue)) {
            setValidationFields(prevState => ({
                ...prevState,
                [selectedChannel]: {
                    ...validationFields[selectedChannel],
                    errorMessage: [translatePanel(`error_message.${fieldName}`)],
                },
            }));
            hasError = true;
        } else {
            setValidationFields(prevState => ({
                ...prevState,
                [selectedChannel]: {
                    ...validationFields[selectedChannel],
                    errorMessage: [],
                },
            }));
        }
        return hasError;
    };

    const handleOnSubmit = () => {
        const {email, faxNo, address, sendPowerAttorneyViaEmail, sendPowerAttorneyViaFax} = powerOfAttorneyData;
        let hasError = false;

        if (sendPowerAttorneyViaEmail && handleValidation('email', email)) hasError = true;
        if (sendPowerAttorneyViaFax && handleValidation('faxNo', faxNo)) hasError = true;

        if (hasError) return;

        onSubmit({
            serviceAssignmentData: {
                sendPowerAttorneyViaEmail: sendPowerAttorneyViaEmail,
                sendPowerAttorneyViaFax: sendPowerAttorneyViaFax,
                sendPowerAttorneyViaPost: powerOfAttorneyData.sendPowerAttorneyViaPost,
            },
            serviceAssignmentPowerOfAttorneyData: {
                email: email || null,
                faxNo: faxNo || null,
                postAddress: address,
            },
        });
        powerOfAttorneyButtonRef.current.blur();
    };

    const handleOnChange = (key, value) => {
        setPowerOfAttorneyData({
            ...powerOfAttorneyData,
            [key]: value,
        });
    };

    const handleOnTabChange = useCallback(formData => {
        submitPowerOfAttorneyChannels({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                sendPowerAttorneyViaEmail: formData.sendPowerAttorneyViaEmail,
                sendPowerAttorneyViaFax: formData.sendPowerAttorneyViaFax,
                sendPowerAttorneyViaPost: formData.sendPowerAttorneyViaPost,
                powerOfAttorneyEmail: formData.email,
                powerOfAttorneyFax: formData.faxNo,
                powerOfAttorneyPost: formData.address,
            },
        });
    }, [
        serviceCase.id,
        serviceAssignment.lineNo,
        serviceAssignment.assignmentType,
        submitPowerOfAttorneyChannels,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!powerOfAttorneyData) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_CHANNELS,
            });
            return;
        }

        handleOnTabChange(powerOfAttorneyData);
    }, [powerOfAttorneyData, handleOnTabChange]);

    // if no service case or service assignment, return
    if (!serviceCase || !serviceAssignment) return;

    const {member} = serviceCase;

    const isMemberSurnameValid = member.membershipType === ampMembershipTypes.CORPORATE
        ? !!member.personalDetails?.name : !!member.personalDetails?.surname;
    const isButtonDisabled = !(member.id && isMemberSurnameValid && pickupLocation) || !(
        (powerOfAttorneyData.sendPowerAttorneyViaEmail && powerOfAttorneyData.email)
        || (powerOfAttorneyData.sendPowerAttorneyViaFax && powerOfAttorneyData.faxNo)
        || (powerOfAttorneyData.sendPowerAttorneyViaPost && powerOfAttorneyData.address));

    return (
        <Panel title={translatePanel('title.power_of_attorney')}>
            <div className={cx('global!ace-u-grid')}>
                <Checkbox
                    className={cx('global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24')}
                    value={true}
                    isSelected={powerOfAttorneyData.sendPowerAttorneyViaEmail}
                    onChange={() => handleOnChange('sendPowerAttorneyViaEmail', !powerOfAttorneyData.sendPowerAttorneyViaEmail)}
                />
                <InputField
                    className={cx('global!ace-u-grid-column--span-11')}
                    label={translatePanel('input_label.by_email')}
                    value={powerOfAttorneyData.email}
                    onChange={value => handleOnChange('email', value)}
                    errors={validationFields['email'].errorMessage}
                    disallowedSymbols={disallowedSymbols}
                />
            </div>
            <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-32', 'global!ace-u-margin--top-32'])}>
                <Checkbox
                    className={cx('global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24')}
                    value={true}
                    isSelected={powerOfAttorneyData.sendPowerAttorneyViaFax}
                    onChange={() => handleOnChange('sendPowerAttorneyViaFax', !powerOfAttorneyData.sendPowerAttorneyViaFax)}
                />
                <InputField
                    className={cx('global!ace-u-grid-column--span-11')}
                    label={translatePanel('input_label.by_fax')}
                    value={powerOfAttorneyData.faxNo}
                    onChange={value => handleOnChange('faxNo', value)}
                    errors={validationFields['faxNo'].errorMessage}
                    disallowedSymbols={disallowedSymbols}
                />
            </div>
            <div className={cx('global!ace-u-grid')}>
                <Checkbox
                    className={cx('global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24')}
                    value={true}
                    isSelected={powerOfAttorneyData.sendPowerAttorneyViaPost}
                    onChange={() => handleOnChange('sendPowerAttorneyViaPost', !powerOfAttorneyData.sendPowerAttorneyViaPost)}
                />
                <InputField
                    className={cx('global!ace-u-grid-column--span-11')}
                    label={translatePanel('input_label.by_post')}
                    value={powerOfAttorneyData.address}
                    onChange={value => handleOnChange('address', value)}
                />
            </div>
            <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--top-48'])}>
                <Button
                    className={cx('global!ace-u-grid-column--span-12')}
                    onClick={handleOnSubmit}
                    isDisabled={isButtonDisabled}
                    ref={powerOfAttorneyButtonRef}
                >
                    {translatePanel('button_label.send_power_of_attorney')}
                </Button>
            </div>
        </Panel>
    );
};

PowerOfAttorneyPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    pickupLocation: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    submitPowerOfAttorneyChannels: PropTypes.func.isRequired,
};

PowerOfAttorneyPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    pickupLocation: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitPowerOfAttorneyChannels: payload => dispatch({
            type: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_CHANNELS,
            payload,
        }),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PowerOfAttorneyPanel));
