import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import savScreenTabs from '../service-assignments-vehicle/savScreenTabs';
import saaScreenTabs from '../service-assignments-accommodation/saaScreenTabs';
import samcScreenTabs from '../service-assignments-medical-clearance/samcScreenTabs';
import saosScreenTabs from '../service-assignments-other-services/saosScreenTabs';
import sarcScreenTabs from '../service-assignments-rental-car/sarcScreenTabs';
import sarjScreenTabs from '../service-assignments-return-journey/sarjScreenTabs';
import sastScreenTabs from '../service-assignments-short-trip/sastScreenTabs';
import saspScreenTabs from '../service-assignments-sick-person/saspScreenTabs';
import sasdScreenTabs from '../service-assignments-substitute-driver/sasdScreenTabs';
import savrScreenTabs from '../service-assignments-vehicle-return/savrScreenTabs';
import sapScreenTabs from '../service-assignments-pickup/sapScreenTabs';
import satrScreenTabs from '../service-assignments-trailer-return/satrScreenTabs';
import sadehScreenTabs from '../service-assignments-death-event-help/sadehScreenTabs';
import samsScreenTabs from '../service-assignments-medicine-shipment/samsScreenTabs';
import samaScreenTabs from '../service-assignments-medical-advice/samaScreenTabs';
import salaScreenTabs from '../service-assignments-legal-advice/salaScreenTabs';

const serviceAssignmentTypeToScreenTabMapping = {
    [efServiceAssignmentTypes.VEHICLE]: savScreenTabs.CONTRACT_PARTNER,
    [efServiceAssignmentTypes.ACCOMMODATION]: saaScreenTabs.ACCOMMODATION,
    [efServiceAssignmentTypes.MEDICAL_CLEARANCE]: samcScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.OTHER_SERVICES]: saosScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.RENTAL_CAR]: sarcScreenTabs.COST_COVERAGE,
    [efServiceAssignmentTypes.RETURN_JOURNEY]: sarjScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.SHORT_TRIP]: sastScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.SICK_PERSON]: saspScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.SUBSTITUTE_DRIVER]: sasdScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.VEHICLE_RETURN]: savrScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.PICKUP]: sapScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.TRAILER_RETURN]: satrScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.DEATH]: sadehScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.MEDICINE_SHIPMENT]: samsScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.MEDICAL_ADVICE]: samaScreenTabs.SERVICE_PROVIDER,
    [efServiceAssignmentTypes.LEGAL_ADVICE]: salaScreenTabs.SERVICE_PROVIDER,
};

export default serviceAssignmentTypeToScreenTabMapping;
