import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {TextAreaField, useStyles, Panel} from '@ace-de/ui-components';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import config from '../../config';

const ServiceAssignmentJourneyNotesPanel = props => {
    const {serviceCase, tabName, submitServiceAssignmentJourneyNotesForm, serviceAssignment} = props;
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand(tabName);
    const [journeyNotes, setJourneyNotes] = useState('');

    useEffect(() => {
        if (serviceAssignment?.journeyNotes) {
            setJourneyNotes(serviceAssignment.journeyNotes);
        }
    }, [serviceAssignment?.journeyNotes, setJourneyNotes]);

    const handleTabChange = useCallback(notes => {
        const serviceAssignmentData = {
            journeyNotes: notes,
        };
        submitServiceAssignmentJourneyNotesForm({
            serviceCaseId: serviceCase.id,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData,
        });
    }, [
        serviceCase.id,
        serviceAssignment.assignmentType,
        serviceAssignment.lineNo,
        submitServiceAssignmentJourneyNotesForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (serviceAssignment?.journeyNotes === journeyNotes) {
            completeRouteUnmountSideEffect({
                caller: serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM,
            });
            return;
        }

        handleTabChange(journeyNotes);
    }, [serviceAssignment?.journeyNotes, journeyNotes, handleTabChange]);


    return (
        <Panel title={translateTab('panel_title.more_informations')}>
            <TextAreaField
                name="journeyNotes"
                value={journeyNotes}
                placeholder={translateTab('text_area_placeholder.enter_description')}
                label={translateTab('text_area_label.additional_informations_on_the_assignment')}
                isResizable={false}
                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                onChange={value => setJourneyNotes(value)}
                className={cx('global!ace-u-full-width')}
            />
            <p
                className={cx([
                    'global!ace-u-margin--top-8',
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                ])}
            >
                {`${journeyNotes
                    ? config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH - journeyNotes.length
                    : config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH
                }/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                        ${translate('global.unit.characters_left')}`
                }
            </p>
        </Panel>
    );
};

ServiceAssignmentJourneyNotesPanel.propTypes = {
    serviceCase: PropTypes.object.isRequired,
    serviceAssignment: PropTypes.object.isRequired,
    tabName: PropTypes.string.isRequired,
    submitServiceAssignmentJourneyNotesForm: PropTypes.func,
};

ServiceAssignmentJourneyNotesPanel.defaultProps = {
    submitServiceAssignmentJourneyNotesForm: () => {},
};

const mapDispatchToProps = dispatch => ({
    submitServiceAssignmentJourneyNotesForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(ServiceAssignmentJourneyNotesPanel);
