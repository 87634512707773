import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceCaseTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

/**
 * Update SAV damage location info flow
 */
const updateSAVDamageLocationInfoFlow = function* updateSAVDamageLocationInfoFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM);
        const {serviceCaseData, serviceCaseId, serviceAssignmentLineNo} = payload;
        const {serviceCaseCallbackPhoneNoData} = payload;

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData,
        });

        const {callbackPhoneNo, unknownCallbackPhoneNo} = serviceCaseCallbackPhoneNoData;
        yield put({
            type: serviceCaseActionTypes.SET_IS_CALLBACK_PHONE_NUMBER_VALID,
            payload: {
                serviceCaseId,
                callbackPhoneNo,
                unknownCallbackPhoneNo,
                isCallbackPhoneNoValid: !unknownCallbackPhoneNo ? callbackPhoneNo !== '' : true,
            },
        });

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_CALLBACK_PHONE_NUMBER_FORM,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData: {
                callbackPhoneNo,
                unknownCallbackPhoneNo,
            },
        });

        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_CALLBACK_PHONE_NUMBER_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceCaseId,
            serviceAssignmentLineNo,
            serviceAssignmentData: {callbackPhoneNo},
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM},
        });
    }
};

export default updateSAVDamageLocationInfoFlow;
