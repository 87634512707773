import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, DataRow} from '@ace-de/ui-components';
import {efOtherServiceTypes} from '@ace-de/eua-entity-types';
import {snakeCase} from 'change-case';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageDetailsPanel = props => {
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('saos_cost_coverage_tab');
    const {serviceAssignment, serviceCase} = props;
    const serviceType = serviceAssignment.serviceType
        ? serviceAssignment.serviceType
        : serviceCase.serviceType && Object.values(efOtherServiceTypes).includes(serviceCase.serviceType)
            ? serviceCase.serviceType : '';

    return (
        <Panel
            title={translateTab('panel_title.details')}
            qaIdent="saos-cost-coverage-details"
        >
            <DataRow
                label={translateTab('data_row_label.service')}
                qaIdent="sa-service-type"
            >
                {serviceType ? translate(`global.other_services_service_type.${snakeCase(serviceType)}`) : '-'}
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.requirement')}
                qaIdent="sa-requirements-text"
            >
                {serviceAssignment.requirementsText || '-'}
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.damage_location')}
                qaIdent="sa-damage-location-address"
            >
                {serviceAssignment.damageLocation?.formattedAddress || serviceCase.damage?.location?.formattedAddress || '-'}
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.damage_location_notes')}
                qaIdent="sa-damage-location-notes"
            >
                {serviceAssignment.damageLocation?.locationNotes || '-'}
            </DataRow>
        </Panel>
    );
};

CostCoverageDetailsPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
};

CostCoverageDetailsPanel.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageDetailsPanel));
