import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {efAdditionalServiceRequestedTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Divider} from '@ace-de/ui-components';
import {Form, Checkbox, ButtonPrimary, NumberInput} from '@ace-de/ui-components/form';
import {InteractiveIcon, editIcon, closeIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as sarcActionTypes from '../sarcActionTypes';
import getPrice from '../../utils/getPrice';

const CostCoverageBudgetCalculationPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_cost_coverage_tab');
    const {serviceAssignment, submitSARCCostsAndBudgetForm, serviceCase, setIsSendButtonDisabled} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [isConfirmedByMember, setIsConfirmedByMember] = useState(!!serviceAssignment.costConfirmedByMember);
    const [formData, setFormData] = useState({
        costs: {
            rental: serviceAssignment.costs.rental || '',
            tires: serviceAssignment.costs.tires || '',
            service: serviceAssignment.costs.service || '',
            delivery: serviceAssignment.costs.delivery || '',
            other: serviceAssignment.costs.other || '',
        },
        budget: {
            rental: serviceAssignment.budget.rental || '',
            tires: serviceAssignment.budget.tires || '',
            service: serviceAssignment.budget.service || '',
            delivery: serviceAssignment?.deliveryRequested
                ? typeof serviceAssignment.budget.delivery === 'number'
                    ? serviceAssignment.budget.delivery
                    : (typeof serviceCase.remainingBudget === 'number'
                        ? serviceCase.remainingBudget
                        : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET)
                : null,
            other: serviceAssignment.budget.other || '',
        },
    });
    const areDatesSelected = serviceAssignment.dropOffDate && serviceAssignment.pickupDate;

    const roundToAtMost2Decimals = num => {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const calculateSum = (terms = []) => {
        let sum = 0;
        terms.forEach(term => {
            const float = parseFloat(term);
            if (typeof float === 'number' && !Number.isNaN(float)) {
                sum += float;
            }
        });
        return roundToAtMost2Decimals(sum);
    };

    const calculateTotalBudget = (budgetSubtotal, budgetSubtotal2, subtotalDifference) => {
        let totalBudget = parseFloat(budgetSubtotal) + parseFloat(budgetSubtotal2);
        if (subtotalDifference < 0) {
            totalBudget += parseFloat(subtotalDifference);
        }
        return roundToAtMost2Decimals(totalBudget);
    };

    const subtotals = {
        costSubtotal: calculateSum([
            serviceAssignment?.costs.rental,
            serviceAssignment?.costs.tires,
            serviceAssignment?.costs.service,
        ]),
        budgetSubtotal: calculateSum([
            serviceAssignment?.budget.rental,
            serviceAssignment?.budget.tires,
            serviceAssignment?.budget.service,
        ]),
        costSubtotal2: calculateSum([
            serviceAssignment?.deliveryRequested ? serviceAssignment?.costs.delivery : 0,
            serviceAssignment?.costs.other,
        ]),
        budgetSubtotal2: calculateSum([
            serviceAssignment?.deliveryRequested
                ? (typeof serviceAssignment?.budget.delivery === 'number'
                    ? serviceAssignment?.budget.delivery
                    : (typeof serviceCase.remainingBudget === 'number'
                        ? serviceCase.remainingBudget
                        : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET)
                ) : 0,
            serviceAssignment?.budget.other,
        ]),
    };

    const subtotalDiffs = {
        subtotalDifference: roundToAtMost2Decimals(subtotals.costSubtotal - subtotals.budgetSubtotal),
        subtotalDifference2: roundToAtMost2Decimals(subtotals.costSubtotal2 - subtotals.budgetSubtotal2),
    };

    const totals = {
        totalCost: calculateSum([subtotals.costSubtotal, subtotals.costSubtotal2]),
        totalBudget: calculateTotalBudget(
            subtotals.budgetSubtotal,
            subtotals.budgetSubtotal2,
            subtotalDiffs.subtotalDifference,
        ),
        totalBudgetDifference: calculateSum([
            subtotalDiffs.subtotalDifference > 0 ? subtotalDiffs.subtotalDifference : 0,
            subtotalDiffs.subtotalDifference2 > 0 ? subtotalDiffs.subtotalDifference2 : 0,
        ]),
    };

    useEffect(() => {
        setFormData({
            costs: {
                rental: serviceAssignment.costs.rental || '',
                tires: serviceAssignment.costs.tires || '',
                service: serviceAssignment.costs.service || '',
                delivery: serviceAssignment.costs.delivery || '',
                other: serviceAssignment.costs.other || '',
            },
            budget: {
                rental: serviceAssignment.budget.rental || '',
                tires: serviceAssignment.budget.tires || '',
                service: serviceAssignment.budget.service || '',
                delivery: serviceAssignment.deliveryRequested
                    ? typeof serviceAssignment.budget.delivery === 'number'
                        ? serviceAssignment.budget.delivery
                        : (typeof serviceCase.remainingBudget === 'number'
                            ? serviceCase.remainingBudget
                            : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET)
                    : null,
                other: serviceAssignment.budget.other || '',
            },
        });
    }, [
        serviceAssignment.costs,
        serviceAssignment.budget,
        serviceCase.remainingBudget,
        serviceAssignment.deliveryRequested,
    ]);

    useEffect(() => {
        setIsSendButtonDisabled(totals.totalBudgetDifference > 0 && !isConfirmedByMember);
    }, [setIsSendButtonDisabled, isConfirmedByMember, totals.totalBudgetDifference]);

    const toggleEditMode = () => {
        if (isEditModeActive) {
            setFormData({
                costs: {
                    rental: serviceAssignment.costs.rental || '',
                    tires: serviceAssignment.costs.tires || '',
                    service: serviceAssignment.costs.service || '',
                    delivery: serviceAssignment.costs.delivery || '',
                    other: serviceAssignment.costs.other || '',
                },
                budget: {
                    rental: serviceAssignment.budget.rental || '',
                    tires: serviceAssignment.budget.tires || '',
                    service: serviceAssignment.budget.service || '',
                    delivery: serviceAssignment?.deliveryRequested
                        ? typeof serviceAssignment.budget.delivery === 'number'
                            ? serviceAssignment.budget.delivery
                            : (typeof serviceCase.remainingBudget === 'number'
                                ? serviceCase.remainingBudget
                                : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET)
                        : null,
                    other: serviceAssignment.budget.other || '',
                },
            });
        }
        setIsEditModeActive(prevState => !prevState);
    };

    const handleOnChange = formValues => {
        const haveTiresCostsChanged = !!formValues.costs.tires && formValues.costs.tires !== formData.costs.tires;
        const haveServiceCostsChanged = !!formValues.costs.service
            && formValues.costs.service !== formData.costs.service;
        setFormData(prevState => ({
            costs: {
                ...prevState.costs,
                ...formValues.costs,
            },
            budget: {
                ...prevState.budget,
                ...formValues.budget,
                ...(haveTiresCostsChanged && {
                    tires: formValues.costs.tires,
                }),
                ...(haveServiceCostsChanged && {
                    service: formValues.costs.service,
                }),
            },
        }));
    };

    const handleOnSubmit = () => {
        const isBudgetFormTouched = !!Object.keys(formData.budget).length;

        submitSARCCostsAndBudgetForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                ...formData,
                ...(isBudgetFormTouched && formData.budget.rental !== serviceAssignment.budget.rental && {
                    budgetPerDay: formData.budget.rental / serviceAssignment.numberOfDays,
                    overrideDefaultBudget: true, // a default budget has been overwritten
                }),
                costs: formData.costs,
                budget: formData.budget,
            },
        });
        toggleEditMode();
    };

    if (!serviceAssignment) return null;

    const {deliveryRequested, additionalServiceRequested} = serviceAssignment;
    return (
        <Panel
            title={(
                <div>
                    {translateTab('panel_title.budget_calculation', {
                        priceType: Number(serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID
                            ? translate('global.pricing_type.net')
                            : translate('global.pricing_type.gross'),
                    })}
                    <p
                        className={cx('global!ace-u-typography--variant-body', {
                            'global!ace-u-typography--color-highlighted': !areDatesSelected,
                        })}
                    >
                        {
                            areDatesSelected
                                ? translateTab('panel_title.number_of_days', {numberOfDays: serviceAssignment.numberOfDays})
                                : translateTab('panel_title.no_dates_selected')
                        }
                    </p>
                </div>
                )}
            actions={!isEditModeActive ? (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={toggleEditMode}
                />
            ) : (
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={toggleEditMode}
                />
            )}
            qaIdent="sarc-cost-coverage-budget-calculation"
        >
            {!isEditModeActive ? (
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>
                        <div
                            className={cx([
                                'global!ace-u-padding--16',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateTab('text.cost')}
                        </div>
                        <Divider />
                        <DataRow
                            label={translateTab('data_row_label.rental_car')}
                            qaIdent="sa-costs-rental"
                        >
                            {getPrice(serviceAssignment.costs.rental, activeLocale)}
                        </DataRow>
                        {additionalServiceRequested && additionalServiceRequested.length
                        && additionalServiceRequested.includes(efAdditionalServiceRequestedTypes.WINTER_TIRES) ? (
                            <DataRow
                                label={translateTab('data_row_label.winter_tires')}
                                qaIdent="sa-costs-tires"
                            >
                                {getPrice(serviceAssignment.costs.tires, activeLocale)}
                            </DataRow>
                            ) : null}
                        {additionalServiceRequested && additionalServiceRequested.length
                        && additionalServiceRequested.includes(efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE)
                            ? (
                                <DataRow
                                    label={translateTab('data_row_label.emergency_service')}
                                    qaIdent="sa-costs-service"
                                >
                                    {getPrice(serviceAssignment.costs.service, activeLocale)}
                                </DataRow>
                            ) : null}
                        <DataRow
                            label={translateTab('data_row_label.subtotal')}
                            qaIdent="sa-cost-subtotal"
                        >
                            {getPrice(subtotals.costSubtotal, activeLocale)}
                        </DataRow>
                        <div className={cx('global!ace-u-margin--top-48')}>
                            {deliveryRequested ? (
                                <DataRow
                                    label={translateTab('data_row_label.delivery')}
                                    qaIdent="sa-costs-delivery"
                                >
                                    {getPrice(serviceAssignment.costs.delivery, activeLocale)}
                                </DataRow>
                            ) : null}
                            <DataRow
                                label={translateTab('data_row_label.other')}
                                qaIdent="sa-costs-other"
                            >
                                {getPrice(serviceAssignment.costs.other, activeLocale)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.subtotal')}
                                qaIdent="sa-cost-subtotal-2"
                            >
                                {getPrice(subtotals.costSubtotal2, activeLocale)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.total_costs', {
                                    priceType: Number(
                                        serviceCase.commissioner.id,
                                    ) !== config.ACE_COMMISSIONER_ID
                                        ? translate('global.pricing_type.net')
                                        : translate('global.pricing_type.gross'),
                                })}
                                qaIdent="sa-total-cost"
                            >
                                {getPrice(totals.totalCost, activeLocale)}
                            </DataRow>
                        </div>
                        <Checkbox
                            name="costConfirmedByMember"
                            value={true}
                            className={cx('global!ace-u-margin--top-24')}
                            isSelected={isConfirmedByMember}
                            onChange={value => setIsConfirmedByMember(!!value)}
                        >
                            {translateTab('checkbox_label.confirmed_by_member')}
                        </Checkbox>
                    </div>
                    <div>
                        <div
                            className={cx([
                                'global!ace-u-padding--16',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateTab('text.budget')}
                        </div>
                        <Divider />
                        <DataRow
                            label={translateTab('data_row_label.rental_car')}
                            qaIdent="sa-budget-rental"
                        >
                            {getPrice(serviceAssignment.budget.rental, activeLocale)}
                        </DataRow>
                        {additionalServiceRequested && additionalServiceRequested.length
                        && additionalServiceRequested.includes(efAdditionalServiceRequestedTypes.WINTER_TIRES) ? (
                            <DataRow
                                label={translateTab('data_row_label.winter_tires')}
                                qaIdent="sa-budget-tires"
                            >
                                {getPrice(serviceAssignment.budget.tires, activeLocale)}
                            </DataRow>
                            ) : null}
                        {additionalServiceRequested && additionalServiceRequested.length
                        && additionalServiceRequested.includes(efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE)
                            ? (
                                <DataRow
                                    label={translateTab('data_row_label.emergency_service')}
                                    qaIdent="sa-budget-service"
                                >
                                    {getPrice(serviceAssignment.budget.service, activeLocale)}
                                </DataRow>
                            ) : null}
                        <DataRow
                            label={translateTab('data_row_label.subtotal')}
                            qaIdent="sa-budget-subtotal"
                        >
                            <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                                <div>{getPrice(subtotals.budgetSubtotal, activeLocale)}&nbsp;</div>
                                {subtotalDiffs.subtotalDifference > 0 ? (
                                    <div className={cx('global!ace-u-typography--color-warning')}>
                                        {`(+${getPrice(Math.abs(subtotalDiffs.subtotalDifference), activeLocale, {showCurrency: false})})`}
                                    </div>
                                ) : (
                                    <div className={cx('global!ace-u-typography--color-success')}>
                                        {`(${subtotalDiffs.subtotalDifference === 0 ? '' : '-'}${getPrice(
                                            Math.abs(subtotalDiffs.subtotalDifference),
                                            activeLocale,
                                            {showCurrency: false},
                                        )})`}
                                    </div>
                                )}
                            </div>
                        </DataRow>
                        <div className={cx('global!ace-u-margin--top-48')}>
                            {deliveryRequested ? (
                                <DataRow
                                    label={translateTab('data_row_label.delivery')}
                                    qaIdent="sa-budget-delivery"
                                >
                                    {getPrice(deliveryRequested
                                        ? (typeof serviceAssignment.budget.delivery === 'number'
                                            ? serviceAssignment.budget.delivery
                                            : (typeof serviceCase.remainingBudget === 'number'
                                                ? serviceCase.remainingBudget
                                                : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET)
                                        ) : 0, activeLocale)}
                                </DataRow>
                            ) : null}
                            <DataRow
                                label={translateTab('data_row_label.other')}
                                qaIdent="sa-budget-other"
                            >
                                {getPrice(serviceAssignment.budget.other, activeLocale)}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.subtotal')}
                                qaIdent="sa-budget-subtotal-2"
                            >
                                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                                    <div>{getPrice(subtotals.budgetSubtotal2, activeLocale)}&nbsp;</div>
                                    {subtotalDiffs.subtotalDifference2 > 0 ? (
                                        <div className={cx('global!ace-u-typography--color-warning')}>
                                            {`(+${getPrice(Math.abs(subtotalDiffs.subtotalDifference2), activeLocale, {showCurrency: false})})`}
                                        </div>
                                    ) : (
                                        <div className={cx('global!ace-u-typography--color-success')}>
                                            {`(${subtotalDiffs.subtotalDifference2 === 0 ? '' : '-'}${getPrice(
                                                Math.abs(subtotalDiffs.subtotalDifference2),
                                                activeLocale,
                                                {showCurrency: false},
                                            )})`}
                                        </div>
                                    )}
                                </div>
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.total_budget', {
                                    priceType: Number(
                                        serviceCase.commissioner.id,
                                    ) !== config.ACE_COMMISSIONER_ID
                                        ? translate('global.pricing_type.net')
                                        : translate('global.pricing_type.gross'),
                                })}
                                qaIdent="sa-budget-total"
                            >
                                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                                    <div>{getPrice(totals.totalBudget, activeLocale)}&nbsp;</div>
                                    {totals.totalBudgetDifference > 0 ? (
                                        <div className={cx('global!ace-u-typography--color-warning')}>
                                            {`(+${getPrice(
                                                Math.abs(totals.totalBudgetDifference),
                                                activeLocale,
                                                {showCurrency: false},
                                            )} ${translateTab('text.additional_payment')})`}
                                        </div>
                                    ) : (
                                        <div className={cx('global!ace-u-typography--color-success')}>
                                            {`(${totals.totalBudgetDifference === 0 ? '' : '-'}${getPrice(
                                                Math.abs(totals.totalBudgetDifference),
                                                activeLocale,
                                                {showCurrency: false},
                                            )} ${translateTab('text.no_additional_payment')})`}
                                        </div>
                                    )}
                                </div>
                            </DataRow>
                        </div>
                    </div>
                </div>
            ) : (
                <Form name="costsAndBudgetForm" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    {formValues => {
                        // cost subtotal (first panel from above) = rental + tires + service
                        const costSubtotal = calculateSum([
                            formData.costs?.rental ? formData.costs.rental : serviceAssignment.costs.rental,
                            formData.costs?.tires ? formData.costs.tires : serviceAssignment.costs.tires,
                            formData.costs?.service ? formData.costs.service : serviceAssignment.costs.service,
                        ]);
                        // budget subtotal (first panel from above) = rental + tires + service
                        const budgetSubtotal = calculateSum([
                            formData.budget?.rental
                                ? formData.budget.rental
                                : serviceAssignment.budget.rental,
                            formData.budget?.tires
                                ? formData.budget.tires
                                : serviceAssignment.budget.tires,
                            formData.budget?.service
                                ? formData.budget.service
                                : serviceAssignment.budget.service,
                        ]);
                        // cost subtotal (second panel from above) = delivery + other
                        const costSubtotal2 = calculateSum([
                            deliveryRequested
                                ? (formData.costs?.delivery
                                    ? formData.costs.delivery
                                    : serviceAssignment.costs.delivery
                                ) : 0,
                            formData.costs?.other ? formData.costs.other : serviceAssignment.costs.other,
                        ]);
                        // budget subtotal (second panel from above) = delivery + other
                        const budgetSubtotal2 = calculateSum([
                            deliveryRequested
                                ? (formData.budget?.delivery
                                    ? formData.budget.delivery
                                    : (typeof serviceAssignment.budget.delivery === 'number'
                                        ? serviceAssignment.budget.delivery
                                        : (typeof serviceCase.remainingBudget === 'number'
                                            ? serviceCase.remainingBudget
                                            : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET))
                                ) : 0,
                            formData.budget?.other ? formData.budget.other : serviceAssignment.budget.other,
                        ]);

                        const subtotalDifference = roundToAtMost2Decimals(costSubtotal - budgetSubtotal);
                        const subtotalDifference2 = roundToAtMost2Decimals(costSubtotal2 - budgetSubtotal2);

                        // totalCost = cost subtotal (first panel from above) + cost subtotal (second panel from above)
                        const totalCost = calculateSum([costSubtotal, costSubtotal2]);

                        const totalBudget = calculateTotalBudget(budgetSubtotal, budgetSubtotal2, subtotalDifference);
                        const totalBudgetDifference = calculateSum([
                            subtotalDifference > 0 ? subtotalDifference : 0,
                            subtotalDifference2 > 0 ? subtotalDifference2 : 0,
                        ]);

                        return (
                            <Fragment>
                                <div className={cx('global!ace-u-two-column-layout')}>
                                    <div>
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--16',
                                                'global!ace-u-typography--variant-body-bold',
                                            ])}
                                        >
                                            {translateTab('text.cost')}
                                        </div>
                                        <Divider />
                                        <Form name="costs">
                                            <DataRow
                                                label={translateTab('data_row_label.rental_car')}
                                                className={cx('ace-c-data-row--condensed')}
                                            >
                                                <NumberInput
                                                    name="rental"
                                                    value={formData.costs.rental || ''}
                                                    className={cx('ace-c-input--small')}
                                                    min={0}
                                                />
                                            </DataRow>
                                            {additionalServiceRequested && additionalServiceRequested.length
                                            && additionalServiceRequested
                                                .includes(efAdditionalServiceRequestedTypes.WINTER_TIRES) ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.winter_tires')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="tires"
                                                            value={formData.costs.tires || ''}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                            {additionalServiceRequested && additionalServiceRequested.length
                                            && additionalServiceRequested
                                                .includes(efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE) ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.emergency_service')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="service"
                                                            value={formData.costs.service || ''}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                            <DataRow
                                                label={translateTab('data_row_label.subtotal')}
                                                qaIdent="sa-cost-subtotal"
                                            >
                                                {getPrice(costSubtotal, activeLocale)}
                                            </DataRow>
                                            <div className={cx('global!ace-u-margin--top-48')}>
                                                {deliveryRequested ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.delivery')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="delivery"
                                                            value={formData.costs.delivery || ''}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                                <DataRow
                                                    label={translateTab('data_row_label.other')}
                                                    className={cx('ace-c-data-row--condensed')}
                                                >
                                                    <NumberInput
                                                        name="other"
                                                        value={formData.costs.other || ''}
                                                        className={cx('ace-c-input--small')}
                                                        min={0}
                                                    />
                                                </DataRow>
                                                <DataRow
                                                    label={translateTab('data_row_label.subtotal')}
                                                    qaIdent="sa-cost-subtotal-2"
                                                >
                                                    {getPrice(costSubtotal2, activeLocale)}
                                                </DataRow>
                                                <DataRow
                                                    label={translateTab('data_row_label.total_costs', {
                                                        priceType: Number(
                                                            serviceCase.commissioner.id,
                                                        ) !== config.ACE_COMMISSIONER_ID
                                                            ? translate('global.pricing_type.net')
                                                            : translate('global.pricing_type.gross'),
                                                    })}
                                                    qaIdent="sa-cost-total"
                                                >
                                                    {getPrice(totalCost, activeLocale)}
                                                </DataRow>
                                            </div>
                                        </Form>
                                        {totalBudgetDifference > 0 ? (
                                            <Checkbox
                                                name="costConfirmedByMember"
                                                value={true}
                                                className={cx('global!ace-u-margin--top-24')}
                                                isSelected={isConfirmedByMember}
                                                onChange={value => setIsConfirmedByMember(!!value)}
                                            >
                                                {translateTab('checkbox_label.confirmed_by_member')}
                                            </Checkbox>
                                        ) : null}
                                    </div>
                                    <div>
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--16',
                                                'global!ace-u-typography--variant-body-bold',
                                            ])}
                                        >
                                            {translateTab('text.budget')}
                                        </div>
                                        <Divider />
                                        <Form name="budget">
                                            <DataRow
                                                label={translateTab('data_row_label.rental_car')}
                                                className={cx('ace-c-data-row--condensed')}
                                            >
                                                <NumberInput
                                                    name="rental"
                                                    value={formData.budget.rental}
                                                    className={cx('ace-c-input--small')}
                                                    min={0}
                                                />
                                            </DataRow>
                                            {additionalServiceRequested && additionalServiceRequested.length
                                            && additionalServiceRequested
                                                .includes(efAdditionalServiceRequestedTypes.WINTER_TIRES) ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.winter_tires')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="tires"
                                                            value={formData.budget.tires}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                            {additionalServiceRequested && additionalServiceRequested.length
                                            && additionalServiceRequested
                                                .includes(efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE) ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.emergency_service')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="service"
                                                            value={formData.budget.service}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                            <DataRow
                                                label={translateTab('data_row_label.subtotal')}
                                                qaIdent="sa-subtotal-diff"
                                            >
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                    ])}
                                                >
                                                    <div>{getPrice(budgetSubtotal, activeLocale)}&nbsp;</div>
                                                    {subtotalDifference > 0 ? (
                                                        <div className={cx('global!ace-u-typography--color-warning')}>
                                                            {`(+${getPrice(Math.abs(subtotalDifference), activeLocale, {showCurrency: false})})`}
                                                        </div>
                                                    ) : (
                                                        <div className={cx('global!ace-u-typography--color-success')}>
                                                            {`(${subtotalDifference === 0 ? '' : '-'}${getPrice(Math.abs(subtotalDifference), activeLocale, {showCurrency: false})})`}
                                                        </div>
                                                    )}
                                                </div>
                                            </DataRow>
                                            <div className={cx('global!ace-u-margin--top-48')}>
                                                {deliveryRequested ? (
                                                    <DataRow
                                                        label={translateTab('data_row_label.delivery')}
                                                        className={cx('ace-c-data-row--condensed')}
                                                    >
                                                        <NumberInput
                                                            name="delivery"
                                                            value={formData.budget.delivery}
                                                            className={cx('ace-c-input--small')}
                                                            min={0}
                                                        />
                                                    </DataRow>
                                                ) : null}
                                                <DataRow
                                                    label={translateTab('data_row_label.other')}
                                                    className={cx('ace-c-data-row--condensed')}
                                                    qaIdent="sa-budget-other"
                                                >
                                                    <NumberInput
                                                        name="other"
                                                        value={formData.budget.other || ''}
                                                        className={cx('ace-c-input--small')}
                                                        min={0}
                                                    />
                                                </DataRow>
                                                <DataRow
                                                    label={translateTab('data_row_label.subtotal')}
                                                >
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                        ])}
                                                    >
                                                        <div>{getPrice(budgetSubtotal2, activeLocale)}&nbsp;</div>
                                                        {subtotalDifference2 > 0 ? (
                                                            <div className={cx('global!ace-u-typography--color-warning')}>
                                                                {`(+${getPrice(Math.abs(subtotalDifference2), activeLocale, {showCurrency: false})})`}
                                                            </div>
                                                        ) : (
                                                            <div className={cx('global!ace-u-typography--color-success')}>
                                                                {`(${subtotalDifference2 === 0 ? '' : '-'}${getPrice(Math.abs(subtotalDifference2), activeLocale, {showCurrency: false})})`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </DataRow>
                                                <DataRow
                                                    label={translateTab('data_row_label.total_budget', {
                                                        priceType: Number(
                                                            serviceCase.commissioner.id,
                                                        ) !== config.ACE_COMMISSIONER_ID
                                                            ? translate('global.pricing_type.net')
                                                            : translate('global.pricing_type.gross'),
                                                    })}
                                                    qaIdent="sa-budget-total"
                                                >
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                        ])}
                                                    >
                                                        <div>{getPrice(totalBudget, activeLocale)}&nbsp;</div>
                                                        {totalBudgetDifference > 0 ? (
                                                            <div className={cx('global!ace-u-typography--color-warning')}>
                                                                {`(+${getPrice(Math.abs(totalBudgetDifference), activeLocale, {showCurrency: false})} ${translateTab('text.additional_payment')})`}
                                                            </div>
                                                        ) : (
                                                            <div className={cx('global!ace-u-typography--color-success')}>
                                                                {`(${totalBudgetDifference === 0 ? '' : '-'}${getPrice(Math.abs(totalBudgetDifference), activeLocale, {showCurrency: false})} ${translateTab('text.no_additional_payment')})`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </DataRow>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--top-24',
                                    ])}
                                >
                                    <ButtonPrimary
                                        name="submitCostsAndBudget"
                                        type="submit"
                                        isDisabled={totalBudgetDifference > 0 && !isConfirmedByMember}
                                    >
                                        {translateTab('button_label.save_changes')}
                                    </ButtonPrimary>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            )}
        </Panel>
    );
};

CostCoverageBudgetCalculationPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    submitSARCCostsAndBudgetForm: PropTypes.func.isRequired,
    setIsSendButtonDisabled: PropTypes.func.isRequired,
};

CostCoverageBudgetCalculationPanel.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARCCostsAndBudgetForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_COSTS_AND_BUDGET_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CostCoverageBudgetCalculationPanel));
