import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useRouter, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, ampMemberStatusTypes, ampMemberTariffGroups, ampMemberTariffAdditionalPackageTypes, efServiceAssignmentStatusTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle, Badge} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, newCaseIcon, closeIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import MemberNotePopup from '../../service-cases/ui-elements/MemberNotePopup';
import * as serviceCaseIcons from '../../service-cases/serviceCaseIcons';

const ServiceAssignmentHeader = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const {serviceCase, serviceAssignment, initiateCloseArchiveAssignmentFlow} = props;
    const {member, nonAceMember, commissioner} = serviceCase;
    const {personalDetails, tariffDetails} = member;
    const router = useRouter();
    const translateHeader = createTranslateShorthand('service_assignment_header');
    const additionalPackages = tariffDetails?.additionalPackages
        ? tariffDetails.additionalPackages.reduce((prev, curr) => prev + (prev === '' ? '' : ' & ')
            + translate(`global.member_tariff_additional_package.${snakeCase(ampMemberTariffAdditionalPackageTypes[curr])}`), '')
        : '';

    const handleOnClose = () => {
        if (serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW) {
            router.goBack();
            return;
        }
        localStorage.removeItem('isInitialAssignmentCreationFlow');
        initiateCloseArchiveAssignmentFlow({
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
        });
    };

    return (
        <Fragment>
            <ScreenTitle>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={(serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                            && serviceAssignment.serviceType)
                            ? serviceCaseIcons.serviceIcons[serviceAssignment.serviceType]
                            : (serviceAssignment.assignmentType !== efServiceAssignmentTypes.VEHICLE)
                                ? serviceCaseIcons.serviceAssignmentIcons[serviceAssignment.assignmentType]
                                : newCaseIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <span
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                            && serviceAssignment.serviceType
                            ? translate(`global.service_type.${serviceAssignment.serviceType.toLowerCase()}`)
                            : serviceAssignment.assignmentType !== efServiceAssignmentTypes.VEHICLE
                                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                                : translateHeader('screen_title.new_service_assignment')}
                    </span>
                    <span
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {serviceCase.id}
                    </span>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    {member && (
                        <Fragment>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.commissioner')}&nbsp;
                                <p className={cx('global!ace-u-typography--variant-body')}>
                                    {commissioner?.name || ''}
                                </p>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--left-48',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.member')}&nbsp;
                                <p className={cx('global!ace-u-typography--variant-body')}>
                                    {nonAceMember
                                        ? personalDetails.name || personalDetails.surname
                                        : personalDetails.displayName}
                                </p>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--left-48',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.tariff')}&nbsp;
                                <p className={cx('global!ace-u-typography--variant-body')}>
                                    {tariffDetails.tariffGroup
                                        ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[tariffDetails.tariffGroup])}`)
                                        : ''}
                                    {additionalPackages ? ` | ${additionalPackages}` : ''}
                                </p>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--left-48',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.status')}&nbsp;
                                {member.status !== ampMemberStatusTypes.EMPTY && (
                                    <Badge status={member.status.toLowerCase()}>
                                        {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                    </Badge>
                                )}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--left-48',
                                    'global!ace-u-flex--align-self-stretch',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                {translateHeader('label.dunning_level')}
                                <Badge
                                    status={!member.dunningLevel
                                        ? 'positive'
                                        : (member.dunningLevel < config.WARNING_DUNNING_LEVEL ? 'gold' : 'notification')}
                                    className={cx('global!ace-u-margin--left-8')}
                                >
                                    {member.dunningLevel
                                        ? member.dunningLevel
                                        : '0'}
                                </Badge>
                            </div>
                            {!serviceCase.nonAceMember && (
                                <div
                                    className={cx([
                                        'global!ace-u-typography--variant-body-medium',
                                        'global!ace-u-margin--left-48',
                                        'global!ace-u-flex--align-self-stretch',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    {translateHeader('label.member_notes')}
                                    <MemberNotePopup member={member} />
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={handleOnClose}
                        isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                            || serviceAssignment.persistenceState === persistenceStates.PENDING}
                    />
                </div>
            </div>
        </Fragment>
    );
};

ServiceAssignmentHeader.propTypes = {
    serviceCase: PropTypes.object.isRequired,
    serviceAssignment: PropTypes.object.isRequired,
    initiateCloseArchiveAssignmentFlow: PropTypes.func.isRequired,
};

ServiceAssignmentHeader.defaultProps = {
};

const mapDispatchToProps = dispatch => ({
    initiateCloseArchiveAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(ServiceAssignmentHeader));
