import {take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

/**
 * Initiation of foreign assistance case creation through modal
 */
const initiateForeignAssistanceCaseCreationModalFlow = function* initiateForeignAssistanceCaseCreationModalFlow() {
    while (true) {
        yield take([serviceCaseActionTypes.INITIATE_CREATE_FOREIGN_ASSISTANCE_CASE_FLOW]);

        yield* openModal(modalIds.CREATE_FOREIGN_ASSISTANCE_CASE);
        const chosenModalOption = yield take([
            serviceCaseActionTypes.DECLINE_CREATE_FOREIGN_ASSISTANCE_CASE,
            serviceCaseActionTypes.CONFIRM_CREATE_FOREIGN_ASSISTANCE_CASE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceCaseActionTypes.CONFIRM_CREATE_FOREIGN_ASSISTANCE_CASE) {
            const {payload: modalOptionPayload} = chosenModalOption;
            yield put({
                type: serviceCaseActionTypes.CREATE_SERVICE_CASE,
                payload: {
                    ...modalOptionPayload,
                },
            });
        }

        yield* closeModal(modalIds.CREATE_FOREIGN_ASSISTANCE_CASE);
    }
};

export default initiateForeignAssistanceCaseCreationModalFlow;
