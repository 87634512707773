import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ContentBlock, ContentItem, HighlightCircle} from '@ace-de/ui-components';
import {ButtonPrimary, ButtonSecondary} from '@ace-de/ui-components/buttons';
import {InteractiveIcon, Icon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';

const SCDocumentDeleteConfirmationModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('sc_document_delete_confirmation_modal');
    const {serviceCase, confirmSCDocumentDelete, declineSCDocumentDelete, hasBackdrop} = props;

    if (!serviceCase) return null;
    const isFormDisabled = serviceCase.persistenceState === persistenceStates.PENDING;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSCDocumentDelete}
                    isDisabled={isFormDisabled}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('global!ace-u-flex--justify-center')}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-11',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--primary',
                        ])}
                    >
                        <Icon
                            icon={warningOutlinedIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-highlight-pressed',
                            ])}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateModal('title.delete_file')}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-32',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('heading.delete_confirmation')}
                    </p>
                </ContentItem>
                <ContentItem
                    className={cx([
                        'global!ace-u-flex--direction-column',
                        'global!ace-u--margin-0',
                    ])}
                >
                    <ButtonPrimary
                        className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                        onClick={confirmSCDocumentDelete}
                        isDisabled={isFormDisabled}
                    >
                        {translateModal('button_label.delete_file')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        className={cx('global!ace-u-full-width')}
                        onClick={declineSCDocumentDelete}
                        isDisabled={isFormDisabled}
                    >
                        {translateModal('button_label.abort')}
                    </ButtonSecondary>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

SCDocumentDeleteConfirmationModal.propTypes = {
    confirmSCDocumentDelete: PropTypes.func.isRequired,
    declineSCDocumentDelete: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    hasBackdrop: PropTypes.bool,
};

SCDocumentDeleteConfirmationModal.defaultProps = {
    serviceCase: null,
    hasBackdrop: true,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmSCDocumentDelete: () => dispatch({type: serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_DELETE}),
    declineSCDocumentDelete: () => dispatch({type: serviceCaseActionTypes.DECLINE_SC_DOCUMENT_DELETE}),
});

export default connect(mapStateToProps, mapDispatchToProps)(SCDocumentDeleteConfirmationModal);
