import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savaActionTypes from '../savaActionTypes';
import savaScreenTabs from '../savaScreenTabs';

const loadSAVAMapContractPartnerWatcher = function* loadSAVAMapContractPartnerWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savaScreenTabs.CONTRACT_PARTNER) return;

    yield put({
        type: savaActionTypes.START_SAVA_MAP_CONTRACT_PARTNER_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAVAMapContractPartnerWatcher;
