import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel} from '@ace-de/ui-components/app-layout';
import {useStyles, TextAreaField, SelectField, Option} from '@ace-de/ui-components';
import {efDamageNoteTopics} from '@ace-de/eua-entity-types';
import * as savActionTypes from '../savActionTypes';
import config from '../../config';

const DamageNotePanel = ({serviceCase, submitSAVDamageDescriptionForm, damageNote, setDamageNote}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_damage_service_tab');
    const damageNoteRef = useRef(damageNote);
    const textTemplateTopicRef = useRef(false);
    const [textTemplateTopic, setTextTemplateTopic] = useState();
    const [limitExceededError, setLimitExceededError] = useState([]);

    const formatDamageNoteText = useCallback((damageNote, selectedTopic) => {
        if (!selectedTopic) return;
        const translatedTopic = translateTab(`to_note_panel_text_template.${selectedTopic.toLowerCase()}`);

        if (translatedTopic.length + damageNote.length > config.DAMAGE_NOTE_MAX_LENGTH) {
            setLimitExceededError([translateTab(`to_note_panel_error_message.input_too_long`)]);
            return damageNote;
        }

        setLimitExceededError([]);

        if (!damageNote) return translatedTopic;

        return damageNote + '\n\n' + translatedTopic;
    }, [translateTab]);

    useEffect(() => {
        if (serviceCase?.damage?.additionalDescription !== damageNoteRef.current) {
            damageNoteRef.current = serviceCase?.damage?.additionalDescription;
            setDamageNote(serviceCase?.damage?.additionalDescription);
        }
    }, [serviceCase?.damage?.additionalDescription, damageNoteRef, setDamageNote]);

    useEffect(() => {
        if (textTemplateTopic) {
            textTemplateTopicRef.current = true;
        }
    }, [textTemplateTopic]);

    useEffect(() => {
        if (textTemplateTopicRef.current) {
            setDamageNote(formatDamageNoteText(damageNote, textTemplateTopic));
            textTemplateTopicRef.current = false;
        }
    }, [damageNote, setDamageNote, formatDamageNoteText, textTemplateTopic]);

    const handleTabChange = useCallback(additionalDescription => {
        const serviceCaseData = {
            damage: {
                additionalDescription,
            },
        };
        submitSAVDamageDescriptionForm({
            serviceCaseId: serviceCase.id,
            serviceCaseData,
        });
    }, [submitSAVDamageDescriptionForm, serviceCase.id]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (serviceCase?.damage?.additionalDescription === damageNote) {
            completeRouteUnmountSideEffect({
                caller: savActionTypes.SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM,
            });
            return;
        }

        handleTabChange(damageNote);
    }, [serviceCase?.damage?.additionalDescription, damageNote, handleTabChange]);

    const handleOnChange = value => {
        setDamageNote(value);

        if (damageNote.length > config.DAMAGE_NOTE_MAX_LENGTH) {
            setLimitExceededError([translateTab(`to_note_panel_error_message.input_too_long`)]);
            return;
        }

        setLimitExceededError([]);
    };

    return (
        <Panel title={translateTab('panel_title.to_note')}>
            <SelectField
                name="topic"
                label={translateTab('to_note_panel_label.topic')}
                placeholder={translate('global.select.placeholder')}
                className={cx(['global!ace-u-margin--bottom-32', 'global!ace-u-full-width'])}
                onChange={setTextTemplateTopic}
            >
                {Object.values(efDamageNoteTopics).map((topic, id) => (
                    <Option
                        key={`${topic}-${id}`}
                        name={`${topic}-${id}`}
                        value={topic}
                    >
                        {translateTab(`to_note_panel_option.${topic.toLowerCase()}`)}
                    </Option>
                ))}
            </SelectField>
            <TextAreaField
                name="additionalDescription"
                value={damageNote}
                placeholder={translateTab('input_placeholder.enter_description')}
                label={translateTab('input_label.free_commissioning_text')}
                isResizable={false}
                onChange={handleOnChange}
                maxLength={config.DAMAGE_NOTE_MAX_LENGTH}
                className={cx('global!ace-u-full-width')}
                contentClassName={cx('ace-c-text-area--medium')}
                errors={limitExceededError}
            />
            <p
                className={cx([
                    'global!ace-u-margin--top-8',
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                ])}
            >
                {`${damageNote
                    ? damageNote.length
                    : 0
                }/${config.DAMAGE_NOTE_MAX_LENGTH}
                 ${translate('global.unit.characters')}`
                }
            </p>

        </Panel>
    );
};

DamageNotePanel.propTypes = {
    serviceCase: PropTypes.object,
    damageNote: PropTypes.string,
    submitSAVDamageDescriptionForm: PropTypes.func.isRequired,
    setDamageNote: PropTypes.func.isRequired,
};

DamageNotePanel.defaultProps = {
    serviceCase: null,
    damageNote: '',
};

const mapDispatchToProps = dispatch => ({
    submitSAVDamageDescriptionForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(DamageNotePanel);
