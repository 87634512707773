import {put} from 'redux-saga/effects';
import * as taskActionTypes from './taskActionTypes';
import config from '../config';

export const loadTaskSearchResults = function* loadTaskSearchResults({payload}) {
    const {location} = payload;

    // do not search when an agent opens a TaskDataModal: no need to reload in the background
    // TBD: improve performances on close: if the modal is submitted, then reload, otherwise no
    if (location.query.modal && location.state?.isModalOpen) return;

    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('sort', 'dueDate,asc');
    searchQueryParams.append('size', `${config.TASKS_DEFAULT_PAGE_SIZE}`);

    yield put({
        type: taskActionTypes.SEARCH_TASKS,
        payload: {searchQueryParams},
    });
};
