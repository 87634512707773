import {all, fork, takeLatest} from 'redux-saga/effects';
import createServiceAssignmentPickupFlow from './sagas/createServiceAssignmentPickupFlow';
import searchSAPPickupLocationGeolocation from './sagas/searchSAPPickupLocationGeolocation';
import searchSAPDestinationGeolocation from './sagas/searchSAPDestinationGeolocation';
import updateSAPRequirementsFlow from './sagas/updateSAPRequirementsFlow';
import selectSAPContractPartnerFlow from './sagas/selectSAPContractPartnerFlow';
import searchSAPContractPartners from './sagas/searchSAPContractPartners';
import calculatePickupRouteDistance from './sagas/calculatePickupRouteDistance';
import updateSAPCostCoverageCommissioningFlow from './sagas/updateSAPCostCoverageCommissioningFlow';
import updateSAPCostCoverageFlow from './sagas/updateSAPCostCoverageFlow';
import sapMapContractPartnerWatcher from './sagas/sapMapContractPartnerWatcher';
import * as sapActionTypes from './sapActionTypes';
import updateSAPRequiredLocationFlow from './sagas/updateSAPRequiredLocationFlow';
import sapCreateVBAPartnerQMFeedbackDraft from './sagas/sapCreateVBAPartnerQMFeedbackDraft';

/**
 *  SAP watcher saga
 */
const sapWatcher = function* sapWatcher() {
    yield all([
        fork(createServiceAssignmentPickupFlow),
        fork(updateSAPRequirementsFlow),
        takeLatest(sapActionTypes.SEARCH_SAP_PICKUP_LOCATION_GEOLOCATION, searchSAPPickupLocationGeolocation),
        takeLatest(sapActionTypes.SEARCH_SAP_DESTINATION_GEOLOCATION, searchSAPDestinationGeolocation),
        fork(selectSAPContractPartnerFlow),
        takeLatest(sapActionTypes.SEARCH_SAP_CONTRACT_PARTNERS, searchSAPContractPartners),
        fork(calculatePickupRouteDistance),
        fork(updateSAPCostCoverageCommissioningFlow),
        fork(updateSAPCostCoverageFlow),
        fork(sapMapContractPartnerWatcher),
        fork(updateSAPRequiredLocationFlow),
        fork(sapCreateVBAPartnerQMFeedbackDraft),
    ]);
};

export default sapWatcher;
