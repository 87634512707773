import {all, takeLatest} from 'redux-saga/effects';
import * as dashboardActionTypes from './dashboardActionTypes';
import dashboardAutoRefreshTimer from './sagas/dashboardAutoRefreshTimer';

/**
 *  Dashboard watcher saga
 */
const dashboardWatcher = function* dashboardWatcher() {
    yield all([
        takeLatest(dashboardActionTypes.START_AUTO_REFRESH_TIMER, dashboardAutoRefreshTimer),
    ]);
};

export default dashboardWatcher;
