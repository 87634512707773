import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import savScreenTabs from '../savScreenTabs';
import * as savActionTypes from '../savActionTypes';
import onSAVMapClickFetchDamageLocationAddress from './onSAVMapClickFetchDamageLocationAddress';

const savMapDamageLocationWatcher = function* savMapDamageLocationWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(savActionTypes.START_SAV_MAP_DAMAGE_LOCATION_WATCHER);
        const {serviceCaseId} = payload;
        const onSAVMapClickFetchDamageLocationAddressTask = yield fork(onSAVMapClickFetchDamageLocationAddress, {
            serviceCaseId,
        });

        while (true) {
            const watcherTerminationAction = yield take([
                savActionTypes.STOP_SAV_MAP_DAMAGE_LOCATION_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === savActionTypes.STOP_SAV_MAP_DAMAGE_LOCATION_WATCHER) {
                yield cancel(onSAVMapClickFetchDamageLocationAddressTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_VEHICLE,
                exact: true,
            });
            const {activeTab} = savRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savRouteMatch || activeTab !== savScreenTabs.DAMAGE_LOCATION) {
                yield cancel(onSAVMapClickFetchDamageLocationAddressTask);
                continue mainFlow;
            }
        }
    }
};

export default savMapDamageLocationWatcher;
