import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as dashboardSelectors from './dashboardSelectors';
import CurrentTeamPanel from './ui-elements/CurrentTeamPanel';
import CreateNewCasePanel from './ui-elements/CreateNewCasePanel';
import ecsAccessControl from '../ecsAccessControl';
import dashboardLayouts from './dashboardLayouts';
import {ecsLayouts} from '../application/ecsLayoutTypes';

const DashboardScreen = props => {
    const {cx} = useStyles();
    const {loggedInUsers} = props;
    const activeRoleLayout = ecsAccessControl.getRoleScreenLayout(ecsLayouts.DASHBOARD);

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                {activeRoleLayout
                    ? dashboardLayouts[activeRoleLayout]
                    : null}
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CurrentTeamPanel activeTeamMembers={loggedInUsers} />
                <CreateNewCasePanel />
            </ContentItem>
        </ContentBlock>
    );
};

DashboardScreen.propTypes = {
    loggedInUsers: PropTypes.object,
};

DashboardScreen.defaultProps = {
    loggedInUsers: {},
};

const mapStateToProps = state => {
    return {
        loggedInUsers: dashboardSelectors.getLoggedInUsers(state),
    };
};

export default connect(mapStateToProps, null)(DashboardScreen);
