import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceCaseTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savActionTypes from '../savActionTypes';

/**
 * Update SAV symptoms flow
 */
const updateSAVDamageSymptomsFlow = function* updateSAVDamageSymptomsFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM);
        const {serviceCaseData, serviceCaseId, serviceAssignmentLineNo} = payload;

        // ACEECS-6070 fix: BE removed serviceType from SC patch object. Service type on SC will be patched
        // automatically as soon as serviceType on assignment is patched.
        // This is the reason why we need to update assignment first and then service case.
        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                serviceType: serviceCaseData.serviceType,
                assignmentText: null,
            },
        });

        yield* updateServiceCase({
            caller: savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM,
            caseType: efServiceCaseTypes.VEHICLE,
            serviceCaseId,
            serviceCaseData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM},
        });
    }
};

export default updateSAVDamageSymptomsFlow;
