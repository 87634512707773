import React from 'react';
import PropTypes from 'prop-types';
import {useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Modal, HighlightCircle, useStyles, ButtonSecondary} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, warningIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';

const CreateCaseNoMemberTariffWarningModal = ({hasBackdrop}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const {closeModal} = useRouter();
    const translateModal = createTranslateShorthand('create_case_no_member_tariff_warning_modal');

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => closeModal(modalIds.CREATE_CASE_NO_TARIFF_WARNING)}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--warning',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                </HighlightCircle>
                <h3 className={cx('global!ace-u-margin--bottom-16')}>
                    {translateModal('headline_text.member_without_tariff')}
                </h3>
                <p className={cx('global!ace-u-margin--bottom-32')}>
                    {translateModal('body_text.fill_out_navision')}
                </p>
                <ButtonSecondary
                    onClick={() => closeModal(modalIds.CREATE_CASE_NO_TARIFF_WARNING)}
                >
                    {translateModal('button.close')}
                </ButtonSecondary>
            </div>
        </Modal>
    );
};

CreateCaseNoMemberTariffWarningModal.propTypes = {
    hasBackdrop: PropTypes.bool,
};

CreateCaseNoMemberTariffWarningModal.defaultProps = {
    hasBackdrop: true,
};

export default CreateCaseNoMemberTariffWarningModal;

