import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as sarcActionTypes from '../sarcActionTypes';
import sarcScreenTabs from '../sarcScreenTabs';

const loadSARCMapRentalCarStationWatcher = function* loadSARCMapRentalCarStationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== sarcScreenTabs.SELECT_STATION) return;

    yield put({
        type: sarcActionTypes.START_SARC_MAP_RENTAL_CAR_STATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSARCMapRentalCarStationWatcher;
