/**
 * RequestState type definition
 *
 * @typedef {Object} RequestState
 * @property {?Boolean} hasRetryOption
 * @property {?String} retryTranslationKey
 * @property {?Error} error
 * @property {?Boolean} isInProgress
 */
const defaultRequestState = {
    hasRetryOption: false,
    translationKey: null,
    error: null,
    isInProgress: false,
};

export default defaultRequestState;
