import React, {useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Parser} from 'html-to-react';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes, efServiceCaseStatusTypes, efServiceAssignmentStatusTypes, efRentalCarSubStatusTypes, etmTaskCategoryTypes, eupShiftRoleTypes, ehmCaseLogActionTypes, ehmCaseLogCategoryTypes, efServiceTypes, efDriverAppServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, HighlightCircle, Divider, Icon, svgIcons, InteractiveIcon, editIcon, attachmentIcon} from '@ace-de/ui-components';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';
import styles from './LogEntry.module.scss';

const serviceIcons = {
    [efServiceAssignmentTypes.VEHICLE]: {icon: svgIcons.vehicleIcon, isHighlighted: true},
    [efServiceTypes.VEHICLE_OPENING]: {icon: svgIcons.vehicleOpeningIcon, isHighlighted: true},
    [efServiceTypes.RECOVERY]: {icon: svgIcons.recoveryIcon, isHighlighted: true},
    [efServiceTypes.ROADSIDE_ASSISTANCE]: {icon: svgIcons.breakdownIcon, isHighlighted: true},
    [efServiceTypes.TOWING]: {icon: svgIcons.towingIcon, isHighlighted: true},
    [efServiceTypes.NO_SERVICE]: {icon: svgIcons.noServiceIcon, isHighlighted: true},
    [efServiceAssignmentTypes.PICKUP]: {icon: svgIcons.shipmentIcon, isHighlighted: true},
    JUMP_START: {icon: svgIcons.noServiceIcon, isHighlighted: true},
    [efServiceAssignmentTypes.RENTAL_CAR]: {icon: svgIcons.carRentalIcon, isHighlighted: true},
    [efServiceAssignmentTypes.ACCOMMODATION]: {icon: svgIcons.overnightStayIcon, isHighlighted: true},
    [efServiceAssignmentTypes.SHORT_TRIP]: {icon: svgIcons.taxiIcon, isHighlighted: true},
    [efServiceAssignmentTypes.RETURN_JOURNEY]: {icon: svgIcons.flightIcon, isHighlighted: true},
    [efServiceAssignmentTypes.SUBSTITUTE_DRIVER]: {icon: svgIcons.personOffIcon, isHighlighted: true},
    [efServiceAssignmentTypes.MEDICAL_CLEARANCE]: {icon: svgIcons.hospitalIcon, isHighlighted: true},
    [efServiceAssignmentTypes.SICK_PERSON]: {icon: svgIcons.hospitalIcon, isHighlighted: true},
    [efServiceAssignmentTypes.VEHICLE_RETURN]: {icon: svgIcons.pickupIcon, isHighlighted: true},
    [efServiceAssignmentTypes.OTHER_SERVICES]: {icon: svgIcons.otherIcon, isHighlighted: true},
    [efServiceAssignmentTypes.TRAILER_RETURN]: {icon: svgIcons.trailerIcon, isHighlighted: true},
    [efServiceAssignmentTypes.DEATH]: {icon: svgIcons.deathCaseIcon, isHighlighted: true},
    [efServiceAssignmentTypes.MEDICINE_SHIPMENT]: {icon: svgIcons.medicationIcon, isHighlighted: true},
    [efServiceAssignmentTypes.MEDICAL_ADVICE]: {icon: svgIcons.healingIcon, isHighlighted: true},
    [efServiceAssignmentTypes.LEGAL_ADVICE]: {icon: svgIcons.legalIcon, isHighlighted: true},
};

const caseLogIcons = {
    [ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE]: {icon: svgIcons.caseIcon, isHighlighted: false},
    [ehmCaseLogCategoryTypes.NOTE]: {icon: svgIcons.caseIcon, isHighlighted: false},
    [ehmCaseLogCategoryTypes.MESSAGE]: {icon: svgIcons.caseIcon, isHighlighted: false},
    [ehmCaseLogCategoryTypes.TASK]: {icon: svgIcons.timeAlertIcon, isHighlighted: false},
    [ehmCaseLogCategoryTypes.DRIVER_APP]: {icon: svgIcons.appIcon, isHighlighted: true},
    [ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK]: {icon: svgIcons.ratingNegativeIcon, isHighlighted: true},
};

const caseDataChangeIcons = {
    [ehmCaseLogActionTypes.DOCUMENT_UPLOADED]: {icon: svgIcons.uploadIcon, isHighlighted: true},
    [ehmCaseLogActionTypes.DOCUMENT_DELETED]: {icon: svgIcons.deleteIcon, isHighlighted: true},
};

const logCategories = [
    ehmCaseLogCategoryTypes.NOTE,
    ehmCaseLogCategoryTypes.MESSAGE,
    ehmCaseLogCategoryTypes.ASSIGNMENT,
    ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
    ehmCaseLogCategoryTypes.CASE_DATA_CHANGE,
];

const HTMLParser = new Parser();

const getUserDetails = (user, caseLog) => {
    if (!user && !caseLog) return '';
    const userName = user?.displayName
        ? user.displayName
        : caseLog?.subjectDetails?.name || '';

    const userShiftRole = user?.shiftRole
        ? user.shiftRole
        : '';

    return `${userName} ${userShiftRole}`;
};

const LogEntry = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sc_protocol_tab');
    const {users, caseLog} = props;
    const {initiateUpdateCaseLogFlow, submitNoteDocumentDownloadFlow, submitSCDocumentDownloadFlow} = props;
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );
    const user = users[caseLog?.subjectDetails?.id];
    const userDetails = caseLog?.logCategory === ehmCaseLogCategoryTypes.DRIVER_APP
        ? translate('global.case_log_user_label.driver_app')
        : (!caseLog?.subjectDetails?.id
            ? translate('global.case_log_user_label.system_user')
            : getUserDetails(user, caseLog));

    const formatTaskEditedLogText = useCallback(changeLogs => {
        let formattedLogText = '';
        changeLogs.forEach(changeLog => {
            switch (changeLog?.field) {
                case '/category': {
                    formattedLogText = formattedLogText.concat(
                        `${translateTab('protocol_panel_text.new_category')}`,
                        ' → ',
                        `${translate(`global.task_category.${etmTaskCategoryTypes[changeLog?.newValue]?.toLowerCase()}`)}; `,
                    );
                    break;
                }

                case '/assignedTo': {
                    if (eupShiftRoleTypes[changeLog?.newValue]) { // NOTE: only when task is assigned to ROLE
                        formattedLogText = formattedLogText.concat(
                            `${translateTab('protocol_panel_text.new_responsibility')}`,
                            ' → ',
                            `${translate(`global.user_shift_role.${eupShiftRoleTypes[changeLog.newValue]?.toLowerCase()}`)}; `,
                        );
                    }
                    break;
                }

                case '/assignedToUser': { // NOTE: only when task is assigned to user
                    formattedLogText = formattedLogText.concat(
                        `${translateTab('protocol_panel_text.new_responsibility')}`,
                        ' → ',
                        `${changeLog?.newValue || ''}; `,
                    );
                    break;
                }

                case '/dueDate': {
                    formattedLogText = formattedLogText.concat(
                        `${translateTab('protocol_panel_text.new_due_date')}`,
                        ' → ',
                        `${moment(changeLog?.newValue || '').isValid()
                            ? `${moment(changeLog.newValue).format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`
                            : ''}; `,
                    );
                    break;
                }

                case '/description': {
                    formattedLogText = formattedLogText.concat(
                        `${changeLog?.oldValue || ''}`,
                        ' → ',
                        `${changeLog?.newValue || ''}; `,
                    );
                    break;
                }

                default: {
                    // no-op
                }
            }
        });
        return formattedLogText;
    }, [translateTab, translate]);

    const formatVehicleDataChangedLogText = useCallback(changeLogs => {
        const newValues = [];
        const oldValues = [];

        changeLogs.forEach(changeLog => {
            const fieldName = changeLog.field.split('/').pop();
            let oldValue;
            let newValue;

            switch (fieldName) {
                case 'colour': {
                    newValue = changeLog.newValue ? translate(`global.vehicle_color.${snakeCase(changeLog.newValue)}`) : null;
                    oldValue = changeLog.oldValue ? translate(`global.vehicle_color.${snakeCase(changeLog.oldValue)}`) : null;
                    break;
                }

                case 'specialFeatures': {
                    const newSpecialFeatures = changeLog.newValue?.split(',');
                    newValue = newSpecialFeatures ? newSpecialFeatures.map(specialFeature => translate(`global.vehicle_special_feature.${snakeCase(specialFeature)}`)).join(', ') : null;
                    const oldSpecialFeatures = changeLog.oldValue?.split(',');
                    oldValue = oldSpecialFeatures ? oldSpecialFeatures.map(specialFeature => translate(`global.vehicle_special_feature.${snakeCase(specialFeature)}`)).join(', ') : null;
                    break;
                }

                case 'fuelType': {
                    newValue = changeLog.newValue ? translate(`global.vehicle_fuel_type.${snakeCase(changeLog.newValue)}`) : null;
                    oldValue = changeLog.oldValue ? translate(`global.vehicle_fuel_type.${snakeCase(changeLog.oldValue)}`) : null;
                    break;
                }

                case 'width':
                case 'length':
                case 'height':
                case 'weight': {
                    newValue = changeLog.newValue ? `${translateTab(`protocol_panel_text.vehicle_data_changed.${snakeCase(fieldName)}`)}: ${changeLog.newValue}` : null;
                    oldValue = changeLog.oldValue ? `${translateTab(`protocol_panel_text.vehicle_data_changed.${snakeCase(fieldName)}`)}: ${changeLog.oldValue}` : null;
                    break;
                }

                case 'numberOfSeats':
                case 'vehicleId':
                case 'licensePlateCountryFlag':
                case 'type':
                    break;

                default: {
                    newValue = changeLog.newValue ? changeLog.newValue : null;
                    oldValue = changeLog.oldValue ? changeLog.oldValue : null;
                }
            }

            if (newValue) newValues.push(newValue);
            if (oldValue) oldValues.push(oldValue);
        });

        return {
            newValues: newValues.join(', '),
            oldValues: oldValues.join(', '),
        };
    }, [translateTab, translate]);

    const caseLogText = {
        [ehmCaseLogActionTypes.CASE_CREATED]: translateTab('protocol_panel_text.case_created'),
        [ehmCaseLogActionTypes.CASE_STATUS_CHANGED]: translateTab(
            'protocol_panel_text.case_status_changed',
            {
                caseStatusOld: translate(`global.service_case_status_type.${efServiceCaseStatusTypes[caseLog?.changeLog[0]?.oldValue]?.toLowerCase()}`),
                caseStatusNew: translate(`global.service_case_status_type.${efServiceCaseStatusTypes[caseLog?.changeLog[0]?.newValue]?.toLowerCase()}`),
            },
        ),
        [ehmCaseLogActionTypes.CASE_VIEWED]: translateTab('protocol_panel_text.case_viewed'),
        [ehmCaseLogActionTypes.CASE_LEFT]: translateTab('protocol_panel_text.case_left'),
        [ehmCaseLogActionTypes.NOTE_ADDED]: caseLog?.lineNo ? translateTab(
            'protocol_panel_text.assignment_note_added',
            {
                noteTopic: caseLog?.payload?.noteTopic,
                noteText: caseLog?.payload?.note,
            },
        ) : translateTab(
            'protocol_panel_text.case_note_added',
            {
                noteTopic: caseLog?.payload?.noteTopic,
                noteText: caseLog?.payload?.note,
            },
        ),
        [ehmCaseLogActionTypes.MESSAGE_SENT]: translateTab(
            'protocol_panel_text.message_added',
            {
                receiver: caseLog?.payload?.receiverName,
                contactChannel: caseLog?.payload?.channel?.toLowerCase(),
                assignmentType: caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType
                    ? translate(`global.service_type.${caseLog?.serviceType?.toLowerCase()}`)
                    : translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                messageText: caseLog?.payload?.text,
                namesOfAddedAttachments: caseLog?.payload?.attachments ? caseLog?.payload?.attachments.map(attachment => attachment.fileName).join(', ') : '',
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_CREATED]: translateTab(
            'protocol_panel_text.assignment_created',
            {
                assignmentLineNum: caseLog?.lineNo,
                assignmentType: caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType && caseLog?.serviceType !== 'UNKNOWN'
                    ? translate(`global.service_type.${caseLog?.serviceType?.toLowerCase()}`)
                    : translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
            },
        ),
        [ehmCaseLogActionTypes.TASK_CREATED]: translateTab(
            'protocol_panel_text.task_created',
            {
                taskId: caseLog?.taskId,
                taskCategory: translate(`global.task_category.${caseLog?.payload?.taskCategory?.toLowerCase()}`),
            },
        ),
        [ehmCaseLogActionTypes.TASK_EDITED]: translateTab(
            'protocol_panel_text.task_edited',
            {
                taskId: caseLog?.taskId,
                updatedFields: caseLog?.changeLog ? formatTaskEditedLogText(caseLog.changeLog) : '',
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_ASSIGNED]: caseLog?.payload?.channels ? translateTab(
            'protocol_panel_text.assignment_assigned',
            {
                assignmentType: caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType
                    ? translate(`global.service_type.${caseLog?.serviceType?.toLowerCase()}`)
                    : translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                assignmentLineNum: caseLog?.lineNo,
                channels: caseLog?.payload?.channels.map(channel => translate(`global.contract_partner_assignment_channel.${snakeCase(channel)}`)).join(', '),
                partnerName: caseLog?.payload?.partnerName ? caseLog.payload.partnerName : '',
            },
        ) : translateTab(
            'protocol_panel_text.assignment_assigned_no_channels',
            {
                assignmentType: caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType
                    ? translate(`global.service_type.${caseLog?.serviceType?.toLowerCase()}`)
                    : translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                assignmentLineNum: caseLog?.lineNo,
                partnerName: caseLog?.payload?.partnerName ? caseLog.payload.partnerName : '',
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_STATUS_CHANGED]: translateTab(
            'protocol_panel_text.assignment_status_change',
            {
                assignmentType: caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType
                    ? translate(`global.service_type.${caseLog?.serviceType?.toLowerCase()}`)
                    : translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                assignmentLineNum: caseLog?.lineNo,
                partnerName: caseLog?.payload?.partnerName ? caseLog.payload.partnerName : '',
                assignmentStatusOld: translate(`global.service_assignment_status_type.${efServiceAssignmentStatusTypes[caseLog?.changeLog[0]?.oldValue]?.toLowerCase()}`),
                assignmentStatusNew: translate(`global.service_assignment_status_type.${efServiceAssignmentStatusTypes[caseLog?.changeLog[0]?.newValue]?.toLowerCase()}`),
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_SUB_STATUS_CHANGED]: translateTab(
            'protocol_panel_text.assignment_status_change',
            {
                assignmentType: translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                assignmentLineNum: caseLog?.lineNo,
                partnerName: caseLog?.payload?.partnerName ? caseLog.payload.partnerName : '',
                assignmentStatusOld: caseLog?.changeLog[0]?.oldValue ? translate(`global.rental_car_sub_status_type.${efRentalCarSubStatusTypes[caseLog?.changeLog[0]?.oldValue]?.toLowerCase()}`) : '',
                assignmentStatusNew: caseLog?.changeLog[0]?.newValue ? translate(`global.rental_car_sub_status_type.${efRentalCarSubStatusTypes[caseLog?.changeLog[0]?.newValue]?.toLowerCase()}`) : '',
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_EMAIL_RESEND]: translateTab(
            'protocol_panel_text.assignment_email_resend',
            {
                assignmentLineNum: caseLog?.lineNo,
                assignmentType: translate(`global.service_type.${caseLog?.assignmentType?.toLowerCase()}`),
                resendChannels: caseLog.payload ? `${caseLog.payload.email} ${caseLog.payload.faxNo}` : '',
            },
        ),
        [ehmCaseLogActionTypes.VEHICLE_DATA_CHANGED]: translateTab(
            'protocol_panel_text.vehicle_data_changed',
            {
                oldVehicleData: caseLog?.changeLog ? formatVehicleDataChangedLogText(caseLog.changeLog).oldValues : '',
                newVehicleData: caseLog?.changeLog ? formatVehicleDataChangedLogText(caseLog.changeLog).newValues : '',
            },
        ),
        [ehmCaseLogActionTypes.DOCUMENT_UPLOADED]: translateTab(
            'protocol_panel_text.document_uploaded',
            {
                documentDescription: config.IS_FALLBACK_SYSTEM
                    ? translateTab('protocol_panel_text.document_not_available_in_fbs')
                    : (caseLog?.payload?.description || ''),
            },
        ),
        [ehmCaseLogActionTypes.ASSIGNMENT_SERVICE_MANUAL_CHANGE]: translateTab(
            'protocol_panel_text.assignment_service_manual_change',
            {
                lineNum: caseLog?.lineNo,
                oldServiceType: caseLog?.changeLog[0]?.oldValue
                    ? translate(`global.service_type.${efServiceTypes[caseLog?.changeLog[0]?.oldValue]?.toLowerCase()}`) : '-',
                newServiceType: translate(`global.service_type.${efServiceTypes[caseLog?.changeLog[0]?.newValue]?.toLowerCase()}`),
            },
        ),
        [ehmCaseLogActionTypes.DAMAGE_LOCATION_CHANGED]: translateTab(
            'protocol_panel_text.damage_location_changed',
            {
                oldDamageLocation: caseLog?.changeLog?.find(log => log.field?.includes('formattedAddress'))?.oldValue || '-',
                newDamageLocation: caseLog?.changeLog?.find(log => log.field?.includes('formattedAddress'))?.newValue || '-',
            },
        ),
        [ehmCaseLogActionTypes.DAF_ASSIGNMENT_STATUS_CHANGED]: translateTab(
            'protocol_panel_text.daf_assignment_status_changed',
            {
                driverAppStatus: caseLog?.changeLog[0]?.newValue ? translate(`global.driver_app_service_assignment_status_type.${efDriverAppServiceAssignmentStatusTypes[caseLog?.changeLog[0]?.newValue]?.toLowerCase()}`) : '',
            },
        ),
        [ehmCaseLogActionTypes.DRIVER_ETA_CREATED]: translateTab(
            'protocol_panel_text.driver_eta_created',
            {
                eta: caseLog?.payload?.eta && moment(caseLog?.payload?.eta || '').isValid()
                    ? `${moment(moment.utc(caseLog.payload.eta)).local().format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`
                    : '-',
            },
        ),
        [ehmCaseLogActionTypes.TASK_FINISHED]: translateTab(
            'protocol_panel_text.task_finished',
            {
                taskId: caseLog?.taskId || '',
            },
        ),
        [ehmCaseLogActionTypes.POWER_OF_ATTORNEY_SENT]: translateTab(
            'protocol_panel_text.power_of_attorney_sent',
            {
                caseId: caseLog?.caseId || '',
                lineNo: caseLog?.lineNo || '',
            },
        ),
        [ehmCaseLogActionTypes.QUALITY_MANAGEMENT_FEEDBACK_CREATED]: caseLog?.lineNo && caseLog?.serviceType
            ? translateTab('protocol_panel_text.quality_management_feedback_created_assignment_qm',
                {
                    lineNo: caseLog.lineNo,
                    serviceType: translate(`global.service_type.${snakeCase(caseLog.serviceType)}`),
                    categories: (caseLog?.payload?.categories || [])
                        .map(category => translate(`global.quality_management_feedback_category.${snakeCase(category)}`))
                        .toString() || '-',
                    partnerName: caseLog?.payload?.partnerName || '-',
                })
            : translateTab('protocol_panel_text.quality_management_feedback_created_base_qm',
                {
                    categories: (caseLog?.payload?.categories || [])
                        .map(category => translate(`global.quality_management_feedback_category.${snakeCase(category)}`))
                        .toString() || '-',
                    partnerName: caseLog?.payload?.partnerName || '-',
                }),
        [ehmCaseLogActionTypes.ASSIGNMENT_ARCHIVED_AND_PAID_WITH_CARD]: translateTab(
            'protocol_panel_text.assignment_archived_and_paid',
            {
                lineNo: caseLog?.lineNo || '',
            },
        ),
        [ehmCaseLogActionTypes.DOCUMENT_DELETED]: translateTab(
            'protocol_panel_text.document_deleted',
            {
                documentName: config.IS_FALLBACK_SYSTEM
                    ? translateTab('protocol_panel_text.document_not_available_in_fbs')
                    : (caseLog?.payload?.attachments?.length ? caseLog.payload.attachments[0].fileName : ''),
            },
        ),
        // Note: here will go other translations for other actionTypes of caseLog
    };

    const caseLogCategory = {
        [ehmCaseLogActionTypes.CASE_VIEWED]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.CASE_LEFT]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.NOTE_ADDED]: translateTab('protocol_panel_category.notes'),
        [ehmCaseLogActionTypes.MESSAGE_SENT]: translateTab('protocol_panel_category.messages'),
        [ehmCaseLogActionTypes.ASSIGNMENT_CREATED]: translateTab('protocol_panel_category.assignment'),
        [ehmCaseLogActionTypes.ASSIGNMENT_SERVICE_MANUAL_CHANGE]: translateTab('protocol_panel_category.assignment'),
        [ehmCaseLogActionTypes.DAMAGE_LOCATION_CHANGED]: translateTab('protocol_panel_category.assignment'),
        [ehmCaseLogActionTypes.TASK_CREATED]: translateTab('protocol_panel_category.task'),
        [ehmCaseLogActionTypes.TASK_EDITED]: translateTab('protocol_panel_category.task'),
        [ehmCaseLogActionTypes.TASK_FINISHED]: translateTab('protocol_panel_category.task'),
        [ehmCaseLogActionTypes.ASSIGNMENT_ASSIGNED]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.ASSIGNMENT_STATUS_CHANGED]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.ASSIGNMENT_SUB_STATUS_CHANGED]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.ASSIGNMENT_ARCHIVED_AND_PAID_WITH_CARD]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.DOCUMENT_UPLOADED]: translateTab('protocol_panel_category.status_changed'),
        [ehmCaseLogActionTypes.DAF_ASSIGNMENT_STATUS_CHANGED]: translateTab('protocol_panel_category.driver_app'),
        [ehmCaseLogActionTypes.DRIVER_ETA_CREATED]: translateTab('protocol_panel_category.driver_app_eta'),
        [ehmCaseLogActionTypes.POWER_OF_ATTORNEY_SENT]: translateTab('protocol_panel_category.assignment'),
        [ehmCaseLogActionTypes.QUALITY_MANAGEMENT_FEEDBACK_CREATED]: translateTab('protocol_panel_category.quality_management_note'),
        [ehmCaseLogActionTypes.DOCUMENT_DELETED]: translateTab('protocol_panel_category.status_changed'),
    };

    const renderIconDetails = (caseLog, iconProp) => {
        const fallbackIcon = iconProp === 'isHighlighted' ? false : svgIcons.caseProtocolIcon;
        if (logCategories.includes(caseLog?.logCategory)) {
            if (caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType && caseLog?.serviceType !== 'UNKNOWN') {
                return serviceIcons[caseLog.serviceType][iconProp];
            }
            return caseLog?.assignmentType && serviceIcons.hasOwnProperty(`${caseLog.assignmentType}`)
                ? serviceIcons[caseLog.assignmentType][iconProp]
                : Object.keys(caseDataChangeIcons).includes(caseLog?.actionType)
                    ? caseDataChangeIcons[caseLog.actionType][iconProp] : fallbackIcon;
        }

        if (caseLog?.logCategory === ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK) {
            return caseLog?.payload?.positiveFeedback
                ? svgIcons.ratingPositiveIcon : svgIcons.ratingNegativeIcon;
        }

        return caseLogIcons[caseLog?.logCategory][iconProp];
    };

    const openUpdateCaseLogNote = caseLog => {
        const pathParams = {
            serviceCaseId: caseLog.caseId,
        };

        initiateUpdateCaseLogFlow({
            eventDate: caseLog.eventDate,
            eventTime: caseLog.eventTime,
            pathParams,
        });
    };

    const parseCaseLogText = caseLogText => {
        const emailRegex = /(<)(\w+)([.-]?\w+)*(@\w+)([.-]?\w+)*(\.\w{2,3})+(>)/mg;
        const trimmedText = caseLogText.replaceAll(emailRegex, (...matchedResult) => {
            return `&lt${matchedResult[2]}${matchedResult[3]}${matchedResult[4]}${matchedResult[6]}&gt`;
        });
        return HTMLParser.parse(trimmedText);
    };

    const formatCaseLogText = caseLogText => {
        if (!caseLogText) return '';
        if (caseLog.actionType !== ehmCaseLogActionTypes.VEHICLE_DATA_CHANGED) return caseLogText;
        const stringRowArray = caseLogText.split('\n');
        return stringRowArray.map(stringRow => (
            <p key={stringRow}>{stringRow}</p>
        ));
    };

    const handleFileDownload = attachment => {
        const payloadData = {
            fileName: attachment.fileName,
            url: attachment.url,
        };

        caseLog.category === ehmCaseLogCategoryTypes.NOTE
            ? submitNoteDocumentDownloadFlow(payloadData)
            : submitSCDocumentDownloadFlow(payloadData);
    };

    return (
        <div>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-padding--16-0',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--small',
                        'global!ace-u-margin--right-16',
                        {
                            'ace-c-highlight-circle--primary': renderIconDetails(caseLog, 'isHighlighted'),
                        },
                    ])}
                >
                    <Icon
                        icon={renderIconDetails(caseLog, 'icon')}
                        className={cx([
                            'ace-c-icon--m',
                            {
                                'ace-c-icon--color-highlight': renderIconDetails(caseLog, 'isHighlighted'),
                            },
                        ])}
                    />
                </HighlightCircle>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-full-width',
                    ])}
                >
                    <div className={cx('global!ace-u-full-width')}>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {`${isAgentIdentityAvailable
                                ? userDetails
                                : translate('global.user_shift_role.emergency_call_advisor')
                            } ${moment(caseLog.dateToISOString).locale(activeLocale).format('DD.MM.YYYY, HH:mm')}
                            ${translate('global.time_unit.time')}`}
                        </p>
                        <p className={cx([
                            'global!ace-u-typography--variant-caption',
                            'global!ace-u-typography--color-highlighted',
                            'global!ace-u-margin--bottom-8',
                        ])}
                        >
                            {caseLogCategory[caseLog.actionType] ? translateTab(`protocol_panel_category`, {caseLogCategory: caseLogCategory[caseLog.actionType]}) : ''}
                        </p>
                        {caseLog.logCategory === ehmCaseLogCategoryTypes.NOTE
                    || caseLog.logCategory === ehmCaseLogCategoryTypes.MESSAGE ? (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography-word-break--break-word',
                                'ace-c-log-entry__note-message-content',
                            ])}
                        >
                            {caseLogText[caseLog.actionType] ? parseCaseLogText(caseLogText[caseLog.actionType]) : null}
                        </div>
                            ) : (
                                <div className={cx('global!ace-u-typography--variant-body')}>
                                    {formatCaseLogText(translateTab(`protocol_panel_text`, {caseLogText: caseLogText[caseLog.actionType]}))}
                                </div>
                            )}
                        {(caseLog.actionType === ehmCaseLogActionTypes.DOCUMENT_UPLOADED
                            && !config.IS_FALLBACK_SYSTEM && caseLog?.payload?.attachments.length)
                            ? (
                                <div className={cx('global!ace-u-margin--top-8')}>
                                    {caseLog?.payload?.attachments.map(attachment => (
                                        <span
                                            key={attachment.id}
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-typography--variant-body',
                                                'global!ace-u-typography--color-highlighted',
                                                'global!ace-u-cursor--pointer',
                                                'global!ace-u-margin--bottom-4',
                                            ])}
                                            onClick={() => handleFileDownload(attachment)}
                                        >
                                            <Icon
                                                icon={attachmentIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                            {attachment.fileName}
                                        </span>
                                    ))}
                                </div>
                            ) : null}
                    </div>
                    {(caseLog.logCategory === ehmCaseLogCategoryTypes.NOTE) && (
                        <div>
                            {ecsAccessControl.grantFeatureAccess(ecsFeatures.CASE_LOG_NOTE, ecsFeatureActions.UPDATE)
                                && (
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => openUpdateCaseLogNote(caseLog)}
                                    />
                                )}
                            {!config.IS_FALLBACK_SYSTEM && caseLog?.payload?.attachments.length ? (
                                <Fragment>
                                    <p className={cx([
                                        'global!ace-u-typography--variant-body',
                                        'global!ace-u-margin--top-16',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                    >
                                        {`${translateTab('protocol_panel_note_text.attachments')}:`}
                                    </p>
                                    {caseLog.payload.attachments.map(attachment => (
                                        <span
                                            key={attachment.id}
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-typography--variant-body',
                                                'global!ace-u-typography--color-highlighted',
                                                'global!ace-u-cursor--pointer',
                                                'global!ace-u-margin--bottom-4',
                                            ])}
                                            onClick={() => handleFileDownload(attachment)}
                                        >
                                            <Icon
                                                icon={attachmentIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                            {attachment.fileName}
                                        </span>
                                    ))}
                                </Fragment>
                            ) : null}
                        </div>
                    )}
                    <div>
                        {((caseLog.actionType === ehmCaseLogActionTypes.ASSIGNMENT_ASSIGNED
                                || caseLog.actionType === ehmCaseLogActionTypes.ASSIGNMENT_EMAIL_RESEND
                                || caseLog.actionType === ehmCaseLogActionTypes.POWER_OF_ATTORNEY_SENT)
                            && caseLog?.payload?.attachments) && (
                            caseLog.payload.attachments.map(attachment => (
                                <span
                                    key={attachment.id}
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-typography--variant-body',
                                        'global!ace-u-typography--color-highlighted',
                                        'global!ace-u-cursor--pointer',
                                        'global!ace-u-margin--bottom-4',
                                    ])}
                                    onClick={() => handleFileDownload(attachment)}
                                >
                                    <Icon
                                        icon={attachmentIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            'ace-c-icon--color-highlight',
                                        ])}
                                    />
                                    {attachment.fileName}
                                </span>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Divider />
        </div>
    );
};

LogEntry.propTypes = {
    caseLog: PropTypes.object,
    users: PropTypes.object,
    initiateUpdateCaseLogFlow: PropTypes.func.isRequired,
    submitNoteDocumentDownloadFlow: PropTypes.func.isRequired,
    submitSCDocumentDownloadFlow: PropTypes.func.isRequired,
};

LogEntry.defaultProps = {
    caseLog: null,
    users: null,
};

const mapStateToProps = state => ({
    users: userProfileSelectors.getUsers(state),
});

const mapDispatchToProps = dispatch => ({
    initiateUpdateCaseLogFlow: payload => dispatch({
        type: caseLogActionTypes.INITIATE_UPDATE_CASE_LOG_FLOW,
        payload,
    }),
    submitNoteDocumentDownloadFlow: payload => dispatch({
        type: caseLogActionTypes.SUBMIT_NOTE_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
    submitSCDocumentDownloadFlow: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogEntry));
