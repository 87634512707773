import {all, fork, takeLatest} from 'redux-saga/effects';
import createPersonServiceAssignmentFlow from './sagas/createPersonServiceAssignmentFlow';
import createServiceAssignmentFlow from './sagas/createServiceAssignmentFlow';
import batchUpdateServiceAssignmentFlow from './sagas/batchUpdateServiceAssignmentFlow';
import cloneServiceAssignment from './sagas/cloneServiceAssignment';
import serviceAssignmentStatusUpdateFlow from './sagas/serviceAssignmentStatusUpdateFlow';
import saveArchiveServiceAssignmentFlow from './sagas/saveArchiveServiceAssignmentFlow';
import closeArchiveServiceAssignmentFlow from './sagas/closeArchiveServiceAssignmentFlow';
import updateServiceAssignmentFinalDestination from './sagas/updateServiceAssignmentFinalDestination';
import resendServiceAssignmentFlow from './sagas/resendServiceAssignmentFlow';
import updateServiceAssignmentTextFlow from './sagas/updateServiceAssignmentTextFlow';
import updateAlternativeServiceProvider from './sagas/updateAlternativeServiceProvider';
import updatePowerOfAttorneyChannels from './sagas/updatePowerOfAttorneyChannels';
import updatePowerOfAttorneyFlow from './sagas/updatePowerOdAttorneyFlow';
import deleteServiceAssignmentFlow from './sagas/deleteServiceAssignmentFlow';
import updateCommissioningACEPartnerDataFlow from './sagas/updateCommissioningACEPartnerDataFlow';
import searchAssignmentRelevantLocationGeolocation from './sagas/searchAssignmentRelevantLocationGeolocation';
import searchArcGISNearbyPOIs from './sagas/searchArcGISNearbyPOIs';
import searchServiceAssignmentFinalDestinationLocationGeolocation from './sagas/searchServiceAssignmentFinalDestinationLocationGeolocation';
import * as serviceAssignmentActionTypes from './serviceAssignmentActionTypes';
import updateCommissioningACEPartnerDetailsFlow from './sagas/updateCommissioningACEPartnerDetailsFlow';
import createServiceAssignmentPDFFlow from './sagas/createServiceAssignmentPDFFlow';
import updateServiceAssignmentJourneyNotesForm from './sagas/updateServiceAssignmentJourneyNotesForm';
import updateACEPartnerFlow from './sagas/updateACEPartnerFlow';
import searchACEPartnerLocationGeolocation from './sagas/searchACEPartnerLocationGeolocation';
import deleteServiceAssignmentACEPartnerFlow from './sagas/deleteServiceAssignmentACEPartnerFlow';
import initiateServiceAssignmentQMFeedbackDraftCreationFlow
    from './sagas/initiateServiceAssignmentQMFeedbackDraftCreationFlow';
import createPowerOfAttorneyTaskFlow from './sagas/createPowerOfAttorneyTaskFlow';
import getBudgetsByServiceType from './sagas/getBudgetsByServiceType';

/**
 *  Service assignment watcher saga
 */
const serviceAssignmentWatcher = function* serviceAssignmentWatcher() {
    yield all([
        fork(serviceAssignmentStatusUpdateFlow),
        fork(createServiceAssignmentFlow),
        fork(createPersonServiceAssignmentFlow),
        fork(batchUpdateServiceAssignmentFlow),
        fork(cloneServiceAssignment),
        fork(saveArchiveServiceAssignmentFlow),
        fork(closeArchiveServiceAssignmentFlow),
        fork(updateServiceAssignmentFinalDestination),
        fork(resendServiceAssignmentFlow),
        fork(updateServiceAssignmentTextFlow),
        fork(updateAlternativeServiceProvider),
        fork(updatePowerOfAttorneyChannels),
        fork(updatePowerOfAttorneyFlow),
        fork(deleteServiceAssignmentFlow),
        fork(updateCommissioningACEPartnerDataFlow),
        takeLatest(
            serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION,
            searchAssignmentRelevantLocationGeolocation,
        ),
        takeLatest(serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS, searchArcGISNearbyPOIs),
        takeLatest(
            serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION,
            searchServiceAssignmentFinalDestinationLocationGeolocation,
        ),
        fork(updateCommissioningACEPartnerDetailsFlow),
        fork(createServiceAssignmentPDFFlow),
        fork(updateServiceAssignmentJourneyNotesForm),
        fork(updateACEPartnerFlow),
        takeLatest(
            serviceAssignmentActionTypes.SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION,
            searchACEPartnerLocationGeolocation,
        ),
        fork(deleteServiceAssignmentACEPartnerFlow),
        fork(initiateServiceAssignmentQMFeedbackDraftCreationFlow),
        fork(createPowerOfAttorneyTaskFlow),
        fork(getBudgetsByServiceType),
    ]);
};

export default serviceAssignmentWatcher;
