import {take, fork, put, select} from 'redux-saga/effects';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savrActionTypes from '../savrActionTypes';

/**
 * Calculate the distance between pickup location and destination
 */
const calculatePickupLocationToDestinationDistance = function* calculatePickupLocationToDestinationDistance() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    while (true) {
        const {payload} = yield take(savrActionTypes.CALCULATE_PICKUP_LOCATION_TO_DESTINATION_DISTANCE);
        const {serviceAssignmentId, destination, pickupLocation} = payload;

        // calculate the distance beetwen pickup location and destination
        if (pickupLocation) {
            yield fork(
                fetchRequest,
                savrActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: pickupLocation.longitude,
                        latitude: pickupLocation.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_TRAVEL_TIME,
                    startTime: 'now',
                },
            );

            const routeResponseAction = yield take([
                savrActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_SUCCEEDED,
                savrActionTypes.FETCH_PICKUP_LOCATION_TO_DESTINATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                const pickupLocationToDestinationDistance = arcGISRouteDTO.totalKilometers;

                yield put({
                    type: savrActionTypes.SET_SAVR_PICKUP_LOCATION_TO_DESTINATION_DISTANCE,
                    payload: {pickupLocationToDestinationDistance, serviceAssignmentId},
                });
            }
        }
    }
};

export default calculatePickupLocationToDestinationDistance;
