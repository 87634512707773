import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceCaseTypes, ampVehicleCreationStatusTypes} from '@ace-de/eua-entity-types';
import {ArcGISMapWidget, useArcGISMapLayer} from '@ace-de/eua-arcgis-map';
import {useStyles, ButtonGhost, LockBackdrop} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar, NavigationBar, NotificationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import {Icon, caseProtocolIcon, infoIcon, closedIcon, warningAlertIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import ScreenTabControls from '../service-cases/ui-elements/ScreenTabControls';
import ServiceAssignmentHeader from '../service-assignments/ui-elements/ServiceAssignmentHeader';
import FallbackSystemNotificationBar from '../application/ui-elements/FallbackSystemNotificationBar';
import savScreenTabs from './savScreenTabs';
import SAVDamageServiceTab from './SAVDamageServiceTab';
import SAVVehicleTab from './SAVVehicleTab';
import SAVDamageLocationTab from './SAVDamageLocationTab';
import SAVTowingDestinationTab from './SAVTowingDestinationTab';
import SAVContractPartnerTab from './SAVContractPartnerTab';
import SAVCommissioningTab from './SAVCommissioningTab';
import serviceAssignmentPartnerDisabledStateStatuses from '../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';
import useServiceCaseLockedStatus from '../service-cases/useServiceCaseLockedStatus';
import createServiceCaseProtocolTabURL from '../service-cases/createServiceCaseProtocolTabURL';
import useBeforeUnloadEffect from '../service-cases/useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import contractPartnerInfoPopup from '../service-assignments/contractPartnerInfoPopup';

const ServiceAssignmentVehicleScreen = props => {
    const {cx} = useStyles();
    const {serviceCase, serviceAssignment, searchArcGISNearbyPOIs, resetArcGISNearbyPOIs} = props;
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('service_assignment_vehicle_screen');
    const savContractPartnerLocationsLayer = useArcGISMapLayer(
        'service-assignment-vehicle',
        'sav-contract-partner-locations',
    );
    const {activeTab} = useSearchQueryParams();
    const [isCPSelectionDisabled, setIsCPSelectionDisabled] = useState(false);
    const {isServiceCaseLocked, lockedBy} = useServiceCaseLockedStatus(serviceCase);
    const displayVehicleDriverOwnerWarning = activeTab !== savScreenTabs.VEHICLE
        && (!serviceCase?.vehicle?.driver.type || !serviceCase?.vehicle?.owner.type);
    const displayDraftVehicleWarning = !config.IS_FALLBACK_SYSTEM
        ? activeTab !== savScreenTabs.VEHICLE
        && (!serviceCase?.nonAceMember && !!serviceCase?.vehicle && !serviceCase?.vehicle?.vehicleId)
        : activeTab !== savScreenTabs.VEHICLE && (!serviceCase?.nonAceMember
            && (!!serviceCase?.vehicle
                && ((serviceCase?.vehicle.creationStatus === ampVehicleCreationStatusTypes.DRAFT && !serviceCase.vehicle.vehicleId) // eslint-disable-line max-len
                    || (serviceCase?.vehicle.creationStatus === null && !serviceCase.vehicle.vehicleId))));

    const displayVehicleWarning = displayVehicleDriverOwnerWarning || displayDraftVehicleWarning;
    const displayServiceTypeWarning = activeTab !== savScreenTabs.DAMAGE_AND_SERVICE
        && !serviceCase?.serviceType;
    const displayCallbackNumberPhoneNoWarning = activeTab !== savScreenTabs.DAMAGE_LOCATION
        && !serviceCase?.isCallbackPhoneValid;
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const topElementRef = useRef();

    useBeforeUnloadEffect(event => {
        return {
            unlockRequest: ecsFlowService.unlockServiceCase,
            requestParams: {serviceCaseId: serviceCase.id},
        };
    });

    useEffect(() => {
        if (!serviceCase || !serviceAssignment || !savContractPartnerLocationsLayer) return;

        const {assignmentStatus} = serviceAssignment;
        const {caseType} = serviceCase;
        const isPartnerChangingDisabled = serviceAssignmentPartnerDisabledStateStatuses.includes(assignmentStatus)
            && caseType === efServiceCaseTypes.VEHICLE;

        if (isPartnerChangingDisabled !== isCPSelectionDisabled) {
            savContractPartnerLocationsLayer.toggleLayerDisabled(isPartnerChangingDisabled);
            setIsCPSelectionDisabled(isPartnerChangingDisabled);
        }
    }, [savContractPartnerLocationsLayer, serviceCase, serviceAssignment, isCPSelectionDisabled]);

    useEffect(() => {
        if (!topElementRef.current) return;
        topElementRef.current.scrollIntoView();
    }, [topElementRef]);

    // if no ServiceCase or ServiceAssignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const tabsWithMap = [
        savScreenTabs.DAMAGE_LOCATION,
        savScreenTabs.TOWING_DESTINATION,
        savScreenTabs.CONTRACT_PARTNER,
    ];
    const {damage} = serviceCase;

    const isServiceCaseEditAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.UPDATE,
    );

    return (
        <Fragment>
            <FallbackSystemNotificationBar />
            <div ref={topElementRef} />
            <HeaderBar>
                <ServiceAssignmentHeader serviceCase={serviceCase} serviceAssignment={serviceAssignment} />
            </HeaderBar>
            <TabSet
                name="sav-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="sav-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="sav-screen-tabs"
                            tabSet="sav-screen-tab-set"
                            defaultValue={activeTab || savScreenTabs.DAMAGE_AND_SERVICE}
                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                || serviceAssignment.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={savScreenTabs.DAMAGE_AND_SERVICE}
                                value={savScreenTabs.DAMAGE_AND_SERVICE}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    {
                                        'global!ace-u-typography--color-warning': displayServiceTypeWarning,
                                    },
                                ])}
                            >
                                {translateScreen('tab_label.damage_and_service')}
                                {displayServiceTypeWarning ? (
                                    <Icon
                                        icon={warningAlertIcon}
                                        className={cx([
                                            'ace-c-icon--color-warning',
                                            'global!ace-u-margin--left-8',
                                        ])}
                                    />
                                ) : null}
                            </Tab>
                            <Tab
                                name={savScreenTabs.VEHICLE}
                                value={savScreenTabs.VEHICLE}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    {
                                        'global!ace-u-typography--color-warning': displayVehicleWarning,
                                    },
                                ])}
                            >
                                {translateScreen('tab_label.vehicle')}
                                {displayVehicleWarning ? (
                                    <Icon
                                        icon={warningAlertIcon}
                                        className={cx([
                                            'ace-c-icon--color-warning',
                                            'global!ace-u-margin--left-8',
                                        ])}
                                    />
                                ) : null}
                            </Tab>
                            <Tab
                                name={savScreenTabs.DAMAGE_LOCATION}
                                value={savScreenTabs.DAMAGE_LOCATION}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    {
                                        'global!ace-u-typography--color-warning': displayCallbackNumberPhoneNoWarning,
                                    },
                                ])}
                            >
                                {translateScreen('tab_label.damage_location')}
                                {displayCallbackNumberPhoneNoWarning ? (
                                    <Icon
                                        icon={warningAlertIcon}
                                        className={cx([
                                            'ace-c-icon--color-warning',
                                            'global!ace-u-margin--left-8',
                                        ])}
                                    />
                                ) : null}
                            </Tab>
                            <Tab
                                name={savScreenTabs.TOWING_DESTINATION}
                                value={savScreenTabs.TOWING_DESTINATION}
                                isDisabled={!damage || !damage.location}
                            >
                                {translateScreen('tab_label.towing_destination')}
                            </Tab>
                            <Tab
                                name={savScreenTabs.CONTRACT_PARTNER}
                                value={savScreenTabs.CONTRACT_PARTNER}
                                isDisabled={!damage || !damage.location}
                            >
                                {translateScreen('tab_label.contract_partner')}
                            </Tab>
                            <Tab
                                name={savScreenTabs.COMMISSIONING}
                                value={savScreenTabs.COMMISSIONING}
                                isDisabled={!damage || !damage.location}
                            >
                                {translateScreen('tab_label.commissioning')}
                            </Tab>
                        </TabBar>
                        <ButtonGhost
                            className={cx([
                                'global!ace-u-flex--align-self-stretch',
                                'global!ace-u-margin--right-32',
                            ])}
                            onClick={() => window.open(createServiceCaseProtocolTabURL(serviceCase.id), '_blank')}
                        >
                            <Icon
                                icon={caseProtocolIcon}
                                className={cx('global!ace-u-margin--right-8')}
                            />
                            {translateScreen('button_label.case_protocol')}
                        </ButtonGhost>
                    </div>
                </NavigationBar>
                <div>
                    {isServiceCaseLocked && (
                        <NotificationBar type="information">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                ])}
                            >
                                <Icon
                                    icon={closedIcon}
                                    className={cx('global!ace-u-margin--right-8')}
                                />
                                {translate('global.case_lock_check.case_lock_notification', {
                                    userName: lockedBy,
                                })}
                            </div>
                        </NotificationBar>
                    )}
                    {!serviceAssignment.entitlementCheck && !tabsWithMap.includes(activeTab) && (
                        <NotificationBar type="negative">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-typography--color-contrast',
                                ])}
                            >
                                <Icon
                                    icon={infoIcon}
                                    className={cx([
                                        'ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                <div>
                                    {Array.isArray(serviceAssignment.entitlementResponses)
                                        && serviceAssignment.entitlementResponses.map((response, idx) => {
                                            return (
                                                <Fragment key={`${response.entitlementError}-${idx}`}>
                                                    <span>{translate(response.message)}</span>
                                                    {idx < serviceAssignment.entitlementResponses.length - 1 && (
                                                        <br />
                                                    )}
                                                </Fragment>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </NotificationBar>
                    )}
                </div>
                <AppLayoutMain
                    contentClassName={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-stretch',
                    ])}
                >
                    {(isServiceCaseLocked || !isServiceCaseEditAvailable) && (
                        <LockBackdrop />
                    )}
                    <ArcGISMapWidget
                        name="service-assignment-vehicle"
                        tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                        className={cx({
                            'ace-c-arcgis-map-widget--is-visible': tabsWithMap.includes(activeTab),
                        })}
                        hasFilterPOIsWidget={activeTab !== savScreenTabs.TOWING_DESTINATION}
                        searchArcGISNearbyPOIs={searchArcGISNearbyPOIs}
                        resetArcGISNearbyPOIs={resetArcGISNearbyPOIs}
                        locale={activeLocale}
                        popup={contractPartnerInfoPopup}
                    />
                    <TabPanel for={savScreenTabs.DAMAGE_AND_SERVICE}>
                        <SAVDamageServiceTab />
                    </TabPanel>
                    <TabPanel for={savScreenTabs.VEHICLE}>
                        <SAVVehicleTab />
                    </TabPanel>
                    <TabPanel for={savScreenTabs.DAMAGE_LOCATION}>
                        <SAVDamageLocationTab />
                    </TabPanel>
                    <TabPanel for={savScreenTabs.TOWING_DESTINATION}>
                        <SAVTowingDestinationTab />
                    </TabPanel>
                    <TabPanel for={savScreenTabs.CONTRACT_PARTNER}>
                        <SAVContractPartnerTab />
                    </TabPanel>
                    <TabPanel for={savScreenTabs.COMMISSIONING}>
                        <SAVCommissioningTab />
                    </TabPanel>

                    <ScreenTabControls activeTab={activeTab} screenTabs={savScreenTabs} />
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ServiceAssignmentVehicleScreen.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchArcGISNearbyPOIs: PropTypes.func.isRequired,
    resetArcGISNearbyPOIs: PropTypes.func.isRequired,
};

ServiceAssignmentVehicleScreen.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS,
        payload,
    }),
    resetArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.RESET_ARCGIS_NEARBY_POIS_RESULTS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAssignmentVehicleScreen);
