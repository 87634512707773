import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useStyles} from '@ace-de/ui-components';
import {withRouter} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {InteractiveIcon, arrowDownIcon, arrowUpIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import styles from './ContactDetailsPanel.module.scss';

const ContactDetailsPanelItem = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {serviceAssignment} = props;
    const [isToggleOn, setIsToggleOn] = useState(false);
    const {acePartner, finalDestination, assignmentType} = serviceAssignment;
    const contactDetails = acePartner?.contactDetails || acePartner?.businessContactDetails;

    const toggleList = event => {
        event.preventDefault();
        event.stopPropagation();
        setIsToggleOn(!isToggleOn);
    };

    const serviceType = serviceAssignment.serviceType
        ? (serviceAssignment.assignmentType === efServiceAssignmentTypes.OTHER_SERVICES
            ? translate(`global.other_services_service_type.${serviceAssignment.serviceType.toLowerCase()}`)
            : translate(`global.service_type.${serviceAssignment.serviceType.toLowerCase()}`))
        : translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`);

    return (
        <Fragment>
            <div
                className={cx([
                    'global!ace-u-typography--variant-subtitle',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-margin--bottom-24',
                ])}
            >
                {serviceAssignment.lineNo} - {serviceType}
                <InteractiveIcon
                    icon={!isToggleOn ? arrowDownIcon : arrowUpIcon}
                    onClick={toggleList}
                    className={cx('global!ace-u-padding--left-24')}
                />
            </div>
            {isToggleOn && acePartner && (
                <div className={cx('global!ace-u-margin--bottom-24')}>
                    <div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-4',
                            ])}
                        >
                            {acePartner.partnerType
                                ? assignmentType === efServiceAssignmentTypes.RENTAL_CAR
                                    ? translateTab('partner_type.rental_car_provider')
                                    : translate(`global.ace_partner_type.${acePartner.partnerType.toLowerCase()}`)
                                : ''}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-4',
                            ])}
                        >
                            {acePartner.name || '-'}
                        </p>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--column-gap-16',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            <span className={cx('global!ace-u-flex--basis-20')}>
                                {translateTab('data_label.address')}
                            </span>
                            <span className={cx('global!ace-u-flex--basis-80')}>
                                {acePartner.location
                                    ? acePartner.location.formattedAddress
                                        ? acePartner.location.formattedAddress
                                        : [
                                            acePartner.location.street,
                                            acePartner.location.postCode,
                                            acePartner.location.city,
                                        ].filter(value => !!value).join(', ')
                                    : '-'}
                            </span>
                        </div>
                        {contactDetails
                        && Object.keys(contactDetails)
                            .filter(key => (['websiteUrl', 'email', 'phoneNo'].includes(key)))
                            .map((key, index) => (
                                <div
                                    key={`${key}-${index}`}
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--column-gap-16',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                >
                                    <span className={cx('global!ace-u-flex--basis-20')}>
                                        {translateTab(`data_label.${snakeCase(key)}`)}
                                    </span>
                                    {contactDetails[key] ? (
                                        <a
                                            href={key === 'phoneNo'
                                                ? `tel:${contactDetails[key]}`
                                                : key === 'email'
                                                    ? `mailto:${contactDetails[key]}`
                                                    : contactDetails[key]}
                                            target={key === 'websiteUrl' ? '_blank' : ''}
                                            className={cx([
                                                'global!ace-u-flex--basis-80',
                                                'global!ace-u-typography--color-highlighted',
                                                'ace-c-contact-details-panel__contact-link',
                                            ])}
                                            rel="noreferrer"
                                        >
                                            {contactDetails[key]}
                                        </a>
                                    ) : <span className={cx('global!ace-u-flex--basis-80')}>-</span>}
                                </div>
                            ))}
                    </div>
                    {finalDestination?.phoneNo && (
                        <div className={cx('global!ace-u-margin--top-24')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--bottom-4',
                                ])}
                            >
                                {translateTab('phone_numbers_panel.final_towing_destination')}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--bottom-4',
                                ])}
                            >
                                {finalDestination?.location?.locationName || '-'}
                            </p>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-16',
                                    'global!ace-u-typography--variant-body',
                                ])}
                            >
                                <span className={cx('global!ace-u-flex--basis-20')}>
                                    {translateTab('data_label.address')}
                                </span>
                                <span className={cx('global!ace-u-flex--basis-80')}>
                                    {finalDestination.location
                                        ? finalDestination.location.formattedAddress
                                            ? finalDestination.location.formattedAddress
                                            : [
                                                finalDestination.location.street,
                                                finalDestination.location.postCode,
                                                finalDestination.location.city,
                                            ].filter(value => !!value).join(', ')
                                        : '-'}
                                </span>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-16',
                                    'global!ace-u-typography--variant-body',
                                ])}
                            >
                                <span className={cx('global!ace-u-flex--basis-20')}>
                                    {translateTab(`data_label.phone_no`)}
                                </span>
                                <a
                                    href={`tel:${finalDestination.phoneNo}`}
                                    className={cx([
                                        'global!ace-u-flex--basis-80',
                                        'global!ace-u-typography--color-highlighted',
                                        'ace-c-contact-details-panel__contact-link',
                                    ])}
                                    rel="noreferrer"
                                >
                                    {finalDestination.phoneNo}
                                </a>
                            </div>
                        </div>
                    )}
                    {assignmentType === efServiceAssignmentTypes.RENTAL_CAR && (
                        <div>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--32-0-4',
                                ])}
                            >
                                {translateTab('data_label.rental_car_pickup_station')}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--bottom-4',
                                ])}
                            >
                                {serviceAssignment.rentalCarStationPickup?.name || '-'}
                            </p>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-16',
                                    'global!ace-u-typography--variant-body',
                                ])}
                            >
                                <span className={cx('global!ace-u-flex--basis-20')}>
                                    {translateTab('data_label.address')}
                                </span>
                                <span className={cx('global!ace-u-flex--basis-80')}>
                                    {serviceAssignment.rentalCarStationPickup?.location
                                        ? serviceAssignment.rentalCarStationPickup.location.formattedAddress
                                            ? serviceAssignment.rentalCarStationPickup.location.formattedAddress
                                            : [
                                                serviceAssignment.rentalCarStationPickup.location.street,
                                                serviceAssignment.rentalCarStationPickup.location.postCode,
                                                serviceAssignment.rentalCarStationPickup.location.city,
                                            ].filter(value => !!value).join(', ')
                                        : '-'}
                                </span>
                            </div>
                            {serviceAssignment.rentalCarStationDropOff && (
                                <div>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--32-0-8',
                                        ])}
                                    >
                                        {translateTab('data_label.rental_car_drop_off_station')}
                                    </p>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-4',
                                        ])}
                                    >
                                        {serviceAssignment.rentalCarStationDropOff.name || '-'}
                                    </p>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--column-gap-16',
                                            'global!ace-u-typography--variant-body',
                                        ])}
                                    >
                                        <span className={cx('global!ace-u-flex--basis-20')}>
                                            {translateTab('data_label.address')}
                                        </span>
                                        <span className={cx('global!ace-u-flex--basis-80')}>
                                            {serviceAssignment.rentalCarStationDropOff.location
                                                ? serviceAssignment.rentalCarStationDropOff.location.formattedAddress
                                                    ? serviceAssignment.rentalCarStationDropOff.location.formattedAddress // eslint-disable-line max-len
                                                    : [
                                                        serviceAssignment.rentalCarStationDropOff.location.street,
                                                        serviceAssignment.rentalCarStationDropOff.location.postCode,
                                                        serviceAssignment.rentalCarStationDropOff.location.city,
                                                    ].filter(value => !!value).join(', ')
                                                : '-'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

ContactDetailsPanelItem.propTypes = {
    serviceAssignment: PropTypes.object,
};

ContactDetailsPanelItem.defaultProps = {
    serviceAssignment: {},
};

export default withRouter(ContactDetailsPanelItem);
