import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleFuelTypes, ampVehicleSpecialFeatureTypes, ampVehicleCreationStatusTypes, efServiceCaseTypes, efPersonTypes} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {useStyles, Panel, DataRow, Divider, DropDownTrigger, PopOver, Badge} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon, Icon, detailsIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';

const VehicleInfoPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {serviceCase, handleOnEdit} = props;
    const isVehicleEditingAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_VEHICLE,
        ecsFeatureActions.UPDATE,
    );

    const onVehicleEdit = () => {
        if (typeof handleOnEdit === 'function') {
            handleOnEdit();
        }
    };

    // if no service case, do not render
    if (!serviceCase) return null;
    const {vehicle, vehicleDamageNotes} = serviceCase;

    return (
        <Panel
            title={(
                <Fragment>
                    {translateTab('vehicle_info_panel.title')}
                    {vehicle?.isVehicleBCNav && (
                        <Badge
                            className={cx('global!ace-u-margin--left-16')}
                        >
                            {translate('global.vehicle_source.bc_nav')}
                        </Badge>
                    )}
                </Fragment>
            )}
            actions={
                (typeof handleOnEdit === 'function'
                && (vehicle?.vehicleId || vehicle?.creationStatus === ampVehicleCreationStatusTypes.COMPLETED)
                && (
                    <InteractiveIcon
                        icon={editIcon}
                        onClick={onVehicleEdit}
                        isDisabled={!isVehicleEditingAvailable}
                    />
                ))}
            qaIdent="vehicle-info"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.type')}
                        qaIdent="vehicle-type"
                    >
                        {vehicle?.type
                            ? translate(`global.service_assignment_vehicle_type.${vehicle?.type.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.manufacturer_model_variant')}
                        qaIdent="vehicle-manufacturer-model-variant"
                    >
                        {[
                            vehicle?.manufacturer,
                            [vehicle?.model, vehicle?.variant].filter(value => !!value).join(' '),
                        ].filter(value => !!value).join(' - ') || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.license_plate_no')}
                        qaIdent="vehicle-licence-plate-number"
                    >
                        {vehicle?.licensePlateNumber || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.build_year')}
                        qaIdent="vehicle-build-year"
                    >
                        {vehicle?.buildYear || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.driver')}
                        qaIdent="vehicle-driver"
                    >
                        {vehicle?.driver?.type ? translateTab(`vehicle_info_panel_input_value.${vehicle.driver.type.toLowerCase()}`) : '-'}
                        {vehicle?.driver?.type && vehicle.driver.type !== efPersonTypes.MEMBER && (
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translateTab('data_row_label.driver_type')}
                                            qaIdent="vehicle-driver-type"
                                        >
                                            {translateTab(`vehicle_info_panel_input_value.${vehicle.driver.type.toLowerCase()}`)}
                                        </DataRow>
                                        <DataRow
                                            label={translateTab('data_row_label.driver_name')}
                                            qaIdent="vehicle-driver-name"
                                        >
                                            {vehicle.driver.name || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translateTab('data_row_label.driver_address')}
                                            qaIdent="vehicle-driver-address"
                                        >
                                            {vehicle.driver.address ? (
                                                <div>
                                                    <p>{vehicle.driver.address.street}</p>
                                                    <p>{`${vehicle.driver.address.postCode} ${vehicle.driver.address.city}`}</p>
                                                </div>
                                            ) : '-'}
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        )}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.owner')}
                        qaIdent="vehicle-owner"
                    >
                        {vehicle?.owner?.type ? translateTab(`vehicle_info_panel_input_value.${vehicle.owner.type.toLowerCase()}`) : '-'}
                        {vehicle?.owner?.type && vehicle.owner.type !== efPersonTypes.MEMBER && (
                            <DropDownTrigger>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    <Icon
                                        icon={detailsIcon}
                                        className={cx('global!ace-u-margin--top-4')}
                                    />
                                </div>
                                <PopOver alignment="end" hasBorder={true}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-grid-column--span-4',
                                        ])}
                                    >
                                        <DataRow
                                            label={translateTab('data_row_label.driver_type')}
                                            qaIdent="vehicle-driver-type"
                                        >
                                            {translateTab(`vehicle_info_panel_input_value.${vehicle.owner.type.toLowerCase()}`)}
                                        </DataRow>
                                        <DataRow
                                            label={translateTab('data_row_label.driver_name')}
                                            qaIdent="vehicle-driver-name"
                                        >
                                            {vehicle.owner.name || '-'}
                                        </DataRow>
                                        <DataRow
                                            label={translateTab('data_row_label.driver_address')}
                                            qaIdent="vehicle-driver-address"
                                        >
                                            {vehicle.owner.address ? (
                                                <div>
                                                    <p>{vehicle.owner.address.street}</p>
                                                    <p>{`${vehicle.owner.address.postCode} ${vehicle.owner.address.city}`}</p>
                                                </div>
                                            ) : '-'}
                                        </DataRow>
                                    </div>
                                </PopOver>
                            </DropDownTrigger>
                        )}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.vehicle_dimensions')}
                        qaIdent="vehicle-dimensions"
                        className={cx('ace-c-data-row--full-bleed-content')}
                        contentClassName={cx([
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-items-stretch',
                        ])}
                    >
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_info_panel_input_label.dimensions')}</p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-typography--align-flex-start',
                                    'global!ace-u-margin--left-8',
                                ])}
                            >
                                {[
                                    vehicle?.length?.toLocaleString(activeLocale),
                                    vehicle?.width?.toLocaleString(activeLocale),
                                    vehicle?.height?.toLocaleString(activeLocale),
                                ].filter(value => !!value).join(' x ') || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('vehicle_info_panel_input_label.weight')}</p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-typography--align-flex-start',
                                    'global!ace-u-margin--left-8',
                                ])}
                            >
                                {vehicle?.weight?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.fuel_type')}
                        qaIdent="sa-vehicle-info-fuel-type"
                    >
                        {vehicle?.fuelType && vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                            ? translate(`global.vehicle_fuel_type.${snakeCase(vehicle.fuelType)}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.other')}
                        qaIdent="sa-vehicle-info-special-features"
                    >
                        {Array.isArray(vehicle?.specialFeatures)
                            ? ([
                                (vehicle?.specialFeatures || [])
                                    .filter(specialFeature => specialFeature !== ampVehicleSpecialFeatureTypes.UNKNOWN)
                                    .map(feature => translate(`global.vehicle_special_feature.${snakeCase(feature)}`))
                                    .join(', ') || '',
                                vehicle?.numberOfSeats
                                    ? `${vehicle.numberOfSeats} ${translateTab('vehicle_info_panel_input_label.number_of_seats')}`
                                    : '',
                            ].filter(value => !!value).join(', ') || '-')
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.color')}
                        qaIdent="vehicle-color"
                    >
                        {vehicle?.color ? translate(`global.vehicle_color.${vehicle.color.toLowerCase()}`) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('vehicle_info_panel_input_label.comment')}
                        qaIdent="vehicle-damage-notes"
                    >
                        {serviceCase.caseType === efServiceCaseTypes.VEHICLE
                            ? vehicleDamageNotes || '-'
                            : serviceCase?.damage?.additionalNotes || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

VehicleInfoPanel.propTypes = {
    serviceCase: PropTypes.object,
    handleOnEdit: PropTypes.func,
};

VehicleInfoPanel.defaultProps = {
    serviceCase: null,
    handleOnEdit: undefined,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(VehicleInfoPanel));
