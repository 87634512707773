import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {ContentBlock, ContentItem, useStyles} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import VehicleCreatePanel from '../service-cases/ui-elements/VehicleCreatePanel';
import VehicleSelectPanel from '../service-cases/ui-elements/VehicleSelectPanel';
import OwnerAndDriverPanel from './ui-elements/OwnerAndDriverPanel';
import VehicleDamageNotesPanel from './ui-elements/VehicleDamageNotesPanel';
import CreateTrailerPanel from './ui-elements/CreateTrailerPanel';

const SAVVehicleTab = ({serviceCase, memberVehicles, serviceAssignment}) => {
    const {cx} = useStyles();
    const [selectedVehicle, setSelectedVehicle] = useState(serviceCase?.vehicle?.vehicleId
        ? serviceCase.vehicle
        : null);

    // if an agent creates a draft, reset previously selected vehicle
    useEffect(() => {
        if (serviceCase?.vehicle && !serviceCase.vehicle?.vehicleId && selectedVehicle) {
            setSelectedVehicle(null);
        }
    }, [serviceCase?.vehicle, selectedVehicle]);

    // if no service case don't render
    if (!serviceCase) return null;

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                {serviceCase.nonAceMember
                    ? (
                        <Fragment>
                            <VehicleCreatePanel
                                serviceCase={serviceCase}
                            />
                            <div className={cx('global!ace-u-margin--top-24')}>
                                <CreateTrailerPanel serviceCase={serviceCase} />
                            </div>
                        </Fragment>
                    ) : (
                        <VehicleSelectPanel
                            memberVehicles={memberVehicles}
                            selectedVehicle={selectedVehicle}
                            setSelectedVehicle={setSelectedVehicle}
                            serviceCase={serviceCase}
                        />
                    )
                }
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <OwnerAndDriverPanel
                    serviceCase={serviceCase}
                    memberAddress={serviceCase.member.personalDetails.address}
                    selectedVehicle={selectedVehicle}
                    serviceAssignment={serviceAssignment}
                />
                <VehicleDamageNotesPanel serviceCase={serviceCase} />
            </ContentItem>
        </ContentBlock>
    );
};

SAVVehicleTab.propTypes = {
    serviceCase: PropTypes.object,
    memberVehicles: PropTypes.array,
    serviceAssignment: PropTypes.object,
};

SAVVehicleTab.defaultProps = {
    serviceCase: null,
    memberVehicles: [],
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const memberVehiclesSelector = serviceCaseSelectors.createMemberVehiclesSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        memberVehicles: memberVehiclesSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(SAVVehicleTab));
