import {call, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as applicationActionTypes from '../applicationActionTypes';

/**
 * Fetch request saga
 *
 * @param {string} actionType
 * @param {function} serviceMethod
 * @param {Object} requestParams
 * @param {Object} meta
 */
const fetchRequest = function* fetchRequest(actionType, serviceMethod, requestParams, meta) {
    // ensure actionType contains `_REQUEST` postfix
    actionType = !actionType.includes('_REQUEST') ? `${actionType}_REQUEST` : actionType;

    while (true) {
        const {requestStates} = yield select(state => state.requestStates);
        const {location} = yield select(state => state.router);
        const requestState = requestStates
            ? (requestStates[actionType]
                ? requestStates[actionType]
                : (meta?.isBatch && meta?.caller && requestStates[meta?.caller]
                    ? requestStates[meta?.caller]
                    : null)
            ) : null;
        const requestStateActionType = meta?.isBatch && meta?.caller ? meta?.caller : actionType;

        try {
            if (requestState) {
                // set request state: reset error and set isInProgress=true
                yield put({
                    type: applicationActionTypes.SET_REQUEST_STATE,
                    payload: {
                        actionType: requestStateActionType,
                        requestState: {
                            ...requestState,
                            error: null,
                            isInProgress: true,
                        },
                    },
                });
            }

            // send request
            yield put({
                type: `${actionType}_SENT`,
                payload: {requestParams},
                meta,
            });
            const response = yield call(serviceMethod, requestParams);
            yield put({
                type: `${actionType}_SUCCEEDED`,
                payload: {response},
                meta,
            });

            if (requestState) {
                // set request state: reset error and set isInProgress=false
                yield put({
                    type: applicationActionTypes.SET_REQUEST_STATE,
                    payload: {
                        actionType: requestStateActionType,
                        requestState: {
                            ...requestState,
                            error: null,
                            isInProgress: false,
                        },
                    },
                });
                // if the error modal is open, close it
                if (location?.query?.modal === modalIds.ERROR_MESSAGE_MODAL
                    && location?.query?.actionType === requestStateActionType) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {actionType: ''});
                }
            }
            return;
        } catch (error) {
            if (requestState) {
                // set request state: set error and set isInProgress=false
                yield put({
                    type: applicationActionTypes.SET_REQUEST_STATE,
                    payload: {
                        actionType: requestStateActionType,
                        requestState: {
                            ...requestState,
                            error,
                            isInProgress: false,
                        },
                    },
                });
            }
            // if the request has retry option, open the error message modal
            if (requestState?.hasRetryOption) {
                // if the error modal is not open, open it
                if (location?.query?.modal !== modalIds.ERROR_MESSAGE_MODAL) {
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {actionType: requestStateActionType});
                }

                const errorModalResponseAction = yield take([
                    applicationActionTypes.RETRY_REQUEST,
                    applicationActionTypes.CANCEL_REQUEST,
                ]);

                if (errorModalResponseAction.type === applicationActionTypes.CANCEL_REQUEST) {
                    // set request state: reset error and set isInProgress=false
                    yield put({
                        type: applicationActionTypes.SET_REQUEST_STATE,
                        payload: {
                            actionType: requestStateActionType,
                            requestState: {
                                ...requestState,
                                error: null,
                                isInProgress: false,
                            },
                        },
                    });
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {actionType: ''});
                    yield put({type: `${actionType}_FAILED`, error: true, payload: error, meta});
                    return;
                }
                if (errorModalResponseAction.type === applicationActionTypes.RETRY_REQUEST) {
                    continue;
                }
            }

            yield put({type: `${actionType}_FAILED`, error: true, payload: error, meta});
            return;
        }
    }
};

export default fetchRequest;
