import {select, fork, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

/**
 * Get member service case counts
 */
const getMemberServiceCaseCounts = function* getMemberServiceCaseCounts(
    memberIds = [],
) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    if (memberIds.length === 0) return [];

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST,
        ecsFlowService.findServiceCaseCountForMembers,
        {
            memberIds,
        },
    );

    const findServiceCaseCountResponseAction = yield take([
        serviceCaseActionTypes.FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_FAILED,
    ]);

    if (findServiceCaseCountResponseAction.error) return [];

    const {payload: serviceCaseCountPayload} = findServiceCaseCountResponseAction;
    const {serviceCaseCountForMembers} = serviceCaseCountPayload.response;

    return serviceCaseCountForMembers;
};

export default getMemberServiceCaseCounts;
