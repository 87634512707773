import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as salaActionTypes from '../salaActionTypes';

/**
 * Update SALA service provider flow
 */
const updateSALAServiceProviderFlow = function* updateSALAServiceProviderFlow() {
    while (true) {
        const {payload} = yield take(salaActionTypes.SUBMIT_SALA_SERVICE_PROVIDER_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: salaActionTypes.SUBMIT_SALA_SERVICE_PROVIDER_FORM,
            assignmentType: efServiceAssignmentTypes.LEGAL_ADVICE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: salaActionTypes.SUBMIT_SALA_SERVICE_PROVIDER_FORM},
        });
    }
};

export default updateSALAServiceProviderFlow;
