import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, Panel} from '@ace-de/ui-components';
import {Form, InputField, InputPhoneNumberField, RadioButtonGroup, RadioButton} from '@ace-de/ui-components/form';
import ContactPersonDetailsPanel from '../service-assignments/ui-elements/ContactPersonDetailsPanel';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as salaActionTypes from './salaActionTypes';
import mapMemberDataToContactPersonData from '../service-cases/mapMemberDataToContactPersonData';

const isObjectWithKeys = obj => (typeof obj === 'object' && !!Object.keys(obj).length);

const SALARequirementsTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sala_requirements_tab');
    const {serviceCase, serviceAssignment, submitSALARequirementsForm, saveSALAServiceLocation} = props;
    const [formData, setFormData] = useState({
        // set initial form state in order to patch default values if an agent does not touch the form
        contactPersonOnSite: serviceAssignment?.contactPersonOnSite
            || mapMemberDataToContactPersonData(serviceCase.member),
        affectedPerson: serviceAssignment?.affectedPerson || mapMemberDataToContactPersonData(serviceCase.member),
    });

    const handleOnChange = formValues => {
        setFormData({
            ...formValues,
            ...(isObjectWithKeys(formValues['contactPersonOnSite']) && {
                contactPersonOnSite: {
                    ...(formValues['contactPersonOnSite']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['contactPersonOnSite']),
                    isIdenticalToMember: !!formValues['contactPersonOnSite']['isIdenticalToMember'],
                },
            }),
            ...(isObjectWithKeys(formValues['affectedPerson']) && {
                affectedPerson: {
                    ...(formValues['affectedPerson']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['affectedPerson']),
                    isIdenticalToMember: !!formValues['affectedPerson']['isIdenticalToMember'],
                },
            }),
        });
    };

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        saveSALAServiceLocation({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            caseType: serviceCase.caseType,
            serviceCaseData: {
                // if it's an initial assignment, update damage location
                ...(isInitialAssignment && {
                    damage: {
                        location: {
                            ...(locationInfo.selectedLocation || {}),
                            locationNotes: locationInfo.locationNotes || '',
                        },
                    },
                }),
            },
            serviceAssignmentData: {
                serviceLocation: {
                    ...(locationInfo.selectedLocation || {}),
                    locationNotes: locationInfo.locationNotes || '',
                },
            },
        });
    };

    const handleOnTabChange = useCallback(formValues => {
        const {contactPersonOnSite, affectedPerson, legalDetailsData, legalEntitlementData} = formValues;
        submitSALARequirementsForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                ...(isObjectWithKeys(contactPersonOnSite)
                    ? {contactPersonOnSite}
                    : (!serviceAssignment.contactPersonOnSite
                        ? {contactPersonOnSite: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(affectedPerson)
                    ? {affectedPerson}
                    : (!serviceAssignment.affectedPerson
                        ? {affectedPerson: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(legalDetailsData) && legalDetailsData),
                ...(isObjectWithKeys(legalEntitlementData) && legalEntitlementData),
            },
        });
    }, [
        serviceCase.id,
        serviceAssignment.lineNo,
        serviceCase.member,
        serviceAssignment.contactPersonOnSite,
        serviceAssignment.affectedPerson,
        submitSALARequirementsForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: salaActionTypes.SUBMIT_SALA_REQUIREMENTS_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="salaRequirementsForm" onChange={handleOnChange}>
                    <ContactPersonDetailsPanel
                        title={translateTab('panel_title.local_contact')}
                        formName="contactPersonOnSite"
                        isIdenticalToMember={true}
                        contactPerson={serviceAssignment.contactPersonOnSite}
                    />
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.affected')}
                            formName="affectedPerson"
                            isIdenticalToMember={true}
                            hasDate={true}
                            contactPerson={serviceAssignment.affectedPerson}
                        />
                    </div>
                    <Panel title={translateTab('panel_title.details')}>
                        <Form name="legalDetailsData">
                            <div className={cx('global!ace-u-grid')}>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-7')}
                                    name="reason"
                                    value={serviceAssignment.reason || ''}
                                    label={translateTab('input_label.reason')}
                                />
                                <InputPhoneNumberField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="callbackPhoneNo"
                                    value={serviceAssignment.callbackPhoneNo || ''}
                                    label={translateTab('input_label.callback_number')}
                                />
                            </div>
                        </Form>
                    </Panel>
                    <Panel title={translateTab('panel_title.legal_entitlement')}>
                        <Form name="legalEntitlementData">
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            >
                                {translateTab('text.case_traffic_law')}
                            </div>
                            <RadioButtonGroup
                                name="legalClaim"
                                value={serviceAssignment.legalClaim}
                            >
                                <RadioButton
                                    className={cx('global!ace-u-margin--right-64')}
                                    name="legalClaimYes"
                                    value={true}
                                >
                                    {translateTab('radio_button_label.yes')}
                                </RadioButton>
                                <RadioButton
                                    name="legalClaimNo"
                                    value={false}
                                >
                                    {translateTab('radio_button_label.no')}
                                </RadioButton>
                            </RadioButtonGroup>
                        </Form>
                    </Panel>
                </Form>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    hasPreviousLocations={false}
                    mapName="sala-member-location"
                    handleLocationSaving={handleLocationSaving}
                    hasLocationNotes={true}
                    hasConfirmation={false}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SALARequirementsTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSALARequirementsForm: PropTypes.func.isRequired,
    saveSALAServiceLocation: PropTypes.func.isRequired,
};

SALARequirementsTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSALARequirementsForm: payload => dispatch({
        type: salaActionTypes.SUBMIT_SALA_REQUIREMENTS_FORM,
        payload,
    }),
    saveSALAServiceLocation: payload => dispatch({
        type: salaActionTypes.SUBMIT_SALA_SERVICE_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SALARequirementsTab));
