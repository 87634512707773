import {fork, all, takeLatest} from 'redux-saga/effects';
import createServiceAssignmentTrailerReturn from './sagas/createServiceAssignmentTrailerReturn';
import updateSATRRequirementsFlow from './sagas/updateSATRRequirementsFlow';
import searchSAVRPickupLocationGeolocation from './sagas/searchSAVRPickupLocationGeolocation';
import searchSAVRDestinationGeolocation from './sagas/searchSAVRDestinationGeolocation';
import updateSATRCostCoverageFlow from './sagas/updateSATRCostCoverageFlow';
import updateSATRServiceProviderFlow from './sagas/updateSATRServiceProviderFlow';
import * as satrActionTypes from './satrActionTypes';

/**
 * SATR watcher saga
 */
const satrWatcher = function* satrWatcher() {
    yield all([
        fork(createServiceAssignmentTrailerReturn),
        fork(updateSATRRequirementsFlow),
        takeLatest(
            satrActionTypes.SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION,
            searchSAVRPickupLocationGeolocation,
        ),
        takeLatest(
            satrActionTypes.SEARCH_SATR_DESTINATION_GEOLOCATION,
            searchSAVRDestinationGeolocation,
        ),
        fork(updateSATRCostCoverageFlow),
        fork(updateSATRServiceProviderFlow),
    ]);
};

export default satrWatcher;
