import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, NoResultsBlock, Icon, findCaseIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const ContractPartnerAdditionalLocationsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner} = props;
    const {operatingUnits} = contractPartner;

    return (
        <Panel
            title={translateTab('panel_title.additional_locations')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="contract-partner-additional-locations">
                {operatingUnits.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateTab('no_results.message')}
                            description={translateTab('no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="cp-additional-location-name">
                            {translateTab('additional_locations_table_cell.name')}
                        </TableCell>
                        <TableCell qaIdentPart="cp-additional-location-address">
                            {translateTab('additional_locations_table_cell.address')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {operatingUnits.length > 0 && operatingUnits.map(unit => {
                        return (
                            <TableRow
                                key={unit.id}
                                qaIdentPart={unit.id}
                            >
                                <TableCell
                                    qaIdentPart="cp-additional-location-name"
                                    qaIdentPartPostfix={unit.id}
                                >
                                    {unit.name}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="cp-additional-location-address"
                                    qaIdentPartPostfix={unit.id}
                                >
                                    {unit.location?.formattedAddress || ''}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

ContractPartnerAdditionalLocationsPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerAdditionalLocationsPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerAdditionalLocationsPanel));
