import {select} from 'redux-saga/effects';
import updateServiceCase from './updateServiceCase';

const sendAttachmentsWithAssignmentFlow = function* sendAttachmentsWithAssignmentFlow(params) {
    const {serviceCaseId, attachmentsToSendWithAssignment, caller} = params;
    const {serviceCases} = yield select(state => state.serviceCases);
    const serviceCase = serviceCases[serviceCaseId];

    yield* updateServiceCase({
        caller,
        caseType: serviceCase.caseType,
        serviceCaseId,
        serviceCaseData: {
            attachments: (serviceCase.attachments || []).map(attachment => (
                {
                    ...attachment,
                    sendWithAssignment: attachmentsToSendWithAssignment?.length
                        ? attachmentsToSendWithAssignment.includes(attachment.id) : false,
                }
            )),
        },
    });
};

export default sendAttachmentsWithAssignmentFlow;
