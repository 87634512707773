import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Update power of attorney channels
 */
const updatePowerOfAttorneyChannels = function* updatePowerOfAttorneyChannels() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_CHANNELS);

        const {
            assignmentType,
            serviceAssignmentLineNo,
            serviceAssignmentData,
            serviceCaseId,
        } = payload;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_CHANNELS,
            assignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_CHANNELS},
        });
    }
};

export default updatePowerOfAttorneyChannels;

