import {all, fork, takeLatest} from 'redux-saga/effects';
import createServiceAssignmentMedicalAdviceFlow from './sagas/createServiceAssignmentMedicalAdviceFlow';
import updateSAMARequirements from './sagas/updateSAMARequirements';
import updateSAMAServiceLocationFlow from './sagas/updateSAMAServiceLocationFlow';
import selectSAMAServiceProviderFlow from './sagas/selectSAMAServiceProviderFlow';
import searchSAMADoctorLocations from './sagas/searchSAMADoctorLocations';
import samaMapDoctorLocationWatcher from './sagas/samaMapDoctorLocationWatcher';
import * as samaActionTypes from './samaActionTypes';
import updateSAMACommissioningFlow from './sagas/updateSAMACommissioningFlow';
import searchSAMAServiceProviders from './sagas/searchSAMAServiceProviders';
import samaMapServiceProviderWatcher from './sagas/samaMapServiceProviderWatcher';

/**
 *  SAMA watcher saga
 */
const samaWatcher = function* samaWatcher() {
    yield all([
        fork(createServiceAssignmentMedicalAdviceFlow),
        fork(updateSAMARequirements),
        fork(updateSAMAServiceLocationFlow),
        fork(selectSAMAServiceProviderFlow),
        fork(samaMapDoctorLocationWatcher),
        fork(updateSAMACommissioningFlow),
        fork(searchSAMAServiceProviders),
        fork(samaMapServiceProviderWatcher),
        takeLatest(samaActionTypes.SEARCH_SAMA_DOCTOR_LOCATIONS, searchSAMADoctorLocations),
    ]);
};

export default samaWatcher;
