import {all, fork} from 'redux-saga/effects';
import createServiceAssignmentSickPersonFlow from './sagas/createServiceAssignmentSickPersonFlow';
import updateSASPCommissioningFlow from './sagas/updateSASPCommissioningFlow';
import updateSASPServiceProviderFlow from './sagas/updateSASPServiceProviderFlow';

/**
 *  SASP watcher saga
 */
const saspWatcher = function* saspWatcher() {
    yield all([
        fork(createServiceAssignmentSickPersonFlow),
        fork(updateSASPCommissioningFlow),
        fork(updateSASPServiceProviderFlow),
    ]);
};

export default saspWatcher;
