import {take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes, efServiceTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as sarcActionTypes from '../../service-assignments-rental-car/sarcActionTypes';
import * as saaActionTypes from '../../service-assignments-accommodation/saaActionTypes';
import * as sastActionTypes from '../../service-assignments-short-trip/sastActionTypes';
import * as sarjActionTypes from '../../service-assignments-return-journey/sarjActionTypes';
import * as saspActionTypes from '../../service-assignments-sick-person/saspActionTypes';
import * as savrActionTypes from '../../service-assignments-vehicle-return/savrActionTypes';
import * as savaActionTypes from '../../service-assignments-vehicle-additional/savaActionTypes';
import * as saosActionTypes from '../../service-assignments-other-services/saosActionTypes';
import * as sapActionTypes from '../../service-assignments-pickup/sapActionTypes';
import * as satrActionTypes from '../../service-assignments-trailer-return/satrActionTypes';
import * as sadehActionTypes from '../../service-assignments-death-event-help/sadehActionTypes';
import * as salaActionTypes from '../../service-assignments-legal-advice/salaActionTypes';
import * as sasdActionTypes from '../../service-assignments-substitute-driver/sasdActionTypes';
import * as samcActionTypes from '../../service-assignments-medical-clearance/samcActionTypes';

/**
 * Create service assignment flow
 */
const createServiceAssignmentFlow = function* selectConnectionServicesFlow() {
    while (true) {
        yield take([serviceAssignmentActionTypes.INITIATE_SELECT_ADDITIONAL_SERVICES_FLOW]);

        yield* openModal(modalIds.ADDITIONAL_SERVICES);
        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_SELECT_ADDITIONAL_SERVICES,
            serviceAssignmentActionTypes.DECLINE_SELECT_ADDITIONAL_SERVICES,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_SELECT_ADDITIONAL_SERVICES) {
            const {serviceCaseId, assignmentType} = chosenModalOption.payload;

            switch (assignmentType) {
                case efServiceAssignmentTypes.RENTAL_CAR: {
                    yield put({
                        type: sarcActionTypes.CREATE_SERVICE_ASSIGNMENT_RENTAL_CAR,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.ACCOMMODATION: {
                    yield put({
                        type: saaActionTypes.CREATE_SERVICE_ASSIGNMENT_ACCOMMODATION,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.SHORT_TRIP: {
                    yield put({
                        type: sastActionTypes.CREATE_SERVICE_ASSIGNMENT_SHORT_TRIP,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.RETURN_JOURNEY: {
                    yield put({
                        type: sarjActionTypes.CREATE_SERVICE_ASSIGNMENT_RETURN_JOURNEY,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.SICK_PERSON: {
                    yield put({
                        type: saspActionTypes.CREATE_SERVICE_ASSIGNMENT_SICK_PERSON,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.VEHICLE_RETURN: {
                    yield put({
                        type: savrActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_RETURN,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.OTHER_SERVICES: {
                    yield put({
                        type: saosActionTypes.CREATE_SERVICE_ASSIGNMENT_OTHER_SERVICES,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.PICKUP: {
                    yield put({
                        type: sapActionTypes.CREATE_SERVICE_ASSIGNMENT_PICKUP,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.TRAILER_RETURN: {
                    yield put({
                        type: satrActionTypes.CREATE_SERVICE_ASSIGNMENT_TRAILER_RETURN,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceTypes.TOWING:
                case efServiceTypes.ROADSIDE_ASSISTANCE:
                case efServiceTypes.RECOVERY:
                case efServiceTypes.VEHICLE_OPENING: {
                    yield put({
                        type: savaActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                        payload: {serviceCaseId, serviceType: assignmentType},
                    });
                    break;
                }

                case efServiceAssignmentTypes.DEATH: {
                    yield put({
                        type: sadehActionTypes.CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.LEGAL_ADVICE: {
                    yield put({
                        type: salaActionTypes.CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.SUBSTITUTE_DRIVER: {
                    yield put({
                        type: sasdActionTypes.CREATE_SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                case efServiceAssignmentTypes.MEDICAL_CLEARANCE: {
                    yield put({
                        type: samcActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE,
                        payload: {serviceCaseId},
                    });
                    break;
                }

                default: {
                    // no-op
                }
            }
        }

        yield* closeModal(modalIds.ADDITIONAL_SERVICES);
    }
};

export default createServiceAssignmentFlow;
