import {select, take, put, fork} from 'redux-saga/effects';
import moment from 'moment/moment';
import {mapMemberTariffGroupsFromAMP, mapMemberStatusesFromAMP} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

const getBudgetsByServiceType = function* getBudgetsByServiceType() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.GET_BUDGETS_BY_SERVICE_TYPE);
        const {createdAt, serviceType, memberStatus, tariffGroup} = payload;

        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('validOn', moment(createdAt).format('YYYY-MM-DD'));
        searchQueryParams.append('serviceType', serviceType);
        searchQueryParams.append('memberStatus', mapMemberStatusesFromAMP(memberStatus));
        searchQueryParams.append('tariffGroup', mapMemberTariffGroupsFromAMP(tariffGroup));

        yield fork(
            fetchRequest,
            serviceAssignmentActionTypes.FETCH_BUDGETS_BY_SERVICE_TYPE_REQUEST,
            pricingManagementService.getBudgets, {
                searchQueryParams,
            },
        );

        const responseAction = yield take([
            serviceAssignmentActionTypes.FETCH_BUDGETS_BY_SERVICE_TYPE_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.FETCH_BUDGETS_BY_SERVICE_TYPE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {budgetDTOs} = response;

            yield put({
                type: serviceAssignmentActionTypes.STORE_BUDGETS_BY_SERVICE_TYPE,
                payload: {budgetDTOs},
            });
        }
    }
};

export default getBudgetsByServiceType;
