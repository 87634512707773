import {put, select, take, fork, all} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {efLocationTypes} from '@ace-de/eua-entity-types';
import {v4 as uuidv4} from 'uuid';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import loadServiceCaseAssignments from './loadServiceCaseAssignments';
import updateServiceCase from './updateServiceCase';
import updateSCMemberResidenceGeolocation from './updateSCMemberResidenceGeolocation';
import loadSCMember from './loadSCMember';

const loadServiceCase = function* loadServiceCase({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const {serviceCases} = yield select(state => state.serviceCases);
    const {location} = yield select(state => state.router);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {match} = payload;
    const {serviceCaseId} = match.params;

    // exit if on a service assignment screen AND service case was previously loaded
    const serviceAssignmentMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_ASSIGNMENT,
        exact: true,
    });
    if (typeof serviceCases[serviceCaseId] !== 'undefined' && serviceAssignmentMatch) return;

    const caller = uuidv4();
    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST,
        ecsFlowService.getServiceCase,
        {serviceCaseId},
        {caller},
    );

    let responseAction = {};
    while (true) {
        responseAction = yield take([
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST_FAILED,
        ]);

        if (responseAction.meta?.caller && responseAction.meta.caller === caller) {
            break;
        }
    }

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {serviceCaseDTO} = response;

    yield put({
        type: serviceCaseActionTypes.STORE_SERVICE_CASES,
        payload: {serviceCaseDTOs: [serviceCaseDTO]},
    });

    // Ensure member residence geolocation and distance from damage location are calculated and
    // saved for service cases created through ACE MemberApp
    const {id, caseType, member: snapshotMemberDTO, nonAceMember} = serviceCaseDTO;
    const {personalDetails: personalDetailsDTO} = snapshotMemberDTO || {};
    if (!nonAceMember && !personalDetailsDTO.coordinates) {
        const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

        const {damage: damageDTO} = serviceCaseDTO || {};
        let {location: damageLocation} = damageDTO || {};
        const memberResidenceLocation = yield* updateSCMemberResidenceGeolocation({
            serviceCaseId: id,
            caseType,
            snapshotMemberDTO,
        });

        // when damage location was set from residence location it should be updated too
        if (damageLocation && damageLocation.locationType === efLocationTypes.RESIDENCE_PLACE) {
            damageLocation = memberResidenceLocation;
            damageLocation.locationType = efLocationTypes.RESIDENCE_PLACE;
        }

        if (memberResidenceLocation
            && damageLocation
            && damageLocation.longitude
            && damageLocation.latitude) {
            yield fork(
                fetchRequest,
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: damageLocation.longitude,
                        latitude: damageLocation.latitude,
                    },
                    destination: {
                        longitude: memberResidenceLocation.longitude,
                        latitude: memberResidenceLocation.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED,
            ]);

            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;

                serviceCaseDTO['distanceResidenceToDamageLocation'] = arcGISRouteDTO.totalKilometers;
                yield* updateServiceCase({
                    caller: serviceCaseActionTypes.FETCH_SERVICE_CASE_REQUEST,
                    caseType,
                    serviceCaseId,
                    serviceCaseData: {
                        ...(damageLocation.locationType === efLocationTypes.RESIDENCE_PLACE && {
                            damage: {location: damageLocation},
                        }),
                        distanceResidenceToDamageLocation: arcGISRouteDTO.totalKilometers,
                    },
                });
            }
        }
    }

    // load rest of data in parallel
    yield all([
        fork(loadServiceCaseAssignments, {
            serviceCaseId,
        }),
        fork(loadSCMember, {
            snapshotMemberDTO,
            nonAceMember,
        }),
    ]);
};

export default loadServiceCase;
