import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {NotificationBar, useStyles, useTabSet} from '@ace-de/ui-components';
import serviceCaseScreenTabs from '../serviceCaseScreenTabs';
import styles from './ToDoTasksBanner.module.scss';

const ToDoTasksBanner = props => {
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();
    const {goToTab} = useTabSet();
    const {description} = props;

    return (
        <NotificationBar type="primary">
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-margin--16-0',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-typography--variant-highlight',
                    'global!ace-u-cursor--pointer',
                ])}
                onClick={() => goToTab(serviceCaseScreenTabs.TASK_LIST)}
            >
                <span className={cx('ace-c-todo-tasks-banner__content')}>
                    {translate('global.todo_task_check.todo_task_notification', {
                        description,
                    })}
                </span>
            </div>
        </NotificationBar>
    );
};

ToDoTasksBanner.propTypes = {
    description: PropTypes.string,
};

ToDoTasksBanner.defaultProps = {
    description: '',
};

export default ToDoTasksBanner;
