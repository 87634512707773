import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Search service assignment final destination location geolocation
 */
const searchServiceAssignmentFinalDestinationLocationGeolocation = function* searchServiceAssignmentFinalDestinationLocationGeolocation({payload}) { // eslint-disable-line max-len
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId} = payload;
    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const responseActionType = yield take([
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!responseActionType.error) {
        const {response} = responseActionType.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_CANDIDATES,
            payload: {
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchServiceAssignmentFinalDestinationLocationGeolocation;
