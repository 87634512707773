import React, {Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, useScrollableBlock, Divider, RadioButton} from '@ace-de/ui-components';

const ServiceProviderItem = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sama_service_provider_tab');
    const {serviceProvider, isSelected} = props;
    const prevIsSelectedRef = useRef(false);
    const serviceProviderItemRef = useRef();
    const {scrollToChildRef} = useScrollableBlock();

    // on isSelected, scroll ScrollableBlock to element ref
    useEffect(() => {
        if (isSelected === true && prevIsSelectedRef.current === false) {
            scrollToChildRef(serviceProviderItemRef);
        }
        prevIsSelectedRef.current = isSelected;
    }, [isSelected, scrollToChildRef]);

    return (
        <Fragment>
            <div
                ref={serviceProviderItemRef}
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-margin--16-0',
                ])}
            >
                <RadioButton
                    id={`radio-button-${serviceProvider.id}`}
                    name={`radio-button-${serviceProvider.id}`}
                    value={serviceProvider.id}
                />
                <label
                    htmlFor={`radio-button-${serviceProvider.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-cursor--pointer',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <p className={cx('global!ace-u-margin--bottom-4')}>
                        {serviceProvider.name}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {serviceProvider.address?.formattedAddress || [
                            serviceProvider.address?.street,
                            serviceProvider.address?.postCode,
                            serviceProvider.address?.city,
                        ].filter(value => !!value).join(', ')}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                        {translateTab('radio_button_label.phone_no')}
                        &nbsp;
                        {serviceProvider.contactDetails?.phoneNo || ''}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {translateTab('radio_button_label.distance')}
                        &nbsp;
                        {Math.round(serviceProvider.arcGISData?.routeToReferentialPoint?.totalKilometers * 10) / 10}
                        &nbsp;
                        {translate('global.unit.km')}
                    </p>
                </label>
            </div>
            <Divider />
        </Fragment>
    );
};

ServiceProviderItem.propTypes = {
    serviceProvider: PropTypes.object,
    isSelected: PropTypes.bool,
};

ServiceProviderItem.defaultProps = {
    serviceProvider: {},
    isSelected: false,
};

export default ServiceProviderItem;
