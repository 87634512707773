/**
 * Command action types
 */
export const INITIATE_CREATE_CASE_LOG_VIEWED_FLOW = '[case-logs] INITIATE_CREATE_CASE_LOG_VIEWED_FLOW';
export const INITIATE_CREATE_CASE_LOG_LEFT_FLOW = '[case-logs] INITIATE_CREATE_CASE_LOG_LEFT_FLOW';
export const INITIATE_CREATE_CASE_LOG_NOTE_FLOW = '[case-logs] INITIATE_CREATE_CASE_LOG_NOTE_FLOW';
export const CONFIRM_CREATE_CASE_LOG_NOTE = '[case-logs] CONFIRM_CREATE_CASE_LOG_NOTE';
export const DECLINE_CREATE_CASE_LOG_NOTE = '[case-logs] DECLINE_CREATE_CASE_LOG_NOTE';
export const INITIATE_SENDING_MESSAGES_FLOW = '[case-logs] INITIATE_SENDING_MESSAGES_FLOW';
export const DECLINE_SEND_MESSAGE = '[case-logs] DECLINE_SEND_MESSAGE';
export const CONFIRM_SEND_MESSAGE = '[case-logs] CONFIRM_SEND_MESSAGE';
export const INITIATE_UPDATE_CASE_LOG_FLOW = '[case-logs] INITIATE_UPDATE_CASE_LOG_FLOW';
export const DECLINE_UPDATE_CASE_LOG_NOTE = '[case-logs] DECLINE_UPDATE_CASE_LOG_NOTE';
export const CONFIRM_UPDATE_CASE_LOG_NOTE = '[case-logs] CONFIRM_UPDATE_CASE_LOG_NOTE';
export const SUBMIT_NOTE_DOCUMENT_DOWNLOAD_FLOW = '[case-logs] SUBMIT_NOTE_DOCUMENT_DOWNLOAD_FLOW';
export const SUBMIT_CREATE_CASE_LOG_PDF = '[case-logs] SUBMIT_CREATE_CASE_LOG_PDF';

/**
 * Event action types
 */
export const FETCH_CASE_LOG_REQUEST = '[case-logs] FETCH_CASE_LOG_REQUEST';
export const FETCH_CASE_LOG_REQUEST_SENT = '[case-logs] FETCH_CASE_LOG_REQUEST_SENT';
export const FETCH_CASE_LOG_REQUEST_SUCCEEDED = '[case-logs] FETCH_CASE_LOG_REQUEST_SUCCEEDED';
export const FETCH_CASE_LOG_REQUEST_FAILED = '[case-logs] FETCH_CASE_LOG_REQUEST_FAILED';

export const CREATE_CASE_LOG_VIEWED_REQUEST = '[case-logs] CREATE_CASE_LOG_VIEWED_REQUEST';
export const CREATE_CASE_LOG_VIEWED_REQUEST_SENT = '[case-logs] CREATE_CASE_LOG_VIEWED_REQUEST_SENT';
export const CREATE_CASE_LOG_VIEWED_REQUEST_SUCCEEDED = '[case-logs] CREATE_CASE_LOG_VIEWED_REQUEST_SUCCEEDED';
export const CREATE_CASE_LOG_VIEWED_REQUEST_FAILED = '[case-logs] CREATE_CASE_LOG_VIEWED_REQUEST_FAILED';

export const CREATE_CASE_LOG_LEFT_REQUEST = '[case-logs] CREATE_CASE_LOG_LEFT_REQUEST';
export const CREATE_CASE_LOG_LEFT_REQUEST_SENT = '[case-logs] CREATE_CASE_LOG_LEFT_REQUEST_SENT';
export const CREATE_CASE_LOG_LEFT_REQUEST_SUCCEEDED = '[case-logs] CREATE_CASE_LOG_LEFT_REQUEST_SUCCEEDED';
export const CREATE_CASE_LOG_LEFT_REQUEST_FAILED = '[case-logs] CREATE_CASE_LOG_LEFT_REQUEST_FAILED';

export const CREATE_CASE_LOG_NOTE_REQUEST = '[case-logs] CREATE_CASE_LOG_NOTE_REQUEST';
export const CREATE_CASE_LOG_NOTE_REQUEST_SENT = '[case-logs] CREATE_CASE_LOG_NOTE_REQUEST_SENT';
export const CREATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED = '[case-logs] CREATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED';
export const CREATE_CASE_LOG_NOTE_REQUEST_FAILED = '[case-logs] CREATE_CASE_LOG_NOTE_REQUEST_FAILED';

export const FILTER_CASE_LOGS_REQUEST = '[service-cases] FILTER_CASE_LOGS_REQUEST';
export const FILTER_CASE_LOGS_REQUEST_SENT = '[service-cases] FILTER_CASE_LOGS_REQUEST_SENT';
export const FILTER_CASE_LOGS_REQUEST_SUCCEEDED = '[service-cases] FILTER_CASE_LOGS_REQUEST_SUCCEEDED';
export const FILTER_CASE_LOGS_REQUEST_FAILED = '[service-cases] FILTER_CASE_LOGS_REQUEST_FAILED';

export const UPDATE_CASE_LOG_NOTE_REQUEST = '[case-logs] UPDATE_CASE_LOG_NOTE_REQUEST';
export const UPDATE_CASE_LOG_NOTE_REQUEST_SENT = '[case-logs] UPDATE_CASE_LOG_NOTE_REQUEST_SENT';
export const UPDATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED = '[case-logs] UPDATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED';
export const UPDATE_CASE_LOG_NOTE_REQUEST_FAILED = '[case-logs] UPDATE_CASE_LOG_NOTE_REQUEST_FAILED';

export const DOWNLOAD_NOTE_DOCUMENT_REQUEST = '[case-logs] DOWNLOAD_NOTE_DOCUMENT_REQUEST';
export const DOWNLOAD_NOTE_DOCUMENT_REQUEST_SENT = '[case-logs] DOWNLOAD_NOTE_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_NOTE_DOCUMENT_REQUEST_SUCCEEDED = '[case-logs] DOWNLOAD_NOTE_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_NOTE_DOCUMENT_REQUEST_FAILED = '[case-logs] DOWNLOAD_NOTE_DOCUMENT_REQUEST_FAILED';

export const CREATE_CASE_LOG_PDF_REQUEST = '[case-logs] CREATE_CASE_LOG_PDF_REQUEST';
export const CREATE_CASE_LOG_PDF_REQUEST_SENT = '[case-logs] CREATE_CASE_LOG_PDF_REQUEST_SENT';
export const CREATE_CASE_LOG_PDF_REQUEST_SUCCEEDED = '[case-logs] CREATE_CASE_LOG_PDF_REQUEST_SUCCEEDED';
export const CREATE_CASE_LOG_PDF_REQUEST_FAILED = '[case-logs] CREATE_CASE_LOG_PDF_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_CASE_LOGS = '[case-logs] STORE_CASE_LOGS';
export const CLEAR_CASE_LOG_FILTER_RECORDS = '[case-logs] CLEAR_CASE_LOG_FILTER_RECORDS';
export const SET_ERROR_CODE = '[case-logs] SET_ERROR_CODE';
export const RESET_ERROR_CODE = '[case-logs] RESET_ERROR_CODE';
