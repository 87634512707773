import {take, put, select, fork} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';

/**
 * Update ServiceCase
 */
const updateServiceCase = function* updateServiceCase(params) {
    const {caller, caseType, serviceCaseId, serviceCaseData} = params;
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);
    const isCaseUpdateAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.UPDATE,
    );
    const isCaseLockUpdateAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.CASE_LOCK,
        ecsFeatureActions.UPDATE,
    );

    if (!serviceCase || !isCaseUpdateAvailable || !isCaseLockUpdateAvailable) return;

    const lockUUID = serviceCase.lock ? serviceCase.lock.lockUUID : null;

    // call lock endpoint
    yield fork(fetchRequest, serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST, ecsFlowService.lockServiceCase, {
        serviceCaseId,
        lockData: {lockUUID: lockUUID || uuidv4()},
    }, {
        caller,
    });

    let lockResponse = {};
    while (true) {
        lockResponse = yield take([
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_FAILED,
        ]);

        if (lockResponse.meta?.caller && lockResponse.meta.caller === caller) {
            break;
        }
    }

    // if case is not locked or case is lockedBy current user -> allow patch
    if (!lockResponse.error && lockResponse.type === serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED) {
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_REMAINING_LOCK_TIME,
            payload: {remainingLockTime: config.DEFAULT_SERVICE_CASE_LOCK_TIME},
        });

        yield put({
            type: serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE,
            payload: {caseType, serviceCaseId, serviceCaseData},
            meta: {caller},
        });

        let batchUpdateServiceCaseResponse;
        while (true) {
            const batchUpdateServiceCaseAction = yield take(
                serviceCaseActionTypes.BATCH_UPDATE_SERVICE_CASE_COMPLETED,
            );
            const {meta = {}} = batchUpdateServiceCaseAction;
            if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;

            batchUpdateServiceCaseResponse = batchUpdateServiceCaseAction;
            break;
        }

        return batchUpdateServiceCaseResponse;
    }

    return lockResponse;
};

export default updateServiceCase;
