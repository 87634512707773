import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageVehicleDetailsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('savr_cost_coverage_tab');
    const {serviceAssignment} = props;

    return (
        <Panel
            title={translateTab('panel_title.vehicle_details')}
            qaIdent="savr-cost-coverage-vehicle-details"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.key_at_vehicle')}
                        qaIdent="sa-key-at-vehicle"
                    >
                        {serviceAssignment.keyAtVehicle
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.vehicle_registration_at_vehicle')}
                        qaIdent="sa-vehicle-registration-at-vehicle"
                    >
                        {serviceAssignment.vehicleRegistrationAtVehicle
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.reason')}
                        qaIdent="sa-reason"
                    >
                        {serviceAssignment.reason || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.rollable')}
                        qaIdent="sa-rollable"
                    >
                        {serviceAssignment.rollable
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageVehicleDetailsPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageVehicleDetailsPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageVehicleDetailsPanel));
