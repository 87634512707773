import React, {Fragment, useContext} from 'react';
import {Parser} from 'html-to-react';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMembershipTypes} from '@ace-de/eua-entity-types';
import {Panel, Option, useStyles} from '@ace-de/ui-components';
import {SearchBox} from '@ace-de/ui-components/text-inputs';
import {Icon, personIcon, officeIcon} from '@ace-de/ui-components/icons';
import MemberSearchContext from '../../members/MemberSearchContext';
import routePaths from '../../routePaths';
import config from '../../config';

const HTMLParser = new Parser();

const escapeRegExp = searchQueryString => {
    if (!searchQueryString || typeof searchQueryString !== 'string') {
        return '';
    }

    return searchQueryString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const MemberSearchPanel = () => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {searchQueryString, setSearchQueryString} = useContext(MemberSearchContext);
    const {
        isSearchRouteActive,
        searchResults,
        openSearchResultsScreen,
        areSearchResultsValid,
    } = useContext(MemberSearchContext);

    const redirectToMember = member => {
        history.push(resolveRoute(routePaths.MEMBER, {
            membershipNo: member.membershipNo,
        }));
    };

    const highlightTerm = data => {
        if (searchQueryString.length < config.MINIMUM_SEARCH_QUERY_LENGTH) return '';
        let searchTermRegex = new RegExp(escapeRegExp(searchQueryString), 'ig');
        const multiWordSearchQueryString = searchQueryString.replace(/\s+/g, ' ').trim().split(' ');
        if (multiWordSearchQueryString.length > 1) {
            // multi-word string
            let multiWordPattern = '';
            multiWordSearchQueryString.forEach((word, idx) => {
                multiWordPattern = multiWordPattern.concat(word);
                if (idx !== multiWordSearchQueryString.length - 1) {
                    multiWordPattern = multiWordPattern.concat('|');
                }
            });
            searchTermRegex = new RegExp(multiWordPattern, 'ig');
        }
        let newResult = '';
        let highlightedParts;
        let lastIndex = 0;

        while ((highlightedParts = searchTermRegex.exec(data)) !== null) {
            if (highlightedParts[0]) {
                newResult = newResult.concat(data.substring(lastIndex, highlightedParts.index));
                newResult = newResult.concat(
                    '<em class="ace-u-typography--color-highlighted ace-u-typography--variant-body-bold">'
                    + highlightedParts[0]
                    + '</em>',
                );
                lastIndex = searchTermRegex.lastIndex;
            }
        }
        if (lastIndex !== data.length - 1) {
            newResult = newResult.concat(data.substring(lastIndex));
        }

        return HTMLParser.parse(newResult);
    };

    return (
        <Panel
            contentClassName={cx('global!ace-u-flex')}
            title={translateScreen('search_box_panel.title')}
        >
            {!isSearchRouteActive ? (
                <SearchBox
                    name="memberSearchBox"
                    className={cx('global!ace-u-flex--grow-1')}
                    value={searchQueryString}
                    onChange={setSearchQueryString}
                    onSearchSubmit={openSearchResultsScreen}
                    onOptionSelect={redirectToMember}
                    isDropDownDisabled={!areSearchResultsValid}
                    placeholder={translateScreen('search_box_placeholder.search')}
                >
                    {areSearchResultsValid && searchResults.length > 0 ? (
                        <Fragment>
                            {searchResults.slice(0, 10).map(member => {
                                const {personalDetails} = member;
                                const {address} = personalDetails;
                                const optionLabel = `${member.membershipNo} ${personalDetails.displayName} ${address.street} ${address.postCode} ${address.city}`;

                                return (
                                    <Option
                                        key={member.id}
                                        name={`member-${member.id}`}
                                        value={member}
                                    >
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <Icon
                                                icon={member.membershipType === ampMembershipTypes.PRIVATE
                                                    ? personIcon : officeIcon}
                                                className={cx('global!ace-u-margin--right-16')}
                                            />
                                            <span>
                                                {highlightTerm(optionLabel)}
                                            </span>
                                        </div>
                                    </Option>
                                );
                            })}
                        </Fragment>
                    ) : null}

                    {areSearchResultsValid && searchResults.length <= 0 ? (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography--color-default',
                                'global!ace-u-padding--0-16',
                            ])}
                        >
                            {translateScreen('search_box_results.no_results_message')}
                        </div>
                    ) : null}
                </SearchBox>
            ) : null}
        </Panel>
    );
};

export default MemberSearchPanel;
