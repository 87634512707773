import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useStyles, HighlightCircle, Panel, ContentItem, Icon, warningIcon, LoaderSpinner} from '@ace-de/ui-components';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const CurrentUserWorkloadTile = props => {
    const {cx} = useStyles();
    const {amount, status, teamMemberRole, onClick, hasWarning} = props;
    const {isPending, hasLoadingError, translationKey} = props;

    return (
        <ContentItem className={cx('global!ace-u-grid-column--span-3')}>
            <Panel
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-full-height',
                    'global!ace-u-cursor--pointer',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-typography--align-center',
                    ])}
                    onClick={isPending ? () => {} : onClick}
                >
                    {isPending && (
                        <LoaderSpinner />
                    )}
                    {!!hasLoadingError && !isPending && (
                        <FailedRequestBlock translationKey={translationKey} />
                    )}
                    {!isPending && !hasLoadingError && (
                        <HighlightCircle>
                            {hasWarning
                                ? (
                                    <Fragment>
                                        {amount}
                                        <Icon
                                            icon={warningIcon}
                                            className={cx([
                                                'global!ace-u-position--absolute',
                                                'global!ace-u-position--top-neg-4',
                                                'global!ace-u-position--right-neg-4',
                                                'ace-c-icon--m',
                                                'ace-c-icon--color-warning',
                                            ])}
                                        />
                                    </Fragment>
                                ) : amount}
                        </HighlightCircle>
                    )}
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-default',
                            'global!ace-u-margin--24-0-8',
                        ])}
                    >
                        {status}
                    </p>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-highlight',
                            'global!ace-u-typography--color-medium-emphasis',
                        ])}
                    >
                        {teamMemberRole}
                    </p>
                </div>
            </Panel>
        </ContentItem>
    );
};

CurrentUserWorkloadTile.propTypes = {
    amount: PropTypes.number,
    status: PropTypes.string.isRequired,
    teamMemberRole: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    hasWarning: PropTypes.bool,
    isPending: PropTypes.bool.isRequired,
    hasLoadingError: PropTypes.bool,
    translationKey: PropTypes.string,
};

CurrentUserWorkloadTile.defaultProps = {
    amount: 0,
    hasWarning: false,
    hasLoadingError: false,
    translationKey: '',
};

export default CurrentUserWorkloadTile;
