import {ehmCaseLogMessageTopics, ehmCaseLogMessageTextTemplates} from '@ace-de/eua-entity-types';

export default {
    [ehmCaseLogMessageTopics.FEEDBACK_CASE_RECEIVED]: [
        ehmCaseLogMessageTextTemplates.MESSAGE_ETA,
        ehmCaseLogMessageTextTemplates.MISSING_DAMAGE_LOCATION_INFORMATION,
        ehmCaseLogMessageTextTemplates.MISSING_VEHICLE_DATA,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_CASE_INFORMATION]: [
        ehmCaseLogMessageTextTemplates.CUSTOMER_NOT_ON_SITE,
        ehmCaseLogMessageTextTemplates.CANCELLATION_CLOSE_CASE,
        ehmCaseLogMessageTextTemplates.CANCELLATION_EMPTY_TRIP,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_ROADSIDE_ASSISTANCE_SUCCESSFUL]: [
        ehmCaseLogMessageTextTemplates.ROADSIDE_ASSISTANCE_SUCCESSFUL,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_ROADSIDE_ASSISTANCE_NOT_SUCCESSFUL]: [
        ehmCaseLogMessageTextTemplates.TOWING_WORKSHOP,
        ehmCaseLogMessageTextTemplates.TOWING_DEPOT,
        ehmCaseLogMessageTextTemplates.TOWING_WORKSHOP_CLOSED,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_ASSIGNMENT_RECEIVED]: [
        ehmCaseLogMessageTextTemplates.RECEIVED_CONTRACT,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_WORKSHOP]: [
        ehmCaseLogMessageTextTemplates.DIAGNOSIS_EXISTS,
        ehmCaseLogMessageTextTemplates.MISSING_DIAGNOSTIC_JOB,
        ehmCaseLogMessageTextTemplates.SCHEDULE_DIAGNOSIS,
        ehmCaseLogMessageTextTemplates.REPAIR_COMPLETE,
        ehmCaseLogMessageTextTemplates.CONTACT_PERSON_NOT_REACHED,
        ehmCaseLogMessageTextTemplates.REPAIR_COMPLETED_AND_PICKED_UP,
        ehmCaseLogMessageTextTemplates.SPARE_PART_MISSING,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_RENTAL_CAR]: [
        ehmCaseLogMessageTextTemplates.MISSING_INFORMATION,
        ehmCaseLogMessageTextTemplates.RENTAL_CAR_COSTS,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_TAXI]: [
        ehmCaseLogMessageTextTemplates.MISSING_INFORMATION,
        ehmCaseLogMessageTextTemplates.TAXI_COSTS,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_TOWING]: [
        ehmCaseLogMessageTextTemplates.TOWING_DESTINATION,
    ],
    [ehmCaseLogMessageTopics.FEEDBACK_CLOSE_CASE]: [
        ehmCaseLogMessageTextTemplates.CLOSE_CASE,
    ],
    [ehmCaseLogMessageTopics.ENQUIRY_REGARDING_UPDATE_WORKSHOP]: [
        ehmCaseLogMessageTextTemplates.WORKSHOP_INFORMATION,
        ehmCaseLogMessageTextTemplates.DIAGNOSIS_DURATION_COSTS_INFORMATION,
    ],
    [ehmCaseLogMessageTopics.UPDATE_FOR_CASE]: [
        ehmCaseLogMessageTextTemplates.UPDATE_VEHICLE_RETURN_TRANSPORT,
    ],
    [ehmCaseLogMessageTopics.UPDATE_FOR_CASE_DOCUMENTS]: [
        ehmCaseLogMessageTextTemplates.DOCUMENTS,
    ],
    [ehmCaseLogMessageTopics.ENQUIRY_UPDATE_REGARDING_INITIAL_ASSIGNMENT]: [
        ehmCaseLogMessageTextTemplates.UPDATE_CASE,
    ],
};
