import React, {Fragment, useMemo, useRef, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efLocationTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ContentBlock, ContentItem, Divider, Pill} from '@ace-de/ui-components';
import {Option, Form, Checkbox, InputField, InputPhoneNumberField, AutosuggestField, SelectField, NumberInputField} from '@ace-de/ui-components/form';
import {Icon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import PowerOfAttorneyPanel from '../service-assignments/ui-elements/PowerOfAttorneyPanel';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as satrActionTypes from './satrActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';

const SATRRequirementsTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('satr_requirements_tab');
    const {serviceCase, serviceAssignment, submitSATRRequirementsForm, submitPowerOfAttorneyForm} = props;
    const {searchSATRPickupLocationGeolocation, searchSATRDestinationGeolocation} = props;
    const {calculatePickupLocationToDestinationDistance, serviceAssignments} = props;
    const {resetSATRPickupLocationCandidates, resetSATRDestinationCandidates} = props;
    const [formData, setFormData] = useState(serviceAssignment);
    const [newPickupLocation, setNewPickupLocation] = useState(null);
    const [newDestination, setNewDestination] = useState(null);
    const isFormTouched = useRef(false);
    const currentPickupLocationAddress = useRef('');
    const lastPickupLocationSearchQuery = useRef('');
    const searchSATRPickupLocationGeolocationDebounced = useMemo(
        () => debounce(searchSATRPickupLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSATRPickupLocationGeolocation],
    );
    const currenDestinationAddress = useRef('');
    const lastDestinationSearchQuery = useRef('');
    const searchSATRDestinationGeolocationDebounced = useMemo(
        () => debounce(searchSATRDestinationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSATRDestinationGeolocation],
    );

    const vehicleServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.VEHICLE)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    const rentalCarServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.RENTAL_CAR)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    const accommodationServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.ACCOMMODATION)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            assignmentText: serviceAssignment?.assignmentText || '',
            agreedPrice: serviceAssignment?.agreedPrice || '',
            callbackPhoneNo: serviceAssignment?.callbackPhoneNo || '',
            additionalInformationText: serviceAssignment?.additionalInformationText || '',
        }));
    }, [
        serviceAssignment?.callbackPhoneNo,
        serviceAssignment?.additionalInformationText,
        serviceAssignment?.assignmentText,
        serviceAssignment?.agreedPrice,
    ]);

    useEffect(() => {
        if (!serviceAssignment?.pickupLocation) return;

        if (serviceAssignment.pickupLocation) {
            setNewPickupLocation(serviceAssignment.pickupLocation);
        }
    }, [serviceAssignment?.pickupLocation]);

    useEffect(() => {
        if (!serviceAssignment?.destination) return;

        if (serviceAssignment.destination) {
            setNewDestination(serviceAssignment.destination);
        }
    }, [serviceAssignment?.destination]);

    useEffect(() => {
        if (newDestination && newPickupLocation) {
            // calculate the distance between selected destination and pickup location
            calculatePickupLocationToDestinationDistance({
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                destination: newDestination,
                pickupLocation: newPickupLocation,
            });
        }
    }, [
        newDestination,
        newPickupLocation,
        calculatePickupLocationToDestinationDistance,
        serviceAssignment?.lineNo,
        serviceAssignment?.serviceCaseId,
    ]);

    const handlePickupLocationSearchQueryChange = searchQueryString => {
        if (!searchQueryString
            && searchQueryString.length <= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            setNewPickupLocation(null);
        }

        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentPickupLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSATRPickupLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastPickupLocationSearchQuery.current = searchQueryString;
        }
    };

    const handlePickupLocationCandidateSelect = pickupLocationCandidate => {
        currentPickupLocationAddress.current = pickupLocationCandidate.formattedAddress;
        setNewPickupLocation(pickupLocationCandidate);
    };

    const setNewLocationFromFinalDestination = useCallback((newLocationType, setNewLocation) => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.finalDestination?.location)[newLocationType.replace(`${efLocationTypes.FINAL_DESTINATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.finalDestination?.location) {
            const location = vehicleServiceAssignment.finalDestination.location
                .setLocationType(efLocationTypes.FINAL_DESTINATION);
            setNewLocation(location);
        }
    }, [vehicleServiceAssignments]);

    const setNewLocationFromTowingDestination = useCallback((newLocationType, setNewLocation) => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.towingDestination)[newLocationType.replace(`${efLocationTypes.TOWING_DESTINATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.towingDestination) {
            const location = vehicleServiceAssignment.towingDestination
                .setLocationType(efLocationTypes.TOWING_DESTINATION);
            setNewLocation(location);
        }
    }, [vehicleServiceAssignments]);

    const setNewLocationFromContractPartnerLocation = useCallback((newLocationType, setNewLocation) => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)[newLocationType.replace(`${efLocationTypes.CONTRACT_PARTNER_LOCATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.acePartner?.location) {
            const location = vehicleServiceAssignment.acePartner.location
                .setLocationType(efLocationTypes.CONTRACT_PARTNER_LOCATION);
            setNewLocation(location?.formattedAddress
                ? location
                // if there is no formattedAddress, set it manually
                : {
                    ...location,
                    formattedAddress: [
                        location?.street,
                        location?.postCode,
                        location?.city,
                        location?.country,
                    ].filter(value => !!value).join(', '),
                });
        }
    }, [vehicleServiceAssignments]);

    const setNewLocationFromRentalCarPickupLocation = useCallback((newLocationType, setNewLocation) => {
        const rentalCarServiceAssignment = rentalCarServiceAssignments.length === 1 ? rentalCarServiceAssignments[0]
            : rentalCarServiceAssignments.filter(assignment => !!assignment.rentalCarStationPickup?.location)[newLocationType.replace(`${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} `, '') - 1];

        if (rentalCarServiceAssignment && rentalCarServiceAssignment.rentalCarStationPickup?.location) {
            const location = rentalCarServiceAssignment.rentalCarStationPickup.location
                .setLocationType(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION);
            setNewLocation(location);
        }
    }, [rentalCarServiceAssignments]);

    const setNewLocationFromRentalCarDropOffLocation = useCallback((newLocationType, setNewLocation) => {
        const rentalCarServiceAssignment = rentalCarServiceAssignments.length === 1 ? rentalCarServiceAssignments[0]
            : rentalCarServiceAssignments.filter(assignment => !!assignment.rentalCarStationDropOff?.location)[newLocationType.replace(`${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} `, '') - 1];

        if (rentalCarServiceAssignment && rentalCarServiceAssignment.rentalCarStationDropOff?.location) {
            const location = rentalCarServiceAssignment.rentalCarStationDropOff.location
                .setLocationType(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION);
            setNewLocation(location);
        }
    }, [rentalCarServiceAssignments]);

    const setNewLocationFromHotelLocation = useCallback((newLocationType, setNewLocation) => {
        const accommodationServiceAssignment = accommodationServiceAssignments.length === 1
            ? accommodationServiceAssignments[0]
            : accommodationServiceAssignments.filter(assignment => !!assignment.acePartner?.location)[newLocationType.replace(`${efLocationTypes.HOTEL_LOCATION} `, '') - 1];

        if (accommodationServiceAssignment && accommodationServiceAssignment.acePartner?.location) {
            // TODO: fix accommodation selection flow: service provider.location must be an entity!
            const location = {
                ...accommodationServiceAssignment.acePartner.location,
                locationType: efLocationTypes.HOTEL_LOCATION,
            };
            setNewLocation(location);
        }
    }, [accommodationServiceAssignments]);

    const handleLocationTypeChange = useCallback((newLocationType, setNewLocation, isDestination = false) => {
        if (newLocationType.includes(efLocationTypes.FINAL_DESTINATION)) {
            setNewLocationFromFinalDestination(newLocationType, setNewLocation);
            return;
        }

        if (newLocationType.includes(efLocationTypes.TOWING_DESTINATION)) {
            setNewLocationFromTowingDestination(newLocationType, setNewLocation);
            return;
        }

        if (newLocationType.includes(efLocationTypes.CONTRACT_PARTNER_LOCATION)) {
            setNewLocationFromContractPartnerLocation(newLocationType, setNewLocation);
            return;
        }

        if (newLocationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION)) {
            setNewLocationFromRentalCarPickupLocation(newLocationType, setNewLocation);
            return;
        }

        if (newLocationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION)) {
            setNewLocationFromRentalCarDropOffLocation(newLocationType, setNewLocation);
            return;
        }

        if (newLocationType.includes(efLocationTypes.HOTEL_LOCATION)) {
            setNewLocationFromHotelLocation(newLocationType, setNewLocation);
            return;
        }

        switch (newLocationType) {
            case efLocationTypes.DAMAGE_LOCATION: {
                const {damage} = serviceCase;
                if (damage && damage.location) {
                    const location = damage.location.setLocationType(efLocationTypes.DAMAGE_LOCATION);
                    setNewLocation(location);
                }
                break;
            }

            case efLocationTypes.RESIDENCE_PLACE: {
                const {member} = serviceCase;
                const {residenceLocation} = member;
                const location = residenceLocation.setLocationType(efLocationTypes.RESIDENCE_PLACE);
                setNewLocation(location);
                break;
            }

            case efLocationTypes.SELECT_LOCATION: {
                if (isDestination) {
                    if (serviceAssignment?.destination) {
                        const location = serviceAssignment.destination.setLocationType(efLocationTypes.SELECT_LOCATION);
                        setNewLocation(location);
                    }
                    resetSATRDestinationCandidates({
                        serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                    });
                    break;
                }

                if (serviceAssignment.pickupLocation) {
                    const location = serviceAssignment.pickupLocation.setLocationType(efLocationTypes.SELECT_LOCATION);
                    setNewLocation(location);
                }
                resetSATRPickupLocationCandidates({
                    serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                });
                break;
            }

            default:
            // no-op
        }
    }, [
        serviceCase,
        serviceAssignment?.destination,
        serviceAssignment?.pickupLocation,
        setNewLocationFromFinalDestination,
        setNewLocationFromTowingDestination,
        setNewLocationFromContractPartnerLocation,
        setNewLocationFromRentalCarPickupLocation,
        setNewLocationFromRentalCarDropOffLocation,
        setNewLocationFromHotelLocation,
        serviceAssignment?.serviceCaseId,
        serviceAssignment?.lineNo,
        resetSATRPickupLocationCandidates,
        resetSATRDestinationCandidates,
    ]);

    const handleDestinationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currenDestinationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSATRDestinationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastDestinationSearchQuery.current = searchQueryString;
        }
    };

    const handleDestinationCandidateSelect = destinationCandidate => {
        currenDestinationAddress.current = destinationCandidate.formattedAddress;
        setNewDestination(destinationCandidate);
    };

    const handleOnChange = formValues => {
        if (!isFormTouched.current) isFormTouched.current = true;
        setFormData({
            ...formValues,
            rollable: !!formValues.rollable,
            keyAtTrailer: !!formValues.keyAtTrailer,
            trailerRegistrationAtTrailer: !!formValues.trailerRegistrationAtTrailer,
            luggage: !!formValues.luggage,
            valuableThings: !!formValues.valuableThings,
            bicycleCarrier: !!formValues.bicycleCarrier,
        });
    };

    const handleOnTabChange = useCallback(formData => {
        const {assignmentType, sendPowerAttorneyViaEmail, sendPowerAttorneyViaFax,
            sendPowerAttorneyViaPost, pickupLocationName, destinationName, ...restFormData} = formData;
        submitSATRRequirementsForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                ...restFormData,
                pickupLocation: {
                    ...newPickupLocation,
                    locationName: pickupLocationName,
                },
                destination: {
                    ...newDestination,
                    locationName: destinationName,
                },
                routeDistance: newPickupLocation && newDestination
                && serviceAssignment.pickupLocationToDestinationDistance
                    ? Math.round(serviceAssignment.pickupLocationToDestinationDistance)
                    : restFormData.routeDistance,
            },
        });
    }, [
        serviceCase?.id,
        serviceAssignment?.lineNo,
        submitSATRRequirementsForm,
        newPickupLocation,
        newDestination,
        serviceAssignment?.pickupLocationToDestinationDistance,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData || !isFormTouched.current) {
            completeRouteUnmountSideEffect({
                caller: satrActionTypes.SUBMIT_SATR_REQUIREMENTS_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    const handleOnSend = powerOfAttorneyData => {
        const {serviceAssignmentData, serviceAssignmentPowerOfAttorneyData} = powerOfAttorneyData;
        submitPowerOfAttorneyForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                ...serviceAssignmentData,
                pickupLocation: {
                    ...newPickupLocation,
                    locationName: formData.pickupLocationName,
                },
                pickupLocationPhoneNo: formData.pickupLocationPhoneNo,
                destination: {
                    ...newDestination,
                    locationName: formData.destinationName,
                },
                destinationPhoneNo: formData.destinationPhoneNo,
                stallFee: formData.stallFee,
            },
            serviceAssignmentPowerOfAttorneyData,
        });
    };

    if (!serviceCase || !serviceAssignment) return null;

    const {pickupLocationCandidates, pickupLocationSearchQuery} = serviceAssignment;
    const {destinationCandidates, destinationSearchQuery} = serviceAssignment;
    const {pickupLocationToDestinationDistance} = serviceAssignment;

    const memberLocationTypeOptions = [
        ...(serviceCase.damage.location.formattedAddress
            ? [efLocationTypes.DAMAGE_LOCATION]
            : []),
        ...(serviceCase.member.residenceLocation.formattedAddress
            ? [efLocationTypes.RESIDENCE_PLACE]
            : []),
        efLocationTypes.SELECT_LOCATION,
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.towingDestination)
                .map((assignment, idx) => `${efLocationTypes.TOWING_DESTINATION} ${idx + 1}`)
            : (vehicleServiceAssignments[0]?.towingDestination ? [efLocationTypes.TOWING_DESTINATION] : [])),
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.finalDestination?.location)
                .map((assignment, idx) => `${efLocationTypes.FINAL_DESTINATION} ${idx + 1}`)
            : (vehicleServiceAssignments[0]?.finalDestination?.location
                ? [efLocationTypes.FINAL_DESTINATION] : [])),
    ];

    const rentalCarLocationOptions = [
        ...(rentalCarServiceAssignments.length > 1
            ? rentalCarServiceAssignments
                .filter(assignment => !!assignment.rentalCarStationPickup?.location)
                .map((assignment, idx) => `${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} ${idx + 1}`)
            : (rentalCarServiceAssignments[0]?.rentalCarStationPickup?.location
                ? [efLocationTypes.RENTAL_CAR_PICKUP_LOCATION] : [])),
        ...(rentalCarServiceAssignments.length > 1
            ? rentalCarServiceAssignments
                .filter(assignment => !!assignment.rentalCarStationDropOff?.location)
                .map((assignment, idx) => `${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} ${idx + 1}`)
            : (rentalCarServiceAssignments[0]?.rentalCarStationDropOff?.location
                ? [efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION] : [])),
    ];

    const accommodationLocationOptions = [
        ...(accommodationServiceAssignments.length > 1
            ? accommodationServiceAssignments
                .filter(assignment => !!assignment.acePartner?.location)
                .map((assignment, idx) => `${efLocationTypes.HOTEL_LOCATION} ${idx + 1}`)
            : (accommodationServiceAssignments[0]?.acePartner?.location ? [efLocationTypes.HOTEL_LOCATION] : [])),
    ];

    const contractPartnerLocationOptions = [
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)
                .map((assignment, idx) => ({
                    value: `${efLocationTypes.CONTRACT_PARTNER_LOCATION} ${idx + 1}`,
                    label: assignment.acePartner.name,
                }))
            : (vehicleServiceAssignments[0]?.acePartner?.location
                ? [{
                    value: efLocationTypes.CONTRACT_PARTNER_LOCATION,
                    label: vehicleServiceAssignments[0].acePartner.name,
                }] : [])),
    ];

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Panel title={translateTab('panel_title.details')}>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateTab('details_panel_text.general_data')}
                    </div>
                    <Form
                        name="vehicleReturnRequirements"
                        onChange={handleOnChange}
                    >
                        {formValues => {
                            const getInitialFinalDestinationType = location => {
                                const index = vehicleServiceAssignments
                                    .filter(assignment => !!assignment.finalDestination?.location)
                                    .findIndex(assignment => {
                                        return assignment.finalDestination.location.formattedAddress === location.formattedAddress; // eslint-disable-line max-len
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.FINAL_DESTINATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialTowingDestinationType = location => {
                                const index = vehicleServiceAssignments
                                    .filter(assignment => !!assignment.towingDestination).findIndex(assignment => {
                                        // eslint-disable-next-line max-len
                                        return assignment.towingDestination.formattedAddress === location.formattedAddress;
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.TOWING_DESTINATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialContractPartnerLocationType = location => {
                                /* eslint-disable max-len */
                                const index = vehicleServiceAssignments
                                    .filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name)
                                    .findIndex(assignment => {
                                        return assignment.acePartner.location.formattedAddress === location.formattedAddress;
                                    });
                                /* eslint-enable max-len */
                                if (index >= 0) {
                                    return `${efLocationTypes.CONTRACT_PARTNER_LOCATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialRentalCarPickupLocationType = location => {
                                const index = rentalCarServiceAssignments
                                    .filter(assignment => !!assignment.rentalCarStationPickup?.location)
                                    .findIndex(assignment => {
                                        // eslint-disable-next-line max-len
                                        return assignment.rentalCarStationPickup.location.formattedAddress === location.formattedAddress;
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.RENTAL_CAR_PICKUP_LOCATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialRentalCarDropOffLocationType = location => {
                                const index = rentalCarServiceAssignments
                                    .filter(assignment => !!assignment.rentalCarStationDropOff?.location)
                                    .findIndex(assignment => {
                                        // eslint-disable-next-line max-len
                                        return assignment.rentalCarStationDropOff.location.formattedAddress === location.formattedAddress;
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialHotelLocationType = location => {
                                const index = accommodationServiceAssignments
                                    .filter(assignment => !!assignment.acePartner?.location)
                                    .findIndex(assignment => {
                                        // eslint-disable-next-line max-len
                                        return assignment.acePartner.location.formattedAddress === location.formattedAddress;
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.HOTEL_LOCATION} ${index + 1}`;
                                }
                                return location.locationType; // placeholder will be shown
                            };

                            const getInitialLocationType = location => {
                                if (vehicleServiceAssignments.length > 1) {
                                    if (location.locationType.includes(efLocationTypes.FINAL_DESTINATION)) {
                                        return getInitialFinalDestinationType(location);
                                    }

                                    if (location.locationType.includes(efLocationTypes.TOWING_DESTINATION)) {
                                        return getInitialTowingDestinationType(location);
                                    }

                                    if (location.locationType.includes(efLocationTypes.CONTRACT_PARTNER_LOCATION)) {
                                        return getInitialContractPartnerLocationType(location);
                                    }
                                }

                                if (rentalCarServiceAssignments.length > 1) {
                                    if (location.locationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION)) {
                                        return getInitialRentalCarPickupLocationType(location);
                                    }

                                    if (location.locationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION)) {
                                        return getInitialRentalCarDropOffLocationType(location);
                                    }
                                }

                                if (accommodationServiceAssignments.length > 1) {
                                    if (location.locationType.includes(efLocationTypes.HOTEL_LOCATION)) {
                                        return getInitialHotelLocationType(location);
                                    }
                                }

                                return location.locationType; // placeholder will be shown
                            };

                            const getSelectedLocationStallFee = fieldName => {
                                if (!['destination', 'pickupLocation'].includes(fieldName)) return '';
                                const location = fieldName === 'destination' ? newDestination : newPickupLocation;
                                if (!location) return serviceAssignment?.stallFee || '';
                                const {locationType} = location;

                                if (efLocationTypes.FINAL_DESTINATION === locationType) {
                                    const selectedLocationType = getInitialFinalDestinationType(location);
                                    const {finalDestination} = vehicleServiceAssignments.length === 1
                                        ? vehicleServiceAssignments[0]
                                        : vehicleServiceAssignments.filter(assignment => {
                                            return !!assignment.finalDestination;
                                        })[selectedLocationType.replace(`${efLocationTypes.FINAL_DESTINATION} `, '') - 1];

                                    return finalDestination?.dailyFee || '';
                                }

                                return serviceAssignment?.stallFee || '';
                            };

                            const getSelectedLocationPhoneNo = fieldName => {
                                if (!['destination', 'pickupLocation'].includes(fieldName)) return '';
                                if (!serviceAssignment) return '';
                                const location = fieldName === 'destination' ? newDestination : newPickupLocation;
                                if (!location) return serviceAssignment[`${fieldName}PhoneNo`] || '';
                                const {locationType} = location;

                                if (locationType === serviceAssignment[fieldName]?.locationType) {
                                    return serviceAssignment[`${fieldName}PhoneNo`] || '';
                                }

                                if (efLocationTypes.FINAL_DESTINATION === locationType) {
                                    const selectedLocationType = getInitialFinalDestinationType(location);
                                    const {finalDestination} = vehicleServiceAssignments.length === 1
                                        ? vehicleServiceAssignments[0]
                                        : vehicleServiceAssignments.filter(assignment => {
                                            return !!assignment.finalDestination;
                                        })[selectedLocationType.replace(`${efLocationTypes.FINAL_DESTINATION} `, '') - 1];
                                    return finalDestination?.phoneNo || '';
                                }

                                return serviceAssignment[`${fieldName}PhoneNo`] || '';
                            };

                            const getSelectedLocationWorkingHours = fieldName => {
                                if (!['destination', 'pickupLocation'].includes(fieldName)) return '';
                                if (!serviceAssignment) return '';
                                const location = fieldName === 'destination' ? newDestination : newPickupLocation;
                                if (!location) return serviceAssignment[`${fieldName}OpeningHours`] || '';
                                const {locationType} = location;

                                if (locationType === serviceAssignment[fieldName]?.locationType) {
                                    return serviceAssignment[`${fieldName}OpeningHours`] || '';
                                }

                                if (efLocationTypes.FINAL_DESTINATION === locationType) {
                                    const selectedLocationType = getInitialFinalDestinationType(location);
                                    const {finalDestination} = vehicleServiceAssignments.length === 1
                                        ? vehicleServiceAssignments[0]
                                        : vehicleServiceAssignments.filter(assignment => {
                                            return !!assignment.finalDestination;
                                        })[selectedLocationType.replace(`${efLocationTypes.FINAL_DESTINATION} `, '') - 1];

                                    if (!finalDestination) return '';
                                    return `${finalDestination.workingHoursFrom}-${finalDestination.workingHoursTo} / ${finalDestination.alternativeWorkingHoursFrom}-${finalDestination.alternativeWorkingHoursTo}`;
                                }

                                return serviceAssignment[`${fieldName}OpeningHours`] || '';
                            };

                            const getSelectedLocationName = fieldName => {
                                if (!['destination', 'pickupLocation'].includes(fieldName)) return '';
                                const location = fieldName === 'destination' ? newDestination : newPickupLocation;
                                const {locationType} = location;

                                if (!serviceAssignment) return '';

                                if (locationType === serviceAssignment[fieldName]?.locationType) {
                                    return location?.locationName;
                                }

                                if (efLocationTypes.TOWING_DESTINATION === locationType) {
                                    return location?.locationName;
                                }

                                if (efLocationTypes.CONTRACT_PARTNER_LOCATION === locationType) {
                                    const [assignment] = vehicleServiceAssignments
                                        .filter(assignment => !!assignment.acePartner?.location && !!assignment.acePartner?.name) // eslint-disable-line max-len
                                        .filter(assignment => assignment.acePartner.location.id === location.id);
                                    if (assignment) {
                                        return assignment.acePartner.name;
                                    }
                                }

                                if (efLocationTypes.RENTAL_CAR_PICKUP_LOCATION === locationType) {
                                    const [assignment] = rentalCarServiceAssignments
                                        .filter(assignment => !!assignment.rentalCarStationPickup?.location && !!assignment.rentalCarStationPickup?.name) // eslint-disable-line max-len
                                        // compare locations by formattedAddress, since stations do not have locationId
                                        .filter(assignment => assignment.rentalCarStationPickup.location.formattedAddress === location.formattedAddress); // eslint-disable-line max-len
                                    if (assignment) {
                                        return assignment.rentalCarStationPickup.name;
                                    }
                                }

                                if (efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION === locationType) {
                                    const [assignment] = rentalCarServiceAssignments
                                        .filter(assignment => !!assignment.rentalCarStationDropOff?.location && !!assignment.rentalCarStationDropOff?.name) // eslint-disable-line max-len
                                        // compare locations by formattedAddress, since stations do not have locationId
                                        .filter(assignment => assignment.rentalCarStationDropOff.location.formattedAddress === location.formattedAddress); // eslint-disable-line max-len
                                    if (assignment) {
                                        return assignment.rentalCarStationDropOff.name;
                                    }
                                }

                                if (efLocationTypes.HOTEL_LOCATION === locationType) {
                                    return location?.locationName;
                                }

                                if (efLocationTypes.FINAL_DESTINATION === locationType) {
                                    return location?.locationName;
                                }

                                return '';
                            };

                            return (
                                <Fragment>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-8')}
                                            name="reason"
                                            value={formData.reason}
                                            label={translateTab('details_panel_input_label.reason')}
                                        />
                                        <InputPhoneNumberField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="callbackPhoneNo"
                                            value={formData.callbackPhoneNo || ''}
                                            label={translateTab('details_panel_input_label.callback_number')}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-start',
                                        ])}
                                    >
                                        <Checkbox
                                            name="rollable"
                                            value={true}
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            isSelected={formData.rollable}
                                        >
                                            {translateTab('details_panel_checkbox_label.rollable')}
                                        </Checkbox>
                                        <Checkbox
                                            name="keyAtTrailer"
                                            value={true}
                                            className={cx([
                                                'global!ace-u-margin--bottom-24',
                                                'global!ace-u-margin--left-48',
                                                'global!ace-u-margin--right-48',
                                            ])}
                                            isSelected={formData.keyAtTrailer}
                                        >
                                            {translateTab('details_panel_checkbox_label.key_at_trailer')}
                                        </Checkbox>
                                        <Checkbox
                                            name="trailerRegistrationAtTrailer"
                                            value={true}
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            isSelected={formData.trailerRegistrationAtTrailer}
                                        >
                                            {translateTab('details_panel_checkbox_label.trailer_registration_at_trailer')}
                                        </Checkbox>
                                    </div>
                                    <Divider />
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-margin--top-32',
                                        ])}
                                    >
                                        {translateTab('details_panel_text.objects_in_the_trailer')}
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-flex-start',
                                        ])}
                                    >
                                        <Checkbox
                                            name="luggage"
                                            value={true}
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            isSelected={formData.luggage}
                                        >
                                            {translateTab('details_panel_checkbox_label.luggage')}
                                        </Checkbox>
                                        <Checkbox
                                            name="valuableThings"
                                            value={true}
                                            className={cx([
                                                'global!ace-u-margin--bottom-24',
                                                'global!ace-u-margin--left-48',
                                                'global!ace-u-margin--right-48',
                                            ])}
                                            isSelected={formData.valuableThings}
                                        >
                                            {translateTab('details_panel_checkbox_label.valuable_things')}
                                        </Checkbox>
                                        <Checkbox
                                            name="bicycleCarrier"
                                            value={true}
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            isSelected={formData.bicycleCarrier}
                                        >
                                            {translateTab('details_panel_checkbox_label.bicycle_carrier')}
                                        </Checkbox>
                                    </div>
                                    <Divider />
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-margin--top-32',
                                        ])}
                                    >
                                        {translateTab('details_panel_text.pickup_location')}
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <SelectField
                                            name="pickupLocationType"
                                            value={newPickupLocation && newPickupLocation.locationType
                                                ? getInitialLocationType(newPickupLocation)
                                                : null}
                                            onChange={location => handleLocationTypeChange(
                                                location,
                                                setNewPickupLocation,
                                            )}
                                            label={translateTab('details_panel_select_label.pickup_location_info')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx(['global!ace-u-grid-column--span-3'])}
                                        >
                                            {memberLocationTypeOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {vehicleServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.FINAL_DESTINATION)
                                                            ? locationType.replace(efLocationTypes.FINAL_DESTINATION,
                                                                translate(`global.location_type.final_destination`)) : (locationType.includes(efLocationTypes.TOWING_DESTINATION)
                                                                ? locationType.replace(
                                                                    efLocationTypes.TOWING_DESTINATION,
                                                                    translate(`global.location_type.towing_destination`),
                                                                ) : translate(`global.location_type.${locationType.toLowerCase()}`))
                                                        )}
                                                </Option>
                                            ))}
                                            {rentalCarLocationOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {rentalCarServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION) // eslint-disable-line max-len
                                                            ? locationType.replace(
                                                                efLocationTypes.RENTAL_CAR_PICKUP_LOCATION,
                                                                translate(`global.location_type.rental_car_pickup_location`),
                                                            )
                                                            : (locationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION) // eslint-disable-line max-len
                                                                ? locationType.replace(
                                                                    efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION,
                                                                    translate(`global.location_type.rental_car_dropoff_location`),
                                                                ) : translate(`global.location_type.${locationType.toLowerCase()}`)))}
                                                </Option>
                                            ))}
                                            {accommodationLocationOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {accommodationServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.HOTEL_LOCATION)
                                                            ? locationType.replace(
                                                                efLocationTypes.HOTEL_LOCATION,
                                                                translate(`global.location_type.hotel_location`),
                                                            )
                                                            : translate(`global.location_type.${locationType.toLowerCase()}`))}
                                                </Option>
                                            ))}
                                            {contractPartnerLocationOptions.map(location => (
                                                <Option
                                                    key={location.value}
                                                    name={`${location.value}Option`}
                                                    value={location.value}
                                                >
                                                    {location.label}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <AutosuggestField
                                            label={translateTab('details_panel_input_label.pickup_location')}
                                            name="pickupLocation"
                                            value={newPickupLocation?.formattedAddress || ''}
                                            onChange={handlePickupLocationSearchQueryChange}
                                            onOptionSelect={handlePickupLocationCandidateSelect}
                                            optionValueSelector={pickupLocationCandidate => {
                                                return pickupLocationCandidate.formattedAddress;
                                            }}
                                            isDisabled={formValues['pickupLocationType'] !== efLocationTypes.SELECT_LOCATION}
                                            placeholder={translateTab('details_panel_input_placeholder.please_fill_in')}
                                            icon={searchIcon}
                                            className={cx('global!ace-u-grid-column--span-3')}
                                        >
                                            {(formValues['pickupLocation'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                             && pickupLocationSearchQuery === lastPickupLocationSearchQuery.current
                                                ? pickupLocationCandidates
                                                    .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                    .map((pickupLocationCandidate, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                name={`pickup-location-candidate-${index}`}
                                                                value={pickupLocationCandidate}
                                                            >
                                                                <Icon
                                                                    icon={locationIcon}
                                                                    className={cx('global!ace-u-margin--right-16')}
                                                                />
                                                                {pickupLocationCandidate.formattedAddress}
                                                            </Option>
                                                        );
                                                    }) : null}
                                        </AutosuggestField>
                                        {newPickupLocation
                                            && (newPickupLocation.countryCode ? newPickupLocation.countryCode !== 'DEU'
                                                : (!newPickupLocation.country || newPickupLocation.country.length > 2
                                                    ? true : newPickupLocation.country !== 'DE'))
                                            ? (
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid-column--span-4',
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-margin--top-24',
                                                    ])}
                                                >
                                                    <Pill
                                                        type="information"
                                                        className={cx([
                                                            'global!ace-u-typography--variant-caption',
                                                            'global!ace-u-typography--align-center',
                                                        ])}
                                                    >
                                                        {translateTab('details_panel_input_label.pickup_location_outside_deu')}<br />
                                                        {translateTab('details_panel_input_label.pickup_location_requires_registration_certificate')}
                                                    </Pill>
                                                </div>
                                            ) : null}
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-3')}
                                            name="pickupLocationName"
                                            label={translateTab('details_panel_input_label.pickup_location_name')}
                                            value={newPickupLocation
                                                ? getSelectedLocationName('pickupLocation')
                                                : ''}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="pickupLocationOpeningHours"
                                            value={getSelectedLocationWorkingHours('pickupLocation')}
                                            label={translateTab('details_panel_input_label.opening_hours_pickup_location')}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="pickupLocationPhoneNo"
                                            value={getSelectedLocationPhoneNo('pickupLocation')}
                                            label={translateTab('details_panel_input_label.phone_no_pickup_location')}
                                        />
                                        <NumberInputField
                                            className={cx('global!ace-u-grid-column--span-3')}
                                            name="stallFee"
                                            value={getSelectedLocationStallFee('pickupLocation')}
                                            label={translateTab('details_panel_input_label.stall_fee', {
                                                priceType: Number(
                                                    serviceCase.commissioner.id,
                                                ) !== config.ACE_COMMISSIONER_ID
                                                    ? translate('global.pricing_type.net')
                                                    : translate('global.pricing_type.gross'),
                                            })}
                                            min={0}
                                        />
                                        <NumberInputField
                                            className={cx('global!ace-u-grid-column--span-3')}
                                            name="otherFees"
                                            value={formData.otherFees}
                                            label={translateTab('details_panel_input_label.other_open_fees', {
                                                priceType: Number(
                                                    serviceAssignment.serviceCase.commissioner.id,
                                                ) !== config.ACE_COMMISSIONER_ID
                                                    ? translate('global.pricing_type.net')
                                                    : translate('global.pricing_type.gross'),
                                            })}
                                            min={0}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-margin--top-32',
                                        ])}
                                    >
                                        {translateTab('details_panel_text.goal_destination')}
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <SelectField
                                            name="destinationLocationType"
                                            value={newDestination && newDestination.locationType
                                                ? getInitialLocationType(newDestination)
                                                : null}
                                            onChange={location => handleLocationTypeChange(
                                                location,
                                                setNewDestination,
                                                true,
                                            )}
                                            label={translateTab('details_panel_select_label.goal_destination_info')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx(['global!ace-u-grid-column--span-3'])}
                                        >
                                            {memberLocationTypeOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {vehicleServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.FINAL_DESTINATION)
                                                            ? locationType.replace(efLocationTypes.FINAL_DESTINATION,
                                                                translate(`global.location_type.final_destination`)) : (locationType.includes(efLocationTypes.TOWING_DESTINATION)
                                                                ? locationType.replace(
                                                                    efLocationTypes.TOWING_DESTINATION,
                                                                    translate(`global.location_type.towing_destination`),
                                                                ) : translate(`global.location_type.${locationType.toLowerCase()}`))
                                                        )}
                                                </Option>
                                            ))}
                                            {rentalCarLocationOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {rentalCarServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.RENTAL_CAR_PICKUP_LOCATION) // eslint-disable-line max-len
                                                            ? locationType.replace(
                                                                efLocationTypes.RENTAL_CAR_PICKUP_LOCATION,
                                                                translate(`global.location_type.rental_car_pickup_location`),
                                                            )
                                                            : (locationType.includes(efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION) // eslint-disable-line max-len
                                                                ? locationType.replace(
                                                                    efLocationTypes.RENTAL_CAR_DROPOFF_LOCATION,
                                                                    translate(`global.location_type.rental_car_dropoff_location`),
                                                                ) : translate(`global.location_type.${locationType.toLowerCase()}`)))}
                                                </Option>
                                            ))}
                                            {accommodationLocationOptions.map(locationType => (
                                                <Option
                                                    key={locationType}
                                                    name={`${locationType}Option`}
                                                    value={locationType}
                                                >
                                                    {accommodationServiceAssignments.length === 1
                                                        ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                        : (locationType.includes(efLocationTypes.HOTEL_LOCATION)
                                                            ? locationType.replace(
                                                                efLocationTypes.HOTEL_LOCATION,
                                                                translate(`global.location_type.hotel_location`),
                                                            )
                                                            : translate(`global.location_type.${locationType.toLowerCase()}`))}
                                                </Option>
                                            ))}
                                            {contractPartnerLocationOptions.map(location => (
                                                <Option
                                                    key={location.value}
                                                    name={`${location.value}Option`}
                                                    value={location.value}
                                                >
                                                    {location.label}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <AutosuggestField
                                            label={translateTab('details_panel_input_label.goal_destination')}
                                            name="destination"
                                            value={newDestination?.formattedAddress || ''}
                                            onChange={handleDestinationSearchQueryChange}
                                            onOptionSelect={handleDestinationCandidateSelect}
                                            optionValueSelector={destinationCandidate => {
                                                return destinationCandidate.formattedAddress;
                                            }}
                                            isDisabled={formValues['destinationLocationType'] !== efLocationTypes.SELECT_LOCATION}
                                            placeholder={translateTab('details_panel_input_placeholder.please_fill_in')}
                                            icon={searchIcon}
                                            className={cx('global!ace-u-grid-column--span-3')}
                                        >
                                            {(formValues['destination'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                             && destinationSearchQuery === lastDestinationSearchQuery.current
                                                ? destinationCandidates
                                                    .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                    .map((destinationCandidate, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                name={`destination-candidate-${index}`}
                                                                value={destinationCandidate}
                                                            >
                                                                <Icon
                                                                    icon={locationIcon}
                                                                    className={cx('global!ace-u-margin--right-16')}
                                                                />
                                                                {destinationCandidate.formattedAddress}
                                                            </Option>
                                                        );
                                                    }) : null}
                                        </AutosuggestField>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-3')}
                                            name="destinationName"
                                            label={translateTab('details_panel_input_label.destination_name')}
                                            value={newDestination ? getSelectedLocationName('destination') : ''}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="destinationOpeningHours"
                                            value={getSelectedLocationWorkingHours('destination')}
                                            label={translateTab('details_panel_input_label.opening_hours_goal_destination')}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="destinationPhoneNo"
                                            value={getSelectedLocationPhoneNo('destination')}
                                            label={translateTab('details_panel_input_label.phone_no_goal_destination')}
                                        />
                                        <NumberInputField
                                            className={cx('global!ace-u-grid-column--span-2')}
                                            name="routeDistance"
                                            value={newDestination && newPickupLocation
                                            && pickupLocationToDestinationDistance
                                                ? Math.round(pickupLocationToDestinationDistance)
                                                : formData.routeDistance}
                                            label={translateTab('details_panel_input_label.route_calculation')}
                                            min={0}
                                            isDisabled={!!(newDestination && newPickupLocation
                                                && pickupLocationToDestinationDistance)}
                                        />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-margin--top-32',
                                        ])}
                                    >
                                        {translateTab('details_panel_text.further_info')}
                                    </div>
                                    <div
                                        className={cx('global!ace-u-grid')}
                                    >
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-10')}
                                            name="additionalInformationText"
                                            value={formData.additionalInformationText}
                                            label={translateTab('details_panel_input_label.comment')}
                                        />
                                    </div>
                                </Fragment>
                            );
                        }}
                    </Form>
                </Panel>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <PowerOfAttorneyPanel
                    pickupLocation={newPickupLocation}
                    onSubmit={handleOnSend}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SATRRequirementsTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    serviceAssignments: PropTypes.array,
    submitSATRRequirementsForm: PropTypes.func.isRequired,
    searchSATRPickupLocationGeolocation: PropTypes.func.isRequired,
    searchSATRDestinationGeolocation: PropTypes.func.isRequired,
    calculatePickupLocationToDestinationDistance: PropTypes.func.isRequired,
    submitPowerOfAttorneyForm: PropTypes.func.isRequired,
    resetSATRPickupLocationCandidates: PropTypes.func.isRequired,
    resetSATRDestinationCandidates: PropTypes.func.isRequired,
};

SATRRequirementsTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    serviceAssignments: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSATRRequirementsForm: payload => dispatch({
        type: satrActionTypes.SUBMIT_SATR_REQUIREMENTS_FORM,
        payload,
    }),
    searchSATRPickupLocationGeolocation: payload => dispatch({
        type: satrActionTypes.SEARCH_SATR_PICKUP_LOCATION_GEOLOCATION,
        payload,
    }),
    searchSATRDestinationGeolocation: payload => dispatch({
        type: satrActionTypes.SEARCH_SATR_DESTINATION_GEOLOCATION,
        payload,
    }),
    calculatePickupLocationToDestinationDistance: payload => dispatch({
        type: satrActionTypes.CALCULATE_PICKUP_LOCATION_TO_DESTINATION_DISTANCE,
        payload,
    }),

    submitPowerOfAttorneyForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_POWER_OF_ATTORNEY_FORM,
        payload,
    }),
    resetSATRPickupLocationCandidates: payload => dispatch({
        type: satrActionTypes.RESET_SATR_PICKUP_LOCATION_CANDIDATES,
        payload,
    }),
    resetSATRDestinationCandidates: payload => dispatch({
        type: satrActionTypes.RESET_SATR_DESTINATION_CANDIDATES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SATRRequirementsTab));
