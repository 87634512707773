import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import sarcScreenTabs from '../sarcScreenTabs';
import * as sarcActionTypes from '../sarcActionTypes';
import onSARCMapClickSelectRentalCarStation from './onSARCMapClickSelectRentalCarStation';

const sarcMapRentalCarStationWatcher = function* sarcMapRentalCarStationWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(sarcActionTypes.START_SARC_MAP_RENTAL_CAR_STATION_WATCHER);

        const onSARCMapClickSelectRentalCarStationTask = yield fork(onSARCMapClickSelectRentalCarStation, payload);

        while (true) {
            const watcherTerminationAction = yield take([
                sarcActionTypes.STOP_SARC_MAP_RENTAL_CAR_STATION_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === sarcActionTypes.STOP_SARC_MAP_RENTAL_CAR_STATION_WATCHER) {
                yield cancel(onSARCMapClickSelectRentalCarStationTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_RENTAL_CAR,
                exact: true,
            });
            const {activeTab} = savRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savRouteMatch || activeTab !== sarcScreenTabs.SELECT_STATION) {
                yield cancel(onSARCMapClickSelectRentalCarStationTask);
                continue mainFlow;
            }
        }
    }
};

export default sarcMapRentalCarStationWatcher;
