import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {camelCase, snakeCase} from 'change-case';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efTransportationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ContentBlock, ContentItem, DateField, SelectField, Option} from '@ace-de/ui-components';
import {Form, Checkbox} from '@ace-de/ui-components/form';
import {calendarIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sadehActionTypes from './sadehActionTypes';
import VehicleSelectPanel from '../service-cases/ui-elements/VehicleSelectPanel';
import ServiceAssignmentJourneyNotesPanel from '../service-assignments/ui-elements/ServiceAssignmentJourneyNotesPanel';

const SADEHJourneyDataTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sadeh_journey_data_tab');
    const {serviceCase, serviceAssignment, memberVehicles, submitSADEHJourneyDataForm} = props;
    const [selectedVehicle, setSelectedVehicle] = useState(serviceCase?.vehicle?.vehicleId
        ? serviceCase.vehicle
        : null);
    const [durationAndTypeData, setDurationAndTypeData] = useState({
        journeyStartDate: serviceAssignment.journeyStartDate,
        journeyEndDate: serviceAssignment.journeyEndDate,
        transportationType: serviceAssignment.transportationType,
    });
    const [additionalInformationData, setAdditionalInformationData] = useState({
        isKeyAvailable: serviceCase.isKeyAvailable,
        isDriverLicenseAvailable: serviceCase.isDriverLicenseAvailable,
    });

    // if an agent creates a draft, reset previously selected vehicle
    useEffect(() => {
        if (serviceCase?.vehicle && !serviceCase.vehicle?.vehicleId && selectedVehicle) {
            setSelectedVehicle(null);
        }
    }, [serviceCase?.vehicle, selectedVehicle]);

    const handleDurationAndTypeFormChange = formValues => {
        setDurationAndTypeData(formValues);
    };

    const handleAdditionalInformationFormChange = formValues => {
        setAdditionalInformationData({
            isKeyAvailable: !!formValues.isKeyAvailable,
            isDriverLicenseAvailable: !!formValues.isDriverLicenseAvailable,
        });
    };

    const handleOnTabChange = useCallback(formValues => {
        submitSADEHJourneyDataForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            caseType: serviceCase.caseType,
            serviceAssignmentData: {
                ...durationAndTypeData,
                journeyStartDate: durationAndTypeData?.journeyStartDate
                    && moment(durationAndTypeData.journeyStartDate).isValid()
                    ? moment(durationAndTypeData.journeyStartDate).format('YYYY-MM-DD') : '',
                journeyEndDate: durationAndTypeData?.journeyEndDate
                    && moment(durationAndTypeData.journeyEndDate).isValid()
                    ? moment(durationAndTypeData.journeyEndDate).format('YYYY-MM-DD') : '',
            },
            serviceCaseData: {
                ...additionalInformationData,
            },
        });
    }, [
        additionalInformationData,
        durationAndTypeData,
        serviceCase.id,
        serviceCase.caseType,
        serviceAssignment.lineNo,
        submitSADEHJourneyDataForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!durationAndTypeData || !additionalInformationData) {
            completeRouteUnmountSideEffect({
                caller: sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM,
            });
            return;
        }

        handleOnTabChange();
    }, [
        durationAndTypeData,
        additionalInformationData,
        handleOnTabChange,
    ]);

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="durationAndTypeForm" onChange={handleDurationAndTypeFormChange}>
                    <Panel
                        title={translateTab('panel_title.duration_and_type')}
                    >
                        <div className={cx('global!ace-u-grid')}>
                            <DateField
                                name="journeyStartDate"
                                label={translateTab('date_field_label.start_journey')}
                                value={durationAndTypeData.journeyStartDate}
                                placeholder={translateTab('date_field_placeholder.please_enter')}
                                icon={calendarIcon}
                                locale={activeLocale}
                            />
                            <DateField
                                name="journeyEndDate"
                                label={translateTab('date_field_label.end_journey')}
                                value={durationAndTypeData.journeyEndDate}
                                placeholder={translateTab('date_field_placeholder.please_enter')}
                                icon={calendarIcon}
                                locale={activeLocale}
                            />
                            <SelectField
                                name="transportationType"
                                value={durationAndTypeData.transportationType}
                                label={translateTab('input_field_label.transportation_type')}
                                className={cx('global!ace-u-width--256')}
                            >
                                {Object.keys(efTransportationTypes).map(vehicleType => {
                                    return (
                                        <Option
                                            key={`transportationType${camelCase(vehicleType)}`}
                                            name={`transportationType${camelCase(vehicleType)}`}
                                            value={efTransportationTypes[vehicleType]}
                                        >
                                            {translate(`global.service_case_personal_vehicle_type.${snakeCase(vehicleType)}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                        </div>
                    </Panel>
                </Form>
                {durationAndTypeData.transportationType !== efTransportationTypes.PERSONAL_VEHICLE
                    ? null
                    : (
                        <VehicleSelectPanel
                            memberVehicles={memberVehicles}
                            selectedVehicle={selectedVehicle}
                            setSelectedVehicle={setSelectedVehicle}
                            serviceCase={serviceCase}
                        />
                    )
                }
                {durationAndTypeData.transportationType !== efTransportationTypes.PERSONAL_VEHICLE
                    ? null
                    : (
                        <div className={cx('global!ace-u-margin--top-24')}>
                            <Form name="additionalInformationForm" onChange={handleAdditionalInformationFormChange}>
                                <Panel
                                    title={translateTab('panel_title.additional_information')}
                                >
                                    <div className={cx('global!ace-u-flex')}>
                                        <Checkbox
                                            name="isKeyAvailable"
                                            value={true}
                                            className={cx('global!ace-u-margin--right-24')}
                                            isSelected={additionalInformationData.isKeyAvailable}
                                        >
                                            {translateTab(`checkbox_label.key_is_available`)}
                                        </Checkbox>
                                        <Checkbox
                                            name="isDriverLicenseAvailable"
                                            value={true}
                                            isSelected={additionalInformationData.isDriverLicenseAvailable}
                                        >
                                            {translateTab('checkbox_label.driver_license_is_available')}
                                        </Checkbox>
                                    </div>
                                </Panel>
                            </Form>
                        </div>
                    )
                }
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <ServiceAssignmentJourneyNotesPanel
                    serviceCase={serviceCase}
                    serviceAssignment={serviceAssignment}
                    tabName="sadeh_journey_data_tab"
                />
            </ContentItem>
        </ContentBlock>
    );
};

SADEHJourneyDataTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    memberVehicles: PropTypes.array,
    submitSADEHJourneyDataForm: PropTypes.func.isRequired,
};

SADEHJourneyDataTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    memberVehicles: [],
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const memberVehiclesSelector = serviceCaseSelectors.createMemberVehiclesSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        memberVehicles: memberVehiclesSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSADEHJourneyDataForm: payload => dispatch({
        type: sadehActionTypes.SUBMIT_SADEH_JOURNEY_DATA_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SADEHJourneyDataTab));
