import {produce} from 'immer';
import * as ehmCaseLogActionTypes from './caseLogActionTypes';

const initialState = {
    errorCode: '',
};

/**
 * Case log reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const caseLogReducer = produce((draft, action) => {
    switch (action.type) {
        case ehmCaseLogActionTypes.SET_ERROR_CODE: {
            const {errorCode} = action.payload;
            draft.errorCode = errorCode;
            break;
        }

        case ehmCaseLogActionTypes.RESET_ERROR_CODE: {
            draft.errorCode = null;
            break;
        }

        default:
        //     no-op
    }
    return draft;
}, initialState);

export default caseLogReducer;
