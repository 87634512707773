import React from 'react';
import {useStyles, LoaderSpinner, TableCaption} from '@ace-de/ui-components';

const TableLoaderSpinner = () => {
    const {cx} = useStyles();
    return (
        <TableCaption>
            <div className={cx('global!ace-u-margin--48')}>
                <LoaderSpinner />
            </div>
        </TableCaption>
    );
};

export default TableLoaderSpinner;
