import moment from 'moment';
import {efPersonTypes} from '@ace-de/eua-entity-types';

const mapMemberDataToContactPersonData = member => ({
    firstName: member.personalDetails.firstName,
    lastName: member.personalDetails.surname,
    relationshipStatus: efPersonTypes.MEMBER,
    phoneNo: member.contactDetails.phoneNo,
    birthDate: member.personalDetails.birthday ? moment(member.personalDetails.birthday).toISOString() : '',
    isIdenticalToMember: true,
});

export default mapMemberDataToContactPersonData;
