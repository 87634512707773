import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as satrActionTypes from '../satrActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SATR cost coverage flow
 */
const updateSATRCostCoverageFlow = function* updateSATRCostCoverageFlow() {
    while (true) {
        const {payload} = yield take(satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, attachmentsToSendWithAssignment} = payload;
        const {serviceCaseType, serviceCaseId, serviceCaseData} = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceCase({
            caller: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM,
            caseType: serviceCaseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield* updateServiceAssignment({
            caller: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.TRAILER_RETURN,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM},
        });
    }
};

export default updateSATRCostCoverageFlow;
