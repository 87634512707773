import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, Panel} from '@ace-de/ui-components';
import {Form, InputField, SelectField, Option, TextAreaField} from '@ace-de/ui-components/form';
import ContactPersonDetailsPanel from '../service-assignments/ui-elements/ContactPersonDetailsPanel';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as samaActionTypes from './samaActionTypes';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import mapMemberDataToContactPersonData from '../service-cases/mapMemberDataToContactPersonData';
import config from '../config';

const isObjectWithKeys = obj => (typeof obj === 'object' && !!Object.keys(obj).length);

const SAMARequirementsTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sama_requirements_tab');
    const {serviceCase, serviceAssignment} = props;
    const {submitSAMARequirementsForm, saveSAMAServiceLocation} = props;
    const [formData, setFormData] = useState({
        affectedPerson: serviceAssignment?.affectedPerson || mapMemberDataToContactPersonData(serviceCase?.member),
        contactPersonOnSite: serviceAssignment?.contactPersonOnSite
            || mapMemberDataToContactPersonData(serviceCase.member),
    });

    const handleOnChange = formValues => {
        setFormData({
            ...formValues,
            ...(isObjectWithKeys(formValues['contactPersonOnSite']) && {
                contactPersonOnSite: {
                    ...(formValues['contactPersonOnSite']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['contactPersonOnSite']),
                    isIdenticalToMember: !!formValues['contactPersonOnSite']['isIdenticalToMember'],
                },
            }),
            ...(isObjectWithKeys(formValues['affectedPerson']) && {
                affectedPerson: {
                    ...(formValues['affectedPerson']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['affectedPerson']),
                    isIdenticalToMember: !!formValues['affectedPerson']['isIdenticalToMember'],
                },
            }),
        });
    };

    const handleOnTabChange = useCallback(formValues => {
        const {contactPersonOnSite, affectedPerson, diagnoseAndInsuranceData, requirementsText} = formValues;
        submitSAMARequirementsForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                requirementsText,
                ...diagnoseAndInsuranceData,
                ...(isObjectWithKeys(contactPersonOnSite)
                    ? {contactPersonOnSite}
                    : (!serviceAssignment.contactPersonOnSite
                        ? {contactPersonOnSite: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(affectedPerson)
                    ? {affectedPerson}
                    : (!serviceAssignment.affectedPerson
                        ? {affectedPerson: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
            },
        });
    }, [
        serviceCase.id,
        serviceCase.member,
        serviceAssignment.lineNo,
        serviceAssignment.contactPersonOnSite,
        serviceAssignment.affectedPerson,
        submitSAMARequirementsForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: samaActionTypes.SUBMIT_SAMA_REQUIREMENTS_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        saveSAMAServiceLocation({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            caseType: serviceCase.caseType,
            serviceCaseData: {
                // if it's an initial assignment, update damage location
                ...(isInitialAssignment && {
                    damage: {
                        location: {
                            ...(locationInfo.selectedLocation || {}),
                            locationNotes: locationInfo.locationNotes || '',
                        },
                    },
                }),
            },
            serviceAssignmentData: {
                serviceLocation: {
                    ...(locationInfo.selectedLocation || {}),
                    locationNotes: locationInfo.locationNotes || '',
                },
            },
        });
    };

    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="contactPersonsForm" onChange={handleOnChange}>
                    <ContactPersonDetailsPanel
                        title={translateTab('panel_title.local_contact')}
                        formName="contactPersonOnSite"
                        contactPerson={serviceAssignment.contactPersonOnSite}
                        isIdenticalToMember={true}
                    />
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.affected')}
                            formName="affectedPerson"
                            isIdenticalToMember={true}
                            hasDate={true}
                            contactPerson={serviceAssignment.affectedPerson}
                        />
                    </div>
                    <Panel title={translateTab('panel_title.diagnose_and_insurance')}>
                        <Form name="diagnoseAndInsuranceData">
                            <div className={cx('global!ace-u-grid')}>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-6')}
                                    name="diagnoseDescription"
                                    value={serviceAssignment.diagnoseDescription || ''}
                                    label={translateTab('input_label.diagnose_description')}
                                    maxLength={config.MAXIMUM_DIAGNOSE_DESCRIPTION_LENGTH}
                                />
                                <SelectField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="isForeignInsuranceGiven"
                                    value={typeof serviceAssignment.isForeignInsuranceGiven === 'boolean'
                                        ? serviceAssignment.isForeignInsuranceGiven : ''}
                                    label={translateTab('input_label.foreign_health_insurance_available')}
                                    placeholder={translateTab('input_placeholder.please_select')}
                                >
                                    <Option
                                        name="foreignInsuranceIsGivenOption"
                                        value={true}
                                    >
                                        {translateTab('option_label.yes')}
                                    </Option>
                                    <Option
                                        name="foreignInsuranceIsNotGivenOption"
                                        value={false}
                                    >
                                        {translateTab('option_label.no')}
                                    </Option>
                                </SelectField>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="insurance"
                                    value={serviceAssignment.insurance || ''}
                                    label={translateTab('input_label.insurance')}
                                />
                            </div>
                        </Form>
                    </Panel>
                    <Panel title={translateTab('panel_title.additional_information')}>
                        <TextAreaField
                            name="requirementsText"
                            className={cx('global!ace-u-full-width')}
                            value={serviceAssignment.requirementsText || ''}
                            placeholder={translateTab('text_area_placeholder.enter_description')}
                            label={translateTab('text_area_label.free_text_for_requirements')}
                            isResizable={false}
                            maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                        />
                    </Panel>
                </Form>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    hasPreviousLocations={false}
                    mapName="sama-member-location"
                    handleLocationSaving={handleLocationSaving}
                    hasLocationNotes={true}
                    hasConfirmation={false}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SAMARequirementsTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSAMARequirementsForm: PropTypes.func.isRequired,
    saveSAMAServiceLocation: PropTypes.func.isRequired,
};

SAMARequirementsTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};
const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAMARequirementsForm: payload => dispatch({
        type: samaActionTypes.SUBMIT_SAMA_REQUIREMENTS_FORM,
        payload,
    }),
    saveSAMAServiceLocation: payload => dispatch({
        type: samaActionTypes.SUBMIT_SAMA_SERVICE_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAMARequirementsTab));
