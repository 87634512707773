import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Panel, Button, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';

// Maybe this should not be a component

const CreateNewCasePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {initiateCreateForeignAssistanceCaseFlow, initiateCreateEmptyCaseFlow} = props;
    const isCaseCreationFeatureAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.CREATE,
    );

    return (
        <Panel
            title={translateScreen('create_new_panel_case.title')}
        >
            <Button
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-margin--bottom-24',
                ])}
                isDisabled={!isCaseCreationFeatureAvailable}
                qaIdent="create-new-foreign-case"
                onClick={() => {
                    if (!isCaseCreationFeatureAvailable) return;
                    initiateCreateForeignAssistanceCaseFlow({});
                }}
            >
                {translateScreen('create_new_panel_case.button_label.foreign_assistance_case')}
            </Button>
            <Button
                className={cx('global!ace-u-full-width')}
                onClick={() => {
                    if (!isCaseCreationFeatureAvailable) return;
                    initiateCreateEmptyCaseFlow();
                }}
                isDisabled={!isCaseCreationFeatureAvailable}
                qaIdent="create-new-empty-case"
            >
                {translateScreen('create_new_panel_case.button_label.blank_case')}
            </Button>
        </Panel>
    );
};

CreateNewCasePanel.propTypes = {
    initiateCreateForeignAssistanceCaseFlow: PropTypes.func.isRequired,
    initiateCreateEmptyCaseFlow: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    initiateCreateForeignAssistanceCaseFlow: () => dispatch({
        type: serviceCaseActionTypes.INITIATE_CREATE_FOREIGN_ASSISTANCE_CASE_FLOW,
    }),
    initiateCreateEmptyCaseFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_EMPTY_CASE_CREATION_FLOW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(CreateNewCasePanel);
