import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import AlternativeServiceProviderPanel from '../service-assignments/ui-elements/AlternativeServiceProviderPanel';
import * as sastActionTypes from './sastActionTypes';

const SASTServiceProviderTab = props => {
    const {cx} = useStyles();
    const {serviceAssignment, submitSASTServiceProviderForm, serviceCase} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(!!serviceAssignment?.acePartner?.isManuallyAdded);

    const handleOnTabChange = useCallback(() => {
        submitSASTServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                ...(!isEditModeActive
                    ? {acePartner: null}
                    : {}
                ),
            },
        });
    }, [
        isEditModeActive,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        submitSASTServiceProviderForm,
    ]);

    useRouteUnmountEffect(() => {
        handleOnTabChange();
    }, [handleOnTabChange]);

    // if no service assignment, don't render
    if (!serviceAssignment || !serviceCase) return null;

    const handleOnToggleSwitch = () => {
        setIsEditModeActive(!isEditModeActive);
    };

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-3')} />
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <AlternativeServiceProviderPanel
                    isActive={isEditModeActive}
                    serviceCase={serviceCase}
                    serviceAssignment={serviceAssignment}
                    onToggleSwitch={handleOnToggleSwitch}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SASTServiceProviderTab.propTypes = {
    serviceAssignment: PropTypes.object,
    submitSASTServiceProviderForm: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
};

SASTServiceProviderTab.defaultProps = {
    serviceAssignment: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSASTServiceProviderForm: payload => dispatch({
        type: sastActionTypes.SUBMIT_SAST_SERVICE_PROVIDER_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SASTServiceProviderTab));
