import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {ArcGISMapWidget, useArcGISMapLayer} from '@ace-de/eua-arcgis-map';
import {useStyles, ButtonGhost, LockBackdrop} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar, NavigationBar, NotificationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import {Icon, caseProtocolIcon, infoIcon, closedIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import ScreenTabControls from '../service-cases/ui-elements/ScreenTabControls';
import ServiceAssignmentHeader from '../service-assignments/ui-elements/ServiceAssignmentHeader';
import FallbackSystemNotificationBar from '../application/ui-elements/FallbackSystemNotificationBar';
import savaScreenTabs from './savaScreenTabs';
import SAVAServiceLocationTab from './SAVAServiceLocationTab';
import SAVAContractPartnerTab from './SAVAContractPartnerTab';
import SAVATowingDestinationTab from './SAVATowingDestinationTab';
import SAVACommissioningTab from './SAVACommissioningTab';
import serviceAssignmentPartnerDisabledStateStatuses from '../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';
import config from '../config';
import useServiceCaseLockedStatus from '../service-cases/useServiceCaseLockedStatus';
import createServiceCaseProtocolTabURL from '../service-cases/createServiceCaseProtocolTabURL';
import useBeforeUnloadEffect from '../service-cases/useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import contractPartnerInfoPopup from '../service-assignments/contractPartnerInfoPopup';

const ServiceAssignmentVehicleAdditionalScreen = props => {
    const {cx} = useStyles();
    const {resetArcGISNearbyPOIs, serviceCase, serviceAssignment, searchArcGISNearbyPOIs} = props;
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('service_assignment_vehicle_additional_screen');
    const savaContractPartnerLocationsLayer = useArcGISMapLayer(
        'service-assignment-vehicle-additional',
        'sava-contract-partner-locations',
    );
    const [isCPSelectionDisabled, setIsCPSelectionDisabled] = useState(false);
    const {activeTab} = useSearchQueryParams();
    const {isServiceCaseLocked, lockedBy} = useServiceCaseLockedStatus(serviceCase);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const topElementRef = useRef();

    useEffect(() => {
        if (!topElementRef.current) return;
        topElementRef.current.scrollIntoView();
    }, [topElementRef]);

    useBeforeUnloadEffect(event => {
        return {
            unlockRequest: ecsFlowService.unlockServiceCase,
            requestParams: {serviceCaseId: serviceCase.id},
        };
    });

    useEffect(() => {
        if (!serviceCase || !serviceAssignment || !savaContractPartnerLocationsLayer) return;

        const {assignmentStatus} = serviceAssignment;
        const isPartnerChangingDisabled = serviceAssignmentPartnerDisabledStateStatuses.includes(assignmentStatus);

        if (isPartnerChangingDisabled !== isCPSelectionDisabled) {
            savaContractPartnerLocationsLayer.toggleLayerDisabled(isPartnerChangingDisabled);
            setIsCPSelectionDisabled(isPartnerChangingDisabled);
        }
    }, [savaContractPartnerLocationsLayer, serviceCase, serviceAssignment, isCPSelectionDisabled]);

    // if no ServiceCase or ServiceAssignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const tabsWithMap = [
        savaScreenTabs.SERVICE_LOCATION,
        savaScreenTabs.TOWING_DESTINATION,
        savaScreenTabs.CONTRACT_PARTNER,
    ];

    const isServiceCaseEditAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.UPDATE,
    );

    return (
        <Fragment>
            <div ref={topElementRef} />
            <FallbackSystemNotificationBar />
            <HeaderBar>
                <ServiceAssignmentHeader serviceCase={serviceCase} serviceAssignment={serviceAssignment} />
            </HeaderBar>
            <TabSet
                name="sava-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="sava-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="sava-screen-tabs"
                            tabSet="sava-screen-tab-set"
                            defaultValue={activeTab || savaScreenTabs.SERVICE_LOCATION}
                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                || serviceAssignment.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={savaScreenTabs.SERVICE_LOCATION}
                                value={savaScreenTabs.SERVICE_LOCATION}
                            >
                                {translateScreen('tab_label.damage_location')}
                            </Tab>
                            <Tab
                                name={savaScreenTabs.TOWING_DESTINATION}
                                value={savaScreenTabs.TOWING_DESTINATION}
                                isDisabled={!serviceAssignment.serviceLocation}
                            >
                                {translateScreen('tab_label.towing_destination')}
                            </Tab>
                            <Tab
                                name={savaScreenTabs.CONTRACT_PARTNER}
                                value={savaScreenTabs.CONTRACT_PARTNER}
                                isDisabled={!serviceAssignment.serviceLocation}
                            >
                                {translateScreen('tab_label.contract_partner')}
                            </Tab>
                            <Tab
                                name={savaScreenTabs.COMMISSIONING}
                                value={savaScreenTabs.COMMISSIONING}
                                isDisabled={!serviceAssignment.serviceLocation}
                            >
                                {translateScreen('tab_label.commissioning')}
                            </Tab>
                        </TabBar>
                        <ButtonGhost
                            className={cx([
                                'global!ace-u-flex--align-self-stretch',
                                'global!ace-u-margin--right-32',
                            ])}
                            onClick={() => window.open(createServiceCaseProtocolTabURL(serviceCase.id), '_blank')}
                        >
                            <Icon
                                icon={caseProtocolIcon}
                                className={cx('global!ace-u-margin--right-8')}
                            />
                            {translateScreen('button_label.case_protocol')}
                        </ButtonGhost>
                    </div>
                </NavigationBar>
                <div>
                    {isServiceCaseLocked && (
                        <NotificationBar type="information">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                ])}
                            >
                                <Icon
                                    icon={closedIcon}
                                    className={cx('global!ace-u-margin--right-8')}
                                />
                                {translate('global.case_lock_check.case_lock_notification', {
                                    userName: lockedBy,
                                })}
                            </div>
                        </NotificationBar>
                    )}
                    {!serviceAssignment.entitlementCheck && !tabsWithMap.includes(activeTab) && (
                        <NotificationBar type="negative">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-typography--color-contrast',
                                ])}
                            >
                                <Icon
                                    icon={infoIcon}
                                    className={cx([
                                        'ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                <div>
                                    {Array.isArray(serviceAssignment.entitlementResponses)
                                        && serviceAssignment.entitlementResponses.map((response, idx) => {
                                            return (
                                                <Fragment key={`${response.entitlementError}-${idx}`}>
                                                    <span>{translate(response.message)}</span>
                                                    {idx < serviceAssignment.entitlementResponses.length - 1 && (
                                                        <br />
                                                    )}
                                                </Fragment>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </NotificationBar>
                    )}
                </div>
                <AppLayoutMain
                    contentClassName={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-stretch',
                    ])}
                >
                    {(isServiceCaseLocked || !isServiceCaseEditAvailable) && (
                        <LockBackdrop />
                    )}
                    <ArcGISMapWidget
                        name="service-assignment-vehicle-additional"
                        tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                        className={cx({
                            'ace-c-arcgis-map-widget--is-visible': tabsWithMap.includes(activeTab),
                        })}
                        hasFilterPOIsWidget={activeTab !== savaScreenTabs.TOWING_DESTINATION}
                        searchArcGISNearbyPOIs={searchArcGISNearbyPOIs}
                        resetArcGISNearbyPOIs={resetArcGISNearbyPOIs}
                        locale={activeLocale}
                        popup={contractPartnerInfoPopup}
                    />
                    <TabPanel for={savaScreenTabs.SERVICE_LOCATION}>
                        <SAVAServiceLocationTab
                            serviceAssignment={serviceAssignment}
                            serviceCase={serviceCase}
                        />
                    </TabPanel>
                    <TabPanel for={savaScreenTabs.TOWING_DESTINATION}>
                        <SAVATowingDestinationTab
                            serviceAssignment={serviceAssignment}
                            serviceCase={serviceCase}
                        />
                    </TabPanel>
                    <TabPanel for={savaScreenTabs.CONTRACT_PARTNER}>
                        <SAVAContractPartnerTab
                            serviceCase={serviceCase}
                            serviceAssignment={serviceAssignment}
                        />
                    </TabPanel>
                    <TabPanel for={savaScreenTabs.COMMISSIONING}>
                        <SAVACommissioningTab />
                    </TabPanel>

                    <ScreenTabControls activeTab={activeTab} screenTabs={savaScreenTabs} />
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ServiceAssignmentVehicleAdditionalScreen.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchArcGISNearbyPOIs: PropTypes.func.isRequired,
    resetArcGISNearbyPOIs: PropTypes.func.isRequired,
};

ServiceAssignmentVehicleAdditionalScreen.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS,
        payload,
    }),
    resetArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.RESET_ARCGIS_NEARBY_POIS_RESULTS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAssignmentVehicleAdditionalScreen);
