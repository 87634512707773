import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import savaScreenTabs from '../savaScreenTabs';
import filterSAVAMapContractPartnersBySearchQuery from './filterSAVAMapContractPartnersBySearchQuery';
import filterSAVAMapContractPartnersByLocation from './filterSAVAMapContractPartnersByLocation';

const searchSAVAContractPartners = function* searchSAVAContractPartners({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, searchQuery, isVBASearch} = payload;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savaScreenTabs.CONTRACT_PARTNER) return;

    if (searchQuery) {
        yield* filterSAVAMapContractPartnersBySearchQuery(
            serviceCaseId,
            serviceAssignmentLineNo,
            searchQuery,
            isVBASearch,
        );
    } else {
        yield* filterSAVAMapContractPartnersByLocation(serviceCaseId, serviceAssignmentLineNo, isVBASearch);
    }
};

export default searchSAVAContractPartners;
