import {put, take, fork, select} from 'redux-saga/effects';
import {ACEPartner, TemporaryRestriction} from '@ace-de/eua-entity-types';
import {matchPath} from '@computerrock/formation-router';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const createTemporaryRestrictionFlow = function* createTemporaryRestrictionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW);
        const {contractPartner} = payload;

        const {location} = yield select(state => state.router);
        const contractPartnerSearchScreenMatch = matchPath(location.pathname, {
            path: routePaths.CONTRACT_PARTNER_SEARCH,
            exact: true,
        });

        if (contractPartnerSearchScreenMatch) {
            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {
                    contractPartnerDTOs: [{
                        ...(ACEPartner.objectToDTO(contractPartner)),
                    }],
                },
            });
        }

        yield* openModal(
            modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
            contractPartnerSearchScreenMatch ? {contractPartnerId: `${contractPartner.id}`} : undefined,
        );

        const chosenModalOption = yield take([
            contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION,
            contractPartnerActionTypes.CONFIRM_CREATE_TEMPORARY_RESTRICTION,
        ]);

        if (chosenModalOption.type === contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION) {
            yield* closeModal(
                modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
                contractPartnerSearchScreenMatch ? {contractPartnerId: `${contractPartner.id}`} : undefined,
            );
            continue;
        }

        const {temporaryRestrictionData} = chosenModalOption.payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST,
            partnerManagementService.createContractPartnerTemporaryRestriction,
            {
                contractPartnerId: contractPartner.id,
                temporaryRestrictionData: TemporaryRestriction.objectToDTO(temporaryRestrictionData),
            },
        );

        const createTemporaryRestrictionResponseAction = yield take([
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED,
        ]);

        if (!createTemporaryRestrictionResponseAction.error) {
            const {response} = createTemporaryRestrictionResponseAction.payload;

            if (response?.contractPartnerRestrictionDTO?.id && contractPartner?.regionId) {
                const {accountManagers} = yield select(state => state.contractPartners);
                const accountManager = accountManagers[contractPartner.regionId];

                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.FORWARD_TEMPORARY_RESTRICTION_REQUEST,
                    partnerManagementService.forwardTemporaryRestriction,
                    {
                        restrictionId: response.contractPartnerRestrictionDTO.id,
                        emailData: {email: accountManager?.email},
                    },
                );

                const forwardTemporaryRestrictionResponseAction = yield take([
                    contractPartnerActionTypes.FORWARD_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.FORWARD_TEMPORARY_RESTRICTION_REQUEST_FAILED,
                ]);

                if (forwardTemporaryRestrictionResponseAction.error) {
                    // TODO: handle error
                }
            }

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS,
                payload: {
                    contractPartnerId: contractPartner.id,
                    contractPartnerRestrictionDTOs: [response.contractPartnerRestrictionDTO],
                },
            });

            yield* closeModal(
                modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
                contractPartnerSearchScreenMatch ? {contractPartnerId: `${contractPartner.id}`} : undefined,
            );
            // display success popup
            yield* openModal(modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL);
            continue;
        }

        yield* closeModal(
            modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
            contractPartnerSearchScreenMatch ? {contractPartnerId: `${contractPartner.id}`} : undefined,
        );
    }
};

export default createTemporaryRestrictionFlow;
