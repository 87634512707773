import {take, put, fork, select} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import config from '../../config';

/**
 * Update ServiceAssignment status
 */
const updateServiceAssignmentStatus = function* updateServiceAssignmentStatus(params) {
    const {caller, assignmentType, serviceAssignmentLineNo, serviceCaseId, serviceAssignmentStatusData} = params;
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);

    if (!serviceCase) return;

    const lockUUID = serviceCase.lock ? serviceCase.lock.lockUUID : null;

    // call lock endpoint
    yield fork(fetchRequest, serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST, ecsFlowService.lockServiceCase, {
        serviceCaseId,
        lockData: {lockUUID: lockUUID || uuidv4()},
    }, {
        caller,
    });

    let lockResponse = {};
    while (true) {
        lockResponse = yield take([
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_FAILED,
        ]);

        if (lockResponse.meta?.caller && lockResponse.meta.caller === caller) {
            break;
        }
    }

    // if case is not locked or case is lockedBy current user -> allow patch
    if (!lockResponse.error && lockResponse.type === serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED) {
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_REMAINING_LOCK_TIME,
            payload: {remainingLockTime: config.DEFAULT_SERVICE_CASE_LOCK_TIME},
        });

        yield put({
            type: serviceAssignmentActionTypes.BATCH_UPDATE_SERVICE_ASSIGNMENT,
            payload: {
                assignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentStatusData,
                isStatusUpdate: true,
            },
            meta: {caller},
        });

        let batchUpdateServiceAssignmentResponse;
        while (true) {
            const batchUpdateServiceAssignmentAction = yield take(
                serviceAssignmentActionTypes.BATCH_UPDATE_SERVICE_ASSIGNMENT_COMPLETED,
            );
            const {meta = {}} = batchUpdateServiceAssignmentAction;
            if (typeof meta.caller !== 'undefined' && meta.caller !== caller) continue;

            batchUpdateServiceAssignmentResponse = batchUpdateServiceAssignmentAction;
            break;
        }

        return batchUpdateServiceAssignmentResponse;
    }

    return lockResponse;
};

export default updateServiceAssignmentStatus;
