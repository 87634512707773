import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ScrollableBlock, Button, Divider, CheckboxGroup} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import DocumentUploadPanelItem from './DocumentUploadPanelItem';
import styles from './DocumentUploadPanel.module.scss';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const DocumentUploadPanel = props => {
    const {cx} = useStyles(props, styles);
    const {serviceCase} = props;
    const {initiateSCDocumentUploadFlow, groupedAttachments} = props;
    const {attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const hasAttachments = serviceCase.attachments.length > 0;
    const isUploadAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE_FILES,
        ecsFeatureActions.CREATE,
    );

    if (!serviceCase) return null;

    return (
        <Panel
            title={translateTab('panel_title.documents')}
            contentClassName={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
            ])}
            className={hasAttachments ? cx(['global!ace-u-flex--grow-1', 'ace-c-document-upload-panel']) : ''}
        >
            <Button
                onClick={() => initiateSCDocumentUploadFlow({serviceCaseId: serviceCase.id})}
                isDisabled={serviceCase.persistenceState === persistenceStates.PENDING || !isUploadAvailable}
                className={cx({
                    'global!ace-u-margin--bottom-32': serviceCase.attachments.length > 0,
                })}
            >
                {translateTab('button_label.document_upload')}
            </Button>
            {hasAttachments && (
                <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                    <Fragment>
                        <Divider />
                        <div className={cx('global!ace-u-margin--top-24')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                {translateTab('text.uploaded_documents')}
                            </p>
                            <CheckboxGroup
                                name="attachmentsToSendWithAssignment"
                                value={attachmentsToSendWithAssignment}
                                onChange={setAttachmentsToSendWithAssignment}
                            >
                                {Object.values(groupedAttachments).length > 0
                                    ? Object.values(groupedAttachments).reverse().map((attachments, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <DocumentUploadPanelItem
                                                    groupedAttachments={groupedAttachments}
                                                    attachmentGroup={attachments}
                                                    serviceCaseId={serviceCase.id}
                                                    serviceCase={serviceCase}
                                                />
                                                {id < Object.values(groupedAttachments).length - 1 ? <Divider /> : null}
                                            </Fragment>
                                        );
                                    }) : null}
                            </CheckboxGroup>
                        </div>
                    </Fragment>
                </ScrollableBlock>
            )}
        </Panel>
    );
};

DocumentUploadPanel.propTypes = {
    initiateSCDocumentUploadFlow: PropTypes.func.isRequired,
    groupedAttachments: PropTypes.object,
    serviceCase: PropTypes.object,
    attachmentsToSendWithAssignment: PropTypes.array,
    setAttachmentsToSendWithAssignment: PropTypes.func,
};

DocumentUploadPanel.defaultProps = {
    serviceCase: null,
    groupedAttachments: null,
    attachmentsToSendWithAssignment: null,
    setAttachmentsToSendWithAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const getServiceCaseGroupedAttachmentsSelector = serviceCaseSelectors.createServiceCaseGroupedAttachmentsSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        groupedAttachments: getServiceCaseGroupedAttachmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateSCDocumentUploadFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SC_DOCUMENT_UPLOAD_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentUploadPanel));
