import {all, fork} from 'redux-saga/effects';
import createServiceAssignmentDeathEventHelpFlow from './sagas/createServiceAssignmentDeathEventHelpFlow';
import updateSADEHJourneyData from './sagas/updateSADEHJourneyData';
import updateSADEHRequirements from './sagas/updateSADEHRequirements';
import updateSADEHServiceLocationFlow from './sagas/updateSADEHServiceLocationFlow';
import updateSADEHCostCoverageFlow from './sagas/updateSADEHCostCoverageFlow';
import updateSADEHServiceProviderFlow from './sagas/updateSADEHServiceProviderFlow';

/**
 *  SADEH watcher saga
 */
const sadehWatcher = function* sadehWatcher() {
    yield all([
        fork(createServiceAssignmentDeathEventHelpFlow),
        fork(updateSADEHRequirements),
        fork(updateSADEHServiceLocationFlow),
        fork(updateSADEHJourneyData),
        fork(updateSADEHCostCoverageFlow),
        fork(updateSADEHServiceProviderFlow),
    ]);
};

export default sadehWatcher;
