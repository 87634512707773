import React from 'react';
import {ecsDashboardNavigationLayoutTypes} from '../application/ecsLayoutTypes';
import MemberSearchBox from './ui-elements/MemberSearchBox';

const dashboardNavigationLayouts = {
    [ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_1_DASHBOARD_NAVIGATION_LAYOUT]: (
        <MemberSearchBox />
    ),
    [ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_2_DASHBOARD_NAVIGATION_LAYOUT]: (
        <MemberSearchBox />
    ),
    [ecsDashboardNavigationLayoutTypes.ECS_DISPATCHER_3_DASHBOARD_NAVIGATION_LAYOUT]: null,
    [ecsDashboardNavigationLayoutTypes.ECS_DISPO_DASHBOARD_NAVIGATION_LAYOUT]: (
        <MemberSearchBox />
    ),
    [ecsDashboardNavigationLayoutTypes.ECS_TEAM_LEAD_DASHBOARD_NAVIGATION_LAYOUT]: (
        <MemberSearchBox />
    ),
    [ecsDashboardNavigationLayoutTypes.ECS_INBOX_DASHBOARD_NAVIGATION_LAYOUT]: null,
    [ecsDashboardNavigationLayoutTypes.ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_NAVIGATION_LAYOUT]: null,
    [ecsDashboardNavigationLayoutTypes.ECS_DEFAULT_DASHBOARD_NAVIGATION_LAYOUT]: null,
};

export default dashboardNavigationLayouts;
