import {put, select, take, fork} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import routePaths from '../../routePaths';

const loadContractPartners = function* loadContractPartners() {
    const {
        areAllSlimContractPartnersFetched,
        areAllFatContractPartnersFetched,
    } = yield select(state => state.contractPartners);
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {location} = yield select(state => state.router);

    const serviceCaseSearchScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_CASE_SEARCH,
        exact: true,
    });

    // on service case search screen, we need only name and ID (slim CP DTO)
    if (serviceCaseSearchScreenMatch && areAllSlimContractPartnersFetched) return;

    // on other screens (CP selection tabs), we need many fields (fat CP DTO)
    if (!serviceCaseSearchScreenMatch && areAllFatContractPartnersFetched) return;

    const query = serviceCaseSearchScreenMatch
        ? `id name`
        : `
            id
            name
            partnerRestrictions {
                id
                startDateTime
                endDateTime
                description
            }
            emergencyContacts {
                id
                name
                type
                phoneNo
                is24h7Emergency
            }
            contractStatus
            workingHours {
                from
                to
                dayOfWeek
            }
            services {
                serviceType
                isCarDismantling
                usedCarStation
                tonnageThreshold
                camperDimensions {
                    weight
                    height
                    length
                    width
                }
            }
        `;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.queryContractPartners,
        {query},
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {contractPartnerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
            payload: {contractPartnerDTOs},
        });

        if (serviceCaseSearchScreenMatch) {
            // set areAllSlimContractPartnersFetched flag to true and prevent multiple fetches per session
            yield put({
                type: contractPartnerActionTypes.SET_ARE_ALL_SLIM_CONTRACT_PARTNERS_FETCHED,
                payload: {areAllSlimContractPartnersFetched: true},
            });
            return;
        }
        // set areAllFatContractPartnersFetched flag to true and prevent multiple fetches per session
        yield put({
            type: contractPartnerActionTypes.SET_ARE_ALL_FAT_CONTRACT_PARTNERS_FETCHED,
            payload: {areAllFatContractPartnersFetched: true},
        });
    }
};

export default loadContractPartners;
