/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP = '[service-cases] CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP';
export const SUBMIT_SADEH_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SADEH_REQUIREMENTS_FORM';
export const SUBMIT_SADEH_SERVICE_LOCATION_FORM = '[service-cases] SUBMIT_SADEH_SERVICE_LOCATION_FORM';
export const SUBMIT_SADEH_JOURNEY_DATA_FORM = '[service-cases] SUBMIT_SADEH_JOURNEY_DATA_FORM';
export const SUBMIT_SADEH_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SADEH_COST_COVERAGE_FORM';
export const SUBMIT_SADEH_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SADEH_SERVICE_PROVIDER_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST_FAILED';

/**
 * Store action types
 */
