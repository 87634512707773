/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_RETURN_JOURNEY = '[service-cases] CREATE_SERVICE_ASSIGNMENT_RETURN_JOURNEY';
export const SEARCH_SARJ_DESTINATION_GEOLOCATION = '[service-cases] SEARCH_SARJ_DESTINATION_GEOLOCATION';
export const SUBMIT_SARJ_MEMBER_LOCATION_FORM = '[service-cases] SUBMIT_SARJ_MEMBER_LOCATION_FORM';
export const SUBMIT_SARJ_DETAILS_FORM = '[service-cases] SUBMIT_SARJ_DETAILS_FORM';
export const SUBMIT_SARJ_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SARJ_COST_COVERAGE_FORM';
export const SUBMIT_SARJ_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SARJ_SERVICE_PROVIDER_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST';
export const SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SARJ_DESTINATION_CANDIDATES = '[service-cases] SET_SARJ_DESTINATION_CANDIDATES';
