import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase, camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentStatusTypes, apmGenericQMFeedbackCategoryTypes, apmQualityManagementFeedbackDecisionByTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, List, Option, ButtonSecondary, Form, Icon, SelectField} from '@ace-de/ui-components';
import {TextAreaField} from '@ace-de/ui-components/text-inputs';
import {InteractiveIcon, closeIcon, infoIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import {HighlightRectangle} from '@ace-de/ui-components/general';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentStatusCandidates from '../serviceAssignmentStatusCandidates';
import config from '../../config';
import styles from './ServiceAssignmentStatusUpdateModal.module.scss';

const ServiceAssignmentStatusUpdateModal = props => {
    const {cx} = useStyles(props, styles);
    const {hasBackdrop, serviceAssignment} = props;
    const {confirmSAVStatusUpdate, declineSAVStatusUpdate, serviceCase, match} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('service_assignment_status_update_modal');
    const [selectedStatus, setSelectedStatus] = useState(serviceAssignment?.assignmentStatus || null);
    const [reason, setReason] = useState(serviceAssignment?.rejectionReason || null);
    const availableStatuses = Object.keys(efServiceAssignmentStatusTypes).filter(filterStatusKey => {
        return (
            filterStatusKey !== efServiceAssignmentStatusTypes.APPROACHING
            && filterStatusKey !== efServiceAssignmentStatusTypes.IN_SERVICE
            && filterStatusKey !== efServiceAssignmentStatusTypes.SERVICE_FINISHED
        );
    });
    const cloningStatusCandidates = serviceAssignmentStatusCandidates.serviceAssignmentCloningStatusCandidates;

    const handleOnSubmit = statusUpdateFormData => {
        if (!statusUpdateFormData) {
            return;
        }

        const {serviceCaseId, lineNo} = serviceAssignment;

        confirmSAVStatusUpdate({
            serviceAssignmentLineNo: lineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: statusUpdateFormData['serviceAssignmentStatus'],
                ...(statusUpdateFormData['serviceAssignmentStatus']
                    === efServiceAssignmentStatusTypes.REJECTED && {
                    reason: statusUpdateFormData['reason'],
                    description: statusUpdateFormData['description'],
                    isContractPartnerConsulted: statusUpdateFormData['isContractPartnerConsulted'],
                    decisionBy: statusUpdateFormData['decisionBy'],
                }),
            },
            currentServiceCaseAssignmentStatus: efServiceAssignmentStatusTypes[serviceAssignment.assignmentStatus],
            redirectionPath: match.path,
        });
    };

    const getStatusCandidates = assignmentStatus => {
        switch (assignmentStatus) {
            case efServiceAssignmentStatusTypes.ACCEPTED: {
                return serviceAssignmentStatusCandidates.serviceAssignmentAcceptedStatusCandidates;
            }

            case efServiceAssignmentStatusTypes.REJECTED: {
                return serviceAssignmentStatusCandidates.serviceAssignmentRejectedCandidates;
            }

            case efServiceAssignmentStatusTypes.EUA_CANCELED: {
                return serviceAssignmentStatusCandidates.serviceAssignmentEUACancelledCandidates;
            }

            case efServiceAssignmentStatusTypes.DONE: {
                return serviceAssignmentStatusCandidates.serviceAssignmentDoneCandidates;
            }

            case efServiceAssignmentStatusTypes.CANCELED: {
                return serviceAssignmentStatusCandidates.serviceAssignmentCancelledCandidates;
            }

            case efServiceAssignmentStatusTypes.NEW: {
                return serviceAssignmentStatusCandidates.serviceAssignmentNewCandidates;
            }

            default: return [];
        }
    };

    if (!serviceAssignment || !serviceCase) return null;
    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSAVStatusUpdate}
                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                />
            )}
            hasBackdrop={hasBackdrop}
            className={cx('ace-c-modal--full-scrollable')}
        >
            <div className={cx('ace-c-select-assignment-status-wrapper')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-full-width',
                    ])}
                >
                    <div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-h3',
                                'global!ace-u-margin--bottom-24',
                            ])}
                        >
                            {translateModal('modal_title.adjust_status')}
                        </p>
                    </div>
                    <div>
                        <Form
                            name="statusUpdateForm"
                            onSubmit={handleOnSubmit}
                        >
                            {statusUpdateFormValues => {
                                return (
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-flex--align-flex-end',
                                        ])}
                                    >
                                        <List
                                            className={cx('global!ace-u-full-width', {
                                                'ace-c-status-update-modal--list': selectedStatus === efServiceAssignmentStatusTypes.REJECTED,
                                            })}
                                            name="serviceAssignmentStatus"
                                            value={selectedStatus}
                                            onChange={setSelectedStatus}
                                        >
                                            {availableStatuses.map(statusKey => {
                                                const statusCandidates = getStatusCandidates(statusKey);
                                                return (
                                                    statusCandidates.includes(serviceAssignment?.assignmentStatus) && (
                                                        <Option
                                                            value={statusKey}
                                                            key={`${statusKey}Key`}
                                                            name={`option${statusKey}`}
                                                            isDisabled={
                                                                serviceCase.persistenceState
                                                                === persistenceStates.PENDING
                                                            }
                                                        >
                                                            <div
                                                                className={cx([
                                                                    'global!ace-u-full-width',
                                                                    'global!ace-u-flex',
                                                                    'global!ace-u-flex--justify-space-between',
                                                                ])}
                                                            >
                                                                <div
                                                                    className={cx([
                                                                        'global!ace-u-flex',
                                                                        'global!ace-u-flex--align-center',
                                                                    ])}
                                                                >
                                                                    {translate(`global.service_assignment_status_type.${snakeCase(statusKey)}`).toLowerCase()}
                                                                </div>
                                                                {selectedStatus === statusKey && (
                                                                    <Icon
                                                                        icon={checkmarkIcon}
                                                                        className={cx('ace-c-icon--color-success')}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Option>
                                                    )
                                                );
                                            })}
                                            {cloningStatusCandidates.includes(serviceAssignment.assignmentStatus) && (
                                                <Option
                                                    value="CLONE_ASSIGNMENT"
                                                    key="cloneAssignmentKey"
                                                    name="optionCloneAssignment"
                                                    isDisabled={
                                                        serviceCase.persistenceState === persistenceStates.PENDING
                                                    }
                                                >
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-full-width',
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--justify-space-between',
                                                        ])}
                                                    >
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--align-center',
                                                            ])}
                                                        >
                                                            {translateModal(`service_assignment_status_option.clone_assignment`)}
                                                        </div>
                                                        {selectedStatus === 'CLONE_ASSIGNMENT' && (
                                                            <Icon
                                                                icon={checkmarkIcon}
                                                                className={cx('ace-c-icon--color-success')}
                                                            />
                                                        )}
                                                    </div>
                                                </Option>
                                            )}
                                        </List>
                                        {selectedStatus === efServiceAssignmentStatusTypes.REJECTED
                                        && (
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                ])}
                                            >
                                                <HighlightRectangle
                                                    className={cx([
                                                        'ace-c-highlight-rectangle--negative',
                                                        'global!ace-u-flex--align-flex-start',
                                                        'global!ace-u-margin--32-0',
                                                    ])}
                                                >
                                                    <Icon
                                                        className={cx([
                                                            'ace-c-icon--color-warning',
                                                            'global!ace-u-margin--right-8',
                                                        ])}
                                                        icon={infoIcon}
                                                    />
                                                    <span>
                                                        {translateModal(`message.${snakeCase(selectedStatus)}`)}
                                                    </span>
                                                </HighlightRectangle>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--column-gap-24',
                                                        'global!ace-u-margin--bottom-24',
                                                    ])}
                                                >
                                                    <SelectField
                                                        className={cx('global!ace-u-flex--basis-50')}
                                                        name="decisionBy"
                                                        label={translateModal('input_label.decision_by')}
                                                        value={apmQualityManagementFeedbackDecisionByTypes.EUA}
                                                        isFieldRequired={true}
                                                    >
                                                        <Option
                                                            value={apmQualityManagementFeedbackDecisionByTypes.EUA}
                                                            name="decisionByOptionEUA"
                                                        >
                                                            {translate('global.quality_management_feedback_decision_by.eua')}
                                                        </Option>
                                                        <Option
                                                            value={apmQualityManagementFeedbackDecisionByTypes.CONTRACT_PARTNER} // eslint-disable-line max-len
                                                            name="decisionByOptionCP"
                                                        >
                                                            {translate('global.quality_management_feedback_decision_by.contract_partner')}
                                                        </Option>
                                                    </SelectField>
                                                    <SelectField
                                                        className={cx('global!ace-u-flex--basis-50')}
                                                        name="isContractPartnerConsulted"
                                                        label={translateModal('input_label.cp_consultation')}
                                                        value={false}
                                                        isFieldRequired={true}
                                                    >
                                                        <Option
                                                            value={true}
                                                            name="isContractPartnerConsultedOptionYes"
                                                        >
                                                            {translateModal('is_cp_consulted_option.yes')}
                                                        </Option>
                                                        <Option
                                                            value={false}
                                                            name="isContractPartnerConsultedOptionNo"
                                                        >
                                                            {translateModal('is_cp_consulted_option.no')}
                                                        </Option>
                                                    </SelectField>
                                                </div>
                                                <SelectField
                                                    className={cx('global!ace-u-full-width')}
                                                    label={translateModal('select_label.rejection_reason')}
                                                    placeholder={translate('global.select.placeholder')}
                                                    name="reason"
                                                    value={reason}
                                                    onChange={value => setReason(value)}
                                                >
                                                    {Object.keys(apmGenericQMFeedbackCategoryTypes)
                                                        .filter(category => {
                                                            return ![
                                                                apmGenericQMFeedbackCategoryTypes.TOO_MUCH_TRAFFIC,
                                                                apmGenericQMFeedbackCategoryTypes.RESTRICTED_SERVICES,
                                                            ].includes(category);
                                                        }).map(category => {
                                                            return (
                                                                <Option
                                                                    value={category}
                                                                    name={`cancellationReason${camelCase(category)}`}
                                                                    key={`reason${camelCase(category)}`}
                                                                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING} // eslint-disable-line max-len
                                                                >
                                                                    {translate(`global.quality_management_feedback_category.${snakeCase(category)}`)}
                                                                </Option>
                                                            );
                                                        })
                                                    }
                                                </SelectField>
                                                <TextAreaField
                                                    className={cx('global!ace-u-margin--24-0-8')}
                                                    name="description"
                                                    label={translateModal(`text_area_label.rejection_reason_details`)}
                                                    placeholder={translateModal('text_area_placeholder.please_enter')}
                                                    isResizable={false}
                                                    value={serviceAssignment.rejectionReasonDescription || ''}
                                                    maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                                    isDisabled={
                                                        serviceCase.persistenceState === persistenceStates.PENDING
                                                    }
                                                />
                                                <p
                                                    className={cx([
                                                        'global!ace-u-margin--bottom-24',
                                                        'global!ace-u-typography--variant-caption',
                                                        'global!ace-u-typography--color-medium-emphasis',
                                                    ])}
                                                >
                                                    {`${statusUpdateFormValues.description
                                                        ? statusUpdateFormValues.description.length
                                                        : serviceAssignment.rejectionReasonDescription
                                                            ? serviceAssignment.rejectionReasonDescription.length
                                                            : 0
                                                    }/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                                     ${translate('global.unit.characters')}`
                                                    }
                                                </p>
                                                <p className={cx('global!ace-u-typography--color-default')}>
                                                    {translateModal(`description_text.${snakeCase(selectedStatus)}`)}
                                                </p>
                                            </div>
                                        )}
                                        <ButtonSecondary
                                            name="submitAssignmentStatus"
                                            type="submit"
                                            className={cx('global!ace-u-margin--top-24')}
                                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                            || (selectedStatus === efServiceAssignmentStatusTypes.REJECTED && !reason)}
                                        >
                                            {translateModal('button_label.set_status')}
                                        </ButtonSecondary>
                                    </div>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ServiceAssignmentStatusUpdateModal.propTypes = {
    confirmSAVStatusUpdate: PropTypes.func.isRequired,
    declineSAVStatusUpdate: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    match: PropTypes.object,
};

ServiceAssignmentStatusUpdateModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
    serviceCase: null,
    match: {},
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmSAVStatusUpdate: payload => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE,
        payload,
    }),
    declineSAVStatusUpdate: () => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_SERVICE_ASSIGNMENT_STATUS_UPDATE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAssignmentStatusUpdateModal);
