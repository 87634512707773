import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary, InputField, Form} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import {validateEmail, validateFax} from '../../utils/validation';

const ResendServiceAssignmentModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, declineResendServiceAssignment, confirmResendServiceAssignment} = props;
    const {serviceCase, serviceAssignment, errorCode} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('resend_service_assignment_modal');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isFaxNoValid, setIsFaxNoValid] = useState(true);
    const {businessContactDetails} = serviceAssignment?.acePartner;
    const disallowedSymbols = ['Tab', ' '];

    const handleOnSubmit = formValues => {
        let hasError = false;

        if (formValues['email'] && !validateEmail(formValues['email'])) {
            setIsEmailValid(false);
            hasError = true;
        } else if (!isEmailValid) setIsEmailValid(true);

        if (formValues['faxNo'] && !validateFax(formValues['faxNo'])) {
            setIsFaxNoValid(false);
            hasError = true;
        } else if (!isFaxNoValid) setIsFaxNoValid(true);

        if (hasError) return;

        confirmResendServiceAssignment({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                email: formValues['email'],
                faxNo: formValues['faxNo'] || null,
            },
        });
    };

    if (!serviceCase || !serviceAssignment) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            title={translateModal('title.send_again')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineResendServiceAssignment}
                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                    'global!ace-u-typography--variant-body',
                ])}
            >
                <div className={cx('global!ace-u-full-width')}>
                    <Form name="resendServiceAssignmentForm" onSubmit={handleOnSubmit}>
                        {formValues => {
                            return (
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-flex-start',
                                        'global!ace-u-margin--top-32',
                                    ])}
                                >
                                    <InputField
                                        name="email"
                                        label={translateModal('input_label.email')}
                                        value={businessContactDetails?.email || ''}
                                        className={cx('global!ace-u-full-width')}
                                        errors={!isEmailValid ? [translateModal('text.email_error_message')] : []}
                                        disallowedSymbols={disallowedSymbols}
                                    />
                                    <InputField
                                        name="faxNo"
                                        label={translateModal('input_label.fax_no')}
                                        value={businessContactDetails?.faxNo || ''}
                                        className={cx(['global!ace-u-full-width', 'global!ace-u-margin--top-8'])}
                                        errors={!isFaxNoValid ? [translateModal('text.fax_no_error_message')] : []}
                                        disallowedSymbols={disallowedSymbols}
                                    />
                                    {errorCode && (
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-margin--8-0',
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-typography--color-warning',
                                            ])}
                                        >
                                            <Icon
                                                icon={infoAlertIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-4',
                                                    'ace-c-icon--s',
                                                    'ace-c-icon--color-warning',
                                                ])}
                                            />
                                            {translateModal(`error_message.resend_failed`, {errorCode: errorCode || ''})}
                                        </div>
                                    )}
                                    <ButtonPrimary
                                        name="resendAssignmentButton"
                                        type="submit"
                                        isDisabled={!(formValues.email || formValues.faxNo)
                                            || serviceAssignment.persistenceState === persistenceStates.PENDING}
                                        className={cx('global!ace-u-margin--top-32')}
                                    >
                                        {errorCode ? translateModal('button_label.try_again') : translateModal('button_label.send')}
                                    </ButtonPrimary>
                                </div>
                            );
                        }}
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

ResendServiceAssignmentModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineResendServiceAssignment: PropTypes.func.isRequired,
    confirmResendServiceAssignment: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    errorCode: PropTypes.string,
};

ResendServiceAssignmentModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
    serviceCase: null,
    errorCode: '',
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
        errorCode: serviceAssignmentSelectors.getErrorCode(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineResendServiceAssignment: () => dispatch({
        type: serviceAssignmentActionTypes.DECLINE_RESEND_SERVICE_ASSIGNMENT,
    }),
    confirmResendServiceAssignment: payload => dispatch({
        type: serviceAssignmentActionTypes.CONFIRM_RESEND_SERVICE_ASSIGNMENT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendServiceAssignmentModal);
