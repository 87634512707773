import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getServiceAssignments = state => state.serviceAssignments.serviceAssignments;
export const getACEPartners = state => state.serviceAssignments.rentalCarACEPartners;
export const getReturnJourneyServiceProviders = state => state.serviceAssignments.returnJourneyServiceProviders;
export const getOtherServicesServiceProviders = state => state.serviceAssignments.otherServicesServiceProviders;
export const getSelectedPOIsCategory = state => state.serviceAssignments.selectedPOIsCategory;
export const getPickupLocationToTowingDestinationRoute = state => {
    return state.serviceAssignments.pickupLocationToTowingDestinationRoute;
};
export const getErrorCode = state => state.serviceAssignments.errorCode;

/**
 * Returns selector for ServiceAssignment matched from route
 */
export const createServiceAssignmentSelector = () => createSelector(
    [
        getServiceAssignments,
        (state, props) => {
            const {match, location} = props;
            return match?.params['serviceCaseId'] || location?.query['serviceCaseId'];
        },
        (state, props) => {
            const {match, location} = props;
            if (!match && !location) return null;

            return match?.params
                ? match.params['serviceAssignmentLineNo']
                : location?.query
                    ? location.query['serviceAssignmentLineNo']
                    : null;
        },
    ],
    (serviceAssignments, serviceCaseId, serviceAssignmentLineNo) => {
        if (!serviceAssignmentLineNo || !serviceCaseId) return null;

        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        return serviceAssignments[serviceAssignmentId];
    },
);
