import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useStyles, Divider} from '@ace-de/ui-components';
import {AppLayoutMain, Panel, PanelHeader, ContentBlock, ContentItem} from '@ace-de/ui-components/app-layout';
import {Form, SelectField, Option, ButtonPrimary} from '@ace-de/ui-components/form';
import {useTranslate} from '@computerrock/formation-i18n';
import PanelLogo from './ui-elements/PanelLogo';
import * as userProfileActionTypes from './userProfileActionTypes';
import * as userProfileSelectors from './userProfileSelectors';

const ShiftRoleSelectionScreen = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('shift_role_selection_screen');
    const {user, allowedShiftRoles, submitChangeUserShiftRoleForm, shiftRoleUpdateErrorCode} = props;

    const onFormSubmit = ({shiftRole}) => {
        submitChangeUserShiftRoleForm({shiftRole});
    };

    const shiftRoles = user && allowedShiftRoles
        ? (user.shiftRole ? allowedShiftRoles.filter(role => role !== user.shiftRole) : allowedShiftRoles)
        : [];
    const selectDefaultValue = shiftRoles.includes('EMERGENCY_CALL_ADVISOR') ? 'EMERGENCY_CALL_ADVISOR' : shiftRoles[0];

    return (
        <AppLayoutMain>
            <ContentBlock
                className={cx([
                    'global!ace-u-full-height',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'ace-c-content-item--large-span-4',
                    ])}
                >
                    <Panel className={cx('ace-c-panel--full-bleed')}>
                        <PanelHeader>
                            <PanelLogo />
                        </PanelHeader>
                        <Divider />
                        <div className={cx('global!ace-u-padding--48-32')}>
                            <ContentBlock
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--direction-column',
                                ])}
                            >
                                <ContentItem className={cx('global!ace-u-typography--align-center')}>
                                    <h2 className={cx([
                                        'global!ace-u-typography--variant-h2',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                    >
                                        {translateScreen('heading_text.select_role')}
                                    </h2>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        {`Hallo ${user?.firstName || ''}${user?.lastName ? ` ${user.lastName}` : ''}! Bitte wählen Sie ihre Rolle aus.`}
                                    </p>
                                </ContentItem>
                                <ContentItem className={cx([
                                    'ace-c-content-item--large-span-12',
                                ])}
                                >
                                    <Form name="shiftRoleForm" onSubmit={onFormSubmit}>
                                        {() => {
                                            return (
                                                <Fragment>
                                                    <div className={cx('global!ace-u-margin--bottom-128')}>
                                                        <SelectField
                                                            name="shiftRole"
                                                            placeholder="Please select value..."
                                                            isDisabled={false}
                                                            label="Role"
                                                            className={cx('global!ace-u-full-width')}
                                                            value={selectDefaultValue}
                                                        >
                                                            {shiftRoles.map(role => {
                                                                const key = `${role}-shift-role`;
                                                                return (
                                                                    <Option
                                                                        key={key}
                                                                        name={key}
                                                                        value={role}
                                                                    >
                                                                        {translate(`global.user_shift_role.${snakeCase(role)}`)}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </SelectField>
                                                    </div>
                                                    <ContentBlock
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                            'global!ace-u-flex--justify-center',
                                                        ])}
                                                    >
                                                        <ContentItem className={cx([
                                                            'ace-c-content-item--large-span-6',
                                                        ])}
                                                        >
                                                            <ButtonPrimary
                                                                name="submitButton"
                                                                type="submit"
                                                                className={cx('global!ace-u-full-width')}
                                                            >
                                                                {translateScreen('button_label.submit')}
                                                            </ButtonPrimary>
                                                            {!!shiftRoleUpdateErrorCode && (
                                                                <p
                                                                    className={cx([
                                                                        'global!ace-u-typography--color-warning',
                                                                        'global!ace-u-typography--variant-caption',
                                                                        'global!ace-u-margin--top-4',
                                                                    ])}
                                                                >
                                                                    {translateScreen('error_message.update_user_shift_role_failed')}
                                                                </p>
                                                            )}
                                                        </ContentItem>
                                                    </ContentBlock>
                                                </Fragment>
                                            );
                                        }}
                                    </Form>
                                </ContentItem>
                            </ContentBlock>
                        </div>
                    </Panel>
                </ContentItem>
            </ContentBlock>
        </AppLayoutMain>
    );
};

ShiftRoleSelectionScreen.propTypes = {
    user: PropTypes.object,
    allowedShiftRoles: PropTypes.array,
    submitChangeUserShiftRoleForm: PropTypes.func.isRequired,
    shiftRoleUpdateErrorCode: PropTypes.string,
};

ShiftRoleSelectionScreen.defaultProps = {
    user: null,
    allowedShiftRoles: null,
    shiftRoleUpdateErrorCode: '',
};

const mapStateToProps = state => ({
    user: userProfileSelectors.getUser(state),
    allowedShiftRoles: userProfileSelectors.getAllowedShiftRoles(state),
    shiftRoleUpdateErrorCode: state.user.shiftRoleUpdateErrorCode,
});

const mapDispatchToProps = dispatch => ({
    submitChangeUserShiftRoleForm: payload => dispatch({
        type: userProfileActionTypes.SUBMIT_CHANGE_USER_SHIFT_ROLE_FORM,
        payload,
    }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShiftRoleSelectionScreen);
