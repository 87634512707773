import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import VehicleAssignmentDetailsTable from './ui-elements/VehicleAssignmentDetailsTable';
import RentalCarAssignmentDetailsTable from './ui-elements/RentalCarAssignmentDetailsTable';
import AccommodationAssignmentDetailsTable from './ui-elements/AccommodationAssignmentDetailsTable';
import ShortTripAssignmentDetailsTable from './ui-elements/ShortTripAssignmentDetailsTable';
import ReturnJourneyAssignmentDetailsTable from './ui-elements/ReturnJourneyAssignmentDetailsTable';
import SubstituteDriverAssignmentDetailsTable from './ui-elements/SubstituteDriverAssignmentDetailsTable';
import MedicalClearanceAssignmentDetailsTable from './ui-elements/MedicalClearanceAssignmentDetailsTable';
import SickPersonAssignmentDetailsTable from './ui-elements/SickPersonAssignmentDetailsTable';
import VehicleReturnAssignmentDetailsTable from './ui-elements/VehicleReturnAssignmentDetailsTable';
import VehicleAdditionalAssignmentDetailsTable from './ui-elements/VehicleAdditionalAssignmentDetailsTable';
import OtherServicesAssignmentDetailsTable from './ui-elements/OtherServicesAssignmentDetailsTable';
import PickupAssignmentDetailsTable from './ui-elements/PickupAssignmentDetailsTable';
import TrailerReturnAssignmentDetailsTable from './ui-elements/TrailerReturnAssignmentDetailsTable';
import AssignmentDetailsPanel from './ui-elements/AssignmentDetailsPanel';
import RemainingBudgetPanel from './ui-elements/RemainingBudgetPanel';
import DeathEventHelpAssignmentDetailsTable from './ui-elements/DeathEventHelpAssignmentDetailsTable';
import MedicineShipmentAssignmentDetailsTable from './ui-elements/MedicineShipmentAssignmentDetailsTable';
import MedicalAdviceAssignmentDetailsTable from './ui-elements/MedicalAdviceAssignmentDetailsTable';
import LegalAdviceAssignmentDetailsTable from './ui-elements/LegalAdviceAssignmentDetailsTable';

const SCServiceAssignmentsTab = props => {
    const {cx} = useStyles();
    const {serviceCase, serviceAssignments, users} = props;
    const sortedServiceAssignments = useMemo(() => {
        if (!serviceAssignments?.length) return [];
        return serviceAssignments
            .sort((assignmentA, assignmentB) => {
                return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
            });
    }, [serviceAssignments]);

    // if no service case and assignments don't render
    if (!serviceCase || !sortedServiceAssignments) return null;

    return (
        <ContentBlock>
            {sortedServiceAssignments.length > 0 && (
                <Fragment>
                    <ContentItem className={cx('ace-c-content-item--span-9')}>
                        {sortedServiceAssignments.map(serviceAssignment => {
                            const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
                                || parseInt(serviceAssignment.lineNo, 10) === 1
                                || serviceAssignment.isCloneFromInitial;

                            return (
                                <AssignmentDetailsPanel
                                    key={`${serviceCase.id}-${serviceAssignment.lineNo}`}
                                    serviceAssignment={serviceAssignment}
                                    serviceCase={serviceCase}
                                >
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                                        && isInitialAssignment && (
                                            <VehicleAssignmentDetailsTable
                                                serviceAssignment={serviceAssignment}
                                                serviceCase={serviceCase}
                                                agentUserProfile={users[serviceAssignment.createdBy]}
                                            />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                                    && !isInitialAssignment
                                    && (
                                        <VehicleAdditionalAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.RENTAL_CAR && (
                                        <RentalCarAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.ACCOMMODATION && (
                                        <AccommodationAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.SHORT_TRIP && (
                                        <ShortTripAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.RETURN_JOURNEY && (
                                        <ReturnJourneyAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.SUBSTITUTE_DRIVER && ( // eslint-disable-line max-len
                                        <SubstituteDriverAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.MEDICAL_CLEARANCE && ( // eslint-disable-line max-len
                                        <MedicalClearanceAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.SICK_PERSON && (
                                        <SickPersonAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE_RETURN && (
                                        <VehicleReturnAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.OTHER_SERVICES && (
                                        <OtherServicesAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.PICKUP && (
                                        <PickupAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.TRAILER_RETURN && (
                                        <TrailerReturnAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.DEATH && (
                                        <DeathEventHelpAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.MEDICINE_SHIPMENT && ( // eslint-disable-line max-len
                                        <MedicineShipmentAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.LEGAL_ADVICE && (
                                        <LegalAdviceAssignmentDetailsTable
                                            serviceCase={serviceCase}
                                            serviceAssignment={serviceAssignment}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.MEDICAL_ADVICE && (
                                        <MedicalAdviceAssignmentDetailsTable
                                            serviceAssignment={serviceAssignment}
                                            serviceCase={serviceCase}
                                            agentUserProfile={users[serviceAssignment.createdBy]}
                                        />
                                    )}
                                </AssignmentDetailsPanel>
                            );
                        })}

                    </ContentItem>
                    <ContentItem className={cx('ace-c-content-item--span-3')}>
                        <RemainingBudgetPanel />
                    </ContentItem>
                </Fragment>
            )}
        </ContentBlock>
    );
};

SCServiceAssignmentsTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.array,
    users: PropTypes.object,
};

SCServiceAssignmentsTab.defaultProps = {
    serviceCase: null,
    serviceAssignments: null,
    users: {},
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
        users: userProfileSelectors.getUsers(state),
    };
};

export default withRouter(connect(mapStateToProps, null)(SCServiceAssignmentsTab));
