import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {withRouter, useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceTypes, efServiceCaseStatusTypes, efServiceCaseTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, TableBody, Pill, Panel, NoResultsBlock, LabelWithIcon, Badge} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, breakdownIcon, deleteIcon, historyIcon, vehicleOpeningIcon, recoveryIcon, towingIcon, noServiceIcon, linkIcon, otherIcon, deathCaseIcon, hospitalIcon, healingIcon, legalIcon, medicationIcon, personOffIcon} from '@ace-de/ui-components/icons';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';
import routePaths from '../../routePaths';
import * as memberActionTypes from '../memberActionTypes';
import * as memberSelectors from '../memberSelectors';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';

const serviceIcons = {
    [efServiceTypes.VEHICLE_OPENING]: vehicleOpeningIcon,
    [efServiceTypes.RECOVERY]: recoveryIcon,
    [efServiceTypes.ROADSIDE_ASSISTANCE]: breakdownIcon,
    [efServiceTypes.TOWING]: towingIcon,
    [efServiceTypes.NO_SERVICE]: noServiceIcon,
    [efServiceAssignmentTypes.DEATH]: deathCaseIcon,
    [efServiceAssignmentTypes.MEDICAL_CLEARANCE]: hospitalIcon,
    [efServiceAssignmentTypes.SUBSTITUTE_DRIVER]: personOffIcon,
    [efServiceAssignmentTypes.MEDICINE_SHIPMENT]: medicationIcon,
    [efServiceAssignmentTypes.MEDICAL_ADVICE]: healingIcon,
    [efServiceAssignmentTypes.LEGAL_ADVICE]: legalIcon,
};

const MemberServiceCasesPanel = ({member, memberServiceCases, initiateServiceCaseDeletionFlow, requestState}) => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const {isInProgress, translationKey, error} = requestState;
    const isCaseDeletionAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.DELETE,
    );

    const openServiceCaseScreen = serviceCase => {
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceCase.id,
        }, {
            state: {previousPath: history.location.pathname},
        }));
    };

    const openServiceCaseSearchScreen = () => {
        const {pathname} = resolveRoute(routePaths.SERVICE_CASE_SEARCH);
        window.open(`${pathname}?membershipNo=${member.membershipNo}`, '_blank');
    };

    const handleCaseDeletion = (event, serviceCaseId) => {
        event.stopPropagation();
        if (!serviceCaseId) return;
        initiateServiceCaseDeletionFlow({serviceCaseId});
    };

    return (
        <Panel
            title={translateScreen('case_history_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={(
                <InteractiveIcon
                    icon={linkIcon}
                    onClick={openServiceCaseSearchScreen}
                />
            )}
        >
            <Table qaIdent="member-service-cases">
                {memberServiceCases.length === 0 && !error && !isInProgress && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={historyIcon}
                                />
                            )}
                            description={translateScreen('case_history_panel_no_results.description')}
                            message={translateScreen('case_history_panel_no_results.message')}
                        />
                    </TableCaption>
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="sc-created-at">
                            {translateScreen('case_history_data_row.created_at')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="sc-status">
                            {translateScreen('case_history_data_row.status')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="sc-id">
                            {translateScreen('case_history_data_row.case_id')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="vehicle-licence-plate-number">
                            {translateScreen('case_history_data_row.license_plate')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="damage-location-address">
                            {translateScreen('case_history_data_row.damage_location')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="sc-service-type">
                            {translateScreen('case_history_data_row.service')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-is-legal-case-ongoing" />
                        <TableCell qaIdentPart="sc-deletion" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberServiceCases?.length > 0
                    && !isInProgress
                    && !error
                    && memberServiceCases.map(serviceCase => {
                        const emergencyCallDate = serviceCase.emergencyCallDate
                            ? moment(serviceCase.emergencyCallDate).format('DD.MM.YYYY') : '';
                        const {vehicle, damage} = serviceCase;

                        return (
                            <TableRow
                                key={serviceCase.id}
                                onClick={() => openServiceCaseScreen(serviceCase)}
                                qaIdentPart={serviceCase.id}
                            >
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="sc-created-at"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {emergencyCallDate}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="sc-status"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                                        <Pill
                                            type={serviceCase.status === efServiceCaseStatusTypes.NEW
                                                ? 'positive' : 'pending'}
                                        >
                                            {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                                        </Pill>
                                    )}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="sc-id"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.id}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="vehicle-licence-plate-number"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="damage-location-address"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {damage && damage.location
                                        ? `${damage.location.city} ${damage.location.postCode}`
                                        : ''}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="sc-service-type"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.serviceType && (
                                        <LabelWithIcon
                                            className={cx('ace-c-label-with-icon--icon-reverse')}
                                            label={
                                                serviceCase.serviceType
                                                    ? (serviceCase?.caseType === efServiceCaseTypes.HOUSEHOLD
                                                        ? translate(`global.other_services_service_type.${serviceCase.serviceType.toLowerCase()}`)
                                                        : translate(`global.service_type.${serviceCase.serviceType.toLowerCase()}`))
                                                    : ''}
                                        >
                                            <Icon
                                                icon={serviceCase?.caseType === efServiceCaseTypes.HOUSEHOLD
                                                    ? otherIcon
                                                    : serviceIcons[serviceCase.serviceType]}
                                            />
                                        </LabelWithIcon>
                                    )}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-ongoing-legal-case"
                                    qaIdentPartPostfix={serviceCase.id}
                                    className={cx('ace-c-table__cell--text-align-center')}
                                >
                                    {serviceCase?.isLegalCaseOngoing && (
                                        <Badge status="negative">
                                            §
                                        </Badge>
                                    )}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-delete"
                                    qaIdentPartPostfix={serviceCase.id}
                                    className={cx('ace-c-table__cell--text-align-center')}
                                >
                                    {(!config.IS_FALLBACK_SYSTEM
                                        && isCaseDeletionAvailable
                                        && !!serviceCase.isEligibleForDeletion) && (
                                            <InteractiveIcon
                                                icon={deleteIcon}
                                                onClick={e => { handleCaseDeletion(e, serviceCase.id); }}
                                            />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

MemberServiceCasesPanel.propTypes = {
    memberServiceCases: PropTypes.array,
    member: PropTypes.object,
    initiateServiceCaseDeletionFlow: PropTypes.func,
    requestState: PropTypes.object,
};

MemberServiceCasesPanel.defaultProps = {
    member: null,
    memberServiceCases: [],
    initiateServiceCaseDeletionFlow: () => {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    const memberServiceCasesSelector = memberSelectors.createMemberServiceCasesSelector();
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST);
    return {
        member: memberByMembershipNoSelector(state, props),
        memberServiceCases: memberServiceCasesSelector(state, props),
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateServiceCaseDeletionFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_DELETE_SERVICE_CASE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberServiceCasesPanel));
