import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sapActionTypes from '../sapActionTypes';

/**
 * Update SAP required location (pickupLocation, destination) flow
 */
const updateSAPRequiredLocationFlow = function* updateSAPRequiredLocationFlow() {
    while (true) {
        const {payload} = yield take(sapActionTypes.SUBMIT_SAP_REQUIRED_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sapActionTypes.SUBMIT_SAP_REQUIRED_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.PICKUP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });
    }
};

export default updateSAPRequiredLocationFlow;
