import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {apmContractPartnerAssignmentChannelTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as satrActionTypes from './satrActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageCommissioningPanel from './ui-elements/CostCoverageCommissioningPanel';
import CostCoverageObjectsInTrailerPanel from './ui-elements/CostCoverageObjectsInTrailerPanel';
import CostCoverageTrailerStateDetailsPanel from './ui-elements/CostCoverageTrailerStateDetailsPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import config from '../config';
import TrailerInfoPanel from '../service-assignments/ui-elements/TrailerInfoPanel';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SATRCostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, submitSATRCommissioningForm, users} = props;
    const {submitSATRCostCoverageServiceProviderForm, submitCreateSATRPDF} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment?.selfPayerOption,
        selfPayerNote: serviceAssignment?.selfPayerNote,
    });
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [additionalInformationText, setAdditionalInformationText] = useState(serviceAssignment?.additionalInformationText || '');
    const [agreedPrice, setAgreedPrice] = useState(serviceAssignment?.agreedPrice ? `${serviceAssignment.agreedPrice}` : '');
    const serviceAssignmentTypeKey = serviceAssignment?.assignmentType.toLowerCase();
    const assignmentDefaultText = (serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`) ? translate(`global.default_assignment_text.${serviceAssignmentTypeKey}`) : '';
    const [assignmentText, setAssignmentText] = useState(
        serviceAssignment?.assignmentText === null
            ? assignmentDefaultText
            : serviceAssignment?.assignmentText,
    );
    const assignmentTextRef = useRef(assignmentText);
    const agreedPriceRef = useRef(agreedPrice);
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment?.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [additionalNotes, setAdditionalNotes] = useState(serviceCase?.damage?.additionalNotes || '');
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    const onChangeHandler = (key, value) => {
        if (key === 'additionalInformationText') {
            setAdditionalInformationText(value);
            return;
        }

        if (key === 'additionalNotes') {
            setAdditionalNotes(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'agreedPrice') {
            setAgreedPrice(value);
            return;
        }

        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
                selfPayerNote: '',
            });
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
        }
    };

    useEffect(() => {
        if (serviceAssignment.assignmentText !== assignmentTextRef.current) {
            assignmentTextRef.current = serviceAssignment.assignmentText;
            setAssignmentText(serviceAssignment.assignmentText);
        }
    }, [serviceAssignment?.assignmentText, setAssignmentText]);

    useEffect(() => {
        if (serviceAssignment.agreedPrice !== agreedPriceRef.current) {
            agreedPriceRef.current = serviceAssignment.agreedPrice;
            setAgreedPrice(serviceAssignment.agreedPrice);
        }
    }, [serviceAssignment?.agreedPrice, setAgreedPrice]);

    useEffect(() => {
        setAdditionalInformationText(serviceAssignment.additionalInformationText);
        setCallbackPhoneNo(serviceAssignment.callbackPhoneNo);
    }, [serviceAssignment?.additionalInformationText, serviceAssignment?.callbackPhoneNo]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM,
            });
            return;
        }
        submitSATRCommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalInformationText,
                assignmentText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                agreedPrice,
            },
            serviceCaseData: {
                damage: {
                    additionalNotes: additionalNotes,
                },
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        submitSATRCommissioningForm,
        additionalInformationText,
        agreedPrice,
        assignmentText,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        maximumBudget,
        additionalNotes,
        serviceCase.nonAceMember,
        serviceCase.caseType,
        attachmentsToSendWithAssignment,
    ]);

    const handleAssignmentSubmit = costCoverageData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            additionalInformationText,
            assignmentText,
            agreedPrice,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            ...costCoverageData,
        };

        submitSATRCostCoverageServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.TRAILER_RETURN,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    const submitCreatePDF = costCoverageData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            additionalInformationText,
            assignmentText,
            agreedPrice,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            ...costCoverageData,
        };

        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);

        submitCreateSATRPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentType: efServiceAssignmentTypes.TRAILER_RETURN,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };
    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    onChangeHandler={onChangeHandler}
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    additionalInformationText={additionalInformationText}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageCommissioningPanel
                    onChangeHandler={onChangeHandler}
                    additionalNotes={additionalNotes}
                    agreedPrice={agreedPrice}
                    serviceCase={serviceCase}
                    assignmentText={assignmentText || ''}
                />
                {serviceCase.trailer ? (
                    <TrailerInfoPanel />
                ) : null}
                <CostCoverageTrailerStateDetailsPanel serviceAssignment={serviceAssignment} />
                <CostCoverageObjectsInTrailerPanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    assignmentChannels={[
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                    ]}
                    handleOnAssignmentSubmit={handleAssignmentSubmit}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SATRCostCoverageTab.propTypes = {
    submitSATRCommissioningForm: PropTypes.func.isRequired,
    submitCreateSATRPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSATRCostCoverageServiceProviderForm: PropTypes.func,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SATRCostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    submitSATRCostCoverageServiceProviderForm: () => {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSATRCommissioningForm: payload => dispatch({
        type: satrActionTypes.SUBMIT_SATR_COST_COVERAGE_FORM,
        payload,
    }),
    submitSATRCostCoverageServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSATRPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SATRCostCoverageTab));
