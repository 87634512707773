import {put, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';

/**
 * Service case vehicle selection flow (uses modal)
 * patches the selected vehicle snapshot on a case
 */
const selectServiceCaseVehicleFlow = function* selectServiceCaseVehicleFlow() {
    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_SELECT_SERVICE_CASE_VEHICLE_FLOW);
        const {serviceCaseData, resetAssignmentText, serviceCaseId, serviceAssignments, caseType} = payload;

        yield* openModal(modalIds.CONFIRM_VEHICLE_SELECTION);
        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_SELECT_SERVICE_CASE_VEHICLE,
            serviceCaseActionTypes.DECLINE_SELECT_SERVICE_CASE_VEHICLE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceCaseActionTypes.CONFIRM_SELECT_SERVICE_CASE_VEHICLE) {
            yield* updateServiceCase({
                caller: serviceCaseActionTypes.CONFIRM_SELECT_SERVICE_CASE_VEHICLE,
                caseType,
                serviceCaseId,
                serviceCaseData,
            });

            if (resetAssignmentText) {
                yield put({
                    type: serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW,
                    payload: {
                        serviceCaseId,
                        serviceAssignments,
                        serviceAssignmentData: {
                            assignmentText: null,
                        },
                    },
                });
            }
        }

        yield* closeModal(modalIds.CONFIRM_VEHICLE_SELECTION);
    }
};

export default selectServiceCaseVehicleFlow;
