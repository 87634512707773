import {select, take, fork, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/*
* Service assignment: delete QM feedback draft flow
*/
const deleteServiceAssignmentQMFeedbackDraft = function* deleteServiceAssignmentQMFeedbackDraft(params) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    const {serviceCaseId, serviceAssignmentLineNo, qmFeedbackDraftId} = params;

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST,
        partnerManagementService.deleteQualityManagementFeedback, {
            qualityManagementFeedbackId: qmFeedbackDraftId,
        },
    );

    const responseAction = yield take([
        serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('isDraft', 'true');
        searchQueryParams.append('caseId', serviceCaseId);
        searchQueryParams.append('lineNo', serviceAssignmentLineNo);

        yield fork(
            fetchRequest,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST,
            partnerManagementService.getQualityManagementFeedbacks, {
                searchQueryParams,
            },
        );

        const getQMDraftResponseAction = yield take([
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED,
        ]);

        if (!getQMDraftResponseAction.error) {
            const {response} = getQMDraftResponseAction.payload;
            const {acePartnerQualityManagementFeedbackDTOs} = response;

            yield put({
                type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS,
                payload: {
                    qmFeedbackDraftDTOs: acePartnerQualityManagementFeedbackDTOs,
                    serviceAssignmentId: `${serviceCaseId}-${serviceAssignmentLineNo}`,
                },
            });
        }
    }
};

export default deleteServiceAssignmentQMFeedbackDraft;
