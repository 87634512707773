import {ACEPartner} from '@ace-de/eua-entity-types';

const createDefaultAlternativeServiceProvider = () => {
    return new ACEPartner({
        contactDetails: {
            phoneNo: '',
            faxNo: '',
            email: '',
        },
        businessContactDetails: {
            phoneNo: '',
            faxNo: '',
            email: '',
        },
        location: {
            street: '',
            postCode: '',
            city: '',
        },
    });
};

export default createDefaultAlternativeServiceProvider;
