import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Panel} from '@ace-de/ui-components';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import ContactDetailsPanelItem from './ContactDetailsPanelItem';

const ContactDetailsPanel = props => {
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {serviceCaseAssignments} = props;

    return (
        <Panel title={translateTab('panel_title.contact_details')}>
            {serviceCaseAssignments?.length > 0
                ? (serviceCaseAssignments.map(serviceAssignment => (
                    <ContactDetailsPanelItem
                        key={serviceAssignment.lineNo}
                        serviceAssignment={serviceAssignment}
                    />
                ))
                ) : null}
        </Panel>
    );
};

ContactDetailsPanel.propTypes = {
    serviceCaseAssignments: PropTypes.array,
};

ContactDetailsPanel.defaultProps = {
    serviceCaseAssignments: [],
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceCaseAssignments: serviceCaseAssignmentsSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContactDetailsPanel));
