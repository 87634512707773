import {put, select, take, fork, call, all} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as savaActionTypes from '../savaActionTypes';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const searchSAVATowingDestinations = function* searchSAVATowingDestinations({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, searchQuery} = payload;

    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    if (!serviceAssignment || !serviceAssignment.serviceLocation) return;

    yield put({
        type: savaActionTypes.SET_SAVA_TOWING_DESTINATION_RECOMMENDATIONS,
        payload: {serviceAssignmentId, towingDestinationRecommendationDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const googlePlacesService = serviceManager.loadService('googlePlacesService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');

    const {serviceLocation} = serviceAssignment;
    if (!arcGISMap || !serviceLocation.longitude || !serviceLocation.latitude) return;

    const savaTowingDestinationsLayer = yield call(arcGISMap.getLayer, 'sava-towing-destinations');
    const savaTowingDestinationRoutesLayer = yield call(arcGISMap.getLayer, 'sava-towing-destination-routes');
    if (!savaTowingDestinationsLayer || !savaTowingDestinationRoutesLayer) return;

    const {longestDistance} = yield call(arcGISMap.getLongestDistanceFromPoint, serviceLocation);
    const mapViewport = yield call(arcGISMap.getMapViewport);
    yield fork(
        fetchRequest,
        savaActionTypes.SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST,
        googlePlacesService.searchPlaces,
        {
            searchQuery: searchQuery,
            longitude: serviceLocation.longitude,
            latitude: serviceLocation.latitude,
            locationRadius: Math.floor(longestDistance) * 1000 || null,
            viewport: mapViewport
                ? {
                    southwest: mapViewport.southwest,
                    northeast: mapViewport.northeast,
                }
                : null,
        },
    );

    const placesSearchResponseAction = yield take([
        savaActionTypes.SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_FAILED,
        savaActionTypes.SEARCH_SAVA_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SUCCEEDED,
    ]);

    if (placesSearchResponseAction.error) return;

    const {response: placesSearchResponse} = placesSearchResponseAction.payload;
    const {googlePlaceDTOs} = placesSearchResponse;

    const towingDestinationAddressList = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        if (googlePlaceDTO.formattedAddress) {
            towingDestinationAddressList.push({
                'OBJECTID': index, // should always be OBJECTID (not FID) for searchBulkAddressLocations
                'SingleLine': googlePlaceDTO.formattedAddress,
            });
        }
    });

    yield fork(
        fetchRequest,
        savaActionTypes.FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST,
        arcGISRESTService.searchBulkAddressLocations,
        {
            addressList: towingDestinationAddressList,
            returnRoutes: true,
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            referentialPoint: serviceLocation,
        },
    );

    const fetchGeolocationsResponseAction = yield take([
        savaActionTypes.FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SUCCEEDED,
        savaActionTypes.FETCH_SAVA_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_FAILED,
    ]);

    if (fetchGeolocationsResponseAction.error) return;

    const {response: fetchGeolocationsResponse} = fetchGeolocationsResponseAction.payload;
    const {arcGISGeocodingResults} = fetchGeolocationsResponse;

    let towingDestinationRecommendationDTOs = [];
    const uniqueTowingDestinationLocationIds = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        const arcGISGeocodingCandidateDTO = arcGISGeocodingResults.find(arcGISGeocodingCandidateDTO => {
            return arcGISGeocodingCandidateDTO.resultId === index;
        });
        if (!arcGISGeocodingCandidateDTO) return;

        const locationId = `${googlePlaceDTO.locationId}_${arcGISGeocodingCandidateDTO.locationId}`;
        if (!uniqueTowingDestinationLocationIds.includes(locationId)) {
            uniqueTowingDestinationLocationIds.push(locationId);
            towingDestinationRecommendationDTOs.push(
                {
                    ...googlePlaceDTO,
                    ...arcGISGeocodingCandidateDTO,
                    placesId: googlePlaceDTO.locationId,
                    locationId,
                    locationName: googlePlaceDTO.locationName,
                    formattedAddress: googlePlaceDTO.formattedAddress,
                },
            );
        }
    });

    // Determine if selected towing destination is within suggestion results
    let selectedLocationFromSuggestions = null;
    if (serviceAssignment.towingDestination) {
        const {id, locationName} = serviceAssignment.towingDestination;

        selectedLocationFromSuggestions = towingDestinationRecommendationDTOs
            .find(towingDestinationDTO => {
                const placesIdBase = towingDestinationDTO['placesId'].split('--')[0];
                const locationIdExtent = towingDestinationDTO['locationId'].split('_')[1];

                return (towingDestinationDTO['locationId'] === id
                    || (towingDestinationDTO['locationName'] === locationName
                        && (placesIdBase === id || `${placesIdBase}_${locationIdExtent}` === id)
                    )
                );
            });

        if (!selectedLocationFromSuggestions) {
            yield fork(
                fetchRequest,
                savaActionTypes.FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: serviceLocation.longitude,
                        latitude: serviceLocation.latitude,
                    },
                    destination: {
                        longitude: serviceAssignment.towingDestination.longitude,
                        latitude: serviceAssignment.towingDestination.latitude,
                    },
                    travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                },
            );

            const routeResponseAction = yield take([
                savaActionTypes.FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED,
                savaActionTypes.FETCH_SERVICE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED,
            ]);

            let routeToReferentialPoint;
            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                routeToReferentialPoint = arcGISRouteDTO;
            }

            towingDestinationRecommendationDTOs.unshift({
                ...serviceAssignment.towingDestination.toDTO(),
                'routeToReferentialPoint': routeToReferentialPoint,
            });
        }

        if (selectedLocationFromSuggestions && selectedLocationFromSuggestions.locationId !== id) {
            yield* updateServiceAssignment({
                caller: savaActionTypes.SUBMIT_SAVA_TOWING_DESTINATION_FORM,
                assignmentType: efServiceAssignmentTypes.VEHICLE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    towingDestination: {
                        ...serviceAssignment.towingDestination,
                        locationId: selectedLocationFromSuggestions.locationId,
                    },
                },
            });
        }
    }

    // sort towing destinations by distance
    towingDestinationRecommendationDTOs = towingDestinationRecommendationDTOs
        .sort((locationA, locationB) => {
            if (!locationA['routeToReferentialPoint']
                || !locationB['routeToReferentialPoint']) return 0;

            const {routeToReferentialPoint: locationARoute} = locationA;
            const {routeToReferentialPoint: locationBRoute} = locationB;
            return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
        });
    const recommendedTowingDestinationDTO = towingDestinationRecommendationDTOs[0];

    // render markers for towing destination options
    if (towingDestinationRecommendationDTOs.length > 0) {
        yield call(savaTowingDestinationsLayer.setFeatures, {
            features: towingDestinationRecommendationDTOs.map((towingDestinationDTO, index) => {
                let geometry = towingDestinationDTO['arcGISPoint'];
                if (!geometry && towingDestinationDTO['longitude'] && towingDestinationDTO['latitude']) {
                    geometry = {
                        type: 'point',
                        x: towingDestinationDTO['longitude'],
                        y: towingDestinationDTO['latitude'],
                    };
                }
                if (!geometry) return null;

                return {
                    attributes: {
                        FID: index,
                        locationId: towingDestinationDTO['locationId'],
                    },
                    geometry,
                };
            }).filter(Boolean),
        });
    }

    // render routes for towing destination options
    if (towingDestinationRecommendationDTOs.length > 0) {
        yield call(savaTowingDestinationRoutesLayer.setFeatures, {
            features: towingDestinationRecommendationDTOs.map((towingDestinationDTO, index) => {
                const routeToReferentialPoint = towingDestinationDTO['routeToReferentialPoint'];
                if (!routeToReferentialPoint) return null;
                return {
                    attributes: {
                        ...towingDestinationDTO,
                        FID: index,
                        establishmentType: undefined,
                        arcGISPoint: undefined,
                        arcGISExtent: undefined,
                        openingHours: undefined,
                        routeToReferentialPoint: undefined,
                    },
                    geometry: routeToReferentialPoint.geometry,
                };
            }).filter(Boolean),
        });
    }

    // select features for current towing destination
    const selectedTowingDestinationId = selectedLocationFromSuggestions
        ? selectedLocationFromSuggestions.locationId
        : serviceAssignment.towingDestination
            ? serviceAssignment.towingDestination.id
            : null;
    if (selectedTowingDestinationId) {
        yield all([
            call(savaTowingDestinationsLayer.selectFeatureByAttribute, {
                where: `locationId = '${selectedTowingDestinationId}'`,
            }),
            call(savaTowingDestinationRoutesLayer.selectFeatureByAttribute, {
                where: `locationId = '${selectedTowingDestinationId}'`,
            }),
        ]);
    }

    yield put({
        type: savaActionTypes.SET_SAVA_TOWING_DESTINATION_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            towingDestinationRecommendationDTOs,
            recommendedTowingDestinationId: recommendedTowingDestinationDTO
                ? recommendedTowingDestinationDTO['locationId'] : null,
        },
    });
};

export default searchSAVATowingDestinations;
