import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, DataRow, Pill} from '@ace-de/ui-components';
import pillTypes from '../pillStateTypes';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';

const MedicalAdviceAssignmentDetailsTable = props => {
    const {cx} = useStyles();
    const {serviceAssignment, agentUserProfile, serviceCase} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_service_assignments_tab');
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    return (
        <div className={cx('global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-24')}>
            <div>
                <DataRow
                    label={translateTab('data_row_label.assigned_at')}
                    qaIdent="sa-assigned-at"
                >
                    {serviceAssignment.assignedAt
                        ? `${moment(serviceAssignment.assignedAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`
                        : ''}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.created_by')}
                    qaIdent="sa-created-by"
                >
                    {isAgentIdentityAvailable
                        ? agentUserProfile?.displayName || ''
                        : translate('global.user_shift_role.emergency_call_advisor')
                    }
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.service_location')}
                    qaIdent="sa-service-location-address"
                >
                    {serviceAssignment.serviceLocation?.formattedAddress || ''}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.service_provider')}
                    qaIdent="sa-ace-partner-name"
                >
                    {serviceAssignment.acePartner?.name || ''}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.diagnose')}
                    qaIdent="sa-ace-diagnose"
                >
                    {serviceAssignment.diagnoseDescription || ''}
                </DataRow>
            </div>
            <div>
                <DataRow
                    label={translateTab('data_row_label.updated_at')}
                    qaIdent="sa-updated-at"
                >
                    {serviceAssignment.updatedAt
                        ? `${moment(serviceAssignment.updatedAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`
                        : ''}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.status')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="sa-assignment-status"
                >
                    <Pill
                        type={pillTypes[serviceAssignment.assignmentStatus]}
                        className={cx('global!ace-u-typography--variant-body-medium')}
                    >
                        {translate(`global.service_assignment_status_type.${
                            snakeCase(serviceAssignment.assignmentStatus)
                        }`)}
                    </Pill>
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.entitlement_check')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="sa-entitlement-check"
                >
                    <div>
                        <Pill
                            type={serviceAssignment.entitlementCheck ? 'positive' : 'negative'}
                            className={cx('global!ace-u-typography--variant-body-medium')}
                        >
                            {serviceAssignment.entitlementCheck
                                ? translate('global.entitlement_check.entitlement_available')
                                : translate('global.entitlement_check.no_entitlement_available')}
                        </Pill>
                        {serviceAssignment.entitlementCheck && serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}` && (
                            <p className={cx('global!ace-u-padding--left-8')}>
                                {translate('global.entitlement_check.entitlement_available')}
                            </p>
                        )}
                    </div>
                </DataRow>
            </div>
        </div>
    );
};

MedicalAdviceAssignmentDetailsTable.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    agentUserProfile: PropTypes.object,
};

MedicalAdviceAssignmentDetailsTable.defaultProps = {
    serviceAssignment: {},
    serviceCase: {},
    agentUserProfile: null,
};

export default MedicalAdviceAssignmentDetailsTable;
