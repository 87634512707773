import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {apsServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import SAAPeopleAndRoomsPanel from './ui-elements/SAAPeopleAndRoomsPanel';
import SAABudgetPanel from './ui-elements/SAABudgetPanel';
import * as saaActionTypes from './saaActionTypes';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import config from '../config';

const SAARequirementsTab = props => {
    const {cx} = useStyles();
    const {serviceAssignment} = props;
    const {submitSAAMemberLocationForm, serviceAssignmentBudgetMaxDurations} = props;
    const [stayStartDate, setStayStartDate] = useState(serviceAssignment.stayStartDate);
    const [stayEndDate, setStayEndDate] = useState(serviceAssignment.stayEndDate);
    const [numberOfAdults, setNumberOfAdults] = useState(serviceAssignment.numberOfAdults);
    const [numberOfKids, setNumberOfKids] = useState(serviceAssignment.numberOfKids);

    const areStayDatesValid = moment(stayStartDate).isValid() && moment(stayEndDate).isValid()
            && moment(stayEndDate).isAfter(moment(stayStartDate));
    const numberOfNights = areStayDatesValid
        ? Number(serviceAssignment.serviceCase.commissioner.id) !== config.ACE_COMMISSIONER_ID
            || moment(stayEndDate).diff(moment(stayStartDate), 'days') <= serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value
            ? moment(stayEndDate).diff(moment(stayStartDate), 'days')
            : serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value || 0
        : 0;

    const numberOfPersons = numberOfAdults + numberOfKids;

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;

        submitSAAMemberLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                memberLocation: locationInfo.selectedLocation,
                pickupLocation: locationInfo.selectedLocation,
            },
        });
    };

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <SAAPeopleAndRoomsPanel
                    serviceAssignment={serviceAssignment}
                    stayStartDate={stayStartDate}
                    stayEndDate={stayEndDate}
                    setStayStartDate={setStayStartDate}
                    setStayEndDate={setStayEndDate}
                    numberOfAdults={numberOfAdults}
                    numberOfKids={numberOfKids}
                    setNumberOfAdults={setNumberOfAdults}
                    setNumberOfKids={setNumberOfKids}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    mapName="saa-member-location"
                    handleLocationSaving={handleLocationSaving}
                />
                <SAABudgetPanel
                    serviceAssignment={serviceAssignment}
                    numberOfNights={numberOfNights}
                    numberOfPersons={numberOfPersons}
                    isMaxDurationError={moment(stayEndDate).diff(moment(stayStartDate), 'days') > serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.ACCOMMODATION]?.value}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SAARequirementsTab.propTypes = {
    serviceAssignment: PropTypes.object,
    submitSAAMemberLocationForm: PropTypes.func.isRequired,
    serviceAssignmentBudgetMaxDurations: PropTypes.object,
};

SAARequirementsTab.defaultProps = {
    serviceAssignment: null,
    serviceAssignmentBudgetMaxDurations: null,
};

const mapStateToProps = state => ({
    serviceAssignmentBudgetMaxDurations: state.serviceAssignments.serviceAssignmentBudgetMaxDurations,
});

const mapDispatchToProps = dispatch => ({
    submitSAAMemberLocationForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_MEMBER_LOCATION_FORM,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SAARequirementsTab);
