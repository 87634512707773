import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {apmContractPartnerAssignmentChannelTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageTravelDataPanel from './ui-elements/CostCoverageTravelDataPanel';
import CostCoverageCostCoveragePanel from './ui-elements/CostCoverageCostCoveragePanel';
import * as sarjActionTypes from './sarjActionTypes';
import CostCoverageCostOverviewPanel from './ui-elements/CostCoverageCostOverviewPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import config from '../config';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SAACostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, users, submitSARJCostCoverageForm, submitSARJACEPartnerForm} = props;
    const {submitCreateSARJPDF} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [additionalInformationText, setAdditionalInformationText] = useState(
        serviceAssignment.additionalInformationText,
    );
    const [assignmentText, setAssignmentText] = useState(serviceAssignment.assignmentText);
    const [totalCost, setTotalCost] = useState(serviceAssignment.totalCost);
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    useEffect(() => {
        setAdditionalInformationText(serviceAssignment.additionalInformationText);
        setCallbackPhoneNo(serviceAssignment.callbackPhoneNo);
    }, [serviceAssignment.additionalInformationText, serviceAssignment.callbackPhoneNo]);

    const onChangeHandler = (key, value) => {
        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
                selfPayerNote: '',
            });
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
            return;
        }

        if (key === 'additionalInformationText' && value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
            setAdditionalInformationText(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'totalCost') {
            setTotalCost(value);
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
        }
    };

    const handleOnSubmit = costCoverageData => {
        submitSARJACEPartnerForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
            serviceAssignmentData: {
                additionalInformationText,
                assignmentText,
                totalCost,
                selfPayerOption: !!selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote || '',
                ...costCoverageData,
            },
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: sarjActionTypes.SUBMIT_SARJ_COST_COVERAGE_FORM,
            });
            return;
        }

        submitSARJCostCoverageForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalInformationText,
                assignmentText,
                totalCost,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.totalCoverage,
        serviceCase.id,
        submitSARJCostCoverageForm,
        totalCost,
        additionalInformationText,
        assignmentText,
        maximumBudget,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        serviceCase.nonAceMember,
        attachmentsToSendWithAssignment,
    ]);

    const submitCreatePDF = serviceProviderData => {
        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);
        submitCreateSARJPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalInformationText,
                assignmentText,
                totalCost,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                ...serviceProviderData,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case don't render
    if (!serviceCase) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    additionalInformationText={additionalInformationText}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageTravelDataPanel />
                <CostCoverageCostCoveragePanel
                    assignmentText={assignmentText}
                    onChangeHandler={onChangeHandler}
                />
                <CostCoverageCostOverviewPanel
                    serviceCase={serviceCase}
                    totalCost={totalCost}
                    onChangeHandler={onChangeHandler}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    assignmentChannels={[apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML]}
                    hasCreditCardPayment={true}
                    handleOnAssignmentSubmit={handleOnSubmit}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SAACostCoverageTab.propTypes = {
    submitSARJCostCoverageForm: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSARJACEPartnerForm: PropTypes.func.isRequired,
    submitCreateSARJPDF: PropTypes.func.isRequired,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SAACostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: {},
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARJCostCoverageForm: payload => dispatch({
        type: sarjActionTypes.SUBMIT_SARJ_COST_COVERAGE_FORM,
        payload,
    }),
    submitSARJACEPartnerForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSARJPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAACostCoverageTab));
