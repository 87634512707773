import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Search assignment's relevant location geolocation
 */

const searchAssignmentRelevantLocationGeolocation = function* searchAssignmentRelevantLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId, locationKey} = payload;
    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const searchDamageLocationGeolocation = yield take([
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchDamageLocationGeolocation.error) {
        const {response} = searchDamageLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_CANDIDATES,
            payload: {
                locationKey,
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchAssignmentRelevantLocationGeolocation;
