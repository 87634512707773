import {all, takeLatest, fork} from 'redux-saga/effects';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import searchContractPartners from './sagas/searchContractPartners';
import editTemporaryRestrictionFlow from './sagas/editTemporaryRestrictionFlow';
import searchServiceCases from '../service-cases/sagas/searchServiceCases';
import createTemporaryRestrictionFlow from './sagas/createTemporaryRestrictionFlow';
import createQualityReportFlow from './sagas/createQualityReportFlow';
import downloadCPOverview from './sagas/downloadCPOverview';

/**
 *  Contract partner watcher saga
 */
const contractPartnerWatcher = function* contractPartnerWatcher() {
    yield all([
        takeLatest(contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS, searchContractPartners),
        takeLatest(contractPartnerActionTypes.SEARCH_SERVICE_CASES, searchServiceCases),
        fork(createTemporaryRestrictionFlow),
        fork(createQualityReportFlow),
        fork(editTemporaryRestrictionFlow),
        fork(downloadCPOverview),
    ]);
};

export default contractPartnerWatcher;
