import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Divider} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';

const TrailerInfoPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('trailer_info_panel');
    const {serviceCase} = props;

    // if no service case, don't render
    if (!serviceCase) return null;
    const {trailer} = serviceCase;

    return (
        <Panel
            title={translateTab('title.trailer_info')}
            qaIdent="trailer-info-panel"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.kind')}
                        qaIdent="sa-trailer-info-type"
                    >
                        {trailer?.type ? translate(`global.trailer_type.${trailer?.type.toLowerCase()}`) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.manufacturer_and_model')}
                        qaIdent="sa-trailer-info-manufacturer-and-model"
                    >
                        {[trailer?.manufacturer, trailer?.model].filter(value => !!value).join(' - ') || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.license_plate_no')}
                        qaIdent="sa-trailer-info-licence-plate-number"
                    >
                        {trailer?.licensePlateNumber || '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.trailer_dimensions')}
                        qaIdent="sa-trailer-info-dimensions"
                        className={cx('ace-c-data-row--full-bleed-content')}
                        contentClassName={cx([
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-items-stretch',
                        ])}
                    >
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('data_row_label.dimensions')}</p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-typography--align-flex-start',
                                    'global!ace-u-margin--left-8',
                                ])}
                            >
                                {[
                                    trailer?.length?.toLocaleString(activeLocale),
                                    trailer?.width?.toLocaleString(activeLocale),
                                    trailer?.height?.toLocaleString(activeLocale),
                                ].filter(value => !!value).join(' x ') || '-'}
                            </p>
                        </div>
                        <Divider />
                        <div className={cx('global!ace-u-flex', 'global!ace-u-padding--16')}>
                            <p>{translateTab('data_row_label.weight')}</p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-typography--align-flex-start',
                                    'global!ace-u-margin--left-8',
                                ])}
                            >
                                {trailer?.weight?.toLocaleString(activeLocale) || '-'}
                            </p>
                        </div>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.comment')}
                        qaIdent="sa-trailer-info-notes"
                    >
                        {trailer?.notes ? trailer?.notes : '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

TrailerInfoPanel.propTypes = {
    serviceCase: PropTypes.object,
};

TrailerInfoPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(TrailerInfoPanel));
