import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageDeathInfoPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sadeh_cost_coverage_tab');
    const {serviceAssignment} = props;

    return (
        <Panel
            title={translateTab('panel_title.reason_and_time_of_death')}
            qaIdent="sadeh-cost-coverage-death-info"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.reason_of_death')}
                        qaIdent="sc-reason-of-death"
                    >
                        {serviceAssignment?.reasonOfDeath || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.insurance')}
                        qaIdent="sc-insurance"
                    >
                        {serviceAssignment?.insurance || '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.date_of_death')}
                        qaIdent="sc-date-of-death"
                    >
                        {serviceAssignment?.dateOfDeath
                            ? moment(serviceAssignment.dateOfDeath).format('DD.MM.YYYY')
                            : '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageDeathInfoPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageDeathInfoPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageDeathInfoPanel));
