import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sarjActionTypes from '../sarjActionTypes';

/**
 * Search return journey destination geolocation
 */
const searchSARJDestinationGeolocation = function* searchSARJDestinationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId} = payload;
    yield fork(
        fetchRequest,
        sarjActionTypes.SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const responseAction = yield take([
        sarjActionTypes.SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_SUCCEEDED,
        sarjActionTypes.SEARCH_SARJ_DESTINATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: sarjActionTypes.SET_SARJ_DESTINATION_CANDIDATES,
            payload: {
                locationKey: 'destination',
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchSARJDestinationGeolocation;
