import React, {useState, useMemo, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {useTranslate} from '@computerrock/formation-i18n';
import {ACEPartner} from '@ace-de/eua-entity-types';
import {useStyles, Modal, InputField, ButtonPrimary, DataRow, Form, Divider, AutosuggestField, Option} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as saaActionTypes from '../saaActionTypes';
import createDefaultAccommodation from '../createDefaultAccommodation';
import config from '../../config';
import {validateEmail, validateFax} from '../../utils/validation';

const nonMandatoryFields = ['phoneNo', 'faxNo', 'email'];
const initialErrorFields = {
    email: [],
    address: [],
};
const accommodationInitialState = createDefaultAccommodation();

const AccommodationDataModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('accommodation_data_modal');
    const {hasBackdrop, serviceAssignment, searchSAAAccommodationLocationGeolocation} = props;
    const {confirmUpdateAccommodation, declineUpdateAccommodation} = props;
    const [accommodationData, setAccommodationData] = useState(serviceAssignment?.acePartner ? {
        ...serviceAssignment?.acePartner,
    } : accommodationInitialState);
    const [errorFields, setErrorFields] = useState({...initialErrorFields});
    const [newAccommodationLocation, setNewAccommodationLocation] = useState(null);
    const currentAccommodationLocationAddress = useRef('');
    const lastAccommodationLocationSearchQuery = useRef('');
    const isAccommodationAddressChanged = useRef(false);
    const searchSAAAccommodationLocationGeolocationDebounced = useMemo(
        () => debounce(
            searchSAAAccommodationLocationGeolocation,
            config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
        ),
        [searchSAAAccommodationLocationGeolocation],
    );
    const disallowedSymbols = ['Tab', ' '];

    useEffect(() => {
        if (!serviceAssignment?.acePartner) return;

        if (serviceAssignment.acePartner.location) {
            setNewAccommodationLocation(serviceAssignment.acePartner.location);
        }
    }, [serviceAssignment.acePartner]);

    const handleAccommodationLocationSearchQueryChange = searchQueryString => {
        if (!searchQueryString
            && searchQueryString.length <= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            setNewAccommodationLocation(null);
        }

        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentAccommodationLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSAAAccommodationLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastAccommodationLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleAccommodationLocationCandidateSelect = accommodationLocationCandidate => {
        currentAccommodationLocationAddress.current = accommodationLocationCandidate.formattedAddress;
        setNewAccommodationLocation(accommodationLocationCandidate);
        isAccommodationAddressChanged.current = true;
    };

    const handleFormFieldsValidation = formValues => {
        let hasError = false;
        const mandatoryErrorMessage = translateModal('error_message.mandatory_field');
        const emailErrorMessage = translateModal('error_message.please_enter_valid_email');
        const faxErrorMessage = translateModal('error_message.please_enter_valid_fax');

        Object.entries(formValues).forEach(formValueEntry => {
            const key = formValueEntry[0];
            const value = formValueEntry[1];

            if (value === '' && !nonMandatoryFields.includes(key)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [mandatoryErrorMessage],
                }));
                hasError = true;
            } else if (key === 'email' && value !== '' && !validateEmail(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [emailErrorMessage],
                }));
                hasError = true;
            } else if (key === 'faxNo' && value !== '' && !validateFax(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [faxErrorMessage],
                }));
                hasError = true;
            } else if (errorFields[key]) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [],
                }));
            }
        });

        return hasError;
    };

    const handleOnSubmit = formValues => {
        if (!formValues || !newAccommodationLocation) return;
        const hasError = handleFormFieldsValidation(formValues);
        if (hasError) return;

        const businessContactDetails = {
            phoneNo: formValues.phoneNo,
            faxNo: formValues.faxNo !== '' ? formValues.faxNo : undefined,
            email: formValues.email,
        };

        if (serviceAssignment.acePartner) {
            confirmUpdateAccommodation({
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                serviceCaseId: serviceAssignment.serviceCaseId,
                assignmentType: serviceAssignment.assignmentType,
                serviceAssignmentData: {
                    acePartner: {
                        ...serviceAssignment.acePartner,
                        id: isAccommodationAddressChanged.current
                            ? newAccommodationLocation.id
                            : serviceAssignment.acePartner.id,
                        name: formValues.name,
                        businessContactDetails,
                        location: newAccommodationLocation,
                        contactDetails: null,
                    },
                },
            });
            return;
        }

        confirmUpdateAccommodation({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: serviceAssignment.assignmentType,
            serviceAssignmentData: {
                acePartner: {
                    ...ACEPartner,
                    id: newAccommodationLocation.id,
                    name: formValues.name,
                    businessContactDetails,
                    location: newAccommodationLocation,
                    contactDetails: null,
                },
            },
        });
    };

    const handleOnChange = formValues => {
        if (!formValues) return;
        setAccommodationData({
            ...formValues,
            location: {
                ...accommodationData.location,
                ...formValues.location,
            },
            businessContactDetails: {
                ...accommodationData.businessContactDetails,
                ...formValues.businessContactDetails,
            },
        });
    };

    if (!serviceAssignment) return null;

    const {hotelLocationCandidates, hotelLocationSearchQuery} = serviceAssignment;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => declineUpdateAccommodation()}
                />
            )}
            title={translateModal('title.add_accommodation_manually')}
            contentClassName={cx(['global!ace-u-margin--top-32', 'global!ace-u-modal-content-size--m'])}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form name="accommodationData" onSubmit={handleOnSubmit} onChange={handleOnChange}>
                    <div className={cx('global!ace-u-grid')}>
                        <div className={cx('global!ace-u-grid-column--span-6')}>
                            <DataRow
                                label={translateModal('data_row_label.name')}
                                qaIdent="accommodation-name"
                                isFieldRequired={true}
                            >
                                <InputField
                                    name="name"
                                    value={accommodationData.name || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.name}
                                />
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.address')}
                                qaIdent="accommodation-address"
                                isFieldRequired={true}
                            >
                                <div className={cx('global!ace-u-flex--grow-1')}>
                                    <AutosuggestField
                                        name="address"
                                        value={newAccommodationLocation?.formattedAddress || ''}
                                        onChange={handleAccommodationLocationSearchQueryChange}
                                        onOptionSelect={handleAccommodationLocationCandidateSelect}
                                        optionValueSelector={accommodationLocationCandidate => {
                                            return accommodationLocationCandidate.formattedAddress;
                                        }}
                                        placeholder={translateModal('input_placeholder.please_fill_in')}
                                        icon={searchIcon}
                                        className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                        errors={errorFields.address}
                                    >
                                        {(accommodationData['address'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                        && hotelLocationSearchQuery === lastAccommodationLocationSearchQuery.current
                                            ? hotelLocationCandidates
                                                .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                .map((accommodationLocationCandidate, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            name={`accommodation-location-candidate-${index}`}
                                                            value={accommodationLocationCandidate}
                                                        >
                                                            <Icon
                                                                icon={locationIcon}
                                                                className={cx('global!ace-u-margin--right-16')}
                                                            />
                                                            {accommodationLocationCandidate.formattedAddress}
                                                        </Option>
                                                    );
                                                }) : null}
                                    </AutosuggestField>
                                </div>
                            </DataRow>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-6')}>
                            <DataRow
                                label={translateModal('data_row_label.phone_number')}
                                qaIdent="accommodation-phone-no"
                            >
                                <InputField
                                    name="phoneNo"
                                    value={accommodationData.businessContactDetails?.phoneNo
                                        ? accommodationData.businessContactDetails.phoneNo
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                />
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.fax_number')}
                                qaIdent="accommodation-fax-no"
                            >
                                <InputField
                                    name="faxNo"
                                    value={accommodationData.businessContactDetails?.faxNo
                                        ? accommodationData.businessContactDetails.faxNo
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    disallowedSymbols={disallowedSymbols}
                                    errors={errorFields.faxNo}
                                />
                            </DataRow>
                            <DataRow
                                label={translateModal('data_row_label.email')}
                                qaIdent="accommodation-email"
                            >
                                <InputField
                                    name="email"
                                    value={accommodationData.businessContactDetails?.email
                                        ? accommodationData.businessContactDetails.email
                                        : ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.email}
                                    disallowedSymbols={disallowedSymbols}
                                />
                            </DataRow>
                        </div>
                    </div>
                    <Divider className={cx('global!ace-u-margin--48-0-32')} />
                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                        <ButtonPrimary
                            name="submitAccommodationData"
                            type="submit"
                            isDisabled={!newAccommodationLocation}
                        >
                            {translateModal('button_label.accept')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

AccommodationDataModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    confirmUpdateAccommodation: PropTypes.func.isRequired,
    declineUpdateAccommodation: PropTypes.func.isRequired,
    searchSAAAccommodationLocationGeolocation: PropTypes.func.isRequired,
};

AccommodationDataModal.defaultProps = {
    hasBackdrop: false,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateAccommodation: payload => dispatch({
        type: saaActionTypes.CONFIRM_UPDATE_ACCOMMODATION,
        payload,
    }),
    declineUpdateAccommodation: payload => dispatch({
        type: saaActionTypes.DECLINE_UPDATE_ACCOMMODATION,
        payload,
    }),
    searchSAAAccommodationLocationGeolocation: payload => dispatch({
        type: saaActionTypes.SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationDataModal);
