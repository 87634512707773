import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceCaseTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Button} from '@ace-de/ui-components';
import {Icon, vehicleIcon, personIcon, homeIcon} from '@ace-de/ui-components/icons';
import * as memberSelectors from '../memberSelectors';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const CreateServiceCasePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const {member, createServiceCase, isServiceCaseCreationInProgress} = props;
    const {initiateCreatePersonServiceCaseFlow} = props;
    const isCaseCreationAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.CREATE,
    );

    const createNewServiceCase = caseType => {
        if (!member || !caseType || !isCaseCreationAvailable) return;
        if (caseType === efServiceCaseTypes.PERSON) {
            initiateCreatePersonServiceCaseFlow({member});
            return;
        }

        createServiceCase({member, caseType});
    };

    if (!member) return null;

    return (
        <Panel
            title={translateScreen('create_service_case_panel_title.create_case')}
            contentClassName={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--direction-row',
            ])}
        >
            <Button
                onClick={() => createNewServiceCase(efServiceCaseTypes.VEHICLE)}
                className={cx('global!ace-u-margin--bottom-24')}
                isDisabled={isServiceCaseCreationInProgress || !isCaseCreationAvailable}
                qaIdent="member-create-new-vehicle-case"
            >
                <Icon
                    icon={vehicleIcon}
                    className={cx([{
                        'ace-c-icon--color-highlight': isCaseCreationAvailable,
                        'ace-c-icon--color-disabled': !isCaseCreationAvailable,
                    },
                    'global!ace-u-margin--right-8'])}
                />
                {translateScreen('create_service_case_panel_button_label.create_vehicle_case')}
            </Button>
            <Button
                onClick={() => createNewServiceCase(efServiceCaseTypes.PERSON)}
                className={cx('global!ace-u-margin--bottom-24')}
                isDisabled={isServiceCaseCreationInProgress || !isCaseCreationAvailable}
                qaIdent="member-create-new-person-case"
            >
                <Icon
                    icon={personIcon}
                    className={cx([{
                        'ace-c-icon--color-highlight': isCaseCreationAvailable,
                        'ace-c-icon--color-disabled': !isCaseCreationAvailable,
                    },
                    'global!ace-u-margin--right-8'])}
                />
                {translateScreen('create_service_case_panel_button_label.create_person_case')}
            </Button>
            <Button
                onClick={() => createNewServiceCase(efServiceCaseTypes.HOUSEHOLD)}
                isDisabled={isServiceCaseCreationInProgress || !isCaseCreationAvailable}
                qaIdent="member-create-new-household-case"
            >
                <Icon
                    icon={homeIcon}
                    className={cx([{
                        'ace-c-icon--color-highlight': isCaseCreationAvailable,
                        'ace-c-icon--color-disabled': !isCaseCreationAvailable,
                    },
                    'global!ace-u-margin--right-8'])}
                />
                {translateScreen('create_service_case_panel_button_label.create_household_case')}
            </Button>
        </Panel>
    );
};

CreateServiceCasePanel.propTypes = {
    member: PropTypes.object,
    createServiceCase: PropTypes.func.isRequired,
    initiateCreatePersonServiceCaseFlow: PropTypes.func.isRequired,
    isServiceCaseCreationInProgress: PropTypes.bool.isRequired,
};

CreateServiceCasePanel.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
        isServiceCaseCreationInProgress: state.serviceCases.isServiceCaseCreationInProgress,
    };
};

const mapDispatchToProps = dispatch => ({
    createServiceCase: payload => dispatch({
        type: serviceCaseActionTypes.CREATE_SERVICE_CASE,
        payload,
    }),
    initiateCreatePersonServiceCaseFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_CREATE_PERSON_SERVICE_CASE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateServiceCasePanel));
