import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Divider, Icon, checkmarkIcon, closedIcon} from '@ace-de/ui-components';
import styles from './RentalCarStationItem.module.scss';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const RentalCarStationItem = props => {
    const {cx} = useStyles(props, styles);
    const {rentalCarStation, isSelected, onSelect, ACEPartners} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_select_station_tab');

    const handleOnClick = () => {
        if (typeof onSelect === 'function') {
            onSelect(rentalCarStation.id);
        }
    };

    const {location, creditorId} = rentalCarStation;
    const mainACEPartner = Object.values(ACEPartners).filter(acePartner => acePartner.id === creditorId).pop();

    return (
        <div
            className={cx([
                'global!ace-u-cursor--pointer',
                'global!ace-u-padding--top-16',
                'global!ace-u-padding--left-32',
                'global!ace-u-padding--right-32',
                'global!ace-u-padding--bottom-0',
            ], {
                'ace-c-rental-car-station-item--is-selected': isSelected,
            })}
            onClick={handleOnClick}
        >
            <div className={cx(['global!ace-u-typography--variant-body', 'global!ace-u-margin--bottom-4'])}>
                {rentalCarStation.name}
            </div>
            <div className={cx(['global!ace-u-typography--variant-caption', 'global!ace-u-margin--bottom-4'])}>
                {location ? `${location.street}, ${location.postCode} ${location.city}` : ''}
            </div>
            <div className={cx(['global!ace-u-typography--variant-caption', 'global!ace-u-margin--bottom-4'])}>
                {mainACEPartner?.businessContactDetails?.phoneNo ? `Tel: ${mainACEPartner.businessContactDetails.phoneNo}` : ''}
            </div>
            <div
                className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-margin--bottom-16',
                ])}
            >
                {rentalCarStation.routeToReferentialPoint && (
                    <div>
                        <span>
                            {translateTab('label.distance_to_pickup_location')}
                                    &nbsp;
                            {Math.round(rentalCarStation.routeToReferentialPoint.totalKilometers)}
                            {translate('global.unit.km')}
                        </span>
                        &nbsp;
                        <span className={cx('global!ace-u-typography--color-success')}>
                            ({Math.round(rentalCarStation.routeToReferentialPoint.totalTravelTime)}{translate('global.time_unit.minute')})
                        </span>
                    </div>
                )}
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--top-4',
                    ])}
                >
                    {rentalCarStation.openUntil && (
                        <Icon
                            icon={rentalCarStation.isClosed ? closedIcon : checkmarkIcon}
                            className={cx([
                                'ace-c-icon--s',
                                'global!ace-u-margin--right-8',
                            ], {
                                'ace-c-icon--color-warning': rentalCarStation.isClosed,
                                'ace-c-icon--color-success': !rentalCarStation.isClosed,
                            })}
                        />
                    )}
                    <div>
                        {rentalCarStation.isClosed
                            ? translateTab('station_item_text.currently_closed')
                            : (rentalCarStation.openUntil
                                ? `${translateTab('station_item_text.open_until')} ${rentalCarStation.openUntil} ${translate('global.time_unit.time')}`
                                : translateTab('station_item_text.no_opening_times_available'))}
                    </div>
                </div>
            </div>
            <Divider />
        </div>
    );
};

RentalCarStationItem.propTypes = {
    rentalCarStation: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    ACEPartners: PropTypes.object,
};

RentalCarStationItem.defaultProps = {
    isSelected: false,
    onSelect: null,
    ACEPartners: null,
};

const mapStateToProps = (state, props) => ({
    ACEPartners: serviceAssignmentSelectors.getACEPartners(state, props),
});

export default withRouter(connect(mapStateToProps, null)(RentalCarStationItem));
