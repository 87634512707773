import {take, fork, select, put} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import {matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import routePaths from '../../routePaths';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';

const isJSONString = string => {
    try {
        JSON.parse(string);
    } catch (error) {
        return false;
    }
    return true;
};

/**
 * Load service case lock status
 */
const loadServiceCaseLockStatus = function* loadServiceCaseLockStatus() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const isLockManipulationAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.CASE_LOCK,
        ecsFeatureActions.UPDATE,
    );

    if (!isLockManipulationAvailable) return;

    const {location} = yield select(state => state.router);

    // exit if NOT under service case section
    const serviceCaseSectionMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_CASE_SECTION,
    });

    const prevServiceCaseId = sessionStorage.getItem('serviceCaseId');
    if (!serviceCaseSectionMatch) {
        if (prevServiceCaseId) {
            yield fork(
                fetchRequest,
                serviceCaseActionTypes.UNLOCK_SERVICE_CASE_REQUEST,
                ecsFlowService.unlockServiceCase,
                {serviceCaseId: prevServiceCaseId},
            );
            // remove serviceCaseId from local storage
            sessionStorage.removeItem('serviceCaseId');
        }
        return;
    }

    const {params} = serviceCaseSectionMatch;
    const {serviceCaseId} = params;

    // if agent switches from case1 to case2 and stays under service case section
    if (prevServiceCaseId && serviceCaseId !== prevServiceCaseId) {
        yield fork(
            fetchRequest,
            serviceCaseActionTypes.UNLOCK_SERVICE_CASE_REQUEST,
            ecsFlowService.unlockServiceCase,
            {serviceCaseId: prevServiceCaseId},
        );
    }

    sessionStorage.setItem('serviceCaseId', serviceCaseId);

    // call lock endpoint
    if (isLockManipulationAvailable) {
        yield fork(fetchRequest, serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST, ecsFlowService.lockServiceCase, {
            serviceCaseId,
            lockData: {lockUUID: uuidv4()}, // generate random UUID, TBD: maybe to use v5 to avoid randomness?
        });

        const lockResponse = yield take([
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_FAILED,
        ]);

        // trigger service case lock timer (30 minutes)
        if (!lockResponse.error && lockResponse.type === serviceCaseActionTypes.LOCK_SERVICE_CASE_REQUEST_SUCCEEDED) {
            const {payload} = lockResponse;
            const {lockData} = payload.response;

            yield put({
                type: serviceCaseActionTypes.START_SERVICE_CASE_LOCK_TIMER,
                payload: {serviceCaseId},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_LOCK_STATUS,
                payload: {lockData, serviceCaseId},
            });
            return;
        }

        // if locking fails, get lock data from the response
        const {payload} = lockResponse;
        if (isJSONString(payload.message)) {
            const lockData = JSON.parse(payload.message);
            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_LOCK_STATUS,
                payload: {lockData, serviceCaseId},
            });
        }
    }
};

export default loadServiceCaseLockStatus;
