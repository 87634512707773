/**
 * @typedef {string} tabId
 */

/**
 * SAMC screen tabs
 *
 * @enum {tabId}
 */
export default {
    SERVICE_CASES: 'service-cases',
    TASKS: 'tasks',
    CONTRACT_PARTNERS: 'contract-partners',
};
