import {take, put} from 'redux-saga/effects';
import {efServiceAssignmentTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Update SARC cost coverage flow
 */
const updateSARCCostCoverageFlow = function* updateSARCCostCoverageFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, serviceCaseData, nonAceMember} = payload;

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (nonAceMember) {
            yield* updateServiceCase({
                caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_FORM,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_FORM},
        });
    }
};

export default updateSARCCostCoverageFlow;
