import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, Button, Icon} from '@ace-de/ui-components';
import {waitingIcon, calendarIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const ContractPartnerTemporaryRestriction = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, activeTemporaryRestrictions} = props;
    const {initiateCreateTemporaryRestrictionFlow} = props;
    const newestTemporaryRestriction = activeTemporaryRestrictions.reduce((prev, curr) => (prev ? (
        moment(curr.createDateTime) > moment(prev.createDateTime) ? curr : prev)
        : curr), 0);

    const isTemporaryRestrictionCreationDisabled = !ecsAccessControl.grantFeatureAccess(
        ecsFeatures.TEMPORARY_RESTRICTION,
        ecsFeatureActions.CREATE,
    );

    const createTemporaryRestriction = () => {
        if (isTemporaryRestrictionCreationDisabled) return;
        initiateCreateTemporaryRestrictionFlow({contractPartner});
    };

    return (
        <Panel
            title={translateTab('panel_title.temporary_restriction')}
            hasBadge={activeTemporaryRestrictions.length > 1}
            notifications={`+${activeTemporaryRestrictions.length - 1}`}
        >
            {activeTemporaryRestrictions.length ? (
                <div>
                    <div className={cx('global!ace-u-margin--bottom-24')}>
                        <label className={cx('global!ace-u-typography--variant-body-medium')}>
                            {translateTab('temporary_restriction_panel_label.start')}
                        </label>
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-margin--top-8'])}>
                            <Icon
                                icon={calendarIcon}
                                className={cx(['ace-c-icon--xs', 'global!ace-u-margin--right-8'])}
                            />
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {newestTemporaryRestriction.restrictionStartDateTime
                                && moment(newestTemporaryRestriction.restrictionStartDateTime).isValid()
                                    ? `${moment(newestTemporaryRestriction.restrictionStartDateTime).format('DD.MM.YYYY[,] HH:mm')} ${
                                        translate('global.time_unit.time')
                                    }` : '-'}
                            </p>
                        </div>
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-24')}>
                        <label className={cx('global!ace-u-typography--variant-body-medium')}>
                            {translateTab('temporary_restriction_panel_label.end')}
                        </label>
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-margin--top-8'])}>
                            <Icon
                                icon={calendarIcon}
                                className={cx(['ace-c-icon--xs', 'global!ace-u-margin--right-8'])}
                            />
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {newestTemporaryRestriction.restrictionEndDateTime
                                && moment(newestTemporaryRestriction.restrictionEndDateTime).isValid()
                                    ? `${moment(newestTemporaryRestriction.restrictionEndDateTime).format('DD.MM.YYYY[,] HH:mm')} ${
                                        translate('global.time_unit.time')
                                    }` : '-'}
                            </p>
                        </div>
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-24')}>
                        <label className={cx('global!ace-u-typography--variant-body-medium')}>
                            {translateTab('temporary_restriction_panel_label.category')}
                        </label>
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-margin--top-8'])}>
                            <Icon
                                icon={waitingIcon}
                                className={cx(['ace-c-icon--xs', 'global!ace-u-margin--right-8'])}
                            />
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {translate(`global.temporary_restriction_category.${snakeCase(newestTemporaryRestriction.restrictionCategory)}`) || ''}
                            </p>
                        </div>
                    </div>
                    <div className={cx('global!ace-u-margin--bottom-32')}>
                        <label className={cx('global!ace-u-typography--variant-body-medium')}>
                            {translateTab('temporary_restriction_panel_label.description')}
                        </label>
                        <p className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-margin--top-8',
                            'global!ace-u-typography-word-break--break-word',
                        ])}
                        >
                            {newestTemporaryRestriction.description || ''}
                        </p>
                    </div>
                </div>
            ) : null}
            {!config.IS_FALLBACK_SYSTEM && (
                <Button
                    className={cx('global!ace-u-full-width')}
                    onClick={createTemporaryRestriction}
                    isDisabled={isTemporaryRestrictionCreationDisabled}
                >
                    {translateTab('temporary_restriction_panel_button.report_temporary_restriction')}
                </Button>
            )}
        </Panel>
    );
};

ContractPartnerTemporaryRestriction.propTypes = {
    contractPartner: PropTypes.object,
    activeTemporaryRestrictions: PropTypes.array,
    initiateCreateTemporaryRestrictionFlow: PropTypes.func.isRequired,
};

ContractPartnerTemporaryRestriction.defaultProps = {
    contractPartner: null,
    activeTemporaryRestrictions: [],
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    const activeTemporaryRestrictionsSelector = contractPartnerSelectors
        .createActiveAndUpcomingTemporaryRestrictionsSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        activeTemporaryRestrictions: activeTemporaryRestrictionsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateTemporaryRestrictionFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerTemporaryRestriction));
