import React, {Fragment, useState, useEffect, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase, kebabCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceCaseDocumentTypes} from '@ace-de/eua-entity-types';
import {useFileAssets, FileAssetsInput, FileAssetsDropZone} from '@ace-de/eua-file-assets';
import {useStyles, Modal, ButtonPrimary, SelectField, Option, HighlightCircle, Divider, TextAreaField} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, uploadIcon, plusIcon, attachmentIcon, timeIcon, deleteIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import formatBytes from '../../application/formatBytes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import allowedAttachmentMIMETypes from '../allowedAttachmentMIMETypes';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

const fileSizeLimit = formatBytes(config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES);

const SCDocumentUploadModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('sc_document_upload_modal');
    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'efFileAssetsService',
        fileList: 'sc-documents',
    });
    const [documentType, setDocumentType] = useState(efServiceCaseDocumentTypes.REQUEST);
    const {serviceCase, hasBackdrop, confirmSCDocumentUpload, declineSCDocumentUpload} = props;
    const [description, setDescription] = useState('');
    const fileInputRef = useRef();

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const saveServiceCaseDocuments = useCallback(() => {
        const fileAssets = fileList.files;
        if (fileAssets.length === 0) return;
        confirmSCDocumentUpload({
            newAttachments: fileAssets.map(fileAsset => ({
                id: fileAsset.id,
                fileName: fileAsset.fileName,
                attachmentType: documentType,
                url: fileAsset.url,
                groupId: null,
                description: description,
            })),
        });
    }, [fileList, documentType, description, confirmSCDocumentUpload]);

    const handleOnDelete = fileAsset => {
        const currentFileList = fileInputRef?.current?.getAttribute('file-list');
        if (currentFileList && typeof currentFileList === 'string') {
            const fileNames = currentFileList.split(',');
            const fileIndex = fileNames.findIndex(fileName => fileName === fileAsset.fileName);
            if (fileIndex >= 0) {
                fileNames.splice(fileIndex, 1);
                fileInputRef.current.setAttribute('file-list', fileNames.toString());
            }
        }
        deleteFile(fileAsset);
    };

    if (!serviceCase) return null;

    const isSubmitDisabled = fileList.files.length === 0
        || (fileList.files.length > 0 && fileList.files.some(fileAsset => fileAsset.isUploadPending))
        || serviceCase.persistenceState === persistenceStates.PENDING;

    const isCancelDisabled = (fileList.files.length > 0 && fileList.files.some(fileAsset => fileAsset.isUploadPending))
        || serviceCase.persistenceState === persistenceStates.PENDING;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSCDocumentUpload}
                    isDisabled={isCancelDisabled}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('ace-c-modal__content--scrollable')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-stretch',
                ])}
            >
                <FileAssetsDropZone
                    service="efFileAssetsService"
                    fileList="sc-documents"
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--16-0',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'global!ace-u-margin--32-192',
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary',
                        ])}
                    >
                        <Icon
                            icon={uploadIcon}
                            className={cx([
                                'ace-c-icon--xxl',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </HighlightCircle>
                    <h3 className={cx('global!ace-u-typography--variant-h3')}>
                        {translateModal('title.document_upload')}
                    </h3>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-medium-emphasis',
                        ])}
                    >
                        {translateModal('text.valid_formats')}
                    </p>
                    <FileAssetsInput
                        ref={fileInputRef}
                        service="efFileAssetsService"
                        fileList="sc-documents"
                        className={cx([
                            'global!ace-u-margin--24-0',
                            'ace-c-file-input--no-chrome',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--24-0',
                                'global!ace-u-typography--variant-highlight',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-cursor--pointer',
                            ])}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx([
                                    'global!ace-u-margin--right-8',
                                    'ace-c-icon--color-highlight',
                                ])}
                            />
                            {translateModal('label.search_documents')}
                        </div>
                    </FileAssetsInput>
                </FileAssetsDropZone>
                {fileList.errors.length > 0 && (
                    <div className={cx('global!ace-u-margin--8-0')}>
                        {fileList.errors.map((errorInfo, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-margin--4-0',
                                        'global!ace-u-typography--variant-caption',
                                        'global!ace-u-typography--color-warning',
                                    ])}
                                >
                                    <Icon
                                        icon={infoAlertIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-4',
                                            'ace-c-icon--s',
                                            'ace-c-icon--color-warning',
                                            'global!ace-u-flex--shrink-0',
                                        ])}
                                    />
                                    {translate(`global.eua_file_assets_error_type.${snakeCase(errorInfo.errorType)}`, {
                                        fileName: errorInfo.fileName,
                                        fileType: errorInfo.fileType,
                                        fileSizeLimit,
                                    })}
                                </div>
                            );
                        })}
                        <Divider />
                    </div>
                )}
                <SelectField
                    name="documentType"
                    label={translateModal('label.document_type')}
                    value={documentType}
                    className={cx('global!ace-u-margin--48-0')}
                    onChange={setDocumentType}
                >
                    {Object.keys(efServiceCaseDocumentTypes).map(documentType => {
                        const key = `${kebabCase(documentType)}-shift-role`;
                        return (
                            <Option
                                key={key}
                                name={key}
                                value={documentType}
                            >
                                {translate(`global.service_case_document_type.${snakeCase(documentType)}`)}
                            </Option>
                        );
                    })}
                </SelectField>
                {fileList.files.length > 0 && (
                    <Fragment>
                        <Divider />
                        <TextAreaField
                            name="description"
                            value={description || ''}
                            placeholder={translateModal('text_area_placeholder.email_from_assistance')}
                            label={translateModal('text_area_label.description')}
                            isResizable={true}
                            maxLength={config.DOCUMENT_UPLOAD_DESCRIPTION_MAX_LENGTH}
                            onChange={value => setDescription(value)}
                            className={cx(['global!ace-u-full-width', 'global!ace-u-margin--24-0'])}
                        />
                        <Divider />
                        <div className={cx('global!ace-u-margin--24-0')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-margin--bottom-8',
                                ])}
                            >
                                {translateModal('text.selected_documents')}
                            </p>
                            {fileList.files.map(fileAsset => {
                                return (
                                    <div
                                        key={fileAsset.id}
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-margin--8-0',
                                        ])}
                                    >
                                        <span
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-typography--variant-body',
                                                'global!ace-u-typography--color-highlighted',
                                            ])}
                                        >
                                            <Icon
                                                icon={fileAsset.isUploadPending ? timeIcon : attachmentIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--xs',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                            {fileAsset.fileName}
                                        </span>
                                        {/* NOTE: we should allow agents to delete a chosen document(s) in the modal
                                        * without checking authorization roles: if they have the rights
                                        * to upload documents(s) then they should be able to delete a document(s)
                                        * BEFORE they are attached to the case
                                        */}
                                        <Icon
                                            onClick={() => handleOnDelete(fileAsset)}
                                            icon={deleteIcon}
                                            className={cx([
                                                'ace-c-icon--xs',
                                                'ace-c-icon--color-medium-emphasis',
                                            ])}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Fragment>
                )}
                <ButtonPrimary
                    name="confirm-sc-document-upload"
                    isDisabled={isSubmitDisabled}
                    onClick={saveServiceCaseDocuments}
                    className={cx([
                        'global!ace-u-flex--align-self-flex-end',
                        'global!ace-u-margin--top-32',
                    ])}
                >
                    {translateModal('button.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

SCDocumentUploadModal.propTypes = {
    serviceCase: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    confirmSCDocumentUpload: PropTypes.func.isRequired,
    declineSCDocumentUpload: PropTypes.func.isRequired,
};

SCDocumentUploadModal.defaultProps = {
    serviceCase: null,
    hasBackdrop: true,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmSCDocumentUpload: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_UPLOAD,
        payload,
    }),
    declineSCDocumentUpload: payload => dispatch({
        type: serviceCaseActionTypes.DECLINE_SC_DOCUMENT_UPLOAD,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SCDocumentUploadModal);
