import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea} from '@ace-de/ui-components';

const CostCoverageCommissioningPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sams_cost_coverage_tab');
    const {assignmentText, onChangeHandler} = props;

    return (
        <Panel
            title={(
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <p>{translateTab('panel_title.commissioning')}</p>
                </div>
            )}
            qaIdent="sams-cost-coverage-commissioning"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-text"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={assignmentText}
                    onChange={value => onChangeHandler('assignmentText', value)}
                />
            </DataRow>
        </Panel>
    );
};

CostCoverageCommissioningPanel.propTypes = {
    assignmentText: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
};

CostCoverageCommissioningPanel.defaultProps = {
    assignmentText: '',
};

export default CostCoverageCommissioningPanel;
