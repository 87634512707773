import {select, take, fork, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/*
* Service case: get vehicle variants flow (uses silverDAT API)
*/
const getVehicleVariantsFlow = function* getVehicleVariantsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const silverDATService = serviceManager.loadService('silverDATService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.GET_VEHICLE_VARIANTS);
        const {vehicleType, manufacturer, baseModel} = payload;

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST,
            silverDATService.getVehicleVariants, {
                vehicleType,
                manufacturer,
                baseModel,
            },
        );

        const responseAction = yield take([
            serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {vehicleVariants} = response;

            yield put({
                type: serviceCaseActionTypes.STORE_VEHICLE_VARIANTS,
                payload: {vehicleVariants, vehicleType, manufacturer, baseModel},
            });
        }
    }
};

export default getVehicleVariantsFlow;
