/**
 * Command action types
 */
export const SUBMIT_CHANGE_USER_SHIFT_ROLE_FORM = '[user-profile] SUBMIT_CHANGE_USER_SHIFT_ROLE_FORM';
export const CONFIRM_REUPDATE_USER_SHIFT_ROLE = '[user-profile] CONFIRM_REUPDATE_USER_SHIFT_ROLE';
export const DECLINE_REUPDATE_USER_SHIFT_ROLE = '[user-profile] DECLINE_REUPDATE_USER_SHIFT_ROLE';
export const INITIATE_SHIFT_ROLE_UPDATE_ERROR_FLOW = '[user-profile] INITIATE_SHIFT_ROLE_UPDATE_ERROR_FLOW';

/**
 * Event action types
 */
export const FETCH_USER_PROFILE_REQUEST = '[user-profile] FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILE_REQUEST_SENT';
export const FETCH_USER_PROFILE_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILE_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILE_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILE_REQUEST_FAILED';

export const FETCH_USER_PROFILES_REQUEST = '[user-profile] FETCH_USER_PROFILES_REQUEST';
export const FETCH_USER_PROFILES_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILES_REQUEST_SENT';
export const FETCH_USER_PROFILES_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILES_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILES_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILES_REQUEST_FAILED';

export const FETCH_USER_SHIFT_ROLES_REQUEST = '[user-profile] FETCH_USER_SHIFT_ROLES_REQUEST';
export const FETCH_USER_SHIFT_ROLES_REQUEST_SENT = '[user-profile] FETCH_USER_SHIFT_ROLES_REQUEST_SENT';
export const FETCH_USER_SHIFT_ROLES_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_SHIFT_ROLES_REQUEST_SUCCEEDED';
export const FETCH_USER_SHIFT_ROLES_REQUEST_FAILED = '[user-profile] FETCH_USER_SHIFT_ROLES_REQUEST_FAILED';

export const UPDATE_USER_SHIFT_ROLE_REQUEST = '[user-profile] UPDATE_USER_SHIFT_ROLE_REQUEST';
export const UPDATE_USER_SHIFT_ROLE_REQUEST_SENT = '[user-profile] UPDATE_USER_SHIFT_ROLE_REQUEST_SENT';
export const UPDATE_USER_SHIFT_ROLE_REQUEST_SUCCEEDED = '[user-profile] UPDATE_USER_SHIFT_ROLE_REQUEST_SUCCEEDED';
export const UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED = '[user-profile] UPDATE_USER_SHIFT_ROLE_REQUEST_FAILED';

export const FETCH_LOGGED_IN_USERS_REQUEST = '[user-profile] FETCH_LOGGED_IN_USERS_REQUEST';
export const FETCH_LOGGED_IN_USERS_REQUEST_SENT = '[user-profile] FETCH_LOGGED_IN_USERS_REQUEST_SENT';
export const FETCH_LOGGED_IN_USERS_REQUEST_SUCCEEDED = '[user-profile] FETCH_LOGGED_IN_USERS_REQUEST_SUCCEEDED';
export const FETCH_LOGGED_IN_USERS_REQUEST_FAILED = '[user-profile] FETCH_LOGGED_IN_USERS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_USER_SHIFT_ROLES = '[user-profile] STORE_USER_SHIFT_ROLES';
export const STORE_USER_PROFILE = '[user-profile] STORE_USER_PROFILE';
export const STORE_USER_PROFILES = '[user-profile] STORE_USER_PROFILES';
export const SET_USER_SHIFT_ROLE = '[user-profile] SET_USER_SHIFT_ROLE';
export const STORE_SHIFT_ROLE_UPDATE_ERROR_CODE = '[user-profile] STORE_SHIFT_ROLE_UPDATE_ERROR_CODE';
