import {take, fork, put, select} from 'redux-saga/effects';
import {Vehicle} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Create member vehicle flow
 */
const createMemberVehicleFlow = function* createMemberVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take(memberActionTypes.INITIATE_CREATE_MEMBER_VEHICLE_FLOW);
        const {membershipNo, memberVehicle} = payload;

        yield fork(fetchRequest, memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST, membersService.createMemberVehicle, {
            memberVehicleDTO: Vehicle.objectToDTO(memberVehicle),
        });
        const responseAction = yield take([
            memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
            memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {memberVehicleDTO} = response;

            yield put({
                type: memberActionTypes.STORE_MEMBER_VEHICLE,
                payload: {membershipNo, memberVehicleDTO},
            });
        }
    }
};

export default createMemberVehicleFlow;
