import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Vehicle} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Member vehicle edit flow (uses modal)
 * edits a vehicle on the AMP (vehicle snapshots on cases are NOT affected)
 */
const editMemberVehicleFlow = function* editMemberVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take(memberActionTypes.INITIATE_EDIT_MEMBER_VEHICLE_FLOW);

        yield* openModal(modalIds.EDIT_MEMBER_VEHICLE, {vehicleId: `${payload?.vehicleId}`});
        const chosenModalOption = yield take([
            memberActionTypes.CONFIRM_EDIT_MEMBER_VEHICLE,
            memberActionTypes.DECLINE_EDIT_MEMBER_VEHICLE,
        ]);

        const {vehicleId} = payload;

        if (chosenModalOption && chosenModalOption.type === memberActionTypes.CONFIRM_EDIT_MEMBER_VEHICLE) {
            const {payload} = chosenModalOption;
            const {membershipNo, memberVehicle} = payload;

            yield fork(
                fetchRequest,
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST,
                membersService.updateMemberVehicle,
                {
                    memberVehiclePatchDTO: Vehicle.objectToPatchDTO(memberVehicle),
                    vehicleId,
                },
            );
            const responseAction = yield take([
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_FAILED,
            ]);

            // NOTE: errors are handled implicitly as part of the fetchRequest
            // below you can handle something in addition if needed

            if (!responseAction.error
               && responseAction.type === memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED) {
                const {response} = responseAction.payload;
                const {memberVehicleDTO} = response;

                yield put({
                    type: memberActionTypes.STORE_MEMBER_VEHICLE,
                    payload: {membershipNo, memberVehicleDTO},
                });
            }
        }

        yield* closeModal(modalIds.EDIT_MEMBER_VEHICLE, {vehicleId: `${vehicleId}`});
    }
};

export default editMemberVehicleFlow;
