import {createSelector} from 'reselect';

/**
 * General state selectors
 */
export const getMembers = state => state.members.members;
export const getMembersByMembershipNo = state => state.members.membersByMembershipNo;
export const getVehicles = state => state.members.vehicles;
export const getServiceAssignments = state => state.serviceAssignments.serviceAssignments;
export const getServiceCaseSearchResults = state => state.serviceCases.serviceCaseSearchResults;
export const getServiceCases = state => state.serviceCases.serviceCases;
export const getCommissioners = state => state.serviceCases.commissioners;
export const getServiceTypeDamageNodes = state => state.serviceCases.serviceTypeDamageNodes;
export const getVehicleInfos = state => state.serviceCases.vehicleInfos;
export const getCaseLogFilterResults = state => state.serviceCases.caseLogFilterResults;
export const getCaseLogFilterResultsCount = state => state.serviceCases.caseLogFilterResultsCount;
export const getCaseLogFilterResultsPage = state => state.serviceCases.caseLogFilterResultsPage;
export const getServiceTypeCategories = state => {
    const {serviceTypeDamageNodes} = state.serviceCases;
    if (Object.keys(serviceTypeDamageNodes).length === 0) return [];

    const damageCategoryParentId = Object.keys(serviceTypeDamageNodes).find(nodeId => {
        return serviceTypeDamageNodes[nodeId].parentNodeId.length === 1
            && serviceTypeDamageNodes[nodeId].parentNodeId[0] === null;
    });

    return serviceTypeDamageNodes[damageCategoryParentId].childNodeIds;
};

/**
 * Returns selector for ServiceCase matched from route
 */
export const createServiceCaseSelector = () => createSelector(
    [
        getServiceCases,
        (state, props) => {
            const {match} = props;
            return match?.params['serviceCaseId'];
        },
    ],
    (serviceCases, serviceCaseId) => {
        if (!serviceCaseId) return null;

        return serviceCases[serviceCaseId];
    },
);

/**
 * Returns ServiceAssignments selector for ServiceCase matched from route
 */
export const createServiceCaseAssignmentsSelector = () => createSelector(
    [
        getServiceAssignments,
        createServiceCaseSelector(),
    ],
    (serviceAssignments, serviceCase) => {
        if (!serviceCase || !serviceCase?.serviceAssignmentIds) return null;

        const serviceCaseAssignments = [];
        serviceCase.serviceAssignmentIds.forEach(serviceAssignmentId => {
            if (serviceAssignments[serviceAssignmentId]) {
                serviceCaseAssignments.push(serviceAssignments[serviceAssignmentId]);
            }
        });

        return serviceCaseAssignments;
    },
);

/**
 * Returns member's Vehicles selector for ServiceCase matched from route
 */
export const createMemberVehiclesSelector = () => createSelector(
    [
        getMembersByMembershipNo,
        createServiceCaseSelector(),
        getVehicles,
    ],
    (membersByMembershipNo, serviceCase, vehicles) => {
        if (!serviceCase.member.membershipNo) return null;

        const member = membersByMembershipNo[serviceCase.member.membershipNo];
        const memberVehicles = [];
        if (!member) return memberVehicles;

        member.vehicleIds.forEach(vehicleId => {
            if (vehicles[vehicleId]) memberVehicles.push(vehicles[vehicleId]);
        });

        return memberVehicles;
    },
);

/**
 * Returns initial assignment of the case
 */
export const createInitialServiceAssignmentSelector = () => createSelector(
    [
        getServiceAssignments,
        (state, props) => {
            const {match} = props;
            return match?.params['serviceCaseId'];
        },
    ],
    (serviceAssignments, serviceCaseId) => {
        if (!serviceAssignments || !serviceCaseId) return null;

        return serviceAssignments[`${serviceCaseId}-1`]
            || serviceAssignments[`${serviceCaseId}-FBS1`];
    },
);

export const createServiceAssignmentWithTowingDestinationSelector = () => createSelector(
    [
        getServiceAssignments,
        (state, props) => {
            const {match} = props;
            return match?.params['serviceCaseId'];
        },
    ],
    (serviceAssignments, serviceCaseId) => {
        if (!serviceAssignments || !serviceCaseId) return null;

        const serviceAssignmentsWithTowingDestination = Object.values(serviceAssignments).filter(serviceAssignment => (
            serviceAssignment.towingDestination
        )).sort((a, b) => a.lineNo.slice(-1) - b.lineNo.slice(-1));

        return serviceAssignmentsWithTowingDestination[0];
    },
);

export const createServiceCaseLogNoteSelector = () => createSelector(
    [
        createServiceCaseSelector(),
        (state, props) => {
            const {history} = props;
            const queryParams = new URLSearchParams(history?.location?.search || '');
            return {eventTime: queryParams?.get('eventTime'), eventDate: queryParams?.get('eventDate')};
        },
    ],
    (serviceCase, serviceCaseNoteLogComposites) => {
        if (!serviceCase
            || serviceCase.caseLogs.length === 0
            || !serviceCaseNoteLogComposites.eventDate
            || !serviceCaseNoteLogComposites.eventTime) return;

        const {eventDate, eventTime} = serviceCaseNoteLogComposites;

        return serviceCase.caseLogs.find(serviceCaseLog => {
            return serviceCaseLog.eventTime === eventTime && serviceCaseLog.eventDate === eventDate;
        });
    },
);

export const createServiceCaseGroupedAttachmentsSelector = () => createSelector(
    [
        createServiceCaseSelector(),
    ],
    serviceCase => {
        if (!serviceCase || serviceCase.attachments.length === 0) return;

        return serviceCase.attachments.reduce((prev, curr) => {
            (prev[curr.groupId] = prev[curr.groupId] || []).push(curr);
            return prev;
        }, {});
    },
);

export const createServiceCaseAttachmentsGroupSelector = () => createSelector(
    [
        createServiceCaseSelector(),
        (state, props) => {
            const {history} = props;
            const queryParams = new URLSearchParams(history?.location?.search || '');
            return queryParams.get('groupId');
        },
    ],
    (serviceCase, groupId) => {
        if (!groupId || !serviceCase || serviceCase.attachments.length === 0) return;

        return serviceCase.attachments.filter(attachment => attachment.groupId === groupId);
    },
);
