/**
 * @typedef {string} tabId
 */

/**
 * contractPartner screen tabs
 *
 * @enum {tabId}
 */
export default {
    BASIC_DATA: 'basic-data',
    SERVICES: 'services',
    CASE_HISTORY: 'case-history',
    TEMPORARY_RESTRICTIONS: 'temporary-restrictions',
};
