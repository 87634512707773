import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Pill, NoResultsBlock, findCaseIcon, Icon, warningAlertIcon} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import routePaths from '../../routePaths';
import * as dashboardSelectors from '../dashboardSelectors';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import TableLoaderSpinner from '../../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const pillTypes = {
    [efServiceAssignmentStatusTypes.NEW]: 'pending',
    [efServiceAssignmentStatusTypes.REJECTED]: 'negative',
    [efServiceAssignmentStatusTypes.CANCELED]: 'pending',
    [efServiceAssignmentStatusTypes.ASSIGNED]: 'positive',
};

const AssignmentsPanel = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {openServiceAssignments, users, serviceCases, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');

    const openServiceCaseScreen = serviceAssignment => {
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceAssignment.serviceCaseId,
        }, {
            state: {previousPath: history.location.pathname},
        }));
    };

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateScreen('assignment_panel.title')}
        >
            <Table qaIdent="open-service-assignments">
                {Object.values(openServiceAssignments).length === 0 && !isInProgress && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={findCaseIcon}
                                />
                                )}
                            message={translateScreen('assignment_panel_no_results.message')}
                            description={translateScreen('assignment_panel_no_results.description')}
                        />
                    </TableCaption>
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="sa-assigned-at" colSpan={3}>
                            {translateScreen('assignment_table_cell.assigned_date')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-assignment-status" colSpan={2}>
                            {translateScreen('assignment_table_cell.status')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-id" colSpan={3}>
                            {translateScreen('assignment_table_cell.assignment_number')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-service-provider-name" colSpan={4}>
                            {translateScreen('assignment_table_cell.partner_name')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-member-personal-details-last-name" colSpan={2}>
                            {translateScreen('assignment_table_cell.member_last_name')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-vehicle-licence-plate-number" colSpan={2}>
                            {translateScreen('assignment_table_cell.license_plate')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-damage-location-post-code" colSpan={2}>
                            {translateScreen('assignment_table_cell.post_code')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-created-by" colSpan={2}>
                            {translateScreen('assignment_table_cell.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(openServiceAssignments).length > 0 && !isInProgress
                        && Object.values(openServiceAssignments).map(serviceAssignment => {
                            const {acePartner, serviceCase} = serviceAssignment;
                            const {damage, id} = serviceCase;
                            const vehicle = serviceCases[id]?.vehicle || null;
                            const initials = users[serviceAssignment.createdBy]
                                ? users[serviceAssignment.createdBy]?.initials?.toUpperCase()
                                : '';
                            const createdByInitials = `${initials}`;
                            const currentDate = moment();
                            const isAssignedBefore10MinutesOrMore = serviceAssignment.assignedAt
                                ? (moment(serviceAssignment.assignedAt).add(10, 'minutes')).isBefore(currentDate) : false;
                            // eslint-disable-next-line max-len
                            const isAssigned = serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED;
                            const serviceAssignmentId = `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`;

                            return (
                                <TableRow
                                    key={serviceAssignmentId}
                                    onClick={() => openServiceCaseScreen(serviceAssignment)}
                                    qaIdentPart={serviceAssignmentId}
                                >
                                    <TableCell
                                        qaIdentPart="sa-assigned-at"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={3}
                                    >
                                        {serviceAssignment.assignedAt ? (
                                            <div
                                                className={cx([
                                                    'global!ace-u-inline-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-flex-start',
                                                ])}
                                            >
                                                {isAssignedBefore10MinutesOrMore && isAssigned
                                                    ? (
                                                        <div>
                                                            <Icon
                                                                icon={warningAlertIcon}
                                                                className={cx([
                                                                    'ace-c-icon--m',
                                                                    'ace-c-icon--color-warning',
                                                                ])}
                                                            />
                                                        </div>
                                                    ) : ''}
                                                <div>
                                                    {moment(serviceAssignment.assignedAt).format('DD.MM.YYYY HH:mm')}
                                                </div>
                                            </div>
                                        ) : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sa-assignment-status"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={2}
                                    >
                                        {serviceAssignment.assignmentStatus && (
                                            <Pill type={pillTypes[serviceAssignment.assignmentStatus]}>
                                                {translate(`global.service_assignment_status_type.${snakeCase(serviceAssignment.assignmentStatus)}`)}
                                            </Pill>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sa-id"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={3}
                                    >
                                        {`${serviceCase?.prefix}${serviceAssignment.serviceCaseId}${serviceAssignment.lineNo}`}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sa-service-provider-name"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={4}
                                    >
                                        {acePartner ? acePartner.name : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-member-personal-details-last-name"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={2}
                                    >
                                        {serviceCase && serviceCase.member && serviceCase.member.personalDetails
                                            ? serviceCase.member.personalDetails.surname : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-vehicle-licence-plate-number"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={2}
                                    >
                                        {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sc-damage-location-post-code"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={2}
                                    >
                                        {damage && damage.location
                                            ? `${damage.location.postCode}`
                                            : ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="sa-created-by"
                                        qaIdentPartPostfix={serviceAssignmentId}
                                        colSpan={2}
                                    >
                                        {createdByInitials}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Panel>
    );
};

AssignmentsPanel.propTypes = {
    openServiceAssignments: PropTypes.object,
    users: PropTypes.object,
    serviceCases: PropTypes.object,
    requestState: PropTypes.object,
};

AssignmentsPanel.defaultProps = {
    openServiceAssignments: {},
    users: {},
    serviceCases: null,
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST);
    return {
        openServiceAssignments: dashboardSelectors.getOpenServiceAssignments(state),
        users: userProfileSelectors.getUsers(state),
        serviceCases: serviceCaseSelectors.getServiceCases(state),
        requestState: createRequestStateSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(AssignmentsPanel);
