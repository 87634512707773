import {put} from 'redux-saga/effects';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Validate search results: set areSearchResultsValid flag
 */
const validateSearchResults = function* validateSearchResults({payload}) {
    const {areSearchResultsValid} = payload;
    yield put({
        type: memberActionTypes.SET_ARE_SEARCH_RESULTS_VALID,
        payload: {areSearchResultsValid},
    });
};

export default validateSearchResults;
