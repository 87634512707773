import React, {Fragment, useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerPersonPNG} from '@ace-de/eua-arcgis-map';
import {useStyles, Panel, ContentBlock, ContentItem, ScrollableBlock, Divider} from '@ace-de/ui-components';
import {Form, Autosuggest, Option, RadioButtonGroup} from '@ace-de/ui-components/form';
import {Icon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as saaActionTypes from './saaActionTypes';
import GooglePlacesLogo from '../application/ui-elements/GooglePlacesLogo';
import AccommodationLocationItem from './ui-elements/AccommodationLocationItem';

const SAAAccommodationTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('saa_accommodation_tab');
    const arcGISMap = useArcGISMap('service-assignment-accommodation');
    const {serviceCase, serviceAssignment, submitSAAAccommodationLocationForm, submitSAAReferencePositionForm} = props;
    const {searchSAAReferencePositionGeolocation, searchSAAAccommodationLocations} = props;
    const [selectedAccommodationLocationId, setSelectedAccommodationLocationId] = useState('');
    const currentReferencePositionAddress = useRef('');
    const lastReferencePositionSearchQuery = useRef('');
    const prevReverseGeocodingReferencePosition = useRef(null);
    const [newReferencePosition, setNewReferencePosition] = useState(null);
    const searchSAAReferencePositionGeolocationDebounced = useMemo(
        () => debounce(searchSAAReferencePositionGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSAAReferencePositionGeolocation],
    );

    // on mount, set referencePosition state
    useEffect(() => {
        if (!!newReferencePosition || !serviceAssignment) return;

        // set member location as initial referencePosition
        if (serviceAssignment.memberLocation) {
            setNewReferencePosition(serviceAssignment.memberLocation);
            return;
        }

        if (serviceAssignment.referencePosition) {
            setNewReferencePosition(serviceAssignment.referencePosition);
        }
    }, [newReferencePosition, serviceAssignment]);

    // on reverseGeocoding change, update referencePosition & search for accommodationLocations
    useEffect(() => {
        if (!serviceAssignment) return;

        const {reverseGeocodingReferencePosition} = serviceAssignment;
        if (reverseGeocodingReferencePosition
            && reverseGeocodingReferencePosition !== prevReverseGeocodingReferencePosition.current) {
            prevReverseGeocodingReferencePosition.current = reverseGeocodingReferencePosition;
            setNewReferencePosition(reverseGeocodingReferencePosition);

            searchSAAAccommodationLocations({
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                referencePosition: reverseGeocodingReferencePosition,
            });
        }
    }, [serviceAssignment, searchSAAAccommodationLocations]);

    // on change, set referencePosition map marker
    useEffect(() => {
        if (!arcGISMap) return;

        arcGISMap.setGraphics({
            graphics: [
                ...(newReferencePosition
                    ? [createMarkerGraphic({
                        id: 'referencePosition',
                        longitude: newReferencePosition.longitude,
                        latitude: newReferencePosition.latitude,
                        icon: markerPersonPNG,
                        isDraggable: true,
                    })] : []),
            ],
        });
    }, [arcGISMap, newReferencePosition]);

    // on serviceAssignment change, set selected accommodationLocation
    useEffect(() => {
        const {acePartner} = serviceAssignment;

        if (!acePartner || !acePartner?.externalId || selectedAccommodationLocationId === acePartner?.externalId) {
            return;
        }

        setSelectedAccommodationLocationId(acePartner.externalId);
    }, [serviceAssignment, selectedAccommodationLocationId]);

    const handleReferencePositionSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentReferencePositionAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSAAReferencePositionGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastReferencePositionSearchQuery.current = searchQueryString;
        }
    };

    const handleReferencePositionCandidateSelect = referencePositionCandidate => {
        currentReferencePositionAddress.current = referencePositionCandidate.formattedAddress;
        setNewReferencePosition(referencePositionCandidate);

        searchSAAAccommodationLocations({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            referencePosition: referencePositionCandidate,
        });

        submitSAAReferencePositionForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                referencePosition: referencePositionCandidate,
            },
        });
    };

    const handleAccommodationLocationSelect = newAccommodationLocationId => {
        if (!newAccommodationLocationId || selectedAccommodationLocationId === newAccommodationLocationId) return;
        setSelectedAccommodationLocationId(newAccommodationLocationId);
        const {accommodationLocationRecommendations} = serviceAssignment;
        submitSAAAccommodationLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            accommodationLocation: accommodationLocationRecommendations
                .find(accommodationLocation => accommodationLocation.id === newAccommodationLocationId),
        });
    };

    // on Enter, prevent form submission
    const onFormSubmit = event => {
        event.preventDefault();
    };

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return;

    const {referencePositionCandidates, referencePositionSearchQuery} = serviceAssignment;
    const {accommodationLocationRecommendations, updatedAccommodationLocationId} = serviceAssignment;
    const updatedAccommodationLocation = accommodationLocationRecommendations
        .find(accommodationLocation => accommodationLocation.id === updatedAccommodationLocationId);

    const numberOfRooms = serviceAssignment.numberOfDoubleBedroom
        + serviceAssignment.numberOfFamilyBedroom
        + serviceAssignment.numberOfTwinBedroom;
    const travelPeriod = serviceAssignment.stayStartDate && serviceAssignment.stayEndDate
        ? (moment(serviceAssignment.stayStartDate).format('DD.MM.YYYY')
            + ' – '
            + moment(serviceAssignment.stayEndDate).format('DD.MM.YYYY'))
        : '–';

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.choose_accommodation')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true}>
                        <Form name="referencePositionForm" onSubmit={onFormSubmit}>
                            {formValues => (
                                <Autosuggest
                                    name="referencePositionSearchQuery"
                                    value={newReferencePosition?.formattedAddress || ''}
                                    onChange={handleReferencePositionSearchQueryChange}
                                    onOptionSelect={handleReferencePositionCandidateSelect}
                                    optionValueSelector={locationCandidate => {
                                        return locationCandidate.formattedAddress;
                                    }}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                    label={translateTab('input_label.pickup_location')}
                                    placeholder={translateTab('input_placeholder.find_location')}
                                    icon={searchIcon}
                                >
                                    {(formValues['referencePositionSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                    && referencePositionSearchQuery === lastReferencePositionSearchQuery.current
                                        ? referencePositionCandidates
                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`pickup-location-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.formattedAddress}
                                                    </Option>
                                                );
                                            }) : null}
                                </Autosuggest>
                            )}
                        </Form>
                        <Divider />
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--wrap',
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-padding--24-0',
                            ])}
                        >
                            <p
                                className={cx([
                                    'global!ace-u-flex--basis-60',
                                    'global!ace-u-margin--right-16',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                <span className={cx('global!ace-u-typography--variant-body-bold')}>
                                    {translateTab('title.travel_period')}
                                </span><br />
                                {travelPeriod}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--basis-30',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                <span className={cx('global!ace-u-typography--variant-body-bold')}>
                                    {translateTab('title.number_of_rooms')}
                                </span><br />
                                {numberOfRooms}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--basis-60',
                                    'global!ace-u-margin--right-16',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                <span className={cx('global!ace-u-typography--variant-body-bold')}>
                                    {translateTab('title.number_of_persons')}
                                </span><br />
                                {translateTab('text.number_of_persons', {
                                    numberOfAdults: serviceAssignment.numberOfAdults,
                                    numberOfKids: serviceAssignment.numberOfKids,
                                })}
                            </p>
                            <p
                                className={cx([
                                    'global!ace-u-flex--basis-30',
                                    'global!ace-u-margin--bottom-16',
                                ])}
                            >
                                <span className={cx('global!ace-u-typography--variant-body-bold')}>
                                    {translateTab('title.number_of_pets')}
                                </span><br />
                                {serviceAssignment.numberOfPets || '–'}
                            </p>
                            <p>
                                <span className={cx('global!ace-u-typography--variant-body-bold')}>
                                    {translateTab('title.budget_per_night')}
                                </span><br />
                                {typeof serviceAssignment.budgetPerNight === 'number'
                                    ? serviceAssignment.budgetPerNight.toLocaleString(activeLocale, {
                                        style: 'currency',
                                        currency: config.CURRENCY,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : null}
                            </p>
                        </div>
                        <Divider />
                        {!!accommodationLocationRecommendations.length && (
                            <Fragment>
                                <RadioButtonGroup
                                    name="selectedAccommodationLocationId"
                                    value={selectedAccommodationLocationId
                                        || serviceAssignment.recommendedAccommodationLocationId}
                                    onChange={handleAccommodationLocationSelect}
                                    isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                        || serviceAssignment.persistenceState === persistenceStates.PENDING}
                                >
                                    {updatedAccommodationLocation && (
                                        <Fragment>
                                            <h4
                                                className={cx([
                                                    'global!ace-u-typography--variant-body-bold',
                                                    'global!ace-u-typography--align-left',
                                                    'global!ace-u-margin--24-0',
                                                ])}
                                            >
                                                {translateTab('heading.selected_hotel')}
                                            </h4>
                                            <AccommodationLocationItem
                                                accommodationLocation={updatedAccommodationLocation}
                                                /* eslint-disable-next-line max-len */
                                                isSelected={updatedAccommodationLocation.id === selectedAccommodationLocationId}
                                            />
                                            <h4
                                                className={cx([
                                                    'global!ace-u-typography--variant-body-bold',
                                                    'global!ace-u-typography--align-left',
                                                    'global!ace-u-margin--24-0',
                                                ])}
                                            >
                                                {translateTab('heading.other_hotels')}
                                            </h4>
                                        </Fragment>
                                    )}
                                    {accommodationLocationRecommendations.map(accommodationLocation => {
                                        return accommodationLocation.id !== updatedAccommodationLocationId && (
                                            <AccommodationLocationItem
                                                key={accommodationLocation.id}
                                                accommodationLocation={accommodationLocation}
                                                /* eslint-disable-next-line max-len */
                                                isSelected={accommodationLocation.id === selectedAccommodationLocationId}
                                            />
                                        );
                                    })}
                                </RadioButtonGroup>
                                <GooglePlacesLogo className={cx('global!ace-u-margin--top-8')} />
                            </Fragment>
                        )}
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAAAccommodationTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchSAAReferencePositionGeolocation: PropTypes.func.isRequired,
    submitSAAReferencePositionForm: PropTypes.func.isRequired,
    searchSAAAccommodationLocations: PropTypes.func.isRequired,
    submitSAAAccommodationLocationForm: PropTypes.func.isRequired,
};

SAAAccommodationTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSAAReferencePositionGeolocation: payload => dispatch({
        type: saaActionTypes.SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION,
        payload,
    }),
    submitSAAReferencePositionForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_REFERENCE_POSITION_FORM,
        payload,
    }),
    searchSAAAccommodationLocations: payload => dispatch({
        type: saaActionTypes.SEARCH_SAA_ACCOMMODATION_LOCATIONS,
        payload,
    }),
    submitSAAAccommodationLocationForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_ACCOMMODATION_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAAAccommodationTab));
