import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';

const loadSARCMapLayers = function* loadSARCMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-rental-car');

    if (!arcGISMap.isLayerSet('sarc-rental-car-stations')) {
        arcGISMap.createServiceLayer({
            layerId: 'sarc-rental-car-stations',
            serviceURL: config.ARCGIS_RENTAL_CAR_STATIONS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.RENTAL_CAR_STATION,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.RENTAL_CAR_STATION,
            isVisible: false,
        });
    }
};

export default loadSARCMapLayers;
