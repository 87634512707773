import {all, fork, takeLatest} from 'redux-saga/effects';
import * as saaActionTypes from './saaActionTypes';
import createServiceAssignmentAccommodationFlow from './sagas/createServiceAssignmentAccommodationFlow';
import searchSAAReferencePositionGeolocation from './sagas/searchSAAReferencePositionGeolocation';
import updateSAAReferencePositionFlow from './sagas/updateSAAReferencePositionFlow';
import searchSAAAccommodationLocations from './sagas/searchSAAAccommodationLocations';
import selectSAAAccommodationLocationFlow from './sagas/selectSAAAccommodationLocationFlow';
import saaMapAccommodationLocationWatcher from './sagas/saaMapAccommodationLocationWatcher';
import updateSAAPeopleAndRoomsDataFlow from './sagas/updateSAAPeopleAndRoomsDataFlow';
import updateSAAMemberLocationFlow from './sagas/updateSAAMemberLocationFlow';
import updateSAABudgetFlow from './sagas/updateSAABudgetFlow';
import updateSAACostCoverageFlow from './sagas/updateSAACostCoverageFlow';
import updateAccommodationDataFlow from './sagas/updateAccommodationDataFlow';
import searchSAAHotelLocationGeolocation from './sagas/searchSAAHotelLocationGeolocation';
import extendSAAStayEndDateFlow from './sagas/extendSAAStayEndDateFlow';

/**
 *  SAA watcher saga
 */
const saaWatcher = function* saaWatcher() {
    yield all([
        fork(createServiceAssignmentAccommodationFlow),
        fork(updateSAAPeopleAndRoomsDataFlow),
        fork(updateSAAMemberLocationFlow),
        takeLatest(saaActionTypes.SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION, searchSAAReferencePositionGeolocation),
        fork(updateSAAReferencePositionFlow),
        takeLatest(saaActionTypes.SEARCH_SAA_ACCOMMODATION_LOCATIONS, searchSAAAccommodationLocations),
        fork(selectSAAAccommodationLocationFlow),
        fork(saaMapAccommodationLocationWatcher),
        fork(updateSAABudgetFlow),
        fork(updateSAACostCoverageFlow),
        fork(updateAccommodationDataFlow),
        fork(extendSAAStayEndDateFlow),
        takeLatest(
            saaActionTypes.SEARCH_SAA_HOTEL_LOCATION_GEOLOCATION,
            searchSAAHotelLocationGeolocation,
        ),
    ]);
};

export default saaWatcher;
