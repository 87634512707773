import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as saaActionTypes from '../saaActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SAA cost coverage flow
 */
const updateSAACostCoverageFlow = function* updateSAACostCoverageFlow() {
    while (true) {
        const {payload} = yield take(saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM);
        const {
            serviceAssignmentLineNo,
            serviceAssignmentData,
            serviceCaseId,
            serviceCaseData,
            nonAceMember,
            attachmentsToSendWithAssignment,
        } = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });


        yield* updateServiceAssignment({
            caller: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (nonAceMember) {
            yield* updateServiceCase({
                caller: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM},
        });
    }
};

export default updateSAACostCoverageFlow;
