import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sapActionTypes from '../sapActionTypes';

/**
 * Update SAP requirements flow
 */
const updateSAPRequirementsFlow = function* updateSAPRequirementsFlow() {
    while (true) {
        const {payload} = yield take(sapActionTypes.SUBMIT_SAP_REQUIREMENTS_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sapActionTypes.SUBMIT_SAP_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.PICKUP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sapActionTypes.SUBMIT_SAP_REQUIREMENTS_FORM},
        });
    }
};

export default updateSAPRequirementsFlow;
