import React, {Fragment, useState, useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efChildrenAgeTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Form, Divider, DateField, calendarIcon, InputCounter} from '@ace-de/ui-components';
import {InputPhoneNumberField} from '@ace-de/ui-components/text-inputs';
import {Checkbox, SelectField, Option} from '@ace-de/ui-components/selector-inputs';
import config from '../../config';
import * as saaActionTypes from '../saaActionTypes';
import styles from './SAAPeopleAndRoomsPanel.module.scss';

const SAAPeopleAndRoomsPanel = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('saa_people_and_rooms_panel');
    const {serviceAssignment, submitSAAPeopleAndRoomsForm} = props;
    const {stayStartDate, stayEndDate, setStayStartDate, setStayEndDate} = props;
    const {numberOfKids, numberOfAdults, setNumberOfKids, setNumberOfAdults} = props;
    const [accommodationData, setAccommodationData] = useState(serviceAssignment);
    const [ageOfKids, setAgeOfKids] = useState(serviceAssignment.ageOfKids.length
        ? (serviceAssignment.ageOfKids.length < serviceAssignment.numberOfKids
            ? serviceAssignment.ageOfKids
                .concat(new Array(serviceAssignment.numberOfKids - serviceAssignment.ageOfKids.length)
                    .fill('', 0))
            : serviceAssignment.ageOfKids)
        : (serviceAssignment.numberOfKids
            ? new Array(serviceAssignment.numberOfKids).fill('', 0)
            : []));
    const isPeopleAndRoomsFormTouched = useRef(false);

    useEffect(() => {
        setAccommodationData(prevState => ({
            ...prevState,
            callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
        }));
    }, [serviceAssignment.callbackPhoneNo]);

    const handleTabChange = useCallback(formValues => {
        const {assignmentType, ...restFormValues} = formValues;
        const areStayDatesValid = moment(stayStartDate).isValid() && moment(stayEndDate).isValid()
            && moment(stayEndDate).isAfter(moment(stayStartDate));
        const numberOfNights = areStayDatesValid
            ? moment(stayEndDate).diff(moment(stayStartDate), 'days')
            : 0;
        const serviceAssignmentData = {
            ...restFormValues,
            ageOfKids: ageOfKids.filter(age => age !== ''),
            stayStartDate: stayStartDate,
            stayEndDate: stayEndDate,
            numberOfNights: numberOfNights,
        };

        submitSAAPeopleAndRoomsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSAAPeopleAndRoomsForm,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        ageOfKids,
        stayStartDate,
        stayEndDate,
    ]);

    const updateAgeOfKidsData = (numberOfKids, ageOfKidsValues) => {
        const currentAgeOfKidsValues = numberOfKids
            ? (Object.keys(ageOfKidsValues).length
                ? Object.keys(ageOfKidsValues).map(key => ageOfKidsValues[key])
                    .slice(0, accommodationData?.numberOfKids || serviceAssignment.numberOfKids)
                : ageOfKids)
            : [];

        if (numberOfKids > currentAgeOfKidsValues.length) {
            setAgeOfKids([...currentAgeOfKidsValues, '']);
            return;
        }
        if (numberOfKids < currentAgeOfKidsValues.length) {
            const updatedKidsArray = [...currentAgeOfKidsValues];
            updatedKidsArray.pop();
            setAgeOfKids(updatedKidsArray);
            return;
        }
        setAgeOfKids(currentAgeOfKidsValues);
    };

    // Note: sum numbers of rooms and put limit for all together to be 9 as max,
    // with any combination
    const handleLimitOfRooms = formValues => {
        const {numberOfDoubleBedroom, numberOfFamilyBedroom, numberOfTwinBedroom} = formValues;
        const sum = numberOfDoubleBedroom + numberOfFamilyBedroom + numberOfTwinBedroom;
        return sum >= config.MAXIMUM_NUMBER_OF_SELECTED_ROOMS;
    };

    const handleFormChange = formValues => {
        if (!isPeopleAndRoomsFormTouched.current) isPeopleAndRoomsFormTouched.current = true;
        updateAgeOfKidsData(formValues.numberOfKids, formValues.ageOfKids);
        setAccommodationData({
            ...formValues,
            creditCardIsAvailable: !!formValues['creditCardIsAvailable'],
            nightAccessNeeded: !!formValues['nightAccessNeeded'],
            idIsAvailable: !!formValues['idIsAvailable'],
            // TODO: Hidden feature >> uncomment this when feature is ready
            // extendStay: !!formValues['extendStay'],
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!accommodationData || !isPeopleAndRoomsFormTouched.current) {
            completeRouteUnmountSideEffect({
                caller: saaActionTypes.SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM,
            });
            return;
        }

        handleTabChange(accommodationData);
    }, [accommodationData, handleTabChange]);

    return (
        <Panel title={translateTab('panel_title.people_and_rooms')}>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-bold',
                    'global!ace-u-margin--bottom-24',
                ])}
            >
                {translateTab('text.period_of_time')}
            </p>
            <Form name="peopleAndRooms" onChange={handleFormChange}>
                {formValues => {
                    return (
                        <Fragment>
                            <div
                                className={cx([
                                    'global!ace-u-grid',
                                    'global!ace-u-margin--bottom-48',
                                ])}
                            >
                                <DateField
                                    name="stayStartDate"
                                    label={translateTab('date_field_label.start_date')}
                                    value={stayStartDate}
                                    onChange={setStayStartDate}
                                    placeholder={translateTab('date_field_placeholder.please_enter')}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                />
                                <DateField
                                    name="stayEndDate"
                                    label={translateTab('date_field_label.end_date')}
                                    value={stayEndDate}
                                    onChange={setStayEndDate}
                                    placeholder={translateTab('date_field_placeholder.please_enter')}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                />
                                {/* TODO: Hidden feature >> uncomment when feature is ready */}
                                {/* <Checkbox */}
                                {/*    name="extendStay" */}
                                {/*    value={true} */}
                                {/*    className={cx('global!ace-u-margin--top-24')} */}
                                {/*    isSelected={serviceAssignment.extendStay} */}
                                {/* > */}
                                {/*    {translateTab('checkbox_label.extension')} */}
                                {/* </Checkbox> */}
                            </div>
                            <Divider />
                            <div>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--32-0-24',
                                    ])}
                                >
                                    {translateTab('text.people_on_site')}
                                </p>
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    <div className={cx('global!ace-u-grid-column--span-4')}>
                                        <InputCounter
                                            name="numberOfAdults"
                                            label={translateTab('input_counter_label.adults')}
                                            value={numberOfAdults}
                                            onChange={setNumberOfAdults}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid-column--span-4',
                                            'global!ace-u-margin--left-24',
                                        ])}
                                    >
                                        <InputCounter
                                            name="numberOfKids"
                                            label={translateTab('input_counter_label.children')}
                                            value={numberOfKids}
                                            onChange={setNumberOfKids}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--wrap',
                                                'global!ace-u-flex--justify-space-between',
                                            ], {
                                                'global!ace-u-margin--bottom-48': ageOfKids.length,
                                            })}
                                        >
                                            <Form name="ageOfKids">
                                                {ageOfKids.map((age, idx) => (
                                                    <SelectField
                                                        key={idx}
                                                        name={`ageOfKids${idx}`}
                                                        label={`${translateTab('select_field_label.age_of_kid')} ${idx + 1}`}
                                                        value={age}
                                                        className={cx([
                                                            'global!ace-u-margin--top-8',
                                                            'global!ace-u-flex--basis-45',
                                                        ])}
                                                    >
                                                        {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                            <Option
                                                                key={idx}
                                                                name={`age-of-kid-option-${idx}`}
                                                                value={idx}
                                                            >
                                                                {efChildrenAgeTypes[ageOption]}
                                                            </Option>
                                                        ))}
                                                    </SelectField>
                                                ))}
                                            </Form>
                                        </div>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid-column--span-4',
                                            'global!ace-u-margin--left-24',
                                        ])}
                                    >
                                        <InputCounter
                                            name="numberOfPets"
                                            label={translateTab('input_counter_label.animals')}
                                            value={accommodationData.numberOfPets}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                    </div>
                                </div>
                                <div>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-margin--32-0-24',
                                        ])}
                                    >
                                        {translateTab('text.room_selection')}
                                    </p>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <div className={cx('global!ace-u-grid-column--span-4')}>
                                            <InputCounter
                                                name="numberOfDoubleBedroom"
                                                label={translateTab('input_counter_label.double_room')}
                                                value={accommodationData.numberOfDoubleBedroom}
                                                isLimitationAchieved={handleLimitOfRooms(formValues)}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'ace-c-input-counter--list-element',
                                                ])}
                                            />
                                            <Divider />
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid-column--span-4',
                                                'global!ace-u-margin--left-24',
                                            ])}
                                        >
                                            <div>
                                                <InputCounter
                                                    name="numberOfFamilyBedroom"
                                                    label={translateTab('input_counter_label.family_room')}
                                                    value={accommodationData.numberOfFamilyBedroom}
                                                    isLimitationAchieved={handleLimitOfRooms(formValues)}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'ace-c-input-counter--list-element',
                                                    ])}
                                                />
                                                <Divider />
                                            </div>
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid-column--span-4',
                                                'global!ace-u-margin--left-24',
                                            ])}
                                        >
                                            <InputCounter
                                                name="numberOfTwinBedroom"
                                                label={translateTab('input_counter_label.twin_room')}
                                                value={accommodationData.numberOfTwinBedroom}
                                                isLimitationAchieved={handleLimitOfRooms(formValues)}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'ace-c-input-counter--list-element',
                                                ])}
                                            />
                                            <Divider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                >
                                    {translateTab('text.additional_information')}
                                </p>
                                <div className={cx('global!ace-u-grid')}>
                                    <InputPhoneNumberField
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="callbackPhoneNo"
                                        value={accommodationData.callbackPhoneNo || ''}
                                        label={translateTab('input_label.callback_phone_no')}
                                    />
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--align-flex-start',
                                        'global!ace-u-margin--top-48',
                                    ])}
                                >
                                    <Checkbox
                                        name="idIsAvailable"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        isSelected={serviceAssignment.idIsAvailable}
                                    >
                                        {translateTab('checkbox_label.identity_card')}
                                    </Checkbox>
                                    <Checkbox
                                        name="creditCardIsAvailable"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        isSelected={serviceAssignment.creditCardIsAvailable}
                                    >
                                        {translateTab('checkbox_label.credit_card')}
                                    </Checkbox>
                                    <Checkbox
                                        name="nightAccessNeeded"
                                        value={true}
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        isSelected={serviceAssignment.nightAccessNeeded}
                                    >
                                        {translateTab('checkbox_label.night_porter')}
                                    </Checkbox>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Panel>
    );
};

SAAPeopleAndRoomsPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    submitSAAPeopleAndRoomsForm: PropTypes.func.isRequired,
    stayStartDate: PropTypes.string.isRequired,
    stayEndDate: PropTypes.string.isRequired,
    setStayStartDate: PropTypes.func.isRequired,
    setStayEndDate: PropTypes.func.isRequired,
    numberOfAdults: PropTypes.number.isRequired,
    numberOfKids: PropTypes.number.isRequired,
    setNumberOfAdults: PropTypes.func.isRequired,
    setNumberOfKids: PropTypes.func.isRequired,
};

SAAPeopleAndRoomsPanel.defaultProps = {
    serviceAssignment: {},
};

const mapDispatchToProps = dispatch => ({
    submitSAAPeopleAndRoomsForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_PEOPLE_AND_ROOMS_FORM,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(SAAPeopleAndRoomsPanel));
