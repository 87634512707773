import {
    efServiceAssignmentTypes,
    ServiceAssignmentRentalCar,
    ServiceAssignmentVehicle,
    ServiceAssignmentAccommodation,
    ServiceAssignmentShortTrip,
    ServiceAssignmentReturnJourney,
    ServiceAssignmentSubstituteDriver,
    ServiceAssignmentMedicalClearance,
    ServiceAssignmentSickPerson,
    ServiceAssignmentVehicleReturn,
    ServiceAssignmentOtherServices,
    ServiceAssignmentPickup,
    ServiceAssignmentTrailerReturn,
    ServiceAssignmentDeathEventHelp,
    ServiceAssignmentMedicineShipment,
    ServiceAssignmentMedicalAdvice,
    ServiceAssignmentLegalAdvice,
} from '@ace-de/eua-entity-types';

const getServiceAssignmentClass = assignmentType => {
    switch (assignmentType) {
        case efServiceAssignmentTypes.VEHICLE: {
            return ServiceAssignmentVehicle;
        }

        case efServiceAssignmentTypes.RENTAL_CAR: {
            return ServiceAssignmentRentalCar;
        }

        case efServiceAssignmentTypes.ACCOMMODATION: {
            return ServiceAssignmentAccommodation;
        }

        case efServiceAssignmentTypes.SHORT_TRIP: {
            return ServiceAssignmentShortTrip;
        }

        case efServiceAssignmentTypes.RETURN_JOURNEY: {
            return ServiceAssignmentReturnJourney;
        }

        case efServiceAssignmentTypes.SUBSTITUTE_DRIVER: {
            return ServiceAssignmentSubstituteDriver;
        }

        case efServiceAssignmentTypes.MEDICAL_CLEARANCE: {
            return ServiceAssignmentMedicalClearance;
        }

        case efServiceAssignmentTypes.SICK_PERSON: {
            return ServiceAssignmentSickPerson;
        }

        case efServiceAssignmentTypes.VEHICLE_RETURN: {
            return ServiceAssignmentVehicleReturn;
        }

        case efServiceAssignmentTypes.OTHER_SERVICES: {
            return ServiceAssignmentOtherServices;
        }

        case efServiceAssignmentTypes.PICKUP: {
            return ServiceAssignmentPickup;
        }

        case efServiceAssignmentTypes.TRAILER_RETURN: {
            return ServiceAssignmentTrailerReturn;
        }

        case efServiceAssignmentTypes.DEATH: {
            return ServiceAssignmentDeathEventHelp;
        }

        case efServiceAssignmentTypes.MEDICINE_SHIPMENT: {
            return ServiceAssignmentMedicineShipment;
        }

        case efServiceAssignmentTypes.MEDICAL_ADVICE: {
            return ServiceAssignmentMedicalAdvice;
        }

        case efServiceAssignmentTypes.LEGAL_ADVICE: {
            return ServiceAssignmentLegalAdvice;
        }

        default:
            return {};
    }
};

export default getServiceAssignmentClass;
