import {select, take, fork, put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import {getManufacturerIdByName, getModelIdByName} from '../../service-cases/useVehicleInfo';
import vehicleTypesMapping from '../../service-cases/vehicleTypesMapping';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';

const loadMemberVehicleInfo = function* loadMemberVehicleInfo({payload}) {
    const {location} = yield select(state => state.router);
    const {vehicles} = yield select(state => state.members);
    const queryParams = new URLSearchParams(location?.search || '');
    const vehicleId = queryParams.get('vehicleId') || null;
    const vehicle = vehicles && vehicleId ? vehicles[vehicleId] : null;

    if (!vehicle) return;

    const memberScreenMatch = matchPath(location.pathname, {
        path: routePaths.MEMBER,
        exact: true,
    });

    // load SilverDAT vehicle info only when edit modal is open
    if (!(memberScreenMatch && location?.state?.isModalOpen
        && location?.state?.modalId === modalIds.EDIT_MEMBER_VEHICLE)) return;

    const {serviceManager} = yield select(state => state.application);
    const silverDATService = serviceManager.loadService('silverDATService');
    const {vehicleInfos} = yield select(state => state.serviceCases);

    // CAR is default vehicle type in create vehicle form
    const vehicleType = vehicle.type
        ? vehicleTypesMapping[vehicle.type]
        : vehicleTypesMapping.CAR;

    // if manufacturers already exist, do not load them again
    if (vehicleInfos[vehicleType]) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST,
        silverDATService.getVehicleManufacturers, {
            vehicleType,
        },
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {vehicleManufacturers} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_VEHICLE_MANUFACTURERS,
            payload: {vehicleManufacturers, vehicleType},
        });

        // get models if manufacturer exists
        if (vehicle.type && vehicle.manufacturer) {
            const manufacturerId = getManufacturerIdByName(
                {
                    [vehicleType]: vehicleManufacturers,
                },
                {type: vehicle.type, manufacturer: vehicle.manufacturer},
            );

            if (manufacturerId) {
                yield fork(
                    fetchRequest,
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST,
                    silverDATService.getVehicleModels, {
                        vehicleType,
                        manufacturer: manufacturerId,
                    },
                );

                const responseAction = yield take([
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED,
                    serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_FAILED,
                ]);

                if (!responseAction.error) {
                    const {response} = responseAction.payload;
                    const {vehicleModels} = response;

                    yield put({
                        type: serviceCaseActionTypes.STORE_VEHICLE_MODELS,
                        payload: {vehicleModels, vehicleType, manufacturer: manufacturerId},
                    });

                    // get variants if model exists
                    if (vehicle?.model) {
                        const modelId = getModelIdByName(
                            {
                                [vehicleType]: {
                                    [manufacturerId]: {
                                        name: vehicle.manufacturer,
                                        models: vehicleModels,
                                    },
                                },
                            },
                            {
                                type: vehicle.type,
                                manufacturer: vehicle.manufacturer,
                                model: vehicle.model,
                            },
                        );
                        if (modelId) {
                            yield fork(
                                fetchRequest,
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST,
                                silverDATService.getVehicleVariants, {
                                    vehicleType,
                                    manufacturer: manufacturerId,
                                    baseModel: modelId,
                                },
                            );

                            const responseAction = yield take([
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED,
                                serviceCaseActionTypes.FETCH_VEHICLE_VARIANTS_REQUEST_FAILED,
                            ]);

                            if (!responseAction.error) {
                                const {response} = responseAction.payload;
                                const {vehicleVariants} = response;

                                yield put({
                                    type: serviceCaseActionTypes.STORE_VEHICLE_VARIANTS,
                                    payload: {
                                        vehicleVariants,
                                        vehicleType,
                                        manufacturer: manufacturerId,
                                        baseModel: modelId,
                                    },
                                });
                            }
                        }
                    }
                }
            }
        }
    }
};

export default loadMemberVehicleInfo;
