import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as saaActionTypes from '../saaActionTypes';

/**
 * Search reference position geolocation
 */
const searchSAAReferencePositionGeolocation = function* searchSAAReferencePositionGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, serviceAssignmentId} = payload;
    yield fork(
        fetchRequest,
        saaActionTypes.SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString, outFields: ['StAddr', 'Postal', 'City', 'Country', 'Region']},
    );

    const searchDamageLocationGeolocation = yield take([
        saaActionTypes.SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_SUCCEEDED,
        saaActionTypes.SEARCH_SAA_REFERENCE_POSITION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchDamageLocationGeolocation.error) {
        const {response} = searchDamageLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: saaActionTypes.SET_SAA_REFERENCE_POSITION_CANDIDATES,
            payload: {
                locationKey: 'referencePosition',
                arcGISLocationCandidateDTOs,
                serviceAssignmentId,
                searchQueryString,
            },
        });
    }
};

export default searchSAAReferencePositionGeolocation;
