import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import sapScreenTabs from '../sapScreenTabs';
import filterSAPMapContractPartnersBySearchQuery from './filterSAPMapContractPartnersBySearchQuery';
import filterSAPMapContractPartnersByLocation from './filterSAPMapContractPartnersByLocation';

const searchSAPContractPartners = function* searchSAPContractPartners({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, searchQuery, isVBASearch} = payload;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== sapScreenTabs.SERVICE_PROVIDER) return;

    if (searchQuery) {
        yield* filterSAPMapContractPartnersBySearchQuery(
            serviceCaseId,
            serviceAssignmentLineNo,
            searchQuery,
            isVBASearch,
        );
    } else {
        yield* filterSAPMapContractPartnersByLocation(serviceCaseId, serviceAssignmentLineNo, isVBASearch);
    }
};

export default searchSAPContractPartners;
