import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';

/**
 * Search tasks
 */
const searchTasks = function* searchTasks({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    const {searchQueryParams} = payload;

    // reset search results
    yield put({
        type: taskActionTypes.STORE_TASK_SEARCH_RESULTS,
        payload: {taskSearchResults: [], taskSearchResultsCount: 0},
    });

    yield fork(
        fetchRequest,
        taskActionTypes.SEARCH_TASKS_REQUEST,
        ecsTaskManagementService.searchTasks, {
            searchQueryParams,
        },
    );

    const searchTaskResponseAction = yield take([
        taskActionTypes.SEARCH_TASKS_REQUEST_SUCCEEDED,
        taskActionTypes.SEARCH_TASKS_REQUEST_FAILED,
    ]);

    if (!searchTaskResponseAction.error) {
        const {response} = searchTaskResponseAction.payload;
        const {results: taskSearchResults, totalCount: taskSearchResultsCount} = response;

        yield put({
            type: taskActionTypes.STORE_TASK_SEARCH_RESULTS,
            payload: {taskSearchResults, taskSearchResultsCount},
        });

        return;
    }
};

export default searchTasks;
