import {select, take, fork} from 'redux-saga/effects';
import {ehmCaseLogActionTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import config from '../../config';

const createCaseLogLeftFlow = function* createCaseLogLeftFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    while (true) {
        const {payload} = yield take(caseLogActionTypes.INITIATE_CREATE_CASE_LOG_LEFT_FLOW);
        const {serviceCaseId} = payload;

        if (config.IS_FALLBACK_SYSTEM) {
            continue;
        }

        yield fork(fetchRequest, caseLogActionTypes.CREATE_CASE_LOG_LEFT_REQUEST,
            ecsHistoryManagementService.createCaseLogInteraction, {
                caseId: serviceCaseId,
                actionType: ehmCaseLogActionTypes.CASE_LEFT,
            });
    }
};

export default createCaseLogLeftFlow;
