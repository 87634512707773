import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as saspActionTypes from '../saspActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SASP commissioning flow
 */
const updateSASPCommissioningFlow = function* updateSASPCommissioningFlow() {
    while (true) {
        const {payload} = yield take(saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM);
        const {
            serviceAssignmentLineNo,
            serviceAssignmentData,
            serviceCaseId,
            attachmentsToSendWithAssignment,
        } = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM,
            assignmentType: efServiceAssignmentTypes.SICK_PERSON,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saspActionTypes.SUBMIT_SASP_COMMISSIONING_FORM},
        });
    }
};

export default updateSASPCommissioningFlow;
