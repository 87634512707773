import React, {Fragment, useState, useRef, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {Panel, useStyles, Form, ButtonPrimary, RadioButton, downloadIcon, RadioButtonGroup, CheckboxGroup, List, Checkbox, InteractiveIcon, resetIcon} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {ehmCaseLogCategoryTypes, sortingOptions} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import serviceCaseScreenTabs from '../../service-cases/serviceCaseScreenTabs';
import AssignmentTypeOption from './AssignmentTypeOption';
import caseLogFilterCategories from '../caseLogFilterCategories';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import * as serviceCaseActionTypes from '../caseLogActionTypes';

const initialCategories = [...Object.values(ehmCaseLogCategoryTypes)]
    .filter(category => category !== ehmCaseLogCategoryTypes.CASE_DATA_CHANGE
        && category !== ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE
        && category !== ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE
        && category !== ehmCaseLogCategoryTypes.TASK
        && category !== ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK
        && category !== ehmCaseLogCategoryTypes.DRIVER_APP
        && category !== ehmCaseLogCategoryTypes.INVOICE);

const categories = Object.keys(caseLogFilterCategories);

const FilterPanel = props => {
    const {cx} = useStyles();
    const resetRef = useRef(false);
    const submitRef = useRef(false);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_protocol_tab');
    const {serviceAssignments, caseLogAssignments, serviceCaseId, history, createCaseLogPDF} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState({
        category: queryParams.getAll('category').length ? queryParams.getAll('category') : [],
        serviceTypes: queryParams.getAll('serviceTypes').length
            ? [...queryParams.getAll('serviceTypes'), queryParams.get('includeCaseLogs') ? 'SERVICE_CASE' : '']
            : queryParams.get('includeCaseLogs') ? ['SERVICE_CASE'] : [],
        sort: queryParams.get('sort'),
    });
    const [selectedTypes, setSelectedTypes] = useState(queryParams.getAll('serviceTypes').length
        ? [...queryParams.getAll('serviceTypes'), queryParams.get('includeCaseLogs') ? 'SERVICE_CASE' : '']
        : queryParams.get('includeCaseLogs') ? ['SERVICE_CASE'] : []);
    const initialAssignment = serviceAssignments?.find(serviceAssignment => {
        return (
            (parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.lineNo === 'FBS1')
            && serviceAssignment.serviceType
        );
    });

    const formatQueryParams = useCallback(formData => {
        const apiQueryParams = new URLSearchParams();
        apiQueryParams.append('activeTab', serviceCaseScreenTabs.PROTOCOL);

        Object.keys(formData).forEach(formField => {
            const formFieldValue = formData[formField];
            if (formFieldValue) {
                switch (formField) {
                    case 'category': {
                        if (!Array.isArray(formFieldValue) || !formFieldValue.length > 0) break;
                        // check if service type list contains at least one of assignment service types
                        const hasServiceType = formData.serviceTypes.length > 0
                            && formData.serviceTypes.some(serviceType => caseLogAssignments.includes(serviceType));
                        // create a combined list of categories based on selection
                        const categoryParams = formFieldValue.map(category => {
                            if (!category) return [];
                            const categoryValues = caseLogFilterCategories[category];
                            if (category === categories.STATUS_CHANGES) {
                                return hasServiceType
                                    ? categoryValues
                                    : categoryValues.splice(
                                        categoryValues.indexOf(ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE),
                                        1,
                                    );
                            }
                            return categoryValues;
                        }).flat();

                        // append category values
                        categoryParams.forEach(categoryParam => {
                            apiQueryParams.append('category', categoryParam);
                        });
                        break;
                    }
                    case 'serviceTypes': {
                        if (!Array.isArray(formFieldValue) || !formFieldValue.length > 0) break;
                        if (formFieldValue.includes(initialAssignment?.serviceType)
                            || formFieldValue.includes(initialAssignment?.assignmentType)
                        ) {
                            apiQueryParams.append('serviceTypes', 'UNKNOWN');
                        }

                        apiQueryParams.append('includeCaseLogs', formFieldValue.includes('SERVICE_CASE'));

                        formFieldValue.forEach(serviceType => {
                            if (serviceType !== 'SERVICE_CASE') {
                                apiQueryParams.append('serviceTypes', serviceType);
                            }
                        });
                        break;
                    }
                    case 'sort': {
                        apiQueryParams.append(formField, formData[formField]);
                        apiQueryParams.append(
                            formField,
                            formData[formField].includes('asc') ? 'eventTime,asc' : 'eventTime,desc',
                        );
                        break;
                    }
                    default:
                        break;
                }
            }
        });

        return apiQueryParams;
    }, [caseLogAssignments, initialAssignment?.serviceType, initialAssignment?.assignmentType]);

    const resetCaseLogFilteringParams = useCallback(() => {
        const initialCaseLogFilteringParams = {
            category: initialCategories,
            serviceTypes: [],
            sort: 'eventDate,asc',
            page: '0',
        };
        setFilterFormData(initialCaseLogFilteringParams);
        setSelectedTypes([]);
        const apiQueryParams = formatQueryParams(initialCaseLogFilteringParams);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(routePaths.SERVICE_CASE_SECTION, {serviceCaseId}, {search: queryParamsString}));
    }, [history, serviceCaseId, formatQueryParams]);

    useEffect(() => {
        if (queryParams.get('activeTab') === serviceCaseScreenTabs.PROTOCOL
           && !queryParams.get('category')
           && !queryParams.get('serviceTypes')
           && !queryParams.get('sort')
           && !queryParams.get('includeCaseLogs')) {
            resetCaseLogFilteringParams();
        }
    }, [history.location.search, queryParams, resetCaseLogFilteringParams]);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    },
    [history.location.search, queryParams]);

    const handleOnChange = filterData => {
        if (submitRef.current) return;
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }

        setFilterFormData(filterData);
    };

    const filterCaseLogs = formValues => {
        if (!formValues) {
            return;
        }
        const apiQueryParams = formatQueryParams(formValues);
        apiQueryParams.set('page', 0);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(routePaths.SERVICE_CASE_SECTION, {serviceCaseId}, {search: queryParamsString}));
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        submitRef.current = false;
        resetCaseLogFilteringParams();
    };

    const handleCreateCaseLogPDF = () => {
        const searchParams = history.location.search;
        const searchQueryParams = new URLSearchParams(searchParams);
        searchQueryParams.delete('page');
        createCaseLogPDF({serviceCaseId, searchQueryParams});
    };

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateTab('filter_panel.title')}
        >
            <div className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
                'global!ace-u-padding--0-32-24',
            ])}
            >
                <Form
                    name="filterCaseLogs"
                    onChange={handleOnChange}
                    onSubmit={filterCaseLogs}
                >
                    <Fragment>
                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--bottom-24',
                            ])}
                            >
                                {translateTab('filter_panel_category_label')}
                            </p>
                            <CheckboxGroup
                                name="category"
                                value={filterFormData.category}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                    ])}
                                >
                                    {categories.map((category, id) => (
                                        <Checkbox
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            key={`${category}-${id}`}
                                            name={category}
                                            value={category}
                                        >
                                            {translateTab(`filter_panel_category_checkbox.${category.toLowerCase()}`)}
                                        </Checkbox>
                                    ))}
                                </div>
                            </CheckboxGroup>
                        </div>
                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                {translateTab('filter_panel_assignment_label')}
                            </p>
                            <List
                                className={cx('global!ace-u-full-width')}
                                name="serviceTypes"
                                isMultipleChoice={true}
                                value={filterFormData.serviceTypes}
                                onChange={setSelectedTypes}
                            >
                                {(caseLogAssignments || []).map(caseLogAssignment => {
                                    return (
                                        <AssignmentTypeOption
                                            value={caseLogAssignment}
                                            isSelected={selectedTypes && selectedTypes.includes(caseLogAssignment)}
                                            key={`${caseLogAssignment}Key`}
                                            label={translate(`global.service_type.${caseLogAssignment.toLowerCase()}`)}
                                        />
                                    );
                                })}
                                <AssignmentTypeOption
                                    value="SERVICE_CASE"
                                    isSelected={selectedTypes && selectedTypes.includes('SERVICE_CASE')}
                                    key="serviceCaseKey"
                                    label={translateTab('filter_panel.service_case_option')}
                                />
                            </List>
                        </div>

                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            >
                                {translateTab('filter_panel_sorting_label')}
                            </p>
                            <RadioButtonGroup
                                name="sort"
                                value={queryParams.get('sort') || filterFormData?.sort}
                            >
                                {Object.values(sortingOptions).map((sortOption, id) => (
                                    <RadioButton
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        key={`${sortOption}-${id}`}
                                        name={sortOption}
                                        value={sortOption === sortingOptions.OLDEST_TO_NEWEST
                                            ? 'eventDate,asc' : 'eventDate,desc'}
                                    >
                                        {translateTab(`filter_panel_sorting_option.${sortOption.toLowerCase()}`)}
                                    </RadioButton>
                                ))}
                            </RadioButtonGroup>
                        </div>
                        <ButtonPrimary
                            className={cx('global!ace-u-full-width')}
                            type="submit"
                            name="filterButton"
                        >
                            {translateTab('filter_panel_button_label.filter')}
                        </ButtonPrimary>
                    </Fragment>
                </Form>
                <div className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-margin--top-16',
                ])}
                >
                    <InteractiveIcon
                        icon={resetIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                        ])}
                        onClick={handleResetFilter}
                    >
                        {translateTab('filter_panel_interactive_icon_label.reset_filter')}
                    </InteractiveIcon>
                </div>
                {ecsAccessControl.grantFeatureAccess(ecsFeatures.CASE_LOG_EXPORT, ecsFeatureActions.READ) && (
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-center',
                            'global!ace-u-margin--top-64',
                        ])}
                    >
                        <InteractiveIcon
                            icon={downloadIcon}
                            className={cx([
                                'ace-c-interactive-icon--reverse',
                                'ace-c-interactive-icon--highlight',
                            ])}
                            onClick={handleCreateCaseLogPDF}
                        >
                            {translateTab('filter_panel_interactive_icon_label.export_case_log')}
                        </InteractiveIcon>
                    </div>
                )}
            </div>
        </Panel>
    );
};


FilterPanel.propTypes = {
    serviceCaseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    caseLogAssignments: PropTypes.array,
    history: PropTypes.object,
    serviceAssignments: PropTypes.array,
    createCaseLogPDF: PropTypes.func.isRequired,
};

FilterPanel.defaultProps = {
    caseLogAssignments: null,
    history: {},
    serviceAssignments: null,
};

const mapDispatchToProps = dispatch => {
    return ({
        createCaseLogPDF: payload => dispatch({type: serviceCaseActionTypes.SUBMIT_CREATE_CASE_LOG_PDF, payload}),
    });
};

export default withRouter(connect(null, mapDispatchToProps)(FilterPanel));
