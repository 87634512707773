import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';
import contractPartnerInfoPopupTemplate from '../../service-assignments/contractPartnerInfoPopupTemplate';

const loadSAPMapLayers = function* loadSAPMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-pickup');

    if (!arcGISMap.isLayerSet('sap-contract-partner-service-areas')) {
        arcGISMap.createServiceLayer({
            layerId: 'sap-contract-partner-service-areas',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
            featureType: euaFeatureTypes.SELECTABLE_SERVICE_AREA,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
        });
    }

    if (!arcGISMap.isLayerSet('sap-contract-partner-routes')) {
        arcGISMap.createLayer({
            layerId: 'sap-contract-partner-routes',
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_ROUTE,
            featureType: euaFeatureTypes.LOCATION_ROUTE,
            isVisible: false,
        });
    }

    if (!arcGISMap.isLayerSet('sap-contract-partner-locations')) {
        arcGISMap.createServiceLayer({
            layerId: 'sap-contract-partner-locations',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.CONTRACT_PARTNER,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
            popupTemplate: contractPartnerInfoPopupTemplate,
        });
    }
};

export default loadSAPMapLayers;
