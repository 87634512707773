import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {europeanCountries, apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Divider} from '@ace-de/ui-components';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const handleMidnightFormat = endTime => {
    if (endTime !== '00:00' && endTime !== '24:00') return endTime;
    return endTime === '00:00' ? '24:00' : '00:00';
};

const ContractPartnerAddressDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner} = props;
    const mondayToFridayWorkingHours = contractPartner.workingHours.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY
    ));
    const saturdayWorkingHours = contractPartner.workingHours.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SATURDAY
    ));
    const sundayHolidayWorkingHours = contractPartner.workingHours.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY
    ));
    const workingHours = {
        monday_to_friday: {
            startTime: mondayToFridayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(mondayToFridayWorkingHours?.to?.slice(0, -3)) || '',
        },
        saturday: {
            startTime: saturdayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(saturdayWorkingHours?.to?.slice(0, -3)) || '',
        },
        sunday_and_holiday: {
            startTime: sundayHolidayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(sundayHolidayWorkingHours?.to?.slice(0, -3)) || '',
        },
    };

    const websiteUrl = useMemo(() => {
        if (!contractPartner?.businessContactDetails?.websiteUrl) return '';
        try {
            return new URL(contractPartner.businessContactDetails.websiteUrl).hostname;
        } catch (err) {
            return contractPartner?.businessContactDetails?.websiteUrl;
        }
    }, [contractPartner?.businessContactDetails?.websiteUrl]);

    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.address_data')}
            qaIdent="contract-partner-address-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.name')}
                        qaIdent="contract-partner-name"
                    >
                        {contractPartner.name}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.street')}
                        qaIdent="contract-partner-address-street"
                    >
                        {contractPartner.address?.street}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.post_code')}
                        qaIdent="contract-partner-address-post-code"
                    >
                        {contractPartner.address?.postCode}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.city')}
                        qaIdent="contract-partner-address-city"
                    >
                        {contractPartner.address?.city}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.state')}
                        qaIdent="contract-partner-address-state"
                    >
                        {contractPartner.address?.state}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.country')}
                        qaIdent="contract-partner-address-country"
                    >
                        {contractPartner.address?.country && europeanCountries[contractPartner.address.country]
                            ? translate(`global.country.${snakeCase(europeanCountries[contractPartner.address.country].name)}`)
                            : ''}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.phone_no')}
                        qaIdent="contract-partner-contact-details-phone"
                    >
                        <span className={cx('global!ace-u-typography--color-highlighted')}>
                            {contractPartner?.businessContactDetails?.phoneNo}
                        </span>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.fax_no')}
                        qaIdent="contract-partner-contact-details-fax-number"
                    >
                        <span className={cx('global!ace-u-typography--color-highlighted')}>
                            {contractPartner?.businessContactDetails?.faxNo}
                        </span>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.email')}
                        qaIdent="contract-partner-contact-details-email"
                    >
                        <a
                            href={`mailto:${contractPartner?.businessContactDetails?.email}`}
                            className={cx([
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {contractPartner?.businessContactDetails?.email}
                        </a>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.website')}
                        qaIdent="contract-partner-contact-details-website-url"
                    >
                        <a
                            href={contractPartner?.businessContactDetails?.websiteUrl}
                            target="_blank"
                            className={cx([
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-typography--variant-body',
                            ])}
                            rel="noreferrer"
                        >
                            {websiteUrl}
                        </a>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.general_reachability')}
                        qaIdent="contract-partner-contact-working-hours"
                        className={cx('ace-c-data-row--full-bleed-content')}
                        contentClassName={cx([
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-items-stretch',
                        ])}
                    >
                        {Object.keys(workingHours).map(workingHour => (
                            <div key={workingHour}>
                                <div
                                    className={cx([
                                        'global!ace-u-padding--16',
                                        'global!ace-u-flex',
                                    ])}
                                >
                                    <span
                                        className={cx([
                                            'global!ace-u-flex--basis-30',
                                            'global!ace-u-typography--variant-body-medium',
                                        ])}
                                    >
                                        {translateTab(`data_row_label.${workingHour}`)}:
                                    </span>
                                    <div>
                                        <span>
                                            {workingHours[workingHour].startTime}
                                        </span>
                                        <span>
                                            {workingHours[workingHour].startTime
                                                && workingHours[workingHour].endTime
                                                ? '–' : translateTab('data_row_label.closed')}
                                        </span>
                                        <span>
                                            {workingHours[workingHour].endTime}
                                        </span>
                                    </div>
                                </div>
                                {workingHour !== Object.keys(workingHours).pop() && <Divider />}
                            </div>
                        ))}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

ContractPartnerAddressDataPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerAddressDataPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerAddressDataPanel));
