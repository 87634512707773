import {all, fork, takeLatest} from 'redux-saga/effects';
import * as sarcActionTypes from './sarcActionTypes';
import createServiceAssignmentRentalCarFlow from './sagas/createServiceAssignmentRentalCarFlow';
import updateSARCRequirementsFlow from './sagas/updateSARCRequirementsFlow';
import updateSARCCostCoverageFlow from './sagas/updateSARCCostCoverageFlow';
import searchSARCDeliveryLocationGeolocation from './sagas/searchSARCDeliveryLocationGeolocation';
import searchSARCDropOffLocationGeolocation from './sagas/searchSARCDropOffLocationGeolocation';
import updateSARCMemberLocationFlow from './sagas/updateSARCMemberLocationFlow';
import updateSARCPickupLocationFlow from './sagas/updateSARCPickupLocationFlow';
import sendSARCACEPartnerEnquiryFlow from './sagas/sendSARCACEPartnerEnquiryFlow';
import searchSARCPickupLocationGeolocation from './sagas/searchSARCPickupLocationGeolocation';
import searchSARCRentalCarStations from './sagas/searchSARCRentalCarStations';
import selectSARCRentalCarStationFlow from './sagas/selectSARCRentalCarStationFlow';
import sarcMapRentalCarStationWatcher from './sagas/sarcMapRentalCarStationWatcher';
import updateSARCACEPartnerStationFlow from './sagas/updateSARCACEPartnerStationFlow';
import updateSARCCostCoverageRentalCarDataFlow from './sagas/updateSARCCostCoverageRentalCarDataFlow';
import updateSARCBudgetFlow from './sagas/updateSARCBudgetFlow';
import fetchSARCACEPartnerStationsFlow from './sagas/fetchSARCACEPartnerStationsFlow';
import updateSARCCostsAndBudgetFlow from './sagas/updateSARCCostsAndBudgetFlow';
import calculateMemberLocationToDeliveryLocationDistance from './sagas/calculateMemberLocationToDeliveryLocationDistance';
import extendSARCDropOffDateFlow from './sagas/extendSARCDropOffDateFlow';

/**
 *  SARC watcher saga
 */
const sarcWatcher = function* sarcWatcher() {
    yield all([
        fork(createServiceAssignmentRentalCarFlow),
        fork(updateSARCRequirementsFlow),
        fork(updateSARCCostCoverageFlow),
        takeLatest(sarcActionTypes.SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION, searchSARCDeliveryLocationGeolocation),
        takeLatest(sarcActionTypes.SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION, searchSARCDropOffLocationGeolocation),
        fork(updateSARCMemberLocationFlow),
        fork(updateSARCPickupLocationFlow),
        fork(sendSARCACEPartnerEnquiryFlow),
        takeLatest(sarcActionTypes.SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION, searchSARCPickupLocationGeolocation),
        takeLatest(sarcActionTypes.SEARCH_SARC_RENTAL_CAR_STATIONS, searchSARCRentalCarStations),
        fork(selectSARCRentalCarStationFlow),
        fork(sarcMapRentalCarStationWatcher),
        fork(updateSARCACEPartnerStationFlow),
        fork(fetchSARCACEPartnerStationsFlow),
        fork(updateSARCCostCoverageRentalCarDataFlow),
        fork(updateSARCBudgetFlow),
        fork(updateSARCCostsAndBudgetFlow),
        fork(calculateMemberLocationToDeliveryLocationDistance),
        fork(extendSARCDropOffDateFlow),
    ]);
};

export default sarcWatcher;
