import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

const createMemberNoteFlow = function* createMemberNoteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take([memberActionTypes.SUBMIT_CREATE_MEMBER_NOTE_FORM]);
        const {memberId, content} = payload;
        const {firstName, lastName} = yield select(state => state.user.user);

        yield fork(fetchRequest, memberActionTypes.CREATE_MEMBER_NOTE_REQUEST, membersService.createMemberNote, {
            memberId,
            firstName,
            lastName,
            content,
        });

        const responseAction = yield take([
            memberActionTypes.CREATE_MEMBER_NOTE_REQUEST_SUCCEEDED,
            memberActionTypes.CREATE_MEMBER_NOTE_REQUEST_FAILED,
        ]);

        // NOTE: errors are handled implicitly as part of the fetchRequest
        // below you can handle something in addition if needed

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {memberNoteDTO} = response;
            const {memberId} = memberNoteDTO;

            yield put({
                type: memberActionTypes.STORE_MEMBER_NOTE,
                payload: {memberNoteDTO, memberId},
            });
        }
    }
};

export default createMemberNoteFlow;
