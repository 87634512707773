import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Panel, TextAreaField} from '@ace-de/ui-components';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import * as serviceCaseActionTypes from '../savActionTypes';
import config from '../../config';

const VehicleDamageNotesPanel = props => {
    const {cx} = useStyles();
    const {serviceCase, submitSAVVehicleDamageNotes} = props;
    const {vehicleDamageNotes} = serviceCase;
    const [vehicleDamageNote, setVehicleDamageNote] = useState(vehicleDamageNotes);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sav_vehicle_tab');

    const handleOnTabChange = useCallback(vehicleDamageNotes => {
        const serviceCaseData = {
            vehicleDamageNotes: vehicleDamageNotes,
        };

        submitSAVVehicleDamageNotes({
            serviceCaseId: serviceCase.id,
            serviceCaseData,
        });
    }, [
        serviceCase.id,
        submitSAVVehicleDamageNotes,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!vehicleDamageNote) {
            completeRouteUnmountSideEffect({
                caller: serviceCaseActionTypes.SUBMIT_SAV_VEHICLE_DAMAGE_NOTES,
            });
            return;
        }
        handleOnTabChange(vehicleDamageNote);
    }, [
        handleOnTabChange,
        vehicleDamageNote,
    ]);

    return (
        <Panel title={translateTab('vehicle_damage_notes_panel.title')}>
            <TextAreaField
                name="vehicleDamageNotes"
                value={vehicleDamageNote}
                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                label={translateTab('vehicle_damage_notes_textarea_label.new_notes')}
                placeholder={translateTab('vehicle_damage_notes_textarea_placeholder.enter_new_note')}
                isResizable={false}
                className={cx('global!ace-u-full-width')}
                onChange={value => setVehicleDamageNote(value)}
            />
            <p
                className={cx([
                    'global!ace-u-margin--top-8',
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                ])}
            >
                {`${vehicleDamageNote.length}/${config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH} ${translate('global.unit.characters')}`}
            </p>
        </Panel>
    );
};

VehicleDamageNotesPanel.propTypes = {
    serviceCase: PropTypes.object.isRequired,
    submitSAVVehicleDamageNotes: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    submitSAVVehicleDamageNotes: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SAV_VEHICLE_DAMAGE_NOTES,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(VehicleDamageNotesPanel);
