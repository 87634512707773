import {fork} from 'redux-saga/effects';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import applicationWatcher from './application/applicationWatcher';
import userProfileWatcher from './user-profiles/userProfileWatcher';
import memberWatcher from './members/memberWatcher';
import serviceCaseWatcher from './service-cases/serviceCaseWatcher';
import serviceAssignmentWatcher from './service-assignments/serviceAssignmentWatcher';
import savWatcher from './service-assignments-vehicle/savWatcher';
import sarcWatcher from './service-assignments-rental-car/sarcWatcher';
import saaWatcher from './service-assignments-accommodation/saaWatcher';
import sastWatcher from './service-assignments-short-trip/sastWatcher';
import sarjWatcher from './service-assignments-return-journey/sarjWatcher';
import sasdWatcher from './service-assignments-substitute-driver/sasdWatcher';
import samcWatcher from './service-assignments-medical-clearance/samcWatcher';
import saspWatcher from './service-assignments-sick-person/saspWatcher';
import savrWatcher from './service-assignments-vehicle-return/savrWatcher';
import sapWatcher from './service-assignments-pickup/sapWatcher';
import savaWatcher from './service-assignments-vehicle-additional/savaWatcher';
import satrWatcher from './service-assignments-trailer-return/satrWatcher';
import taskWatcher from './tasks/taskWatcher';
import caseLogWatcher from './case-logs/caseLogWatcher';
import saosWatcher from './service-assignments-other-services/saosWatcher';
import contractPartnerWatcher from './contract-partners/contractPartnerWatcher';
import dashboardWatcher from './dashboard/dashboardWatcher';
import sadehWatcher from './service-assignments-death-event-help/sadehWatcher';
import samsWatcher from './service-assignments-medicine-shipment/samsWatcher';
import samaWatcher from './service-assignments-medical-advice/samaWatcher';
import salaWatcher from './service-assignments-legal-advice/salaWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(keycloakAuth.watcher),
    fork(arcGISAuth.watcher),
    fork(userProfileWatcher),
    fork(memberWatcher),
    fork(serviceCaseWatcher),
    fork(serviceAssignmentWatcher),
    fork(savWatcher),
    fork(sarcWatcher),
    fork(saaWatcher),
    fork(sastWatcher),
    fork(sarjWatcher),
    fork(sasdWatcher),
    fork(samcWatcher),
    fork(saspWatcher),
    fork(savrWatcher),
    fork(savaWatcher),
    fork(sapWatcher),
    fork(taskWatcher),
    fork(caseLogWatcher),
    fork(saosWatcher),
    fork(satrWatcher),
    fork(contractPartnerWatcher),
    fork(dashboardWatcher),
    fork(sadehWatcher),
    fork(samsWatcher),
    fork(samaWatcher),
    fork(salaWatcher),
];
