import {take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as saaActionTypes from '../saaActionTypes';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const updateAccommodationDataFlow = function* updateAccommodationDataFlow() {
    while (true) {
        yield take(saaActionTypes.INITIATE_UPDATE_ACCOMMODATION_FLOW);

        yield* openModal(modalIds.ACCOMMODATION_DATA_MODAL);

        const chosenModalOption = yield take([
            saaActionTypes.CONFIRM_UPDATE_ACCOMMODATION,
            saaActionTypes.DECLINE_UPDATE_ACCOMMODATION,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === saaActionTypes.CONFIRM_UPDATE_ACCOMMODATION) {
            const {
                serviceAssignmentLineNo,
                serviceAssignmentData,
                serviceCaseId,
                assignmentType,
            } = chosenModalOption.payload;

            yield* updateServiceAssignment({
                caller: saaActionTypes.CONFIRM_UPDATE_ACCOMMODATION,
                assignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });
        }

        yield* closeModal(modalIds.ACCOMMODATION_DATA_MODAL);
    }
};

export default updateAccommodationDataFlow;
