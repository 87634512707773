import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';
import contractPartnerInfoPopupTemplate from '../../service-assignments/contractPartnerInfoPopupTemplate';

const loadSAVAMapLayers = function* loadSAVAMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');

    if (!arcGISMap.isLayerSet('sava-contract-partner-service-areas')) {
        arcGISMap.createServiceLayer({
            layerId: 'sava-contract-partner-service-areas',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
            featureType: euaFeatureTypes.SELECTABLE_SERVICE_AREA,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
        });
    }

    if (!arcGISMap.isLayerSet('sava-contract-partner-routes')) {
        arcGISMap.createLayer({
            layerId: 'sava-contract-partner-routes',
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_ROUTE,
            featureType: euaFeatureTypes.LOCATION_ROUTE,
            isVisible: false,
        });
    }

    if (!arcGISMap.isLayerSet('sava-contract-partner-locations')) {
        arcGISMap.createServiceLayer({
            layerId: 'sava-contract-partner-locations',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.CONTRACT_PARTNER,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
            popupTemplate: contractPartnerInfoPopupTemplate,
        });
    }

    if (!arcGISMap.isLayerSet('sava-towing-destination-routes')) {
        arcGISMap.createLayer({
            layerId: 'sava-towing-destination-routes',
            featureEntityType: euaFeatureEntityTypes.TOWING_DESTINATION_ROUTE,
            featureType: euaFeatureTypes.LOCATION_ROUTE,
            isVisible: false,
        });
    }

    if (!arcGISMap.isLayerSet('sava-towing-destinations')) {
        arcGISMap.createLayer({
            layerId: 'sava-towing-destinations',
            featureEntityType: euaFeatureEntityTypes.TOWING_DESTINATION,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.TOWING_DESTINATION,
            isVisible: false,
        });
    }
};

export default loadSAVAMapLayers;
