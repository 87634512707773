import {put, select, take, fork, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';
import * as samaActionTypes from '../samaActionTypes';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const searchSAMADoctorLocations = function* searchSAMADoctorLocations({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, searchQuery} = payload;

    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (!serviceAssignment || !serviceAssignment?.serviceLocation) return;

    const {serviceManager} = yield select(state => state.application);

    const googlePlacesService = serviceManager.loadService('googlePlacesService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');

    if (!arcGISMap) return;
    const samaDoctorsLayer = yield call(arcGISMap.getLayer, 'sama-doctors-layer');
    if (!samaDoctorsLayer) return;

    yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);

    yield put({
        type: samaActionTypes.SET_SAMA_DOCTOR_LOCATION_RECOMMENDATIONS,
        payload: {serviceAssignmentId, doctorLocationRecommendationDTOs: []},
    });
    yield call(samaDoctorsLayer.setFeatures, {features: []});

    const referentialPoint = serviceAssignment.serviceLocation;
    const {longestDistance} = yield call(arcGISMap.getLongestDistanceFromPoint, referentialPoint);
    const mapViewport = yield call(arcGISMap.getMapViewport);


    yield fork(
        fetchRequest,
        samaActionTypes.SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST,
        googlePlacesService.searchPlaces,
        {
            searchQuery: searchQuery,
            longitude: referentialPoint.longitude,
            latitude: referentialPoint.latitude,
            locationRadius: Math.floor(longestDistance) * 1000 || null,
            viewport: mapViewport
                ? {
                    southwest: mapViewport.southwest,
                    northeast: mapViewport.northeast,
                }
                : null,
            placeTypes: ['doctor'],
        },
    );

    const placesSearchResponseAction = yield take([
        samaActionTypes.SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_SUCCEEDED,
        samaActionTypes.SEARCH_DOCTOR_LOCATION_GOOGLE_PLACES_REQUEST_FAILED,
    ]);

    if (placesSearchResponseAction.error) {
        yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
        return;
    }

    const {response: placesSearchResponse} = placesSearchResponseAction.payload;
    const {googlePlaceDTOs} = placesSearchResponse;

    const doctorLocationAddressList = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        if (googlePlaceDTO.formattedAddress) {
            doctorLocationAddressList.push({
                'OBJECTID': index, // should always be OBJECTID (not FID) for searchBulkAddressLocations
                'SingleLine': googlePlaceDTO.formattedAddress,
            });
        }
    });

    yield fork(
        fetchRequest,
        samaActionTypes.FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST,
        arcGISRESTService.searchBulkAddressLocations,
        {
            addressList: doctorLocationAddressList,
            returnRoutes: true,
            referentialPoint: referentialPoint,
        },
    );

    const fetchGeolocationsResponseAction = yield take([
        samaActionTypes.FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED,
        samaActionTypes.FETCH_DOCTOR_LOCATION_GEOLOCATIONS_REQUEST_FAILED,
    ]);

    if (fetchGeolocationsResponseAction.error) {
        yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
        return;
    }

    const {response: fetchGeolocationResponse} = fetchGeolocationsResponseAction.payload;
    const {arcGISGeocodingResults} = fetchGeolocationResponse;

    let doctorLocationRecommendationDTOs = [];
    googlePlaceDTOs.forEach((googlePlaceDTO, index) => {
        const arcGISGeocodingCandidateDTO = arcGISGeocodingResults.find(arcGISGeocodingCandidateDTO => {
            return arcGISGeocodingCandidateDTO.resultId === index;
        });
        if (arcGISGeocodingCandidateDTO) {
            doctorLocationRecommendationDTOs.push(
                {
                    ...googlePlaceDTO,
                    ...arcGISGeocodingCandidateDTO,
                    locationId: `${googlePlaceDTO.locationId}_${arcGISGeocodingCandidateDTO.locationId}`,
                    locationName: googlePlaceDTO.locationName,
                    formattedAddress: googlePlaceDTO.formattedAddress,
                },
            );
        }
    });

    // Determine if selected SP is within suggestion results
    let selectedLocationFromSuggestions = null;
    if (serviceAssignment.acePartner && serviceAssignment.acePartner.location) {
        const {locationName, id: locationId} = serviceAssignment.acePartner.location;

        selectedLocationFromSuggestions = doctorLocationRecommendationDTOs
            .find(doctorLocationRecommendationDTO => {
                return doctorLocationRecommendationDTO['locationName'] === locationName
                    && doctorLocationRecommendationDTO['locationId'] === locationId;
            });

        if (!selectedLocationFromSuggestions) {
            yield fork(
                fetchRequest,
                samaActionTypes.FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST,
                arcGISRESTService.getRoute,
                {
                    startingPoint: {
                        longitude: referentialPoint.longitude,
                        latitude: referentialPoint.latitude,
                    },
                    destination: {
                        longitude: serviceAssignment.acePartner.location.longitude,
                        latitude: serviceAssignment.acePartner.location.latitude,
                    },
                },
            );

            const routeResponseAction = yield take([
                samaActionTypes.FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_SUCCEEDED,
                samaActionTypes.FETCH_SERVICE_LOCATION_TO_DOCTOR_LOCATION_ROUTE_REQUEST_FAILED,
            ]);

            let routeToReferentialPoint;
            if (!routeResponseAction.error) {
                const {response} = routeResponseAction.payload;
                const {arcGISRouteDTO} = response;
                routeToReferentialPoint = arcGISRouteDTO;
            }

            doctorLocationRecommendationDTOs.unshift({
                ...serviceAssignment.acePartner.location.toDTO(),
                locationId,
                'routeToReferentialPoint': routeToReferentialPoint,
            });
        }

        if (selectedLocationFromSuggestions && selectedLocationFromSuggestions.locationId !== locationId) {
            yield* updateServiceAssignment({
                caller: samaActionTypes.SUBMIT_SAMA_SERVICE_LOCATION_FORM,
                assignmentType: efServiceAssignmentTypes.MEDICAL_ADVICE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        ...serviceAssignment.acePartner,
                        id: null, // see https://computerrock.atlassian.net/browse/ACELEA-575
                        externalId: selectedLocationFromSuggestions.locationId,
                        location: {
                            ...serviceAssignment.acePartner.location,
                            locationId: selectedLocationFromSuggestions.locationId,
                        },

                    },
                },
            });
        }
    }

    // sort doctor locations by distance
    doctorLocationRecommendationDTOs = doctorLocationRecommendationDTOs.sort((locationA, locationB) => {
        if (!locationA['routeToReferentialPoint']
            || !locationB['routeToReferentialPoint']) return 0;

        const {routeToReferentialPoint: locationARoute} = locationA;
        const {routeToReferentialPoint: locationBRoute} = locationB;
        return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
    });

    // render markers for doctor location options
    if (doctorLocationRecommendationDTOs.length > 0) {
        yield call(samaDoctorsLayer.setFeatures, {
            features: doctorLocationRecommendationDTOs.map((doctorLocationRecommendationDTO, index) => {
                let geometry = doctorLocationRecommendationDTO['arcGISPoint'];
                if (!geometry && doctorLocationRecommendationDTO['longitude'] && doctorLocationRecommendationDTO['latitude']) {
                    geometry = {
                        type: 'point',
                        x: doctorLocationRecommendationDTO['longitude'],
                        y: doctorLocationRecommendationDTO['latitude'],
                    };
                }
                if (!geometry) return null;
                return {
                    attributes: {
                        FID: index,
                        locationId: doctorLocationRecommendationDTO['locationId'],
                    },
                    geometry,
                };
            }).filter(Boolean),
        });

        const selectedDoctorLocationId = selectedLocationFromSuggestions
            ? selectedLocationFromSuggestions.locationId
            : serviceAssignment.acePartner?.location?.id || null;

        if (selectedDoctorLocationId) {
            yield call(samaDoctorsLayer.selectFeatureByAttribute, {
                where: `locationId = '${selectedDoctorLocationId}'`,
            });
        }
    }

    yield put({
        type: samaActionTypes.SET_SAMA_DOCTOR_LOCATION_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            doctorLocationRecommendationDTOs,
        },
    });

    // display layers
    samaDoctorsLayer.show();
    yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
};

export default searchSAMADoctorLocations;
