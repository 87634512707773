import {all, fork, takeLatest} from 'redux-saga/effects';
import * as sasdActionTypes from './sasdActionTypes';
import createServiceAssignmentSubstituteDriverFlow from './sagas/createServiceAssignmentSubstituteDriverFlow';
import updateSASDProblemAndDamageDetails from './sagas/updateSASDProblemAndDamageDetails';
import updateSASDJourneyDetails from './sagas/updateSASDJourneyDetails';
import updateSASDServiceProviderFlow from './sagas/updateSASDServiceProviderFlow';
import updateSASDCommissioningFlow from './sagas/updateSASDCommissioningFlow';
import updateSASDDamageLocationInfo from './sagas/updateSASDDamageLocationInfo';
import searchSASDRelevantLocationGeolocation from './sagas/searchSASDRelevantLocationGeolocation';
import updateSASDRelevantLocationFlow from './sagas/updateSASDRelevantLocationFlow';
import sasdMapRelevantLocationWatcher from './sagas/sasdMapRelevantLocationWatcher';

/**
 *  SASD watcher saga
 */
const sasdWatcher = function* sasdWatcher() {
    yield all([
        fork(createServiceAssignmentSubstituteDriverFlow),
        fork(updateSASDProblemAndDamageDetails),
        fork(updateSASDJourneyDetails),
        fork(updateSASDServiceProviderFlow),
        fork(updateSASDCommissioningFlow),
        fork(updateSASDDamageLocationInfo),
        takeLatest(sasdActionTypes.SEARCH_SASD_RELEVANT_LOCATION_GEOLOCATION, searchSASDRelevantLocationGeolocation),
        fork(updateSASDRelevantLocationFlow),
        fork(sasdMapRelevantLocationWatcher),
    ]);
};

export default sasdWatcher;
