import * as caseLogActionTypes from '../case-logs/caseLogActionTypes';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';
import * as memberActionTypes from '../members/memberActionTypes';
import * as saaActionTypes from '../service-assignments-accommodation/saaActionTypes';
import * as sadehActionTypes from '../service-assignments-death-event-help/sadehActionTypes';
import * as salaActionTypes from '../service-assignments-legal-advice/salaActionTypes';
import * as samcActionTypes from '../service-assignments-medical-clearance/samcActionTypes';
import * as saosActionTypes from '../service-assignments-other-services/saosActionTypes';
import * as sapActionTypes from '../service-assignments-pickup/sapActionTypes';
import * as sarcActionTypes from '../service-assignments-rental-car/sarcActionTypes';
import * as sarjActionTypes from '../service-assignments-return-journey/sarjActionTypes';
import * as sastActionTypes from '../service-assignments-short-trip/sastActionTypes';
import * as saspActionTypes from '../service-assignments-sick-person/saspActionTypes';
import * as sasdActionTypes from '../service-assignments-substitute-driver/sasdActionTypes';
import * as satrActionTypes from '../service-assignments-trailer-return/satrActionTypes';
import * as savaActionTypes from '../service-assignments-vehicle-additional/savaActionTypes';
import * as savrActionTypes from '../service-assignments-vehicle-return/savrActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../service-cases/serviceCaseActionTypes';
import * as taskActionTypes from '../tasks/taskActionTypes';
import * as userProfileActionTypes from '../user-profiles/userProfileActionTypes';

const formatTranslationKey = actionType => {
    if (!actionType) return '';
    actionType = !actionType.includes('_FAILED') ? `${actionType}_FAILED` : actionType;
    const action = actionType.split(']')[1];
    if (action) {
        return action.trim().toLowerCase();
    }
    return '';
};

/**
 * Register requests based on the action types
 * To track the request persistence state, use 'isInProgress' flag
 * To work with the request error, use the 'error' field
 * To enable RetryModal, use the 'hasRetryOption' flag, and (optionally) set the translation key
 */
const initialRequestStates = {
    [taskActionTypes.SEARCH_TASKS_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(taskActionTypes.SEARCH_TASKS_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [taskActionTypes.UPDATE_TASK_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(taskActionTypes.UPDATE_TASK_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [caseLogActionTypes.CREATE_CASE_LOG_PDF_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(caseLogActionTypes.CREATE_CASE_LOG_PDF_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.CREATE_MEMBER_NOTE_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            memberActionTypes.CREATE_MEMBER_NOTE_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.UPDATE_MEMBER_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            memberActionTypes.UPDATE_MEMBER_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [taskActionTypes.CREATE_TASK_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            taskActionTypes.CREATE_TASK_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceCaseActionTypes.CONFIRM_UPDATE_DOCUMENT_DESCRIPTION,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE,
        ),
        error: null,
        isInProgress: false,
    },
    [saaActionTypes.CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            saaActionTypes.CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sadehActionTypes.CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sadehActionTypes.CREATE_SERVICE_ASSIGNMENT_DEATH_EVENT_HELP_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [salaActionTypes.CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            salaActionTypes.CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [samcActionTypes.CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            samcActionTypes.CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [saosActionTypes.CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            saosActionTypes.CREATE_SERVICE_CASE_OTHER_SERVICES_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sapActionTypes.CREATE_SERVICE_CASE_PICKUP_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sapActionTypes.CREATE_SERVICE_CASE_PICKUP_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sarcActionTypes.CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sarcActionTypes.CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sarjActionTypes.CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sarjActionTypes.CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sastActionTypes.CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sastActionTypes.CREATE_SERVICE_CASE_SHORT_TRIP_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [saspActionTypes.CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            saspActionTypes.CREATE_SERVICE_CASE_SICK_PERSON_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [sasdActionTypes.CREATE_SERVICE_CASE_SUBSTITUTE_DRIVER_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            sasdActionTypes.CREATE_SERVICE_CASE_SUBSTITUTE_DRIVER_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [satrActionTypes.CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            satrActionTypes.CREATE_SERVICE_CASE_TRAILER_RETURN_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [savaActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            savaActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [savrActionTypes.CREATE_SERVICE_CASE_VEHICLE_RETURN_ASSIGNMENT_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            savrActionTypes.CREATE_SERVICE_CASE_VEHICLE_RETURN_ASSIGNMENT_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.CREATE_SERVICE_CASE_REQUEST]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceCaseActionTypes.CREATE_SERVICE_CASE_REQUEST,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_DELETE]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_DELETE,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_UPLOAD]: {
        hasRetryOption: true,
        translationKey: formatTranslationKey(
            serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_UPLOAD,
        ),
        error: null,
        isInProgress: false,
    },
    [taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [caseLogActionTypes.FETCH_CASE_LOG_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(caseLogActionTypes.FETCH_CASE_LOG_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [memberActionTypes.SEARCH_MEMBERS_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(memberActionTypes.SEARCH_MEMBERS_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [userProfileActionTypes.FETCH_LOGGED_IN_USERS_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(userProfileActionTypes.FETCH_LOGGED_IN_USERS_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(
            serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_FAILED,
        ),
        error: null,
        isInProgress: false,
    },
    [serviceCaseActionTypes.FETCH_REPORTER_CASES_COUNT_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(serviceCaseActionTypes.FETCH_REPORTER_CASES_COUNT_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
    [taskActionTypes.FETCH_REPORTER_TASKS_COUNT_REQUEST]: {
        hasRetryOption: false,
        translationKey: formatTranslationKey(taskActionTypes.FETCH_REPORTER_TASKS_COUNT_REQUEST_FAILED),
        error: null,
        isInProgress: false,
    },
};

export default initialRequestStates;
