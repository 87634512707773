import {all, fork} from 'redux-saga/effects';
import createServiceAssignmentOtherServicesFlow from './sagas/createServiceAssignmentOtherServicesFlow';
import updateSAOSRequirementsDataFlow from './sagas/updateSAOSRequirementsDataFlow';
import updateSAOSDamageLocationFlow from './sagas/updateSAOSDamageLocationFlow';
import updateSAOSCostCoverageDataFlow from './sagas/updateSAOSCostCoverageFlow';
import updateSAOSServiceProviderFlow from './sagas/updateSAOSServiceProviderFlow';

/**
 *  SAOS watcher saga
 */
const saosWatcher = function* saosWatcher() {
    yield all([
        fork(createServiceAssignmentOtherServicesFlow),
        fork(updateSAOSRequirementsDataFlow),
        fork(updateSAOSDamageLocationFlow),
        fork(updateSAOSCostCoverageDataFlow),
        fork(updateSAOSServiceProviderFlow),
    ]);
};

export default saosWatcher;
