import React, {useState, Fragment, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {snakeCase, camelCase, kebabCase} from 'change-case';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberSalutationTypes, ampMemberSexTypes, europeanCountries, efServiceCaseDocumentTypes, ServiceCaseVehicle, efServiceCaseTypes, ampMembershipTypes, ampMemberTariffGroups, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Divider, Option, Button, HighlightCircle} from '@ace-de/ui-components';
import {Form, SelectField, InputField, DateField, AutocompleteField, TextAreaField, NumberInputField} from '@ace-de/ui-components/form';
import {closeIcon, InteractiveIcon, calendarIcon, Icon, vehicleIcon, arrowDownIcon, uploadIcon, plusIcon, attachmentIcon, timeIcon, deleteIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {useFileAssets, FileAssetsInput, FileAssetsDropZone} from '@ace-de/eua-file-assets';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import config from '../../config';
import formatBytes from '../../application/formatBytes';
import allowedAttachmentMIMETypes from '../../service-cases/allowedAttachmentMIMETypes';
import {validatePhoneNo, validateEmail} from '../../utils/validation';

const fileSizeLimit = formatBytes(config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES);
const initialErrors = {
    email: '',
    phoneNo: '',
    country: '',
};

const CreateForeignAssistanceCaseModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('foreign_assistance_case_data_modal');
    const {hasBackdrop, commissioners, declineCreateForeignAssistanceCase, confirmCreateForeignAssistanceCase} = props;
    const [country, setCountry] = useState('');
    const [foreignAssistanceCaseData, setForeignAssistanceCaseData] = useState(new ServiceCaseVehicle());
    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'efFileAssetsService',
        fileList: 'sc-documents',
    });
    const [documentType, setDocumentType] = useState(efServiceCaseDocumentTypes.REQUEST);
    const [description, setDescription] = useState('');
    const availableCommissioners = useMemo(() => {
        return Object.values(commissioners)
            .filter(commissioner => commissioner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE)
            .sort((commissionerA, commissionerB) => {
                const commissionerAName = (commissionerA.name || '').toLowerCase();
                const commissionerBName = (commissionerB.name || '').toLowerCase();
                return commissionerAName.localeCompare(commissionerBName);
            });
    }, [commissioners]);
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    const isButtonDisabled = !foreignAssistanceCaseData.commissioner
        || !foreignAssistanceCaseData.member.personalDetails.surname
        || !foreignAssistanceCaseData.externalCaseId
        || fileList.files.some(fileAsset => fileAsset.isUploadPending);

    const areFormValuesValid = formValues => {
        let hasError = false;
        const updatedErrors = {...initialErrors};
        // validate email
        if (formValues.email && !validateEmail(formValues.email)) {
            updatedErrors['email'] = translateModal('error_message.invalid_email');
            hasError = true;
        }
        // validate phone number
        if (formValues.phoneNo && !validatePhoneNo(formValues.phoneNo)) {
            updatedErrors['phoneNo'] = translateModal('error_message.invalid_phone_number');
            hasError = true;
        }
        // validate country
        if (formValues.country && !europeanCountries[formValues.country]) {
            updatedErrors['country'] = translateModal('error_message.invalid_country');
            hasError = true;
        }
        if (!hasError) {
            setErrors(initialErrors);
            return true;
        }
        setErrors(updatedErrors);
        return false;
    };

    const handleOnSubmit = () => {
        const {member, commissioner} = foreignAssistanceCaseData;
        const {personalDetails} = member;
        const selectedCommissioner = commissioners[commissioner];
        const fileAssets = fileList.files;

        const hasValidationError = !areFormValuesValid({
            email: member?.contactDetails?.email,
            phoneNo: member?.contactDetails?.phoneNo,
            country: member?.personalDetails?.address?.country,
        });
        if (hasValidationError) return;

        if (personalDetails.surname
            && selectedCommissioner
            && (fileAssets.length === 0 || !fileAssets.some(fileAsset => fileAsset.isUploadPending))) {
            confirmCreateForeignAssistanceCase({
                ...foreignAssistanceCaseData,
                commissioner: selectedCommissioner,
                initialAttachments: fileAssets.length > 0
                    ? fileAssets.map(fileAsset => ({
                        id: fileAsset.id,
                        fileName: fileAsset.fileName,
                        attachmentType: documentType,
                        url: fileAsset.url,
                        groupId: null,
                        description: description,
                    }))
                    : null,
            });
        }
    };

    const handleCommissionerAndPersonalData = formValues => {
        const {
            street,
            country,
            city,
            postCode,
            phoneNo,
            email,
            commissioner,
            externalCaseId,
            maximumBudget,
            birthday,
            ...restFormValues
        } = formValues;

        setForeignAssistanceCaseData({
            commissioner: commissioner,
            member: {
                membershipNo: '0000000000',
                membershipType: ampMembershipTypes.PRIVATE,
                tariffDetails: {
                    currentTariff: ampMemberTariffGroups.NON_MEMBER,
                },
                personalDetails: {
                    ...restFormValues,
                    ...(restFormValues.firstName && restFormValues.surname
                        ? {name: `${restFormValues.firstName} ${restFormValues.surname}`} : {}),
                    birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : '',
                    address: {
                        country: country,
                        city: city,
                        postCode: postCode,
                        street: street,
                    },
                },
                contactDetails: {
                    email: email,
                    phoneNo: phoneNo,
                },
            },
            maximumBudget,
            externalCaseId,
            nonAceMember: true,
            caseType: efServiceCaseTypes.VEHICLE,
        });
    };

    return (
        <Modal
            title={translateModal('title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateForeignAssistanceCase}
                />
            )}
            className={cx('ace-c-modal--full-scrollable')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <div className={cx('global!ace-u-grid')}>
                    <div className={cx({
                        'global!ace-u-grid-column--span-9': !config.IS_FALLBACK_SYSTEM,
                        'global!ace-u-grid-column--span-12': config.IS_FALLBACK_SYSTEM,
                    })}
                    >
                        <Form name="commissionerAndPersonalData" onChange={handleCommissionerAndPersonalData}>
                            {() => {
                                return (
                                    <Fragment>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--48-0',
                                                'global!ace-u-flex--align-flex-end',
                                            ])}
                                        >
                                            <SelectField
                                                name="commissioner"
                                                placeholder={translateModal('select.placeholder')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                value={foreignAssistanceCaseData.commissioner
                                                    ? foreignAssistanceCaseData.commissioner : ''}
                                                isFieldRequired={true}
                                            >
                                                {availableCommissioners.map((commissioner, idx) => (
                                                    <Option
                                                        key={`${commissioner.id}-${idx}`}
                                                        name={`${commissioner.id}-${idx}`}
                                                        value={commissioner.id}
                                                    >
                                                        {commissioner.name}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                            <InputField
                                                name="externalCaseId"
                                                value={foreignAssistanceCaseData.externalCaseId || ''}
                                                label={translateModal('input_external_assignment_number.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                isFieldRequired={true}
                                            />
                                            <NumberInputField
                                                name="maximumBudget"
                                                value={foreignAssistanceCaseData.maximumBudget || ''}
                                                label={translateModal('input_maximum_budget.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                min={0}
                                            />
                                        </div>
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-h3',
                                                'global!ace-u-margin--24-0',
                                            ])}
                                        >
                                            {translateModal('text.personal_data')}
                                        </p>
                                        <div className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-24',
                                        ])}
                                        >
                                            <SelectField
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                label={translateModal('select_salutation.label')}
                                                name="salutation"
                                                value={foreignAssistanceCaseData.member.personalDetails.salutation
                                                    ? foreignAssistanceCaseData.member.personalDetails.salutation : ''}
                                                placeholder={translate('global.select.placeholder')}
                                            >
                                                {Object.keys(ampMemberSalutationTypes).map(salutation => {
                                                    return (
                                                        <Option
                                                            key={`salutation${camelCase(salutation)}`}
                                                            name={`salutation${camelCase(salutation)}`}
                                                            value={ampMemberSalutationTypes[salutation]}
                                                            className={cx('ace-c-option--small')}
                                                        >
                                                            {translateModal(`salutation_option.${snakeCase(salutation)}`)}
                                                        </Option>
                                                    );
                                                })}
                                            </SelectField>
                                            <InputField
                                                name="surname"
                                                value={foreignAssistanceCaseData.member.personalDetails
                                                    ? foreignAssistanceCaseData.member.personalDetails.surname : ''}
                                                label={translateModal('input_surname.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                isFieldRequired={true}
                                            />
                                            <InputField
                                                name="firstName"
                                                value={foreignAssistanceCaseData.member.personalDetails
                                                    ? foreignAssistanceCaseData.member.personalDetails.firstName : ''}
                                                label={translateModal('input_name.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                            />
                                            <InputField
                                                name="street"
                                                value={foreignAssistanceCaseData.member.personalDetails
                                                    ? foreignAssistanceCaseData.member.personalDetails.address.street : ''}
                                                label={translateModal('input_street_and_number.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                            />
                                            <InputField
                                                name="postCode"
                                                value={foreignAssistanceCaseData.member.personalDetails
                                                    ? foreignAssistanceCaseData.member.personalDetails.address.postCode : ''}
                                                label={translateModal('input_post_code.label')}
                                                className={cx('global!ace-u-grid-column--span-2')}
                                            />
                                            <InputField
                                                name="city"
                                                value={foreignAssistanceCaseData.member.personalDetails
                                                    ? foreignAssistanceCaseData.member.personalDetails.address.city : ''}
                                                label={translateModal('input_city.label')}
                                                className={cx('global!ace-u-grid-column--span-2')}
                                            />
                                            <AutocompleteField
                                                name="country"
                                                value={country}
                                                label={translateModal('select_country.label')}
                                                placeholder={translate('global.select.placeholder')}
                                                onOptionSelect={value => setCountry(value)}
                                                icon={arrowDownIcon}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                errors={errors['country'] ? [errors['country']] : []}
                                            >
                                                {sortedCountries
                                                    .map(([countryCode, country]) => {
                                                        return (
                                                            <Option
                                                                key={country.id}
                                                                name={`country-${country.id}`}
                                                                value={countryCode}
                                                                className={cx('ace-c-option--small')}
                                                            >
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })}
                                            </AutocompleteField>
                                            <InputField
                                                name="phoneNo"
                                                value={foreignAssistanceCaseData.member.contactDetails
                                                    ? foreignAssistanceCaseData.member.contactDetails.phoneNo : ''}
                                                label={translateModal('input_phone.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                errors={errors['phoneNo'] ? [errors['phoneNo']] : []}
                                            />
                                            <InputField
                                                name="email"
                                                value={foreignAssistanceCaseData.member.contactDetails
                                                    ? foreignAssistanceCaseData.member.contactDetails.email : ''}
                                                label={translateModal('input_email.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                                errors={errors['email'] ? [errors['email']] : []}
                                            />
                                            <span className={cx('global!ace-u-grid-column--span-4')} />
                                            <SelectField
                                                name="sex"
                                                placeholder={translate('global.select.placeholder')}
                                                value={foreignAssistanceCaseData.member.personalDetails.sex
                                                    ? foreignAssistanceCaseData.member.personalDetails.sex : ''}
                                                label={translateModal('select_sex.label')}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                            >
                                                {Object.keys(ampMemberSexTypes).map((sex, idx) => {
                                                    return (
                                                        <Option
                                                            key={`sex${idx}`}
                                                            name={`sex${camelCase(sex)}`}
                                                            value={sex}
                                                            className={cx('ace-c-option--small')}
                                                        >
                                                            {translate(`global.member_sex.${snakeCase(sex)}`)}
                                                        </Option>
                                                    );
                                                })}
                                            </SelectField>
                                            <DateField
                                                name="birthday"
                                                label={translateModal('select_birthdate.label')}
                                                placeholder={translate('global.select.placeholder')}
                                                value={foreignAssistanceCaseData.member.personalDetails.birthday
                                                    ? foreignAssistanceCaseData.member.personalDetails.birthday : ''}
                                                icon={calendarIcon}
                                                className={cx('global!ace-u-grid-column--span-4')}
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }}
                        </Form>
                    </div>
                    {!config.IS_FALLBACK_SYSTEM && (
                        <div className={cx('global!ace-u-grid-column--span-3')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--16-0',
                                ])}
                            >
                                {translateModal('text.upload_files')}
                            </p>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-flex--align-stretch',
                                ])}
                            >
                                <FileAssetsDropZone
                                    service="efFileAssetsService"
                                    fileList="sc-documents"
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--justify-center',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-padding--0-8',
                                    ])}
                                >
                                    <HighlightCircle
                                        className={cx([
                                            'global!ace-u-margin--32-192',
                                            'ace-c-highlight-circle--medium',
                                            'ace-c-highlight-circle--primary',
                                        ])}
                                    >
                                        <Icon
                                            icon={uploadIcon}
                                            className={cx([
                                                'ace-c-icon--xxl',
                                                'ace-c-icon--color-highlight',
                                            ])}
                                        />
                                    </HighlightCircle>
                                    <h3 className={cx('global!ace-u-typography--variant-h3')}>
                                        {translateModal('drop_zone_title.document_upload')}
                                    </h3>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-body',
                                            'global!ace-u-typography--color-medium-emphasis',
                                            'global!ace-u-typography--align-center',
                                        ])}
                                    >
                                        {translateModal('text.valid_formats')}
                                    </p>
                                    <FileAssetsInput
                                        service="efFileAssetsService"
                                        fileList="sc-documents"
                                        className={cx([
                                            'global!ace-u-margin--24-0',
                                            'ace-c-file-input--no-chrome',
                                        ])}
                                    >
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-margin--24-0',
                                                'global!ace-u-typography--variant-highlight',
                                                'global!ace-u-typography--color-highlighted',
                                                'global!ace-u-cursor--pointer',
                                            ])}
                                        >
                                            <Icon
                                                icon={plusIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                            {translateModal('label.search_documents')}
                                        </div>
                                    </FileAssetsInput>
                                </FileAssetsDropZone>
                                {fileList.errors.length > 0 && (
                                    <div className={cx('global!ace-u-margin--8-0')}>
                                        {fileList.errors.map((errorInfo, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-margin--4-0',
                                                        'global!ace-u-typography--variant-caption',
                                                        'global!ace-u-typography--color-warning',
                                                    ])}
                                                >
                                                    <Icon
                                                        icon={infoAlertIcon}
                                                        className={cx([
                                                            'global!ace-u-margin--right-4',
                                                            'ace-c-icon--s',
                                                            'ace-c-icon--color-warning',
                                                            'global!ace-u-flex--shrink-0',
                                                        ])}
                                                    />
                                                    {translate(`global.eua_file_assets_error_type.${snakeCase(errorInfo.errorType)}`, {
                                                        fileName: errorInfo.fileName,
                                                        fileType: errorInfo.fileType,
                                                        fileSizeLimit,
                                                    })}
                                                </div>
                                            );
                                        })}
                                        <Divider />
                                    </div>
                                )}
                                <SelectField
                                    name="documentType"
                                    label={translateModal('label.document_type')}
                                    value={documentType}
                                    className={cx('global!ace-u-margin--24-0')}
                                    onChange={setDocumentType}
                                >
                                    {Object.keys(efServiceCaseDocumentTypes).map(documentType => {
                                        const key = `${kebabCase(documentType)}-shift-role`;
                                        return (
                                            <Option
                                                key={key}
                                                name={key}
                                                value={documentType}
                                            >
                                                {translate(`global.service_case_document_type.${snakeCase(documentType)}`)}
                                            </Option>
                                        );
                                    })}
                                </SelectField>
                                {fileList.files.length > 0 && (
                                    <Fragment>
                                        <Divider />
                                        <TextAreaField
                                            name="description"
                                            value={description || ''}
                                            placeholder={translateModal('text_area_placeholder.email_from_assistance')}
                                            label={translateModal('text_area_label.description')}
                                            isResizable={false}
                                            maxLength={config.DOCUMENT_UPLOAD_DESCRIPTION_MAX_LENGTH}
                                            onChange={value => setDescription(value)}
                                            className={cx(['global!ace-u-full-width', 'global!ace-u-margin--24-0'])}
                                        />
                                        <Divider />
                                        <div className={cx('global!ace-u-margin--24-0')}>
                                            <p
                                                className={cx([
                                                    'global!ace-u-typography--variant-body-medium',
                                                    'global!ace-u-margin--bottom-8',
                                                ])}
                                            >
                                                {translateModal('label.uploaded_documents')}
                                            </p>
                                            {fileList.files.map(fileAsset => {
                                                return (
                                                    <div
                                                        key={fileAsset.id}
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                            'global!ace-u-flex--justify-space-between',
                                                            'global!ace-u-margin--8-0',
                                                        ])}
                                                    >
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--align-center',
                                                                'global!ace-u-typography--variant-body',
                                                                'global!ace-u-typography--color-highlighted',
                                                            ])}
                                                        >
                                                            <Icon
                                                                icon={fileAsset.isUploadPending
                                                                    ? timeIcon
                                                                    : attachmentIcon
                                                                }
                                                                className={cx([
                                                                    'global!ace-u-margin--right-8',
                                                                    'ace-c-icon--xs',
                                                                    'ace-c-icon--color-highlight',
                                                                ])}
                                                            />
                                                            {fileAsset.fileName}
                                                        </span>
                                                        <Icon
                                                            onClick={() => deleteFile(fileAsset)}
                                                            icon={deleteIcon}
                                                            className={cx([
                                                                'ace-c-icon--xs',
                                                                'ace-c-icon--color-medium-emphasis',
                                                            ])}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <Divider />
                <div
                    className={cx([
                        'global!ace-u-margin--top-24',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <Button
                        name="createNewVehicleRelatedCase"
                        onClick={handleOnSubmit}
                        isDisabled={isButtonDisabled}
                    >
                        <Icon
                            icon={vehicleIcon}
                            className={cx('global!ace-u-margin--right-8', {
                                'ace-c-icon--color-highlight': !isButtonDisabled,
                            })}
                        />
                        {translateModal('button_label.create_vehicle_related_case')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

CreateForeignAssistanceCaseModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    commissioners: PropTypes.object,
    declineCreateForeignAssistanceCase: PropTypes.func.isRequired,
    confirmCreateForeignAssistanceCase: PropTypes.func.isRequired,
};

CreateForeignAssistanceCaseModal.defaultProps = {
    hasBackdrop: true,
    commissioners: {},
};

const mapStateToProps = state => ({
    commissioners: serviceCaseSelectors.getCommissioners(state),
});

const mapDispatchToProps = dispatch => ({
    declineCreateForeignAssistanceCase: () => dispatch({
        type: serviceCaseActionTypes.DECLINE_CREATE_FOREIGN_ASSISTANCE_CASE,
    }),
    confirmCreateForeignAssistanceCase: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_CREATE_FOREIGN_ASSISTANCE_CASE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateForeignAssistanceCaseModal);
