import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sastActionTypes from '../sastActionTypes';

/**
 * Update SAST member location flow
 */
const updateSASTMemberLocationFlow = function* updateSASTMemberLocationFlow() {
    while (true) {
        const {payload} = yield take(sastActionTypes.SUBMIT_SAST_MEMBER_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sastActionTypes.SUBMIT_SAST_MEMBER_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.SHORT_TRIP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceAssignmentData.memberLocation});
    }
};

export default updateSASTMemberLocationFlow;
