import {take, select, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as sadehActionTypes from '../sadehActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';

/**
 * Update SADEH service location flow
 */
const updateSADEHServiceLocationFlow = function* updateSADEHServiceLocationFlow() {
    while (true) {
        const {payload} = yield take(sadehActionTypes.SUBMIT_SADEH_SERVICE_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, serviceCaseData, caseType} = payload;
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignment = serviceAssignments[serviceAssignmentId];

        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
                || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.SUBMIT_RELEVANT_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.DEATH,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceAssignmentData.serviceLocation});

        if (isInitialAssignment) {
            yield* updateServiceCase({
                caller: serviceAssignmentActionTypes.SUBMIT_RELEVANT_LOCATION_FORM,
                caseType: caseType,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.SUBMIT_RELEVANT_LOCATION_FORM},
        });
    }
};

export default updateSADEHServiceLocationFlow;
