/**
 * @typedef {string} tabId
 */

/**
 * SAV screen tabs
 *
 * @enum {tabId}
 */
export default {
    DAMAGE_AND_SERVICE: 'damage-and-service',
    VEHICLE: 'vehicle',
    DAMAGE_LOCATION: 'damage-location',
    TOWING_DESTINATION: 'towing-destination',
    CONTRACT_PARTNER: 'contract-partner',
    COMMISSIONING: 'commissioning',
};
