import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import updateServiceAssignment from './updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * Update SA Commissioning ace partner details flow
 */
const updateCommissioningACEPartnerDetailsFlow = function* updateCommissioningACEPartnerDetailsFlow() {
    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS);
        const {serviceAssignmentLineNo, assignmentType, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS,
            assignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: serviceAssignmentActionTypes.INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS},
        });
    }
};

export default updateCommissioningACEPartnerDetailsFlow;
