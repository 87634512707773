import {resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../routePaths';
import serviceCaseScreenTabs from './serviceCaseScreenTabs';

const createServiceCaseProtocolTabURL = serviceCaseId => {
    const {pathname, search} = resolveRoute(
        routePaths.SERVICE_CASE_SECTION,
        {serviceCaseId},
        {search: prepareSearchQueryParams({activeTab: serviceCaseScreenTabs.PROTOCOL})},
    );
    return pathname + search;
};

export default createServiceCaseProtocolTabURL;
