import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, Button} from '@ace-de/ui-components';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const ContractPartnerQualityManagementNote = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {initiateCreateCPQualityReportFlow} = props;

    const isQualityReportCreationDisabled = !ecsAccessControl.grantFeatureAccess(
        ecsFeatures.QUALITY_REPORT,
        ecsFeatureActions.CREATE,
    );

    const createQualityManagementNote = () => {
        if (isQualityReportCreationDisabled) return;
        initiateCreateCPQualityReportFlow();
    };

    return (
        <Panel title={translateTab('panel_title.quality_management_note')}>
            <Button
                className={cx('global!ace-u-full-width')}
                onClick={createQualityManagementNote}
                isDisabled={isQualityReportCreationDisabled}
            >
                {translateTab('quality_management_note_panel_button.create_quality_management_note')}
            </Button>
        </Panel>
    );
};


ContractPartnerQualityManagementNote.propTypes = {
    initiateCreateCPQualityReportFlow: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    initiateCreateCPQualityReportFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(ContractPartnerQualityManagementNote));
