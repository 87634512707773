import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as samsActionTypes from '../samsActionTypes';
import samsScreenTabs from '../samsScreenTabs';

const createServiceAssignmentMedicineShipmentFlow = function* createServiceAssignmentMedicineShipmentFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(samsActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT);
        const {serviceCaseId} = payload;

        yield fork(fetchRequest,
            samsActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST,
            ecsFlowService.createServiceAssignment, {
                serviceCaseId,
                assignmentType: efServiceAssignmentTypes.MEDICINE_SHIPMENT,
            });

        const responseAction = yield take([
            samsActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_SUCCEEDED,
            samsActionTypes.CREATE_SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT_REQUEST_FAILED,
        ]);

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE,
            payload: {isServiceCaseCreationInProgress: false},
        });

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceAssignmentDTO} = response;
            const serviceAssignmentLineNo = serviceAssignmentDTO.lineNo;
            const serviceAssignmentDTOs = [serviceAssignmentDTO];

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs, serviceCaseId},
            });

            yield put(push(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo,
                },
                {search: prepareSearchQueryParams({activeTab: samsScreenTabs.REQUIREMENTS})},
            )));
        }
    }
};

export default createServiceAssignmentMedicineShipmentFlow;
