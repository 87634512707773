import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import deletionStatuses from '../../service-cases/deletionStatuses';

const deleteServiceAssignmentFlow = function* deleteServiceAssignmentFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(serviceAssignmentActionTypes.INITIATE_DELETE_SERVICE_ASSIGNMENT_FLOW);
        const {serviceCaseId, serviceAssignmentLineNo} = payload;
        let requestStatus = deletionStatuses.SUCCEEDED;

        yield* openModal(modalIds.DELETE_SERVICE_ASSIGNMENT);

        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_DELETE_SERVICE_ASSIGNMENT_FLOW,
            serviceAssignmentActionTypes.DECLINE_DELETE_SERVICE_ASSIGNMENT_FLOW,
        ]);

        if (chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_DELETE_SERVICE_ASSIGNMENT_FLOW) {
            yield fork(fetchRequest,
                serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_REQUEST,
                ecsFlowService.deleteServiceAssignment,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo,
                });

            const serviceAssignmentDeletionResponseAction = yield take([
                serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_REQUEST_FAILED,
                serviceAssignmentActionTypes.DELETE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED,
            ]);

            if (serviceAssignmentDeletionResponseAction.error) {
                requestStatus = deletionStatuses.FAILED;
            }

            if (!serviceAssignmentDeletionResponseAction.error) {
                yield put({
                    type: serviceCaseActionTypes.REMOVE_DELETED_SERVICE_ASSIGNMENTS,
                    payload: {serviceCaseId, serviceAssignmentLineNo},
                });
            }

            yield* openModal(modalIds.SERVICE_ASSIGNMENT_DELETION_STATUS, {requestStatus});
        }

        yield* closeModal(modalIds.DELETE_SERVICE_ASSIGNMENT);
    }
};

export default deleteServiceAssignmentFlow;
