import React, {useCallback, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {camelCase, snakeCase} from 'change-case';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efOtherServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem, Panel} from '@ace-de/ui-components';
import {Form, InputPhoneNumberField, AutocompleteField, Option, TextAreaField} from '@ace-de/ui-components/form';
import {arrowDownIcon} from '@ace-de/ui-components/icons';
import * as saosActionTypes from './saosActionTypes';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import config from '../config';

const SAOSRequirementsTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('saos_requirements_tab');
    const {serviceAssignment, submitSAOSRequirementsForm, serviceCase, submitSAOSDamageLocationForm} = props;
    const [formData, setFormData] = useState({
        serviceType: serviceAssignment?.serviceType || '',
        callbackPhoneNo: serviceAssignment?.callbackPhoneNo || '',
        requirementsText: serviceAssignment?.requirementsText || '',
    });
    const [description, setDescription] = useState(serviceAssignment.description || '');
    const memberAppCaseServiceType = serviceCase.serviceType
        && Object.values(efOtherServiceTypes).includes(serviceCase.serviceType) ? serviceCase.serviceType : '';

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
        }));
        setDescription(serviceAssignment.description || '');
    }, [serviceAssignment.callbackPhoneNo, serviceAssignment.description]);

    const handleOnChange = formValues => {
        setFormData(formValues);
        if (formValues.serviceType !== serviceAssignment.serviceType) {
            const descriptionValue = (formValues.serviceType === efOtherServiceTypes.BIKE_SERVICE
                && serviceCase.prefix === `${config.ACE_COMMISSIONER_ID}`)
                ? translate(`global.default_assignment_text.${formValues.serviceType.toLowerCase()}`) : '';
            setDescription(descriptionValue);

            if (formValues.serviceType === '') {
                submitSAOSRequirementsForm({
                    serviceAssignmentLineNo: serviceAssignment.lineNo,
                    serviceCaseId: serviceAssignment.serviceCaseId,
                    serviceAssignmentData: {
                        serviceType: null,
                    },
                });
            }
        }
    };

    const handleOnOptionSelect = serviceType => {
        submitSAOSRequirementsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {serviceType},
        });
    };

    const handleOnBlur = () => {
        if (formData.serviceType === serviceAssignment.serviceType) return;
        setFormData({
            ...formData,
            serviceType: serviceAssignment.serviceType,
        });
    };

    const handleTabChange = useCallback(formValues => {
        const serviceAssignmentData = {
            ...formValues,
            serviceType: formValues.serviceType || null,
            description: description,
        };

        submitSAOSRequirementsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSAOSRequirementsForm,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        description,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: saosActionTypes.SUBMIT_SAOS_REQUIREMENTS_FORM,
            });
            return;
        }

        handleTabChange(formData);
    }, [formData, handleTabChange]);

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        submitSAOSDamageLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                damageLocation: {
                    ...locationInfo.selectedLocation,
                    locationNotes: locationInfo.locationNotes,
                },
            },
            serviceCaseData: {
                damage: {
                    location: {
                        ...locationInfo.selectedLocation,
                        locationNotes: locationInfo.locationNotes,
                    },
                },
            },
        });
    };

    return (
        <Form
            name="saosRequirementsFormData"
            onSubmit={handleTabChange}
            onChange={handleOnChange}
        >
            <ContentBlock>
                <ContentItem className={cx('ace-c-content-item--span-9')}>
                    <Panel title={translateTab('details_panel.title')}>
                        <div className={cx('global!ace-u-grid')}>
                            <div className={cx('global!ace-u-grid-column--span-9')}>
                                <AutocompleteField
                                    name="serviceType"
                                    className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-full-width')}
                                    value={formData?.serviceType || memberAppCaseServiceType}
                                    label={translateTab('input_label.service')}
                                    placeholder={translate('global.select.placeholder')}
                                    icon={arrowDownIcon}
                                    isDisabled={serviceAssignment.persistenceState === persistenceStates.PENDING}
                                    onOptionSelect={handleOnOptionSelect}
                                    onBlur={handleOnBlur}
                                >
                                    {Object.values(efOtherServiceTypes).filter(service => (
                                        ![
                                            efOtherServiceTypes.ELECTRICITY_SANITY_SERVICE,
                                            efOtherServiceTypes.REPLACEMENT_APARTMENT,
                                        ].includes(service)
                                        || moment(serviceCase.createdAt).isBefore(config.INTERSECTION_DATE)
                                    )).map(service => (
                                        <Option
                                            key={`service${camelCase(service)}`}
                                            name={`service${camelCase(service)}`}
                                            value={service}
                                        >
                                            {translate(`global.other_services_service_type.${snakeCase(efOtherServiceTypes[service])}`)}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                            </div>
                            <div className={cx('global!ace-u-grid-column--span-3')}>
                                <InputPhoneNumberField
                                    name="callbackPhoneNo"
                                    label={translateTab('input_label.callback_phone_no')}
                                    value={formData?.callbackPhoneNo || ''}
                                />
                            </div>
                        </div>
                    </Panel>
                    <Panel title={translateTab('requirements_panel.title')}>
                        <div className={cx('global!ace-u-grid')}>
                            <div className={cx('global!ace-u-grid-column--span-12')}>
                                <TextAreaField
                                    name="requirementsText"
                                    className={cx('global!ace-u-full-width')}
                                    value={formData?.requirementsText || ''}
                                    placeholder={translateTab('text_area_placeholder.enter_description')}
                                    label={translateTab('text_area_label.free_text_for_requirements')}
                                    isResizable={false}
                                    maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                />
                            </div>
                        </div>
                    </Panel>
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-3')}>
                    <RelevantLocationPanel
                        mapName="saos-damage-location-map"
                        handleLocationSaving={handleLocationSaving}
                        hasConfirmation={false}
                        hasLocationNotes={true}
                        hasPreviousLocations={false}
                        title={translateTab('damage_location_panel_title.damage_location')}
                    />
                </ContentItem>
            </ContentBlock>
        </Form>
    );
};

SAOSRequirementsTab.propTypes = {
    submitSAOSRequirementsForm: PropTypes.func.isRequired,
    submitSAOSDamageLocationForm: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
};

SAOSRequirementsTab.defaultProps = {
    serviceAssignment: null,
    serviceCase: {},
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAOSRequirementsForm: payload => dispatch({
        type: saosActionTypes.SUBMIT_SAOS_REQUIREMENTS_FORM,
        payload,
    }),
    submitSAOSDamageLocationForm: payload => dispatch({
        type: saosActionTypes.SUBMIT_SAOS_DAMAGE_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAOSRequirementsTab));
