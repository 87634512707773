import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, TableBody, Pill, Panel, NoResultsBlock} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, resubmissionIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import * as dashboardSelectors from '../dashboardSelectors';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import TableLoaderSpinner from '../../application/ui-elements/TableLoaderSpinner';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const SickPersonAndVehicleTransportPanel = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {sickPersonAndVehicleTransportAssignments, users, serviceCases, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;

    const openServiceCaseScreen = serviceAssignment => {
        history.push(resolveRoute(routePaths.SERVICE_CASE, {
            serviceCaseId: serviceAssignment.serviceCaseId,
        }, {
            state: {previousPath: history.location.pathname},
        }));
    };

    return (
        <Panel
            title={translateScreen('sick_person_and_vehicle_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="sick-person-and-vehicle-transport-info">
                {Object.values(sickPersonAndVehicleTransportAssignments).length === 0
                && !isInProgress
                && !error && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={resubmissionIcon}
                                />
                            )}
                            description={translateScreen('sick_person_and_vehicle_panel_no_results.description')}
                            message={translateScreen('sick_person_and_vehicle_panel_no_results.message')}
                        />
                    </TableCaption>
                )}
                {!!error && !isInProgress && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                {isInProgress && (
                    <TableLoaderSpinner />
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="sa-created-at">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.last_updated')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-status">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.status')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-id">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.case_id')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-member-personal-details-last-name">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.last_name')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-vehicle-licence-plate-number">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.license_plate')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-damage-location-address">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.damage_location')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-service-type">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.service')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-commissioner">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.commissioner')}
                        </TableCell>
                        <TableCell qaIdentPart="sa-created-by">
                            {translateScreen('sick_person_and_vehicle_panel_data_row.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isInProgress && Object.values(sickPersonAndVehicleTransportAssignments).map(serviceAssignment => {
                        const createdAt = serviceAssignment.createdAt
                            ? moment(serviceAssignment.createdAt).format('DD.MM.YYYY[,] HH:mm') : '';
                        const {serviceCase} = serviceAssignment;
                        const {damage} = serviceCase;
                        const vehicle = serviceCases[serviceCase.id]?.vehicle || null;

                        return (
                            <TableRow
                                key={`${serviceCase.id}-${serviceAssignment.lineNo}`}
                                onClick={() => openServiceCaseScreen(serviceAssignment)}
                                qaIdentPart={`${serviceCase.id}-${serviceAssignment.lineNo}`}
                            >
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="sa-created-at"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {createdAt}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sa-status"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    <Pill type="positive">
                                        {translate(`global.service_assignment_status_type.new`)}
                                    </Pill>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-id"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.id}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-member-personal-details-last-name"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.member.personalDetails.surname}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-vehicle-licence-plate-number"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {vehicle?.licensePlateNumber ? vehicle.licensePlateNumber : '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-damage-location-address"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {damage && damage.location
                                        ? `${damage.location.city} ${damage.location.postCode}` : '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sa-service-type"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceAssignment.assignmentType
                                        ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                                        : '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sc-commissioner"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {serviceCase.commissioner ? serviceCase.commissioner.name : ''}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="sa-created-by"
                                    qaIdentPartPostfix={serviceCase.id}
                                >
                                    {users[serviceAssignment.createdBy]
                                        ? `${users[serviceAssignment.createdBy].firstName} ${users[serviceAssignment.createdBy]?.lastName}`
                                        : ''}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};


SickPersonAndVehicleTransportPanel.propTypes = {
    sickPersonAndVehicleTransportAssignments: PropTypes.object,
    users: PropTypes.object,
    serviceCases: PropTypes.object,
    requestState: PropTypes.object,
};

SickPersonAndVehicleTransportPanel.defaultProps = {
    sickPersonAndVehicleTransportAssignments: {},
    users: {},
    serviceCases: {},
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors.createRequestStateSelector(
        serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST,
    );
    return {
        users: userProfileSelectors.getUsers(state),
        sickPersonAndVehicleTransportAssignments: dashboardSelectors.getSickPersonAndVehicleTransportAssignments(state),
        serviceCases: serviceCaseSelectors.getServiceCases(state),
        requestState: createRequestStateSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(SickPersonAndVehicleTransportPanel);
