import React, {Fragment} from 'react';
import {ecsDashboardLayoutTypes} from '../application/ecsLayoutTypes';
import MemberSearchPanel from './ui-elements/MemberSearchPanel';
import MemberAppPanel from './ui-elements/MemberAppPanel';
import AssignmentsPanel from './ui-elements/AssignmentPanel';
import MyTasksPanel from './ui-elements/MyTasksPanel';
import CurrentUserWorkloadPanel from './ui-elements/CurrentUserWorkloadPanel';
import ForeignAssistanceCasesPanel from './ui-elements/ForeignAssistanceCasesPanel';
import SickPersonAndVehicleTransportPanel from './ui-elements/SickPersonAndVehicleTransportPanel';
import config from '../config';

const dashboardLayouts = {
    [ecsDashboardLayoutTypes.ECS_DISPATCHER_1_DASHBOARD_LAYOUT]: (
        <Fragment>
            <CurrentUserWorkloadPanel />
            <MemberAppPanel />
            <AssignmentsPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_DISPATCHER_2_DASHBOARD_LAYOUT]: (
        <Fragment>
            <CurrentUserWorkloadPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_DISPATCHER_3_DASHBOARD_LAYOUT]: (
        <Fragment>
            <MemberSearchPanel />
            <CurrentUserWorkloadPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_DISPO_DASHBOARD_LAYOUT]: (
        <Fragment>
            <CurrentUserWorkloadPanel />
            <ForeignAssistanceCasesPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_TEAM_LEAD_DASHBOARD_LAYOUT]: (
        <Fragment>
            <CurrentUserWorkloadPanel />
            <SickPersonAndVehicleTransportPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_INBOX_DASHBOARD_LAYOUT]: (
        <Fragment>
            <MemberSearchPanel />
            <CurrentUserWorkloadPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_EMERGENCY_CALL_ADVISOR_DASHBOARD_LAYOUT]: (
        <Fragment>
            <MemberSearchPanel />
            <CurrentUserWorkloadPanel />
            {!config.IS_FALLBACK_SYSTEM && (
                <MyTasksPanel />
            )}
        </Fragment>
    ),
    [ecsDashboardLayoutTypes.ECS_DEFAULT_DASHBOARD_LAYOUT]: (
        <Fragment>
            <MemberSearchPanel />
            <CurrentUserWorkloadPanel />
        </Fragment>
    ),
};

export default dashboardLayouts;
