import React, {Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import URLParse from 'url-parse';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, useScrollableBlock, Divider, RadioButton} from '@ace-de/ui-components';

const AccommodationLocationItem = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('saa_accommodation_tab');
    const {accommodationLocation, isSelected} = props;
    const prevIsSelectedRef = useRef(false);
    const accommodationLocationItemRef = useRef();
    const {scrollToChildRef} = useScrollableBlock();

    // on isSelected, scroll ScrollableBlock to element ref
    useEffect(() => {
        if (isSelected === true && prevIsSelectedRef.current === false) {
            scrollToChildRef(accommodationLocationItemRef);
        }
        prevIsSelectedRef.current = isSelected;
    }, [isSelected, scrollToChildRef]);

    let websiteURL;
    if (typeof accommodationLocation.website === 'string') {
        const parsedURL = URLParse(accommodationLocation.website);
        websiteURL = `${parsedURL.hostname}${parsedURL.pathname}`;
    }

    return (
        <Fragment>
            <div
                ref={accommodationLocationItemRef}
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-margin--16-0',
                ])}
            >
                <RadioButton
                    id={`radio-button-${accommodationLocation.id}`}
                    name={`radio-button-${accommodationLocation.id}`}
                    value={accommodationLocation.id}
                />
                <label
                    htmlFor={`radio-button-${accommodationLocation.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-cursor--pointer',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <p className={cx('global!ace-u-margin--bottom-4')}>
                        {accommodationLocation.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {accommodationLocation.formattedAddress}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                        {translateTab('radio_button_label.phone_no')}
                        &nbsp;
                        {accommodationLocation.phoneNo || ''}
                    </p>
                    {websiteURL && accommodationLocation.website && (
                        <a
                            className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-typography--color-website-link')}
                            href={accommodationLocation.website}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {websiteURL || ''}
                        </a>
                    )}
                </label>
            </div>
            <Divider />
        </Fragment>
    );
};

AccommodationLocationItem.propTypes = {
    accommodationLocation: PropTypes.object,
    isSelected: PropTypes.bool,
};

AccommodationLocationItem.defaultProps = {
    accommodationLocation: {},
    isSelected: false,
};

export default AccommodationLocationItem;
