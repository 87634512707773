import {select, call} from 'redux-saga/effects';

const calculateSASDServiceProviderRouteDistance = function* calculateSASDServiceProviderRouteDistance(params) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {damage, destination, serviceProvider} = params;

    if (!destination?.latitude || !destination?.longitude) return;
    if (!damage?.location?.latitude || !damage?.location?.longitude) return;
    if (!serviceProvider?.location?.latitude || !serviceProvider?.location?.longitude) return;

    const response = yield call(
        arcGISRESTService.getMultipleStopsRoute,
        {
            stops: [
                [damage.location.longitude, damage.location.latitude],
                [destination.longitude, destination.latitude],
                [serviceProvider.location.longitude, serviceProvider.location.latitude],
            ],
        },
    );

    const {arcGISRouteDTO} = response;
    serviceProvider.location.routeToReferentialPoint = arcGISRouteDTO;
};

export default calculateSASDServiceProviderRouteDistance;
