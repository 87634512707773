import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as memberActionTypes from '../memberActionTypes';

/**
 * Search vehicles
 */
const searchVehicles = function* searchVehicles({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    const {searchQueryString, membershipNo} = payload;
    yield fork(fetchRequest, memberActionTypes.SEARCH_VEHICLES_REQUEST, membersService.searchVehicles, {
        searchQueryString,
        membershipNo,
    });

    const searchVehiclesResponseAction = yield take([
        memberActionTypes.SEARCH_VEHICLES_REQUEST_SUCCEEDED,
        memberActionTypes.SEARCH_VEHICLES_REQUEST_FAILED,
    ]);

    if (!searchVehiclesResponseAction.error) {
        const {response} = searchVehiclesResponseAction.payload;
        const {results: vehicleSearchResults} = response;

        yield put({
            type: memberActionTypes.STORE_VEHICLE_SEARCH_RESULTS,
            payload: {vehicleSearchResults},
        });
    }
};

export default searchVehicles;
