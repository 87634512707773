import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {Panel, useStyles, Button, Divider, NoResultsBlock, Paginator, LoaderSpinner} from '@ace-de/ui-components';
import {Icon, plusIcon, historyIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import LogEntry from './LogEntry';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import useServiceCaseLockedStatus from '../../service-cases/useServiceCaseLockedStatus';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';
import config from '../../config';
import routePaths from '../../routePaths';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';

const CaseProtocolPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_protocol_tab');
    const {serviceCase, initialCreateCaseLogNoteFlow, initiateSendingMessagesFlow, history} = props;
    const {caseLogFilterResults, caseLogFilterResultsCount, caseLogFilterResultsPage, requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const {isServiceCaseLocked} = useServiceCaseLockedStatus(serviceCase);

    const isNoteCreationAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.CASE_LOG_NOTE,
        ecsFeatureActions.CREATE,
    );
    const isMessageCreationAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.CASE_LOG_MESSAGE,
        ecsFeatureActions.CREATE,
    );

    const handleOnPaginationChange = page => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set('page', page);
        const queryParamsString = searchParams.toString();
        const {id: serviceCaseId} = serviceCase;
        history.replace(resolveRoute(routePaths.SERVICE_CASE_SECTION, {serviceCaseId}, {search: queryParamsString}));
    };

    return (
        <Fragment>
            <Panel
                className={cx('ace-c-panel--full-bleed-content')}
                title={translateTab('protocol_panel.title')}
                actions={(
                    <Fragment>
                        <Button
                            className={cx('global!ace-u-margin--right-24')}
                            onClick={() => initiateSendingMessagesFlow({})}
                            isDisabled={isServiceCaseLocked || !isMessageCreationAvailable}
                        >
                            {translateTab('protocol_panel_button_label.send_message')}
                        </Button>
                        <Button
                            onClick={initialCreateCaseLogNoteFlow}
                            isDisabled={isServiceCaseLocked || !isNoteCreationAvailable}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateTab('protocol_panel_button_label.add_note')}
                        </Button>
                    </Fragment>
                )}
            >
                <Divider />
                <div className={cx('global!ace-u-padding--16-24-32')}>
                    {!isInProgress && !error && (
                        caseLogFilterResults.length === 0
                            ? (
                                <NoResultsBlock
                                    icon={(
                                        <Icon
                                            className={cx('ace-c-icon--xxl')}
                                            icon={historyIcon}
                                        />
                                        )}
                                    message={translateTab('protocol_panel_no_results.message')}
                                    description={translateTab('protocol_panel_no_results.description')}
                                />
                            )
                            : caseLogFilterResults.map((caseLog, idx) => (
                                <LogEntry key={idx} caseLog={caseLog} />
                            ))
                    )}
                    {isInProgress && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-center',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <LoaderSpinner />
                        </div>
                    )}
                    {!isInProgress && !!error && (
                        <FailedRequestBlock translationKey={translationKey} />
                    )}
                </div>
            </Panel>
            <div>
                {!!caseLogFilterResultsCount && !isInProgress && (
                    <Paginator
                        page={caseLogFilterResultsPage}
                        count={Math.ceil(caseLogFilterResultsCount / config.PROTOCOL_DEFAULT_PAGE_SIZE)}
                        onClick={page => handleOnPaginationChange(page)}
                    />
                )}
            </div>
        </Fragment>
    );
};

CaseProtocolPanel.propTypes = {
    initialCreateCaseLogNoteFlow: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    initiateSendingMessagesFlow: PropTypes.func.isRequired,
    caseLogFilterResults: PropTypes.array,
    caseLogFilterResultsCount: PropTypes.number,
    caseLogFilterResultsPage: PropTypes.number,
    history: PropTypes.object.isRequired,
    requestState: PropTypes.object,
};

CaseProtocolPanel.defaultProps = {
    serviceCase: null,
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(caseLogActionTypes.FETCH_CASE_LOG_REQUEST);
    return {
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initialCreateCaseLogNoteFlow: payload => dispatch({
        type: caseLogActionTypes.INITIATE_CREATE_CASE_LOG_NOTE_FLOW,
        payload,
    }),
    initiateSendingMessagesFlow: () => dispatch({
        type: caseLogActionTypes.INITIATE_SENDING_MESSAGES_FLOW,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseProtocolPanel));

