import {put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import config from '../../config';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import routePaths from '../../routePaths';

const loadServiceCaseSearchResults = function* loadServiceCaseSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    const serviceCasesSearchScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_CASE_SEARCH,
        exact: true,
    });

    if (serviceCasesSearchScreenMatch.params.vehicleId) {
        yield put({
            type: serviceCaseActionTypes.SEARCH_SERVICE_CASES_BY_VEHICLE_ID,
            payload: {vehicleId: serviceCasesSearchScreenMatch.params.vehicleId},
        });
        return;
    }

    searchQueryParams.append('sort', 'emergencyCallDateTime,desc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    yield put({
        type: serviceCaseActionTypes.SEARCH_SERVICE_CASES,
        payload: {searchQueryParams},
    });
};

export default loadServiceCaseSearchResults;
