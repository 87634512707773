import {take, all, call, select} from 'redux-saga/effects';
import {efServiceAssignmentTypes, efDamageNodeTypes, efServiceCaseTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';
import createServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/createServiceAssignmentQMFeedbackDraft';
import deleteServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/deleteServiceAssignmentQMFeedbackDraft';
import isACEPartnerFromDACH from '../../service-assignments/isACEPartnerFromDACH';

const createDamageDescriptionString = (damageNodeSnapshots, damageNodes, translate, translateToEnglish) => {
    let snapshotIds = [...damageNodeSnapshots];
    const description = [];
    damageNodeSnapshots.forEach(nodeId => {
        if (!snapshotIds.includes(nodeId)) return;
        const node = damageNodes[nodeId];
        if (!node) return;
        if (node.nodeType === efDamageNodeTypes.ANSWER) {
            translateToEnglish ? description.push(translate(node.name, {}, 'en-US'))
                : description.push(translate(node.name));
            description.push('\n');
            return;
        }

        if (node.nodeType === efDamageNodeTypes.DECISION) {
            const childrenInSnapshot = node.childNodeIds.filter(childId => damageNodeSnapshots.includes(childId));
            if (childrenInSnapshot.length) {
                translateToEnglish ? description.push(translate(node.name, {}, 'en-US'), ' ')
                    : description.push(translate(node.name), ' ');
                childrenInSnapshot.forEach((childrenId, idx) => {
                    translateToEnglish ? description.push(translate(damageNodes[childrenId].name, {}, 'en-US'))
                        : description.push(translate(damageNodes[childrenId].name));
                    if (idx !== childrenInSnapshot.length - 1) {
                        description.push('***');
                    }
                    snapshotIds = snapshotIds.filter(nodeId => nodeId !== childrenId);
                });
                description.push('\n');
            }
        }
    });
    return description.toString().replaceAll(',', '').replaceAll('***', ', ');
};

/**
 * Select SAV contract partner flow
 */
const selectSAVContractPartnerFlow = function* selectSAVContractPartnerFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const i18nService = serviceManager.loadService('i18nService');
    const {translate} = i18nService;

    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_CONTRACT_PARTNER_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;
        const {acePartner: selectedContractPartner} = serviceAssignmentData;
        const {serviceAssignments} = yield select(state => state.serviceAssignments);
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
        const serviceAssignment = serviceAssignments[serviceAssignmentId];
        const {contractPartners} = yield select(state => state.contractPartners);
        if (!selectedContractPartner) continue;

        const previousPartners = serviceAssignment.listOfPreviousPartners || [];
        const firstAvailableCP = serviceAssignment && serviceAssignment.contractPartnerRecommendations
            .find(contractPartnerRecommendation => {
                return !previousPartners.includes(contractPartnerRecommendation.id);
            });

        /* eslint-disable max-len */
        const isVBAPartnerSelected = contractPartners && selectedContractPartner?.id
            && contractPartners[selectedContractPartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY;
        /* eslint-enable max-len */
        let deleteQMFeedbackDraft = !isVBAPartnerSelected && ((!serviceAssignment.recommendedContractPartnerId
                && selectedContractPartner.id === firstAvailableCP.id)
            || (serviceAssignment.recommendedContractPartnerId
                && selectedContractPartner.id === serviceAssignment.recommendedContractPartnerId));

        let qmFeedbackDraftData = null;
        const isVBAListWithoutRecommendedContractPartner = isVBAPartnerSelected
            && !serviceAssignment?.qmFeedbackDraft
            && !serviceAssignment?.recommendedContractPartnerId
            && serviceAssignment?.acePartner?.id;

        if ((serviceAssignment.recommendedContractPartnerId
            && !(previousPartners?.length && previousPartners.includes(serviceAssignment.recommendedContractPartnerId))
            && selectedContractPartner.id !== serviceAssignment.recommendedContractPartnerId
            // skip QM flow if VBA partners are in the list
            /* eslint-disable-next-line max-len */
            && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
            && (serviceAssignment.acePartner
                && serviceAssignment.acePartner.id === serviceAssignment.recommendedContractPartnerId)
        ) || (previousPartners.length > 0
            && firstAvailableCP.id !== selectedContractPartner.id
            // skip QM flow if VBA partners are in the list
            /* eslint-disable-next-line max-len */
            && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
            && !previousPartners.includes(serviceAssignment.recommendedContractPartnerId)
        ) || (
            // if there was a recommended active CP selected and the agent switches to the VBA list
            // if there is no recommended VBA partner in the list and agent selects other VBA partner
            // then we should open a modal and create a QM draft for the previously selected active CP
            // NOTE: if there was a non-recommended active CP selected, the condition will fail since
            // the QM draft is already created
            isVBAListWithoutRecommendedContractPartner)) {
            yield* openModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: serviceAssignment.acePartner.id});
            const qmNotesConfirmAction = yield take([
                savActionTypes.CONFIRM_SAV_QM_NOTES,
                savActionTypes.DECLINE_SAV_QM_NOTES,
            ]);

            if (qmNotesConfirmAction.type === savActionTypes.DECLINE_SAV_QM_NOTES) {
                yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
                continue;
            }
            if (qmNotesConfirmAction.type === savActionTypes.CONFIRM_SAV_QM_NOTES) {
                const {qmDraftData} = qmNotesConfirmAction.payload;
                deleteQMFeedbackDraft = !!serviceAssignment.qmFeedbackDraft;
                qmFeedbackDraftData = {
                    ...qmDraftData,
                    // if it's a VBA list without the recommended CP, we have to create a QM note
                    // with the list of active contract partners instead of using the VBA partner list
                    ...(!!isVBAListWithoutRecommendedContractPartner && {
                        /* eslint-disable-next-line max-len */
                        contractPartnerRecommendations: serviceAssignment?.activeContractPartnerRecommendationsSnapshot || [],
                    }),
                };
                yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
            }
        }

        const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle');
        const savContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sav-contract-partner-locations');
        const savContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sav-contract-partner-service-areas');
        const savContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sav-contract-partner-routes');

        if (savContractPartnerLocationsLayer
            && savContractPartnerServiceAreasLayer
            && savContractPartnerRoutesLayer) {
            yield all([
                call(savContractPartnerLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
                call(savContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
                call(savContractPartnerRoutesLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartner.id}'`,
                }),
            ]);
        }

        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId];
        const {damageNodeSnapshots} = serviceCase || {};
        if (damageNodeSnapshots.length > 0) {
            const isDACH = isACEPartnerFromDACH(serviceAssignmentData.acePartner);
            const damageNodes = yield select(state => state.serviceCases.serviceTypeDamageNodes);
            const description = createDamageDescriptionString(damageNodeSnapshots, damageNodes, translate, !isDACH);

            yield* updateServiceCase({
                caller: savActionTypes.SUBMIT_SAV_CONTRACT_PARTNER_FORM,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceCaseData: {
                    damage: {
                        description: description,
                    },
                },
            });
        }

        yield* updateServiceAssignment({
            caller: savActionTypes.SUBMIT_SAV_CONTRACT_PARTNER_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...selectedContractPartner,
                    ...(selectedContractPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...selectedContractPartner.businessContactDetails,
                                email: selectedContractPartner.assignmentEmail || '',
                                faxNo: selectedContractPartner.assignmentFaxNo || '',
                                phoneNo: selectedContractPartner.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                },
                assignmentText: null,
            },
        });

        if (deleteQMFeedbackDraft && serviceAssignment?.qmFeedbackDraft) {
            yield call(deleteServiceAssignmentQMFeedbackDraft, {
                serviceAssignmentLineNo,
                serviceCaseId,
                qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft?.id || null,
            });
        }
        if (qmFeedbackDraftData) {
            yield call(createServiceAssignmentQMFeedbackDraft, {
                qmFeedbackDraftData,
            });
        }
    }
};

export default selectSAVContractPartnerFlow;
