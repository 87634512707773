import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';

const loadServiceCaseAssignments = function* loadServiceCaseAssignments({serviceCaseId}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {serviceCases} = yield select(state => state.serviceCases);

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST,
        ecsFlowService.getServiceCaseAssignments,
        {serviceCaseId},
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {serviceAssignmentDTOs} = response;

    const damageLocation = serviceCases[serviceCaseId].damage?.location;

    const initialAssignment = serviceAssignmentDTOs.find(serviceAssignmentDTO => {
        return (serviceAssignmentDTO.lineNo === 'FBS1'
            || (parseInt(serviceAssignmentDTO.lineNo, 10) === 1 && !serviceAssignmentDTO.isCloned)
            || (parseInt(serviceAssignmentDTO.lineNo, 10) !== 1
                && !serviceAssignmentDTO.isCloned
                && serviceAssignmentDTO.isCloneFromInitial));
    });

    yield put({
        type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
        payload: {serviceAssignmentDTOs},
    });

    yield put({
        type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
        payload: {serviceAssignmentDTOs, serviceCaseId},
    });

    if (initialAssignment && !initialAssignment.serviceLocation && damageLocation) {
        yield* updateServiceAssignment({
            caller: serviceCaseActionTypes.FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST,
            assignmentType: initialAssignment.assignmentType,
            serviceAssignmentLineNo: initialAssignment.lineNo,
            serviceCaseId,
            serviceAssignmentData: {
                serviceLocation: damageLocation,
            },
        });
    }
};

export default loadServiceCaseAssignments;
