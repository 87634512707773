import {select, take, fork, put} from 'redux-saga/effects';
import {QualityManagementFeedback} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/*
* Service assignment: create QM feedback draft flow
*/
const createServiceAssignmentQMFeedbackDraft = function* createServiceAssignmentQMFeedbackDraft(params) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    const {qmFeedbackDraftData} = params;
    const qmFeedbackDraftDTO = qmFeedbackDraftData
        ? QualityManagementFeedback.objectToDTO({...qmFeedbackDraftData, isDraft: true})
        : null;

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST,
        partnerManagementService.createContractPartnerQualityReport, {
            qualityReportData: qmFeedbackDraftDTO,
        },
    );

    const responseAction = yield take([
        serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const serviceCaseId = qmFeedbackDraftDTO.caseId;
        const serviceAssignmentLineNo = qmFeedbackDraftDTO.lineNo;
        const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;

        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('isDraft', 'true');
        searchQueryParams.append('caseId', serviceCaseId);
        searchQueryParams.append('lineNo', serviceAssignmentLineNo);

        yield fork(
            fetchRequest,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST,
            partnerManagementService.getQualityManagementFeedbacks, {
                searchQueryParams,
            },
        );

        const getQMDraftResponseAction = yield take([
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED,
            serviceAssignmentActionTypes.FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED,
        ]);

        if (!getQMDraftResponseAction.error) {
            const {response} = getQMDraftResponseAction.payload;
            const {acePartnerQualityManagementFeedbackDTOs} = response;

            yield put({
                type: serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS,
                payload: {
                    qmFeedbackDraftDTOs: acePartnerQualityManagementFeedbackDTOs,
                    serviceAssignmentId,
                },
            });
        }
    }
};

export default createServiceAssignmentQMFeedbackDraft;
