import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as sasdActionTypes from '../sasdActionTypes';
import sasdScreenTabs from '../sasdScreenTabs';

const createServiceAssignmentSubstituteDriverFlow = function* createServiceAssignmentSubstituteDriverFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(sasdActionTypes.CREATE_SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER);
        const {serviceCaseId} = payload;

        yield fork(
            fetchRequest,
            sasdActionTypes.CREATE_SERVICE_CASE_SUBSTITUTE_DRIVER_ASSIGNMENT_REQUEST,
            ecsFlowService.createServiceAssignment,
            {
                serviceCaseId,
                assignmentType: efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
            },
        );

        const responseAction = yield take([
            sasdActionTypes.CREATE_SERVICE_CASE_SUBSTITUTE_DRIVER_ASSIGNMENT_REQUEST_SUCCEEDED,
            sasdActionTypes.CREATE_SERVICE_CASE_SUBSTITUTE_DRIVER_ASSIGNMENT_REQUEST_FAILED,
        ]);

        // NOTE: errors are handled implicitly as part of the fetchRequest
        // below you can handle something in addition if needed

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE,
            payload: {isServiceCaseCreationInProgress: false},
        });

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceAssignmentDTO} = response;
            const serviceAssignmentLineNo = serviceAssignmentDTO.lineNo;
            const serviceAssignmentDTOs = [serviceAssignmentDTO];

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs, serviceCaseId},
            });

            yield put(push(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo,
                },
                {search: prepareSearchQueryParams({activeTab: sasdScreenTabs.PROBLEM_DAMAGE})},
            )));
        }
    }
};
export default createServiceAssignmentSubstituteDriverFlow;
