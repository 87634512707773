/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE';
export const SELECT_DECISION_TREE_OPTIONS = '[service-cases] SELECT_DECISION_TREE_OPTIONS';
export const INITIATE_SAV_SERVICE_TYPE_SELECTION_FLOW = '[service-cases] INITIATE_SAV_SERVICE_TYPE_SELECTION_FLOW';
export const CONFIRM_SAV_SERVICE_TYPE_SELECTION = '[service-cases] CONFIRM_SAV_SERVICE_TYPE_SELECTION';
export const DECLINE_SAV_SERVICE_TYPE_SELECTION = '[service-cases] DECLINE_SAV_SERVICE_TYPE_SELECTION';
export const SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM = '[service-cases] SUBMIT_SAV_DAMAGE_SYMPTOMS_FORM';
export const SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM = '[service-cases] SUBMIT_SAV_DAMAGE_DESCRIPTION_FORM';
export const SUBMIT_SAV_TRAILER_FORM = '[service-cases] SUBMIT_SAV_TRAILER_FORM';
export const SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM = '[service-cases] SUBMIT_SAV_VEHICLE_DRIVER_OWNER_FORM';
export const SUBMIT_SAV_VEHICLE_DAMAGE_NOTES = '[service-cases] SUBMIT_SAV_VEHICLE_DAMAGE_NOTES';
export const SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION';
export const SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION';
export const SUBMIT_SAV_TOWING_DESTINATION_FORM = '[service-cases] SUBMIT_SAV_TOWING_DESTINATION_FORM';
export const START_SAV_MAP_DAMAGE_LOCATION_WATCHER = '[service-cases] START_SAV_MAP_DAMAGE_LOCATION_WATCHER';
export const STOP_SAV_MAP_DAMAGE_LOCATION_WATCHER = '[service-cases] STOP_SAV_MAP_DAMAGE_LOCATION_WATCHER';
export const SUBMIT_SAV_DAMAGE_LOCATION_FORM = '[service-cases] SUBMIT_SAV_DAMAGE_LOCATION_FORM';
export const SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM = '[service-cases] SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM';
export const SUBMIT_SAV_DAMAGE_LOCATION_INFO_CALLBACK_PHONE_NUMBER_FORM = '[service-cases] SUBMIT_SAV_DAMAGE_LOCATION_INFO_CALLBACK_PHONE_NUMBER_FORM';
export const SEARCH_SAV_TOWING_DESTINATIONS = '[service-cases] SEARCH_SAV_TOWING_DESTINATIONS';
export const SUBMIT_SAV_CONTRACT_PARTNER_FORM = '[service-cases] SUBMIT_SAV_CONTRACT_PARTNER_FORM';
export const SUBMIT_SAV_COMMISSIONING_FORM = '[service-cases] SUBMIT_SAV_COMMISSIONING_FORM';
export const START_SAV_MAP_TOWING_DESTINATION_WATCHER = '[service-cases] START_SAV_MAP_TOWING_DESTINATION_WATCHER';
export const STOP_SAV_MAP_TOWING_DESTINATION_WATCHER = '[service-cases] STOP_SAV_MAP_TOWING_DESTINATION_WATCHER';
export const START_SAV_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] START_SAV_MAP_CONTRACT_PARTNER_WATCHER';
export const STOP_SAV_MAP_CONTRACT_PARTNER_WATCHER = '[service-cases] STOP_SAV_MAP_CONTRACT_PARTNER_WATCHER';
export const SEARCH_SAV_CONTRACT_PARTNERS = '[service-cases] SEARCH_SAV_CONTRACT_PARTNERS';
export const CONFIRM_SAV_QM_NOTES = '[service-cases] CONFIRM_SAV_QM_NOTES';
export const DECLINE_SAV_QM_NOTES = '[service-cases] DECLINE_SAV_QM_NOTES';
export const CONFIRM_RESUBMITTING_COMMISSIONING_FORM = '[service-cases] CONFIRM_RESUBMITTING_COMMISSIONING_FORM';
export const DECLINE_RESUBMITTING_COMMISSIONING_FORM = '[service-cases] DECLINE_RESUBMITTING_COMMISSIONING_FORM';
export const SUBMIT_SAV_STATUS_CHANGE = '[service-cases] SUBMIT_SAV_STATUS_CHANGE';
export const INITIATE_SAV_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION = '[service-cases] INITIATE_SAV_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_VEHICLE_REQUEST_FAILED';

export const SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST = '[service-cases] SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST';
export const SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SENT = '[service-cases] SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SENT';
export const SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_SUCCEEDED';
export const SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_FAILED = '[service-cases] SEARCH_TOWING_DESTINATION_GOOGLE_PLACES_REQUEST_FAILED';

export const FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST = '[service-cases] FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST';
export const FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SENT = '[service-cases] FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SENT';
export const FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_SUCCEEDED';
export const FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_FAILED = '[service-cases] FETCH_TOWING_DESTINATION_GEOLOCATIONS_REQUEST_FAILED';

export const FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST = '[service-cases] FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST';
export const FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT = '[service-cases] FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SENT';
export const FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_TOWING_DESTINATION_ROUTE_REQUEST_FAILED';

export const FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST = '[service-cases] FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST';
export const FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SENT = '[service-cases] FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SENT';
export const FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED';
export const FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED = '[service-cases] FILTER_SAV_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED';

export const FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST';
export const FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAV_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST = '[service-cases] FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST';
export const FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SENT';
export const FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FILTER_SAV_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED';

export const FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST = '[service-cases] FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST';
export const FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT = '[service-cases] FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SENT';
export const FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED = '[service-cases] FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED';
export const FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED = '[service-cases] FILTER_SAV_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED';

export const FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST = '[service-cases] FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST';
export const FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_SENT = '[service-cases] FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_SENT';
export const FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED';
export const FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_FAILED = '[service-cases] FETCH_SAV_CP_TEMPORARY_RESTRICTIONS_REQUEST_FAILED';

export const FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST = '[service-cases] FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST';
export const FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_SENT = '[service-cases] FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_SENT';
export const FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_SUCCEEDED';
export const FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_FAILED = '[service-cases] FETCH_SAV_DAMAGE_LOCATION_ADDRESS_REQUEST_FAILED';

export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAV_TOWING_DESTINATION_RECOMMENDATIONS = '[service-cases] SET_SAV_TOWING_DESTINATION_RECOMMENDATIONS';
export const SET_SAV_CONTRACT_PARTNER_RECOMMENDATIONS = '[service-cases] SET_SAV_CONTRACT_PARTNER_RECOMMENDATIONS';
export const SET_SAV_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT = '[service-cases] SET_SAV_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT';
export const SET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID = '[service-cases] SET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID';
export const RESET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID = '[service-cases] RESET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID';
export const SET_SAV_CP_RECOMMENDATIONS_PERSISTENCE_STATE = '[service-cases] SET_SAV_CP_RECOMMENDATIONS_PERSISTENCE_STATE';
