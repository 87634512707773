import {take, call} from 'redux-saga/effects';
import {efServiceAssignmentStatusTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignmentStatus from '../../service-assignments/sagas/updateServiceAssignmentStatus';
import * as sarcActionTypes from '../sarcActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SARC cost coverage rental car data flow
 */
const updateSARCCostCoverageRentalCarDataFlow = function* updateSARCCostCoverageRentalCarDataFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM);
        const {serviceAssignmentLineNo, serviceCaseId, serviceAssignmentData} = payload;
        const {caseType, serviceCaseData, attachmentsToSendWithAssignment} = payload;


        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield* updateServiceCase({
            caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM,
            caseType,
            serviceCaseId,
            serviceCaseData,
        });

        yield* updateServiceAssignmentStatus({
            caller: sarcActionTypes.SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            },
        });
    }
};

export default updateSARCCostCoverageRentalCarDataFlow;
