import {all, fork, takeLatest} from 'redux-saga/effects';
import * as sarjActionTypes from './sarjActionTypes';
import createServiceAssignmentReturnJourneyFlow from './sagas/createServiceAssignmentReturnJourneyFlow';
import searchSARJDestinationGeolocation from './sagas/searchSARJDestinationGeolocation';
import updateSARJMemberLocationFlow from './sagas/updateSARJMemberLocationFlow';
import updateSARJDetailsDataFlow from './sagas/updateSARJDetailsDataFlow';
import updateSARJCostCoverageFlow from './sagas/updateSARJCostCoverageFlow';
import updateSARJServiceProviderFlow from './sagas/updateSARJServiceProviderFlow';

/**
 *  SARJ watcher saga
 */
const sarjWatcher = function* sarjWatcher() {
    yield all([
        fork(createServiceAssignmentReturnJourneyFlow),
        takeLatest(sarjActionTypes.SEARCH_SARJ_DESTINATION_GEOLOCATION, searchSARJDestinationGeolocation),
        fork(updateSARJMemberLocationFlow),
        fork(updateSARJDetailsDataFlow),
        fork(updateSARJCostCoverageFlow),
        fork(updateSARJServiceProviderFlow),
    ]);
};

export default sarjWatcher;
