import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, Panel, calendarIcon} from '@ace-de/ui-components';
import {Form, InputField, DateField} from '@ace-de/ui-components/form';
import ContactPersonDetailsPanel from '../service-assignments/ui-elements/ContactPersonDetailsPanel';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sadehActionTypes from './sadehActionTypes';
import mapMemberDataToContactPersonData from '../service-cases/mapMemberDataToContactPersonData';

const isObjectWithKeys = obj => (typeof obj === 'object' && !!Object.keys(obj).length);

const SADEHRequirementsTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sadeh_requirements_tab');
    const {serviceCase, serviceAssignment, submitSADEHRequirementsForm, saveSADEHServiceLocation} = props;
    const [formData, setFormData] = useState({
        // set initial form state in order to patch default values if an agent does not touch the form
        affectedPerson: serviceAssignment?.affectedPerson || mapMemberDataToContactPersonData(serviceCase.member),
    });

    const handleOnChange = formValues => {
        setFormData({
            ...formValues,
            ...(isObjectWithKeys(formValues['contactPersonOnSite']) && {
                contactPersonOnSite: {
                    ...(formValues['contactPersonOnSite']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['contactPersonOnSite']),
                    isIdenticalToMember: !!formValues['contactPersonOnSite']['isIdenticalToMember'],
                },
            }),
            ...(isObjectWithKeys(formValues['affectedPerson']) && {
                affectedPerson: {
                    ...(formValues['affectedPerson']['isIdenticalToMember']
                        ? mapMemberDataToContactPersonData(serviceCase.member)
                        : formValues['affectedPerson']),
                    isIdenticalToMember: !!formValues['affectedPerson']['isIdenticalToMember'],
                },
            }),
        });
    };

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
            || parseInt(serviceAssignment.lineNo, 10) === 1 || serviceAssignment.isCloneFromInitial;

        saveSADEHServiceLocation({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            caseType: serviceCase.caseType,
            serviceCaseData: {
                // if it's an initial assignment, update damage location
                ...(isInitialAssignment && {
                    damage: {
                        location: {
                            ...(locationInfo.selectedLocation || {}),
                            locationNotes: locationInfo.locationNotes || '',
                        },
                    },
                }),
            },
            serviceAssignmentData: {
                serviceLocation: {
                    ...(locationInfo.selectedLocation || {}),
                    locationNotes: locationInfo.locationNotes || '',
                },
            },
        });
    };

    const handleOnTabChange = useCallback(formValues => {
        const {contactPersonOnSite, affectedPerson, contactPersonAtHome, deathReasonAndTimeData} = formValues;
        submitSADEHRequirementsForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                ...(isObjectWithKeys(contactPersonOnSite) && {
                    contactPersonOnSite: {
                        ...contactPersonOnSite,
                        ...(!contactPersonOnSite.relationshipStatus && {relationshipStatus: null}),
                    },
                }),
                ...(isObjectWithKeys(affectedPerson)
                    ? {affectedPerson}
                    : (!serviceAssignment.affectedPerson
                        ? {affectedPerson: mapMemberDataToContactPersonData(serviceCase.member)}
                        : {})
                ),
                ...(isObjectWithKeys(contactPersonAtHome) && {
                    contactPersonAtHome: {
                        ...contactPersonAtHome,
                        ...(!contactPersonAtHome.relationshipStatus && {relationshipStatus: null}),
                    },
                }),
                ...(isObjectWithKeys(deathReasonAndTimeData) && {
                    ...deathReasonAndTimeData,
                    dateOfDeath: deathReasonAndTimeData?.dateOfDeath
                        && moment(deathReasonAndTimeData.dateOfDeath).isValid()
                        ? moment(deathReasonAndTimeData.dateOfDeath).format('YYYY-MM-DD') : '',
                }),
            },
        });
    }, [
        serviceCase.id,
        serviceCase.member,
        serviceAssignment.lineNo,
        serviceAssignment.affectedPerson,
        submitSADEHRequirementsForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!formData) {
            completeRouteUnmountSideEffect({
                caller: sadehActionTypes.SUBMIT_SADEH_REQUIREMENTS_FORM,
            });
            return;
        }

        handleOnTabChange(formData);
    }, [formData, handleOnTabChange]);

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="contactPersonsForm" onChange={handleOnChange}>
                    <ContactPersonDetailsPanel
                        title={translateTab('panel_title.local_contact')}
                        formName="contactPersonOnSite"
                        contactPerson={serviceAssignment.contactPersonOnSite}
                    />
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.affected')}
                            formName="affectedPerson"
                            isIdenticalToMember={true}
                            hasDate={true}
                            contactPerson={serviceAssignment.affectedPerson}
                        />
                    </div>
                    <div className={cx('global!ace-u-margin--top-24')}>
                        <ContactPersonDetailsPanel
                            title={translateTab('panel_title.contact_person_at_home')}
                            formName="contactPersonAtHome"
                            contactPerson={serviceAssignment.contactPersonAtHome}
                            hasToggle={false}
                        />
                    </div>
                    <Panel title={translateTab('panel_title.reason_and_time_of_death')}>
                        <Form name="deathReasonAndTimeData">
                            <div className={cx('global!ace-u-grid')}>
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-6')}
                                    name="reasonOfDeath"
                                    value={serviceAssignment.reasonOfDeath}
                                    label={translateTab('input_label.reason_of_death')}
                                />
                                <DateField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="dateOfDeath"
                                    label={translateTab('date_field_label.date_of_death')}
                                    value={serviceAssignment.dateOfDeath}
                                    icon={calendarIcon}
                                    locale={activeLocale}
                                />
                                <InputField
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    name="insurance"
                                    value={serviceAssignment.insurance}
                                    label={translateTab('input_label.insurance')}
                                />
                            </div>
                        </Form>
                    </Panel>
                </Form>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    hasPreviousLocations={false}
                    mapName="sadeh-member-location"
                    handleLocationSaving={handleLocationSaving}
                    hasLocationNotes={true}
                    hasConfirmation={false}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SADEHRequirementsTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    submitSADEHRequirementsForm: PropTypes.func.isRequired,
    saveSADEHServiceLocation: PropTypes.func.isRequired,
};

SADEHRequirementsTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSADEHRequirementsForm: payload => dispatch({
        type: sadehActionTypes.SUBMIT_SADEH_REQUIREMENTS_FORM,
        payload,
    }),
    saveSADEHServiceLocation: payload => dispatch({
        type: sadehActionTypes.SUBMIT_SADEH_SERVICE_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SADEHRequirementsTab));
