import React from 'react';
import {useStyles, NotificationBar} from '@ace-de/ui-components';
import {Icon, infoIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import config from '../../config';

const FallbackSystemNotificationBar = () => {
    const {cx} = useStyles();
    const {translate} = useTranslate();

    return config.IS_FALLBACK_SYSTEM ? (
        <NotificationBar type="information">
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-margin--16-0',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-typography--variant-body-medium',
                ])}
            >
                <Icon
                    icon={infoIcon}
                    className={cx('global!ace-u-margin--right-8')}
                />
                {translate('global.info.fallback_system_active')}
            </div>
        </NotificationBar>
    ) : null;
};

export default FallbackSystemNotificationBar;
