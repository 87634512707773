import {put, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import samaScreenTabs from '../samaScreenTabs';
import * as samaActionTypes from '../samaActionTypes';

const loadSAMAServiceProviders = function* loadSAMAServiceProviders({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();

    if (activeTab !== samaScreenTabs.SERVICE_PROVIDER) return;

    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];

    if (!serviceAssignment) return;

    yield put({
        type: samaActionTypes.SEARCH_SAMA_SERVICE_PROVIDERS,
        payload: {serviceAssignment},
    });
};

export default loadSAMAServiceProviders;
