import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';

const loadSAMAMapLayers = function* loadSAMAMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-medical-advice');

    if (!arcGISMap.isLayerSet('sama-doctors-layer')) {
        arcGISMap.createLayer({
            layerId: 'sama-doctors-layer',
            featureEntityType: euaFeatureEntityTypes.DOCTOR_LOCATION,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.DOCTOR_LOCATION,
            isVisible: false,
        });
    }
    if (!arcGISMap.isLayerSet('sama-service-providers-layer')) {
        arcGISMap.createLayer({
            layerId: 'sama-service-providers-layer',
            featureEntityType: euaFeatureEntityTypes.DOCTOR_LOCATION,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.DOCTOR_LOCATION,
            isVisible: false,
        });
    }
};

export default loadSAMAMapLayers;
