import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import savaScreenTabs from '../savaScreenTabs';
import * as savaActionTypes from '../savaActionTypes';
import onSAVAMapClickSelectContractPartner from './onSAVAMapClickSelectContractPartner';

const savaMapContractPartnerWatcher = function* savaMapContractPartnerWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(savaActionTypes.START_SAVA_MAP_CONTRACT_PARTNER_WATCHER);

        const onSAVMapClickSelectContractPartnerTask = yield fork(onSAVAMapClickSelectContractPartner, payload);

        while (true) {
            const watcherTerminationAction = yield take([
                savaActionTypes.STOP_SAVA_MAP_CONTRACT_PARTNER_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === savaActionTypes.STOP_SAVA_MAP_CONTRACT_PARTNER_WATCHER) {
                yield cancel(onSAVMapClickSelectContractPartnerTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                exact: true,
            });
            const {activeTab} = savRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savRouteMatch || activeTab !== savaScreenTabs.CONTRACT_PARTNER) {
                yield cancel(onSAVMapClickSelectContractPartnerTask);
                continue mainFlow;
            }
        }
    }
};

export default savaMapContractPartnerWatcher;
