import {all, fork, takeLatest} from 'redux-saga/effects';
import * as sastActionTypes from './sastActionTypes';
import createServiceAssignmentShortTripFlow from './sagas/createServiceAssignmentShortTripFlow';
import updateSASTBudgetFlow from './sagas/updateSASTBudgetFlow';
import updateSASTCostCoverageFlow from './sagas/updateSASTCostCoverageFlow';
import updateSASTOverviewDataFlow from './sagas/updateSASTOverviewDataFlow';
import searchSASTDestinationGeolocation from './sagas/searchSASTDestinationGeolocation';
import updateSASTMemberLocationFlow from './sagas/updateSASTMemberLocationFlow';
import updateSASTServiceProviderFlow from './sagas/updateSASTServiceProviderFlow';
import calculateMemberLocationToDestinationDistance from './sagas/calculateMemberLocationToDestinationDistance';

/**
 *  SAST watcher saga
 */
const sastWatcher = function* sastWatcher() {
    yield all([
        fork(createServiceAssignmentShortTripFlow),
        fork(updateSASTOverviewDataFlow),
        takeLatest(sastActionTypes.SEARCH_SAST_DESTINATION_GEOLOCATION, searchSASTDestinationGeolocation),
        fork(updateSASTMemberLocationFlow),
        fork(updateSASTCostCoverageFlow),
        fork(updateSASTBudgetFlow),
        fork(updateSASTServiceProviderFlow),
        fork(calculateMemberLocationToDestinationDistance),
    ]);
};

export default sastWatcher;
