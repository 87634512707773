import {put, select, take, fork} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadContractPartner = function* loadContractPartner({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {activeTab} = yield* selectSearchQueryParams();
    const {match, location} = payload;
    const {contractPartnerId} = match.params;

    // on the temporary restriction tab, do not reload contract partner if TR modal is open/closed
    if (activeTab === contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS
        && (location.query?.modal || location.state?.isModalClosed)
    ) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.getACEPartner,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {acePartnerDTO: contractPartnerDTO} = response;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
        payload: {contractPartnerDTOs: [contractPartnerDTO]},
    });
};

export default loadContractPartner;
