import React, {Fragment, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {ArcGISMapWidget} from '@ace-de/eua-arcgis-map';
import {useStyles, ButtonGhost, LockBackdrop} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar, NavigationBar, NotificationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import {Icon, caseProtocolIcon, infoIcon, closedIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import ScreenTabControls from '../service-cases/ui-elements/ScreenTabControls';
import ServiceAssignmentHeader from '../service-assignments/ui-elements/ServiceAssignmentHeader';
import FallbackSystemNotificationBar from '../application/ui-elements/FallbackSystemNotificationBar';
import sarcScreenTabs from './sarcScreenTabs';
import SARCRequirementsTab from './SARCRequirementsTab';
import SARCSelectStationTab from './SARCSelectStationTab';
import SARCCostCoverageTab from './SARCCostCoverageTab';
import useServiceCaseLockedStatus from '../service-cases/useServiceCaseLockedStatus';
import createServiceCaseProtocolTabURL from '../service-cases/createServiceCaseProtocolTabURL';
import useBeforeUnloadEffect from '../service-cases/useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';

const ServiceAssignmentRentalCarScreen = ({serviceCase, serviceAssignment}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('service_assignment_rental_car_screen');
    const {activeTab} = useSearchQueryParams();
    const {isServiceCaseLocked, lockedBy} = useServiceCaseLockedStatus(serviceCase);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const topElementRef = useRef();

    useEffect(() => {
        if (!topElementRef.current) return;
        topElementRef.current.scrollIntoView();
    }, [topElementRef]);

    useBeforeUnloadEffect(event => {
        return {
            unlockRequest: ecsFlowService.unlockServiceCase,
            requestParams: {serviceCaseId: serviceCase.id},
        };
    });

    // if no case or member don't render
    if (!serviceAssignment) return null;

    const tabsWithMap = [sarcScreenTabs.SELECT_STATION];
    const isServiceCaseEditAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_CASE,
        ecsFeatureActions.UPDATE,
    );

    return (
        <Fragment>
            <div ref={topElementRef} />
            <FallbackSystemNotificationBar />
            <HeaderBar>
                <ServiceAssignmentHeader serviceCase={serviceCase} serviceAssignment={serviceAssignment} />
            </HeaderBar>
            <TabSet
                name="sarc-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="sarc-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="sarc-screen-tabs"
                            tabSet="sarc-screen-tab-set"
                            defaultValue={activeTab || sarcScreenTabs.REQUIREMENTS}
                            isDisabled={serviceAssignment.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={sarcScreenTabs.REQUIREMENTS}
                                value={sarcScreenTabs.REQUIREMENTS}
                            >
                                {translateScreen('tab_label.requirement')}
                            </Tab>
                            <Tab
                                name={sarcScreenTabs.SELECT_STATION}
                                value={sarcScreenTabs.SELECT_STATION}
                                isDisabled={!(serviceAssignment.memberLocation || serviceAssignment.pickupLocation)}
                            >
                                {translateScreen('tab_label.select_station')}
                            </Tab>
                            <Tab
                                name={sarcScreenTabs.COST_COVERAGE}
                                value={sarcScreenTabs.COST_COVERAGE}
                            >
                                {translateScreen('tab_label.cost_coverage')}
                            </Tab>
                        </TabBar>
                        <ButtonGhost
                            className={cx([
                                'global!ace-u-flex--align-self-stretch',
                                'global!ace-u-margin--right-32',
                            ])}
                            onClick={() => window.open(createServiceCaseProtocolTabURL(serviceCase.id), '_blank')}
                        >
                            <Icon
                                icon={caseProtocolIcon}
                                className={cx('global!ace-u-margin--right-8')}
                            />
                            {translateScreen('button_label.case_protocol')}
                        </ButtonGhost>
                    </div>
                </NavigationBar>
                <div>
                    {isServiceCaseLocked && (
                        <NotificationBar type="information">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                ])}
                            >
                                <Icon
                                    icon={closedIcon}
                                    className={cx('global!ace-u-margin--right-8')}
                                />
                                {translate('global.case_lock_check.case_lock_notification', {
                                    userName: lockedBy,
                                })}
                            </div>
                        </NotificationBar>
                    )}
                    {!serviceAssignment.entitlementCheck && !tabsWithMap.includes(activeTab) && (
                        <NotificationBar type="negative">
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-margin--16-0',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-typography--variant-body-medium',
                                    'global!ace-u-typography--color-contrast',
                                ])}
                            >
                                <Icon
                                    icon={infoIcon}
                                    className={cx([
                                        'ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                <div>
                                    {Array.isArray(serviceAssignment.entitlementResponses)
                                        && serviceAssignment.entitlementResponses.map((response, idx) => {
                                            return (
                                                <Fragment key={`${response.entitlementError}-${idx}`}>
                                                    <span>{translate(response.message)}</span>
                                                    {idx < serviceAssignment.entitlementResponses.length - 1 && (
                                                        <br />
                                                    )}
                                                </Fragment>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </NotificationBar>
                    )}
                </div>
                <AppLayoutMain
                    contentClassName={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-stretch',
                    ])}
                >
                    {(isServiceCaseLocked || !isServiceCaseEditAvailable) && (
                        <LockBackdrop />
                    )}
                    <ArcGISMapWidget
                        name="service-assignment-rental-car"
                        tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                        className={cx({
                            'ace-c-arcgis-map-widget--is-visible': tabsWithMap.includes(activeTab),
                        })}
                    />
                    <TabPanel for={sarcScreenTabs.REQUIREMENTS}>
                        <SARCRequirementsTab />
                    </TabPanel>
                    <TabPanel for={sarcScreenTabs.SELECT_STATION}>
                        <SARCSelectStationTab />
                    </TabPanel>
                    <TabPanel for={sarcScreenTabs.COST_COVERAGE}>
                        <SARCCostCoverageTab />
                    </TabPanel>

                    <ScreenTabControls activeTab={activeTab} screenTabs={sarcScreenTabs} />
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ServiceAssignmentRentalCarScreen.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
};

ServiceAssignmentRentalCarScreen.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(ServiceAssignmentRentalCarScreen);
