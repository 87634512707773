import React from 'react';
import PropTypes from 'prop-types';
import {useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {connect} from 'react-redux';
import {useStyles, Modal, ButtonSecondary, HighlightCircle, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, closeIcon, acceptedIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

const PowerOfAttorneySuccessfulModal = ({hasBackdrop, initiateTaskCreationFlow}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const {closeModal} = useRouter();
    const translateModal = createTranslateShorthand('power_of_attorney_successful_modal');

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={() => closeModal(modalIds.POWER_OF_ATTORNEY_SUCCESSFUL)}
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--success',
                    ])}
                >
                    <Icon
                        icon={acceptedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-success',
                        ])}
                    />
                </HighlightCircle>
                <h3>{translateModal('headline_text.power_of_attorney_sent_successfully')}</h3>
                <div
                    className={cx([
                        'global!ace-u-margin--top-32',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <ButtonPrimary
                        name="createTaskButton"
                        onClick={initiateTaskCreationFlow}
                    >
                        {translateModal('button.create_task')}
                    </ButtonPrimary>
                    <ButtonSecondary
                        name="closeModalButton"
                        className={cx('global!ace-u-margin--left-16')}
                        onClick={() => closeModal(modalIds.POWER_OF_ATTORNEY_SUCCESSFUL)}
                    >
                        {translateModal('button.close')}
                    </ButtonSecondary>
                </div>
            </div>
        </Modal>
    );
};

PowerOfAttorneySuccessfulModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    initiateTaskCreationFlow: PropTypes.func.isRequired,
};

PowerOfAttorneySuccessfulModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    initiateTaskCreationFlow: () => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_POWER_OF_ATTORNEY_TASK_CREATION_FLOW,
    }),
});

export default connect(null, mapDispatchToProps)(PowerOfAttorneySuccessfulModal);
