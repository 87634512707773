import {eventChannel} from 'redux-saga';
import {select, take, all, call} from 'redux-saga/effects';
import {efServiceAssignmentTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sapActionTypes from '../sapActionTypes';
import createServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/createServiceAssignmentQMFeedbackDraft';
import deleteServiceAssignmentQMFeedbackDraft from '../../service-assignments/sagas/deleteServiceAssignmentQMFeedbackDraft';

const onSAPMapClickSelectContractPartner = function* onSAPMapClickSelectContractPartner(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-pickup');
    const sapContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-locations');
    const sapContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-service-areas');
    const sapContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-routes');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        if (sapContractPartnerLocationsLayer) {
            const unsubscribeClickListener = sapContractPartnerLocationsLayer.on('feature-click', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {featureDTO: contractPartnerFeatureDTO} = yield take(locationsLayerClickEventChannel);

        if (contractPartnerFeatureDTO && !contractPartnerFeatureDTO.isSelected) {
            const selectedContractPartnerId = contractPartnerFeatureDTO['contractPartnerId'];

            const {contractPartners} = yield select(state => state.contractPartners);
            const {serviceAssignments} = yield select(state => state.serviceAssignments);
            const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
            const selectedContractPartner = serviceAssignment
                && serviceAssignment.contractPartnerRecommendations
                    .find(contractPartner => contractPartner.id === selectedContractPartnerId);
            if (!selectedContractPartner) continue;

            const previousPartners = serviceAssignment.listOfPreviousPartners || [];
            const firstAvailableCP = serviceAssignment && serviceAssignment.contractPartnerRecommendations
                .find(contractPartnerRecommendation => {
                    return !previousPartners.includes(contractPartnerRecommendation.id);
                });

            /* eslint-disable max-len */
            const isVBAPartnerSelected = contractPartners && selectedContractPartner?.id
                && contractPartners[selectedContractPartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY;
            /* eslint-enable max-len */
            let deleteQMFeedbackDraft = !isVBAPartnerSelected && ((!serviceAssignment.recommendedContractPartnerId
                    && selectedContractPartner.id === firstAvailableCP.id)
                || (serviceAssignment.recommendedContractPartnerId
                    && selectedContractPartner.id === serviceAssignment.recommendedContractPartnerId));
            let qmFeedbackDraftData = null;
            const isVBAListWithoutRecommendedContractPartner = isVBAPartnerSelected
                && !serviceAssignment?.qmFeedbackDraft
                && !serviceAssignment?.recommendedContractPartnerId
                && serviceAssignment?.acePartner?.id;

            if ((serviceAssignment.recommendedContractPartnerId
                    && selectedContractPartner.id !== serviceAssignment.recommendedContractPartnerId
                    // skip QM flow if VBA partners are in the list
                    /* eslint-disable-next-line max-len */
                    && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
                    && (serviceAssignment.acePartner
                        && serviceAssignment.acePartner.id === serviceAssignment.recommendedContractPartnerId)
            ) || (previousPartners.length > 0
                && firstAvailableCP.id !== selectedContractPartner.id
                // skip QM flow if VBA partners are in the list
                /* eslint-disable-next-line max-len */
                && (contractPartners || {})[selectedContractPartner.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY
                && !previousPartners.includes(serviceAssignment.recommendedContractPartnerId)
            ) || (
                // if there was a recommended active CP selected and the agent switches to the VBA list
                // if there is no recommended VBA partner in the list and agent selects other VBA partner
                // then we should open a modal and create a QM draft for the previously selected active CP
                // NOTE: if there was a non-recommended active CP selected, the condition will fail since
                // the QM draft is already created
                isVBAListWithoutRecommendedContractPartner)
            ) {
                yield* openModal(
                    modalIds.SERVICE_ASSIGNMENT_QM_NOTES,
                    {recommendedCPId: serviceAssignment.acePartner.id},
                );
                const qmNotesConfirmAction = yield take([
                    sapActionTypes.CONFIRM_SAP_QM_NOTES,
                    sapActionTypes.DECLINE_SAP_QM_NOTES,
                ]);

                if (qmNotesConfirmAction.type === sapActionTypes.DECLINE_SAP_QM_NOTES) {
                    yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
                    continue;
                }
                if (qmNotesConfirmAction.type === sapActionTypes.CONFIRM_SAP_QM_NOTES) {
                    const {qmDraftData} = qmNotesConfirmAction.payload;
                    deleteQMFeedbackDraft = !!serviceAssignment?.qmFeedbackDraft;
                    qmFeedbackDraftData = {
                        ...qmDraftData,
                        // if it's a VBA list without the recommended CP, we have to create a QM note
                        // with the list of active contract partners instead of using the VBA partner list
                        ...(!!isVBAListWithoutRecommendedContractPartner && {
                            /* eslint-disable-next-line max-len */
                            contractPartnerRecommendations: serviceAssignment?.activeContractPartnerRecommendationsSnapshot || [],
                        }),
                    };
                    yield* closeModal(modalIds.SERVICE_ASSIGNMENT_QM_NOTES, {recommendedCPId: ''});
                }
            }

            yield all([
                call(sapContractPartnerLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerId}'`,
                }),
                call(sapContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerId}'`,
                }),
                call(sapContractPartnerRoutesLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerId}'`,
                }),
            ]);

            const selectedACEPartner = contractPartners[selectedContractPartnerId] || null;

            yield* updateServiceAssignment({
                caller: 'ON_SAP_MAP_CLICK_SELECT_CONTRACT_PARTNER',
                assignmentType: efServiceAssignmentTypes.PICKUP,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    acePartner: {
                        ...selectedContractPartner,
                        ...(selectedContractPartner.businessContactDetails
                            ? {
                                businessContactDetails: {
                                    ...selectedContractPartner.businessContactDetails,
                                    email: selectedACEPartner?.assignmentEmail || '',
                                    faxNo: selectedACEPartner?.assignmentFaxNo || '',
                                    phoneNo: selectedACEPartner?.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                                },
                                contactDetails: null,
                            } : {}
                        ),
                    },
                    assignmentText: null,
                    tariffGroup: '',
                    routeDistance: 0,
                },
            });

            if (deleteQMFeedbackDraft && serviceAssignment?.qmFeedbackDraft) {
                yield call(deleteServiceAssignmentQMFeedbackDraft, {
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    qmFeedbackDraftId: serviceAssignment.qmFeedbackDraft?.id || null,
                });
            }
            if (qmFeedbackDraftData) {
                yield call(createServiceAssignmentQMFeedbackDraft, {
                    qmFeedbackDraftData,
                });
            }
        }
    }
};

export default onSAPMapClickSelectContractPartner;
