/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE = '[service-cases] CREATE_SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE';
export const SUBMIT_SAMC_COMMISSIONING_FORM = '[service-cases] SUBMIT_SAMC_COMMISSIONING_FORM';
export const SUBMIT_SAMC_DAMAGE_LOCATION_INFO_FORM = '[service-cases] SUBMIT_SAMC_DAMAGE_LOCATION_INFO_FORM';
export const SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION';
export const SUBMIT_SAMC_DAMAGE_LOCATION_FORM = '[service-cases] SUBMIT_SAMC_DAMAGE_LOCATION_FORM';
export const START_SAMC_MAP_DAMAGE_LOCATION_WATCHER = '[service-cases] START_SAMC_MAP_DAMAGE_LOCATION_WATCHER';
export const STOP_SAMC_MAP_DAMAGE_LOCATION_WATCHER = '[service-cases] STOP_SAMC_MAP_DAMAGE_LOCATION_WATCHER';
export const SUBMIT_SAMC_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SAMC_SERVICE_PROVIDER_FORM';
export const SUBMIT_SAMC_PROBLEM_DAMAGE_FORM = '[service-cases] SUBMIT_SAMC_PROBLEM_DAMAGE_FORM';
export const SUBMIT_SAMC_JOURNEY_DETAILS_FORM = '[service-cases] SUBMIT_SAMC_JOURNEY_DETAILS_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_MEDICAL_CLEARANCE_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SAMC_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED';

export const FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST = '[service-cases] FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST';
export const FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_SENT = '[service-cases] FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_SENT';
export const FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_SUCCEEDED';
export const FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_FAILED = '[service-cases] FETCH_SAMC_DAMAGE_LOCATION_ADDRESS_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SAMC_DAMAGE_LOCATION_CANDIDATES = '[service-cases] SET_SAMC_DAMAGE_LOCATION_CANDIDATES';
export const SET_SAMC_REVERSE_GEOCODING_DAMAGE_LOCATION = '[service-cases] SET_SAMC_REVERSE_GEOCODING_DAMAGE_LOCATION';
