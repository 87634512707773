import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel} from '@ace-de/ui-components';
import {RadioTile, RadioButtonGroup} from '@ace-de/ui-components/selector-inputs';
import {infoIcon} from '@ace-de/ui-components/icons';
import {decisionTreeIcons} from './decisionTreeIcons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as savActionTypes from '../savActionTypes';

const DamageCategoryPanel = props => {
    const {cx} = useStyles();
    const {damageCategories, selectDecisionTreeOptions, damageNodes} = props;
    const {serviceCase, serviceAssignment} = props;
    const {translate} = useTranslate();
    const [selectedCategory] = useState(serviceCase.damageNodeSnapshots[0] || '');

    const firstNodeId = Object.keys(damageNodes).find(node => {
        return damageNodes[node].parentNodeId.length === 1 && damageNodes[node].parentNodeId[0] === null;
    });

    const selectCategoryIcon = category => {
        const categoryId = category.split('.').pop().toUpperCase();
        return decisionTreeIcons[categoryId] || infoIcon;
    };

    const handleDamageCategoryChange = category => {
        selectDecisionTreeOptions({
            serviceCase,
            serviceAssignment,
            parentId: firstNodeId,
            selectedOptions: [category],
            damageType: damageNodes[category].name.split('.').pop().toUpperCase(),
        });
    };

    return (
        <Panel title={translate('damage_category.panel_title.damage_category')}>
            <RadioButtonGroup
                name="damageCategoryGroup"
                onChange={handleDamageCategoryChange}
                value={selectedCategory}
            >
                <div className={cx('global!ace-u-grid')}>
                    {damageCategories.map((damageCategoryId, idx) => {
                        const damageCategory = damageNodes[damageCategoryId];
                        return (
                            <div
                                key={`radioTile${idx}`}
                                className={cx('global!ace-u-grid-column--span-2')}
                            >
                                <RadioTile
                                    icon={selectCategoryIcon(damageCategory.name)}
                                    value={damageCategoryId}
                                    name={`damageCategory${damageCategory.id}`}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-full-height',
                                    ])}
                                >
                                    {translate(damageCategory.name)}
                                </RadioTile>
                            </div>
                        );
                    })}
                </div>
            </RadioButtonGroup>
        </Panel>
    );
};

DamageCategoryPanel.propTypes = {
    damageCategories: PropTypes.array.isRequired,
    serviceCase: PropTypes.object.isRequired,
    serviceAssignment: PropTypes.object.isRequired,
    damageNodes: PropTypes.object.isRequired,
    selectDecisionTreeOptions: PropTypes.func,
};

DamageCategoryPanel.defaultProps = {
    selectDecisionTreeOptions: () => null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        damageNodes: serviceCaseSelectors.getServiceTypeDamageNodes(state),
        damageCategories: serviceCaseSelectors.getServiceTypeCategories(state),
        serviceCase: getServiceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    selectDecisionTreeOptions: payload => dispatch({
        type: savActionTypes.SELECT_DECISION_TREE_OPTIONS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DamageCategoryPanel));
