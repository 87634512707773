import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import savScreenTabs from '../savScreenTabs';
import * as savActionTypes from '../savActionTypes';
import onSAVMapClickSelectTowingDestination from './onSAVMapClickSelectTowingDestination';

const savMapTowingDestinationWatcher = function* savMapTowingDestinationWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(savActionTypes.START_SAV_MAP_TOWING_DESTINATION_WATCHER);

        const onSAVMapClickSelectTowingDestinationTask = yield fork(onSAVMapClickSelectTowingDestination, payload);

        while (true) {
            const watcherTerminationAction = yield take([
                savActionTypes.STOP_SAV_MAP_TOWING_DESTINATION_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === savActionTypes.STOP_SAV_MAP_TOWING_DESTINATION_WATCHER) {
                yield cancel(onSAVMapClickSelectTowingDestinationTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const savRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_VEHICLE,
                exact: true,
            });
            const {activeTab} = savRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!savRouteMatch || activeTab !== savScreenTabs.TOWING_DESTINATION) {
                yield cancel(onSAVMapClickSelectTowingDestinationTask);
                continue mainFlow;
            }
        }
    }
};

export default savMapTowingDestinationWatcher;
