/**
 * @typedef {string} tabId
 */

/**
 * ServiceCase screen tabs
 *
 * @enum {tabId}
 */
export default {
    ASSIGNMENTS: 'assignments',
    PROTOCOL: 'protocol',
    TASK_LIST: 'task-list',
    BASIC_DATA: 'basic-data',
    DOCUMENTS: 'documents',
};
