import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {ArcGISMiniMapWidget, createMarkerGraphic, markerVehiclePNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel} from '@ace-de/ui-components';
import config from '../../config';

const ServiceLocationMapInfoPanel = ({serviceLocation}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sava_commissioning_tab');
    const arcGISMap = useArcGISMap('sc-basic-data-damage-location');

    const mapCenter = useMemo(() => {
        if (serviceLocation && serviceLocation.longitude && serviceLocation.latitude) {
            return [serviceLocation.longitude, serviceLocation.latitude];
        }

        return config.GERMANY_CENTER_COORDINATES;
    }, [serviceLocation]);

    useEffect(() => {
        if (!arcGISMap) return;

        if (mapCenter) {
            const [longitude, latitude] = mapCenter;
            arcGISMap.setGraphics({
                graphics: [
                    ...(latitude && longitude
                        ? [createMarkerGraphic({
                            id: 'serviceLocation',
                            longitude: longitude,
                            latitude: latitude,
                            icon: markerVehiclePNG,
                        })] : []),
                ],
            });
        }
    }, [serviceLocation, arcGISMap, mapCenter]);

    return (
        <Panel
            title={translateTab('damage_location_info_panel.title')}
            className={cx([
                'ace-c-panel--full-bleed-content',
            ])}
        >
            <ArcGISMiniMapWidget
                name="sc-basic-data-damage-location"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
            />
            {serviceLocation ? (
                <div className={cx('global!ace-u-margin--32')}>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {serviceLocation.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-body-bold')}>
                        {`${serviceLocation.street ? `${serviceLocation.street}, ` : ''}${serviceLocation.postCode} ${serviceLocation.city}`}
                        &nbsp;
                        {serviceLocation.country ? translate(`global.country.${snakeCase(serviceLocation.country)}`) : ''}
                    </p>
                    <p className={cx('global!ace-u-typography')}>
                        {serviceLocation.locationNotes}
                    </p>
                    <p className={cx('global!ace-u-typography')}>
                        {`${serviceLocation.latitude} ${serviceLocation.longitude}`}
                    </p>
                </div>
            ) : null}
        </Panel>
    );
};

ServiceLocationMapInfoPanel.propTypes = {
    serviceLocation: PropTypes.object,
};

ServiceLocationMapInfoPanel.defaultProps = {
    serviceLocation: null,
};

export default ServiceLocationMapInfoPanel;
