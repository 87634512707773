import React, {useState, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase, camelCase} from 'change-case';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberSalutationTypes, ampMemberSexTypes, europeanCountries, ServiceCaseVehicle, efServiceCaseTypes, ampMembershipTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Divider, Option, Button} from '@ace-de/ui-components';
import {Form, SelectField, InputField, DateField, AutocompleteField, NumberInputField} from '@ace-de/ui-components/form';
import {closeIcon, InteractiveIcon, calendarIcon, Icon, vehicleIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import {validatePhoneNo, validateEmail} from '../../utils/validation';

const initialErrors = {
    email: '',
    phoneNo: '',
    country: '',
};

const CreateEmptyCaseModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('create_empty_case_modal');
    const {hasBackdrop, declineCreateEmptyCase, confirmCreateEmptyCase} = props;
    const [country, setCountry] = useState('');
    const [emptyCaseData, setEmptyCaseData] = useState(new ServiceCaseVehicle());
    const [errors, setErrors] = useState(initialErrors);
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    const isButtonDisabled = !emptyCaseData.member.personalDetails.surname;

    const areFormValuesValid = formValues => {
        let hasError = false;
        const updatedErrors = {...initialErrors};
        // validate email
        if (formValues.email && !validateEmail(formValues.email)) {
            updatedErrors['email'] = translateModal('error_message.invalid_email');
            hasError = true;
        }
        // validate phone number
        if (formValues.phoneNo && !validatePhoneNo(formValues.phoneNo)) {
            updatedErrors['phoneNo'] = translateModal('error_message.invalid_phone_number');
            hasError = true;
        }
        // validate country
        if (formValues.country && !europeanCountries[formValues.country]) {
            updatedErrors['country'] = translateModal('error_message.invalid_country');
            hasError = true;
        }
        if (!hasError) {
            setErrors(initialErrors);
            return true;
        }
        setErrors(updatedErrors);
        return false;
    };

    const handleOnSubmit = () => {
        const {member, commissioner} = emptyCaseData;
        const {personalDetails} = member;

        const hasValidationError = !areFormValuesValid({
            email: member?.contactDetails?.email,
            phoneNo: member?.contactDetails?.phoneNo,
            country: member?.personalDetails?.address?.country,
        });
        if (hasValidationError) return;

        if (personalDetails.surname && commissioner) {
            confirmCreateEmptyCase({
                ...emptyCaseData,
                commissioner: config.ACE_COMMISSIONER,
                nonAceMember: true,
                caseType: efServiceCaseTypes.VEHICLE,
                isNonACEMemberEmptyCase: true,
            });
        }
    };

    const handleOnChange = formValues => {
        const {
            street,
            country,
            city,
            postCode,
            phoneNo,
            email,
            commissioner,
            externalCaseId,
            maximumBudget,
            birthday,
            ...restFormValues
        } = formValues;

        setEmptyCaseData({
            commissioner: commissioner,
            externalCaseId,
            maximumBudget,
            member: {
                membershipNo: '0000000000',
                membershipType: ampMembershipTypes.PRIVATE,
                tariffDetails: {
                    currentTariff: ampMemberTariffGroups.NON_MEMBER,
                },
                personalDetails: {
                    ...restFormValues,
                    ...(restFormValues.firstName && restFormValues.surname
                        ? {name: `${restFormValues.firstName} ${restFormValues.surname}`} : {}),
                    birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : '',
                    address: {
                        country: country,
                        city: city,
                        postCode: postCode,
                        street: street,
                    },
                },
                contactDetails: {
                    email: email,
                    phoneNo: phoneNo,
                },
            },
        });
    };

    return (
        <Modal
            title={translateModal('title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateEmptyCase}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form name="emptyCaseForm" onChange={handleOnChange}>
                    {() => {
                        return (
                            <Fragment>
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--48-0',
                                        'global!ace-u-flex--align-flex-end',
                                    ])}
                                >
                                    <SelectField
                                        name="commissioner"
                                        placeholder={translateModal('select.placeholder')}
                                        className={cx('global!ace-u-grid-column--span-4')}
                                        value={config.ACE_COMMISSIONER_ID}
                                        isDisabled={true}
                                    >
                                        <Option
                                            key="commissionerACE"
                                            name="commissionerACE"
                                            value={config.ACE_COMMISSIONER_ID}
                                        >
                                            {config.ACE_COMMISSIONER.name}
                                        </Option>
                                    </SelectField>
                                    <InputField
                                        name="externalCaseId"
                                        value={emptyCaseData.externalCaseId || ''}
                                        label={translateModal('input_external_assignment_number.label')}
                                        className={cx('global!ace-u-grid-column--span-4')}
                                    />
                                    <NumberInputField
                                        name="maximumBudget"
                                        value={emptyCaseData.maximumBudget || ''}
                                        label={translateModal('input_maximum_budget.label')}
                                        className={cx('global!ace-u-grid-column--span-4')}
                                        min={0}
                                    />
                                </div>
                                <Divider />
                                <div>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-h3',
                                            'global!ace-u-margin--24-0',
                                        ])}
                                    >
                                        {translateModal('text.personal_data')}
                                    </p>
                                    <div className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-margin--bottom-24',
                                    ])}
                                    >
                                        <SelectField
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            label={translateModal('select_salutation.label')}
                                            name="salutation"
                                            value={emptyCaseData.member.personalDetails.salutation
                                                ? emptyCaseData.member.personalDetails.salutation : ''}
                                            placeholder={translate('global.select.placeholder')}
                                        >
                                            {Object.keys(ampMemberSalutationTypes).map(salutation => {
                                                return (
                                                    <Option
                                                        key={`salutation${camelCase(salutation)}`}
                                                        name={`salutation${camelCase(salutation)}`}
                                                        value={ampMemberSalutationTypes[salutation]}
                                                        className={cx('ace-c-option--small')}
                                                    >
                                                        {translateModal(`salutation_option.${snakeCase(salutation)}`)}
                                                    </Option>
                                                );
                                            })}
                                        </SelectField>
                                        <InputField
                                            name="surname"
                                            value={emptyCaseData.member.personalDetails
                                                ? emptyCaseData.member.personalDetails.surname : ''}
                                            label={translateModal('input_surname.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            isFieldRequired={true}
                                        />
                                        <InputField
                                            name="firstName"
                                            value={emptyCaseData.member.personalDetails
                                                ? emptyCaseData.member.personalDetails.firstName : ''}
                                            label={translateModal('input_name.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                        />
                                        <InputField
                                            name="street"
                                            value={emptyCaseData.member.personalDetails
                                                ? emptyCaseData.member.personalDetails.address.street : ''}
                                            label={translateModal('input_street_and_number.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                        />
                                        <InputField
                                            name="postCode"
                                            value={emptyCaseData.member.personalDetails
                                                ? emptyCaseData.member.personalDetails.address.postCode : ''}
                                            label={translateModal('input_post_code.label')}
                                            className={cx('global!ace-u-grid-column--span-2')}
                                        />
                                        <InputField
                                            name="city"
                                            value={emptyCaseData.member.personalDetails
                                                ? emptyCaseData.member.personalDetails.address.city : ''}
                                            label={translateModal('input_city.label')}
                                            className={cx('global!ace-u-grid-column--span-2')}
                                        />
                                        <AutocompleteField
                                            name="country"
                                            value={country}
                                            label={translateModal('select_country.label')}
                                            placeholder={translate('global.select.placeholder')}
                                            onOptionSelect={value => setCountry(value)}
                                            icon={arrowDownIcon}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            errors={errors['country'] ? [errors['country']] : []}
                                        >
                                            {sortedCountries
                                                .map(([countryCode, country]) => {
                                                    return (
                                                        <Option
                                                            key={country.id}
                                                            name={`country-${country.id}`}
                                                            value={countryCode}
                                                            className={cx('ace-c-option--small')}
                                                        >
                                                            {country.name}
                                                        </Option>
                                                    );
                                                })}
                                        </AutocompleteField>
                                        <InputField
                                            name="phoneNo"
                                            value={emptyCaseData.member.contactDetails
                                                ? emptyCaseData.member.contactDetails.phoneNo : ''}
                                            label={translateModal('input_phone.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            errors={errors['phoneNo'] ? [errors['phoneNo']] : []}

                                        />
                                        <InputField
                                            name="email"
                                            value={emptyCaseData.member.contactDetails
                                                ? emptyCaseData.member.contactDetails.email : ''}
                                            label={translateModal('input_email.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            errors={errors['email'] ? [errors['email']] : []}

                                        />
                                        <span className={cx('global!ace-u-grid-column--span-4')} />
                                        <SelectField
                                            name="sex"
                                            placeholder={translate('global.select.placeholder')}
                                            value={emptyCaseData.member.personalDetails.sex
                                                ? emptyCaseData.member.personalDetails.sex : ''}
                                            label={translateModal('select_sex.label')}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                        >
                                            {Object.keys(ampMemberSexTypes).map((sex, idx) => {
                                                return (
                                                    <Option
                                                        key={`sex${idx}`}
                                                        name={`sex${camelCase(sex)}`}
                                                        value={sex}
                                                        className={cx('ace-c-option--small')}
                                                    >
                                                        {translate(`global.member_sex.${snakeCase(sex)}`)}
                                                    </Option>
                                                );
                                            })}
                                        </SelectField>
                                        <DateField
                                            name="birthday"
                                            label={translateModal('select_birthdate.label')}
                                            placeholder={translate('global.select.placeholder')}
                                            value={emptyCaseData.member.personalDetails.birthday
                                                ? emptyCaseData.member.personalDetails.birthday : ''}
                                            icon={calendarIcon}
                                            className={cx('global!ace-u-grid-column--span-4')}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </Fragment>
                        );
                    }}
                </Form>
                <div
                    className={cx([
                        'global!ace-u-margin--top-24',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <Button
                        name="createVehicleRelatedCase"
                        onClick={handleOnSubmit}
                        isDisabled={isButtonDisabled}
                    >
                        <Icon
                            icon={vehicleIcon}
                            className={cx('global!ace-u-margin--right-8', {
                                'ace-c-icon--color-highlight': !isButtonDisabled,
                            })}
                        />
                        {translateModal('button_label.create_vehicle_related_case')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

CreateEmptyCaseModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineCreateEmptyCase: PropTypes.func.isRequired,
    confirmCreateEmptyCase: PropTypes.func.isRequired,
};

CreateEmptyCaseModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineCreateEmptyCase: () => dispatch({
        type: serviceCaseActionTypes.DECLINE_CREATE_EMPTY_CASE,
    }),
    confirmCreateEmptyCase: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_CREATE_EMPTY_CASE,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(CreateEmptyCaseModal);
