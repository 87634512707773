import {all, fork} from 'redux-saga/effects';
import createServiceAssignmentMedicineShipmentFlow from './sagas/createServiceAssignmentMedicineShipmentFlow';
import updateSAMSJourneyData from './sagas/updateSAMSJourneyData';
import updateSAMSRequirements from './sagas/updateSAMSRequirements';
import updateSAMSServiceLocationFlow from './sagas/updateSAMSServiceLocationFlow';
import updateSAMSCostCoverageFlow from './sagas/updateSAMSCostCoverageFlow';
import updateSAMSServiceProviderFlow from './sagas/updateSAMSServiceProvider';

/**
 *  SAMS watcher saga
 */
const samsWatcher = function* samsWatcher() {
    yield all([
        fork(createServiceAssignmentMedicineShipmentFlow),
        fork(updateSAMSRequirements),
        fork(updateSAMSServiceLocationFlow),
        fork(updateSAMSJourneyData),
        fork(updateSAMSCostCoverageFlow),
        fork(updateSAMSServiceProviderFlow),
    ]);
};

export default samsWatcher;
