import {take, put, call, select} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED, matchPath} from '@computerrock/formation-router';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as savActionTypes from '../savActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';
import routePaths from '../../routePaths';

const updateSAVCommissioningFlow = function* updateSAVCommissioningFlow() {
    while (true) {
        const {payload} = yield take(savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM);

        if (!payload) {
            yield put({
                type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
                payload: {caller: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM},
            });
            continue;
        }

        const {serviceCaseId, serviceAssignmentLineNo, serviceCaseData, serviceCaseType} = payload;
        const {serviceAssignmentData, serviceAssignmentType} = payload;
        const {attachmentsToSendWithAssignment, nonAceMember} = payload;
        const {location} = yield select(state => state.router);
        const dashboardScreenMatch = matchPath(location.pathname, {
            path: routePaths.DASHBOARD,
            exact: true,
        });

        // if the dashboardScreenMatch=true
        // service assignment was assigned successfully, no need to execute route unmount effect
        // all the serviceAssignmentData and serviceCaseData were already stored properly
        if (!dashboardScreenMatch) {
            yield call(sendAttachmentsWithAssignmentFlow, {
                caller: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM,
                attachmentsToSendWithAssignment,
                serviceCaseId,
            });

            yield* updateServiceAssignment({
                caller: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM,
                assignmentType: serviceAssignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });

            if (nonAceMember || serviceCaseData?.damage?.additionalDescription) {
                yield* updateServiceCase({
                    caller: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM,
                    caseType: serviceCaseType,
                    serviceCaseId,
                    serviceCaseData,
                });
            }
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savActionTypes.SUBMIT_SAV_COMMISSIONING_FORM},
        });
    }
};

export default updateSAVCommissioningFlow;
