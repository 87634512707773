import React, {useState, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useArcGISMap, createMarkerGraphic, markerSearchPNG, markerPersonPNG} from '@ace-de/eua-arcgis-map';
import {useStyles, Panel, ContentBlock, ContentItem, ScrollableBlock, Form, AutosuggestField, Option} from '@ace-de/ui-components';
import {Icon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sarcActionTypes from './sarcActionTypes';
import RentalCarStationItem from './ui-elements/RentalCarStationItem';

const SARCSelectStationTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const activeLanguage = activeLocale.split('-')[0];
    const translateTab = createTranslateShorthand('sarc_select_station_tab');
    const arcGISMap = useArcGISMap('service-assignment-rental-car');
    const {serviceCase, serviceAssignment, submitSARCRentalCarStationForm, submitSARCPickupLocationForm} = props;
    const {searchSARCPickupLocationGeolocation, searchSARCRentalCarStations} = props;
    const [selectedRentalCarStationId, setSelectedRentalCarStationId] = useState('');
    const currentPickupLocationAddress = useRef('');
    const lastPickupLocationSearchQuery = useRef('');
    const [newPickupLocation, setNewPickupLocation] = useState(null);
    const searchSARCPickupLocationGeolocationDebounced = useMemo(
        () => debounce(searchSARCPickupLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSARCPickupLocationGeolocation],
    );

    useEffect(() => {
        if (!newPickupLocation
            && serviceAssignment
            && serviceAssignment.pickupLocation) {
            setNewPickupLocation(serviceAssignment.pickupLocation);
        }
    }, [newPickupLocation, serviceAssignment]);

    useEffect(() => {
        const {provisionalRentalCarStation} = serviceAssignment;
        if (!provisionalRentalCarStation || selectedRentalCarStationId === provisionalRentalCarStation.id) return;

        setSelectedRentalCarStationId(provisionalRentalCarStation.id);
    }, [serviceAssignment, selectedRentalCarStationId]);

    useEffect(() => {
        if (!arcGISMap || !serviceAssignment) return;

        const {memberLocation} = serviceAssignment;
        arcGISMap.setGraphics({
            graphics: [
                ...(newPickupLocation
                    ? [createMarkerGraphic({
                        id: 'pickupLocation',
                        longitude: newPickupLocation.longitude,
                        latitude: newPickupLocation.latitude,
                        icon: markerSearchPNG,
                    })] : []),
                ...(memberLocation
                    ? [createMarkerGraphic({
                        id: 'memberLocation',
                        longitude: memberLocation.longitude,
                        latitude: memberLocation.latitude,
                        icon: markerPersonPNG,
                    })] : []),
            ],
        });
    }, [arcGISMap, serviceAssignment, newPickupLocation]);

    const handlePickupLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentPickupLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSARCPickupLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastPickupLocationSearchQuery.current = searchQueryString;
        }
    };

    const handlePickupLocationCandidateSelect = pickupLocationCandidate => {
        currentPickupLocationAddress.current = pickupLocationCandidate.formattedAddress;
        setNewPickupLocation(pickupLocationCandidate);

        searchSARCRentalCarStations({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            pickupLocation: pickupLocationCandidate,
        });

        submitSARCPickupLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                pickupLocation: pickupLocationCandidate,
            },
        });
    };

    const handleRentalCarStationSelect = newRentalCarStationId => {
        if (!newRentalCarStationId || selectedRentalCarStationId === newRentalCarStationId) return;

        setSelectedRentalCarStationId(newRentalCarStationId);
        const {rentalCarStationRecommendations} = serviceAssignment;
        submitSARCRentalCarStationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            provisionalRentalCarStation: rentalCarStationRecommendations
                .find(rentalCarStation => rentalCarStation.id === newRentalCarStationId),
        });
    };

    // on Enter, prevent form submission
    const onFormSubmit = event => {
        event.preventDefault();
    };

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return;

    const {pickupLocationCandidates, pickupLocationSearchQuery, rentalCarStationRecommendations} = serviceAssignment;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.rental_car_station')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                        <Form name="pickupLocationForm" onSubmit={onFormSubmit}>
                            {formValues => (
                                <AutosuggestField
                                    name="pickupLocationSearchQuery"
                                    value={newPickupLocation?.formattedAddress || ''}
                                    onChange={handlePickupLocationSearchQueryChange}
                                    onOptionSelect={handlePickupLocationCandidateSelect}
                                    optionValueSelector={locationCandidate => {
                                        return locationCandidate.formattedAddress;
                                    }}
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-padding--left-32',
                                        'global!ace-u-padding--right-32',
                                    ])}
                                    label={translateTab('input_label.pickup_location')}
                                    placeholder={translateTab('input_placeholder.please_fill_in')}
                                    icon={searchIcon}
                                >
                                    {(formValues['pickupLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                    && pickupLocationSearchQuery === lastPickupLocationSearchQuery.current
                                        ? pickupLocationCandidates
                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                            .map((locationCandidate, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        name={`pickup-location-candidate-${index}`}
                                                        value={locationCandidate}
                                                    >
                                                        <Icon
                                                            icon={locationIcon}
                                                            className={cx('global!ace-u-margin--right-16')}
                                                        />
                                                        {locationCandidate.formattedAddress}
                                                    </Option>
                                                );
                                            }) : null}
                                </AutosuggestField>
                            )}
                        </Form>
                        <div
                            className={cx([
                                'global!ace-u-padding--top-32',
                                'global!ace-u-padding--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            <div className={cx('global!ace-u-typography--variant-body-bold')}>
                                {translateTab('heading.rental_car_duration')}
                            </div>
                            <div className={cx('global!ace-u-typography--variant-body')}>
                                {serviceAssignment.pickupDate && serviceAssignment.dropOffDate
                                    ? (
                                        `${moment(serviceAssignment.pickupDate).locale(activeLanguage).format('DD.MM.YYYY')}
                                        -
                                         ${moment(serviceAssignment.dropOffDate).locale(activeLanguage).format('DD.MM.YYYY')}
                                        `
                                    )
                                    : ''}
                            </div>
                            <div
                                className={cx(['global!ace-u-padding--top-16', 'global!ace-u-typography--variant-body-bold'])}
                            >
                                {translateTab('heading.rental_car_category')}
                            </div>
                            <div className={cx('global!ace-u-typography--variant-body')}>
                                {serviceAssignment.rentalCarCategory
                                    ? translate(`global.rental_car_category_type.${serviceAssignment.rentalCarCategory.toLowerCase()}`)
                                    : ''}
                            </div>
                        </div>
                        {rentalCarStationRecommendations.map(rentalCarStation => {
                            return (
                                <RentalCarStationItem
                                    key={rentalCarStation.id}
                                    rentalCarStation={rentalCarStation}
                                    isSelected={rentalCarStation.id === selectedRentalCarStationId}
                                    onSelect={handleRentalCarStationSelect}
                                />
                            );
                        })}
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SARCSelectStationTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchSARCPickupLocationGeolocation: PropTypes.func.isRequired,
    submitSARCPickupLocationForm: PropTypes.func.isRequired,
    searchSARCRentalCarStations: PropTypes.func.isRequired,
    submitSARCRentalCarStationForm: PropTypes.func.isRequired,
};

SARCSelectStationTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSARCPickupLocationGeolocation: payload => dispatch({
        type: sarcActionTypes.SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSARCPickupLocationForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_PICKUP_LOCATION_FORM,
        payload,
    }),
    searchSARCRentalCarStations: payload => dispatch({
        type: sarcActionTypes.SEARCH_SARC_RENTAL_CAR_STATIONS,
        payload,
    }),
    submitSARCRentalCarStationForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_RENTAL_CAR_STATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SARCSelectStationTab));
