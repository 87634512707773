import {select, take, fork, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/*
* Service case: get vehicle models flow (uses silverDAT API)
*/
const getVehicleModelsFlow = function* getVehicleModelsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const silverDATService = serviceManager.loadService('silverDATService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.GET_VEHICLE_MODELS);
        const {vehicleType, manufacturer} = payload;

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST,
            silverDATService.getVehicleModels, {
                vehicleType,
                manufacturer,
            },
        );

        const responseAction = yield take([
            serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.FETCH_VEHICLE_MODELS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {vehicleModels} = response;

            yield put({
                type: serviceCaseActionTypes.STORE_VEHICLE_MODELS,
                payload: {vehicleModels, vehicleType, manufacturer},
            });
        }
    }
};

export default getVehicleModelsFlow;
