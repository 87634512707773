import React, {useState, Fragment, useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles, ButtonSecondary, HighlightCircle} from '@ace-de/ui-components';
import {InteractiveIcon, arrowUpIcon, arrowDownIcon, plusIcon, Icon} from '@ace-de/ui-components/icons';
import {efServiceAssignmentTypes, efServiceAssignmentStatusTypes, efServiceTypes} from '@ace-de/eua-entity-types';
import {useHistory, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import * as serviceCaseIcons from '../serviceCaseIcons';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import getServiceAssignmentRedirectParams from '../getServiceAssignmentRedirectParams';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import config from '../../config';

const AssignmentDetailsPanel = props => {
    const {cx} = useStyles();
    const {children, serviceAssignment, serviceCase, damageNodes} = props;
    const {initiateServiceAssignmentDeletionFlow, initiateCreateCPQualityReportFlow} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateComponent = createTranslateShorthand('sc_service_assignments_tab');
    const [isToggleOn, setIsToggleOn] = useState(
        serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.DONE,
    );
    const damageCategory = useMemo(() => {
        const [damageCategoryNode] = serviceCase.damageNodeSnapshots;
        if (damageCategoryNode && damageNodes[damageCategoryNode]) {
            return damageNodes[damageCategoryNode]?.name.split('.').pop().toUpperCase() || '';
        }
        return '';
    }, [serviceCase.damageNodeSnapshots, damageNodes]);
    const history = useHistory();
    const isServiceAssignmentDeletionAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.SERVICE_ASSIGNMENT,
        ecsFeatureActions.DELETE,
    ) && !!serviceAssignment?.isEligibleForDeletion;

    const handleTogglePanel = () => setIsToggleOn(!isToggleOn);

    const handleRedirectToAssignment = () => {
        const routeParams = getServiceAssignmentRedirectParams(serviceAssignment);

        history.push(resolveRoute(
            routeParams.route,
            {
                serviceCaseId: serviceCase.id,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
            },
            {search: prepareSearchQueryParams({activeTab: routeParams.activeTab})},
        ));
    };

    const handleServiceAssignmentDeletion = () => {
        initiateServiceAssignmentDeletionFlow({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
        });
    };

    const isInitialAssignment = serviceAssignment.lineNo === 'FBS1'
        || parseInt(serviceAssignment.lineNo, 10) === 1
        || serviceAssignment.isCloneFromInitial;

    return (
        <Panel
            qaIdent={`${serviceAssignment.assignmentType}-assignment`}
            title={(
                <div className={cx('global!ace-u-flex')}>
                    <p
                        className={cx('global!ace-u-typography--variant-h2', 'global!ace-u-margin--right-32')}
                    >
                        {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE && (
                            serviceAssignment.serviceType
                                ? translate(`global.service_type.${snakeCase(serviceAssignment.serviceType)}`)
                                : translateComponent(`assignment_details_panel_title.empty`)
                        )}
                        {serviceAssignment.assignmentType !== efServiceAssignmentTypes.VEHICLE && (
                            serviceAssignment.assignmentType === efServiceAssignmentTypes.OTHER_SERVICES
                                ? (serviceAssignment.serviceType
                                    ? translate(`global.other_services_service_type.${snakeCase(serviceAssignment.serviceType)}`)
                                    : translate(`global.other_services_no_service_selected`))
                                : translate(`global.additional_service.${snakeCase(serviceAssignment.assignmentType)}`)
                        )}
                        &nbsp;{`${serviceCase.prefix}-${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`}
                    </p>
                    {serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE && (
                        <InteractiveIcon
                            icon={isToggleOn ? arrowUpIcon : arrowDownIcon}
                            onClick={handleTogglePanel}
                        />
                    )}
                </div>
            )}
            actions={(
                <Fragment>
                    {serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE
                        && !isToggleOn
                        && !serviceAssignment.isCloned && (
                            <Fragment>
                                <ButtonSecondary
                                    onClick={handleRedirectToAssignment}
                                    className={cx('global!ace-u-margin--right-24')}
                                >
                                    {translateComponent('assignment_panel_button_label.details')}
                                </ButtonSecondary>
                            </Fragment>
                    )}
                    {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                        && serviceAssignment.isCloned
                        && isInitialAssignment
                        && damageCategory
                        && (
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--disabled',
                                    'global!ace-u-margin--right-16',
                                ])}
                            >
                                <Icon
                                    icon={serviceCaseIcons.damageCategoryIcons[damageCategory]}
                                    className={cx('ace-c-icon--color-disabled')}
                                />
                            </HighlightCircle>
                        )}
                    <HighlightCircle className={cx('ace-c-highlight-circle--primary')}>
                        {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                            && serviceAssignment.serviceType
                            && (
                                <Icon
                                    icon={serviceCaseIcons.serviceIcons[serviceAssignment.serviceType]}
                                    className={cx('ace-c-icon--color-highlight')}
                                />
                            )}
                        {serviceAssignment.assignmentType === efServiceAssignmentTypes.VEHICLE
                            && !serviceAssignment.serviceType
                            && (
                                <Icon
                                    icon={serviceCaseIcons.serviceIcons[efServiceTypes.NO_SERVICE]}
                                    className={cx('ace-c-icon--color-highlight')}
                                />
                            )}
                        {serviceAssignment.assignmentType !== efServiceAssignmentTypes.VEHICLE && (
                            <Icon
                                icon={serviceCaseIcons.serviceAssignmentIcons[serviceAssignment.assignmentType]}
                                className={cx('ace-c-icon--color-highlight')}
                            />
                        )}
                    </HighlightCircle>
                </Fragment>
            )}
        >
            {isToggleOn && (
                <div>
                    {children}
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row-reverse',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                    >
                        <div>
                            {(!config.IS_FALLBACK_SYSTEM
                                && isServiceAssignmentDeletionAvailable
                                && !isInitialAssignment) && (
                                    <ButtonSecondary onClick={handleServiceAssignmentDeletion}>
                                        {translateComponent('assignment_panel_button_label.delete')}
                                    </ButtonSecondary>
                            )}
                            {!serviceAssignment.isCloned && (
                                <ButtonSecondary
                                    onClick={handleRedirectToAssignment}
                                    className={cx('global!ace-u-margin--left-24')}
                                >
                                    {translateComponent('assignment_panel_button_label.details')}
                                </ButtonSecondary>
                            )}
                        </div>
                        {[efServiceAssignmentTypes.VEHICLE, efServiceAssignmentTypes.PICKUP]
                            .includes(serviceAssignment.assignmentType)
                            && !!serviceAssignment.acePartner?.id && (
                                <InteractiveIcon
                                    icon={plusIcon}
                                    className={cx('ace-c-interactive-icon--primary')}
                                    onClick={() => initiateCreateCPQualityReportFlow({
                                        serviceCaseId: serviceCase?.id,
                                        contractPartnerId: serviceAssignment?.acePartner?.id,
                                        contractPartnerName: serviceAssignment?.acePartner?.name,
                                    })}
                                >
                                    {translateComponent('assignment_panel_button_label.create_qm_note')}
                                </InteractiveIcon>
                        )}
                    </div>
                </div>
            )}
        </Panel>
    );
};

AssignmentDetailsPanel.propTypes = {
    serviceAssignment: PropTypes.object.isRequired,
    serviceCase: PropTypes.object,
    damageNodes: PropTypes.object,
    initiateServiceAssignmentDeletionFlow: PropTypes.func,
    initiateCreateCPQualityReportFlow: PropTypes.func.isRequired,
};

AssignmentDetailsPanel.defaultProps = {
    damageNodes: {},
    serviceCase: {},
    initiateServiceAssignmentDeletionFlow: () => {},
};
const mapStateToProps = state => {
    return {
        damageNodes: serviceCaseSelectors.getServiceTypeDamageNodes(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateServiceAssignmentDeletionFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_DELETE_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateCreateCPQualityReportFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetailsPanel);
