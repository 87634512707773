import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import savScreenTabs from '../savScreenTabs';
import filterSAVMapContractPartnersBySearchQuery from './filterSAVMapContractPartnersBySearchQuery';
import filterSAVMapContractPartnersByLocation from './filterSAVMapContractPartnersByLocation';

const searchSAVContractPartners = function* searchSAVContractPartners({payload}) {
    const {serviceCaseId, serviceAssignmentLineNo, searchQuery, isVBASearch} = payload;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savScreenTabs.CONTRACT_PARTNER) return;

    if (searchQuery) {
        yield* filterSAVMapContractPartnersBySearchQuery(
            serviceCaseId,
            serviceAssignmentLineNo,
            searchQuery,
            isVBASearch,
        );
    } else {
        yield* filterSAVMapContractPartnersByLocation(serviceCaseId, serviceAssignmentLineNo, isVBASearch);
    }
};

export default searchSAVContractPartners;
