import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';

const CommissioningMemberDetailsPanel = ({serviceCase}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_commissioning_tab');
    const {member, callbackPhoneNo} = serviceCase;
    const {personalDetails} = member;
    const {address} = personalDetails;

    return (
        <Panel
            title={translateTab('member_details_panel.title')}
            qaIdent="sav-commissioning-member-details"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.membership_no')}
                        qaIdent="member-membership-number"
                    >
                        {member.membershipNo || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.name')}
                        qaIdent="member-personal-details-first-name"
                    >
                        {personalDetails.firstName || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.last_name')}
                        qaIdent="member-personal-details-surname"
                    >
                        {personalDetails.surname || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.callback_phone_no')}
                        qaIdent="member-contact-details-phone"
                    >
                        {callbackPhoneNo || '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.street')}
                        qaIdent="member-address-street"
                    >
                        {address.street || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.post_code')}
                        qaIdent="member-address-post-code"
                    >
                        {address.postCode || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_details_panel_input_label.city')}
                        qaIdent="member-address-city"
                    >
                        {address.city || '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CommissioningMemberDetailsPanel.propTypes = {
    serviceCase: PropTypes.object,
};

CommissioningMemberDetailsPanel.defaultProps = {
    serviceCase: null,
};

export default withRouter(CommissioningMemberDetailsPanel);
