/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE = '[service-cases] CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE';
export const SUBMIT_SALA_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SALA_REQUIREMENTS_FORM';
export const SUBMIT_SALA_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_SALA_SERVICE_PROVIDER_FORM';
export const SUBMIT_SALA_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SALA_COST_COVERAGE_FORM';
export const SUBMIT_SALA_SERVICE_LOCATION_FORM = '[service-cases] SUBMIT_SALA_SERVICE_LOCATION_FORM';

/**
 * Event action types
 */
export const CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_LEGAL_ADVICE_REQUEST_FAILED';

/**
 * Store action types
 */
