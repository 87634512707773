import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, List, Option, ButtonSecondary, Form, Icon, InteractiveIcon} from '@ace-de/ui-components';
import {ContentBlock, ContentItem} from '@ace-de/ui-components/app-layout';
import {breakdownIcon, vehicleOpeningIcon, towingIcon, closeIcon, checkmarkIcon, noServiceIcon, recoveryIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as savActionTypes from '../savActionTypes';

const serviceIcons = {
    [efServiceTypes.VEHICLE_OPENING]: vehicleOpeningIcon,
    [efServiceTypes.RECOVERY]: recoveryIcon,
    [efServiceTypes.ROADSIDE_ASSISTANCE]: breakdownIcon,
    [efServiceTypes.TOWING]: towingIcon,
    [efServiceTypes.NO_SERVICE]: noServiceIcon,
};

const SAVServiceTypeSelectionModal = props => {
    const {hasBackdrop, serviceCase} = props;
    const {confirmSAVServiceTypeSelection, declineSAVServiceTypeSelection} = props;
    const {cx} = useStyles();
    const [selectedType, setSelectedType] = useState(serviceCase?.serviceType || null);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('sav_service_type_selection_modal');

    const handleOnSubmit = pickedService => {
        if (!pickedService) {
            return;
        }
        confirmSAVServiceTypeSelection({
            serviceType: pickedService.serviceTypeList,
            damageNodeSnapshots: serviceCase.damageNodeSnapshots,
        });
    };

    return (
        <Modal
            action={(
                <InteractiveIcon icon={closeIcon} onClick={declineSAVServiceTypeSelection} />
            )}
            hasBackdrop={hasBackdrop}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-full-width',
                ])}
            >
                <ContentItem>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateModal('header.title')}
                    </p>
                    <p className={cx('global!ace-u-margin--bottom-24')}>
                        {translateModal('question.text')}
                    </p>
                </ContentItem>
                <ContentItem>
                    <Form
                        name="manualPickService"
                        onSubmit={handleOnSubmit}
                        isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                                'global!ace-u-flex--align-flex-end',
                                'global!ace-u-full-width',
                            ])}
                        >
                            <List
                                className={cx('global!ace-u-full-width')}
                                name="serviceTypeList"
                                value={selectedType}
                                onChange={setSelectedType}
                            >
                                {Object.keys(efServiceTypes).map(typeKey => {
                                    return (
                                        <Option
                                            value={typeKey}
                                            key={`${typeKey}Key`}
                                            name={`option${typeKey}`}
                                        >
                                            <div
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--justify-space-between',
                                                ])}
                                            >
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--align-center',
                                                    ])}
                                                >
                                                    <Icon
                                                        icon={serviceIcons[typeKey] || null}
                                                        className={cx('global!ace-u-margin--right-16')}
                                                    />
                                                    {translate(`global.service_type.${snakeCase(typeKey)}`)}
                                                </div>
                                                {selectedType === typeKey && (
                                                    <Icon
                                                        icon={checkmarkIcon}
                                                        className={cx('ace-c-icon--color-success')}
                                                    />
                                                )}
                                            </div>
                                        </Option>
                                    );
                                })}
                            </List>
                            <ButtonSecondary
                                name="submitManualPickService"
                                type="submit"
                                className={cx('global!ace-u-margin--top-24')}
                            >
                                {translateModal('button_label.set_manually')}
                            </ButtonSecondary>
                        </div>
                    </Form>
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

SAVServiceTypeSelectionModal.propTypes = {
    confirmSAVServiceTypeSelection: PropTypes.func.isRequired,
    declineSAVServiceTypeSelection: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceCase: PropTypes.object,
};

SAVServiceTypeSelectionModal.defaultProps = {
    hasBackdrop: true,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmSAVServiceTypeSelection: payload => dispatch({
        type: savActionTypes.CONFIRM_SAV_SERVICE_TYPE_SELECTION,
        payload,
    }),
    declineSAVServiceTypeSelection: () => dispatch({type: savActionTypes.DECLINE_SAV_SERVICE_TYPE_SELECTION}),
});

export default connect(mapStateToProps, mapDispatchToProps)(SAVServiceTypeSelectionModal);
