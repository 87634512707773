import React from 'react';
import {useStyles} from '@ace-de/ui-components';
import styles from './GooglePlacesLogo.module.scss';

const GooglePlacesLogo = props => {
    const {cx} = useStyles(props, styles);

    return (
        <p className={cx('ace-c-google-places-logo')} />
    );
};
export default GooglePlacesLogo;
