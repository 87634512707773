import {all, takeLatest, fork} from 'redux-saga/effects';
import * as memberActionTypes from './memberActionTypes';
import deleteMemberVehicleFlow from './sagas/deleteMemberVehicleFlow';
import searchMembers from './sagas/searchMembers';
import searchVehicles from './sagas/searchVehicles';
import updateMemberFlow from './sagas/updateMemberFlow';
import validateSearchResults from './sagas/validateSearchResults';
import createMemberNoteFlow from './sagas/createMemberNoteFlow';
import deleteMemberNoteFlow from './sagas/deleteMemberNoteFlow';
import createMemberVehicleFlow from './sagas/createMemberVehicleFlow';
import createPersonServiceCaseFlow from './sagas/createPersonServiceCaseFlow';
import initiateForeignAssistanceCaseCreationModalFlow from './sagas/initiateForeignAssistanceCreationModalFlow';
import initiateEmptyCaseCreationFlow from './sagas/initiateEmptyCaseCreationFlow';
import editMemberVehicleFlow from './sagas/editMemberVehicleFlow';
import searchMemberAddressGeolocation from './sagas/searchMemberAddressGeolocation';
import selectMemberAddressGeolocation from './sagas/selectMemberAddressGeolocation';
import updateMemberVehicleActivityFlow from './sagas/updateMemberVehicleActivityFlow';

/**
 *  Members watcher saga
 */
const memberWatcher = function* memberWatcher() {
    yield all([
        takeLatest(memberActionTypes.SEARCH_MEMBERS, searchMembers),
        fork(updateMemberFlow),
        takeLatest(memberActionTypes.MARK_SEARCH_RESULTS_AS_VALID, validateSearchResults),
        fork(deleteMemberVehicleFlow),
        takeLatest(memberActionTypes.SEARCH_VEHICLES, searchVehicles),
        fork(createMemberNoteFlow),
        fork(deleteMemberNoteFlow),
        fork(createMemberVehicleFlow),
        fork(createPersonServiceCaseFlow),
        fork(initiateForeignAssistanceCaseCreationModalFlow),
        fork(initiateEmptyCaseCreationFlow),
        fork(editMemberVehicleFlow),
        takeLatest(memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION, searchMemberAddressGeolocation),
        takeLatest(memberActionTypes.SELECT_MEMBER_ADDRESS_GEOLOCATION, selectMemberAddressGeolocation),
        fork(updateMemberVehicleActivityFlow),
    ]);
};

export default memberWatcher;
