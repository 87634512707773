import {take, put, select, fork} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';
import routePaths from '../../routePaths';

const updateTaskFlow = function* updateTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    while (true) {
        const {payload = {}} = yield take(taskActionTypes.INITIATE_TASK_UPDATE_FLOW);
        const {task} = payload;
        const {location} = yield select(state => state.router);

        const isTaskSearchScreenPath = matchPath(location.pathname, {
            path: routePaths.TASK_SEARCH,
            exact: true,
        });

        if (isTaskSearchScreenPath) {
            const taskDTOs = [task];
            yield put({
                type: taskActionTypes.STORE_TASKS,
                payload: {taskDTOs},
            });
        }

        yield* openModal(modalIds.TASK_DATA, task?.id ? {taskId: `${task.id}`} : {});
        const chosenModalOption = yield take([
            taskActionTypes.CONFIRM_TASK_UPDATE,
            taskActionTypes.DECLINE_TASK_UPDATE,
        ]);

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.CONFIRM_TASK_UPDATE) {
            const {taskData, taskId} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                taskActionTypes.UPDATE_TASK_REQUEST,
                ecsTaskManagementService.updateTask,
                {
                    taskId,
                    taskPatchDTO: Task.objectToPatchDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.UPDATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.UPDATE_TASK_REQUEST_FAILED,
            ]);

            // NOTE: errors are handled implicitly as part of the fetchRequest
            // below you can handle something in addition if needed

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {taskDTO} = response;
                const taskDTOs = [taskDTO];

                yield put({
                    type: taskActionTypes.STORE_TASKS,
                    payload: {taskDTOs},
                });
            }
        }

        yield* closeModal(modalIds.TASK_DATA, task?.id ? {taskId: `${task.id}`} : {});
    }
};

export default updateTaskFlow;
