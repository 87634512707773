import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as saosActionTypes from '../saosActionTypes';

/**
 * Update SAOS damage location flow
 */
const updateSAOSDamageLocationFlow = function* updateSAOSDamageLocationFlow() {
    while (true) {
        const {payload} = yield take(saosActionTypes.SUBMIT_SAOS_DAMAGE_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId, serviceCaseData} = payload;

        // if it's an initial other service assignment, patch the damage location on a case
        if (['1', 'FBS1'].includes(serviceAssignmentLineNo) && serviceCaseData) {
            yield* updateServiceCase({
                caller: saosActionTypes.SUBMIT_SAOS_DAMAGE_LOCATION_FORM,
                caseType: efServiceCaseTypes.HOUSEHOLD,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield* updateServiceAssignment({
            caller: saosActionTypes.SUBMIT_SAOS_DAMAGE_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.OTHER_SERVICES,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saosActionTypes.SUBMIT_SAOS_DAMAGE_LOCATION_FORM},
        });
    }
};

export default updateSAOSDamageLocationFlow;
