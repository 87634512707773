import {eventChannel} from 'redux-saga';
import {select, take, put, call} from 'redux-saga/effects';
import * as sarcActionTypes from '../sarcActionTypes';

const onSARCMapClickSelectRentalCarStation = function* onSARCMapClickSelectRentalCarStation(payload) {
    const {serviceCaseId, serviceAssignmentLineNo} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-rental-car');
    const sarcRentalCarStationsLayer = yield call(arcGISMap.getLayer, 'sarc-rental-car-stations');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = sarcRentalCarStationsLayer.on('feature-select', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => unsubscribeClickListener();
    });

    while (true) {
        const {featureDTO: rentalCarStationDTO} = yield take(locationsLayerClickEventChannel);

        if (rentalCarStationDTO && !rentalCarStationDTO.isSelected) {
            const rentalCarStationId = rentalCarStationDTO['stationId'];

            const {serviceAssignments} = yield select(state => state.serviceAssignments);
            const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
            const serviceAssignment = serviceAssignments[serviceAssignmentId];
            const selectedRentalCarStation = serviceAssignment
                && serviceAssignment.rentalCarStationRecommendations
                    .find(rentalCarStation => rentalCarStation.id === rentalCarStationId);
            if (!selectedRentalCarStation) continue;

            yield put({
                type: sarcActionTypes.SET_SARC_PROVISIONAL_RENTAL_CAR_STATION,
                payload: {serviceAssignmentId, provisionalRentalCarStation: selectedRentalCarStation},
            });
        }
    }
};

export default onSARCMapClickSelectRentalCarStation;
