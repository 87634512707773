import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {useRouter, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efServiceCaseStatusTypes, efServiceCaseContactChannelTypes} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle, Badge} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, assignmentIcon, rightIcon, closeIcon} from '@ace-de/ui-components/icons';
import MemberNotePopup from './MemberNotePopup';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';

const ServiceCaseHeader = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const router = useRouter();
    const translateHeader = createTranslateShorthand('service_case_header');
    const {serviceCase, agentUserProfile} = props;
    const {member, nonAceMember, commissioner, contactChannel, isLegalCaseOngoing} = serviceCase;
    const {personalDetails} = member;
    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    return (
        <Fragment>
            <ScreenTitle>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={assignmentIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <span
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {translateHeader('screen_title.service_case')}
                    </span>
                    <span
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {serviceCase.id}
                    </span>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                        ])}
                    >
                        {translateHeader('label.status')}&nbsp;
                        {serviceCase.status !== efServiceCaseStatusTypes.UNKNOWN && (
                            <Badge status="platinum">
                                {translate(`global.service_case_status_type.${snakeCase(serviceCase.status)}`)}
                            </Badge>
                        )}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.created_at')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {serviceCase.createdAt
                                ? moment(serviceCase.createdAt).format('DD.MM.YYYY') : ''}
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.created_by')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {contactChannel !== efServiceCaseContactChannelTypes.APP
                                ? isAgentIdentityAvailable
                                    ? agentUserProfile && agentUserProfile.displayName
                                    : translate('global.user_shift_role.emergency_call_advisor')
                                : efServiceCaseContactChannelTypes.APP}
                        </p>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.commisioner')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {commissioner?.name || ''}
                        </p>
                    </div>
                    {member && (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body-medium',
                                'global!ace-u-margin--left-48',
                                'global!ace-u-flex',
                            ])}
                        >
                            {translateHeader('label.member')}&nbsp;
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {nonAceMember
                                    ? personalDetails.name || personalDetails.surname
                                    : personalDetails.displayName}
                            </p>
                            {!nonAceMember && <MemberNotePopup member={member} />}
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-medium',
                            'global!ace-u-margin--left-48',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        {translateHeader('label.place_of_residence')}
                        <Icon icon={rightIcon} className={cx('ace-c-icon--color-highlight')} />
                        {translateHeader('label.place_of_damage')}&nbsp;
                        <p className={cx('global!ace-u-typography--variant-body')}>
                            {serviceCase.distanceResidenceToDamageLocation !== null
                                ? `${Math.round(serviceCase.distanceResidenceToDamageLocation)} ${translate('global.unit.km')}`
                                : ''}
                        </p>
                    </div>
                    {isLegalCaseOngoing && (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body-medium',
                                'global!ace-u-margin--left-32',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <Badge status="negative">
                                {translateHeader('label.legal_case_ongoing')}
                            </Badge>
                        </div>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={router.goBack}
                        isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                    />
                </div>
            </div>
        </Fragment>
    );
};

ServiceCaseHeader.propTypes = {
    serviceCase: PropTypes.object.isRequired,
    agentUserProfile: PropTypes.object,
};

ServiceCaseHeader.defaultProps = {
    agentUserProfile: null,
};

export default withRouter(ServiceCaseHeader);
