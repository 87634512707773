import {take, put, call} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes, efServiceCaseTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import * as saosActionTypes from '../saosActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';

/**
 * Update SAOS cost coverage data flow
 */
const updateSAOSCostCoverageDataFlow = function* updateSAOSCostCoverageDataFlow() {
    while (true) {
        const {payload} = yield take(saosActionTypes.SUBMIT_SAOS_COST_COVERAGE_FORM);
        const {
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
            serviceCaseData,
            nonAceMember,
            attachmentsToSendWithAssignment,
        } = payload;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: saosActionTypes.SUBMIT_SAOS_COST_COVERAGE_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: saosActionTypes.SUBMIT_SAOS_COST_COVERAGE_FORM,
            assignmentType: efServiceAssignmentTypes.OTHER_SERVICES,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (nonAceMember) {
            yield* updateServiceCase({
                caller: saosActionTypes.SUBMIT_SAOS_COST_COVERAGE_FORM,
                caseType: efServiceCaseTypes.VEHICLE,
                serviceCaseId,
                serviceCaseData,
            });
        }

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: saosActionTypes.SUBMIT_SAOS_COST_COVERAGE_FORM},
        });
    }
};

export default updateSAOSCostCoverageDataFlow;
