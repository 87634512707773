import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savrActionTypes from '../savrActionTypes';

/**
 * Update SAVR requirements flow
 */
const updateSAVRRequirementsFlow = function* updateSAVRRequirementsFlow() {
    while (true) {
        const {payload} = yield take(savrActionTypes.SUBMIT_SAVR_REQUIREMENTS_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: savrActionTypes.SUBMIT_SAVR_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.VEHICLE_RETURN,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: savrActionTypes.SUBMIT_SAVR_REQUIREMENTS_FORM},
        });
    }
};

export default updateSAVRRequirementsFlow;
