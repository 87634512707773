import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes, apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as salaActionTypes from './salaActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageCommissioningPanel from './ui-elements/CostCoverageCommissioningPanel';
import CommissioningContactPersonPanel from '../service-assignments/ui-elements/CommissioningContactPersonPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import config from '../config';
import CostCoverageDetailsPanel from './ui-elements/CostCoverageDetailsPanel';
import CostCoverageCaseDescriptionPanel from './ui-elements/CostCoverageCaseDescriptionPanel';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SALACostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, submitSALACostCoverageForm, users} = props;
    const {submitSALACostCoverageServiceProviderForm, submitCreateSALAPDF} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [assignmentText, setAssignmentText] = useState(serviceAssignment?.assignmentText || '');
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [additionalInformationText, setAdditionalInformationText] = useState(
        serviceAssignment?.additionalInformationText || '',
    );
    // TODO: check field name
    const [description, setDescription] = useState(serviceAssignment?.description || '');
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    useEffect(() => {
        setCallbackPhoneNo(serviceAssignment?.callbackPhoneNo || '');
    }, [serviceAssignment.callbackPhoneNo]);

    const onChangeHandler = (key, value) => {
        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'description') {
            setDescription(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'additionalInformationText' && value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
            setAdditionalInformationText(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
                selfPayerNote: '',
            });
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_SELF_PAYER_NOTE_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
        }
    };

    const handleOnSend = serviceProviderData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            assignmentText,
            additionalInformationText,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            description,
            ...serviceProviderData,
        };

        submitSALACostCoverageServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.LEGAL_ADVICE,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM,
            });
            return;
        }

        submitSALACostCoverageForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                assignmentText,
                additionalInformationText,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                description,
            },
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        assignmentText,
        additionalInformationText,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        description,
        submitSALACostCoverageForm,
        attachmentsToSendWithAssignment,
    ]);

    const submitCreatePDF = serviceProviderData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            assignmentText,
            additionalInformationText,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            description,
            ...serviceProviderData,
        };

        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);

        submitCreateSALAPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentType: efServiceAssignmentTypes.LEGAL_ADVICE,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    additionalInformationText={additionalInformationText}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageCommissioningPanel
                    assignmentText={assignmentText}
                    onChangeHandler={onChangeHandler}
                />
                <CommissioningContactPersonPanel
                    title={translate('sala_cost_coverage_tab.panel_title.contact_person_at_location')}
                    contactPerson={serviceAssignment.contactPersonOnSite}
                />
                <CommissioningContactPersonPanel
                    title={translate('sala_cost_coverage_tab.panel_title.affected')}
                    contactPerson={serviceAssignment.affectedPerson}
                    isAffectedPerson={true}
                />
                <CostCoverageDetailsPanel />
                <CostCoverageCaseDescriptionPanel
                    description={description}
                    onChangeHandler={onChangeHandler}
                    legalClaim={serviceAssignment?.legalClaim}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    assignmentChannels={[
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                    ]}
                    handleOnAssignmentSubmit={handleOnSend}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SALACostCoverageTab.propTypes = {
    submitSALACostCoverageForm: PropTypes.func.isRequired,
    submitCreateSALAPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSALACostCoverageServiceProviderForm: PropTypes.func.isRequired,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SALACostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSALACostCoverageForm: payload => dispatch({
        type: salaActionTypes.SUBMIT_SALA_COST_COVERAGE_FORM,
        payload,
    }),
    submitSALACostCoverageServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSALAPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SALACostCoverageTab));
