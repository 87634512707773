import {eventChannel} from 'redux-saga';
import {select, take, fork, put, call} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as sasdActionTypes from '../sasdActionTypes';
import config from '../../config';

const onSASDMapClickFetchRelevantLocationAddress = function* onSASDMapClickFetchRelevantLocationAddress(payload) {
    const {serviceAssignmentId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-substitute-driver');
    const mapClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = arcGISMap.on('map-click', payload => {
            emitter(payload);
        });
        const unsubscribeDragListener = arcGISMap.on('graphic-drag-end', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeClickListener();
            unsubscribeDragListener();
        };
    });

    while (true) {
        const {arcGISPoint} = yield take(mapClickEventChannel);

        yield fork(
            fetchRequest,
            sasdActionTypes.FETCH_SASD_RELEVANT_LOCATION_ADDRESS_REQUEST,
            arcGISRESTService.getLocationAddress,
            {
                longitude: arcGISPoint.longitude,
                latitude: arcGISPoint.latitude,
                langCode: config.ARCGIS_DEFAULT_LANG_CODE,
            },
        );

        const responseAction = yield take([
            sasdActionTypes.FETCH_SASD_RELEVANT_LOCATION_ADDRESS_REQUEST_SUCCEEDED,
            sasdActionTypes.FETCH_SASD_RELEVANT_LOCATION_ADDRESS_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {arcGISReverseGeocodingLocationDTO} = response;

            yield put({
                type: sasdActionTypes.SET_SASD_REVERSE_GEOCODING_RELEVANT_LOCATION,
                payload: {arcGISReverseGeocodingLocationDTO, serviceAssignmentId},
            });
        }
    }
};

export default onSASDMapClickFetchRelevantLocationAddress;
