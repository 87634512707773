import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import samaScreenTabs from '../samaScreenTabs';
import * as samaActionTypes from '../samaActionTypes';
import onSAMAMapClickSelectServiceProvider from './onSAMAMapClickSelectServiceProvider';

const samaMapServiceProviderWatcher = function* samaMapServiceProviderWatcher() {
    mainFlow: while (true) {
        const {payload} = yield take(samaActionTypes.START_SAMA_MAP_SERVICE_PROVIDER_WATCHER);

        const onSAMAMapClickSelectServiceProviderTask = yield fork(
            onSAMAMapClickSelectServiceProvider,
            payload,
        );

        while (true) {
            const watcherTerminationAction = yield take([
                samaActionTypes.STOP_SAMA_MAP_SERVICE_PROVIDER_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === samaActionTypes.STOP_SAMA_MAP_SERVICE_PROVIDER_WATCHER) {
                yield cancel(onSAMAMapClickSelectServiceProviderTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const samaRouteMatch = matchPath(location.pathname, {
                path: routePaths.SERVICE_ASSIGNMENT_MEDICAL_ADVICE,
                exact: true,
            });

            const {activeTab} = samaRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!samaRouteMatch || activeTab !== samaScreenTabs.SERVICE_PROVIDER) {
                yield cancel(onSAMAMapClickSelectServiceProviderTask);
                continue mainFlow;
            }
        }
    }
};

export default samaMapServiceProviderWatcher;
