import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sadehActionTypes from '../sadehActionTypes';

/**
 * Update SADEH requirements details
 */
const updateSADEHRequirements = function* updateSADEHRequirements() {
    while (true) {
        const {payload} = yield take(sadehActionTypes.SUBMIT_SADEH_REQUIREMENTS_FORM);
        const {serviceCaseId, serviceAssignmentData, serviceAssignmentLineNo} = payload;

        yield* updateServiceAssignment({
            caller: sadehActionTypes.SUBMIT_SADEH_REQUIREMENTS_FORM,
            assignmentType: efServiceAssignmentTypes.DEATH,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sadehActionTypes.SUBMIT_SADEH_REQUIREMENTS_FORM},
        });
    }
};

export default updateSADEHRequirements;
