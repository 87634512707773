import React, {Fragment, useEffect, useState, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmContractPartnerContractStatusTypes, efRentalCarSubStatusTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Divider} from '@ace-de/ui-components';
import {InputField, CheckboxGroup, Checkbox, Button, ButtonPrimary} from '@ace-de/ui-components/form';
import {InteractiveIcon, editIcon, closeIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as sarcActionTypes from '../sarcActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import acePartnerIdToLogoMapping from '../acePartnerIdToLogoMapping';
import {validateEmail} from '../../utils/validation';

const CostCoverageRentalCarPanel = props => {
    const {cx} = useStyles();
    const {serviceCase, serviceAssignment, acePartners} = props;
    const {submitSARCACEPartnerEnquiryForm, initiateSARCUpdateACEPartnerStationFlow, submitCreatePDFForm} = props;
    const {initiateServiceAssignmentStatusUpdateFlow, initiateSaveArchiveServiceAssignmentFlow} = props;
    const {initiateExtendSARCDropOffDateFlow, onSendCostCoverage, isSendButtonDisabled} = props;
    const {initiateResendServiceAssignmentFlow, attachmentsToSendWithAssignment} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_cost_coverage_tab');
    const [emailError, setEmailErrorMessage] = useState([]);
    const [email, setEmail] = useState(serviceAssignment?.acePartner?.businessContactDetails?.email || '');
    const [selectedSpecialACEPartners, setSelectedSpecialACEPartner] = useState(
        serviceAssignment?.subStatus === efRentalCarSubStatusTypes.ENQUIRY
            ? serviceAssignment?.enquiryListIds || []
            : [],
    );
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const downloadPDFButtonRef = useRef(null);

    const specialACEPartners = useMemo(() => {
        return Object.values(acePartners).filter(acePartner => acePartner.isSpecial);
    }, [acePartners]);

    useEffect(() => {
        setEmail(serviceAssignment?.acePartner?.businessContactDetails?.email || '');
    }, [serviceAssignment?.acePartner?.businessContactDetails?.email]);

    const handleOnSaveArchive = () => {
        initiateSaveArchiveServiceAssignmentFlow({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
        });
    };

    const formatEnquiryList = () => {
        return Object.values(specialACEPartners)
            .filter(acePartner => selectedSpecialACEPartners.indexOf(acePartner.id) !== -1)
            .map(acePartner => ({
                serviceProviderId: null,
                partnerId: acePartner.id,
                name: acePartner.name,
                email: acePartner?.businessContactDetails?.email || '',
                phone: acePartner?.businessContactDetails?.phoneNo || '',
                contractId: acePartner.contractId || '',
                customerId: acePartner.customerId || '',
            }));
    };

    const handleOnSelectProvider = () => {
        if (isEditModeActive) {
            setIsEditModeActive(false);
        }

        initiateSARCUpdateACEPartnerStationFlow();
    };

    const handleDownloadPDF = () => {
        submitCreatePDFForm();
        downloadPDFButtonRef.current.blur();
    };

    const handleOnSubmitACEPartnerEnquiry = isSendEnquiryRequested => {
        const serviceAssignmentSubStatusData = {
            subStatus: efRentalCarSubStatusTypes.ENQUIRY,
            enquiryList: formatEnquiryList(),
            sendEnquiryRequested: isSendEnquiryRequested,
        };

        submitSARCACEPartnerEnquiryForm({
            serviceAssignmentSubStatusData,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
        });
    };

    const handleOnSend = () => {
        if (email && !validateEmail(email)) {
            setEmailErrorMessage([translateTab('text.please_enter_valid_email')]);
            return;
        }

        setEmailErrorMessage([]);

        const serviceAssignmentData = {
            acePartner: {
                businessContactDetails: {
                    email,
                },
            },
        };

        onSendCostCoverage({
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData,
        });
    };

    if (!serviceAssignment || !specialACEPartners || Object.values(specialACEPartners).length === 0) return null;
    const {acePartner} = serviceAssignment;
    const isEnquirySent = serviceAssignment.subStatus === efRentalCarSubStatusTypes.ENQUIRY;
    const isAssignmentExtended = serviceAssignment.subStatus === efRentalCarSubStatusTypes.EXTEND;
    const isAssignmentAssigned = serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED;

    return (
        <Panel
            title={translateTab('panel_title.rental_car')}
            actions={isEnquirySent && !isAssignmentExtended && !!acePartner && !isAssignmentAssigned && (
                <InteractiveIcon
                    icon={isEditModeActive ? closeIcon : editIcon}
                    className={cx('ace-c-interactive-icon--reverse')}
                    onClick={() => setIsEditModeActive(prevState => !prevState)}
                />
            )}
        >
            {!!acePartner && !isEditModeActive ? (
                <Fragment>
                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                        <div className={cx('global!ace-u-margin--bottom-24')}>
                            <p className={cx('global!ace-u-typography--variant-body-medium')}>
                                {acePartner.name}
                            </p>
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {acePartner.location?.street || ''}
                            </p>
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {[acePartner.location?.postCode, acePartner.location?.city].filter(value => value).join(' ')}
                            </p>
                            <p className={cx('global!ace-u-typography--variant-body')}>
                                {`${translateTab('text.tel')}: ${acePartner.businessContactDetails?.phoneNo || '-'}`}
                            </p>
                        </div>
                    </div>
                    <p className={cx(['global!ace-u-typography--variant-body-bold', 'global!ace-u-margin--bottom-24'])}>
                        {translateTab('text.send_cost_coverage')}
                    </p>
                    <InputField
                        name="email"
                        label={translateTab('input_label.by_email')}
                        value={email || ''}
                        onChange={value => setEmail(value)}
                        isDisabled={serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW
                            || isAssignmentAssigned}
                        errors={emailError}
                        className={cx('global!ace-u-full-width')}
                    />
                </Fragment>
            ) : (
                <CheckboxGroup
                    name="selectedSpecialACEPartners"
                    value={selectedSpecialACEPartners}
                    onChange={value => setSelectedSpecialACEPartner(value)}
                >
                    {Object.values(specialACEPartners)
                        .sort((acePartnerA, acePartnerB) => {
                            const acePartnerAName = (acePartnerA.name || '').toLowerCase();
                            const acePartnerBName = (acePartnerB.name || '').toLowerCase();
                            return acePartnerAName.localeCompare(acePartnerBName);
                        }).filter(acePartner => {
                            return acePartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE;
                        })
                        .map((acePartner, idx) => {
                            return (
                                <Fragment key={acePartner.id}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-margin--bottom-16',
                                            {
                                                'global!ace-u-margin--top-16': idx !== 0,
                                            },
                                        ])}
                                    >
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-flex-start'])}>
                                            <Checkbox
                                                name={acePartner.name}
                                                value={acePartner.id}
                                                isDisabled={(isEnquirySent && serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW) // eslint-disable-line max-len
                                                    || isEditModeActive
                                                    || serviceAssignment.listOfPreviousPartners?.includes(acePartner.id)
                                                }
                                            />
                                            <div>
                                                {acePartnerIdToLogoMapping[acePartner.id] ? (
                                                    <img
                                                        src={acePartnerIdToLogoMapping[acePartner.id]}
                                                        alt={acePartner.name}
                                                    />
                                                ) : (
                                                    <p>{acePartner.name}</p>
                                                )}
                                                <p className={cx('global!ace-u-typography--variant-caption')}>
                                                    {`${translateTab('text.email')}: ${acePartner.businessContactDetails?.email || '-'}`}
                                                </p>
                                                <p className={cx('global!ace-u-typography--variant-caption')}>
                                                    {`${translateTab('text.tel')}: ${acePartner.businessContactDetails?.phoneNo || '-'}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                </CheckboxGroup>
            )}
            {!acePartner && !isEnquirySent && (
                <div className={cx('global!ace-u-margin--top-32')}>
                    <ButtonPrimary
                        name="sendEnquiryRequestButton"
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-24')}
                        onClick={() => handleOnSubmitACEPartnerEnquiry(true)}
                        isDisabled={Array.isArray(selectedSpecialACEPartners)
                            ? selectedSpecialACEPartners.length === 0 : true}
                    >
                        {translateTab('button_label.send_request')}
                    </ButtonPrimary>
                    <Button
                        name="doNotSendEnquiryRequestButton"
                        className={cx('global!ace-u-full-width')}
                        onClick={() => handleOnSubmitACEPartnerEnquiry(false)}
                    >
                        {translateTab('button_label.do_not_send_request')}
                    </Button>
                </div>
            )}
            <div className={cx('global!ace-u-margin--top-32')}>
                {(!acePartner || isEditModeActive) && isEnquirySent && (
                    <Fragment>
                        <ButtonPrimary
                            name="selectServiceProviderButton"
                            className={cx('global!ace-u-full-width')}
                            onClick={handleOnSelectProvider}
                        >
                            {translateTab('button_label.select_provider')}
                        </ButtonPrimary>
                    </Fragment>
                )}
            </div>
            <div className={cx('global!ace-u-margin--top-32')}>
                {(isEnquirySent
                    || isAssignmentExtended
                    // when the assignment was archived & reopened, then display buttons
                    || (serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.NEW
                        && !serviceAssignment.subStatus
                        && !!acePartner
                        && !!serviceAssignment?.rentalCarStationPickup
                    )
                ) && !!acePartner && !isAssignmentAssigned && !isEditModeActive && (
                    <Fragment>
                        <ButtonPrimary
                            name="sendCostCoverageButton"
                            className={cx('global!ace-u-full-width')}
                            onClick={handleOnSend}
                            isDisabled={!email || isSendButtonDisabled}
                        >
                            {translateTab('button_label.send')}
                        </ButtonPrimary>
                        <Button
                            name="saveArchiveButton"
                            className={cx(['global!ace-u-margin--top-24', 'global!ace-u-full-width'])}
                            onClick={handleOnSaveArchive}
                        >
                            {translateTab('button_label.save_archive')}
                        </Button>
                    </Fragment>
                )}
                {!isEnquirySent
                    && !!acePartner
                    && serviceAssignment.assignmentStatus !== efServiceAssignmentStatusTypes.NEW && (
                        <Fragment>
                            <ButtonPrimary
                                name="updateStatusButton"
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--bottom-24'])}
                                onClick={initiateServiceAssignmentStatusUpdateFlow}
                            >
                                {translateTab('button_label.update_status')}
                            </ButtonPrimary>
                            <Button
                                name="downloadPDFButton"
                                ref={downloadPDFButtonRef}
                                className={cx('global!ace-u-full-width')}
                                onClick={handleDownloadPDF}
                            >
                                {translateTab('button_label.download_assignment_pdf')}
                            </Button>
                            <Button
                                name="resendServiceAssignmentButton"
                                className={cx(['global!ace-u-full-width', 'global!ace-u-margin--top-24'])}
                                onClick={() => initiateResendServiceAssignmentFlow({
                                    attachmentsToSendWithAssignment,
                                })}
                                isDisabled={serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE
                                    || isSendButtonDisabled}
                            >
                                {translateTab('button_label.send_again')}
                            </Button>
                        </Fragment>
                )}
                {[
                    efServiceAssignmentStatusTypes.ASSIGNED,
                    efServiceAssignmentStatusTypes.ACCEPTED,
                    efServiceAssignmentStatusTypes.DONE,
                ].includes(serviceAssignment.assignmentStatus) && (
                    <Button
                        name="extendServiceAssignmentButton"
                        className={cx(['global!ace-u-full-width', 'global!ace-u-margin--top-24'])}
                        onClick={() => initiateExtendSARCDropOffDateFlow()}
                        isDisabled={serviceAssignment.assignmentStatus === efServiceAssignmentStatusTypes.DONE}
                    >
                        {translateTab('button_label.extend')}
                    </Button>
                )}
            </div>
        </Panel>
    );
};

CostCoverageRentalCarPanel.propTypes = {
    serviceCase: PropTypes.object,
    submitSARCACEPartnerEnquiryForm: PropTypes.func.isRequired,
    initiateSARCUpdateACEPartnerStationFlow: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
    acePartners: PropTypes.object,
    initiateServiceAssignmentStatusUpdateFlow: PropTypes.func.isRequired,
    submitCreatePDFForm: PropTypes.func.isRequired,
    initiateExtendSARCDropOffDateFlow: PropTypes.func.isRequired,
    initiateSaveArchiveServiceAssignmentFlow: PropTypes.func.isRequired,
    onSendCostCoverage: PropTypes.func,
    initiateResendServiceAssignmentFlow: PropTypes.func.isRequired,
    isSendButtonDisabled: PropTypes.bool,
    attachmentsToSendWithAssignment: PropTypes.array,
};

CostCoverageRentalCarPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    acePartners: null,
    attachmentsToSendWithAssignment: null,
    onSendCostCoverage: () => null,
    isSendButtonDisabled: false,
};

const mapStateToProps = (state, props) => {
    const getServiceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentsSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: getServiceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentsSelector(state, props),
        acePartners: serviceAssignmentSelectors.getACEPartners(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSARCACEPartnerEnquiryForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_ACE_PARTNER_ENQUIRY_FORM,
        payload,
    }),
    initiateSARCUpdateACEPartnerStationFlow: payload => dispatch({
        type: sarcActionTypes.INITIATE_SARC_UPDATE_ACE_PARTNER_STATION_FLOW,
        payload,
    }),
    initiateServiceAssignmentStatusUpdateFlow: () => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW,
    }),
    initiateExtendSARCDropOffDateFlow: () => dispatch({
        type: sarcActionTypes.INITIATE_SARC_EXTEND_DROPOFF_DATE_FLOW,
    }),
    initiateSaveArchiveServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
    initiateResendServiceAssignmentFlow: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CostCoverageRentalCarPanel));
