import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import {apmContractPartnerAssignmentChannelTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageTravelDataPanel from './ui-elements/CostCoverageTravelDataPanel';
import CostCoverageCostCoveragePanel from './ui-elements/CostCoverageCostCoveragePanel';
import * as saaActionTypes from './saaActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import CostCoverageCostOverviewPanel from './ui-elements/CostCoverageCostOverviewPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import config from '../config';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SAACostCoverageTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const {serviceCase, serviceAssignment, users, submitSAACostCoverageForm, submitSAAACEPartnerForm} = props;
    const {submitCreateSAAPDF, hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const translateTab = createTranslateShorthand('saa_cost_coverage_tab');
    const coverageBreakfastTranslation = translateTab('input_value.take_over_breakfast');
    const [additionalServicesText, setAdditionalServicesText] = useState(
        serviceAssignment.coveringBreakfast
            ? serviceAssignment.additionalServicesText || coverageBreakfastTranslation
            : !serviceAssignment.coveringBreakfast
                && serviceAssignment.additionalServicesText !== coverageBreakfastTranslation
                ? serviceAssignment.additionalServicesText
                : '',
    );
    const [description, setDescription] = useState(serviceAssignment.description);
    const [hotelCost, setHotelCost] = useState(serviceAssignment.hotelCost);
    const [costConfirmedByMember, setIsConfirmedByMember] = useState(!!serviceAssignment.costConfirmedByMember);
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);
    const [hasCostError, setHasCostError] = useState(!serviceAssignment?.hotelCost);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    useEffect(() => {
        setCallbackPhoneNo(serviceAssignment.callbackPhoneNo);
        setHotelCost(serviceAssignment.hotelCost);
        setHasCostError(!serviceAssignment.hotelCost);
    }, [serviceAssignment.callbackPhoneNo, serviceAssignment.hotelCost]);

    const onChangeHandler = (key, value) => {
        if (key === 'hasSelfPayerOption') {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
                selfPayerNote: '',
            });
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
            return;
        }

        if (key === 'additionalServicesText') {
            if (serviceAssignment.coveringBreakfast && value.length < coverageBreakfastTranslation.length) return;
            setAdditionalServicesText(value);
            return;
        }

        if (key === 'description') {
            setDescription(value);
            return;
        }

        if (key === 'hotelCost') {
            setHotelCost(value);
            setHasCostError(!value);
            return;
        }

        if (key === 'costConfirmedByMember') {
            setIsConfirmedByMember(value);
            return;
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
        }
    };

    const handleAssignmentSubmit = costCoverageData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            additionalServicesText,
            description,
            hotelCost,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            costConfirmedByMember: hotelCost - serviceAssignment.totalCoverage <= 0
                ? false
                : !!costConfirmedByMember,
            ...costCoverageData,
        };

        submitSAAACEPartnerForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            serviceAssignmentData,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };

    const handleTabChange = useCallback(() => {
        submitSAACostCoverageForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalServicesText,
                description,
                hotelCost,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                costConfirmedByMember: hotelCost - serviceAssignment.totalCoverage <= 0
                    ? false
                    : !!costConfirmedByMember,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.totalCoverage,
        serviceCase.id,
        submitSAACostCoverageForm,
        costConfirmedByMember,
        hotelCost,
        additionalServicesText,
        description,
        maximumBudget,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        serviceCase.nonAceMember,
        attachmentsToSendWithAssignment,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM,
            });
            return;
        }

        handleTabChange();
    }, [selfPayer, handleTabChange]);

    const submitCreatePDF = costCoverageData => {
        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);
        submitCreateSAAPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentType: serviceAssignment.assignmentType,
            serviceCaseType: serviceCase.caseType,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                additionalServicesText,
                description,
                hotelCost,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                costConfirmedByMember: hotelCost - serviceAssignment.totalCoverage <= 0
                    ? false
                    : !!costConfirmedByMember,
                ...costCoverageData,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case don't render
    if (!serviceCase) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'global!ace-u-margin--top-0',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageTravelDataPanel />
                <CostCoverageCostCoveragePanel
                    description={description}
                    additionalServicesText={additionalServicesText}
                    onChangeHandler={onChangeHandler}
                />
                <CostCoverageCostOverviewPanel
                    serviceCase={serviceCase}
                    hotelCost={hotelCost}
                    costConfirmedByMember={costConfirmedByMember}
                    onChangeHandler={onChangeHandler}
                    hasCostError={hasCostError}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    title={translateTab('panel_title.accommodation')}
                    assignmentChannels={[
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                    ]}
                    handleOnAssignmentSubmit={handleAssignmentSubmit}
                    isConfirmedByMember={serviceAssignment.totalCoverage - hotelCost < 0 && !costConfirmedByMember}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    hasCostError={hasCostError}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SAACostCoverageTab.propTypes = {
    submitSAACostCoverageForm: PropTypes.func.isRequired,
    submitCreateSAAPDF: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSAAACEPartnerForm: PropTypes.func,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SAACostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: {},
    users: {},
    submitSAAACEPartnerForm: () => {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSAACostCoverageForm: payload => dispatch({
        type: saaActionTypes.SUBMIT_SAA_COST_COVERAGE_FORM,
        payload,
    }),

    submitSAAACEPartnerForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSAAPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAACostCoverageTab));
