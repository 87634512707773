/**
 * Command action types
 */
export const CREATE_SERVICE_ASSIGNMENT_RENTAL_CAR = '[service-cases] CREATE_SERVICE_ASSIGNMENT_RENTAL_CAR';
export const SUBMIT_SARC_REQUIREMENTS_FORM = '[service-cases] SUBMIT_SARC_REQUIREMENTS_FORM';
export const SUBMIT_SARC_COST_COVERAGE_FORM = '[service-cases] SUBMIT_SARC_COST_COVERAGE_FORM';
export const SEARCH_SARC_MEMBER_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SARC_MEMBER_LOCATION_GEOLOCATION';
export const SUBMIT_SARC_MEMBER_LOCATION_FORM = '[service-cases] SUBMIT_SARC_MEMBER_LOCATION_FORM';
export const SUBMIT_SARC_ACE_PARTNER_ENQUIRY_FORM = '[service-cases] SUBMIT_SARC_ACE_PARTNER_ENQUIRY_FORM';
export const SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION';
export const SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION';
export const SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION';
export const SEARCH_SARC_RENTAL_CAR_STATIONS = '[service-cases] SEARCH_SARC_RENTAL_CAR_STATIONS';
export const SUBMIT_SARC_RENTAL_CAR_STATION_FORM = '[service-cases] SUBMIT_SARC_RENTAL_CAR_STATION_FORM';
export const SUBMIT_SARC_PICKUP_LOCATION_FORM = '[service-cases] SUBMIT_SARC_PICKUP_LOCATION_FORM';
export const INITIATE_SARC_UPDATE_ACE_PARTNER_STATION_FLOW = '[service-cases] INITIATE_SARC_UPDATE_ACE_PARTNER_STATION_FLOW';
export const CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION = '[service-cases] CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION';
export const DECLINE_SARC_UPDATE_ACE_PARTNER_STATION = '[service-cases] DECLINE_SARC_UPDATE_ACE_PARTNER_STATION';
export const FETCH_SARC_ACE_PARTNER_STATIONS_FLOW = '[service-cases] FETCH_SARC_ACE_PARTNER_STATIONS_FLOW';
export const SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM = '[service-cases] SUBMIT_SARC_COST_COVERAGE_RENTAL_CAR_FORM';
export const START_SARC_MAP_RENTAL_CAR_STATION_WATCHER = '[service-cases] START_SARC_MAP_RENTAL_CAR_STATION_WATCHER';
export const STOP_SARC_MAP_RENTAL_CAR_STATION_WATCHER = '[service-cases] STOP_SARC_MAP_RENTAL_CAR_STATION_WATCHER';
export const SUBMIT_SARC_BUDGET_FORM = '[service-cases] SUBMIT_SARC_BUDGET_FORM';
export const SUBMIT_SARC_COSTS_AND_BUDGET_FORM = '[service-cases] SUBMIT_SARC_COSTS_AND_BUDGET_FORM';
export const CALCULATE_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE = '[service-cases] CALCULATE_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE';
export const INITIATE_SARC_EXTEND_DROPOFF_DATE_FLOW = '[service-cases] INITIATE_SARC_EXTEND_DROPOFF_DATE_FLOW';
export const CONFIRM_SARC_EXTEND_DROPOFF_DATE = '[service-cases] CONFIRM_SARC_EXTEND_DROPOFF_DATE';
export const DECLINE_SARC_EXTEND_DROPOFF_DATE = '[service-cases] DECLINE_SARC_EXTEND_DROPOFF_DATE';

/**
 * Event action types
 */
export const CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST = '[service-cases] CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST';
export const CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_SENT';
export const CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_RENTAL_CAR_ASSIGNMENT_REQUEST_FAILED';

export const FETCH_ACE_PARTNER_DATA_REQUEST = '[service-cases] FETCH_ACE_PARTNER_DATA_REQUEST';
export const FETCH_ACE_PARTNER_DATA_REQUEST_SENT = '[service-cases] FETCH_ACE_PARTNER_DATA_REQUEST_SENT';
export const FETCH_ACE_PARTNER_DATA_REQUEST_SUCCEEDED = '[service-cases] FETCH_ACE_PARTNER_DATA_REQUEST_SUCCEEDED';
export const FETCH_ACE_PARTNER_DATA_REQUEST_FAILED = '[service-cases] FETCH_ACE_PARTNER_DATA_REQUEST_FAILED';

export const FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST = '[service-cases] FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST';
export const FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_SENT = '[service-cases] FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_SENT';
export const FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_SUCCEEDED = '[service-cases] FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_SUCCEEDED';
export const FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_FAILED = '[service-cases] FETCH_SARC_ACE_PARTNER_STATIONS_FLOW_REQUEST_FAILED';

export const FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST = '[service-cases] FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST';
export const FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_SENT = '[service-cases] FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_SENT';
export const FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_SUCCEEDED = '[service-cases] FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_SUCCEEDED';
export const FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_FAILED = '[service-cases] FETCH_RENTAL_CAR_ACE_PARTNER_SIPP_CODES_REQUEST_FAILED';

export const SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST';
export const SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SARC_PICKUP_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST = '[service-cases] FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST';
export const FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SENT = '[service-cases] FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SENT';
export const FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_SUCCEEDED';
export const FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_FAILED = '[service-cases] FETCH_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS_REQUEST_FAILED';

export const FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST';
export const FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE_REQUEST_FAILED';

export const FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST = '[service-cases] FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST';
export const FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SENT = '[service-cases] FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SENT';
export const FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SUCCEEDED = '[service-cases] FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SUCCEEDED';
export const FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_FAILED = '[service-cases] FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_SARC_PICKUP_LOCATION_CANDIDATES = '[service-cases] SET_SARC_PICKUP_LOCATION_CANDIDATES';
export const SET_SARC_DELIVERY_LOCATION_CANDIDATES = '[service-cases] SET_SARC_DELIVERY_LOCATION_CANDIDATES';
export const SET_SARC_DROP_OFF_LOCATION_CANDIDATES = '[service-cases] SET_SARC_DROP_OFF_LOCATION_CANDIDATES';
export const SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS = '[service-cases] SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS';
export const SET_SARC_PROVISIONAL_RENTAL_CAR_STATION = '[service-cases] SET_SARC_PROVISIONAL_RENTAL_CAR_STATION';
export const SET_SARC_DELIVERY_LOCATION_CANDIDATE_DISTANCE = '[service-cases] SET_SARC_DELIVERY_LOCATION_CANDIDATE_DISTANCE';
