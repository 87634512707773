import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as savaActionTypes from '../savaActionTypes';
import savaScreenTabs from '../savaScreenTabs';

const loadSAVAMapTowingDestinationWatcher = function* loadSAVAMapTowingDestinationWatcher({payload}) {
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== savaScreenTabs.TOWING_DESTINATION) return;

    yield put({
        type: savaActionTypes.START_SAVA_MAP_TOWING_DESTINATION_WATCHER,
        payload: {
            serviceCaseId,
            serviceAssignmentLineNo,
        },
    });
};

export default loadSAVAMapTowingDestinationWatcher;
