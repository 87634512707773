import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efTransportationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ContentBlock, ContentItem, DateField} from '@ace-de/ui-components';
import {Form, InputField, Checkbox} from '@ace-de/ui-components/form';
import {calendarIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sasdActionTypes from './sasdActionTypes';
import VehicleSelectPanel from '../service-cases/ui-elements/VehicleSelectPanel';
import ServiceAssignmentJourneyNotesPanel from '../service-assignments/ui-elements/ServiceAssignmentJourneyNotesPanel';

const SASDJourneyDataTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sasd_journey_data_tab');
    const {serviceCase, serviceAssignment, memberVehicles, submitSASDJourneyDetailsForm} = props;
    const [selectedVehicle, setSelectedVehicle] = useState(serviceCase?.vehicle?.vehicleId
        ? serviceCase.vehicle
        : null);
    const [durationAndTypeData, setDurationAndTypeData] = useState({
        journeyStartDate: serviceAssignment.journeyStartDate,
        journeyEndDate: serviceAssignment.journeyEndDate,
        luggageAmount: serviceAssignment.luggageAmount,
    });
    const [additionalInformationData, setAdditionalInformationData] = useState({
        isKeyAvailable: serviceCase.isKeyAvailable,
        isDriverLicenseAvailable: serviceCase.isDriverLicenseAvailable,
    });

    // if an agent creates a draft, reset previously selected vehicle
    useEffect(() => {
        if (serviceCase?.vehicle && !serviceCase.vehicle?.vehicleId && selectedVehicle) {
            setSelectedVehicle(null);
        }
    }, [serviceCase?.vehicle, selectedVehicle]);

    const handleDurationAndTypeFormChange = formValues => {
        setDurationAndTypeData(formValues);
    };

    const handleAdditionalInformationFormChange = formValues => {
        setAdditionalInformationData({
            isKeyAvailable: !!formValues.isKeyAvailable,
            isDriverLicenseAvailable: !!formValues.isDriverLicenseAvailable,
        });
    };

    const handleOnTabChange = useCallback(() => {
        submitSASDJourneyDetailsForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            caseType: serviceCase.caseType,
            serviceCaseData: {
                ...additionalInformationData,
            },
            serviceAssignmentData: {
                ...durationAndTypeData,
                transportationType: efTransportationTypes.PERSONAL_VEHICLE,
            },
        });
    }, [
        additionalInformationData,
        durationAndTypeData,
        serviceCase.id,
        serviceCase.caseType,
        serviceAssignment.lineNo,
        submitSASDJourneyDetailsForm,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!durationAndTypeData || !additionalInformationData) {
            completeRouteUnmountSideEffect({
                caller: sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM,
            });
            return;
        }

        handleOnTabChange();
    }, [
        durationAndTypeData,
        additionalInformationData,
        handleOnTabChange,
    ]);

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Form name="durationAndTypeForm" onChange={handleDurationAndTypeFormChange}>
                    <Panel
                        title={translateTab('panel_title.duration_and_type')}
                    >
                        <div className={cx('global!ace-u-flex')}>
                            <DateField
                                className={cx('global!ace-u-margin--right-24')}
                                name="journeyStartDate"
                                label={translateTab('date_field_label.start_journey')}
                                value={durationAndTypeData.journeyStartDate}
                                placeholder={translateTab('date_field_placeholder.please_enter')}
                                icon={calendarIcon}
                                locale={activeLocale}
                            />
                            <DateField
                                className={cx('global!ace-u-margin--right-24')}
                                name="journeyEndDate"
                                label={translateTab('date_field_label.end_journey')}
                                value={durationAndTypeData.journeyEndDate}
                                placeholder={translateTab('date_field_placeholder.please_enter')}
                                icon={calendarIcon}
                                locale={activeLocale}
                            />
                            <InputField
                                name="luggageAmount"
                                value={durationAndTypeData.luggageAmount}
                                label={translateTab('input_field_label.pieces_of_luggage')}
                            />
                        </div>
                    </Panel>
                </Form>
                <VehicleSelectPanel
                    memberVehicles={memberVehicles}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    serviceCase={serviceCase}
                />
                <div className={cx('global!ace-u-margin--top-24')}>
                    <Form name="additionalInformationForm" onChange={handleAdditionalInformationFormChange}>
                        <Panel
                            title={translateTab('panel_title.additional_information')}
                        >
                            <div className={cx('global!ace-u-flex')}>
                                <Checkbox
                                    name="isKeyAvailable"
                                    value={true}
                                    className={cx('global!ace-u-margin--right-24')}
                                    isSelected={additionalInformationData.isKeyAvailable}
                                >
                                    {translateTab(`checkbox_label.key_is_available`)}
                                </Checkbox>
                                <Checkbox
                                    name="isDriverLicenseAvailable"
                                    value={true}
                                    isSelected={additionalInformationData.isDriverLicenseAvailable}
                                >
                                    {translateTab('checkbox_label.driver_license_is_available')}
                                </Checkbox>
                            </div>
                        </Panel>
                    </Form>
                </div>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <ServiceAssignmentJourneyNotesPanel
                    serviceCase={serviceCase}
                    tabName="sasd_journey_data_tab"
                    serviceAssignment={serviceAssignment}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SASDJourneyDataTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    memberVehicles: PropTypes.array,
    submitSASDJourneyDetailsForm: PropTypes.func.isRequired,
};

SASDJourneyDataTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    memberVehicles: [],
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const memberVehiclesSelector = serviceCaseSelectors.createMemberVehiclesSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        memberVehicles: memberVehiclesSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitSASDJourneyDetailsForm: payload => dispatch({
        type: sasdActionTypes.SUBMIT_SASD_JOURNEY_DETAILS_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SASDJourneyDataTab));
