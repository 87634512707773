import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Divider} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, closedIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import styles from './FinalDestinationItem.module.scss';

const FinalDestinationItem = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_towing_destination_tab');
    const {serviceAssignment, recommendedTowingDestination} = props;

    const areWorkingHoursOpen = () => {
        // check if working hours exist
        if (!serviceAssignment?.finalDestination?.workingHoursFrom
            || !serviceAssignment?.finalDestination?.workingHoursTo
        ) return false;
        const timeRegex = /^(([0-1][0-9])|([2][0-3])):[0-5][0-9]$/;
        // check if working hours are valid
        if (!timeRegex.test(serviceAssignment.finalDestination.workingHoursFrom)
            || !timeRegex.test(serviceAssignment.finalDestination.workingHoursTo)
        ) return false;
        const now = moment();
        const [hourFrom, minuteFrom] = serviceAssignment.finalDestination.workingHoursFrom.split(':');
        const [hourTo, minuteTo] = serviceAssignment.finalDestination.workingHoursTo.split(':');
        const dateFrom = moment().set('hour', hourFrom).set('minute', minuteFrom);
        const dateTo = moment().set('hour', hourTo).set('minute', minuteTo);
        return dateFrom.isSameOrBefore(now) && dateTo.isSameOrAfter(now);
    };

    // if no finalDestination, don't render
    if (!serviceAssignment?.finalDestination) return null;
    const {finalDestination} = serviceAssignment;

    const recommendedTowingDestinationDiff = serviceAssignment.finalDestinationRoute
        && recommendedTowingDestination.routeToReferentialPoint
        ? (Math.round(serviceAssignment.finalDestinationRoute.totalKilometers * 10) / 10
            - Math.round(recommendedTowingDestination.routeToReferentialPoint.totalKilometers * 10) / 10)
        : null;

    return (
        <Fragment>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-padding--16-32',
                    'ace-c-final-destination-item',
                ])}
            >
                <p
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-margin--bottom-4',
                    ])}
                >
                    {finalDestination.location?.locationName}
                </p>
                <p className={cx('global!ace-u-typography--variant-caption')}>
                    {finalDestination.location?.formattedAddress || ''}
                </p>
                <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                    {translateTab('radio_button_label.phone_no')}
                    &nbsp;
                    {finalDestination.phoneNo}
                </p>
                <div
                    className={cx([
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-typography--color-medium-emphasis',
                        'global!ace-u-margin--4-0-8',
                    ])}
                >
                    {serviceAssignment?.finalDestinationRoute && (
                        <Fragment>
                            <span>
                                {translateTab('radio_button_label.distance_to_damage_location')}
                                &nbsp;
                                {Math.round(serviceAssignment?.finalDestinationRoute.totalKilometers * 10) / 10}
                                &nbsp;
                                {translate('global.unit.km')}
                            </span>
                            &nbsp;
                            <span className={cx('global!ace-u-typography--color-success')}>
                                ({Math.round(serviceAssignment?.finalDestinationRoute.totalTravelTime)}{translate('global.time_unit.minute')})
                            </span>
                        </Fragment>
                    )}
                    {recommendedTowingDestinationDiff !== null && (
                        <Fragment>
                            <br />
                            <span>
                                {translateTab('radio_button_label.diff_distance_to_damage_location')}
                            </span>
                            &nbsp;
                            <span
                                className={cx({
                                    'global!ace-u-typography--color-success': recommendedTowingDestinationDiff <= 0,
                                    'global!ace-u-typography--color-warning': recommendedTowingDestinationDiff > 0,
                                })}
                            >
                                {new Intl.NumberFormat('en-US', {
                                    signDisplay: 'exceptZero',
                                }).format(recommendedTowingDestinationDiff)}
                                &nbsp;
                                {translate('global.unit.km')}
                            </span>
                        </Fragment>
                    )}
                    {finalDestination.workingHoursFrom && finalDestination.workingHoursTo && (
                        <p className={cx('global!ace-u-flex', 'global!ace-u-flex--align-flex-end')}>
                            <Icon
                                icon={areWorkingHoursOpen()
                                    ? checkmarkIcon
                                    : closedIcon
                                }
                                className={cx([
                                    'ace-c-icon--s',
                                    areWorkingHoursOpen()
                                        ? 'ace-c-icon--color-success'
                                        : 'ace-c-icon--color-warning',
                                    'global!ace-u-margin--top-4',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {`${finalDestination.workingHoursFrom} - ${finalDestination.workingHoursTo} ${translate('global.time_unit.time')}`}
                        </p>
                    )}
                </div>
            </div>
            <Divider />
        </Fragment>
    );
};

FinalDestinationItem.propTypes = {
    recommendedTowingDestination: PropTypes.object,
    serviceAssignment: PropTypes.object,
};

FinalDestinationItem.defaultProps = {
    recommendedTowingDestination: {},
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(FinalDestinationItem));
