import {efDACHCountryCodes} from '@ace-de/eua-entity-types';

const isACEPartnerFromDACH = acePartner => {
    // from the APM, we are getting the country code stored in the country field
    return acePartner?.location?.countryCode || acePartner?.location?.country
        ? Object.values(efDACHCountryCodes)
            .includes(acePartner?.location?.countryCode || acePartner?.location?.country)
        // if there is no country/countryCode in the locationDTO, check in the addressDTO
        : (acePartner?.address?.countryCode || acePartner?.address?.country
            ? Object.values(efDACHCountryCodes)
                .includes(acePartner?.address?.countryCode || acePartner?.address?.country)
            : true);
};

export default isACEPartnerFromDACH;
