import {take, put} from 'redux-saga/effects';
import {ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Update SARC budget flow
 */
const updateSARCBudgetFlow = function* updateSARCBudgetFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_BUDGET_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        yield put({
            type: ROUTE_UNMOUNT_SIDE_EFFECT_COMPLETED,
            payload: {caller: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM},
        });
    }
};

export default updateSARCBudgetFlow;
