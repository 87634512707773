import React, {Fragment, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {apmACEPartnerTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, Divider, ToggleSwitch, Option} from '@ace-de/ui-components';
import {ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Form} from '@ace-de/ui-components/form';
import {AutocompleteField, InputField} from '@ace-de/ui-components/text-inputs';
import {closeIcon, InteractiveIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as sarcActionTypes from '../sarcActionTypes';

const SARCUpdateACEPartnerStationModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('sarc_select_ace_partner_station_modal');
    const {hasBackdrop, serviceAssignment, acePartners} = props;
    const {declineSARCUpdateACEPartnerStation, confirmSARCUpdateACEPartnerStation} = props;
    const {initiateSARCGetACEPartnerStationsFlow} = props;
    const [selectedACEPartner, setSelectedACEPartner] = useState(null);
    const [selectedRentalCarStationPickup, setSelectedRentalCarStationPickup] = useState(null);
    const [selectedRentalCarStationDropOff, setSelectedRentalCarStationDropOff] = useState(null);
    const [selectedSippCode, setSelectedSippCode] = useState('');
    const [formData, setFormData] = useState({
        acePartner: '',
        acePartnerToggle: false,
        rentalCarStationPickup: '',
        rentalCarStationPickupToggle: false,
        rentalCarStationDropOff: '',
        rentalCarStationDropOffToggle: false,
        sippCode: '',
    });

    const sortedACEPartnersServiceProviders = useMemo(() => (
        Object.values(acePartners).sort((acePartnerA, acePartnerB) => {
            const acePartnerAName = (acePartnerA.name || '').toLowerCase();
            const acePartnerBName = (acePartnerB.name || '').toLowerCase();
            return acePartnerAName.localeCompare(acePartnerBName);
        }).filter((partner, i, arr) => (!i || partner.name !== arr[i - 1].name))
    ), [acePartners]);

    const acePartnersServiceProviderList = useMemo(() => (
        sortedACEPartnersServiceProviders.filter(acePartner => acePartner.isSpecial
            && acePartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE)
            .concat(sortedACEPartnersServiceProviders.filter(acePartner => !acePartner.isSpecial
                && acePartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE))
    ), [sortedACEPartnersServiceProviders]);

    const handleOnOptionSelect = (key, option) => {
        switch (key) {
            case 'rentalCarStationPickup': {
                if (selectedACEPartner) {
                    const rentalCarStations = acePartners[selectedACEPartner.id]?.rentalCarStations || [];
                    if (rentalCarStations[option]) {
                        setSelectedRentalCarStationPickup(rentalCarStations[option]);
                    }
                }
                break;
            }

            case 'rentalCarStationDropOff': {
                if (selectedACEPartner) {
                    const rentalCarStations = acePartners[selectedACEPartner.id]?.rentalCarStations || [];
                    if (rentalCarStations[option]) {
                        setSelectedRentalCarStationDropOff(rentalCarStations[option]);
                    }
                }
                break;
            }

            case 'acePartner': {
                const [newACEPartner] = Object.values(acePartners)
                    .filter(acePartner => acePartner.name.includes(option));
                if (newACEPartner) {
                    setSelectedACEPartner(newACEPartner);
                }
                break;
            }

            case 'sippCode': {
                if (option) {
                    setSelectedSippCode(option);
                }
                break;
            }

            default: {
                // no-op
            }
        }
    };

    const handleOnChange = formValues => {
        let newACEPartner = {};

        if (formValues.acePartner.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            // catch the selected acePartner
            newACEPartner = Object.values(acePartners)
                .filter(acePartner => acePartner.id)
                .find(acePartner => acePartner.name.includes(formValues.acePartner));

            // update selectedACEPartner if needed
            if (newACEPartner && newACEPartner.id !== selectedACEPartner?.id) {
                setSelectedACEPartner(newACEPartner);
            }

            // load rental car stations only if they are not exist
            if (newACEPartner && newACEPartner.partnerType
                && [apmACEPartnerTypes.CONTRACT_PARTNER,
                    apmACEPartnerTypes.SERVICE_PROVIDER].includes(newACEPartner.partnerType)
                && !newACEPartner.rentalCarStations?.length) {
                initiateSARCGetACEPartnerStationsFlow({
                    acePartnerId: newACEPartner.id,
                    partnerType: newACEPartner.partnerType,
                });
            }

            // if user edits selected acePartner, disable and clear all other inputs & clear selectedACEPartner
            if (formValues.acePartner.length !== selectedACEPartner?.name.length
                || !!formValues['acePartnerToggle']) {
                setSelectedACEPartner(null);
                setSelectedRentalCarStationPickup(null);
                setSelectedRentalCarStationDropOff(null);
                setFormData({
                    ...formData,
                    acePartnerToggle: !!formValues['acePartnerToggle'],
                    rentalCarStationPickupToggle: !!formValues['rentalCarStationPickupToggle'],
                    rentalCarStationDropOffToggle: !!formValues['rentalCarStationDropOffToggle'],
                    rentalCarStationPickup: '',
                    rentalCarStationDropOff: '',
                    sippCode: '',
                    ...(formValues['acePartnerToggle'] ? {acePartner: ''} : {}),
                });
                return;
            }

            // if user edits selected rentalCarStationPickup, clear selectedRentalCarStationPickup
            if (formValues.rentalCarStationPickup !== `${selectedRentalCarStationPickup?.id}`
                || !!formValues['rentalCarStationPickupToggle']) {
                setSelectedRentalCarStationPickup(null);
            }

            // if user edits selected rentalCarStationDropOff, clear selectedRentalCarStationDropOff
            if (formValues.rentalCarStationDropOff !== `${selectedRentalCarStationDropOff?.id}`
                || !!formValues['rentalCarStationDropOffToggle']) {
                setSelectedRentalCarStationDropOff(null);
            }

            // if user edits selected sippCode, clear selectedSippCode
            if (formValues.sippCode.length !== selectedSippCode?.length) {
                setSelectedSippCode('');
            }

            setFormData({
                ...formValues,
                acePartnerToggle: !!formValues['acePartnerToggle'],
                rentalCarStationPickupToggle: !!formValues['rentalCarStationPickupToggle'],
                rentalCarStationDropOffToggle: !!formValues['rentalCarStationDropOffToggle'],
                ...(formValues['acePartnerToggle'] ? {acePartner: ''} : {}),
                ...(formValues['rentalCarStationPickupToggle'] ? {rentalCarStationPickup: ''} : {}),
                ...(formValues['rentalCarStationDropOffToggle'] ? {rentalCarStationDropOff: ''} : {}),
            });
            return;
        }

        if (!formValues.acePartner.length) {
            setSelectedACEPartner(null);
            setSelectedRentalCarStationPickup(null);
            setSelectedRentalCarStationDropOff(null);

            setFormData({
                ...formValues,
                rentalCarStationPickup: '',
                rentalCarStationDropOff: '',
                sippCode: '',
                acePartnerToggle: !!formValues['acePartnerToggle'],
                rentalCarStationPickupToggle: !!formValues['rentalCarStationPickupToggle'],
                rentalCarStationDropOffToggle: !!formValues['rentalCarStationDropOffToggle'],
                ...(formValues['acePartnerToggle'] ? {acePartner: ''} : {}),
            });
            return;
        }

        setFormData({
            ...formValues,
            rentalCarStationPickup: '',
            rentalCarStationDropOff: '',
            acePartnerToggle: !!formValues['acePartnerToggle'],
            rentalCarStationPickupToggle: !!formValues['rentalCarStationPickupToggle'],
            rentalCarStationDropOffToggle: !!formValues['rentalCarStationDropOffToggle'],
            ...(formValues['acePartnerToggle'] ? {acePartner: ''} : {}),
        });
    };

    const handleConfirmSelection = () => {
        const {acePartnerData, rentalCarStationPickupData, rentalCarStationDropOffData, sippCode} = formData;

        const serviceAssignmentData = {
            acePartner: formData['acePartnerToggle']
                ? {
                    ...acePartnerData,
                    // if ACEPartner is manually entered, overwrite businessContactDetails
                    // see ACEECS-3367: https://computerrock.atlassian.net/browse/ACEECS-3367
                    businessContactDetails: {
                        phoneNo: '',
                        faxNo: '',
                        email: '',
                    },
                } : selectedACEPartner,
            rentalCarStationPickup: formData['rentalCarStationPickupToggle']
                ? rentalCarStationPickupData : selectedRentalCarStationPickup,
            rentalCarStationDropOff: formData['rentalCarStationDropOffToggle']
                ? rentalCarStationDropOffData || null
                : selectedRentalCarStationDropOff || null,
            sippCode,
        };

        confirmSARCUpdateACEPartnerStation({
            serviceAssignmentData,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            isRentalCarStationPickupEnteredManually: !!formData['rentalCarStationPickupToggle'],
            numberOfDays: serviceAssignment.numberOfDays,
        });
    };

    const rentalCarStations = selectedACEPartner && acePartners[selectedACEPartner.id]?.rentalCarStations;
    const rentalCarSIPPCodes = selectedACEPartner && acePartners?.[selectedACEPartner.id]?.rentalCarSIPPCodes
        ? acePartners?.[selectedACEPartner.id]?.rentalCarSIPPCodes : [];
    const isACEPartnerEnteredManually = !!(formData.acePartnerData && formData.acePartnerData.name
        && formData.acePartnerData.location?.street
        && formData.acePartnerData.location?.city
        && formData.acePartnerData.location?.postCode);
    const isRentalCarStationPickupEnteredManually = !!(formData.rentalCarStationPickupData
        && formData.rentalCarStationPickupData.name
        && formData.rentalCarStationPickupData.location?.street
        && formData.rentalCarStationPickupData.location?.city
        && formData.rentalCarStationPickupData.location?.postCode);
    const isButtonDisabled = (!selectedACEPartner && !(!!formData.acePartnerToggle && isACEPartnerEnteredManually))
        || (!selectedRentalCarStationPickup && !(
            !!formData.rentalCarStationPickupToggle
            && isRentalCarStationPickupEnteredManually
        ));

    return (
        <Modal
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--m'])}
            title={translateModal('header.title')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineSARCUpdateACEPartnerStation}
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form
                    name="acePartnerStationSelectionForm"
                    onChange={handleOnChange}
                >
                    <Fragment>
                        <div
                            className={cx([
                                'global!ace-u-grid',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-margin--right-24',
                                    'global!ace-u-grid-column--span-4',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-margin--bottom-16',
                                        'global!ace-u-typography--variant-body-bold',
                                    ])}
                                >
                                    {translateModal('text.providers')}
                                </p>
                                <AutocompleteField
                                    name="acePartner"
                                    className={cx('global!ace-u-margin--bottom-24')}
                                    value={formData.acePartner || ''}
                                    label={translateModal('input_label.provider_selection')}
                                    placeholder={translate('global.select.placeholder')}
                                    icon={arrowDownIcon}
                                    onOptionSelect={value => handleOnOptionSelect('acePartner', value)}
                                    isDisabled={!!formData.acePartnerToggle}
                                >
                                    {acePartnersServiceProviderList.map(acePartner => (
                                        <Option
                                            key={`acePartner-${acePartner.id}`}
                                            name={`acePartner-${acePartner.id}`}
                                            value={acePartner.name}
                                            isDisabled={
                                                serviceAssignment.listOfPreviousPartners?.includes(acePartner.id)
                                            }
                                        >
                                            {acePartner.name}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                                <ToggleSwitch
                                    name="acePartnerToggle"
                                    value={true}
                                    isSelected={formData.acePartnerToggle}
                                    className={cx('global!ace-u-margin--bottom-24')}
                                >
                                    {translateModal('toggle_switch_label.enter_provider_manually')}
                                </ToggleSwitch>
                                {formData.acePartnerToggle
                                    && (
                                        <Form name="acePartnerData">
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                ])}
                                            >
                                                <InputField
                                                    name="name"
                                                    label={translateModal('input_label.name')}
                                                    value=""
                                                />
                                                <Form name="location">
                                                    <InputField
                                                        name="street"
                                                        className={cx('global!ace-u-margin--32-0')}
                                                        label={translateModal('input_label.street_and_housenumber')}
                                                        value=""
                                                    />
                                                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                                        <InputField
                                                            name="postCode"
                                                            className={cx('global!ace-u-grid-column--span-3')}
                                                            label={translateModal('input_label.post_code')}
                                                            value=""
                                                        />
                                                        <InputField
                                                            name="city"
                                                            className={cx('global!ace-u-grid-column--span-9')}
                                                            label={translateModal('input_label.city')}
                                                            value=""
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        </Form>
                                    )
                                }
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-margin--right-24',
                                    'global!ace-u-grid-column--span-4',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-margin--bottom-16',
                                        'global!ace-u-typography--variant-body-bold',
                                    ])}
                                >
                                    {translateModal('text.pick_up_station')}
                                </p>
                                <AutocompleteField
                                    name="rentalCarStationPickup"
                                    className={cx('global!ace-u-margin--bottom-24')}
                                    value={formData.rentalCarStationPickup || ''}
                                    label={translateModal('input_label.pick_up_selection')}
                                    placeholder={translate('global.select.placeholder')}
                                    icon={arrowDownIcon}
                                    isDisabled={!selectedACEPartner || !!formData.rentalCarStationPickupToggle}
                                    onOptionSelect={value => handleOnOptionSelect('rentalCarStationPickup', value)}
                                >
                                    {Object.values(rentalCarStations || {}).map(station => (
                                        <Option
                                            key={`rentalCarStationPickup-${station.id}`}
                                            name={`rentalCarStationPickup-${station.id}`}
                                            value={`${station.id}`}
                                        >
                                            {`${station.name}, ${station.location.postCode}, ${station.location.city}`}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                                <ToggleSwitch
                                    name="rentalCarStationPickupToggle"
                                    value={true}
                                    isSelected={formData?.rentalCarStationPickupToggle}
                                    className={cx('global!ace-u-margin--bottom-24')}
                                >
                                    {translateModal('toggle_switch_label.enter_station_manually')}
                                </ToggleSwitch>
                                {formData.rentalCarStationPickupToggle
                                    && (
                                        <Form name="rentalCarStationPickupData">
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                ])}
                                            >
                                                <InputField
                                                    name="name"
                                                    value=""
                                                    label={translateModal('input_label.name')}
                                                />
                                                <Form name="location">
                                                    <InputField
                                                        name="street"
                                                        value=""
                                                        className={cx('global!ace-u-margin--32-0')}
                                                        label={translateModal('input_label.street_and_housenumber')}
                                                    />
                                                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                                        <InputField
                                                            name="postCode"
                                                            value=""
                                                            className={cx('global!ace-u-grid-column--span-3')}
                                                            label={translateModal('input_label.post_code')}
                                                        />
                                                        <InputField
                                                            name="city"
                                                            value=""
                                                            className={cx('global!ace-u-grid-column--span-9')}
                                                            label={translateModal('input_label.city')}
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        </Form>
                                    )
                                }
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-margin--right-24',
                                    'global!ace-u-grid-column--span-4',
                                ])}
                            >
                                <p
                                    className={cx([
                                        'global!ace-u-margin--bottom-16',
                                        'global!ace-u-typography--variant-body-bold',
                                    ])}
                                >
                                    {translateModal('text.drop_off_station')}
                                </p>
                                <AutocompleteField
                                    name="rentalCarStationDropOff"
                                    className={cx('global!ace-u-margin--bottom-24')}
                                    value={formData.rentalCarStationDropOff || ''}
                                    label={translateModal('input_label.delivery_selection')}
                                    placeholder={translate('global.select.placeholder')}
                                    icon={arrowDownIcon}
                                    isDisabled={!selectedACEPartner || !!formData.rentalCarStationDropOffToggle}
                                    onOptionSelect={value => handleOnOptionSelect('rentalCarStationDropOff', value)}
                                >
                                    {Object.values(rentalCarStations || {}).map(station => (
                                        <Option
                                            key={`rentalCarStationDropOff-${station.id}`}
                                            name={`rentalCarStationDropOff-${station.id}`}
                                            value={`${station.id}`}
                                        >
                                            {`${station.name}, ${station.location.postCode}, ${station.location.city}`}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                                <ToggleSwitch
                                    name="rentalCarStationDropOffToggle"
                                    value={true}
                                    isSelected={formData.rentalCarStationDropOffToggle}
                                    className={cx('global!ace-u-margin--bottom-24')}
                                >
                                    {translateModal('toggle_switch_label.enter_station_manually')}
                                </ToggleSwitch>
                                {formData.rentalCarStationDropOffToggle
                                    && (
                                        <Form name="rentalCarStationDropOffData">
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                ])}
                                            >
                                                <InputField
                                                    name="name"
                                                    label={translateModal('input_label.name')}
                                                    value=""
                                                />
                                                <Form name="location">
                                                    <InputField
                                                        name="street"
                                                        className={cx('global!ace-u-margin--32-0')}
                                                        label={translateModal('input_label.street_and_housenumber')}
                                                        value=""
                                                    />
                                                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                                                        <InputField
                                                            name="postCode"
                                                            className={cx('global!ace-u-grid-column--span-3')}
                                                            label={translateModal('input_label.post_code')}
                                                            value=""
                                                        />
                                                        <InputField
                                                            name="city"
                                                            className={cx('global!ace-u-grid-column--span-9')}
                                                            label={translateModal('input_label.city')}
                                                            value=""
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        </Form>
                                    )
                                }
                            </div>
                        </div>
                        <Divider />
                        <p
                            className={cx([
                                'global!ace-u-margin--top-24',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateModal('text.sipp_code')}
                        </p>
                        <div
                            className={cx([
                                'global!ace-u-grid',
                                'global!ace-u-margin--top-8',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-margin--right-24',
                                    'global!ace-u-grid-column--span-4',
                                ])}
                            >
                                <AutocompleteField
                                    name="sippCode"
                                    className={cx('global!ace-u-margin--bottom-24')}
                                    value={formData.sippCode || ''}
                                    label={translateModal('select_field.sipp_code')}
                                    placeholder={translate('global.select.placeholder')}
                                    icon={arrowDownIcon}
                                    isDisabled={!selectedACEPartner || (
                                        selectedACEPartner
                                        && selectedACEPartner.partnerType === apmACEPartnerTypes.CONTRACT_PARTNER
                                    )}
                                    onOptionSelect={value => handleOnOptionSelect('sippCode', value)}
                                >
                                    {rentalCarSIPPCodes?.map(sippCode => (
                                        <Option
                                            key={`sippCode-${sippCode}`}
                                            name={`sippCode-${sippCode}`}
                                            value={sippCode}
                                        >
                                            {sippCode}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                            </div>
                        </div>
                        <Divider />
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                        >
                            <ButtonPrimary
                                name="confirmModalButton"
                                className={cx('global!ace-u-margin--top-16')}
                                onClick={handleConfirmSelection}
                                isDisabled={isButtonDisabled}
                            >
                                {translateModal('button_label.confirm_selection')}
                            </ButtonPrimary>
                        </div>
                    </Fragment>
                </Form>
            </div>
        </Modal>
    );
};

SARCUpdateACEPartnerStationModal.propTypes = {
    declineSARCUpdateACEPartnerStation: PropTypes.func.isRequired,
    confirmSARCUpdateACEPartnerStation: PropTypes.func.isRequired,
    initiateSARCGetACEPartnerStationsFlow: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceAssignment: PropTypes.object,
    acePartners: PropTypes.object,
};

SARCUpdateACEPartnerStationModal.defaultProps = {
    hasBackdrop: true,
    serviceAssignment: null,
    acePartners: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
        acePartners: serviceAssignmentSelectors.getACEPartners(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineSARCUpdateACEPartnerStation: payload => dispatch({
        type: sarcActionTypes.DECLINE_SARC_UPDATE_ACE_PARTNER_STATION,
        payload,
    }),
    confirmSARCUpdateACEPartnerStation: payload => dispatch({
        type: sarcActionTypes.CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION,
        payload,
    }),
    initiateSARCGetACEPartnerStationsFlow: payload => dispatch({
        type: sarcActionTypes.FETCH_SARC_ACE_PARTNER_STATIONS_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SARCUpdateACEPartnerStationModal);
