import {produce} from 'immer';
import * as applicationActionTypes from './applicationActionTypes';
import initialRequestStates from './initialRequestStates';

const initialState = {
    requestStates: initialRequestStates,
};

/**
 * Request state reducer
 *
 * @param state
 * @param action
 * @return {Object}
 */
const requestStateReducer = produce((draft, action) => {
    switch (action.type) {
        case applicationActionTypes.SET_REQUEST_STATE: {
            const {actionType, requestState} = action.payload;
            if (!actionType || !requestState) return;
            if (!draft.requestStates.hasOwnProperty(actionType)) return;
            draft.requestStates[actionType] = requestState;
            break;
        }

        default:
            // no-op
    }
    return draft;
}, initialState);

export default requestStateReducer;
