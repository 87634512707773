import React, {Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, useScrollableBlock, Divider, RadioButton} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, closedIcon} from '@ace-de/ui-components/icons';

const DoctorLocationItem = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sama_service_provider_tab');
    const {doctorLocation, isSelected} = props;
    const prevIsSelectedRef = useRef(false);
    const doctorLocationItemRef = useRef();
    const {scrollToChildRef} = useScrollableBlock();

    // on isSelected, scroll ScrollableBlock to element ref
    useEffect(() => {
        if (isSelected === true && prevIsSelectedRef.current === false) {
            scrollToChildRef(doctorLocationItemRef);
        }
        prevIsSelectedRef.current = isSelected;
    }, [isSelected, scrollToChildRef]);

    const displayWorkingHours = workingHours => {
        if (!workingHours) return '';
        const {today, isAlwaysOpen, isOpen} = workingHours;
        let formattedWorkingHours = translateTab(`radio_button_label.${isOpen ? 'open' : 'closed'}`);

        if (isAlwaysOpen) {
            formattedWorkingHours += ` 24 ${translate('global.time_unit.time')}`;
        }

        if (!isAlwaysOpen) {
            formattedWorkingHours += (today !== ''
                ? `, ${translate('global.time_unit.today')} ${today}`
                : '');
        }

        return formattedWorkingHours;
    };

    return (
        <Fragment>
            <div
                ref={doctorLocationItemRef}
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-flex-start',
                    'global!ace-u-margin--16-0',
                ])}
            >
                <RadioButton
                    id={`radio-button-${doctorLocation.id}`}
                    name={`radio-button-${doctorLocation.id}`}
                    value={doctorLocation.id}
                />
                <label
                    htmlFor={`radio-button-${doctorLocation.id}`}
                    className={cx([
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-cursor--pointer',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <p className={cx('global!ace-u-margin--bottom-4')}>
                        {doctorLocation.locationName}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {doctorLocation.formattedAddress}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-margin--4-0')}>
                        {translateTab('radio_button_label.phone_no')}
                        &nbsp;
                        {doctorLocation.phoneNo || ''}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption')}>
                        {translateTab('radio_button_label.distance')}
                        &nbsp;
                        {Math.round(doctorLocation.routeToReferentialPoint?.totalKilometers * 10) / 10}
                        &nbsp;
                        {translate('global.unit.km')}
                    </p>
                    <p className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-flex', 'global!ace-u-flex--align-flex-end')}>
                        <Icon
                            icon={
                                doctorLocation.workingHours?.isOpen
                                    ? checkmarkIcon
                                    : closedIcon
                            }
                            className={cx([
                                'ace-c-icon--s',
                                doctorLocation.workingHours?.isOpen
                                    ? 'ace-c-icon--color-success'
                                    : 'ace-c-icon--color-warning',
                                'global!ace-u-margin--top-4',
                                'global!ace-u-margin--right-8',
                            ])}
                        />
                        {displayWorkingHours(doctorLocation.workingHours)}
                    </p>

                </label>
            </div>
            <Divider />
        </Fragment>
    );
};

DoctorLocationItem.propTypes = {
    doctorLocation: PropTypes.object,
    isSelected: PropTypes.bool,
};

DoctorLocationItem.defaultProps = {
    doctorLocation: {},
    isSelected: false,
};

export default DoctorLocationItem;
