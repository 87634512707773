import React, {Fragment} from 'react';
import {useTranslate} from '@computerrock/formation-i18n';

const NotFoundScreen = () => {
    const {translate} = useTranslate();

    return (
        <Fragment>
            {translate('not_found_screen.label.page_not_found')}
        </Fragment>
    );
};

export default NotFoundScreen;
