import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, Divider, Form, SelectField, Option, RichTextAreaField, ButtonPrimary} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon} from '@ace-de/ui-components/icons';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import config from '../../config';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as caseLogActionTypes from '../caseLogActionTypes';

const UpdateCaseLogNoteModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const {serviceCase, serviceAssignments, serviceCaseLogNote, confirmUpdateCaseLogNote} = props;
    const {declineUpdateCaseLogNote, hasBackdrop} = props;

    const translateUpdateModal = createTranslateShorthand('update_case_log_note_modal');
    const [formData, setFormData] = useState({
        ...(serviceCaseLogNote && serviceCaseLogNote.payload),
        noteTopic: serviceCaseLogNote ? serviceCaseLogNote.lineNo?.toString() : '',
    });
    const [richTextAreaContent, setRichTextAreaContent] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (serviceCaseLogNote) {
            const {payload: noteDetails} = serviceCaseLogNote;
            const contentState = stateFromHTML(noteDetails.note);
            setRichTextAreaContent(
                EditorState.createWithContent(
                    contentState,
                ),
            );
        }
    }, [serviceCaseLogNote]);

    const handleOnChange = formValues => {
        setFormData(formValues);
    };

    const handleOnSubmit = () => {
        const html = stateToHTML(richTextAreaContent.getCurrentContent());
        const caseLogNoteData = {
            note: html,
        };
        confirmUpdateCaseLogNote({caseLogNoteData});
    };

    return (
        <Modal
            contentClassName={cx('global!ace-u-modal-content-size--l')}
            title={translateUpdateModal('header.title')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineUpdateCaseLogNote}
                />
             )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form
                    name="editCaseLogNoteForm"
                    onChange={handleOnChange}
                >

                    <Fragment>
                        <div className={cx('global!ace-u-margin--top-32')} />
                        <Divider />
                        <div
                            className={cx([
                                'global!ace-u-grid',
                                'global!ace-u-margin--24-0-48',
                            ])}
                        >
                            <SelectField
                                name="noteTopic"
                                className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-margin--24-0-48')}
                                label={translateUpdateModal('select_field_label.assignment')}
                                value={formData?.noteTopic || ''}
                                isDisabled={true}
                            >
                                {(serviceCase?.serviceAssignmentIds || []).map(serviceAssignmentId => {
                                    const serviceAssignment = serviceAssignments[serviceAssignmentId];
                                    const {serviceType, assignmentType} = serviceAssignment;
                                    const serviceAssignmentType = serviceType
                                                && assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES
                                        ? translate(`global.service_type.${serviceType.toLowerCase()}`)
                                        : translate(`global.service_type.${assignmentType.toLowerCase()}`);
                                    const serviceCaseCreationDate = `${moment(serviceAssignment?.createdAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`;

                                    return (
                                        <Option
                                            name={`serviceAssignment${serviceAssignment.lineNo}`}
                                            key={`serviceAssignment${serviceAssignment.lineNo}`}
                                            value={serviceAssignment.lineNo}
                                        >
                                            {`${serviceAssignmentType} (${serviceCaseCreationDate})`}
                                        </Option>
                                    );
                                })}
                                <Option
                                    name="other"
                                    value=""
                                >
                                    {translateUpdateModal('assignment_option.other')}
                                </Option>
                            </SelectField>
                            <RichTextAreaField
                                label={translateUpdateModal('text_area_field_label.notice')}
                                maxLength={config.CASE_LOG_NOTE_MAX_LENGTH}
                                setEditorState={value => setRichTextAreaContent(value)}
                                editorState={richTextAreaContent}
                                className={cx('global!ace-u-grid-column--span-12')}
                                contentClassName={cx('ace-c-text-area--medium')}
                                locale={activeLocale}
                            />
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                        >
                            <ButtonPrimary
                                name="confirmModalButton"
                                className={cx('global!ace-u-margin--top-32')}
                                onClick={handleOnSubmit}
                                isDisabled={!formData?.note}
                            >
                                {translateUpdateModal('button_label.save_message')}
                            </ButtonPrimary>
                        </div>
                    </Fragment>
                </Form>
            </div>
        </Modal>
    );
};

UpdateCaseLogNoteModal.propTypes = {
    declineUpdateCaseLogNote: PropTypes.func.isRequired,
    confirmUpdateCaseLogNote: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    serviceCaseLogNote: PropTypes.object,
};

UpdateCaseLogNoteModal.defaultProps = {
    serviceCase: null,
    serviceAssignments: null,
    hasBackdrop: true,
    serviceCaseLogNote: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const getServiceCaseLogNote = serviceCaseSelectors.createServiceCaseLogNoteSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignments: serviceCaseSelectors.getServiceAssignments(state, props),
        serviceCaseLogNote: getServiceCaseLogNote(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineUpdateCaseLogNote: () => dispatch({type: caseLogActionTypes.DECLINE_UPDATE_CASE_LOG_NOTE}),
    confirmUpdateCaseLogNote: payload => dispatch({type: caseLogActionTypes.CONFIRM_UPDATE_CASE_LOG_NOTE, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCaseLogNoteModal);
