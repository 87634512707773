import {take, call, select} from 'redux-saga/effects';
import {efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import updateServiceCase from '../../service-cases/sagas/updateServiceCase';
import updateServiceAssignment from './updateServiceAssignment';
import updateServiceAssignmentStatus from './updateServiceAssignmentStatus';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import sendAttachmentsWithAssignmentFlow from '../../service-cases/sagas/sendAttachmentsWithAssignmentFlow';
import modalIds from '../../modalIds';

/**
 * Update SA Commissioning ace partner data flow
 */
const updateCommissioningACEPartnerDataFlow = function* updateCommissioningACEPartnerDataFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM);
            payloadData = payload;
        }

        if (!payloadData) continue;

        const {serviceAssignmentLineNo, assignmentType, serviceAssignmentData} = payloadData;
        const {serviceCaseData, serviceCaseType, serviceCaseId} = payloadData;
        const {attachmentsToSendWithAssignment} = payloadData;

        yield call(sendAttachmentsWithAssignmentFlow, {
            caller: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
            attachmentsToSendWithAssignment,
            serviceCaseId,
        });

        yield* updateServiceAssignment({
            caller: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
            assignmentType,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        if (serviceCaseData) {
            yield* updateServiceCase({
                caller: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
                caseType: serviceCaseType,
                serviceCaseId,
                serviceCaseData,
            });
        }

        const updateServiceAssignmentStatusResponseAction = yield* updateServiceAssignmentStatus({
            caller: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_STATUS_CHANGE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentStatusData: {
                assignmentStatus: efServiceAssignmentStatusTypes.ASSIGNED,
            },
        });

        if (updateServiceAssignmentStatusResponseAction.error) {
            const {status} = updateServiceAssignmentStatusResponseAction.payload.response;

            yield* openModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {
                errorCode: status ? `${status}` : '',
                requestedAction: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_STATUS_CHANGE
                    .toLowerCase(),
            });

            const chosenModalOption = yield take([
                serviceAssignmentActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_ACE_PARTNER,
                serviceAssignmentActionTypes.DECLINE_RESUBMITTING_COMMISSIONING_ACE_PARTNER,
            ]);

            if (chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_RESUBMITTING_COMMISSIONING_ACE_PARTNER) { // eslint-disable-line max-len
                shouldWaitForAction = false;
                continue;
            }
        }

        const {location} = yield select(state => state.router);
        if (location?.query?.modal && location.query.modal === modalIds.FAILED_SERVICE_ERROR_MESSAGE) {
            yield* closeModal(modalIds.FAILED_SERVICE_ERROR_MESSAGE, {errorCode: '', requestedAction: ''});
        }

        shouldWaitForAction = true;
    }
};

export default updateCommissioningACEPartnerDataFlow;
