import {ehmCaseLogCategoryTypes} from '@ace-de/eua-entity-types';

const caseLogFilterCategories = {
    'STATUS_CHANGE': [
        ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE,
        ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
        ehmCaseLogCategoryTypes.CASE_DATA_CHANGE,
    ],
    [ehmCaseLogCategoryTypes.NOTE]: [ehmCaseLogCategoryTypes.NOTE],
    [ehmCaseLogCategoryTypes.ASSIGNMENT]: [
        ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
        ehmCaseLogCategoryTypes.ASSIGNMENT,
    ],
    [ehmCaseLogCategoryTypes.TASK]: [ehmCaseLogCategoryTypes.TASK],
    [ehmCaseLogCategoryTypes.MESSAGE]: [ehmCaseLogCategoryTypes.MESSAGE],
    [ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK]: [ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK],
    [ehmCaseLogCategoryTypes.DRIVER_APP]: [ehmCaseLogCategoryTypes.DRIVER_APP],
};

export default caseLogFilterCategories;
