/**
 * @typedef {string} tabId
 */

/**
 * SAP screen tabs
 *
 * @enum {tabId}
 */
export default {
    REQUIREMENTS: 'requirements',
    SERVICE_PROVIDER: 'service-provider',
    COST_COVERAGE: 'cost-coverage',
};
