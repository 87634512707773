import {take, call} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import modalIds from '../../modalIds';
import updateServiceAssignment from './updateServiceAssignment';
import deleteServiceAssignmentQMFeedbackDraft from './deleteServiceAssignmentQMFeedbackDraft';

const updateACEPartnerFlow = function* updateACEPartnerFlow() {
    while (true) {
        yield take(serviceAssignmentActionTypes.INITIATE_UPDATE_ACE_PARTNER);

        yield* openModal(modalIds.ACE_PARTNER_DATA);

        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_UPDATE_ACE_PARTNER,
            serviceAssignmentActionTypes.DECLINE_UPDATE_ACE_PARTNER,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_UPDATE_ACE_PARTNER) {
            const {
                serviceAssignmentLineNo,
                serviceAssignmentData,
                serviceCaseId,
                assignmentType,
                qmFeedbackDraftId,
                qmFeedbackDraftPlaceholderId,
            } = chosenModalOption.payload;

            yield* updateServiceAssignment({
                caller: serviceAssignmentActionTypes.CONFIRM_UPDATE_ACE_PARTNER,
                assignmentType,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData,
            });

            if (qmFeedbackDraftId) {
                yield call(deleteServiceAssignmentQMFeedbackDraft, {
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    qmFeedbackDraftId,
                });
            }
            if (qmFeedbackDraftPlaceholderId) {
                yield call(deleteServiceAssignmentQMFeedbackDraft, {
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    qmFeedbackDraftId: qmFeedbackDraftPlaceholderId,
                });
            }
        }

        yield* closeModal(modalIds.ACE_PARTNER_DATA);
    }
};

export default updateACEPartnerFlow;
