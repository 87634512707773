import {take} from 'redux-saga/effects';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import creditGeocodedLocation from '../../service-cases/sagas/creditGeocodedLocation';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarcActionTypes from '../sarcActionTypes';

/**
 * Update SARC member location flow
 */
const updateSARCMemberLocationFlow = function* updateSARCMemberLocationFlow() {
    while (true) {
        const {payload} = yield take(sarcActionTypes.SUBMIT_SARC_MEMBER_LOCATION_FORM);
        const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = payload;

        yield* updateServiceAssignment({
            caller: sarcActionTypes.SUBMIT_SARC_MEMBER_LOCATION_FORM,
            assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData,
        });

        // credit stored location
        yield* creditGeocodedLocation({location: serviceAssignmentData.memberLocation});
    }
};

export default updateSARCMemberLocationFlow;
