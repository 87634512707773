import {take, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {NotePayload} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as caseLogActionTypes from '../caseLogActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const updateCaseLogNoteFlow = function* updateCaseLogNoteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');

    while (true) {
        const {payload} = yield take(caseLogActionTypes.INITIATE_UPDATE_CASE_LOG_FLOW);
        const {eventDate, eventTime, pathParams} = payload;

        yield* openModal(modalIds.UPDATE_CASE_LOG_NOTE, {eventDate, eventTime});

        const chosenModalOption = yield take([
            caseLogActionTypes.CONFIRM_UPDATE_CASE_LOG_NOTE,
            caseLogActionTypes.DECLINE_UPDATE_CASE_LOG_NOTE,
        ]);

        if (chosenModalOption.type === caseLogActionTypes.CONFIRM_UPDATE_CASE_LOG_NOTE) {
            const {caseLogNoteData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                caseLogActionTypes.UPDATE_CASE_LOG_NOTE_REQUEST,
                ecsHistoryManagementService.updateCaseLogNote,
                {
                    serviceCaseId: pathParams.serviceCaseId,
                    eventDate,
                    eventTime,
                    noteData: NotePayload.objectToPatchDTO(caseLogNoteData),
                },
            );

            const updateCaseNoteResponseAction = yield take([
                caseLogActionTypes.UPDATE_CASE_LOG_NOTE_REQUEST_SUCCEEDED,
                caseLogActionTypes.UPDATE_CASE_LOG_NOTE_REQUEST_FAILED,
            ]);

            if (updateCaseNoteResponseAction.error) {
                // no-op
            }
        }

        yield* closeModal(modalIds.UPDATE_CASE_LOG_NOTE, {eventDate, eventTime});
    }
};

export default updateCaseLogNoteFlow;
