import {put, select, take, fork, all, call} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {apmACEPartnerServiceTypes, efServiceAssignmentTypes, efOtherServiceTypes, apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';
import saosScreenTabs from '../../service-assignments-other-services/saosScreenTabs';
import calculateSASDServiceProviderRouteDistance from '../../service-assignments-substitute-driver/sagas/calculateSASDServiceProviderRouteDistance';

const getServiceProviderPayloadData = serviceAssignment => {
    if (!serviceAssignment) return;

    switch (serviceAssignment.assignmentType) {
        case efServiceAssignmentTypes.OTHER_SERVICES: {
            return (serviceAssignment.serviceType === efOtherServiceTypes.OTHER_SERVICE
                ? {partnerType: [apmACEPartnerTypes.CONTRACT_PARTNER, apmACEPartnerTypes.SERVICE_PROVIDER]}
                : {serviceType: serviceAssignment.serviceType});
        }

        case efServiceAssignmentTypes.VEHICLE_RETURN:
        case efServiceAssignmentTypes.TRAILER_RETURN: {
            return {serviceType: apmACEPartnerServiceTypes.VEHICLE_RETURN_SERVICE};
        }

        case efServiceAssignmentTypes.MEDICAL_CLEARANCE: {
            return {
                serviceType: apmACEPartnerServiceTypes.MEDICAL_CLEARANCE,
                includeNumberOfAssignments: true,
            };
        }

        case efServiceAssignmentTypes.RETURN_JOURNEY: {
            return {serviceType: apmACEPartnerServiceTypes.RETURN_JOURNEY};
        }

        case efServiceAssignmentTypes.SICK_PERSON: {
            return {serviceType: apmACEPartnerServiceTypes.SICK_PERSON_TRANSPORT};
        }

        case efServiceAssignmentTypes.SUBSTITUTE_DRIVER: {
            return {serviceType: apmACEPartnerServiceTypes.REPLACEMENT_DRIVER};
        }

        case efServiceAssignmentTypes.MEDICINE_SHIPMENT: {
            return {serviceType: apmACEPartnerServiceTypes.MEDICINE_SHIPMENT};
        }

        case efServiceAssignmentTypes.DEATH: {
            return {serviceType: apmACEPartnerServiceTypes.HELP_IN_CASE_OF_DEATH};
        }

        case efServiceAssignmentTypes.LEGAL_ADVICE: {
            return {serviceType: apmACEPartnerServiceTypes.LEGAL_ADVICE};
        }

        default: return {};
    }
};

/**
 * Fetch service provider data
 */
const loadServiceProviderData = function* loadServiceProviderData({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const serviceCases = yield select(state => state.serviceCases.serviceCases);
    const {activeTab} = yield* selectSearchQueryParams();
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {match} = payload;
    const {serviceCaseId, serviceAssignmentLineNo} = match.params;
    const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
    const serviceCase = serviceCases[serviceCaseId];
    const serviceProviderPayloadData = getServiceProviderPayloadData(serviceAssignment);

    if (!activeTab.includes(saosScreenTabs.SERVICE_PROVIDER) || !serviceProviderPayloadData) return;

    yield put({
        type: serviceAssignmentActionTypes.SET_SERVICE_PROVIDER_SEARCH_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield fork(fetchRequest,
        serviceAssignmentActionTypes.FETCH_SERVICE_PROVIDER_DATA_REQUEST,
        partnerManagementService.getACEPartnersV2,
        serviceProviderPayloadData);

    const responseAction = yield take([
        serviceAssignmentActionTypes.FETCH_SERVICE_PROVIDER_DATA_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_SERVICE_PROVIDER_DATA_REQUEST_FAILED,
    ]);

    yield put({
        type: serviceAssignmentActionTypes.SET_SERVICE_PROVIDER_SEARCH_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });


    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        if (serviceCase && serviceAssignment?.assignmentType === efServiceAssignmentTypes.SUBSTITUTE_DRIVER) {
            const {damage} = serviceCase;
            const {destination} = serviceAssignment;

            if (damage?.location && destination) {
                yield all(acePartnerDTOs.map(serviceProvider => call(
                    calculateSASDServiceProviderRouteDistance,
                    {
                        damage,
                        destination,
                        serviceProvider,
                    },
                )));
            }
        }

        yield put({
            type: serviceAssignmentActionTypes.STORE_SERVICE_PROVIDERS,
            payload: {acePartnerDTOs},
        });
    }
};

export default loadServiceProviderData;
